import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataAny, APIDataDocumentSchema, SchemaProps } from '@evertel/types'
import { DocumentSchemaStore } from '@evertel/stores'
import { SchemaFieldItemProps, itemProps } from '../../view/schema-builder/data/_schemaItemProps'
import { SchemaCategoryUtils, SchemaMediaUtils } from '../../utils'
import { reorder } from '@evertel/utils'

// defaults
const defaultIconColor = '#359AA2'
const defaultIcon = 'file'
const defaultName = 'Unknown EverDoc'
const defaultRow  = {
    type: 'row',
    items: []
}

type CategoriesArrays = {
    added: number[],
    deleteQueue: number[]
}
class SchemaBuilderController {
    id = 0
    name = ''
    description = ''
    isEnabled = false
    meta = {}
    icon = 'file'
    color = defaultIconColor
    departmentId = 0
    createdDate: Date|undefined = undefined
    updatedDate: Date|undefined = undefined
    schema: SchemaProps|Record<string, any> = {}
    categories?: CategoriesArrays = undefined

    schemaKey = 'root'
    documentsCount = 0 // number of docs using this schema

    constructor(
        private api: Api,
        private schemaStore: DocumentSchemaStore,
        private schemaCategoryUtils: SchemaCategoryUtils,
        private schemaMediaUtils: SchemaMediaUtils
    ) {
        makeAutoObservable(this)
    }

    init = async (schemaId: number, departmentId: number) => {
        let docSchema: APIDataDocumentSchema
        let docsCount = 0

        if (schemaId) {
            docSchema = await this.api.Routes.DocumentSchema.getById(schemaId)

            docsCount = (await this.api.Routes.DocumentSchema.getDocumentsCount(schemaId)).count as number

        } else {
            // default for new schema
            docSchema = {
                schema: {
                    layouts: {
                        full: [
                            defaultRow
                        ]
                    },
                    fields: {
                    },
                    internalSchemas: {}
                }
            }
        }

        runInAction(() => {
            this.id = schemaId
            this.documentsCount = docsCount
            this.departmentId = (departmentId) ? departmentId : 0
            this.schema = docSchema.schema
            this.isEnabled = docSchema.isEnabled || false
            this.meta = docSchema.meta || {}
            this.icon = docSchema.icon || defaultIcon
            this.color = docSchema.color || defaultIconColor
            this.name = docSchema.name || defaultName
            this.description = docSchema.description || ''
            this.createdDate = docSchema.createdDate || undefined
            this.updatedDate = docSchema.updatedDate || undefined
        })
    }

    save = async () => {
        const schemaData = {
            departmentId: this.departmentId, // if departmentId is 0 it is a global template
            name: this.name,
            description: this.description,
            isEnabled: this.isEnabled,
            icon: this.icon,
            color: this.color,
            meta: this.meta,
            schema: this.schema
        }

        let schema: APIDataDocumentSchema|undefined = undefined

        if (this.id) {
            // process any media first
            const fields = await this.schemaMediaUtils.processSchemaMedia(
                this.id,
                this.schema['fields']
            )

            // edit
            schema = await this.api.Routes.DocumentSchema.putById(this.id, {
                ...schemaData,
                fields
            } as APIDataDocumentSchema)
        } else {
            // create
            if (this.departmentId) {
                // department template
                schema = await this.api.Routes.Department.postDocumentSchemas(this.departmentId, schemaData as APIDataAny)
            } else {
                // global template
                schema = await this.api.Routes.DocumentSchema.post(schemaData as APIDataAny)
            }

            if (schema?.id) {
                // process any media after template is created
                const fields = await this.schemaMediaUtils.processSchemaMedia(
                    schema.id as number,
                    this.schema['fields']
                )

                // update the new schema with the new media Ids
                schema = await this.api.Routes.DocumentSchema.putById(schema.id, {
                    ...schemaData,
                    fields
                } as APIDataDocumentSchema)
            }
        }

        // process any categories
        if (schema) {
            await this.schemaCategoryUtils.processSchemaCategories(this.categories, schema.id as number)

            runInAction(() => {
                this.schemaStore.update(schema)
            })
        }

        return schema
    }

    updateListLayoutField = (data: SchemaFieldItemProps) => {
        // this is for updating root list properties, NOT the list's fields or layout
        // NOTE: expects the full field object
        if (!data) return

        const dataKey: string = Object.keys(data)[0]

        // copy object (remove from observability so we can manipulate)
        const schemaCopy = toJS(this.schema)
        const schema = (this.schemaKey === 'root') ? schemaCopy : schemaCopy.internalSchemas[this.schemaKey]

        // grab data and index of matching row
        const newData = data[dataKey]
        const rowIndex = schema.layouts.full.findIndex((row: any) => row.items?.some((item: any) => item.type === `&${newData?.internalSchemaId}`))

        if (rowIndex === -1) {
            // if the row does not exist, error
            console.error('ERROR: could not find row for list id "'+newData.internalSchemaId+'"')
            return
        }

        // grab row and matching index of item
        const row = schema.layouts.full[rowIndex]
        const itemIndex = row.items?.findIndex((r: any) => r.type === `&${newData?.internalSchemaId}`)

        if (itemIndex === -1) {
            // if the row does not exist, error
            console.error('ERROR: could not find list id "&'+newData.internalSchemaId+'" in row items')
            return
        }

        const currentItemData = row.items[itemIndex]

        // update item data
        row.items[itemIndex] = {...currentItemData, ...newData}

        // merge new and old data, update schema observable
        schema.layouts.full[rowIndex] = row
        this.schema = schemaCopy
    }

    updateField = (field: SchemaFieldItemProps) => {
        // NOTE: expects the full field object
        if (!field) return

        // copy object (remove from observability so we can manipulate)
        const schemaCopy = toJS(this.schema)
        const schema = (this.schemaKey === 'root') ? schemaCopy : schemaCopy.internalSchemas[this.schemaKey]
        
        // grab key name of field
        const fieldKey: string = Object.keys(field)[0]

        // grab new and current field data
        const newFieldData = field[fieldKey]
        const currentFieldData = schema.fields[fieldKey]

        if (!currentFieldData) {
            // if the field does not exist, error
            console.error('ERROR: no field named '+fieldKey+' found')
            return
        }

        // merge new and old data, update schema observable
        schema.fields[fieldKey] = {...currentFieldData, ...newFieldData}
        this.schema = schemaCopy
    }

    addField = (fieldData: SchemaFieldItemProps): string => {
        // this adds a new field to the FIELDS object in the schema
        // NOTE: expects the full field object with all it's params

        // copy object (remove from observability so we can manipulate)
        const schemaCopy = toJS(this.schema)
        const schema = (this.schemaKey === 'root') ? schemaCopy : schemaCopy.internalSchemas[this.schemaKey]

        // set new field key
        const fieldKey = `${fieldData.type}-field-${this.UID}`

        // set data to new key, update schema observable
        schema.fields[fieldKey] = { ...fieldData, id: fieldKey }
        this.schema = schemaCopy

        return fieldKey
    }

    addRow = (index: number) => {
        // this adds a new row to the schema.layouts for whichever schemaKey is active

        // copy object (remove from observability so we can manipulate)
        const schemaCopy = toJS(this.schema)
        const schema = (this.schemaKey === 'root') ? schemaCopy.layouts.full : schemaCopy.internalSchemas[this.schemaKey].layouts.full

        // insert empty row at index, update schema observable
        schema.splice(index, 0, defaultRow)
        this.schema = schemaCopy

    }

    updateRow = (oldIndex: number, newIndex: number) => {
        // this reorders rows in the schema.layouts.full array
        if (isNaN(oldIndex) || isNaN(newIndex)) {
            return
        }

        // copy object (remove from observability so we can manipulate)
        const schemaCopy = toJS(this.schema)
        const schemaFullLayout = (this.schemaKey === 'root') ? schemaCopy.layouts.full : schemaCopy.internalSchemas[this.schemaKey].layouts.full

        // reorder rows
        const newLayout = reorder(
            schemaFullLayout,
            oldIndex,
            newIndex
        )

        if (this.schemaKey === 'root') {
            schemaCopy.layouts.full = newLayout
        } else {
            schemaCopy.internalSchemas[this.schemaKey].layouts.full = newLayout
        }

        // update schema observable
        this.schema = schemaCopy
    }

    addFieldToRow = (rowIndex: number, fieldId: string) => {
        // this adds a field to a row in the schema.layouts.full array
        if (!fieldId || isNaN(rowIndex)) {
            return
        }

        // copy object (remove from observability so we can manipulate)
        let schemaCopy = toJS(this.schema)
        let schema = (this.schemaKey === 'root') ? schemaCopy.layouts.full : schemaCopy.internalSchemas[this.schemaKey].layouts.full

        // build the field props using field config props
        const field: SchemaFieldItemProps = itemProps[fieldId]
        const fieldKeys = Object.keys(field)
        const fieldData = {} as SchemaFieldItemProps

        // set props to defaults set in field config props
        fieldKeys.forEach((key) => {
            fieldData[key] = field[key].default
        })

        // add new field to FIELDS object
        const newFieldId = this.addField({
            ...fieldData,
            type: fieldId
        })

        // grab a new copy of the schema (new field was added)
        schemaCopy = toJS(this.schema)
        schema = (this.schemaKey === 'root') ? schemaCopy.layouts.full : schemaCopy.internalSchemas[this.schemaKey].layouts.full

        // add new field to target row
        const row = schema[rowIndex]
        row.items = [
            ...row.items,
            { type: `#${newFieldId}` }
        ]
        schema[rowIndex] = row

        this.schema = schemaCopy
    }

    moveFieldToRow = (oldRowIndex: number, newRowIndex: number, fieldId: string) => {
        // this moves an existing field to an existing row
        if (!fieldId || isNaN(oldRowIndex) || isNaN(newRowIndex)) {
            return
        }

        // copy object (remove from observability so we can manipulate)
        const schemaCopy = toJS(this.schema)
        const schema = (this.schemaKey === 'root') ? schemaCopy.layouts.full : schemaCopy.internalSchemas[this.schemaKey].layouts.full

        // defense for bad v1 structure
        if (!schema[newRowIndex].items) return
        
        // remove field from old row
        const row = schema[oldRowIndex]
        if (!row?.items) return
        const targetItem = row.items.find((i: any) => i.type === fieldId)
        const rowItems = row.items.filter((i: any) => i.type !== fieldId)
        schema[oldRowIndex] = { ...row, items: rowItems }

        // add field to new row
        schema[newRowIndex].items.push(targetItem)
        
        // update schema observable
        this.schema = schemaCopy
    }

    reorderFieldInRow = (fieldId: string, rowIndex: number, currentItemIndex: number, newItemIndex: number) => {
        // this reorders a field in a row (horizontally)

        if (!fieldId || isNaN(rowIndex) || isNaN(currentItemIndex) || isNaN(newItemIndex)) {
            return
        }

        // copy object (remove from observability so we can manipulate)
        const schemaCopy = toJS(this.schema)
        const schema = (this.schemaKey === 'root') ? schemaCopy.layouts.full : schemaCopy.internalSchemas[this.schemaKey].layouts.full

        // grab the row
        const row = schema[rowIndex]
        if (!row) return
        
        // reorder items
        const items = reorder(
            row.items,
            currentItemIndex,
            newItemIndex
        )
        // add new order back to row
        schema[rowIndex].items = items
        // update schema ovservable
        this.schema = schemaCopy

    }

    addListToRow = (rowIndex: number, fieldId: string) => {
        // this adds a LIST field to a row in the schema.layouts.full array
        if (!fieldId || isNaN(rowIndex)) {
            return
        }

        // copy object (remove from observability so we can manipulate)
        const schemaCopy = toJS(this.schema)

        const field = itemProps[fieldId]
        const fieldKeys = Object.keys(field)
        const fieldData = {} as SchemaFieldItemProps

        // construct the proper field with default values
        fieldKeys.forEach((key) => {
            fieldData[key] = field[key].default
        })

        // set list id
        const internalId = `list-${this.UID}`

        // add to internalSchemas array
        schemaCopy.internalSchemas[internalId] = {
            layouts: {
                full: [defaultRow]
            },
            fields: {},
            internalSchemas: {}
        }

        // add new field to target row
        const row = schemaCopy.layouts.full[rowIndex]
        row.items = [
            ...row.items,
            {
                ...fieldData,
                type: `&${internalId}`
            }
        ]
        schemaCopy.layouts.full[rowIndex] = row

        this.schema = schemaCopy
    }

    deleteField = (fieldId: string, rowIndex: number) => {
        // this will delete the field AND remove it's reference from layouts.full

        // note: we don't allow the deletion of the 'title' field (this is just a failsafe here)
        if (!fieldId || isNaN(rowIndex)) return

        // copy object (remove from observability so we can manipulate)
        const schemaCopy = toJS(this.schema)
        const schema = (this.schemaKey === 'root') ? schemaCopy : schemaCopy.internalSchemas[this.schemaKey]

        // grab the row
        const row = schema.layouts.full[rowIndex]
        if (!row) return

        // filter out the item we don't want (compare w/o first character # || &)
        const items = row.items?.filter(i => i.type?.substring(1) !== fieldId)

        // rebuild row
        schema.layouts.full[rowIndex].items = items

        // delete the field (must be after we remove references from layouts)
        delete schema.fields[fieldId]

        // update schema ovservable
        this.schema = schemaCopy
    }

    deleteRow = (rowIndex: number) => {
        // this will delete the row AND any fields within it

        if (isNaN(rowIndex)) return

        // copy object (remove from observability so we can manipulate)
        const schemaCopy = toJS(this.schema)
        const schema = (this.schemaKey === 'root') ? schemaCopy.layouts.full : schemaCopy.internalSchemas[this.schemaKey].layouts.full

        // grab row's items (before we delete the row)
        const fields = schema[rowIndex].items

        // delete the row
        schema.splice(rowIndex, 1)

        // delete the fields/items (after we delete the row)
        fields?.forEach(item => {
            const itemKey = item.type.substring(1)
            delete schemaCopy.fields[itemKey]
        })

        // update schema ovservable
        this.schema = schemaCopy
    }

    deleteSchema = async () => {
        await this.api.Routes.DocumentSchema.delById(this.id)
    }

    setName = (name: string) => {
        this.name = name
    }

    setDescription = (desc: string) => {
        this.description = desc
    }

    setIcon = ({icon, color}: {icon: string, color: string}) => {
        this.icon = icon
        this.color = color
    }

    setIsEnabled = (enabled: boolean) => {
        this.isEnabled = enabled
    }

    setSchemaKey = (key: string) => {
        this.schemaKey = key
    }

    setCategories = (categoriesArrays: CategoriesArrays) => {
        this.categories = categoriesArrays
    }

    get defaultName() {
        return defaultName
    }

    get UID() {
        // unique number generator - no, not to NV Gaming Commission standards ;)
        return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
    }
}

decorate(injectable(), SchemaBuilderController)
decorate(inject(Api), SchemaBuilderController, 0)
decorate(inject(DocumentSchemaStore), SchemaBuilderController, 1)
decorate(inject(SchemaCategoryUtils), SchemaBuilderController, 2)
decorate(inject(SchemaMediaUtils), SchemaBuilderController, 3)

export {
    SchemaBuilderController
}

import { makeAutoObservable, runInAction } from 'mobx'
// evertel
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataRoomMessage, APIDataThreadMessage } from '@evertel/types'
import { localId } from '@evertel/utils'
import { SessionState } from '@evertel/session'
import { RoomMessagesStore, RoomStore, ThreadMessagesStore } from '@evertel/stores'
import { MediaUploadService, UploadableMedia } from '@evertel/media'

class CreateMessageController {
    modelId = 0 //roomId or threadId
    modelType: 'room'|'thread' = 'room'
    createdMessage: APIDataRoomMessage|APIDataThreadMessage = undefined

    constructor(
        private api: Api,
        private session: SessionState,
        private roomMessagesStore: RoomMessagesStore,
        private threadMessagesStore: ThreadMessagesStore,
        private mediaUploadService: MediaUploadService,
        private roomStore: RoomStore
    ) {
        makeAutoObservable(this)
    }

    init = (modelId: number, modelType: 'room'|'thread') => {
        if (!modelId) console.error('Missing modelId! in CreateMessageController.init')
        this.modelId = modelId
        this.modelType = modelType
    }

    postMessage = async (text: string, isUrgent: boolean, uploadableMedia: UploadableMedia[]) => {
        if (!this.modelId || (!text && !uploadableMedia?.length)) return undefined

        const isRoomMessage = (this.modelType === 'room')

        // initial object
        // if it has media, we do not set the publishedDate
        const initialMessage = {
            id: localId(),
            isRetracted: false,
            isUrgent,
            media: [],
            ownerId: this.session.currentUserId,
            publishedDate: (!uploadableMedia.length) ? new Date() : undefined, // don't set a publishDate if we have to upload media first
            text,
            type: 'user',
            reads: [],
            meta: { uploadingMedia: uploadableMedia?.length },
            roomId: isRoomMessage ? this.modelId : undefined,
            threadId: !isRoomMessage ? this.modelId : undefined
        } as APIDataRoomMessage|APIDataThreadMessage
        
        try {
            // Create the Room or Thread Message
            const remoteMessage = await this.api.Routes[(isRoomMessage) ? 'Room' : 'Thread'].postMessages(this.modelId, initialMessage) as APIDataRoomMessage|APIDataThreadMessage
            
            // Add uploadable media to state so that MessageController can upload media for this message later
            if (uploadableMedia.length) {
                for (const media of uploadableMedia) {
                    this.mediaUploadService.addUploadableMedia(remoteMessage.id,  this.modelType, media)
                }
            }

            // Update the message in the store
            if (isRoomMessage) {
                this.roomMessagesStore.update(remoteMessage)
            } else {
                this.threadMessagesStore.update(remoteMessage)
            }

            runInAction(() => {
                this.createdMessage = remoteMessage
            })

            return remoteMessage
        } catch (error) {
            // special case on Room.postMessages(), we cant post if the room is archived.
            if (error.code === 'ROOM_IS_ARCHIVED') {
                // we have out dated room details, lets update that.
                const room = await this.api.Routes.Room.getById(this.modelId)
                this.roomStore.update(room)
            }

            // Let the caller handle this
            throw error
        }

    }
}

decorate(injectable(), CreateMessageController)
decorate(inject(Api), CreateMessageController, 0)
decorate(inject(SessionState), CreateMessageController, 1)
decorate(inject(RoomMessagesStore), CreateMessageController, 2)
decorate(inject(ThreadMessagesStore), CreateMessageController, 3)
decorate(inject(MediaUploadService), CreateMessageController, 4)
decorate(inject(RoomStore), CreateMessageController, 5)

export { CreateMessageController }

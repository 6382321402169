import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
// evertel
import { Modal, ModalBody, Row, Col, InfoBox, Button, Icon, useUI, ModalHeader, Text } from '@evertel/web/ui'
import { copyToClipboard } from '@evertel/utils'
import { APIDataRoom } from '@evertel/types'
import { useService } from '@evertel/di'
import { SharedRoomController } from '@evertel/room'


interface ShareRoomModalProps {
    visible: boolean,
    room: APIDataRoom,
    onClose: () => void
    userIsGuest: boolean
}

const SharedRoomModal: React.FC<ShareRoomModalProps> = observer(({
    visible,
    room,
    onClose,
    userIsGuest
}) => {

    const controller = useService(SharedRoomController, [room?.id])
    const { addToast, isConfirmed } = useUI()

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(undefined)

    useEffect(() => {
        controller.init(room?.id)
    
    }, [room?.id])

    useEffect(() => {
        if (visible) {
            //AnalyticsStore.logModalView('room-share')
        }
    }, [visible])

    const createPersistentGuestInvite = async () => {
        setIsLoading(true)

        try {
            await controller.createInvite()

        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }

        setIsLoading(false)
    }

    const copy = (text) => {
        // copy text
        copyToClipboard(text)

        addToast({
            color: 'success',
            message: 'Copied to clipboard!'
        })
    }

    const deleteInvite = async (inviteId) => {

        const deleteIt = async () => {
            setIsLoading(true)

            try {
                await controller.deleteInvite(inviteId)

            } catch (error) {
                addToast({
                    color: 'danger',
                    message: error.message
                })
            }

            setIsLoading(false)
        }

        const confirmed = await isConfirmed({
            title: 'Delete Invite Link?',
            message: 'Are you sure you want to delete this shared invite?\n\nOnce deleted, anyone with the link or access code will not be able to join this room. Anyone already in this room will remain in this room.',
            acceptButton: {
                label: 'Delete',
                color: 'danger'
            }
        })
        if (confirmed) {
            deleteIt()
        }
    }


    if (!room) return null

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            size="lg">
            <ModalHeader
                title={`Share "${room?.name}" with other agencies`}
                subtitle="In a Regional Collaboration room, people from other agencies can join a room and work togetherin a single space. Great for multi-agency events, cases, and crisis situations."
            />
            <ModalBody className="mb-3">
                {(!controller.latestActiveInvite) &&
                    <>
                        <Row className="my-4">
                            <Col>
                                <InfoBox color="info">
                                    <Text bold>Things to keep in mind...</Text>
                                    <ul className="mt-2">
                                        <li className="mb-2">Any people outside your agency that join in a shared room will be guests and will only have access to this room</li>
                                        <li className="mb-2">When you share an existing room, the other agencies can view room members and room message history</li>
                                        <li className="mb-2">Members from both agencies can direct message each other</li>
                                        <li className="mb-2">When you broadcast the invite link or code, anyone that intercepts it can join the room. So, distribute with care</li>
                                        <li>Agencies you share the invite link with can distribute the link to the personnel they want to include on the room</li>
                                    </ul>
                                </InfoBox>
                            </Col>
                        </Row>
                        <Row align="center">
                            <Button
                                color="success"
                                size="lg"
                                loading={isLoading}
                                onClick={createPersistentGuestInvite}>
                                Share Room
                            </Button>
                        </Row>
                    </>
                }
                {(controller.latestActiveInvite) &&
                    <InfoBox color="info">
                        <Text
                            tag="h4"
                            heavy>
                            Anyone with this link can post and invite their coworkers
                        </Text>
                        <Text>
                            In a crisis situation, this Access Code could be broadcast over an encrypted radio channel for mass distribution
                            where emailing the link isn't feasible
                        </Text>
                        <div className="mt-4 mb-2 code-block">
                            {controller.latestActiveInvite.url}<br/><br/>
                            Access Code: {controller.latestActiveInvite.token}
                        </div>
                        <div>
                            <Button
                                color="info"
                                className="mr-4"
                                onClick={() => copy(controller.latestActiveInvite.url + '\n\nAccess Code: ' + controller.latestActiveInvite.token)}>
                                <Icon
                                    name="copy"
                                    className="mr-1"
                                />
                                Copy Link
                            </Button>
                            {(!userIsGuest) &&
                                <Button
                                    color="danger"
                                    outline
                                    onClick={() => deleteInvite(controller.latestActiveInvite.id)}>
                                    <Icon
                                        name="minus-circle"
                                        className="mr-1"
                                    />
                                    Revoke Link
                                </Button>}
                            <Text
                                size="smaller"
                                color="danger"
                                className="float-right ml-4">
                                Link expires on: {moment(controller.latestActiveInvite.expiresDate).format('L HH:mm')}
                            </Text>
                        </div>
                        {(!userIsGuest) &&
                        <div className="mt-2">
                            <Text size="smaller">
                                When revoked, outside agencies will no longer be able to join this room but existing members will remain.
                            </Text>
                        </div>}
                    </InfoBox>
                }
            </ModalBody>
        </Modal>
    )
})

export { SharedRoomModal }

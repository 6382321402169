import * as Electron from '../utilities/electronInterface'
import Api from './Api'

let useApi = process.env.REACT_APP_API_ENV || 'staging'

const hostname = window && window.location && window.location.hostname

// Production API (global.electronProduction is set to TRUE by electron host app if user switches to production.  Will be UNDEFINED otherwise)
if (hostname === 'evertelapp.com' || hostname === 'www.evertelapp.com' || Electron.isProduction() ) {
    useApi = 'production'
}

if (hostname === 'dev.evertelapp.com') {
    useApi = 'dev'
}

const api = new Api(
    useApi,
    true,
    'Web',
    (process.env.BUILD_NUMBER || '').toString()
)

api.addRequestInterceptor(
    (request: any) => {
        if (!api.logLevel) {
            return request
        }

        request.metadata = {
            startTime: new Date()
        }

        let log

        switch (api.logLevel) {
            case Api.LOG_VV:
                log = [
                    'REQUEST', request.url, request.params,
                    'HEADERS', request.headers,
                    'BODY', request.data
                ]

                console.log(log)

                break
            case Api.LOG_V:
                log = [
                    'REQUEST', request.url, request.params
                ]

                console.log(log)

                break
            case Api.LOG_NONE:
            default:
        }

        return request
    }
)

api.addResponseInterceptor(
    (response: any) => {
        if (api.logLevel) {
            let log

            const endTime = new Date()
            const duration = endTime.valueOf() - response.config.metadata.startTime.valueOf()

            switch (api.logLevel) {
                case Api.LOG_VV: {
                    const dataString = JSON.stringify(response.data, null, 2)
                    const prettyDataString = dataString.length > 1024 ? dataString.substring(0, 1024) + '...' : dataString

                    log = [
                        'RESPONSE', response.config.url, prettyDataString, duration
                    ]

                    console.log(log)

                    break
                }
                case Api.LOG_V:
                    log = [
                        'RESPONSE', response.config.url, response.status, duration
                    ]

                    console.log(log)

                    break
                case Api.LOG_NONE:
                default:
            }
        }

        return response
    }, null)

export default api


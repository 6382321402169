import React from 'react'
import { ElementType, forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

export interface SlidePanelTitleProps extends HTMLAttributes<HTMLHeadingElement> {
  className?: string
  component?: string|ElementType
}

const SlidePanelTitle = forwardRef<HTMLHeadingElement, SlidePanelTitleProps>(({
    children,
    component: Component = 'h5',
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('slidepanel-title', className)

    return (
        <Component
            ref={ref}
            className={_className}
            {...otherProps}>
            {children}
        </Component>
    )
})

export { SlidePanelTitle }
SlidePanelTitle.displayName = 'SlidePanelTitle'

import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { Delegator } from '@evertel/schema-parser'

const ListReadWidget = ({
    value = [],
    title,
    item,
    items,
    schemaId
}) => {

    const listItems = (item) ? item.items : items // backwards campatibility
    const values = (!value?.length && items?.length) ? [[]] : value

    return (
        <View>
            <Text style={{
                fontWeight: 'bold',
                marginBottom: 10
            }}>
                {title}
            </Text>
            {values.map((val, index) =>
                <View
                    key={`v-${index}`}
                    style={{
                        width: '100%',
                        borderWidth: 1,
                        borderColor: '#cccccc',
                        borderRadius: 8,
                        padding: 15,
                        marginTop: (index > 0) ? 10 : 0
                    }}>
                    {listItems?.map((listItem, idx: number) =>
                        <Delegator
                            key={`i-${idx}`}
                            value={val}
                            index={idx}
                            schemaId={schemaId}
                            {...listItem}
                        />
                    )}
                </View>
            )}
            {(!value?.length) &&
                <Text style={{
                    fontSize: 10,
                    color: '#666666'
                }}>
                    No {title}
                </Text>
            }
        </View>
    )
}

export default ListReadWidget

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
// evertel
import { Button, InfoBox, ModalHeader, Modal, ModalBody, Row, Col, Input, ModalFooter, Label } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { Analytics } from '@evertel/analytics'
import { CurrentUserController } from '@evertel/blue-user'


interface PublicRoomExtraInfoModalProps {
    visible: boolean,
    onClose: () => void,
    onSave: (body: any) => void
}

const PublicRoomRequestExtraInfoModal: React.FC<PublicRoomExtraInfoModalProps> = observer(({
    visible,
    onClose,
    onSave
}) => {

    const analytics = useService(Analytics, [])
    const currentUser = useService(CurrentUserController, [])

    const [body, setBody] = useState({
        department: currentUser.primaryDepartment?.name,
        location: `${currentUser.primaryDepartment?.city}, ${currentUser.primaryDepartment?.state}` ?? '',
        position: currentUser.primaryDepartmentsAccess?.positionString ?? '',
        message: ''
    })
    const [error, setError] = useState(null)

    useEffect(() => {
        if (visible) {
            setError(null)

            analytics.logView('public-room-request-extra-info', true)
        }
    }, [visible])

    const onSubmit = () => {
        if (!body.department || !body.location || !body.position || !body.message) {
            setError('Please fill out all fields')
            return
        }

        if (onSave) onSave(body)

        onClose()
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}>
            <ModalHeader
                title="Additional info is needed to request access to this Incident Command room"
            />
            <ModalBody>
                {(error) &&
                    <InfoBox
                        color="danger"
                        className="my-2">
                        {error}
                    </InfoBox>
                }
                <Row className="my-2">
                    <Col>
                        <Label
                            text="Your Agency"
                        />
                        <Input
                            name='department'
                            placeholder="Agency's name..."
                            value={body.department}
                            onChange={(e) => setBody((prevState) => ({
                                ...prevState,
                                department: e.target.value
                            }))}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Label
                            text="Your Agency's Location"
                        />
                        <Input
                            name='location'
                            placeholder="Agency's city and state..."
                            value={body.location}
                            onChange={(e) => setBody((prevState) => ({...prevState, location: e.target.value}))}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Label
                            text="Your Position/Title"
                        />
                        <Input
                            name='position'
                            placeholder="Your position/title..."
                            value={body.position}
                            onChange={(e) => setBody((prevState) => ({
                                ...prevState,
                                position: e.target.value
                            }))}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Label
                            text="Reason Requesting Access?"
                        />
                        <Input
                            name='message'
                            type="textarea"
                            placeholder="Why you are requesting to join this room..."
                            value={body.message}
                            onChange={(e) => setBody((prevState) => ({
                                ...prevState,
                                message: e.target.value
                            }))}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter align="right">
                <Button
                    color="muted"
                    outline
                    onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    color="success"
                    onClick={onSubmit}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    )
})

export { PublicRoomRequestExtraInfoModal }

import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { UserSearchSelect, Text } from '@evertel/web/ui'
import Wrapper from './Wrapper'
import { UserTile } from '@evertel/web/user'
import { SessionState } from '@evertel/session'
import { APIDataBlueUser } from '@evertel/types'
import { useService } from '@evertel/di'
import { UserWidgetController } from '@evertel/blue-user'
import { MultiValue, SingleValue } from 'react-select'
import { formatForSelect } from '@evertel/utils'

export interface UserWriteWidgetProps {
    id: number|string,
    value: number,
    onChange: (id: string|number, value: number) => void,
    title: string,
    required?: boolean
    errors?: string[],
    scope?: string,
    documentId?: number,
}

const UserWriteWidget: React.FC<UserWriteWidgetProps> = observer(({
    id,
    value,
    onChange,
    title,
    required,
    scope,
    errors,
    documentId,
    ...otherProps
}) => {

    const session = useService(SessionState, [])
    const userWidgetController = useService(UserWidgetController, [])

    if (!session) {
        return null
    }

    useEffect(() => {
        userWidgetController.init(
            value,
            documentId
        )
    }, [value])

    const _onChange = (user: MultiValue<APIDataBlueUser>|SingleValue<APIDataBlueUser>) => {
        user = Array.isArray(user) ? user[0] : user
        
        if (onChange && scope !== 'schemaBuilder') {
            const _user = user as APIDataBlueUser
            const _userId = _user?.id as number
            onChange(id, _userId)
        }
    }

    const selectedUser = (value && userWidgetController.user) ? formatForSelect([userWidgetController.user], 'firstName+lastName', 'id') : []
    
    return (
        <Wrapper
            title={title}
            required={required}
            errors={errors}
            {...otherProps}>
            <UserSearchSelect
                searchScope="department"
                currentUserId={session?.currentUserId || 0}
                departmentId={session?.selectedDepartmentId || 0}
                onSelect={_onChange}
                defaultOptions={false}
                selected={selectedUser as APIDataBlueUser[]}
                isDisabled={scope === 'schemaBuilder'}
            />
        </Wrapper>
    )
})

// ----------------------------------------------------------------------------------------

export interface UserReadWidgetProps {
    value: number,
    title?: string,
    description?: string,
    hint?: string,
    empty?: string,
    documentId? : number,
}

const UserReadWidget: React.FC<UserReadWidgetProps> = observer(({
    value,
    title,
    description,
    hint,
    empty,
    documentId
}) => {

    const userWidgetController = useService(UserWidgetController, [])

    useEffect(() => {
        userWidgetController.init(
            value,
            documentId
        )
    }, [value])


    return (
        <Wrapper
            title={title}
            description={description}
            hint={hint}
            empty={empty}>
            {(value) ?
                <UserTile
                    userId={value}
                />
                : 
                <Text color="muted">
                    ---
                </Text>
            }
        </Wrapper>
    )
})

export {
    UserWriteWidget,
    UserReadWidget
}

import { decorate, inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import { Api } from '@evertel/api'
import { RoomStore } from '@evertel/stores'
import { SessionState } from '@evertel/session'

class JoinRoomController {
    joinedRoomIds = []
    joinRequests = []

    constructor(
        private api: Api,
        private roomStore: RoomStore,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    init = async () => {
        if (!this.session?.currentUserId) return

        await Promise.all([
            this.fetchJoinedRooms(),
            this.fetchJoinRequests()
        ])
    }

    fetchJoinedRooms = async () => {
        try {
            const rooms = await this.api.Routes.BlueUser.getRooms(this.session.currentUserId, {
                where: {
                    isArchived: false
                }
            })

            runInAction(() => {
                this.joinedRoomIds = rooms?.map(r => r.id)
            })

        } catch (error: any) {
            console.error(error.message)
        }
    }

    fetchJoinRequests = async () => {
        try {
            const requests = await this.api.Routes.BlueUser.getRoomJoinRequests(this.session.currentUserId)

            runInAction(() => {
                this.joinRequests = requests
            })

        } catch (error: any) {
            console.error(error.message)
        }
    }

    joinRoom = async (roomId: number) => {
        if (!roomId) return

        const response = await this.api.Routes.Room.putMembersById(roomId, this.session.currentUserId)

        if (response[0]?.roomId) {
            const room = await this.api.Routes.BlueUser.getRooms(this.session.currentUserId, {
                where: {
                    id: roomId
                }
            })
            
            runInAction(() => {
                this.joinedRoomIds.push(roomId)
                this.roomStore.update(room)
                this.session.addRoomIdIBelongTo(roomId)
            })
        }
    }

    requestToJoin = async (roomId: number, body?: any) => {
        if (!roomId) return

        const request = await this.api.Routes.Room.postRoomJoinRequests(roomId, body)

        runInAction(() => {
            this.joinRequests.push(request)
            if (request?.status === 'accepted') this.joinedRoomIds.push(request.roomId)
        })
    }

    cancelRequestToJoin = async (roomId: number, requestId: number) => {
        if (!requestId) return

        await this.api.Routes.Room.delRoomJoinRequestsById(roomId, requestId)

        runInAction(() => {
            this.joinRequests = this.joinRequests?.filter(jr => jr.id !== requestId)
        })
    }
}

decorate(injectable(), JoinRoomController)
decorate(inject(Api), JoinRoomController, 0)
decorate(inject(RoomStore), JoinRoomController, 1)
decorate(inject(SessionState), JoinRoomController, 2)

export { JoinRoomController }
import { makeAutoObservable } from 'mobx'
import { decorate, inject, injectable } from 'inversify'
import { Api } from '@evertel/api'

class DepartmentSSOController {

    constructor(private api: Api) {
        makeAutoObservable(this)
    }

    joinEnterpriseAccount = async (userId: number) => {
        return await this.api.Routes.Department.postAuth0EnterpriseUserById(userId)
    }

    updateEnterpriseConnector = async (params: any) => {
        return await this.api.Routes.Department.putAuth0EnterpriseConnector(params)
    }
}

//Dependency injection with Inversify
decorate(injectable(), DepartmentSSOController)
decorate(inject(Api), DepartmentSSOController, 0)

export { DepartmentSSOController }
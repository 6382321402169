import { useEffect } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from '@evertel/web/ui'
import { autorun } from 'mobx'
// evertel
import { AnalyticsStore, NavigationStore, AppStore } from '../../../stores'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'



const CrisisNoAccess = () => {

    const session = useService(SessionState, [])

    useEffect(() => {
        autorun((init) => {
            if (!AppStore.appLoading) {
                localStorage.removeItem('crisis')
                AnalyticsStore.logPageView('/register/crisissnoaccess')

                init.dispose()
            }
        })
    })


    return (
        <div className="app d-flex flex-row justify-content-center align-items-center fadeIn animated crisis-splash" style={{height: '100vh'}}>
            <Row className="justify-content-center"  style={{ minWidth: '54vh', maxWidth: '100vh' }}>
                <Col md="12">
                    <Card className="mx-4">
                        <CardBody className="p-4">
                            <div className="pb-4">
                                <h1 className="pb-1 weight-900" style={{fontSize: '3rem'}}>
                                        Restricted Access to Crisis Rooms
                                </h1>
                                <h4 className="pb-3">
                                        Because you did not register with a government email address and we cannot verify you as a government employee,
                                        you cannot join any crisis rooms at this time.
                                </h4>
                                <h4 className="pb-3">
                                        To access crisis rooms, you can change your email to a government email address or join your agency with your agency's Access Code.
                                </h4>
                                <div className="text-center mt-4">
                                    <Button
                                        color="info"
                                        className="mr-3"
                                        size="lg"
                                        onClick={() => NavigationStore.navigate(`/account/profile/${session.currentUserId}`)}>
                                            Change My Email
                                    </Button>
                                    <Button
                                        color="info"
                                        className="mr-3"
                                        size="lg"
                                        onClick={() => NavigationStore.navigate(`/account/departments/${session.currentUserId}`)}>
                                            Join My Agency
                                    </Button>
                                    <Button
                                        color="success"
                                        size="lg"
                                        onClick={() => NavigationStore.restoreNavigation()}>
                                            Continue to Evertel...
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default CrisisNoAccess

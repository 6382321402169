import { makeAutoObservable, runInAction } from 'mobx'
import { pick } from 'lodash'
import { decorate, inject, injectable } from '@evertel/di'
import { AsyncGetPartFormDataFn, AsyncOnPartCompleteFn, mediaMultipartUpload } from './mediaMultipartUploader'
import { Api } from '@evertel/api'
import { SessionState } from '@evertel/session'
import { DeviceState } from '@evertel/device'
import { APIDataMedia } from '@evertel/types'
import { NormalizedMedia } from './NormalizedMediaType'
import { localId } from '@evertel/utils'

export type UploadableMedia = NormalizedMedia & {
    onGetPartFormData: AsyncGetPartFormDataFn
    onPartComplete?: AsyncOnPartCompleteFn
}

export type AsyncOnUploadComplete = (mediaUploads: MediaUpload[], completedUpload: MediaUpload, error: any) => Promise<void>

export type MediaUpload = UploadableMedia & {
    modelId: number,
    modelType: 'room' | 'thread' | 'document' // TODO: add schema and document as types
    remoteMedia?: APIDataMedia,
}

const modelType = {
    'room': 'RoomMessage',
    'thread': 'ThreadMessage',
    'document': 'Document'
}

class MediaUploadService {
    mediaUploads: MediaUpload[] = []

    constructor(
        private api: Api,
        private session: SessionState,
        private device: DeviceState
    ) {
        makeAutoObservable(this)
    }

    addUploadableMedia = (modelId: number, modelType: 'room' | 'thread' | 'document', media: UploadableMedia) => {
        this.mediaUploads.push({
            ...media,
            modelId,
            modelType,
            state: 'readyToUpload',
            id: localId() // negative numbers make sure oir id field does not collide with an actual remote data id 
        })
    }

    removeMediaUpload = (mediaUpload: MediaUpload) => {
        this.mediaUploads = this.mediaUploads.filter(m => m.id === mediaUpload.id)
    }

    getMediaUploadsForModel = (modelId: number, modelType: 'room' | 'thread' | 'document'): MediaUpload[] => {
        return this.mediaUploads.filter(m => m.modelId === modelId && m.modelType === modelType)
    }

    startMediaUploads = async (mediaUploads: MediaUpload[], onUploadComplete?: AsyncOnUploadComplete) => {
        const uploadableMedia = mediaUploads.filter(m => m.state === 'readyToUpload' || m.state === 'failed')
        const uploadPromises = uploadableMedia.map(async (uploadable) => {
            try {
                runInAction(() => {
                    uploadable.state = 'uploading'
                })

                // API media needs meta fields
                const meta = pick(uploadable, ['width', 'height', 'duration', 'fileSize', 'orientation'])

                const remoteMedia = await mediaMultipartUpload(this.api, {
                    ...uploadable,
                    meta,
                    onProgress: (completed: number, total: number) => {
                        // Update observable progress
                        runInAction(() => {
                            uploadable.uploadProgress = (total > 0 && completed > 0) ? (completed / total) : 0
                        })
                    },
                    modelName: modelType[uploadable?.modelType], // Model we are updating
                    modelId: uploadable.modelId, // Message id
                    userId: this.session.currentUserId,
                    deviceId: this.device.deviceId
                })

                // Media upload complete ?
                runInAction(() => {
                    uploadable.remoteMedia = remoteMedia
                    uploadable.uploadProgress = 1.0
                    uploadable.state = 'complete'
                })

                if (onUploadComplete) await onUploadComplete(uploadableMedia, uploadable, undefined)

            } catch (e) {
                // TODO: Handle this error somewhere.
                runInAction(() => {
                    uploadable.state = 'failed'
                })
                if (onUploadComplete) await onUploadComplete(uploadableMedia, uploadable, e)
            }

        })

        await Promise.all(uploadPromises)
        return
    }
}

decorate(injectable(), MediaUploadService)
decorate(inject(Api), MediaUploadService, 0)
decorate(inject(SessionState), MediaUploadService, 1)
decorate(inject(DeviceState), MediaUploadService, 2)

export { MediaUploadService }
import { makeAutoObservable } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataAny, APIDataDocumentSchemaCategory } from '@evertel/types'


class SchemaCategoryController {
    departmentId = 0

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init(departmentId?: number) {
        this.departmentId = (departmentId) ? departmentId : 0
    }

    add = async (categoryName: string) => {
        if (!categoryName?.trim()?.length) return

        categoryName = categoryName.trim()

        if (this.departmentId) {
            await this.api.Routes.Department.postDocumentSchemaCategories(this.departmentId, categoryName)
        } else {
            await this.api.Routes.DocumentSchemaCategory.post({ name: categoryName })
        }
    }

    update = async (category: APIDataDocumentSchemaCategory) => {
        await this.api.Routes.DocumentSchemaCategory.putById(category.id, category as APIDataAny)
    }

    delete = async (categoryId: number) => {
        await this.api.Routes.DocumentSchemaCategory.delById(categoryId)
    }
}

decorate(injectable(), SchemaCategoryController)
decorate(inject(Api), SchemaCategoryController, 0)

export { SchemaCategoryController }

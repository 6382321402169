import React from 'react'
import { forwardRef, HTMLAttributes, ReactNode, ElementType } from 'react'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
    className?: string
    component?: string|ElementType
}

const ModalTitle = forwardRef<HTMLDivElement, Props>(({
    children,
    className,
    component: Component = 'h5',
    ...otherProps
}, ref) => {

    const _className = classNames('modal-title', className)

    return (
        <Component
            ref={ref}    
            className={_className}
            {...otherProps}>
            {children}
        </Component>
    )
})

export default ModalTitle
ModalTitle.displayName = 'ModalTitle'

import React from 'react'
import { DepartmentStore } from '@evertel/department'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { ContentPlaceholder } from '@evertel/web/ui'
import { getEmojiDataFromNative } from 'emoji-mart'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'

interface EmojiProps {
    id?: string, // id = native emoji, colon value (custom), or id (frequents)
    skin?: 1 | 2 | 3 | 4 | 5 | 6
    size?: number | string,
    setE?: 'facebook' | 'twitter' | 'google' | 'apple',
    shortcodes?: string,
    native?: string,
    name?: string
}

const regex = /\p{Extended_Pictographic}/ug

const Emoji: React.FC<EmojiProps> = observer(({
    id,
    skin = 1,
    size = 20,
    setE = 'apple',
    shortcodes,
    native,
    ...otherProps
}) => {

    const session = useService(SessionState, [])
    const departmentStore = useService(DepartmentStore, [])

    const [emoji, setEmoji] = useState<EmojiProps>({})

    const getEmojiData = useCallback(async (text: string) => {
        let emojiData = null
        const isCustom = text.charAt(0) === ':'
        const isNative = regex.test(text)
        const department = departmentStore.findById(session.selectedDepartmentId)

        if (isCustom) {
            // custom emojis
            emojiData = (department as any)?.emojis?.find((e: any) => e.colons === text)
        } else if (isNative) {
            // native emojis (i.e. 🌼)
            emojiData = await getEmojiDataFromNative(text)
        } else {
            // regular emoji id's
            emojiData = { id: text, skin }
        }

        setEmoji(emojiData || text)

    }, [departmentStore, session.selectedDepartmentId, skin])

    useEffect(() => {
        getEmojiData(id || native as string)
    }, [id, native, getEmojiData])

    if (!emoji.id) return (
        <ContentPlaceholder
            height={size}
            width={size}
            className="m-0"
        />
    )

    return (
        // @ts-expect-error: ignore emoji tag
        <em-emoji
            size={size}
            set={setE}
            {...emoji}
            // data-tooltip={emoji.name}
            // data-tooltip-pos="top"
            {...otherProps}
        />
    )
})

export { Emoji }

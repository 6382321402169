import { AxiosResponse } from 'axios'
import axiosWrappedAgent from './AxiosInstanceWrapper'
import { InjectedApiRoutes } from './evertel-api-routes'

class Api extends InjectedApiRoutes {
    // Axios instance wrapper
    agent: typeof axiosWrappedAgent

    // Is production flag
    public isProduction = false

    // Used by direct media upload
    public baseURL = ''

    // Used to send x-app-build header
    private xAppBuild: string

    constructor(
        useApi = 'staging',
        withCredentials: boolean,
        platform: string,
        buildNumber: string
    ) {
        super(() => this.agent)
        this.agent = axiosWrappedAgent

        this.setUseApi(useApi)
        this.agent.setWithCredentials(withCredentials)

        const xAppBuild = `${platform}/${buildNumber}`
        this.xAppBuild = xAppBuild
        this.agent.setHeader('x-app-build', xAppBuild)
    }

    setUseApi = (environment: string) => {
        switch (environment) {
            case 'production': {
                this.isProduction = true
                this.setBaseURL('https://api.evertelapp.com/api/')
            }
                break

            case 'local': {
                this.isProduction = false
                this.setBaseURL('http://localhost:3000/api/')
            }
                break

            case 'dev': {
                this.isProduction = false
                this.setBaseURL('https://dev-api.evertelapp.com/api/')
            }
                break

            case 'staging':
            default: {
                this.isProduction = false
                this.setBaseURL('https://staging-api.evertelapp.com/api/')
            }
        }
    }

    setBaseURL = (baseURL: string) => {
        this.baseURL = baseURL
        this.agent.setBaseURL(baseURL)
    }

    setAuthToken = (token: unknown) => {
        if (typeof token !== 'string' || token === '') {
            return
        }

        this.agent.setHeader('Authorization', token)
    }

    deleteAuthToken = () => {
        this.agent.setHeader('Authorization', undefined)
    }

    addRequestInterceptor(onFulfilled: unknown) {
        this.agent.addRequestInterceptor(onFulfilled)
    }

    addResponseInterceptor(
        onFulfilled: ((value: AxiosResponse) => void) | null,
        onRejected: ((error: unknown) => void) | null
    ) {
        this.agent.addResponseInterceptor(onFulfilled, onRejected)
    }

    get X_APP_BUILD() {
        return this.xAppBuild
    }
}

export { Api }
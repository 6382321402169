import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import * as qs from 'qs'
// evertel
import {
    TanTable, TanTableColumnDef,
    PageBody, Badge, Text, PhotoGallery,
    Card, CardBody, CardHeader, useUI
} from '@evertel/web/ui'
import { DocumentSearchForm } from './DocumentSearchForm'
import { useService } from '@evertel/di'
import { DocumentSearchController, DepartmentDocumentSchemasController, DocumentSearchFilter, DocumentACLStore } from '@evertel/document'
import { DocumentIcon, DocumentModal } from '../elements'
import { DocumentShareModal } from '..'
import { SessionState } from '@evertel/session'
import { SelectedDepartmentAccessController } from '@evertel/departments-access'
import { useDidMountEffect } from '@evertel/hooks'
import { debounce } from 'lodash'
import { APIDataDocument, APIDataDocumentACL } from '@evertel/types'

const DocumentSearch: React.FC<{history, navigationCallback}> = observer(({
    history,
    navigationCallback
}) => {

    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    // grab filter params from query string
    const { toggleLeftNav } = useUI()

    const [searchFilter, setSearchFilter] = useState<DocumentSearchFilter>({'state': 'published'})
    const [showShareModal, setShowShareModal] = useState(false)
    const [selectedDoc, setSelectedDoc] = useState({
        document: null,
        show: false
    })

    const documentSearchController = useService(DocumentSearchController, [])
    const departmentDocumentSchemasController = useService(DepartmentDocumentSchemasController, [])
    const session = useService(SessionState, [])
    const departmentsAccessController = useService(SelectedDepartmentAccessController, [])

    useState(() => {
        toggleLeftNav('open')
        documentSearchController.init({isInfiniteScroll: false})

        // load all parameters from URL
        // parameters need to be processed before <DocumentSearchForm> is loaded
        const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true })

        if (typeof parsedQuery.limit === 'string') {
            documentSearchController.setLimit(parseInt(parsedQuery.limit))
        }
        if (typeof parsedQuery.order === 'string') {
            documentSearchController.setOrder(parsedQuery.order)
        }
        if (typeof parsedQuery.filter === 'string') {
            try {
                setSearchFilter(JSON.parse(decodeURIComponent(parsedQuery.filter)))
            } catch { /* nothing */ }
        }
        //must be set last as all the others reset the page
        if (typeof parsedQuery.page === 'string') {
            documentSearchController.setPage(parseInt(parsedQuery.page), true)
        }

    })

    useEffect(() => {
        if (!documentSearchController.isLoading){
            setIsLoading(false)
        }
    }, [documentSearchController.isLoading])


    useEffect(() => {
        // initialize (fetch available departments and schemas for search filters)
        departmentDocumentSchemasController.init(session.selectedDepartmentId)
        departmentsAccessController.init(session.currentUserId)

        documentSearchController.fetchDocumentDepartments()
        documentSearchController.fetchDocumentSchemas()

    }, [session.currentUserId, session.selectedDepartmentId])

    useEffect(() => {
        // SEARCH (triggered each time the searchFilter changes)
        documentSearchController.setFilter(searchFilter)
        search(searchFilter)
    }, [searchFilter])

    useDidMountEffect(() => {
        // SEARCH (triggered each time the page/limit/order changes.)
        search(searchFilter)
    }, [documentSearchController.page, documentSearchController.limit, documentSearchController.order])

    const debouncedSearch = useCallback(debounce(async (searchFilter) => {
        setIsLoading(true)

        // update query string with each search
        const queryParams = {
            filter: JSON.stringify(searchFilter),
            page: documentSearchController.page,
            limit: documentSearchController.limit,
            order: documentSearchController.order
        }
        const searchParams = qs.stringify(queryParams)
        history.replace(`/everdocs?${searchParams}`)

        try {
            await documentSearchController.search()
        } catch (error) {
            console.error(error.message)
        }
        //this dependency list must be empty or debounce will be created over and over and not work
    }, 500), [])

    const search = useCallback((searchFilter) => {
        // if (documentSearchController.documentSchemaIds.length) return //disable retrieval for !remote table testing
        //setting loading to true sooner feels more responsive
        setIsLoading(true)
        debouncedSearch(searchFilter)
    }, [debouncedSearch])


    const onFilterChange = (filter) => {
        setSearchFilter((prev) => ({ ...prev,
            ...filter
        }))
    }

    const onEditDoc = (documentId) => {
        setSelectedDoc((prev) => ({ ...prev,
            show: false
        }))

        navigationCallback(`/everdocs/${documentId}`)
    }

    const columns = useMemo<TanTableColumnDef<APIDataDocument & {acl: APIDataDocumentACL[]}>[]>(() => [
        {
            header: '',
            accessorKey: 'documentSchemaId',
            size: 40,
            enableSorting: false,
            cell: ({ row }) => (
                <DocumentIcon
                    schema={documentSearchController.documentSchemas?.find(d => d.id === row.original.documentSchemaId)}
                />
            )
        },
        {
            header: 'Name/Title',
            accessorKey: 'name',
            size: '40%',
            enableSorting: true,
            cell: ({ row }) => (
                <>
                    <strong>
                        {row.original.name || 'Untitled Document'}
                    </strong>
                    {row.original.isArchived && (
                        <Badge
                            className="ml-2"
                            color="gray">
                            Archived
                        </Badge>
                    )}
                </>
            )
        },
        {
            header: 'Type',
            accessorKey: 'documentSchemaId',
            size: '15%',
            enableSorting: true,
            cell: ({ row }) => (
                <Text size={13}>
                    {documentSearchController.documentSchemas?.find(d => d.id === row.original.documentSchemaId)?.name}
                </Text>
            )
        },
        {
            header: 'Last Modified',
            size: '20%',
            accessorKey: 'updatedDate',
            accessorFn: (row) => moment(row.updatedDate).format('ll HH:mm'),
            enableSorting: true,
            sortDescFirst: true,
            cell: ({ row, getValue }) => (
                <Text
                    size={13}
                    color="muted">
                    {getValue() as string}
                </Text>
            )
        },
        {
            id: 'acl',
            header: 'Owner',
            size: '20%',
            accessorFn: (row) => {
                const ownerAcl = row.acl?.find(a => a.accessType === 'OWNER')
                const owner = documentSearchController.documentOwnerPrincipals?.users?.find(u => u.id === ownerAcl?.principalId)
                return `${owner?.firstName || ''} ${owner?.lastName || ''}`.trim()
            },
            enableSorting: false,
            cell: ({ cell, getValue }) => (
                <Text size={13} color="muted">
                    {cell.getValue() as string}
                </Text>
            )
        }
    ], [documentSearchController.documentSchemas, documentSearchController.documentOwnerPrincipals])

    return (
        <PageBody>
            <Card style={{minHeight: '90vh'}}>
                <CardHeader>
                    <Text
                        tag="h3"
                        className="float-left"
                    >
                        EverDocs
                    </Text>
                    {(!!documentSearchController.documentsCount) &&
                        <Badge
                            color="gray300"
                            className="ml-2">
                            {documentSearchController.documentsCount}
                        </Badge>
                    }
                </CardHeader>
                <CardBody>
                    <DocumentSearchForm
                        filter={searchFilter}
                        onSearch={onFilterChange}
                    />
                    <TanTable
                        className='table-hover'
                        remote={true}
                        loading={isLoading}
                        data={documentSearchController.documents}
                        rowCount={documentSearchController.documentsCount}
                        columns={columns}
                        options={{
                            responsive: true,
                            responsiveHeight: 'calc(100vh - 280px)',
                            truncateCells: true,
                            pageSize: documentSearchController.limit,
                            pageNumber: documentSearchController.page,
                            sortName: documentSearchController.order?documentSearchController.order.split(' ')[0]:null,
                            sortOrder: documentSearchController.order?documentSearchController.order.split(' ')[1] as 'asc' | 'desc':null,
                            onPageChange: (page) => documentSearchController.setPage(page),
                            onPageSizeChange: (pageSize) => documentSearchController.setLimit(pageSize),
                            onSortChange: (sortName, sortOrder) => {
                                const newOrder = (sortOrder) ? `${sortName} ${sortOrder}` : null
                                documentSearchController.setOrder(newOrder)
                            }
                        }}
                        onRowClick={(row) => setSelectedDoc({
                            document: row.original,
                            show: true
                        })}
                    />
                </CardBody>
            </Card>

            <PhotoGallery>
                <DocumentModal
                    visible={selectedDoc?.show}
                    documentId={selectedDoc?.document?.id}
                    onClose={() => setSelectedDoc((prev) => ({ ...prev,
                        show: false
                    }))}
                    onEdit={(id) => onEditDoc(id)}
                    onShare={() => setShowShareModal(true)}
                />
            </PhotoGallery>

            <DocumentShareModal
                isVisible={showShareModal}
                backdrop={false}
                onClose={() => setShowShareModal(false)}
                documentId={selectedDoc?.document?.id}
                departmentId={session.selectedDepartmentId}
                currentUserId={session.currentUserId}
                documentName={selectedDoc?.document?.data?.title}
            />
        </PageBody>
    )
})

export {
    DocumentSearch
}
import React from 'react'
import { forwardRef, HTMLAttributes, useRef } from 'react'
import classNames from 'classnames'
import { useForkedRef } from '@evertel/hooks'
import { Transition } from 'react-transition-group'

interface Props extends HTMLAttributes<HTMLDivElement> {
    visible: boolean
    className?: string
    transparent?: boolean
    style?: any
}

const Backdrop = forwardRef<HTMLDivElement, Props>(({
    visible = false,
    className,
    transparent,
    style,
    ...otherProps
}, ref) => {

    const backdropRef = useRef<HTMLDivElement>(null)
    const forkedRef = useForkedRef(ref, backdropRef)

    const _className = classNames(className, 'modal-backdrop fade', {'no-bg': transparent})

    const getTransitionClass = (state: string) => {
        return state === 'entered' && 'show'
    }
  
    return (
        <Transition
            in={visible}
            mountOnEnter
            nodeRef={backdropRef}
            timeout={150}
            unmountOnExit
        >
            {(state) => {
                const transitionClass = getTransitionClass(state)
                return (
                    <div
                        className={classNames(_className, transitionClass)}
                        {...otherProps}
                        ref={forkedRef}
                    />
                )
            }}
        </Transition>
    )
})

export { Backdrop }

import React from 'react'
import { observer } from 'mobx-react-lite'
import { Modal, ModalBody, ModalHeader } from '@evertel/web/ui'
import { DocumentFullView } from '../DocumentFullView'

interface Props {
    visible?: boolean,
    documentId: number,
    onClose: () => void,
    onEdit: (documentId: number) => void,
    onShare: (documentId: number) => void
}

const DocumentModal: React.FC<Props> = observer(({
    visible = false,
    documentId,
    onClose,
    onEdit,
    onShare
}) => {

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            size="xl">
            <ModalHeader/>
            <ModalBody>
                <DocumentFullView
                    documentId={documentId}
                    onEdit={onEdit}
                    onShare={onShare}
                />
            </ModalBody>
        </Modal>
    )
})

export {
    DocumentModal
}
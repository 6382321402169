import React from 'react'
import { observer } from 'mobx-react-lite'
import { useWidgets } from './WidgetsContext'

interface DelegatorProps {
    id?: string
    documentId?: number
    schemaId?: number
    index?: number
    type?: string
    value: any
    scope?: string
    onChange?: (id: string, value: any) => void
    className?: string
    errors?: Record<string, any>
}

const Delegator: React.FC<DelegatorProps> = observer(({
    type,
    ...props
}) => {

    const components = useWidgets()
    let Component = components.unknown // default

    //console.log('DELEGATOR', type, props)

    if (components[type]) {
        Component = components[type]
    }

    return (
        <Component
            {...props}
        />
    )
})

export default Delegator

import React, { createContext, useState, useContext } from 'react'
import { CreateRoomModal } from './CreateRoomModal'

export interface CreateRoomContextProps {
    openCreateRoom: () => void,
    closeCreateRoom: () => void
}

export const CreateRoomContext = createContext<CreateRoomContextProps>({
    openCreateRoom: () => {
        //
    },
    closeCreateRoom: () => {
        //
    }
})

export const CreateRoomContextProvider: React.FC<{children?: JSX.Element}> = ({
    children
}) => {

    const [visible, setVisible] = useState(false)

    const openCreateRoom = () => {
        setVisible(true)
    }

    const closeCreateRoom = () => {
        setVisible(false)
    }

    return (
        <CreateRoomContext.Provider value={{ openCreateRoom, closeCreateRoom }}>
            {children}
            <CreateRoomModal
                visible={visible}
                onClose={() => setVisible(false)}
            />
        </CreateRoomContext.Provider>
    )
}

export const CreateRoomConsumer = CreateRoomContext.Consumer

export const useCreateRoom = () => {
    return useContext(CreateRoomContext)
}

import React from 'react'
import { ElementType, forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface CardTitleProps extends HTMLAttributes<HTMLHeadingElement> {
    className?: string
    component?: string|ElementType
}

const CardTitle = forwardRef<HTMLHeadingElement, CardTitleProps>(({
    children,
    component: Component = 'h5',
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('card-title', className)

    return (
        <Component
            ref={ref}
            className={_className}
            {...otherProps}>
            {children}
        </Component>
    )
})

export { CardTitle }

import React, { useState, useEffect } from 'react'
import { Col, Checkbox, ToggleSwitch } from '@evertel/web/ui'


interface FileTypesProps {
    onChange?: (types?: string[]) => void
    typesAllowed?: string[]
    label?: string
}

const FileTypesSelector: React.FC<FileTypesProps> = ({
    onChange,
    typesAllowed,
    label,
    ...otherProps
}) => {

    const [showFileTypes, setShowFileTypes] = useState(!!typesAllowed?.length)
    // const [other, setOther] = useState('')

    useEffect(() => {
        setShowFileTypes(!!typesAllowed?.length)
    }, [typesAllowed])

    const _onChange = ({ value, checked }) => {
        // convert to array then Set
        const typesSet = new Set(typesAllowed)

        if (checked) {
            typesSet.add(value as string)
        } else {
            typesSet.delete(value as string)
        }
        // if (other) typesSet.add(other)

        onChange && onChange(Array.from(typesSet))
    }

    const onToggle = (checked: boolean) => {
        setShowFileTypes(checked)

        if (!checked) {
            onChange && onChange(undefined)
        }
    }


    return (
        <Col {...otherProps}>
            <ToggleSwitch
                id="file-types"
                label={label}
                checked={showFileTypes}
                onChange={onToggle}
            />
            {(showFileTypes) &&
                <div>
                    <Checkbox
                        value="images"
                        label="Images (jpg, jpeg, png)"
                        className="mx-2"
                        checked={typesAllowed?.includes('images')}
                        onChange={_onChange}
                    />
                    <Checkbox
                        value="videos"
                        label="Videos (mp4, mov, wmv)"
                        className="mx-2"
                        checked={typesAllowed?.includes('videos')}
                        onChange={_onChange}
                    />
                    <Checkbox
                        value="audio"
                        label="Audio (mp3, m4a, wav)"
                        className="mx-2"
                        checked={typesAllowed?.includes('audio')}
                        onChange={_onChange}
                    />
                    <Checkbox
                        value="application"
                        label="Files/Documents (pdf, docx, xlsx, txt, ppt...)"
                        className="mx-2"
                        checked={typesAllowed?.includes('application') || typesAllowed?.includes('files')}
                        onChange={_onChange}
                    />
                    {/* <Input
                        placeholder="Other (comma seperated list of file extensions)..."
                        className="mt-2"
                        value={other}
                        onChange={(e) => setOther(e.target.value)}
                    /> */}
                </div>
            }
        </Col>
    )
}

export {
    FileTypesSelector
}

import { ContainerModule } from '@evertel/di'
import { RoomMediaSearchController } from './controllers/RoomMediaSearchController'
import { ThreadMediaSearchController } from './controllers/ThreadMediaSearchController'
import { MediaVersionResolver } from './controllers/MediaVersionResolver'
import { MediaBlobber } from './controllers/MediaBlobber'
import { MediaUploadService } from './MediaUploadService'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(RoomMediaSearchController).toSelf(),
        bind(ThreadMediaSearchController).toSelf()
        bind(MediaVersionResolver).toSelf()
        bind(MediaBlobber).toSelf()
        bind(MediaUploadService).toSelf().inSingletonScope()
    })
]

export { DI_MODULES }

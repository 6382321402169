import React from 'react'
import { Badge } from '@evertel/web/ui'
import { getDepartmentTrialStatus } from '@evertel/department'
import { APIDataDepartment } from '@evertel/types'

interface DepartmentStatusBadgeProps {
    department: APIDataDepartment;
}

const DepartmentStatusBadge: React.FC<DepartmentStatusBadgeProps> = ({ department }) => {
    if (!department) return null

    const status = getDepartmentTrialStatus(department)

    const getStatusColor = (status: ReturnType<typeof getDepartmentTrialStatus>) => {
        switch (status) {
            case 'active':
                return 'bg-success'
            case 'in_trial':
                return 'bg-blue'
            case 'trial_expired':
                return 'bg-warning'
            case 'churned':
                return 'bg-danger'
            default:
                return 'bg-gray'
        }
    }

    const getStatusText = (status: ReturnType<typeof getDepartmentTrialStatus>) => {
        switch (status) {
            case 'active':
                return 'Active'
            case 'in_trial':
                return 'In Trial'
            case 'trial_expired':
                return 'Trial Expired'
            case 'churned':
                return 'Churned'
            default:
                return 'Unknown'
        }
    }

    return (
        <Badge className={`ml-2 ${getStatusColor(status)}`}>
            {getStatusText(status)}
        </Badge>
    )
}

export { DepartmentStatusBadge }

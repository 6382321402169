import { ContainerModule } from '@evertel/di'
import { DepartmentsAccessStore } from './store'
import { DepartmentsAccessController, SelectedDepartmentAccessController } from './controllers'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(DepartmentsAccessStore).toSelf().inSingletonScope()
        bind(DepartmentsAccessController).toSelf()
        bind(SelectedDepartmentAccessController).toSelf()
    })
]

export { DI_MODULES }

import { makeAutoObservable } from 'mobx'
import * as ReactGA from 'react-ga'
import { decorate, injectable, inject } from '@evertel/di'
import { Api } from '@evertel/api'
import { SessionState } from '@evertel/session'
import { Analytics as AnalyticsBase, EventArgs, FieldsObject } from '@evertel/analytics'

class Analytics implements AnalyticsBase {
    platform = 'web'
    enabled = true

    constructor(
        private api: Api,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    async init() {
        ReactGA.initialize('G-J9P9XZGDSM', {
            testMode: !this.api.isProduction, // Boolean. Optional. Enables test mode.
            debug: false, // Boolean. Optional. If set to true, will output additional feedback to the console.
            titleCase: false, // Boolean. Optional. If set to false, strings will not be converted to title case before sending to GA.
            gaOptions: { // Object. Optional.
                userId: this.session.currentUserId?.toString()
            }
        })
    }

    async logEvent(category: string, action: string, options?: { label?: string; value?: number, [i:string]: any }): Promise<any> {
        if (!action) {
            if (!this.api.isProduction) console.warn('ERROR LOGGING EVENT TO GA. Missing "action"')
            return
        }

        if (!this.enabled) return
        ReactGA.event({
            category, // String. Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
            action, // String. Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
            ...options
        })
    }

    async logView(screen: string, isModal?: boolean, title?: string): Promise<any> {
        // //if (CurrentUserStore.isAdmin) return // don't log admin activity to analytics

        if (!screen) {
            if (!this.api.isProduction) console.warn('ERROR LOGGING PAGEVIEW IN GA. Missing "page"')
            return
        }

        if (!this.enabled) return
         
        if (isModal) {
            if (!this.enabled) return
            ReactGA.modalview(screen) // String. E.g. 'login', 'read-terms-and-conditions'
        } else {
            ReactGA.pageview(
                screen, // String. e.g. '/get-involved/other-ways-to-help'
                [], // trackerNames -> Array. Optional. A list of extra trackers to run the command on
                title // String. Optional. e.g. 'Other Ways to Help'
            )
        }


    }

    async logException(description: string, fatal?: boolean): Promise<any> {
        //if (CurrentUserStore.isAdmin) return // don't log admin activity to analytics
        if (!this.enabled) return
        ReactGA.exception({
            description, // String. Optional. Description of what happened.
            fatal // Bool. Optional. Set to true if it was a fatal exception.
        })
    }

    async setDimension(dataObject: FieldsObject): Promise<any> {
        //if (CurrentUserStore.isAdmin) return // don't log admin activity to analytics
        if (!this.enabled) return
        ReactGA.set(dataObject)
    }

    async logLogin(): Promise<any> {
        if (!this.enabled) return
        await this.logEvent('App', 'login')
    }

    async logAppOpen(): Promise<any> {
        if (!this.enabled) return

        await this.logEvent('App', 'opened')
    }

    async logSignUp(): Promise<any> {
        if (!this.enabled) return

        await this.logEvent('App', 'signup')
    }
}

decorate(injectable(), Analytics)
decorate(inject(Api), Analytics, 0)
decorate(inject(SessionState), Analytics, 1)

export { Analytics }

import React, { memo } from 'react'
import { Text, Dropdown, SelectOption } from '@evertel/web/ui'
import Wrapper from './Wrapper'

export interface DropdownWriteWidgetProps {
    id: number|string,
    value: string,
    onChange: (id: string|number, value: string) => void,
    title: string,
    required?: boolean,
    placeholder?: string,
    hint?: string,
    isMulti?: boolean,
    errors?: string[],
    options: SelectOption[],
    scope?: string
}

const DropdownWriteWidget: React.FC<DropdownWriteWidgetProps> = memo(({
    id,
    value = '',
    onChange,
    title,
    required,
    placeholder = 'Select...',
    options,
    hint,
    isMulti,
    errors,
    scope,
    ...otherProps
}) => {

    return (
        <Wrapper
            title={title}
            required={required}
            hint={hint}
            errors={errors}
            {...otherProps}>
                <Dropdown
                    value={value}
                    options={options}
                    isMulti={isMulti}
                    placeholder={placeholder}
                    isDisabled={scope === 'schemaBuilder'}
                    onChange={(props) => {
                        if (!props) {
                            onChange && onChange(id, '')
                            return
                        }
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        {/* @ts-ignore */}
                        const {value} = props

                        onChange && onChange(id, value)
                    }}
                />
        </Wrapper>
    )
})

// ----------------------------------------------------------------------------------------

export interface DropdownReadWidgetProps {
    value?: string,
    title?: string,
    empty?: string,
    options?: [{value: string, label: string}]
}

const DropdownReadWidget: React.FC<DropdownReadWidgetProps> = ({
    value = '',
    title,
    empty,
    options
}) => {

    const option = options?.find((option) => option.value === value)
    value = (typeof value !== 'undefined') ? option?.label as string : ''

    return (
        <Wrapper
            value={value}
            title={title}
            empty={empty}>
            <Text>
                {value ? value : ((typeof empty !== 'undefined') ? empty : '---')}
            </Text>
        </Wrapper>
    )
}

export {
    DropdownWriteWidget,
    DropdownReadWidget
}

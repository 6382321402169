import React, { useContext, useMemo } from 'react'
import { ServiceContext } from './ServiceContext'

export interface ServiceProviderProps {
    services: unknown[],
    children: JSX.Element
}

export const ServiceProvider = ({ services, children }: ServiceProviderProps) => {
    const current = useContext(ServiceContext) || []

    const value = useMemo(() => {
        return [...services, ...current]
    }, [])

    return (
        <ServiceContext.Provider value={value}>
            {children}
        </ServiceContext.Provider>
    )
}

export const ServiceConsumer = ServiceContext.Consumer

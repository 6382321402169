import { makeAutoObservable, runInAction } from 'mobx'
import { decorate, inject, injectable } from '@evertel/di'
import { APIDataDocument } from '@evertel/types'
import { normalize, validate, hasErrors, countErrors } from '@evertel/schema-parser'
import { DepartmentStore } from '@evertel/department'
import { DocumentStore } from '../store'
import { DocumentSchemaStore } from '@evertel/stores'
import { Api } from '@evertel/api'
import { MediaUtils } from '../utils'

const defaultTitle = 'Untitled Document'

type Platform = 'web' | 'mobile'

class EverdocMediaUplaodError extends Error {
    document: APIDataDocument

    constructor(document, args) {
        super(args)
        this.name = 'EverdocMediaUploadError'
        this.document = document
    }
}

class DocumentCreateController {
    departmentId = 0
    documentSchemaId = 0
    name = defaultTitle
    data: Record<string, any> = {}
    platform: Platform
    errors?: Record<string, any> = null
    mediaUploadError = false

    constructor(
        private departmentStore: DepartmentStore,
        private documentStore: DocumentStore,
        private documentSchemaStore: DocumentSchemaStore,
        private api: Api,
        private mediaUtils: MediaUtils
    ) {
        makeAutoObservable(this)
    }

    init = async (departmentId: number, schemaId: number, platform: Platform): Promise<void> => {
        this.departmentId = departmentId
        this.documentSchemaId = schemaId
        this.platform = platform

        if (!this.documentSchema) {
            // if the schema is not in the store, fetch it
            const schema = await this.api.Routes.Department.getDocumentSchemas(this.departmentId, {
                where: {
                    id: schemaId
                }
            })
            runInAction(() => {
                this.documentSchemaStore.update(schema)
            })
        }
    }

    updateData = (data: Record<string, any>) => {
        // called from views to hold the data as they edit the document (state)
        runInAction(() => {
            this.data = data
        })
    }

    create = async (): Promise<APIDataDocument> => {
        this.errors = null
        let document

        // normalize schema
        const schema = normalize(this.documentSchema?.schema)

        // check for validation errors
        const errors = validate(
            this.data,
            {
                type: 'form',
                items: schema['layouts']['full']
            }
        )

        // if errors, stop and throw error back to view
        if (hasErrors(errors)) {
            this.errors = errors

            throw new Error(`${this.errorsCount} errors. Please fill out all *required fields before saving.`)
        }

        // create an empty document
        document = await this.api.Routes.Department.postDocuments(this.departmentId, {
            documentSchemaId: this.documentSchemaId,
            name: this.name,
            data: this.data
        } as APIDataDocument)

        runInAction(() => {
            // update the store's data with the new document & schema
            this.documentStore.update(document)
        })

        try {
            // process any media
            const data = await this.mediaUtils.processDocumentMedia(
                this.platform,
                this.data,
                document.id as number,
                schema['layouts']['full']
            )

            // update the document with the data
            document = await this.api.Routes.Document.putById(document.id as number, {
                data
            })
        } catch (error: any) {
            throw new EverdocMediaUplaodError(document, error.message)
        }

        //if (notify) this.notify(document.id ?? 0, this.data.isUrgent ?? false)

        runInAction(() => {
            // update the store's data with the new document & schema
            this.documentStore.update(document)
        })

        this.clearData()

        // return the updated document model
        return this.documentStore.findById(document.id as number) as APIDataDocument
    }

    setName = (name: string) => {
        this.name = name
    }

    notify = (documentId: number, isUrgent: boolean) => {
        this.api.Routes.Document.postNotify(documentId, isUrgent)
    }

    clearData = () => {
        runInAction(() => {
            this.name = ''
            this.data = {}
        })
    }

    get department() {
        return this.departmentStore.findById(this.departmentId)
    }

    get documentSchema() {
        return this.documentSchemaStore.findById(this.documentSchemaId)
    }

    get errorsCount(): number {
        return countErrors(this.errors)
    }

    get defaultTitle() {
        return defaultTitle
    }
}

decorate(injectable(), DocumentCreateController)
decorate(inject(DepartmentStore), DocumentCreateController, 0)
decorate(inject(DocumentStore), DocumentCreateController, 1)
decorate(inject(DocumentSchemaStore), DocumentCreateController, 2)
decorate(inject(Api), DocumentCreateController, 3)
decorate(inject(MediaUtils), DocumentCreateController, 4)

export { DocumentCreateController, EverdocMediaUplaodError }

import React, { createContext, useState, useContext } from 'react'
import { UserDetailModal } from './UserDetailModal'


export interface UserDetailModalContextProps {
    openUserDetail: (userId: number) => void,
    closeUserDetail: () => void
}

export const UserDetailContext = createContext<UserDetailModalContextProps>({
    openUserDetail: (userId: number) => {
        //
    },
    closeUserDetail: () => {
        //
    }
})

export const UserDetailModalContextProvider: React.FC<{children?: JSX.Element}> = ({
    children
}) => {

    const [visible, setVisible] = useState(false)
    const [userId, setUserId] = useState(0)

    const openUserDetail = (userId: number) => {
        setUserId(userId)
        setVisible(true)
    }

    const closeUserDetail = () => {
        setVisible(false)
    }

    const reset = () => {
        setUserId(0)
    }

    return (
        <UserDetailContext.Provider value={{ openUserDetail, closeUserDetail }}>
            {children}
            <UserDetailModal
                visible={visible}
                onClose={() => setVisible(false)}
                onCloseDone={reset}
                userId={userId}
            />
        </UserDetailContext.Provider>
    )
}

export const UserDetailConsumer = UserDetailContext.Consumer

//TODO: this is weird, why not import the context then use it there, or rename this to useContextUserDetail
export const useUserDetail = () => {
    return useContext(UserDetailContext)
}

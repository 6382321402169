
import { makeAutoObservable } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { ThreadStore } from '@evertel/stores'
import { Api } from '@evertel/api'
import { isArray } from 'lodash'

class CreateThreadController {

    constructor(
        private api: Api,
        private threadStore: ThreadStore
    ) {
        makeAutoObservable(this)
    }


    createThread = async (userId: number|number[]) => {
        if (!isArray(userId)) userId = [userId]

        const thread = await this.api.Routes.Thread.postStart(userId)
        
        this.threadStore.update(thread)

        return thread
    }
}

decorate(injectable(), CreateThreadController)
decorate(inject(Api), CreateThreadController, 0)
decorate(inject(ThreadStore), CreateThreadController, 1)

export { CreateThreadController }
import { observable, runInAction, action, makeObservable } from 'mobx'
import _ from 'lodash'
import AppStore from './AppStore'
import api from '../api'

class PublicUsersSearchStore {
    /**
     * Indicates whether the store is busy.
     *
     * @var bool
     */
    isBusy = false

    /**
     * Contains found users.
     *
     * @type array
     */
    users = []

    constructor() {
        makeObservable(this, {
            isBusy: observable,
            users: observable,
            search: action
        })

        
    }

    /**
     * Search users from given departments and given search string.
     *
     * Store results in ```users```.
     *
     * @param {string} searchString  The string to search by
     * @param {array} departmentIds The departments to look by
     */
    search = _.debounce(async (searchString, departmentIds) => {
        runInAction(() => {
            this.isBusy = true
        })

        try {
            // Fetch from api
            const users = await api.BlueUser.searchPublic(AppStore.userId, departmentIds, {
                where: {
                    searchTerm: searchString
                },
                ...api.defaultUserScope,
                order: 'firstName',
                limit: 100
            })

            // Store results
            runInAction(() => {
                this.users = users
            })
        } finally {
            runInAction(() => {
                this.isBusy = false
            })
        }
    }, 50, {leading: true})
}

export default new PublicUsersSearchStore()


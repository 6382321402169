import React, { ElementType, forwardRef, ReactNode } from 'react'
import classNames from 'classnames'
import { ButtonDropdown } from '../elements'

interface InputGroupProps {
    className?: string;
    size?: 'sm' | 'lg';
    tag?: ElementType;
    type?: string;
    children?: ReactNode;
}

export const InputGroupContext = React.createContext({ insideInputGroup: false })

export const InputGroup = forwardRef<HTMLDivElement, InputGroupProps>(({
    className,
    tag: Tag = 'div',
    type,
    size,
    children,
    ...otherProps
}, ref) => {
    const classes = classNames('input-group', className, {
        [`input-group-${size}`]: size
    })

    if (type === 'dropdown') {
        return <ButtonDropdown {...otherProps} className={classes}>{children}</ButtonDropdown>
    }

    return (
        <InputGroupContext.Provider value={{ insideInputGroup: true }}>
            <Tag
                ref={ref}
                {...otherProps}
                className={classes}
            >
                {children}
            </Tag>
        </InputGroupContext.Provider>
    )
})

import { APIDataDocument, APIDataDocumentSchemaRevision } from '@evertel/types'
import { injectable, inject, decorate } from '@evertel/di'
import { makeAutoObservable, runInAction } from 'mobx'
// evertel
import { Api } from '@evertel/api'
import { DocumentStore } from '../store'
import { DocumentSchemaStore } from '@evertel/stores'
import moment from 'moment'

class DocumentViewController {
    documentId = 0
    name = ''
    documentSchemaId = 0
    documentSchemaRevisionId = 0
    documentSchemaRevisions = []
    loaded = false
    myAccess: any = {}
    canEdit = false
    canShare = false

    constructor(
        private documentStore: DocumentStore,
        private documentSchemaStore: DocumentSchemaStore,
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    load = async (documentId: number) => {
        if (!documentId) throw new Error('Missing a document ID!')

        this.loaded = false
        let revisions = []

        const document = await this.fetchDocument(documentId)

        if (document?.documentSchemaRevisionId) {
            revisions = await this.fetchDocumentRevisions(document.documentSchemaId, {
                where: {
                    id: document.documentSchemaRevisionId
                }
            })
        }

        this.documentStore.update(document)
        this.documentSchemaStore.update(document?.documentSchema)

        runInAction(() => {
            this.documentId = documentId || 0
            this.name = document?.name
            this.documentSchemaId = document?.documentSchemaId
            this.documentSchemaRevisionId = document?.documentSchemaRevisionId || 0
            this.documentSchemaRevisions = revisions || []
            this.loaded = true
        })
    }

    loadFromStoreOrFetch = (documentId: number) => {
        if (!documentId) throw new Error('Missing a document ID!')

        this.loaded = false
        const document = this.documentStore.findById(documentId)

        const fiveMinOld = document?.fetchedDate < moment().subtract(5, 'minutes')
        if (!document || fiveMinOld) {
            //no document or too old, so fetch again
            this.load(documentId)
        }

        if (!document?.id) {
            return
        }

        // if it was too old it intentionally still falls through to here
        // so we can show the older info while the new info is updated
        // in that case this.loaded might be ambiguous...

        runInAction(() => {
            this.documentId = documentId || 0
            this.name = document?.name
            this.documentSchemaId = document?.documentSchemaId
            this.documentSchemaRevisionId = document?.documentSchemaRevisionId || 0
            this.documentSchemaRevisions = document?.revisions || []
            this.loaded = !fiveMinOld
        })

    }

    fetchDocument = async (documentId: number): Promise<APIDataDocument> => {
        return await this.api.Routes.Document.getById(documentId, {
            include: [
                'media',
                'documentSchema'
            ]
        })
    }

    fetchDocumentRevisions = async (documentSchemaId: number, filter: any): Promise<APIDataDocumentSchemaRevision[]> => {
        return await this.api.Routes.DocumentSchema.getRevisions(documentSchemaId, filter)
    }

    setArchive = async (isArchived: boolean): Promise<APIDataDocument> => {
        const resp = await this.api.Routes.Document.putById(this.documentId, { isArchived })

        runInAction(() => {
            this.documentStore.update(resp)
        })

        return this.documentStore.findById(resp.id as number) as APIDataDocument
    }

    downloadCSV = async (documentId: number): Promise<Blob> => {
        // @ts-expect-error we treat it as a blob
        return this.api.Routes.Document.getDownloadCsv(documentId)
    }

    get document() {
        return this.documentStore.findById(this.documentId)
    }

    get documentSchema() {
        // returns the schema at the revision version of the doc
        if (this.documentSchemaRevisionId) {
            const revision = this.documentSchemaRevisions?.find(r => r.id === this.documentSchemaRevisionId)
            if (revision) {
                return {
                    ...this.documentSchemaStore.findById(this.documentSchemaId),
                    schema: revision.schema
                }
            } else {
                return this.documentSchemaStore.findById(this.documentSchemaId)
            }
        } else {
            return this.documentSchemaStore.findById(this.documentSchemaId)
        }
    }
}

decorate(injectable(), DocumentViewController)
decorate(inject(DocumentStore), DocumentViewController, 0)
decorate(inject(DocumentSchemaStore), DocumentViewController, 1)
decorate(inject(Api), DocumentViewController, 2)

export { DocumentViewController }

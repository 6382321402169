import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataDocumentSchemaCategory } from '@evertel/types'


class SchemaCategoriesController {
    schemaId = 0
    categories: APIDataDocumentSchemaCategory[] = []
    added: Map<number, APIDataDocumentSchemaCategory> = new Map()
    deleteQueue: Set<number> = new Set()
    addQueue: Map<number, APIDataDocumentSchemaCategory> = new Map()

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = async (schemaId: number) => {
        this.schemaId = schemaId

        await this.fetchSchemaCategories()
    }

    fetchSchemaCategories = async () => {
        if (!this.schemaId) return

        const categories = await this.api.Routes.DocumentSchema.getCategories(this.schemaId)

        // add to the 'added' set
        categories.forEach((category) => {
            runInAction(() => {
                this.added.set(
                    category.id as number,
                    category
                )
            })
        })

        runInAction(() => {
            this.categories = categories
        })
    }

    deleteAdded = (categoryId: number): void => {
        // remove category from 'added' set
        this.added.delete(categoryId)

        // add mediaId to the list of media to delete on save
        this.deleteQueue.add(
            categoryId
        )
    }

    add = (category: APIDataDocumentSchemaCategory): void => {
        if (!category || !category.id) return

        this.addQueue.set(
            category.id,
            category
        )
    }

    deleteFromAddQueue = (categoryId: number): void => {
        this.addQueue.delete(
            categoryId
        )
    }

    get categoryArrays() {
        return {
            added: Array.from(this.added?.values()),
            deleteQueue: Array.from(this.deleteQueue?.values()),
            addQueue: Array.from(this.addQueue?.values()),
            test: this.addQueue
        }
    }
}

decorate(injectable(), SchemaCategoriesController)
decorate(inject(Api), SchemaCategoriesController, 0)

export {
    SchemaCategoriesController
}

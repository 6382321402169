import React from 'react'

interface ProgressCircleProps {
    progress: number,
    radius?: number,
    stroke?: number,
    color?: string,
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({
    progress,
    radius = 25,
    stroke = 5,
    color = 'success'
}) => {

    const normalizedRadius = radius - stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI
    const strokeDashoffset = circumference - progress / 100 * circumference

    return (
        <svg
            height={radius * 2}
            width={radius * 2}>
            {/* path circle */}
            <circle
                stroke={'#343b43'}
                fill="transparent"
                strokeWidth={stroke}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
            {/* progress bar circle */}
            <circle
                stroke={'#4dbd74'}
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={circumference + ' ' + circumference}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                style={{
                    strokeDashoffset,
                    transition: 'stroke-dashoffset 0.25s',
                    transform: 'rotate(-90deg)',
                    transformOrigin: '50% 50%'
                }}
            />
        </svg>
    )
}

export { ProgressCircle }
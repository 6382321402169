import React, { ElementType, HTMLAttributes, useContext, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { Popper, PopperChildrenProps } from 'react-popper'
// evertel
import { Placements } from '../../prop-types'
import { Alignments, ButtonDropdownContext } from './ButtonDropdown'

export interface DropdownMenuProps
    extends HTMLAttributes<HTMLDivElement|HTMLUListElement>,
    Omit<
    PopperChildrenProps,
    | 'arrowProps'
    | 'forceUpdate'
    | 'hasPopperEscaped'
    | 'isReferenceHidden'
    | 'placement'
    | 'ref'
    | 'style'
    | 'update'
    > {
    className?: string
    /** Component used for the root node. Either a string to use a HTML element or a component. */
    component?: string|ElementType
}


const ButtonDropdownMenu: React.FC<DropdownMenuProps> = ({
    children,
    className,
    component: Component = 'ul',
    ...otherProps
}) => {
    const {
        alignment,
        autoClose,
        dark,
        direction,
        dropdownToggleRef,
        placement,
        popper,
        visible,
        setVisible
    } = useContext(ButtonDropdownContext)

    const dropdownMenuRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (visible) {
            window.addEventListener('mouseup', handleMouseUp)
            window.addEventListener('keyup', handleKeyup)
        }

        return () => {
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('keyup', handleKeyup)
        }
    }, [visible])

    const handleKeyup = (event: KeyboardEvent) => {
        if (autoClose === false) {
            return
        }

        if (event.key === 'Escape') {
            setVisible(false)
        }
    }

    const handleMouseUp = (event: Event) => {
        if (dropdownToggleRef && dropdownToggleRef.current.contains(event.target as HTMLElement)) {
            return
        }

        if (
            autoClose === true ||
            (autoClose === 'inside' && dropdownMenuRef?.current?.contains(event.target as HTMLElement)) ||
            (autoClose === 'outside' && !dropdownMenuRef?.current?.contains(event.target as HTMLElement))
        ) {
            setTimeout(() => setVisible(false), 1)
            return
        }
    }

    let _placement: Placements = placement

    if (direction === 'center') {
        _placement = 'bottom'
    }

    if (direction === 'dropup') {
        _placement = 'top-start'
    }

    if (direction === 'dropup-center') {
        _placement = 'top'
    }

    if (direction === 'dropend') {
        _placement = 'right-start'
    }

    if (direction === 'dropstart') {
        _placement = 'left-start'
    }

    if (alignment === 'end') {
        _placement = 'bottom-end'
    }

    const alignmentClassNames = (alignment: Alignments) => {
        const classNames: string[] = []
        if (typeof alignment === 'object') {
            Object.keys(alignment).map((key) => {
                classNames.push(`drop-menu${key === 'xs' ? '' : `-${key}`}-${alignment[key]}`)
            })
        }

        if (typeof alignment === 'string') {
            classNames.push(`drop-menu-${alignment}`)
        }

        return classNames
    }

    const _className = classNames(
        'drop-menu',
        {
            'drop-menu-dark': dark,
            show: visible
        },
        alignment && alignmentClassNames(alignment),
        className
    )

    const renderChildren = (children: React.ReactNode, parentIndex = '') => {
        return React.Children.map(children, (child, index) => {
            if (React.isValidElement(child)) {
                if (child.type === React.Fragment) {
                    return renderChildren(child.props.children, `${parentIndex}-${index}`)
                } else {
                    return <li key={`${parentIndex}-${index}`}>{React.cloneElement(child)}</li>
                }
            }
            return null
        })
    }

    const dropdownMenuComponent = (ref?: React.Ref<HTMLDivElement>, style?: React.CSSProperties) => {
        return (
            <Component
                className={_className}
                ref={ref}
                style={{ ...style, ...(popper ? {} : { position: 'fixed' }) }}
                role="menu"
                aria-hidden={!visible}
                {...(!popper && { 'data-popper': 'static' })}
                {...otherProps}>
                {Component === 'ul' ? renderChildren(children) : children}
            </Component>
        )
    }

    return popper && visible ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <Popper
            innerRef={dropdownMenuRef}
            placement={_placement}
            strategy='fixed'
            // modifiers={[{
            //     name: 'preventOverflow', 
            //     options: {
            //         mainAxis: true,
            //         rootBoundary: 'viewport'
            //     }
            // }]}    
        >
            { ({ ref, style }) => dropdownMenuComponent(ref, style) }
        </Popper>
    ) : (
        dropdownMenuComponent()
    )
}

export default ButtonDropdownMenu
ButtonDropdownMenu.displayName = 'ButtonDropdownMenu'

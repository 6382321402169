import { TextReadWidget } from './TextWidget'
import { UnknownWidget } from './UnknownWidget'
import { CheckboxReadWidget } from './CheckboxWidget'
import { DateReadWidget } from './DateWidget'
import { MapReadWidget } from './MapWidget'
import { ListReadWidget } from './ListWidget'
import { DropdownReadWidget } from './DropdownWidget'
import { MediaReadWidget } from './MediaReadWidget'
import { SpacerWidget } from './SpacerWidget'
import { UserReadWidget } from './UserWidget'
import { BulletinTypeReadWidget } from './BulletinTypeWidget'
import { BulletinStatusReadWidget } from './BulletinStatusWidget'
import { BulletinUrgentReadWidget } from './BulletinUrgentWidget'
import { Row } from './Row'

const ReadWidgets = {
    'bulletin_status': BulletinStatusReadWidget,
    'bulletin_type': BulletinTypeReadWidget,
    'bulletin_urgent': BulletinUrgentReadWidget,
    'checkbox-group': CheckboxReadWidget,
    checkbox: CheckboxReadWidget,
    user: UserReadWidget,
    media: MediaReadWidget,
    row: Row,
    text: TextReadWidget,
    title: TextReadWidget,
    dropdown: DropdownReadWidget,
    date: DateReadWidget,
    spacer: SpacerWidget,
    map: MapReadWidget,
    list: ListReadWidget,
    unknown: UnknownWidget
}

export {
    ReadWidgets
}
import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface NavGroupItemsProps extends HTMLAttributes<HTMLUListElement> {
    className?: string
}

const NavGroupItems = forwardRef<HTMLUListElement, NavGroupItemsProps>(({
    children,
    className,
    ...otherProps
}, ref) => {

        const _className = classNames('nav-group-items', className)

        return (
            <ul
                ref={ref}
                className={_className}
                {...otherProps}>
                {children}
            </ul>
        )
    }
)

export { NavGroupItems }
NavGroupItems.displayName = 'NavGroupItems'

import { makeAutoObservable } from 'mobx'
import AppStore from './AppStore'
import { User } from './models'
import api from '../api'
import _ from 'lodash'
import moment from 'moment'
import { BlueUserStore } from '@evertel/blue-user'
import { DepartmentsAccessStore } from '@evertel/departments-access'
import { container } from '../di'
import { DepartmentStore } from '@evertel/department'

// Keeps track of our login state and progress
class UsersStore {

    isBusy = false
    error = null
    newUserStore = undefined
    newDepartmentsAccessStore = undefined
    newDepartmentsStore = undefined
    ignoreNewUserUpdate = false

    constructor() {
        makeAutoObservable(this)

        this.newUserStore = container.get(BlueUserStore)
        this.newDepartmentsAccessStore = container.get(DepartmentsAccessStore)
        this.newDepartmentsStore = container.get(DepartmentStore)
    }

    get users() {
        return this.newUserStore?.objectsArray.map(u => new User(u))
    }

    updateFromData(data) {
        if (!data) return

        // if data is an array then use it, otherwise its a user object, put it in an array, the users objects should be unique by id
        const dataUsers = _.uniqBy(data.length !== undefined ? data : [data], 'id')

        this.newUserStore?.update(dataUsers)
        const departmentsAccess = dataUsers.map(u => u.departmentsAccess).flat() 
        this.newDepartmentsAccessStore?.update(departmentsAccess)
        const departments = departmentsAccess.map(da => da?.department).flat()
        this.newDepartmentsStore.update(departments)
    }

    // CurrentUserStore depends on this
    get currentUser() {
        return this.users.find(u => u.id === AppStore.userId) || false
    }

    getUserById(id) {
        if (!id && !api.isProduction) throw new Error('GET USER: User ID is required')
        // look for user id locally

        const userId = +id // convert to int (parseInt is slow)

        const user = this.users.find(u => u.id === userId)

        // if found, return it
        if (user) return user

        // if not found, fetch user
        // will have to create a temp user, then trigger fetch, then update temp user in fetch
        // const newUser = await this.fetchUser(id)

        // return newUser
    }

    async fetchUser(id) {
        const newUser = await api.BlueUser.getById(id, {
            include: [{
                relation: 'publicMedia'
            }, {
                relation: 'departmentsAccess',
                scope: {
                    include: [{
                        relation: 'assignment'
                    }, {
                        relation: 'position'
                    }, {
                        relation: 'department',
                        scope: {
                            include: ['publicMedia']
                        }
                    }]
                }
            }]
        })

        // store this user
        this.updateFromData(newUser)

        // WTF: The users computed field is not updated by the time we run the next line, retuning old data (User with no email)
        // return the user model (not the response)
        return this.users.find(u => u.id === newUser.id)
    }

    addUserToStore(user) {
        this.updateFromData(user)
    }

    deleteById(id){
        return this.newUserStore?.deleteById(id)
    }
}

// creates a single instance of this store
export default new UsersStore()

import React, { useState, useEffect, ReactNode, useCallback, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
// evertel
import { APIDataMedia } from '@evertel/types'
import { useService } from '@evertel/di'
import { ContentPlaceholder, PhotoGalleryContext, CustomAsyncPaginate } from '@evertel/web/ui'
import { MultiValue, Props, SingleValue } from 'react-select'
import { NormalizedMedia, ThreadMediaSearchController } from '@evertel/media'
import { Media } from '@evertel/web/ui'
import { downloadFile } from '@evertel/web/utils'

interface ThreadSearchSelectProps extends Omit<Props, 'maxMenuHeight'> {
    threadId: number,
    departmentId?: number,
    selected?: APIDataMedia[],
    isDisabled?: boolean,
    placeholder?: string,
    searchFilter?: any,
    maxMenuHeight?: number|string,
    className?: string,
    whereFilter?: any,
    allowDownload?: boolean,
    onEndReachedThreshold?: number,
    customComponents?: ReactNode
    onSelect?: (room: APIDataMedia) => void
}


const ThreadMediaSearchSelect: React.FC<ThreadSearchSelectProps> = observer(({
    threadId,
    departmentId,
    selected = [],
    isDisabled,
    placeholder = 'Search...',
    noOptionsMessage = 'No files found',
    onSelect,
    whereFilter,
    maxMenuHeight,
    allowDownload = true,
    className,
    customComponents,
    onEndReachedThreshold = 80,
    ...otherProps
}) => {

    const threadMediaSearchController = useService(ThreadMediaSearchController, [threadId])
    const photoGalleryContext = useContext(PhotoGalleryContext)

    const [isLoading, setIsLoading] = useState(true)
    const [isSearching, setIsSearching] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        if (!threadId) return

        threadMediaSearchController.init(threadId, false)
        //needs to render once with loading true to reload async paginate
        setTimeout(() => { setIsLoading(false)}, 1)

    }, [threadId])

    // onSelect called in <Media> onClick anyway
    // const onSelectOption = (media: MultiValue<APIDataMedia>|SingleValue<APIDataMedia>) => {
    //     if (onSelect) onSelect(media as APIDataMedia)
    // }

    const search = useCallback( async (
        searchString: string,
        loadedOptions: APIDataMedia[],
        { page }: { page: number }
    ) => {
        if (threadMediaSearchController.searchTerm !== searchString) {
            // only set searchTerm if it changes
            threadMediaSearchController.setSearchTerm(searchString)
        }

        //page must be set after searchTerm since searchTerm will reset page since react-select-async keeps cache
        threadMediaSearchController.setPage(page)

        try {
            setIsSearching(true)
            await threadMediaSearchController.search()
            setIsSearching(false)

        } catch (error) {
            setIsSearching(false)
            console.error('ERROR: ThreadMediaSearchSelect search():', error)
        }

        // grab results and format them for the Select
        // const results = formatForSelect(controller.media, 'id', 'id')
        // const hasMore = threadMediaSearchController.media?.length < threadMediaSearchController.mediaCount
        const hasMore = (threadMediaSearchController.mediaLatestFetch.length >= threadMediaSearchController.limit)

        return {
            options: threadMediaSearchController.mediaLatestFetch || [],
            hasMore,
            additional: {
                page: page + 1 || 1
            }
        }
    }, [threadId])

    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number): boolean => {
        if (isSearching) return false
        const maxPixelThreshold = 300
        const percentThreshold = 100 - onEndReachedThreshold

        const pxFromBottom = scrollHeight - clientHeight - scrollTop
        const percentThresholdFromBottomInPx = ((percentThreshold / 100) * (scrollHeight - clientHeight))
    
        // Determine the threshold to use (whichever is smaller)
        const thresholdToUse = Math.min(percentThresholdFromBottomInPx, maxPixelThreshold)

        // console.log('px from bottom / threshold, result', pxFromBottom, thresholdToUse, pxFromBottom <= thresholdToUse)    
        return pxFromBottom <= thresholdToUse
    }

    const onClickMedia = (media: NormalizedMedia) => {
        if (!media) return
        
        if (['image', 'video', 'audio'].includes(media.contentType)) {
            photoGalleryContext.setMedia([media])
            photoGalleryContext.setVisible(true)

        } else {
            downloadFile(media.url, media.fileName)
            return
        }

    }

    if (isLoading) {
        return (
            <ContentPlaceholder
                className={className}
                style={{
                    height: 38
                }}
            />
        )
    }

    return (
        <CustomAsyncPaginate
            name="rooms"
            keyName={threadId}
            searchOnly={true}
            loadOptions={search as any}
            debounceTimeout={300}
            additional={{
                page: 1
            }}
            defaultOptions
            shouldLoadMore={shouldLoadMore}
            value={selected}
            maxMenuHeight={maxMenuHeight}
            isDisabled={isDisabled}
            // onChange={onSelectOption}
            noOptionsMessage={() => noOptionsMessage}
            placeholder={placeholder}
            className={className}
            components={customComponents || { Option: MediaItem as any }}
            callbacks={{
                onClickMedia,
                allowDownload
            }}
            {...otherProps}
        />
    )
})

interface MediaItemProps {
    data: NormalizedMedia,
    innerProps: any,
    selectProps: {
        callbacks: {
            onClickMedia: (arg0: NormalizedMedia) => void,
            allowDownload: boolean
        }
    }
}

const MediaItem: React.FC<MediaItemProps> = observer(({
    data,
    innerProps,
    selectProps
}) => {
    //console.log(data, innerProps, selectProps)

    if (!data) return null

    const { onClickMedia, allowDownload } = selectProps.callbacks
    const thumbWidth = 50

    return (
        <Media
            media={data}
            variant="file-list"
            width={thumbWidth}
            height={thumbWidth}
            rounded
            allowDownload={allowDownload}
            className="p-1 w-100"
            onClick={() => onClickMedia(data)}
            videoProps={{ showThumbnail: true }}
            imageProps={{ flexibility: 'fixed' }}
            resizeMode='cover'
        />
    )
})

export {
    ThreadMediaSearchSelect
}

import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface CardFooterProps extends HTMLAttributes<HTMLDivElement> {
    className?: string
}

const CardFooter = forwardRef<HTMLDivElement, CardFooterProps>(({
    children,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('card-footer', className)

    return (
        <div
            ref={ref}
            className={_className}
            {...otherProps}>
            {children}
        </div>
    )
})

export { CardFooter }

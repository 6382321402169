import { ContainerModule } from '@evertel/di'
import { DrawerController } from './controller'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(DrawerController).toSelf()
    })
]

export { DI_MODULES }

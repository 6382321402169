import { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { Emoji } from 'emoji-mart'
// evertel
import { EmojiStore } from '../stores'

const propTypes = {
    onSelected: PropTypes.func,
    reaction: PropTypes.object,
    showClickAnimation: PropTypes.bool
}

const defaultProps = {
    reaction: {},
    showClickAnimation: true
}

const Reaction = observer(({
    reaction,
    onSelected,
    showClickAnimation
}) => {

    if (!reaction) return null

    const reactedByMe = reaction.thisUserReacted
    const [bounce, setBounce] = useState(false)

    const onClick = () => {
        if (onSelected) onSelected(reaction)

        setBounce(true)
        setTimeout(() => {
            setBounce(false)
        }, 750)
    }

    const emoji = EmojiStore.getEmojiData(reaction.text)
    if (!emoji) return null

    return (
        <div className={classNames('reaction', {mine: reactedByMe, bounce: bounce && showClickAnimation})} onClick={onClick}>
            <Emoji
                emoji={emoji}
                size={16}
                native
                skin={emoji.skin || 1}
            />
            <div>
                {(!!reaction.count) && reaction.count}
            </div>
        </div>
    )
})

Reaction.propTypes = propTypes
Reaction.defaultProps = defaultProps
export default Reaction

import { observable, runInAction, action, computed, makeObservable } from 'mobx'
import api from '../api'
import AppStore from './AppStore'
import _ from 'lodash'

/*
    Search for Rooms. This store will search (depending on the searchScope passed in)...
       - a user's managed rooms ('managed')
       - rooms in a department ('department')
       - public rooms ('public')
*/
class RoomsSearchStore {
    rooms = []
    count = 0
    isBusy = false
    error = null

    constructor() {
        makeObservable(this, {
            rooms: observable,
            count: observable,
            isBusy: observable,
            error: observable,
            results: computed,
            clearSearch: action
        })


    }

    get results() {
        if (!this.rooms.length) return []
        return this.rooms
    }

    search = _.debounce(async ({searchString, searchScope, order, page, limit, whereFilter, include }) => {
        //console.log({searchString, searchScope, order, page, limit, whereFilter, include })

        runInAction(() => {
            this.isBusy = true
            this.error = null
        })

        searchString = searchString || ''

        // each time on the first page, get a new total rooms count
        if (page === 1) {
            // clear rooms list
            runInAction(() => {
                this.rooms = []
            })

            // get total count of matching rooms (used for paging)
            await this.fetchSearchCount({
                searchScope,
                searchString,
                whereFilter
            })
        }

        const limitRecords = limit || 100
        const skip = (page) ? limitRecords * (page - 1) : 0

        const roomsCopy = [...this.rooms]
        let newRooms = []

        try {
            if (searchScope === 'managed') {
                // search only a user's managed rooms in the current department
                newRooms = await api.Routes.BlueUser.getManagedRooms(AppStore.userId, {
                    where: {
                        ...whereFilter,
                        //departmentId: AppStore.selectedDepartmentId, // removed to include rooms managed in other agencies
                        name: { like: `%${searchString}%` }
                    },
                    include,
                    order: order || 'name ASC',
                    limit: limitRecords,
                    skip
                })

            } else if (searchScope === 'members') {
                // search only a user's member rooms in the current department
                newRooms = await api.Routes.BlueUser.getRooms(AppStore.userId, {
                    where: {
                        ...whereFilter,
                        departmentId: AppStore.selectedDepartmentId,
                        name: { like: `%${searchString}%` }
                    },
                    include,
                    order: order || 'name ASC',
                    limit: limitRecords,
                    skip
                })

            } else if (searchScope === 'public') {
                // search all public rooms
                newRooms = await api.Routes.Room.getSearchPublic({
                    where: {
                        searchTerm: searchString,
                        ...whereFilter
                    },
                    include,
                    order: order || 'name ASC',
                    limit: limitRecords,
                    skip
                })

            } else {
                // search all rooms in the current department
                newRooms = await api.Department.getRooms(AppStore.selectedDepartmentId, {
                    where: {
                        ...whereFilter,
                        name: { like: `%${searchString}%` }
                    },
                    include,
                    order: order || 'name ASC',
                    limit: limitRecords,
                    skip
                })
            }

            // save results
            runInAction(() => {
                this.isBusy = false
                this.rooms = _.uniqBy([...roomsCopy, ...newRooms], 'id')
            })

            return newRooms

        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'RoomsSearchStore.search()',
                searchString,
                order,
                page,
                error: e.message || e
            })

            runInAction(() => {
                this.isBusy = false
                this.error = e
            })
        }

    }, 50, {leading: true})


    fetchSearchCount = async ({searchString, searchScope, whereFilter}) => {
        let count = 0

        try {
            if (searchScope === 'managed') {
                count = (await api.Routes.BlueUser.getManagedRoomsCount(AppStore.userId, {
                    ...whereFilter,
                    departmentId: AppStore.selectedDepartmentId,
                    name: { like: `%${searchString}%` } }
                )).count

            } else if (searchScope === 'public') {
                count = (await api.Routes.Room.getSearchPublicCount({
                    ...whereFilter,
                    searchTerm: searchString
                })).count

            } else {
                count = (await api.Routes.Department.getRoomsCount(AppStore.selectedDepartmentId, {
                    ...whereFilter,
                    and: [{
                        or: [
                            { name: { like: `%${searchString}%` } }
                        ]
                    }]
                })).count
            }

            runInAction(() => {
                this.count = count
            })
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'RoomSearchStore.fetchSearchCount()',
                error: e.message || e,
                searchScope,
                searchString
            })
        }
    }

    async clearSearch() {
        this.rooms.clear()
        this.count = 0
        this.isBusy = false
        this.error = null
        this.search.cancel()
    }
}


// Creates a single instance of this store
export default new RoomsSearchStore()


import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import AppStore from './AppStore'
import api from '../api'

// Keeps track of our login state and progress
class AuditsStore {
    audits = []
    isBusy = false
    error = null

    constructor() {
        makeObservable(this, {
            audits: observable,
            isBusy: observable,
            error: observable,
            getAuditPreview: action,
            runAudit: action,
            fetchAuditById: action,
            auditTypeOptions: computed
        })

        
    }

    getAuditById(id) {
        if (!id && !api.isProduction) throw new Error('GET AUSIT: Audit ID is required')
        // Look for user id locally
        const audit = this.audits.find(u => u.id === id)
        return audit
    }

    async getAuditPreview(departmentId, body) {
        if (!departmentId) {
            console.log('******ERROR: Must provide a departmentId in getAuditPreview()')
            return
        }

        this.error = null
        this.isBusy = true

        try {
            const preview = await api.Department.postAuditPreview(departmentId, body)

            runInAction(() => {
                this.isBusy = false
            })

            return preview
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({
                type: 'API post',
                message: 'AuditsStore.getAuditPreview()',
                error: e.message || e
            })
        }
    }

    async runAudit(departmentId, body) {
        if (!departmentId) {
            console.log('******ERROR: Must provide a departmentId in runAudit()')
            return
        }

        this.error = null
        this.isBusy = true

        try {
            const audit = await api.Department.postAudit(departmentId, body)

            return audit
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({
                type: 'API post',
                message: 'AuditsStore.runAudit()',
                error: e.message || e
            })
        }
    }

    async fetchAuditById(deptId, auditId) {
        this.error = null
        this.isBusy = true

        try {
            const audit = await api.Department.getAuditById(deptId, auditId)

            return audit
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API get', message: 'AuditsStore.fetchAuditById()', error: e.message || e, deptId, auditId})
        }
    }

    get auditTypeOptions() {
        return [
            {label: 'Random', value: 'random'},
            {label: 'Scheduled', value: 'scheduled'},
            {label: 'Private', value: 'private'}
        ]
    }
}

// Creates a single instance of this store
export default new AuditsStore()

import React, { useState, useEffect, useCallback, memo } from 'react'
import classNames from 'classnames'
// evertel
import api from '../api'
import { Icon, ProgressCircle } from '@evertel/web/ui'
import { AxiosProgressEvent } from 'axios'
import { APIDataMedia } from '@evertel/types'

interface ImageData {
    url: string;
    isBlob?: boolean;
    mimetype?: string;
    fileName?: string;
}

interface SecuredImageProps {
    data: APIDataMedia | ImageData
    onClick?: (data: APIDataMedia) => void
    postId?: number
    versionId?: string
    resizeMode?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
    width?: number | string
    height?: number | string
    style?: React.CSSProperties
    loadingIndicator?: boolean
    loadingIndicatorProps?: {
        radius: number
        stroke: number
        color: string
    };
    rounded?: boolean
    className?: string
}

const SecuredImage: React.FC<SecuredImageProps> = memo(({
    data,
    onClick,
    resizeMode = 'contain',
    width,
    height,
    style,
    versionId,
    loadingIndicator = false,
    loadingIndicatorProps = {
        radius: 50,
        stroke: 4,
        color: 'success'
    },
    rounded,
    className
}) => {
    const [isMounted, setIsMounted] = useState(false)
    const [source, setSource] = useState<string | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [progress, setProgress] = useState(0)

    const setImageSource = useCallback(() => {
        if (!(data as ImageData).isBlob && !data?.url?.startsWith('blob:')) {
            api.agent.get(`${data.url}?ver=${versionId}`, {
                responseType: 'arraybuffer',
                onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
                    if (isMounted && progressEvent.total !== undefined) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        setProgress(percentCompleted)
                    }
                }
            }).then((bufferResponse: ArrayBuffer) => {
                if (isMounted) {
                    // switched to Blob because when using createObjectURL the browser will
                    // cache the image for the life of the window. Base64 will not cache

                    const type = data?.mimetype || 'application/octet-stream'
                    const blob = new Blob([bufferResponse], { type })
                    const blobURL = URL.createObjectURL(blob)
                    setSource(blobURL)
                }
            }).catch(() => {
                if (isMounted) {
                    setError('Unable to load image')
                }
            })
        } else {
            if (isMounted) {
                // already a blob (new posts create blobs)
                setSource(data.url)
            }
        }
    }, [data.url, (data as ImageData).isBlob, data.mimetype, isMounted, versionId])

    useEffect(() => {
        setIsMounted(true)
        setImageSource()

        return () => {
            setIsMounted(false)
        }
    }, [setImageSource])

    const onError = () => {
        setSource(null)
        setError('Unable to load image')
    }

    const roundedStyle: React.CSSProperties | undefined = rounded ? { borderRadius: 5, overflow: 'hidden' } : undefined

    if (source) {
        return (
            <img
                src={source}
                alt={data?.fileName}
                onClick={onClick && !error ? () => onClick(data) : undefined}
                width={width || '100%'}
                height={height || '100%'}
                style={{ ...style, objectFit: resizeMode, ...roundedStyle }}
                className={classNames({ [className || '']: className, 'cursor-pointer': onClick })}
                onError={onError}
            />
        )
    } else {
        return (
            <div
                style={{
                    ...style,
                    cursor: 'default',
                    width: width || '100%',
                    height: height || '100%',
                    ...roundedStyle
                }}
                className="d-flex justify-content-center align-items-center p-3 overflow-hidden"
            >
                {error && (
                    <span className="text-center">
                        <Icon name="frown" style={{ fontSize: '2rem' }} /><br />
                        <em>{error}</em><br />
                        <small className="text-muted">({data?.fileName})</small>
                    </span>
                )}
                {loadingIndicator && !error && (
                    <ProgressCircle
                        progress={progress}
                        {...loadingIndicatorProps}
                    />
                )}
            </div>
        )
    }
})

export default SecuredImage
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Icon, Row, Text } from '@evertel/web/ui'
import { useContext } from 'react'
import { MessageWallContext } from '../MessageWallContext'

export type ToolBarOption = 'link'|'emoji-picker'|'urgent'|'attach'|'bold'|'italic'|'urgent'|'list'|'ol-list'|'block'|'strike'|'attach'

interface CreateMessageToolbarProps {
    isUrgent: boolean,
    onClickOption: (option: ToolBarOption) => void
}

const CreateMessageToolbar: React.FC<CreateMessageToolbarProps> = observer(({
    isUrgent,
    onClickOption
}) => {

    const { emojiPickerState } = useContext(MessageWallContext)

    return (
        <Row valign="center" >
            <button
                className="btn btn-ghost-muted"
                aria-label='Attach'
                onClick={() => onClickOption('attach')}
            >
                <Icon
                    name="paperclip"
                    //color={(showImgUploader || hasMedia()) ? 'secondary' : null}
                    size={18}
                    data-tooltip="Upload file"
                    data-tooltip-id="attach"
                    data-tooltip-pos="top"
                    aria-hidden
                />
            </button>
            <button
                className="btn btn-ghost-muted"
                aria-label='Emoji Picker'
                onClick={() => onClickOption('emoji-picker')}
            >
                <Icon
                    id="emoji-bttn"
                    name="smile"
                    size={18}
                    color={(emojiPickerState.visible) ? 'warning' : null}
                    data-tooltip={(!emojiPickerState.visible) ? 'Emoji' : null}
                    data-tooltip-id="emoji"
                    data-tooltip-pos="top"
                    aria-hidden
                />
            </button>
            <button
                className="btn btn-ghost-muted"
                aria-label={(isUrgent?'Unmark':'Mark') + 'Urgent'}
                onClick={() => onClickOption('urgent')}
            >
                <Icon
                    name="exclamation-triangle"
                    color={(isUrgent) ? 'danger' : null}
                    size={18}
                    onClick={() => onClickOption('urgent')}
                    data-tooltip="Mark as Urgent"
                    data-tooltip-id="urgent"
                    data-tooltip-pos="top"
                    aria-hidden
                />
            </button>
            {(isUrgent) &&
                <Text
                    size="small"
                    color="danger"
                    className="pl-1">
                    Urgent
                </Text>
            }
        </Row>
    )
})

export { CreateMessageToolbar }

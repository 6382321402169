import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { usePDF } from '@react-pdf/renderer'
// evertel
import { useContainer } from '@evertel/di'
import { Icon, FloatingMenu, MenuItem, MenuDivider } from '@evertel/web/ui'
import { APIDataDocument, APIDataDocumentSchema } from '@evertel/types'
import { DocumentPDF } from '../DocumentPDF'


interface MenuItemsProps {
    document: APIDataDocument,
    userId: number,
    departmentId: number,
    schema: APIDataDocumentSchema,
    canEdit: boolean,
    canShare: boolean,
    onEdit?: (documentId: number) => void,
    onShare?: (documentId: number) => void,
    onDelete?: (documentId: number) => void,
    onExportAsCSV: () => void,
    onToggleArchive: () => void
}

const DocumentMenu: React.FC<MenuItemsProps> = observer(({
    document,
    userId,
    departmentId,
    schema,
    canEdit,
    canShare,
    onEdit,
    onShare,
    onDelete,
    onExportAsCSV,
    onToggleArchive
}) => {

    const container = useContainer()

    const [pdf, updatePdf] = usePDF(
        { document:
            <DocumentPDF
                document={document}
                departmentId={departmentId}
                schema={schema} 
                user={userId}
                canEdit={canEdit}
                value={document.data}
                container={container}
            />
        }
    )

    useEffect(() => {
        updatePdf(<DocumentPDF
            document={document}
            departmentId={departmentId}
            schema={schema} 
            user={userId}
            canEdit={canEdit}
            value={document.data}
            container={container}
        />)
    }, [departmentId, document, document.data, schema, userId, canEdit])

    if (!canEdit && !canShare) return null

    return (
        <FloatingMenu
            label="Document Actions"
            renderLabel={() => (
                <>
                    <Icon
                        name="file-pen"
                        className="pr-2"
                    />
                    <Icon
                        type="solid"
                        name="caret-down"
                    />
                </>
            )}
            className="px-3"
        >
            {onEdit && (
                <MenuItem
                    label="Edit"
                    icon={{
                        name: 'edit',
                        className: 'mr-1',
                        color: (!canEdit || document?.isArchived) ? 'muted' : undefined
                    }}
                    onClick={canEdit ? () => onEdit(document?.id) : undefined}
                    disabled={!canEdit || document?.isArchived}
                    className={(!canEdit) ? 'text-muted' : undefined}
                />
            )}
            {onShare && (
                <MenuItem
                    label="Share"
                    icon={{
                        name: 'share',
                        className: 'mr-1',
                        color: (!canShare) ? 'muted' : undefined
                    }}
                    onClick={(canShare && !document?.isArchived) ? ( () => onShare(document?.id) ) : undefined}
                    disabled={(!canShare || document?.isArchived)}
                    className={(!canShare || document?.isArchived) ? 'text-muted' : undefined}
                />
            )}
            <MenuItem
                label="Export as PDF"
                icon={{
                    name: 'file-pdf',
                    className: 'mr-1'
                }}
                href={pdf.url}
                download={`${document.name || 'EverDoc'}-${schema.name}.pdf`}
            />
            <MenuItem
                label="Export as CSV"
                icon={{
                    name: 'file-csv',
                    className: 'mr-1'
                }}
                onClick={onExportAsCSV}
            />
            <MenuDivider />
            <MenuItem
                label={document?.isArchived ? 'Unarchive' : 'Archive'}
                icon={{
                    name: 'box-archive',
                    className: 'mr-1',
                    color: (!canEdit) ? 'muted' : undefined
                }}
                onClick={onToggleArchive}
                disabled={!canEdit}
                className={(!canEdit) ? 'text-muted' : undefined}
            />
            {onDelete && (
                <MenuItem
                    label="Delete Document"
                    icon={{
                        name: 'trash',
                        className: 'mr-1'
                    }}
                    onClick={() => onDelete(document?.id)}
                    className="text-danger"
                />
            )}
        </FloatingMenu>

    )
})

export { DocumentMenu }
DocumentMenu.displayName = 'DocumentMenu (ButtonDropdown)'

import { DIVIDER } from './common'

const resolveFields = (target: string, schema: any, path: string, found: any) => {

    //console.log('RESOLVE FIELDS')
    
    schema?.forEach((field: Record<string, any>) => {

        if (field.type === 'form') {
            path = (path) ? `${path}${DIVIDER}` : path

            found = resolveFields(
                target,
                field.items,
                `${path}${field.id}`,
                found
            )
        }

        if (field.type === 'list') {
            path = (path) ? `${path}${DIVIDER}` : path

            found = resolveFields(
                target,
                (field.item) ? [field.item] : field.items, // field.item was used in legacy schemas
                `${path}${field.id}[]`,
                found
            )
        }

        if (field.type === 'row' || field.type === 'container') { // container is a legacy type. no longer used.
            found = resolveFields(
                target,
                field.items,
                path,
                found
            )
        }

        if (field.type === target) {
            path = (path) ? `${path}${DIVIDER}` : path

            found = [
                ...found,
                `${path}${field.id}`
            ]
        }
    })

    return found
}

export {
    resolveFields
}


import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { DisplayThread, ThreadStore } from '@evertel/stores'
import { SessionState } from '@evertel/session'
import { BlueUserStore } from '@evertel/blue-user'
import { Api } from '@evertel/api'

class ThreadController {
    threadId = 0
    joinRequestsCount = 0

    constructor(
        private api: Api,
        private threadStore: ThreadStore,
        private session: SessionState,
        private userStore: BlueUserStore
    ) {
        makeAutoObservable(this)
    }

    init = (threadId: number) => {
        if (!threadId) return
        
        this.threadId = threadId

        // background fetch as we already have
        // the thread in the store
        this.fetchThread()
    }

    fetchThread = async () => {
        const thread = (await this.api.Routes.Thread.getById(this.threadId, {
            include: {
                relation: 'users',
                scope: {
                    fields: ['id', 'firstName', 'lastName', 'publicImage', 'publicMedia', 'isBot', 'isAdmin']
                }
            }
        })) as DisplayThread

        runInAction(() => {
            thread.userIds = thread.users?.map((u: any) => u.id)
            this.threadStore.update(thread)
            this.userStore.update(thread.users)
        })
    }

    closeThread = async () => {
        await this.api.Routes.Thread.delById(this.threadId)

        runInAction(() => {
            this.threadStore.deleteById(this.threadId)
        })
    }

    get id(): number {
        return this.threadId
    }
    
    get thread() {
        return this.threadStore.findById(this.threadId)
    }

    get users() {
        const userIds = (this.threadStore.findById(this.threadId) as any)?.userIds ?? []
        return this.userStore.findByIds(userIds)
    }

    get usersNotMe() {
        return this.users?.filter(u => u.id !== this.session.currentUserId)
    }

    get isJustMe() {
        return this.users?.length === 1 && this.users[0].id === this.session.currentUserId
    }

    get canLeave() {
        return !this.isJustMe && !this.isBotThread
    }

    get name() {
        if (this.isJustMe) {
            return `${this.users[0].firstName} ${this.users[0].lastName}`
        } else if (this.usersNotMe?.length === 1) {
            return `${this.usersNotMe[0].firstName} ${this.usersNotMe[0].lastName}`
        } else {
            const names = this.usersNotMe?.map(u => u.firstName?.charAt(0) + '. ' + u.lastName)
            return names?.join(', ')
        }
    }

    get isBotThread() {
        const meta = this.thread?.meta as any
        return meta?.isBotThread ?? false
    }
}

decorate(injectable(), ThreadController)
decorate(inject(Api), ThreadController, 0)
decorate(inject(ThreadStore), ThreadController, 1)
decorate(inject(SessionState), ThreadController, 2)
decorate(inject(BlueUserStore), ThreadController, 3)

export { ThreadController }
import React, {
    createContext,
    forwardRef,
    HTMLAttributes,
    useEffect,
    useRef,
    useState
} from 'react'
import { Transition } from 'react-transition-group'
import classNames from 'classnames'

//evertel
import { Colors } from '../../prop-types'
import { useForkedRef } from '@evertel/hooks'
import ToasterClose from './ToasterClose'

export interface ToastProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
    animation?: 'fade'|'slide'
    autohide?: boolean
    className?: string
    color?: Colors
    /** Delay hiding the toast (ms). */
    delay?: number
    index?: number
    key?: number
    /** Callback fired when the component requests to be closed.*/
    onClose?: (index: number|null) => void
    /** Callback fired when the component requests to be shown.*/
    onShow?: (index: number|null) => void
    visible?: boolean
    closeButton?: boolean,
    onClick?: () => void
}
  
interface ContextProps extends ToastProps {
    visible?: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean|undefined>>
}
  
export const ToastContext = createContext({} as ContextProps)
  
const Toast = forwardRef<HTMLDivElement, ToastProps>(({
    children,
    animation = 'fade',
    autohide = true,
    className,
    color,
    delay = 5000,
    index,
    key,
    visible = false,
    onClose,
    onShow,
    closeButton = true,
    onClick,
    ...otherProps
}, ref) => {

    const toastRef = useRef()
    const forkedRef = useForkedRef(ref, toastRef)
    const timeout = useRef<number>()

    const [_visible, setVisible] = useState(false)

    useEffect(() => {
        setVisible(visible)
    }, [visible])

    const contextValues = {
        visible: _visible,
        setVisible
    }

    // triggered on mount and destroy
    useEffect(() => () => clearTimeout(timeout.current), [])

    useEffect(() => {
        _autohide()
    }, [_visible])
  
    const _autohide = () => {
        if (autohide) {
            clearTimeout(timeout.current)
            timeout.current = window.setTimeout(() => {
                setVisible(false)
            }, delay)
        }
    }

    const _className = classNames(
        'toast',
        {
            'slide-in': animation === 'slide',
            'fade': animation === 'fade',
            [`bg-${color}`]: color,
            'border-0': color
        },
        className
    )

    const getTransitionClass = (state: string) => {
        return (state === 'entering')
            ? 'showing'
            : (state === 'entered')
                ? 'show'
                : (state === 'exiting')
                    ? 'fade'
                    : 'fade'
    }
  
    const wrapperStyle = {
        position: 'absolute',
        right: 8,
        top: 4,
        padding: '4px 10px',
        border: 'none',
        color: '#FFF'
    }
  
    return (
        <Transition
            in={_visible}
            nodeRef={toastRef}
            onEnter={() => onShow && onShow((index) ? index : null)}
            onExited={() => onClose && onClose((index) ? index : null)}
            timeout={250}
            unmountOnExit
        >
            {(state) => {
                const transitionClass = getTransitionClass(state)
                return (
                    <ToastContext.Provider value={contextValues}>
                        <div
                            ref={forkedRef}
                            key={key}
                            className={classNames(_className, transitionClass)}
                            aria-live="assertive"
                            aria-atomic="true"
                            role="alert"
                            onMouseEnter={() => clearTimeout(timeout.current)}
                            onMouseLeave={() => _autohide()}
                            {...otherProps}
                        >
                            {onClick ? (
                                <button
                                    className={`btn toast-full-button p-0 w-100 text-start btn-${color}`}
                                    onClick={() => {
                                        onClick()
                                        setVisible(false)
                                    }}
                                >
                                    {children}
                                </button>
                            ) : (
                                children
                            )}

                            {(closeButton) &&
                                <ToasterClose style={wrapperStyle} />
                            }
                        </div>
                    </ToastContext.Provider>
                )
            }}
        </Transition>
    )
})
  
export default Toast
Toast.displayName = 'Toast'

import { observable, action, runInAction, makeObservable } from 'mobx'
import UsersStore from '../UsersStore'
import AppStore from '../AppStore'
import api from '../../api'
import _ from 'lodash'


// Keeps track of our login state and progress
class UsersStoreAdmin {
    users = []
    count = 0
    isBusy = false
    error = null

    constructor() {
        makeObservable(this, {
            users: observable,
            count: observable,
            isBusy: observable,
            error: observable,
            search: action,
            clearSearch: action,
            getUsersCount: action,
            fetchUser: action,
            addUserToStore: action
        })

        
    }

    // Search contacts api then updates this.resultsIds observable, debounced
    search = _.debounce(async (filter) => {
        this.isBusy = true
        this.error = null
        let users = []

        try {
            // get users via search contacts
            users = await api.BlueUser.getUsersBySearch(filter)

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })
            AppStore.logError({
                type: 'API get',
                message: 'UsersStoreAdmin.search()',
                error: e.message || e,
                filter
            })
        }

        // save results
        runInAction(() => {
            this.isBusy = false
            this.users = users
        })
    }, 50, {leading: true})

    async clearSearch() {
        //this.resultsIds.clear()
        this.users.clear()
        this.isBusy = false
        this.error = null
        this.search.cancel()
    }

    getUsersCount = async (where) => {
        try {
            const count = (await api.BlueUser.getUsersCountBySearch(where)).count

            runInAction(() => {
                this.count = count
            })
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'UsersStoreAdmin.getUsersCount()',
                error: e.message || e,
                where
            })
        }
    }

    async fetchUser(id) {
        const newUser = await api.BlueUser.getById(id, {
            include: [{
                relation: 'publicMedia'
            }, {
                relation: 'departmentsAccess',
                scope: {
                    include: [{
                        relation: 'assignment'
                    }, {
                        relation: 'position'
                    }, {
                        relation: 'department',
                        scope: {
                            include: ['publicMedia']
                        }
                    }]
                }
            }]
        })

        return newUser
    }

    addUserToStore = (user) => {
        UsersStore.updateFromData(user)
    }
}

export default new UsersStoreAdmin()

import React, { forwardRef } from 'react'
import { Label } from '@evertel/web/ui'
import classNames from 'classnames'

export type CheckboxChangeProps = {
    name?: string,
    value?: string|number|readonly string[],
    checked: boolean
}

interface CheckboxProps {
    id?: string,
    name?: string,
    value?: string|number|readonly string[],
    checked?: boolean,
    onChange?: ({name, value, checked}: CheckboxChangeProps) => void,
    disabled?: boolean,
    color?: string,
    label?: string,
    className?: string
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
    id,
    name,
    value,
    checked = false,
    onChange,
    disabled = false,
    color,
    label,
    className,
    ...otherProps
}, ref) => {

    const _inputClassName = classNames(
        {
            [`accent-${color}`]: color
        },
        className
    )

    return (
        <div
            ref={ref}
            className={classNames('form-checkbox', className)}
            {...otherProps}>
            <input
                {...(id && { id: id })}
                {...(name && { name: name })}
                className={_inputClassName}
                type="checkbox"
                value={value || ''}
                disabled={disabled}
                checked={checked}
                onChange={() => onChange && onChange({name, value, checked: !checked})}
            />
            {(label) &&
                <Label
                    text={label}
                    className="mx-2 p-0"
                    {...(id && { htmlFor: id })}
                />
            }
        </div>
    )
})

export { Checkbox }

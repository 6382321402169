import React, { useState, useEffect, ReactNode, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
// evertel
import { DocumentSearchByRoomController } from '@evertel/document'
import { formatForSelect } from '@evertel/utils'
import { APIDataDocument } from '@evertel/types'
import { useService } from '@evertel/di'
import { MultilinePlaceholder, Row, CustomAsyncPaginate } from '@evertel/web/ui'
import { MultiValue, SingleValue } from 'react-select'
import { DocumentTile } from '@evertel/web/document'

interface RoomSearchSelectProps {
    roomId: number,
    selected?: APIDataDocument[],
    isMulti?: boolean,
    isDisabled?: boolean,
    placeholder?: string,
    searchFilter?: any,
    maxMenuHeight?: number|string,
    noOptionsMessage?: string,
    menuIsOpen?: boolean,
    className?: string,
    whereFilter?: any,
    onEndReachedThreshold?: number,
    components?: ReactNode
    onSelect: (room: APIDataDocument) => void
}


const RoomDocumentsSearchSelect: React.FC<RoomSearchSelectProps> = observer(({
    roomId,
    selected = [],
    isMulti = false,
    isDisabled,
    menuIsOpen,
    placeholder = 'Search...',
    noOptionsMessage = 'No EverDocs found',
    onSelect,
    whereFilter,
    maxMenuHeight,
    className,
    components,
    onEndReachedThreshold = 80,
    ...otherProps
}) => {

    const documentSearchByRoomController = useService(DocumentSearchByRoomController, [roomId])

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)

        if (!roomId) return

        documentSearchByRoomController.init(roomId)
        //needs to render once with loading true to reload async paginate
        setTimeout(() => { setIsLoading(false)}, 1)

    }, [roomId])

    //This gets triggered if sent as onChange, but not in the RoomMediaSearchSelect for some reason
    //this is redundant because onSelect is also sent for the DocumentTile onClick event
    // const onSelectOption = (document: MultiValue<APIDataDocument>|SingleValue<APIDataDocument>) => {
    //     if (onSelect) onSelect(document as APIDataDocument)
    // }

    const search = useCallback( async (
        searchString: string,
        loadedOptions: APIDataDocument[],
        { page }: { page: number }
    ) => {
        documentSearchByRoomController.setSearchTerm(searchString)
        documentSearchByRoomController.setPage(page)

        try {
            await documentSearchByRoomController.search()

        } catch (error) {
            console.error('ERROR: RoomDocumentsSearchSelect search():', error)
        }

        // grab results and format them for the Select
        const results = formatForSelect(documentSearchByRoomController.documentsLatestFetch, 'id', 'id')
        // const hasMore = documentSearchByRoomController.documents?.length < documentSearchByRoomController.documentsCount
        const hasMore = (documentSearchByRoomController.documentsLatestFetch.length >= documentSearchByRoomController.limit)

        return {
            options: results || [],
            hasMore,
            additional: {
                page: page + 1 || 1
            }
        }
    }, [roomId])

    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number): boolean => {

        const maxPixelThreshold = 300
        const percentThreshold = 100 - onEndReachedThreshold

        const pxFromBottom = scrollHeight - clientHeight - scrollTop
        const percentThresholdFromBottomInPx = ((percentThreshold / 100) * (scrollHeight - clientHeight))
    
        // Determine the threshold to use (whichever is smaller)
        const thresholdToUse = Math.min(percentThresholdFromBottomInPx, maxPixelThreshold)

        // console.log('px from bottom / threshold, result', pxFromBottom, thresholdToUse, pxFromBottom <= thresholdToUse)    
        return pxFromBottom <= thresholdToUse
    }

    if (isLoading) {
        return (
            <MultilinePlaceholder sections={2}/>
        )
    }

    return (
        <CustomAsyncPaginate
            name="documents"
            keyName={roomId}
            searchOnly={true}
            loadOptions={search}
            debounceTimeout={300}
            additional={{
                page: 1
            }}
            defaultOptions
            shouldLoadMore={shouldLoadMore}
            value={selected}
            maxMenuHeight={maxMenuHeight}
            isMulti={isMulti}
            menuIsOpen={menuIsOpen}
            isDisabled={isDisabled}
            // onChange={onSelectOption}
            noOptionsMessage={() => noOptionsMessage}
            placeholder={placeholder}
            className={className}
            components={components || { Option: Document }}
            callbacks={{onSelect}}
            {...otherProps}
        />
    )
})

interface DocumentProps {
    data: APIDataDocument,
    innerProps: any,
    selectProps: any
}

const Document: React.FC<DocumentProps> = observer(({
    data,
    innerProps,
    selectProps
}) => {
    //console.log(data, innerProps, selectProps, otherProps)

    const onSelect = selectProps?.callbacks?.onSelect

    return (
        <Row {...innerProps}>
            <DocumentTile
                documentId={data.id as number}
                departmentId={data.departmentId as number}
                onClick={() => onSelect && onSelect(data)}
                className="w-100 mb-2"
            />
        </Row>
    )
})

export {
    RoomDocumentsSearchSelect
}

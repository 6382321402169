import { decorate, inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import { Api } from '@evertel/api'
import { RoomStore, UserSettingsStore } from '@evertel/stores'
import { SessionState } from '@evertel/session'

class RoomSettingsController {
    roomId = 0

    constructor(
        private api: Api,
        private roomStore: RoomStore,
        private session: SessionState,
        private userSettingsStore: UserSettingsStore
    ) {
        makeAutoObservable(this)
    }

    init(roomId: number) {
        this.roomId = roomId

        if (roomId && !this.room) {
            // this is only here as a backup. This should rarely
            // be needed. The room should always already be in the RoomStore.
            this.fetchRoom()
        }
    }

    fetchRoom = async () => {
        const room = await this.api.Routes.Room.getById(this.roomId)

        runInAction(() => {
            this.roomStore.update(room)
        })
    }

    update = async (data) => {
        const room = await this.api.Routes.Room.putById(this.roomId, data)

        runInAction(() => {
            this.roomStore.update(room)
        })
    }

    updateUserRoomNotificationSettings = async (enabled: boolean) => {
        const userSettings = await this.api.Routes.BlueUser.putSettings(this.session.currentUserId, {
            roomNotifications: {
                [this.roomId]: {
                    roomMessage: {
                        enabled
                    }
                }
            }
        }) as any

        runInAction(() => {
            this.userSettingsStore.update({id: this.session.currentUserId, settings: userSettings})
        })
    }

    get room() {
        return this.roomStore.findById(this.roomId)
    }

    get userRoomNotificationsEnabled() {
        const settings = this.userSettingsStore.findById(this.session.currentUserId)?.settings as any
        
        if (!settings || !settings.roomNotifications || !settings.roomNotifications[this.roomId]) return true // hasn't been set. default to true

        return settings?.roomNotifications[this.roomId]?.roomMessage?.enabled
    }
}

decorate(injectable(), RoomSettingsController)
decorate(inject(Api), RoomSettingsController, 0)
decorate(inject(RoomStore), RoomSettingsController, 1)
decorate(inject(SessionState), RoomSettingsController, 2)
decorate(inject(UserSettingsStore), RoomSettingsController, 3)

export { RoomSettingsController }

import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { DepartmentStore } from '../store'
import { Api } from '@evertel/api'
import { APIDataDepartment } from '@evertel/types'
import moment from 'moment'


export interface DepartmentCapacities {
    verifiedMemberCapacity: number,
    verifiedMembersCount: number,
    verifiedMembersAvailable: number
}

export type DepartmentStatus = 'active' | 'in_trial' | 'trial_expired' | 'churned' | undefined

/**
 * Determines the trial or subscription status of a department.
 * 
 * @param department - The department object containing trial and subscription information.
 * @returns The status of the department.
 */
export function getDepartmentTrialStatus(department: APIDataDepartment): DepartmentStatus {
    const today = moment()
    const expirationDate = department.subscriptionExpiresDate ? moment(department.subscriptionExpiresDate) : null
    const hasExpired = !expirationDate ?  true : today.isAfter(expirationDate, 'day')
  
    if (department.isTrial) {
        return hasExpired ? 'trial_expired' : 'in_trial'
    }
  
    if (expirationDate) {
        return hasExpired ? 'churned' : 'active'
    }
  
    // Handle case where there's no trial and no expiration date
    return undefined
}


class DepartmentController {
    departmentId = 0
    capacities: DepartmentCapacities = undefined

    constructor(
        private api: Api,
        private departmentStore: DepartmentStore
    ) {
        makeAutoObservable(this)
    }

    init(departmentId: number, filter?: any) {
        if (!departmentId) return
        
        this.departmentId = departmentId
        
        //TODO: this.department should return undefined if empty, but it used to return {...dept, publicMedia} so it
        //never returned undefined even when it was. publicMedia was removed but returning undefined then broke
        //too much stuff where stuff like dept.id didn't use dept?.id
        //need to make it return undefined and find/fix all those places
        if (!this.department || !this.department?.id) {
            //fetch only if it doesn't exist already
            this.departmentStore.update({
                id: departmentId,
                name: ''
            })
            this.fetchDepartment(filter).catch((e) => {
                this.departmentStore.deleteById(this.departmentId)
            })
        }
    }

    fetchDepartment = async (filter: any) => {
        try {
            const department = await this.api.Routes.Department.getById(this.departmentId, {
                ...filter,
                include: [ 'publicMedia']
            })

            this.departmentStore.update(department as APIDataDepartment)

        } catch (e) {
            /** nothing */
        }


    }

    fetchAvailableCapacities = async () => {
        const capacities = await this.api.Routes.Department.getAvailableCapacities(this.departmentId) as DepartmentCapacities

        runInAction(() => {
            this.capacities = capacities
        })
    }

    get id() {
        return this.departmentId
    }
    
    get department(): APIDataDepartment {
        const dept = this.departmentStore.findById(this.departmentId)
        return dept || {}
    }

    get shortName() {
        if (this.department?.shortName) return this.department.shortName
        return this.department.name?.replace(/\(.*?\)/g, '').split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')
    }

}

decorate(injectable(), DepartmentController)
decorate(inject(Api), DepartmentController, 0)
decorate(inject(DepartmentStore), DepartmentController, 1)

export { DepartmentController }
import React from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

// evertel
import { Icon, Row } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DocumentTile, useDocumentModal } from '@evertel/web/document'
import { memo } from 'react'
import { MessageBodyProps } from '../../../types'

const EventMessageInlay: React.FC<MessageBodyProps> = observer(({
    message
}) => {

    const session = useService(SessionState, [])
    const { openDocumentModal } = useDocumentModal()

    const eventType = (message.meta as { eventType: string })?.eventType
    const documentId = (message.meta as { documentId?: number })?.documentId
    const departmentId = session.selectedDepartmentId


    const renderInlay = () => {
        switch (eventType) {
            case 'document_updated':
            case 'document_added':
                return (
                    <div className='d-flex flex-column'>
                        <div className='d-flex mb-2'>
                            {message.isUrgent && (
                                <span className="urgent-icon" >
                                    <Icon
                                        name='triangle-exclamation'
                                        color='white'
                                    />
                                </span>
                            )}

                            <div
                                dangerouslySetInnerHTML={{ __html: message?.text }}
                                className={classNames('post-content', {'urgent': message.isUrgent} )}
                                style={{ display: 'inline-block' }}
                            />
                        </div>
                        <DocumentTile
                            documentId={documentId}
                            departmentId={departmentId}
                            onClick={openDocumentModal}
                            className="m-0"
                        />
                    </div>
                )
            case 'document_removed':
                return (
                    <div
                        dangerouslySetInnerHTML={{ __html: 'EverDoc access has been removed.' }}
                        className="post-content"
                    />
                )
            case 'screen_shot':
                return <strong>{message?.text}</strong>
            case 'updated_room_settings':
            case 'thread_started':
                return (
                    <div
                        dangerouslySetInnerHTML={{ __html: message?.text }}
                        className="post-content"
                    />
                )
            default:
                return null
        }
    }

    return (
        <Row className={classNames('event-message-inlay', {urgent: message?.isUrgent})}>
            {renderInlay()}
        </Row>
    )
})

export const MemoizedEventMessageInlay = memo(EventMessageInlay)

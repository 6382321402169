import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
// evertel
import { ServiceProvider } from '@evertel/service'
import { Document, WidgetsProvider } from '@evertel/schema-parser'
import { useService } from '@evertel/di'
import { Text, Badge, Card, CardHeader, CardBody, CardFooter, CardHeaderActions } from '@evertel/web/ui'
import { DocumentACLsController, DocumentViewController } from '@evertel/document'
import { ReadWidgets } from './schema-widgets'
import { DocumentFooter, DocumentMenu, DocumentPlaceholder } from './elements'
import { APIDataDocumentSchema } from '@evertel/types'
import { SessionState } from '@evertel/session'
import { exportDocumentAsCSV } from '../utils'
interface DocumentFullViewProps {
    documentId: number,
    onEdit: (documentId: number) => void,
    onShare: (documentId: number) => void
}

const DocumentFullView: React.FC<DocumentFullViewProps> = observer(({
    documentId,
    onEdit,
    onShare
}) => {

    const documentViewController = useService(DocumentViewController, [])
    const documentACLController = useService(DocumentACLsController, [])
    const session = useService(SessionState, [])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            if (!documentId) return

            setIsLoading(true)

            try {
                await Promise.all([
                    documentViewController.load(
                        documentId
                    ),
                    documentACLController.init(
                        documentId,
                        session.currentUserId
                    )
                ])

            } catch (error) {
                console.error(error.message)
            }

            setIsLoading(false)
        })()

    }, [documentId, session.currentUserId])

    const toggleArchive = async () => {
        try {
            documentViewController.setArchive(!documentViewController.document?.isArchived)

        } catch (error: any) {
            console.error(error.message)
        }
    }

    const exportCSV = async () => {
        try {
            const blob = await documentViewController.downloadCSV(documentId)
            exportDocumentAsCSV(blob, document.name)

        } catch (error: any) {
            console.error(error.message)
        }
    }

    if (isLoading || !documentViewController.document) {
        return <DocumentPlaceholder />
    }

    const document = documentViewController.document
    const schema = documentViewController.documentSchema
    const canEdit = documentACLController.canEdit
    const canShare = documentACLController.canShare

    //console.log('SCHEMA', toJS(controller.documentSchema?.schema))
    //console.log('DATA', toJS(controller.document?.data))

    return (
        <Card>
            <CardHeader>
                <Text
                    tag="h3"
                    bold
                    className="float-left">
                    {document?.name}
                </Text>
                {(document?.isArchived) &&
                    <Badge
                        color="gray"
                        className="ml-2">
                        Archived
                    </Badge>
                }
                <CardHeaderActions>
                    <DocumentMenu
                        document={document}
                        userId={session.currentUserId}
                        departmentId={session.selectedDepartmentId}
                        schema={schema as APIDataDocumentSchema}
                        canEdit={canEdit}
                        canShare={canShare}
                        onEdit={onEdit}
                        onShare={onShare}
                        onToggleArchive={toggleArchive}
                        onExportAsCSV={() => exportCSV()}
                    />
                </CardHeaderActions>
            </CardHeader>
            <CardBody>
                <ServiceProvider services={[
                    {
                        id: 'meta',
                        value: {
                            user: session.currentUserId,
                            department: session.selectedDepartmentId,
                            document: document.id,
                            documentSchema: schema?.id
                        }
                    }]}>
                    <WidgetsProvider value={ReadWidgets}>
                        <Document
                            documentId={documentId}
                            layout="full"
                            schema={schema?.schema}
                            schemaId={schema?.id}
                            value={document.data}
                        />
                    </WidgetsProvider>
                </ServiceProvider>
            </CardBody>
            <CardFooter>
                <DocumentFooter
                    document={document}
                    schema={schema as APIDataDocumentSchema}
                    revisionId={documentViewController.documentSchemaRevisionId}
                />
            </CardFooter>
        </Card>
    )
})

export {
    DocumentFullView
}

import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

export interface SlidePanelBodyProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

const SlidePanelBody = forwardRef<HTMLDivElement, SlidePanelBodyProps>(({
    children,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('slidepanel-body', className)

    return (
      <div
        ref={ref}
        className={_className}
        {...otherProps}>
        {children}
      </div>
    )
})

export { SlidePanelBody }
SlidePanelBody.displayName = 'SlidePanelBody'

import { forwardRef } from 'react'
import classNames from 'classnames'
import { Icon } from './Icon'
import { Button } from './Button'
import { ButtonProps } from './Button'

const CloseButton = forwardRef<HTMLButtonElement|HTMLAnchorElement, ButtonProps>(({
    className,
    ...otherProps
}, ref) => {

    const _className = classNames(
        className
    )

    return (
        <Button
            ref={ref}
            color="gray500"
            ghost
            aria-label="Close"
            {...otherProps}
        >
            <Icon
                name="xmark"
                className={_className}
            />
        </Button>
    )
})

export { CloseButton }

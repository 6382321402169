import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import GoogleMapReact from 'google-map-react'
// evertel
import {
    InfoBox,
    Button,
    Col,
    Input,
    Modal,
    ModalBody,
    Row,
    Text,
    ModalFooter,
    useUI,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    StateSelect,
    Label,
    Icon,
    DepartmentTypesSelect
} from '@evertel/web/ui'
import { RoomContext } from '../../RoomContext'
import { states as statesList, departmentTypes as departmentTypesList } from '@evertel/constants'
import { useService } from '@evertel/di'
import { ICRoomSettingsController, RuleTypes } from '@evertel/room'

const GOOGLE_API_KEY = 'AIzaSyChZxzna4W6pmBoe3PFibh9saY_DOU1T3Q'

type RadiusPosition = {
    radius: number,
    point: {
        lat: number,
        lng: number
    }
}

interface ICRoomModalProps {
    visible: boolean,
    onClose: () => void
}


const ICRoomModal: React.FC<ICRoomModalProps> = observer(({
    visible,
    onClose
}) => {

    const tabs = [
        {name: 'radius', display: 'By Radius', component: ByRadius},
        {name: 'state', display: 'By State', component: ByState},
        {name: 'ask', display: 'By Request Only', component: ByRequest}
    ]

    const { roomController } = useContext(RoomContext)
    const icRoomSettingsController = useService(ICRoomSettingsController, [roomController?.roomId])

    const { addToast } = useUI()

    const [activeTab, setActiveTab] = useState(tabs[0].name)
    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    // holds one object with all the local data that's been set in each tab
    const [ruleData, setRuleData] = useState(null)

    const defaultPosition = {
        radius: 25,
        point: {
            lat: 37.0902,
            lng: -95.7129
        }
    }

    useEffect(() => {
        if (!roomController?.roomId || !visible) return

        (async () => {
            setIsLoading(true)
            try {
                await icRoomSettingsController.init(roomController.roomId)
            } catch (error) {
                addToast({
                    color: 'danger',
                    message: error.message
                })
            }
            setIsLoading(false)
        })()
    }, [roomController.roomId, visible])

    const onChange = (dataType: RuleTypes, data: any) => {
        setRuleData((prev) => ({
            ...prev,
            [dataType]: data
        }))
    }

    const onSave = async () => {
        setIsSaving(true)

        if (ruleData) {
            switch (activeTab) {
                case 'radius':
                    try {
                        await icRoomSettingsController.deleteJoinRequestRuleByType('departmentStates')
                        await icRoomSettingsController.createOrUpdateJoinRequestRule('departmentDistanceFromPoint', ruleData.departmentDistanceFromPoint)
                        await icRoomSettingsController.createOrUpdateJoinRequestRule('departmentTypes', ruleData.departmentTypes)
                    } catch (error) {
                        addToast({
                            color: 'danger',
                            title: 'Oops!',
                            message: error.message
                        })
                    }
                    break
                case 'state':
                    try {
                        await icRoomSettingsController.deleteJoinRequestRuleByType('departmentDistanceFromPoint')
                        await icRoomSettingsController.createOrUpdateJoinRequestRule('departmentStates', ruleData.departmentStates)
                        await icRoomSettingsController.createOrUpdateJoinRequestRule('departmentTypes', ruleData.departmentTypes)
                    } catch (error) {
                        addToast({
                            color: 'danger',
                            title: 'Oops!',
                            message: error.message
                        })
                    }
                    break
                case 'ask':
                    try {
                        await icRoomSettingsController.deleteJoinRequestRuleByType('departmentDistanceFromPoint')
                        await icRoomSettingsController.deleteJoinRequestRuleByType('departmentStates')
                        await icRoomSettingsController.deleteJoinRequestRuleByType('departmentTypes')
                    } catch (error) {
                        addToast({
                            color: 'danger',
                            title: 'Oops!',
                            message: error.message
                        })
                    }
                    break
                default:
                    break
            }
        }

        setIsSaving(false)
        onClose()
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            size="lg">
            <ModalHeader
                title="Set the Access Criteria for this room"
            />
            <ModalBody className="p-4 mb-4">
                {(isLoading)
                    ?
                    <Text>Loading...</Text>
                    :
                    <>
                        <Nav
                            variant="underline"
                            role="tablist">
                            {tabs.map((tab, idx) =>
                                <NavItem key={idx}>
                                    <NavLink
                                        active={activeTab === tab.name}
                                        onClick={() => setActiveTab(tab.name)}>
                                        <Icon
                                            name={(activeTab === tab.name) ? 'check-circle' : 'empty-set'}
                                            color={(activeTab === tab.name) ? 'success' : 'light'}
                                            type={(activeTab === tab.name) ? 'solid' : 'light'}
                                            className="mr-2"
                                        />
                                        <Text>
                                            {tab.display}
                                        </Text>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>

                        {/* tab content ----------------------------------------------------------------------------------------------- */}
                        <TabContent className="mt-4">
                            {tabs.map((tab, idx) =>
                                <TabPane
                                    key={idx}
                                    role="tabpanel"
                                    visible={activeTab === tab.name}>
                                    <tab.component
                                        initialPosition={icRoomSettingsController.activeRadiusRule?.value as RadiusPosition || defaultPosition}
                                        initialStates={icRoomSettingsController.activeStatesRule?.value as string[] || []}
                                        initialDepartmentTypes={icRoomSettingsController.activeDepartmentTypesRule?.value as string[] || []}
                                        onChange={onChange}
                                    />
                                </TabPane>
                            )}
                        </TabContent>
                    </>
                }                
            </ModalBody>
            <ModalFooter className="text-right bg-light">
                <Button
                    color='muted'
                    outline
                    className="mr-2"
                    onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    color='success'
                    loading={isSaving}
                    onClick={onSave}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    )
})


interface TabContentProps {
    initialPosition?: RadiusPosition,
    initialStates?: string[],
    initialDepartmentTypes?: string[],
    onChange: (dataType: RuleTypes, data: any) => void
}

// ByState ----------------------------------------------------------------------------------------------------------------------------------------------------

const ByState: React.FC<TabContentProps> = observer(({
    initialStates,
    initialDepartmentTypes,
    onChange
}) => {

    const normalizeStates = (states) => {
        return states?.map((state) => {
            return statesList.find(({value}) => {
                return value === state
            })
        })
    }

    const [states, setStates] = useState(normalizeStates(initialStates))
    const [departmentTypes, setDepartmentTypes] = useState(initialDepartmentTypes)

    useEffect(() => {
        onChange('departmentTypes', departmentTypes)
    }, [departmentTypes])

    useEffect(() => {
        onChange('departmentStates', states?.map(s => s.value))
    }, [states])

    return (
        <Col className="pl-0 pr-0">
            <InfoBox
                color="warning"
                className="mb-3">
                <Text bold>By selecting states below, you are allowing any Evertel user in any agency within the states selected to be able to join this room.</Text> 
                This is useful for statewide deployments or large-scale crisis response where all responding agencies need access to a single "digital
                Incident Command" space.
            </InfoBox>
            <Label
                text="Allowed States"
            />
            <StateSelect
                selected={states}
                isMulti={true}
                onSelect={(states) => {
                    setStates(states)
                }}
            />
            <Row className="mt-4">
                <Advanced
                    selectedDepartmentTypes={initialDepartmentTypes}
                    onChange={setDepartmentTypes}
                />
            </Row>
        </Col>
    )
})


// ByRadius ----------------------------------------------------------------------------------------------------------------------------------------------------

const ByRadius: React.FC<TabContentProps> = observer(({
    initialPosition,
    initialDepartmentTypes,
    onChange
}) => {

    const [departmentTypes, setDepartmentTypes] = useState(initialDepartmentTypes)
    const [position, setPosition] = useState(initialPosition)
    const [zoom, setZoom] = useState(null)
    const [drawings, setDrawings] = useState([])
    const [map, setMap] = useState({
        instance: null,
        api: null
    })

    useEffect(() => {
        onChange('departmentTypes', departmentTypes)
    }, [departmentTypes])

    useEffect(() => {
        onChange('departmentDistanceFromPoint', position)
    }, [position])

    useEffect(() => {
        if (map.instance === null || map.api === null) {
            return undefined
        }

        const circle = new map.api.Circle({
            map: map.instance,
            center: {
                lat: position.point.lat,
                lng: position.point.lng
            },
            radius: position.radius * 1609.344, // convert miles to meters
            strokeColor: 'red',
            strokeWeight: 1,
            fillOpacity: 0,
            zIndex: 1
        })

        const marker = new map.api.Marker({
            map: map.instance,
            position: {
                lat: position.point.lat,
                lng: position.point?.lng
            },
            draggable: true
        })

        marker.addListener('dragend', (e) => {
            setPosition((prev) => {
                return {
                    ...prev,
                    point: {
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng()
                    }
                }
            })
        })

        setDrawings((prev) => {
            if (prev.length > 0) {
                // erase current drawings from map
                prev.map(drawing => drawing.setMap(null))
            }

            return [
                circle,
                marker
            ]
        })
    }, [map, position])

    useEffect(() => {
        const radius = position.radius

        if (radius < 25) {
            setZoom(9)
        } else if (radius < 50) {
            setZoom(8)
        } else if (radius < 100) {
            setZoom(7)
        } else if (radius < 200) {
            setZoom(6)
        } else if (radius < 400) {
            setZoom(5)
        } else {
            setZoom(4)
        }
    }, [position?.radius])

    if (zoom === null) {
        // don't show map until zoom gets calculated
        return null
    }

    return (
        <Col>
            <InfoBox
                color="warning"
                className="mb-3">
                By selecting this option, any Evertel user in any agency within the circle below will have the 
                ability to join this room. This is useful for regional deployments and crisis response.
            </InfoBox>
            <Label
                text="Radius (in miles)"
                htmlFor="radius"
                required
            />
            <Input
                id="radius"
                value={position?.radius || ''}
                onChange={(e) => {
                    setPosition((prev) => {
                        return {
                            ...prev,
                            radius: parseInt(e.target.value)
                        }
                    })
                }}
            />
            <Row className="mt-1 w-100" style={{ height: '35vh'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                    zoom={zoom}
                    center={{
                        lat: position.point.lat,
                        lng: position.point.lng
                    }}
                    options={{
                        disableDefaultUI: true
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => {
                        setMap((prev) => {
                            return {
                                ...prev,
                                instance: map,
                                api: maps
                            }
                        })
                    }}
                />
            </Row>
            <Row className="mt-2">
                <Col
                    xs={6}
                    className="p-0 pr-2">
                    <Input
                        value={position.point.lat}
                        placeholder="Latitude..."
                        onChange={(e) => {
                            setPosition((prev) => {
                                return {
                                    ...prev,
                                    point: {
                                        ...prev.point,
                                        lat: parseInt(e.target.value)
                                    }
                                }
                            })
                        }}
                    />
                </Col>
                <Col
                    xs={6}
                    className="p-0 pl-2">
                    <Input
                        value={position.point.lng}
                        placeholder="Longitude..."
                        onChange={(e) => {
                            setPosition((prev) => {
                                return {
                                    ...prev,
                                    point: {
                                        ...prev.point,
                                        lng: parseInt(e.target.value)
                                    }
                                }
                            })
                        }}
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <Advanced
                    selectedDepartmentTypes={initialDepartmentTypes}
                    onChange={setDepartmentTypes}
                />
            </Row>
        </Col>
    )
})


// ByRequest ----------------------------------------------------------------------------------------------------------------------------------------------------

const ByRequest: React.FC<TabContentProps> = observer(() => {
    return (
        <InfoBox color="warning">
            By selecting this option, any Evertel user in any agency can request to join this room. Room managers 
            will be required to approve or deny each request.
        </InfoBox> 
    )
})


// Advanced ----------------------------------------------------------------------------------------------------------------------------------------------------

interface AdvancedProps {
    selectedDepartmentTypes: string[],
    onChange: (types: string[]) => void
}

const Advanced: React.FC<AdvancedProps> = observer(({
    selectedDepartmentTypes,
    onChange
}) => {

    const normalizeDepartmentTypes = (departmentTypes) => {
        return departmentTypes?.map((type) => {
            return departmentTypesList.find(({value}) => {
                return value === type
            })
        })
    }

    const [departmentTypes, setDepartmentTypes] = useState(normalizeDepartmentTypes(selectedDepartmentTypes))

    useEffect(() => {
        onChange && onChange(departmentTypes.map(d => d.value))
    }, [departmentTypes])

    return (
        <Col
            valign="top"
            style={{
                // minHeight: 300
            }}>
            <Label
                text="Allowed Agency Types"
                hint="If you only want to open this room to specic agency types, select them here"
            />
            <DepartmentTypesSelect
                selected={departmentTypes}
                isMulti
                placeholder="Allow any/all"
                menuPlacement="auto"
                onSelect={(types) => {
                    setDepartmentTypes(types)
                }}
            />
        </Col>
    )
})

export { ICRoomModal }

import { computed, action, observable, makeObservable } from 'mobx'
import AppStore from './AppStore'
import CurrentUserStore from './CurrentUserStore'
import RegistrationStore from './RegistrationStore'
import AnalyticsStore from './AnalyticsStore'
import InviteStore from './InviteStore'
import { container } from '../di'
import { SessionState } from '@evertel/session'

let _navigator = {}

class NavigationStore {
    lastNavigationRoute = 'everdocs'
    selectedRoomId = 0
    selectedThreadId = 0
    lastNavigationParams = null

    constructor() {
        this.sessionState = container.get(SessionState)

        makeObservable(this, {
            lastNavigationRoute: observable,
            selectedRoomId: observable,
            selectedThreadId: observable,
            lastNavigationParams: observable,
            restoreNavigation: action,
            navigateToRoom: action('navigateToRoom'),
            navigateToThread: action('navigateToThread'),
            navigateToLanding: action('navigateToLanding'),
            clear: action,
            navigate: action,
            navigateToExternal: action
        })
    }

    setTopLevelNavigator(navigatorRef) {
        _navigator = navigatorRef

        //console.log('NAV', _navigator)
    }


    async restoreNavigation() {
        // if not logged in take them to login
        if (!AppStore.hasValidAuthToken) {
            this.navigate('/login')
        }

        // if registration is incomplete, take them to where they left off
        if (!RegistrationStore.isRegistrationComplete) {
            RegistrationStore.goToNextStep()
            return
        }

        // if email is not verified, take them to email verification
        if (!CurrentUserStore.emailVerified) {
            this.navigate('/email-verification')
            return
        }

        // if we have a pending invite token, consume token (consume function handles navigating them to the right place)
        if (InviteStore.pendingInviteToken) {
            console.log('NAV STORE CONSUME INVITE TOKEN:', InviteStore.pendingInviteToken)
            InviteStore.consumePendingInvite().then((hasNavigated) => {
                if (!hasNavigated) {
                    this.navigate('/landing')
                }
            })
            return
        }

        this.navigate('/landing')

    }

    navigateToRoom(id, qs) {
        this.lastNavigationRoute = 'room'

        this.selectedRoomId = id

        // Log to analytics
        AnalyticsStore.logPageView('room')

        // navigate to room
        const path = qs ? `/room/${id}${qs}` : `/room/${id}`
        this.navigate(path)
    }

    navigateToThread(id) {
        this.lastNavigationRoute = 'thread'

        this.selectedThreadId = id

        // Log to analytics
        AnalyticsStore.logPageView('thread')

        // navigate to thread
        this.navigate(`/thread/${id}`)
    }

    navigateToLanding = () => {
        this.lastNavigationRoute = 'landing'

        this.navigate('/landing')
    }

    clear() {
        this.postPinAction = null
        this.lastNavigationRoute = null
        this.selectedRoomId = 0
        this.selectedThreadId = 0
    }

    navigate(route) {
        this.lastNavigationRoute = route
        let path = route

        // if route does not start with forward slash, add it
        if (route.charAt(0) !== '/') path = '/' + path

        _navigator?.history?.push(path)
    }

    navigateToExternal(url, target = '_blank') {
        if (!url) return

        // if no http or https add it
        if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url
        }

        window.open(url, target)

        AnalyticsStore.logOutboundLink(url)
    }

}


// Creates a single instance of this store
export default new NavigationStore()

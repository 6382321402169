/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { LoadOptions } from 'react-select-async-paginate'
import { MultiValue, SingleValue } from 'react-select'
import classNames from 'classnames'
// evertel
import { formatForSelect } from '@evertel/utils'
import { APIDataDocumentSchemaCategory } from '@evertel/types'
import { useService } from '@evertel/di'
import { SchemaCategorySearchController } from '@evertel/document'
import { ContentPlaceholder, CustomAsyncPaginate } from '@evertel/web/ui'

interface SchemaCategorySearchSelectProps {
    departmentId: number,
    selected?: APIDataDocumentSchemaCategory[],
    isMulti?: boolean,
    placeholder?: string,
    searchFilter?: any,
    maxMenuHeight?: number,
    noOptionsMessage?: string,
    className?: string,
    onEndReachedThreshhold?: number,
    components?: ReactNode
    onSelect: (category: MultiValue<APIDataDocumentSchemaCategory>|SingleValue<APIDataDocumentSchemaCategory>) => void
}


const SchemaCategorySearchSelect: React.FC<SchemaCategorySearchSelectProps> = observer(({
    departmentId, // if global categories departmentId will be 0
    selected = [],
    isMulti = false,
    placeholder = 'Search or Select...',
    noOptionsMessage = 'No matches found',
    onSelect,
    maxMenuHeight,
    className,
    components,
    onEndReachedThreshhold = 80,
    ...otherProps
}) => {

    const controller = useService(SchemaCategorySearchController, [])

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            await controller.init(
                departmentId, // if global categories departmentId will be 0
                true
            )
            setIsLoading(false)
        })()
    }, [])

    const onSelectOption = (category: MultiValue<APIDataDocumentSchemaCategory>|SingleValue<APIDataDocumentSchemaCategory>) => {
        if (onSelect) onSelect(category)
    }

    const search = async (searchString: string, loadedOptions: LoadOptions<APIDataDocumentSchemaCategory, any, { page: number}>, { page }: { page: number }) => {
        //console.log(searchString, loadedOptions, page)

        controller.setSearchTerm(searchString)
        controller.setPage(page)

        try {
            await controller.search()
        } catch (error) {
            console.error('ERROR: /libs/web/document/view/schema-categories/SchemaCategorySearchSelect search():', error)
        }

        // grab results and format them for the Select
        const results = formatForSelect(controller.categories, 'name', 'id')
        const hasMore = controller.categories?.length < controller.categoriesCount

        return {
            options: results || [],
            hasMore,
            additional: {
                page: page + 1 || 1
            }
        }
    }

    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number): boolean => {
        // determines when to load next page
        const precentageScroll = scrollTop / (scrollHeight - clientHeight) * 100
        if (precentageScroll > onEndReachedThreshhold) {
            return true
        } else {
            return false
        }
    }

    if (isLoading) {
        return (
            <ContentPlaceholder/>
        )
    }

    return (
        <>
            {/* @ts-ignore */}
            <CustomAsyncPaginate
                name="categories"
                loadOptions={search as any}
                additional={{
                    page: 1
                }}
                debounceTimeout={300}
                defaultOptions
                shouldLoadMore={shouldLoadMore}
                isMulti={isMulti}
                value={selected}
                onChange={onSelectOption}
                noOptionsMessage={() => noOptionsMessage}
                placeholder={placeholder}
                maxMenuHeight={maxMenuHeight}
                className={className}

                {...(components && { components: components} )}
                {...otherProps}
            />
        </>
    )
})

export default SchemaCategorySearchSelect
SchemaCategorySearchSelect.displayName = 'SchemaCategorySearchSelect'


import { APIDataRoomMessage, APIDataThreadMessage } from '@evertel/types'
import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { debounce, uniqBy } from 'lodash'
import { SessionState } from '@evertel/session'

type SearchScopeProps = 'room'|'thread'

class MessageSearchController {
    modelId = 0 // roomId | threadId
    searchScope = 'room'
    messages: APIDataRoomMessage[]|APIDataThreadMessage[] = []
    messagesCount = 0
    searchTerm = ''
    startDate: Date|null = null
    endDate: Date|null = null
    urgent = false
    onlyMe = false
    notMe = false
    retracted = false
    page = 1
    limit = 100
    isLoading = false


    constructor(
        private api: Api,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    init( modelId: number, searchScope: SearchScopeProps ) {
        if (!modelId) console.error('ERROR: MSC-1')

        this.modelId = modelId
        this.searchScope = searchScope

        this.reset()
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    setSearchTerm(value: string) {
        this.searchTerm = value
        this.resetSearchMeta()
    }

    setUrgent(urgent: boolean) {
        this.urgent = urgent
        this.resetSearchMeta()
    }

    setRetracted(retracted: boolean) {
        this.retracted = retracted
        this.resetSearchMeta()
    }

    setOnlyMe(me: boolean) {
        this.onlyMe = me
        this.resetSearchMeta()
    }

    setNotMe(me: boolean) {
        this.notMe = me
        this.resetSearchMeta()
    }

    setStartDate(date: Date) {
        this.startDate = date
        this.resetSearchMeta()
    }

    setEndDate(date: Date) {
        this.endDate = date
        this.resetSearchMeta()
    }

    private debouncedSearchInternal = debounce(async () => {
        try {
            await this.search()
        } catch (e) {
            /*  */
        }
        runInAction(() => {
            this.isLoading = false
        })
        return 
    }, 300)

    debouncedSearch = () => {
        this.isLoading = true
        return this.debouncedSearchInternal()
    }

    search = async () => {
        if (!this.modelId) {
            console.log('ERROR: MSC-2')
            return []
        }

        runInAction(() => {
            this.isLoading = true
        })

        let count = this.messagesCount
        let messages = []

        // grab the total count of messages for this search (once each new search)
        if (!this.messagesCount) {
            count = await this.fetchMessagesCount()
        }

        // stop if we already fetched them all
        if (this.messages?.length >= count) return []

        try {
            if (this.searchScope === 'room') {
                messages = await this.api.Routes.Room.getMessages(this.modelId, this.filter)
            } else {
                messages = await this.api.Routes.Thread.getMessages(this.modelId, this.filter)
            }

            runInAction(() => {
                this.messages = uniqBy([...this.messages, ...messages], 'id')
            })

            // we return the new array as some components only need this.
            // however, you should usually reference 'this.messages' ovservable
            return messages

        } catch (e) {
            console.error('ERROR: /libs/shared/MessageSearchController search()', e)
            throw e
        } finally {
            runInAction(() => {
                this.isLoading = false
            })
        }
    }

    async fetchMessagesCount() {
        let count = 0

        try {
            if (this.searchScope === 'room') {
                count = (await this.api.Routes.Room.getMessagesCount(this.modelId, this.filter.where)).count
            } else {
                count = (await this.api.Routes.Thread.getMessagesCount(this.modelId, this.filter.where)).count
            }

            runInAction(() => {
                this.messagesCount = count ?? 0
            })
            
        } catch (e) {
            console.error('ERROR: /libs/shared/MessagesSearchController fetchMessagesCount()', e)
            throw e
        }

        return count ?? 0
    }

    loadNextPage(): void {
        this.page = this.page + 1
        this.search()
    }

    reset(): void {
        this.notMe = false
        this.onlyMe = false
        this.startDate = null
        this.endDate = null
        this.retracted = false
        this.urgent = false
        this.searchTerm = ''
        this.limit = 100
        this.resetSearchMeta()
    }

    resetSearchMeta(): void {
        this.page = 1
        this.messages = []
        this.messagesCount = 0
    }

    get filter() {
        const extraFilters = []

        if (this.startDate) { extraFilters.push({ publishedDate: { gt: this.startDate } }) }
        if (this.endDate) { extraFilters.push({ publishedDate: { lt: this.endDate } }) }
        if (this.urgent) { extraFilters.push({ isUrgent: true }) }
        if (this.onlyMe) { extraFilters.push({ ownerId: this.session.currentUserId }) }
        if (this.retracted) { 
            // if looking at retracted messages, you can only look at your own retracted messages
            extraFilters.push({ ownerId: this.session.currentUserId })
            extraFilters.push({ isRetracted: true }) 
        } else {
            extraFilters.push({ isRetracted: false })
        }
        if (this.notMe) { extraFilters.push({ ownerId: { neq: this.session.currentUserId } }) }

        const where = {
            searchTerm: this.searchTerm,
            and: [
                { type: 'user' },
                { and: extraFilters }
            ]
        }

        return {
            include: [
                {relation: 'media'},
                {relation: 'owner'}
            ],
            where: where,
            order: 'publishedDate DESC',
            limit: 100
        }
    }
}

decorate(injectable(), MessageSearchController)
decorate(inject(Api), MessageSearchController, 0)
decorate(inject(SessionState), MessageSearchController, 1)

export { MessageSearchController }

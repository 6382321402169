import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import classNames from 'classnames'
// evertel
import Icon from './Icon'

const propTypes = {
    id: PropTypes.string.isRequired,
    icon: PropTypes.string, // name of icon (fontAwesome)
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
    onClick: PropTypes.func
}

const defaultProps = {
    icon: 'grin',
    iconSize: 20,
    iconColor: 'muted'
}

const EmojiButton = observer(({id, icon, iconColor, iconSize, onClick, style, className}) => {

    return (
        <div id={id} className={classNames('emoji-button', {'cursor-pointer': onClick, className: className})} onClick={onClick} style={style}>
            <Icon
                color={iconColor}
                name={icon}
                size={iconSize}
            />
            +
        </div>
    )
})

EmojiButton.propTypes = propTypes
EmojiButton.defaultProps = defaultProps
export default EmojiButton

import React, { useCallback, useEffect, useRef } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@evertel/web/ui'

// evertel
import { ConfirmProps } from '../UIContext'


const Confirm: React.FC<ConfirmProps> = ({
    title = '',
    message = '',
    isOpen = false,
    proceed,
    cancel,
    cancelButton = {color: 'ghost-muted', label: 'Cancel'},
    acceptButton = {color: 'success', label: 'Ok'},
    headerClassName,
    forceChoice = false
}) => {

    const cancelButtonRef = useRef<{ node: HTMLButtonElement } | null>(null);

    useEffect(() => {
        if (isOpen) {
            const timeoutId = setTimeout(() => {
                //This takes a long while to become visible because of the animation so need a timeout
                //note: LaddaButton, not normal button :/
                cancelButtonRef.current?.node?.focus()
            }, 250)
            return () => clearTimeout(timeoutId)
        }
    }, [isOpen])

    const confirm = useCallback(() => {
        if (acceptButton.onClick) acceptButton.onClick()
        if (proceed) proceed()
    }, [acceptButton, proceed])

    const deny = useCallback(() => {
        if (cancelButton.onClick) cancelButton.onClick()
        if (cancel) cancel()
    }, [cancelButton, cancel])

    const handleClose = useCallback(() => {
        if (!forceChoice) {
            deny()
        }
    }, [forceChoice, deny])

    return (
        <Modal
            visible={isOpen} 
            onClose={handleClose}
            size="md"
            keyboard={!forceChoice}
            backdrop={forceChoice ? 'static' : true}
        >
            <ModalHeader
                title={title || 'Please confirm...'}
                className={headerClassName}
                closeButton={!forceChoice}
            />
            <ModalBody className="pt-3">
                <div dangerouslySetInnerHTML={{__html: message}} />
            </ModalBody>
            <ModalFooter align="right">
                {(cancelButton) &&
                    <Button
                        ref={cancelButtonRef}
                        className="mr-2"
                        {...cancelButton}
                        onClick={deny}
                    >
                        {cancelButton?.label || 'Cancel'}
                    </Button>
                }
                {(acceptButton) &&
                    <Button
                        {...acceptButton}
                        onClick={confirm}
                    >
                        {acceptButton?.label || 'Ok'}
                    </Button>
                }
            </ModalFooter>
        </Modal>
    )
}

export { Confirm }
import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
// evertel
import { Icon, IconProps, Text } from '../../src'
import { HTMLAttributes } from 'react'
import { Link, LinkProps } from 'react-router-dom'

export interface IconLinkProps extends HTMLAttributes<HTMLAnchorElement | HTMLButtonElement | LinkProps> {
    children?: React.ReactNode
    label: string
    icon?: IconProps
    selected?: boolean
    onClick?: () => void
    to?: string
    href?: string
    className?: string
}

const IconLink: React.FC<IconLinkProps> = observer(({
    children,
    label,
    icon,
    selected,
    onClick,
    to,
    href,
    className,
    ...otherProps
}) => {

    return React.createElement(
        href ? 'a' : to ? Link : 'button',
        {
            ...(href && { href, target: otherProps.target || '_blank', rel: otherProps.rel || 'noopener noreferrer' }),
            ...(to && { to }),
            ...(onClick && { onClick }),
            className: classNames(className, 'icon-link', {
                'hover-action cursor-pointer': to || onClick,
                'active': selected
            }),
            ...otherProps
        },
        <>
            {icon &&
                <Icon
                    {...icon}
                    className={classNames(icon.className, {'mr-3': (children || label)})}
                    fixedWidth
                />
            }
            {children ? children : label ? <Text className="text-truncate">{label}</Text> : ''}
        </>
    )
})

export { IconLink }
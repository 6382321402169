import { APIDataRoom, APIMetaDataRoomPropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

class RoomStore extends Store<APIDataRoom> {
    constructor() {
        super(APIMetaDataRoomPropertyNames)
    }
}

decorate(injectable(), RoomStore)

export { RoomStore }

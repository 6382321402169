import { ContainerModule } from '@evertel/di'
import { ThreadController, CreateThreadController } from './controller'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(ThreadController).toSelf()
        bind(CreateThreadController).toSelf()
    })
]

export { DI_MODULES }

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDidUpdate } from '@mantine/hooks'
import { useService } from '@evertel/di'
import { Dropdown, DropdownSelectProps, ContentPlaceholder } from '@evertel/web/ui'
import { DocumentDepartmentPickerController } from '../../controller'

interface DepartmentPickerProps extends Omit<DropdownSelectProps, 'options'> {
    departmentId: number
    onSelect: (arg0?: number) => void,
}

const DocumentDepartmentPicker: React.FC<DepartmentPickerProps> = observer(({
    departmentId,
    onSelect,
    ...otherProps
}) => {
    const documentDepartmentPickerController = useService(DocumentDepartmentPickerController, [])

    useEffect(() => {
        (async () => {
            await documentDepartmentPickerController.load(departmentId)
        })()
    }, [])

    useDidUpdate(() => {
        // TODO: Use autorun
        // onSelect(controller.selected)
    }, [documentDepartmentPickerController.selected])

    const handleChange = (option: any) => {
        if (option === null) {
            documentDepartmentPickerController.clear()
            onSelect(null)
        } else {
            documentDepartmentPickerController.select(option.value)
            onSelect(option.value)
        }
    }

    if (!documentDepartmentPickerController.loaded) {
        return (
            <ContentPlaceholder height={37} />
        )
    }

    return (
        <Dropdown
            value={documentDepartmentPickerController?.selected}
            // @ts-ignore
            options={Array.from(documentDepartmentPickerController.departments, ((department) => {
                return {
                    label: department.name,
                    value: department.id
                }
            }))}
            onChange={handleChange}
            {...otherProps}
        />
    )
})

export {
    DocumentDepartmentPicker
}

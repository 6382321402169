
import { makeAutoObservable } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { SessionState } from '@evertel/session'
import { Api } from '@evertel/api'
import { DepartmentsAccessStore } from '@evertel/departments-access'
import { DepartmentStore } from '@evertel/department'
import { getRole } from '@evertel/utils'

class CurrentUserDepartmentsController {

    constructor(
        private api: Api,
        private session: SessionState,
        private departmentsAccessStore: DepartmentsAccessStore,
        private departmentStore: DepartmentStore
    ) {
        makeAutoObservable(this)
    }

    get departmentsAccess() {
        return this.departmentsAccessStore.find(da => da.blueUserId === this.session.currentUserId)
    }

    get departments() {
        const departmentIds = this.departmentsAccess.map(da => da.departmentId)
        return this.departmentStore.findByIds(departmentIds)
    }

    get departmentsAccessNotGuest() {
        return this.departmentsAccess?.filter((da: any) => getRole(da.roles) !== 'guest')
    }

    get departmentsNotGuest() {
        const departmentIds = this.departmentsAccessNotGuest.map(da => da.departmentId)
        return this.departmentStore.findByIds(departmentIds)
    }

}

decorate(injectable(), CurrentUserDepartmentsController)
decorate(inject(Api), CurrentUserDepartmentsController, 0)
decorate(inject(SessionState), CurrentUserDepartmentsController, 1)
decorate(inject(DepartmentsAccessStore), CurrentUserDepartmentsController, 2)
decorate(inject(DepartmentStore), CurrentUserDepartmentsController, 3)

export { CurrentUserDepartmentsController }

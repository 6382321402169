import { injectable, inject, decorate } from '@evertel/di'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { debounce, uniqBy } from 'lodash'
// evertel
import { Api } from '@evertel/api'
import { downloadCSVFile } from '@evertel/utils'
import { APIDataAny, APIDataBlueUser } from '@evertel/types'

type SortOrder = 'asc' | 'desc'
class DepartmentUsersListController {
    departmentId = 0
    usersCount = 0
    users: APIDataBlueUser[] = []
    usersLatestFetch: APIDataBlueUser[] = []
    page = 1
    limit = 25
    searchTerm = ''
    sortBy = 'id'
    sortOrder: SortOrder = 'desc'
    infiniteScroll = false
    loading = false

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init({
        departmentId,
        infiniteScroll = false
    }: {
        departmentId: number
        infiniteScroll?: boolean
    }) {
        if (!departmentId) {
            console.log('ERROR: DepartmentUsersListController Department ID Required')
            return
        }

        this.infiniteScroll = infiniteScroll
        this.setDepartmentId(departmentId)
        this.resetSearch()

    }

    setDepartmentId(departmentId: number) {
        this.resetSearchMeta()
        this.departmentId = departmentId
    }

    setSearchTerm(value: string) {
        this.resetSearchMeta()
        this.searchTerm = value.trim()
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    setLimit = (limit: number) => {
        this.limit = limit || 25
        this.resetSearchMeta()
    }

    setSort = (sortBy: string, sortOrder: SortOrder) => {
        this.sortBy = sortBy
        this.sortOrder = sortOrder
        this.resetSearchMeta()
    }

    private debouncedSearchInternal = debounce(async () => {
        try {
            await this.search()
        } catch (e) {
            /* */
        }
        runInAction(() => {
            this.loading = false
        })
        return
    }, 300)

    debouncedSearch = () => {
        this.loading = true
        return this.debouncedSearchInternal()
    }

    search = async () => {
        if (!this.departmentId) {
            console.log('ERROR: DepartmentUsersListController Missing DepartmentId')
            return []
        }

        let count = this.usersCount
        let newUsers: APIDataBlueUser[] = []

        // grab the total count of users for this search (once each new search)
        if (!this.usersCount) {
            count = await this.fetchDepartmentUsersCount()
        }

        this.usersLatestFetch = []

        // stop if we already fetched them all
        if (this.users.length < count) {
            try {
                newUsers = await this.api.Routes.Department.getUsersSearch(this.departmentId, this.filter)

                runInAction(() => {
                    this.usersLatestFetch = newUsers
                    this.users = this.infiniteScroll ? uniqBy([...this.users, ...newUsers], 'id') : newUsers
                })
            } catch (e) {
                if (!this.api.isProduction) {
                    console.log('ERROR: DepartmentUsersListController search()', e)
                }
                throw e
            }
        }

        return this.usersLatestFetch
    }

    fetchDepartmentUsersCount = async (): Promise<number> => {
        let count = 0
        count = (await this.api.Routes.Department.getUsersSearchCount(this.departmentId, { searchTerm: this.searchTerm || '' })).count
        
        runInAction(() => {
            this.usersCount = count ?? 0
        })

        return count ?? 0
    }

    downloadUserListCsv = async (fileName:string | undefined = undefined) => {
        const csvData = await this.api.Routes.Department.getUsersCsv(this.departmentId)
        // @ts-ignore
        downloadCSVFile(csvData, fileName)
    }

    resetSearchMeta(): void {
        this.page = 1
        this.users = []
        this.usersCount = 0
    }

    resetSearch(): void {
        this.resetSearchMeta()
        this.searchTerm = ''
        this.limit = 25
        this.sortBy = 'id'
        this.sortOrder = 'desc'
    }

    get filter() {
        return {
            where: {
                searchTerm: this.searchTerm || ''
            },
            include: [{
                relation: 'departmentsAccess',
                scope: {
                    include: ['position', 'department']
                }
            }],
            skip: (this.page - 1) * this.limit,
            limit: this.limit,
            order: `${this.sortBy} ${this.sortOrder}`
        }
    }
}

decorate(injectable(), DepartmentUsersListController)
decorate(inject(Api), DepartmentUsersListController, 0)

export { DepartmentUsersListController }
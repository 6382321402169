/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react'
import { components, Props, InputProps } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import classNames from 'classnames'

export interface CreatableDropdownOption {
    value: string;
    label: string;
}

interface CreatableDropdownProps extends Props {
    value: CreatableDropdownOption[];
    placeholder?: string;
    validator?: (value: string) => string | boolean;
    transform?: (value: string) => string;
    multi?: boolean;
    onChange: (value: CreatableDropdownOption[]) => void;
    onError: (error: string) => void;
}

//must be outside component or buggy
const CustomInput = (props: InputProps<CreatableDropdownOption, true>) => {
    return (<components.Input {...props} onPaste={props.selectProps.onPaste} name="hidden"/>
    )}

const CreatableDropdown: React.FC<CreatableDropdownProps> = ({
    value,
    placeholder = 'Enter value...',
    validator = (value) => true,
    transform = (value) => value,
    multi = true,
    className,
    onChange,
    onError,
    ...otherProps
}) => {
    const [inputValue, setInputValue] = useState('')
    const [error, setError] = useState<string | null>(null)

    const updateError = (error: string) => {
        setError(error)
        onError(error)
    }

    const handleChange = (newValue: CreatableDropdownOption[] | CreatableDropdownOption | null) => {
        if (Array.isArray(newValue)) {
            onChange(newValue)
        } else if (newValue) {
            onChange([newValue])
        } else {
            onChange([])
        }
        updateError(undefined)
    }

    const addItem = () => {
        const inputVal = inputValue.trim()
        const result = validator(inputVal)
        if (inputVal && result === true) {
            const transformedValue = transform(inputVal)
            const newOption: CreatableDropdownOption = { value: transformedValue, label: transformedValue }
            const isOptionSelected = value.some((option) => option.value === newOption.value)
            if (!isOptionSelected) {
                onChange([...value, newOption])
                updateError(null)
            } else {
                updateError('Item already in list')
            }
            setInputValue('')
        } else if (typeof result === 'string') {
            updateError(result)
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const inputVal = inputValue.trim()
        if (event.key === 'Tab') {
            //don't want to trap the tab key
            if (!inputVal) return
            if (error) return
        }
        updateError(null)
        if (event.key === 'Enter' || event.key === ',' || event.key === ' ' || event.key === 'Tab') {
            event.preventDefault()
            addItem()
        }
    }

    const handleBlur = () => {
        addItem()
        updateError(null)
    }
    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault()
        const pastedData = event.clipboardData.getData('text')
        const items = pastedData.split(/[\s,\n]+/)
        const validItems = items.filter((item) => validator(item) === true)
        const transformedItems = validItems.map((item) => transform(item))
        const uniqueTransformedItems = [...new Set(transformedItems)]
        const newOptions = uniqueTransformedItems
            .filter((item) => !value.some((option) => option.value === item))
            .map((item) => {
                return { value: item, label: item }
            })    
        onChange([...value, ...newOptions])
    }

    return (
        <CreatableSelect
            value={value}
            components={{ Input: CustomInput, DropdownIndicator: null }}
            inputValue={inputValue}
            isClearable            
            isMulti={multi}
            menuIsOpen={false}
            onInputChange={(newValue) => setInputValue(newValue)}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            placeholder={placeholder}
            // @ts-ignore
            onPaste={handlePaste}
            className={classNames('react-select', className)}
            classNamePrefix="select"
            {...otherProps}
        />
    )
}

export { CreatableDropdown }
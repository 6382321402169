import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'


class SchemaCategoryUtils {
    constructor(
        private api: Api
    ) {
    }

    processSchemaCategories = async (
        value: any,
        schemaId: number
    ): Promise<any> => {

        // if no categories ignore
        if (!value || !schemaId) return

        // delete categories
        await Promise.all(value.deleteQueue.map(async (categoryId: number) => {
            try {
                await this.api.Routes.DocumentSchema.delCategoriesById(
                    schemaId,
                    categoryId
                )
            } catch (e) {
                console.error(e)
            }
        }))

        // add new categories
        await Promise.all(value.addQueue.map(async (category: {id: number}) => {
            try {
                await this.api.Routes.DocumentSchema.putCategoriesById(
                    schemaId,
                    category.id
                )
            } catch (e) {
                console.error(e)
            }
        }))
    }
}

decorate(injectable(), SchemaCategoryUtils)
decorate(inject(Api), SchemaCategoryUtils, 0)

export { SchemaCategoryUtils }

import React from 'react'
import { forwardRef, ReactNode, useRef, MouseEvent } from 'react'
import LaddaButton from 'react-ladda'
import 'ladda/dist/ladda-themeless.min.css'
import classNames from 'classnames'
import { Breakpoints } from '../prop-types'
import { lightOrDark } from '@evertel/utils'

// Ladda Button Docs -> https://github.com/hakimel/Ladda

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'> {
    color?: string,
    active?: boolean,
    size?: Breakpoints
    onClick?: (event: MouseEvent<HTMLButtonElement|HTMLAnchorElement>) => void
    /** How you want the loading spinner to animate in and display. See https://lab.hakim.se/ladda/ for examples. */
    spinnerStyle?: 'contract'|'contract-overlay'|'expand-left'|'expand-right'|'expand-up'|'expand-down'|'slide-left'|'slide-right'|'slide-up'|'slide-down'|'zoom-in'|'zoom-out'
    spinnerSize?: number
    spinnerColor?: string
    spinnerLines?: number
    children?: ReactNode
    className?: string
    /** To use the progress indicator, pass in percentage loaded as a number from 0.0 to 1.0 */
    progress?: number
    loading?: boolean
    disabled?: boolean
    outline?: boolean
    ghost?: boolean
    block?: boolean
    pill?: boolean
    upload?: boolean|'multiple'
    onUpload?: (files: File[]) => void
    fileTypes?: string
    style?: any
}


const Button = forwardRef<HTMLButtonElement|HTMLAnchorElement, ButtonProps>(({
    color = 'primary',
    active,
    size,
    onClick,
    spinnerStyle = 'expand-left',
    spinnerSize,
    spinnerColor,
    spinnerLines,
    children,
    className,
    progress,
    loading,
    disabled,
    outline,
    ghost,
    block,
    pill,
    upload,
    onUpload,
    fileTypes,
    style,
    ...otherProps
}, ref) => {

    if (progress && (progress < 0 || progress > 1)) {
        console.log('ERROR: Button progress value is out of range! (0.0-1.0 only)', ref)
    }

    const inputRef = useRef<HTMLInputElement>(null)

    const _onUpload = (event: any) => {
        if (event.target.files === null) {
            return
        }

        const files = {...event.target.files} // clone

        // detect onChange for same file
        event.target.value = ''

        if (onUpload) onUpload(Object.values<File>(files))
    }

    const _className = classNames(
        'btn',
        'btn-ladda',
        {
            ['btn-'+color]: !outline && !ghost,
            ['btn-outline-'+color]: outline,
            ['btn-ghost-'+color]: ghost,
            'btn-pill': pill,
            'btn-block': block,
            'btn-disabled': disabled,
            // 'py-1': size === 'sm',
            'active': active
        },
        className
    )

    const _size = (size === 'lg') ? 'l' : (size === 'sm') ? 's' : size

    return (
        <>
            <LaddaButton
                ref={ref}
                data-style={spinnerStyle}
                data-size={_size}
                data-color={color}
                data-spinner-size={spinnerSize}
                data-spinner-lines={spinnerLines}
                data-spinner-color={(spinnerColor) ? spinnerColor : (lightOrDark(color) === 'light') ? 'black' : 'white'}
                className={_className}
                onClick={(!disabled) ? (upload ? () => { inputRef.current?.click() } : onClick) : undefined }
                disabled={disabled || loading}
                loading={loading}
                progress={progress}
                style={style}
                {...otherProps}>
                {children}
            </LaddaButton>

            {/* if an upload button, add a hidden input and have button simulate a click on this input */}
            {(upload) &&
                <input
                    type="file"
                    ref={inputRef}
                    onChange={_onUpload}
                    multiple={upload === 'multiple'}
                    {...fileTypes && { accept: fileTypes }}
                    style={{ display: 'none' }}
                />
            }
        </>
    )
})

export { Button }

import { ContainerModule } from '@evertel/di'
import { HistoryTableController } from './controller/'
import { AuditsSearchController } from './controller/'
import { UsageByUserController } from './controller/'

const DI_WEB_ADMIN_MODULE = new ContainerModule((bind) => {
    bind(HistoryTableController).toSelf()
    bind(AuditsSearchController).toSelf()
    bind(UsageByUserController).toSelf()
})


export { DI_WEB_ADMIN_MODULE }

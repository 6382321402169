/* eslint-disable @typescript-eslint/ban-ts-comment */
import { toJS, makeAutoObservable, runInAction } from 'mobx'
import { Emoji, getEmojiDataFromNative, init as emojiMartInit } from 'emoji-mart'
// @ts-ignore
import emojiData from '@emoji-mart/data/sets/14/apple.json'
import { decorate, inject, injectable } from 'inversify'
import { SessionState } from '@evertel/session'
import { BlueUserStore } from '@evertel/blue-user'
import { Api } from '@evertel/api'

const version = 1
const defaultFrequent = [
    {id: '+1', usedCount: 1, lastUsed: new Date()},
    {id: '-1', usedCount: 1, lastUsed: new Date()},
    {id: 'white_check_mark', usedCount: 1, lastUsed: new Date()},
    {id: 'grinning', usedCount: 1, lastUsed: new Date()},
    {id: 'facepunch', usedCount: 1, lastUsed: new Date()},
    {id: 'pray', usedCount: 1, lastUsed: new Date()},
    {id: 'joy', usedCount: 1, lastUsed: new Date()}
]

class CurrentUserEmojisController {
    constructor(
        private session: SessionState,
        private userStore: BlueUserStore,
        private api: Api
    ) {
        makeAutoObservable(this)
        emojiMartInit({ data: emojiData })
    }

    updateUserData = async (emojiData: any) => {
        if (!emojiData) return

        const userData = await this.api.Routes.BlueUser.putById(this.session.currentUserId, emojiData)

        runInAction(() => {
            this.userStore.update(userData)
        })
    }

    getFrequent(top = 5, includeCustom = true) {
        // return the top X most used emojis on this device
        const sortedFrequents = this.frequent.slice().sort((a: any, b: any) => b.usedCount - a.usedCount)

        if (includeCustom) {
            return sortedFrequents.slice(0, top)
        } else {
            // if includeCustom is false, filter out custom emojis before returning
            return sortedFrequents.filter((f: any) => !f.id.includes(':')).slice(0, top)
        }
    }

    getFrequentIds = (top = 5) => {
        const tops = this.getFrequent(top)
        
        return tops?.map((fc: any) => fc.id.replace(/:/g, ''))
    }

    incrementFrequent = async (text: string) => {
        let emoji: string|Emoji = ''
        let emojiIdx = null
        const isCustom = text.charAt(0) === ':'

        if (isCustom) {
            emoji = text
            emojiIdx = this.frequent.findIndex((e: any) => e.id === text)
        } else {
            emoji = await getEmojiDataFromNative(text)
            // @ts-ignore
            emojiIdx = this.frequent.findIndex((e: any) => e.id === emoji.id)
        }

        if (emojiIdx !== -1) {
            this.frequent[emojiIdx].usedCount++
            this.frequent[emojiIdx].lastUsed = new Date()
        } else {
            this.frequent.push({
                // @ts-ignore
                id: emoji.id || emoji,
                usedCount: 1,
                lastUsed: new Date()
            })
        }

        // update server
        this.updateUserData({
            meta: {
                frequentEmojis: {
                    emojis: this.frequent,
                    skinTone: this.skinTone,
                    version: version
                }
            }
        })
    }

    decrementFrequent = async (text: string) => {
        let emoji: string|Emoji = ''
        let emojiIdx = null
        const isCustom = text.charAt(0) === ':'

        if (isCustom) {
            emoji = text
            emojiIdx = this.frequent.findIndex((e: any) => e.id === text)
        } else {
            emoji = await getEmojiDataFromNative(text)
            // @ts-ignore
            emojiIdx = this.frequent.findIndex((e: any) => e.id === emoji.id)
        }

        if (emojiIdx !== -1) {
            this.frequent[emojiIdx].usedCount--
        }

        // update server
        this.updateUserData({
            meta: {
                frequentEmojis: {
                    emojis: this.frequent,
                    skinTone: this.skinTone,
                    version: version
                }
            }
        })
    }

    setSkinTone = async (skin: 1|2|3|4|5|6) => {
        if (!skin) {
            console.error('setSkinTone is missing the skin param (1-6)')
            return
        }

        this.updateUserData({
            meta: {
                frequentEmojis: {
                    emojis: this.frequent,
                    skinTone: skin,
                    version: version
                }
            }
        })
    }

    get frequent() {
        const frequentEmojisList = toJS((this.userStore.findById(this.session.currentUserId)?.meta as any)?.frequentEmojis?.emojis)
        
        return frequentEmojisList?.length > 0 ? frequentEmojisList : defaultFrequent
    }

    get skinTone() {
        const user = this.userStore.findById(this.session.currentUserId)

        return (user?.meta as any)?.frequentEmojis?.skinTone || 1
    }
}

decorate(injectable(), CurrentUserEmojisController)
decorate(inject(SessionState), CurrentUserEmojisController, 0)
decorate(inject(BlueUserStore), CurrentUserEmojisController, 1)
decorate(inject(Api), CurrentUserEmojisController, 2)


export { CurrentUserEmojisController }

import { autorun, makeAutoObservable } from 'mobx'
import { decorate, injectable } from '@evertel/di'

type Theme = 'light'|'dark'

class ThemeState {
    browserTheme: Theme = 'light'
    theme: Theme = 'light'

    constructor() {
        makeAutoObservable(this)
    }

    init = () => {
        // get browser theme
        const mediaQuery = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')

        this.browserTheme = (mediaQuery?.matches) ? 'dark' : 'light'

        // set theme to what's in localStorage or match the browswer theme
        this.theme = localStorage.getItem('theme') as Theme || this.browserTheme

        // listen to browser theme change
        const changeDeviceTheme = (event) => {
            this.browserTheme = (event.matches) ? 'dark' : 'light'
            
            // if user has not manually set the app theme,
            // set it to match the browser theme when browser theme changed
            if (!localStorage.getItem('theme')) {
                this.theme = this.browserTheme
            }
        }
        mediaQuery?.addEventListener('change', changeDeviceTheme.bind(this))

        // update body class on device or user theme change
        autorun(() => {
            if (this.theme === 'dark') {
                document.body.classList.add('dark')
            } else {
                document.body.classList.remove('dark')
            }
        })
    }
    
    setTheme = (theme: Theme) => {
        this.theme = theme

        localStorage.setItem('theme', theme)
    }

    get themeOptions() {
        return [
            { label: 'Light', value: 'light' },
            { label: 'Dark', value: 'dark' }
        ]
    }
}

decorate(injectable(), ThemeState)

export { ThemeState }
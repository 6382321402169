import React from 'react'
import { Card, CardHeader, CardBody, CardFooter, ContentPlaceholder} from '@evertel/web/ui'

const DocumentPlaceholder = () => {
    return (
        <Card>
            <CardHeader>
                <ContentPlaceholder width="45%" color="gray200"/>
            </CardHeader>
            <CardBody>
                <ContentPlaceholder width={300} height={300} className="mb-5"/>
                <ContentPlaceholder width="60%" className="my-2"/>
                <ContentPlaceholder width="70%" className="mb-5"/>
                <ContentPlaceholder width="55%"/>
                <ContentPlaceholder width="75%" className="my-2"/>
                <ContentPlaceholder width="70%" className="mb-5"/>
            </CardBody>
            <CardFooter>
                <ContentPlaceholder width="25%" className="mb-2" color="gray200"/>
                <ContentPlaceholder width="15%" color="gray200"/>
            </CardFooter>
        </Card>
    )
}

export {
    DocumentPlaceholder
}

import React from 'react'
import { Label, Checkbox, Col } from '@evertel/web/ui'

interface TextTypeSelectorProps {
    onChange?: (value?: string|number|readonly string[]) => void
    value?: string
    label?: string
}

const TextTypeSelector: React.FC<TextTypeSelectorProps> = ({
    onChange,
    label,
    value,
    ...otherProps
}) => {

    return (
        <Col {...otherProps}>
            <Label
                text={label}
            />
            <Checkbox
                id="number"
                label="Number"
                value="number"
                checked={value === 'number'}
                onChange={(e) => onChange && onChange((e.checked) ? e.value : undefined)}
            />
            <Checkbox
                id="url"
                label="Link/URL"
                value="url"
                checked={value === 'url'}
                onChange={(e) => onChange && onChange((e.checked) ? e.value: undefined)}
            />
            <Checkbox
                id="email"
                label="Email"
                value="email"
                checked={value === 'email'}
                onChange={(e) => onChange && onChange((e.checked) ? e.value : undefined)}
            />
            <Checkbox
                id="tel"
                label="Phone Number"
                value="tel"
                checked={value === 'tel'}
                onChange={(e) => onChange && onChange((e.checked) ? e.value : undefined)}
            />
        </Col>
    )
}

export default TextTypeSelector
TextTypeSelector.displayName = 'TextTypeSelector'

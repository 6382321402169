import React, { useState, useContext } from 'react'
import { observer } from 'mobx-react-lite'
// evertel
import {
    Text,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    Col
} from '@evertel/web/ui'
import { RoomContext } from '../../RoomContext'
import { RoomMediaSearchSelect } from '../RoomMediaSearchSelect'
import { RoomDocumentsSearchSelect } from '../../RoomDocumentsSearchSelect'
import { useDocumentModal } from '@evertel/web/document'


const RoomMediaSearch = observer(() => {

    const { roomController, asideOpenComplete } = useContext(RoomContext)
    const { openDocumentModal } = useDocumentModal()
    const room = roomController.room

    const [activeTab, setActiveTab] = useState<'files'|'docs'>('files')

    if (!room || !asideOpenComplete) return null

    return (
        <Col>
            <Nav
                variant="underline"
                role="tablist"
                style={{
                    fontSize: '0.9rem'
                }}>
                <NavItem>
                    <NavLink
                        active={activeTab === 'files'}
                        onClick={() => setActiveTab('files')}>
                        <Text>
                            Files/Images
                        </Text>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={activeTab === 'docs'}
                        onClick={() => setActiveTab('docs')}>
                        <Text>
                            EverDocs
                        </Text>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent>
                <TabPane
                    role="tabpanel"
                    visible={activeTab === 'files'}>
                    <div className="pt-3">
                        Find files shared in this room
                    </div>
                    <RoomMediaSearchSelect
                        roomId={room.id}
                        menuIsOpen
                        maxMenuHeight="calc(100vh - 220px)"
                        className="no-bg search space-below"
                    />
                </TabPane>
                <TabPane
                    role="tabpanel"
                    visible={activeTab === 'docs'}>
                    <div className="pt-3">
                        Find EverDocs shared with this room
                    </div>
                    <RoomDocumentsSearchSelect
                        roomId={room.id}
                        onSelect={(doc) => openDocumentModal(doc.id)}
                        menuIsOpen
                        maxMenuHeight="calc(100vh - 220px)"
                        className="no-bg search space-below"
                    />
                </TabPane>
            </TabContent>
        </Col>
    )
})

export { RoomMediaSearch }

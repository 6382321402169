
import { makeAutoObservable } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { DepartmentsAccessStore } from '../store'
import { Api } from '@evertel/api'
import { SessionState } from '@evertel/session'

class SelectedDepartmentAccessController {
    userId = 0

    constructor(
        private api: Api,
        private departmentsAccessStore: DepartmentsAccessStore,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    init(userId: number) {
        if (!userId) return

        this.userId = userId

        if (!this.departmentsAccessStore.findById(userId)) {
            this.fetchDepartmentsAccess()
        }
    }

    fetchDepartmentsAccess = async (filter?: any) => {
        const da = await this.api.Routes.BlueUser.getDepartmentsAccess(this.userId, filter)

        this.departmentsAccessStore.update(da)
    }

    get departmentsAccess() {
        return this.departmentsAccessStore.findOne(da => da.blueUserId === this.userId && da.departmentId === this.session.selectedDepartmentId)
    }

    get role() {
        const roles = this.departmentsAccess?.roles

        if (!roles) return undefined

        // this works because it's in order of priority and will return their actual (highest) role
        if (roles.includes('executive')) return 'executive'
        if (roles.includes('management')) return 'management'
        if (roles.includes('employee')) return 'employee'
        if (roles.includes('guest')) return 'guest'

        return ''
    }

    get canManage() {
        return this.role === 'executive' || this.role === 'management'
    }
}

decorate(injectable(), SelectedDepartmentAccessController)
decorate(inject(Api), SelectedDepartmentAccessController, 0)
decorate(inject(DepartmentsAccessStore), SelectedDepartmentAccessController, 1)
decorate(inject(SessionState), SelectedDepartmentAccessController, 2)

export { SelectedDepartmentAccessController }
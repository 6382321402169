import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'
import { BreadcrumbItem } from './BreadcrumbItem'

type ItemProps = {label: string, onClick?: () => void, active?: boolean}

export interface BreadcrumbProps extends HTMLAttributes<HTMLOListElement> {
    className?: string,
    items: ItemProps[]
}

const Breadcrumb = forwardRef<HTMLOListElement, BreadcrumbProps>(({
    items,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('breadcrumb', className)

    return (
        <nav aria-label="breadcrumb">
            <ol
                ref={ref}
                className={_className}
                {...otherProps}>
                {items?.map((item, index) =>
                    <BreadcrumbItem
                        key={index}
                        active={item.active}
                        onClick={item.onClick}>
                        {item.label}
                    </BreadcrumbItem>
                )}
            </ol>
        </nav>
    )
})

export { Breadcrumb }
Breadcrumb.displayName = 'Breadcrumb'


import { APIDataRoom } from '@evertel/types'
import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { debounce, uniqBy } from 'lodash'

/**
 * managed = rooms the current user manages.
 * member = rooms the current user is a member of.
 * public = public/crisis/incident-command rooms.
 * department = all rooms in the department
 */
export type RoomSearchScopeProps = 'managed' | 'member' | 'public' | 'department' | 'forward'

class RoomsSearchController {
    searchScope = 'department'
    currentUserId = 0
    departmentId = 0
    rooms: APIDataRoom[] = []
    roomsLatestFetch: APIDataRoom[] = []
    roomsCount = 0
    searchTerm = ''
    page = 1
    limit = 100
    infiniteScroll = false
    filterOutArchived = true

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init({ searchScope, currentUserId, departmentId, infiniteScroll, filterOutArchived = true }: {
        searchScope: RoomSearchScopeProps
        currentUserId: number
        departmentId: number
        infiniteScroll: boolean
        filterOutArchived?: boolean
    }) {
        if (!currentUserId || (!departmentId && searchScope !== 'public')) console.log('ERROR: RSC-1')

        this.searchScope = searchScope
        this.infiniteScroll = infiniteScroll
        this.currentUserId = currentUserId
        this.departmentId = departmentId
        this.filterOutArchived = filterOutArchived
        this.resetSearch()
    }

    setSearchTerm(value: string) {
        this.resetSearchMeta()
        this.searchTerm = value
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    debouncedSearch = debounce(async () => {
        return await this.search()
    }, 300)

    search = async () => {
        if (!this.currentUserId || (!this.departmentId && this.searchScope !== 'public')) return

        let count = this.roomsCount
        let newRooms: APIDataRoom[] = []

        // grab the total count of rooms for this search (once each new search)
        if (!this.roomsCount) {
            count = await this.fetchRoomsCount()
        }

        this.roomsLatestFetch = []

        // stop if we already fetched them all
        if (this.rooms?.length < count) {

            try {
                if (this.searchScope === 'managed') {
                // search rooms the current user manages
                    const filter = this.filter
                    filter.where['departmentId'] = this.departmentId

                    newRooms = await this.api.Routes.BlueUser.getRoomsManaged(this.currentUserId, filter)

                } else if (this.searchScope === 'member') {
                // search rooms the current user is a member OR manager of (includes all agencies by default)
                    newRooms = await this.api.Routes.BlueUser.getRooms(this.currentUserId, this.filter)

                } else if (this.searchScope === 'department') {
                // search all rooms in the current department
                    newRooms = await this.api.Routes.Department.getRooms(this.departmentId, this.filter)

                } else if (this.searchScope === 'forward') {
                    const filter = this.filter
                    filter.where['departmentId'] = this.departmentId

                    newRooms = await this.api.Routes.BlueUser.getRoomsForward(this.currentUserId, filter)
                } else if (this.searchScope === 'public') {
                // search all public/crisis/incident-command rooms
                    const filter = this.filter
                    filter.where['searchTerm'] = this.searchTerm
                    newRooms = await this.api.Routes.Room.getSearchPublic(filter)
                }

            } catch (e) {
                console.log('ERROR: /libs/shared/RoomsSearchController search()', e)
                throw e
            }
        }

        runInAction(() => {
            this.roomsLatestFetch = newRooms
            this.rooms = (this.infiniteScroll) ? uniqBy([...this.rooms, ...newRooms], 'id') : newRooms
        })

        // we return the new array as some components only need this.
        // however, you should usually reference 'this.rooms' ovservable
        return this.roomsLatestFetch

    }

    async fetchRoomsCount() {
        let count = 0

        try {
            if (this.searchScope === 'managed') {
                const where = this.filter.where
                where['departmentId'] = this.departmentId

                count = (await this.api.Routes.BlueUser.getRoomsManagedCount(this.currentUserId, where)).count

            } else if (this.searchScope === 'member') {
                count = (await this.api.Routes.BlueUser.getRoomsCount(this.currentUserId, this.filter.where)).count

            } else if (this.searchScope === 'department') {
                count = (await this.api.Routes.Department.getRoomsCount(this.departmentId, this.filter.where)).count

            } else if (this.searchScope === 'forward') {
                const where = this.filter.where
                where['departmentId'] = this.departmentId

                count = (await this.api.Routes.BlueUser.getRoomsForwardCount(this.currentUserId, where))?.count

            } else if (this.searchScope === 'public') {
                // public rooms
                const where = this.filter.where
                where['searchTerm'] = this.searchTerm
                count = (await this.api.Routes.Room.getSearchPublicCount(where)).count
            }

        } catch (e) {
            console.log('ERROR: /libs/shared/RoomsSearchController fetchRoomsCount()', e)
            throw e
        }

        runInAction(() => {
            this.roomsCount = count ?? 0
        })

        return this.roomsCount
    }

    loadNextPage(): void {
        this.page = this.page + 1
        this.search()
    }

    resetSearchMeta(): void {
        this.page = 1
        this.rooms = []
        this.roomsCount = 0
    }

    resetSearch(): void {
        this.resetSearchMeta()
        this.searchTerm = ''
    }

    get filter() {
        return {
            where: {
                and: [
                    {
                        name: {
                            like: `%${this.searchTerm}%`
                        }
                    },
                    {
                        isArchived: !this.filterOutArchived
                    }
                ]
            },
            //fields: ['id', 'name', 'departmentId'],
            skip: (this.page - 1) * this.limit,
            limit: this.limit,
            order: 'name ASC'
        }
    }
}

decorate(injectable(), RoomsSearchController)
decorate(inject(Api), RoomsSearchController, 0)

export { RoomsSearchController }
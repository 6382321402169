import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Text } from '@evertel/web/ui'

interface MediaUploadOverlayProps {
    onDrop: (files: File[]) => void,
    className?: string
}

const MediaUploadWindowOverlay: React.FC<MediaUploadOverlayProps> = ({
    onDrop,
    className,
    ...otherProps
}) => {

    const [show, setShow] = useState(false)
    const lastDragTarget = useRef(null)

    const isFile = (event) => {
        const dt = event.dataTransfer

        for (let i = 0; i < dt.types.length; i++) {
            if (dt.types[i] === 'Files') {
                return true
            }
        }
        return false
    }

    const onDragOver = useCallback((event: DragEvent) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'copy'
        event.dataTransfer.effectAllowed = 'move'

        if (isFile(event)) {
            lastDragTarget.current = event.target
            setShow(true)
        }
    }, [])

    const onDragLeave = useCallback((event: DragEvent) => {
        event.preventDefault()
        if (event.target === document || event.target === lastDragTarget.current) {
            setShow(false)
        }
    }, [lastDragTarget])

    const _onDrop = useCallback((event: DragEvent) => {
        event.preventDefault()

        const files = []

        if (event.dataTransfer?.items) {
            [...event.dataTransfer.items].forEach((item, i) => {
                // if dropped items aren't files, ignore them
                if (item.kind === 'file') {
                    files.push(item.getAsFile())
                }
            })
        }

        onDrop(files)
        setShow(false)

    }, [onDrop])

    useEffect(() => {
        document.addEventListener('dragover', onDragOver, false)
        document.addEventListener('dragleave', onDragLeave, false)
        document.addEventListener('drop', _onDrop, false)

        return () => {
            // remove listeners on unmount
            document.removeEventListener('dragover', onDragOver, false)
            document.removeEventListener('dragleave', onDragLeave, false)
            document.removeEventListener('drop', _onDrop, false)
        }
    }, [onDragOver, onDragLeave, _onDrop])

    const classes = classNames(
        'animated',
        {
            'visible opacity-5': show,
            'invisible opacity-0': !show
        },
        className
    )

    return (
        <div
            id="dropzone"
            className={classes}
            {...otherProps}>
            <img
                src="/assets/img/photos-stack.png"
                alt="Upload Media"
                width="250"
                style={{pointerEvents: 'none'}}
            />
            <Text
                tag="h1"
                color="white"
                style={{ pointerEvents: 'none' }}>
                Attach Files
            </Text>
            <Text
                size="small"
                color="white"
                style={{ pointerEvents: 'none' }}>
                Drag & drop images, videos and documents
            </Text>
        </div>
    )
}

export { MediaUploadWindowOverlay }

import React, { forwardRef, HTMLAttributes, ReactElement, useEffect, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Text, Col, Row, ContentPlaceholder } from '@evertel/web/ui'
import { UserController } from '@evertel/blue-user'
import { ProfilePic } from './ProfilePic'
import { useService } from '@evertel/di'
import { DepartmentsAccessController } from '@evertel/departments-access'
import { SessionState } from '@evertel/session'
import { DepartmentController } from '@evertel/department'

// ********************************************************************
// NOTE: this component should only be used where you are expecting
// the user to be stored in the usersStore. If it can't find the user
// it will fetch the user. DO NOT use in user's search where results
// are not intended to be stored. Instead use the UserTileRawData component
// ********************************************************************

interface UserTileProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
    userId: number,
    imageWidth?: number,
    showPosition?: boolean,
    subComponent?: ReactElement,
    rightComponent?: ReactElement,
    className?: string
}

const UserTile = observer(forwardRef<HTMLDivElement, UserTileProps>(({
    userId,
    imageWidth = 30,
    showPosition,
    subComponent,
    rightComponent,
    className,
    ...otherProps
}, ref) => {

    const userController = useService(UserController, [])
    const departmentsAccessController = useService(DepartmentsAccessController, [])
    const departmentController = useService(DepartmentController, [])
    const session = useService(SessionState, [])

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)

        userController.init(
            userId,
            {
                fields: ['id', 'firstName', 'lastName', 'publicImage']
            }
        )

        if (showPosition) {
            departmentsAccessController.init(userId)
            departmentController.init(departmentsAccessController.primaryDepartmentsAccess?.departmentId)
        }
        setIsLoading(false)
        
    }, [userId])

    const _className = classNames(
        'user-tile',
        'p-0',
        'd-flex',
        {
            'cursor-pointer': otherProps?.onClick
        },
        className
    )

    if (isLoading) {
        return (
            <div className="d-flex">
                <ContentPlaceholder width={imageWidth} height={imageWidth} className="mr-2"/>
                <ContentPlaceholder width="40%"/>
            </div>
        )
    }

    const user = userController.user

    return (
        <Row
            ref={ref}    
            className={_className}
            {...otherProps}>
            <ProfilePic
                userId={userId}
                imageUrl={user?.publicImage}
                width={imageWidth}
                height={imageWidth}
                firstName={user?.firstName || ':'}
                lastName={user?.lastName || ')'}
            />
            <Col
                xs={(rightComponent) ? 7 : 12}
                className="p-0 ml-2">
                <Text
                    bold
                    className="text-truncate">
                    {userController?.fullName}
                </Text>
                {(showPosition) &&
                    <Text
                        color="muted"
                        size="small">
                        {departmentsAccessController.primaryDepartmentsAccess?.positionString}
                        {(departmentsAccessController.primaryDepartmentsAccess?.departmentId !== session.selectedDepartmentId && departmentController.shortName) &&
                            <Text> @ {departmentController.shortName}</Text>
                        }
                    </Text>
                }
                {(subComponent) && React.cloneElement( subComponent )}
            </Col>
            {(rightComponent) && React.cloneElement( rightComponent )}
        </Row>
    )
}))

export { UserTile }
UserTile.displayName = 'UserTile'

import React, { ElementType, forwardRef, useContext, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useForkedRef } from '@evertel/hooks'
import { NavContext } from '../sidebar/SidebarNav'

export interface NavLinkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    active?: boolean
    className?: string
    component?: ElementType
    disabled?: boolean
    idx?: string
}

const NavLink = forwardRef<HTMLButtonElement, NavLinkProps>(({
    children,
    className,
    active,
    idx,
    onClick,
    ...otherProps
}, ref) => {
    const navLinkRef = useRef<HTMLButtonElement>(null)
    const forkedRef = useForkedRef(ref, navLinkRef)

    const { setVisibleGroup } = useContext(NavContext)
    const _className = classNames('nav-link', className, {
        'active': active
    })

    useEffect(() => {
        if (idx && active) {
            setVisibleGroup(idx)
        }
    }, [active, idx, setVisibleGroup])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!otherProps.disabled && onClick) {
            onClick(event)
        }
    }

    return (
        <button
            ref={forkedRef}
            className={_className}
            onClick={handleClick}
            type="button"
            {...otherProps}
        >
            {children}
        </button>
    )
})

export { NavLink }
NavLink.displayName = 'NavLink'
import React, { forwardRef, HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Transition } from 'react-transition-group'
import classNames from 'classnames'
import { useForkedRef } from '@evertel/hooks'
import { Backdrop } from '../Backdrop'

export interface SlidePanelProps extends HTMLAttributes<HTMLDivElement> {
    backdrop?: boolean|'static'|'clear'
    className?: string
    /** Closes the SlidePanel when escape key is pressed. */
    keyboard?: boolean
    onHide?: () => void
    onShow?: () => void
	onShowComplete?: () => void
	onHideComplete?: () => void
    placement: 'start'|'end'|'top'|'bottom'
    /** Generates modal using createPortal. */
    portal?: boolean
    /** Responsive slidepanel property hide content outside the viewport from a specified breakpoint and down. */
    responsive?: boolean|'sm'|'md'|'lg'|'xl'|'xxl'
    /** Allow body scrolling while slidepanel is open */
    scroll?: boolean
    visible?: boolean
	embed?: boolean
}

const SlidePanel = forwardRef<HTMLDivElement, SlidePanelProps>(({
	children,
	backdrop = true,
	className,
	keyboard = true,
	onHide,
	onShow,
	onShowComplete,
	onHideComplete,
	placement,
	portal = false,
	responsive = true,
	scroll = false,
	visible = false,
	embed = false,
	...otherProps
}, ref) => {

    const [_visible, setVisible] = useState<boolean>(visible)
    const slidePanelRef = useRef<HTMLDivElement>(null)
    const forkedRef = useForkedRef(ref, slidePanelRef)

    useEffect(() => {
      	setVisible(visible)
    }, [visible])

    useEffect(() => {
		if (_visible) {
			if (!scroll) {
				document.body.style.overflow = 'hidden'
				document.body.style.paddingRight = '0px'
			}
			return
		}

		if (!scroll) {
			document.body.style.removeProperty('overflow')
			document.body.style.removeProperty('padding-right')
		}
    }, [_visible])

    const getTransitionClass = (state: string) => {
		return state === 'entering'
			? 'showing'
			: state === 'entered'
			? 'show'
			: state === 'exiting'
			? 'show hiding'
			: ''
    }

    const _className = classNames(
		{
			[`slidepanel${typeof responsive !== 'boolean' ? '-' + responsive : ''}`]: responsive,
			[`slidepanel-${placement}`]: placement,
			embed: embed
		},
		className
    )

    const handleDismiss = () => {
      	setVisible(false)
    }

    const handleBackdropDismiss = () => {
		if (backdrop !== 'static') {
			setVisible(false)
		}
    }

    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === 'Escape' && keyboard) {
				return handleDismiss()
			}
		},
		[ref, handleDismiss]
    )

	const onEntered = () => {
		slidePanelRef.current?.focus()
		onShowComplete && onShowComplete()
	}

    const slidepanel = (ref: React.Ref<HTMLDivElement>, state: string) => {
      return (
        <>
			<div
				ref={ref}
				className={classNames(_className, getTransitionClass(state))}
				role="dialog"
				tabIndex={-1}
				onKeyDown={handleKeyDown}
				{...otherProps}>
				{children}
			</div>
        </>
      )
    }

    return (
		<>
			<Transition
				in={_visible}
				nodeRef={slidePanelRef}
				onEnter={onShow}
				onEntered={onEntered}
				onExit={onHide}
				{...(onHideComplete) && {onExited: onHideComplete}}
				timeout={150}
			>
				{(state) => {
					return typeof window !== 'undefined' && portal
					? createPortal(slidepanel(forkedRef, state), document.body)
					: slidepanel(forkedRef, state)
				}}
			</Transition>
			{(typeof window !== 'undefined' && portal)
				? (backdrop) &&
					createPortal(
						<Backdrop
							className={classNames('slidepanel-backdrop', {['backdrop-clear']: backdrop === 'clear'})}
							onClick={handleBackdropDismiss}
							visible={_visible}
						/>,
						document.body
					)
				: (backdrop) && (
					<Backdrop
						className={classNames('slidepanel-backdrop', {['backdrop-clear']: backdrop === 'clear'})}
						onClick={handleBackdropDismiss}
						visible={_visible}
					/>
			)}
		</>
    )
})

export { SlidePanel }
SlidePanel.displayName = 'SlidePanel'


import { APIDataDocument } from '@evertel/types'
import { makeAutoObservable, runInAction } from 'mobx'
import { uniqBy } from 'lodash'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { DocumentSchemaStore } from '@evertel/stores'


class DocumentSearchByRoomController {
    documents: APIDataDocument[] = []
    documentsLatestFetch: APIDataDocument[] = []
    documentSchemaIds: number[] = []
    documentsCount = 0
    roomId = 0
    searchTerm = ''
    page = 1
    limit = 100
    isLoading = false
    isInfiniteScroll = true

    constructor(
        private api: Api,
        private documentSchemaStore: DocumentSchemaStore
    ) {
        makeAutoObservable(this)
    }

    init(roomId: number, isInfiniteScroll = true) {
        this.roomId = roomId
        this.isInfiniteScroll = isInfiniteScroll
        this.resetSearch()
    }

    setSearchTerm(value: string) {
        this.resetSearchMeta()
        this.searchTerm = value
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    search = async () => {
        if (!this.roomId) return
        
        let count = this.documentsCount
        let results = []

        runInAction(() => {
            this.isLoading = true
        })

        this.documentsLatestFetch = []

        // grab the total count of docs in this room
        if (!this.documentsCount) {
            count = await this.fetchDocumentsCount()
        }

        // stop from fetching if we already got them all
        if (count > this.documents?.length) {
            results = await this.api.Routes.Room.getDocumentsSearch(this.roomId, this.filter)
        }

        runInAction(() => {
            this.documentsLatestFetch = results
            this.documents = (this.isInfiniteScroll) ? uniqBy([...this.documents, ...results], 'id') : results
            this.documentSchemaIds = this.documents.map(d => d.documentSchemaId)
            this.isLoading = false
        })
    }

    async fetchDocumentsCount() {
        const res = await this.api.Routes.Room.getDocumentsSearchCount(this.roomId, this.filter.where)

        runInAction(() => {
            this.documentsCount = res?.count ?? 0
        })

        return this.documentsCount
    }

    fetchNextDocuments() {
        this.page = this.page + 1
        this.search()
    }

    resetSearchMeta() {
        this.documents = []
        this.documentSchemaIds = []
        this.documentsCount = 0
        this.page = 1
    }

    resetSearch() {
        this.resetSearchMeta()
        this.searchTerm = ''
    }

    get filter() {
        return {
            where: {
                searchTerm: this.searchTerm
            },
            order: 'updatedDate DESC',
            limit: this.limit,
            skip: (this.page - 1) * this.limit
        }
    }

    get documentSchemas() {
        return this.documentSchemaStore.findByIds(this.documentSchemaIds)
    }
}

decorate(injectable(), DocumentSearchByRoomController)
decorate(inject(Api), DocumentSearchByRoomController, 0)
decorate(inject(DocumentSchemaStore), DocumentSearchByRoomController, 1)

export { DocumentSearchByRoomController }
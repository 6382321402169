import React, { createContext, useState, useContext } from 'react'
import { InviteModal } from './InviteModal'
import { SendInvitesBody } from '@evertel/invites'

export type InviteTabs = 'member'|'guest'|'contact'

type InvitedRooms = {label: string, value: number}[]
export interface OpenInviteProps {
    inviteType?: InviteTabs,
    inviteBody?: SendInvitesBody,
    invitedRooms?: InvitedRooms,
    inviteDepartmentId?: number
}

export interface InviteContextProps {
    openInvite: (inviteType: InviteTabs, invitedRooms?: InvitedRooms, inviteBody?: SendInvitesBody, inviteDepartmentId?: number) => void,
    closeInvite: () => void
}

export const InviteContext = createContext<InviteContextProps>({
    openInvite: (inviteType: InviteTabs, invitedRooms?: InvitedRooms, inviteBody?: SendInvitesBody, inviteDepartmentId?: number) => {
        //
    },
    closeInvite: () => {
        //
    }
})

export const InviteContextProvider: React.FC<{children?: JSX.Element}> = ({
    children
}) => {

    const [visible, setVisible] = useState(false)
    const [inviteBody, setInviteBody] = useState({})
    const [inviteType, setInviteType] = useState<InviteTabs>('member')
    const [invitedRooms, setInvitedRooms] = useState<InvitedRooms>([])
    const [inviteDepartmentId, setInviteDepartmentId] = useState<number | undefined>(undefined)

    const openInvite = (type: InviteTabs, rooms?: InvitedRooms, body?: SendInvitesBody, departmentId?: number) => {
        setInviteBody(body || {})
        setInvitedRooms(rooms || [])
        setInviteType(type)
        setVisible(true)
        setInviteDepartmentId(departmentId)
    }

    const closeInvite = () => {
        setVisible(false)
    }

    const reset = () => {
        setInviteBody({})
        setInvitedRooms([])
        setInviteType('member')
        setInviteDepartmentId(undefined)
    }

    return (
        <InviteContext.Provider value={{ openInvite, closeInvite }}>
            {children}
            <InviteModal
                visible={visible}
                onClose={() => setVisible(false)}
                onCloseDone={reset}
                inviteType={inviteType}
                inviteBody={inviteBody as SendInvitesBody}
                invitedRooms={invitedRooms}
                inviteDepartmentId={inviteDepartmentId}
            />
        </InviteContext.Provider>
    )
}

export const InviteConsumer = InviteContext.Consumer

export const useInvite = () => {
    return useContext(InviteContext)
}

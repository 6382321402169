import {Container, ContainerModule, inject, injectable, decorate, unmanaged, interfaces} from 'inversify'
import Factory = interfaces.Factory
import AutoFactory = interfaces.AutoFactory
const loadModules = (
    container: Container,
    module: ContainerModule | Array<ContainerModule>
) => {
    if (!Array.isArray(module)) {
        module = [module]
    }
    
    module.forEach(module => container.load(module))
}


export {
    Container,
    ContainerModule,
    inject,
    injectable,
    decorate,
    unmanaged,
    loadModules,
    Factory,
    AutoFactory
}


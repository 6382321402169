import React, { forwardRef, HTMLAttributes, useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import { Transition } from 'react-transition-group'
// evertel
import { Colors } from '../prop-types'
import { CloseButton } from './CloseButton'
import { useForkedRef } from '@evertel/hooks'

export interface InfoBoxProps extends HTMLAttributes<HTMLDivElement> {
    className?: string
    color: Colors
    /** Optionally add a close button to alert and allow it to self dismiss. */
    dismissible?: boolean
    onClose?: () => void
    /** Set the alert variant to a solid or outline. */
    variant?: 'solid'|'outline'
    visible?: boolean
}

const InfoBox = forwardRef<HTMLDivElement, InfoBoxProps>(({
    children,
    className,
    color = 'warning',
    dismissible,
    variant,
    visible = true,
    onClose,
    ...otherProps
}, ref) => {

    const alertRef = useRef<HTMLDivElement>(null)
    const forkedRef = useForkedRef(ref, alertRef)
    const [_visible, setVisible] = useState(visible)

    useEffect(() => {
        setVisible(visible)
    }, [visible])

    const _className = classNames(
        'alert',
        {
            'alert-dismissible fade': dismissible,
            [`bg-${color}`]: variant === 'solid',
            'text-white': variant === 'solid',
            [`alert-${color}`]: variant !== 'solid' && variant !== 'outline',
            [`border border-${color}`]: variant === 'outline'
        },
        className
    )

    const getTransitionClass = (state: string) => {
        return state === 'entered' && 'show'
    }

    return (
        <Transition
            in={_visible}
            mountOnEnter
            nodeRef={alertRef}
            onExit={onClose}
            timeout={150}
            unmountOnExit>
            {(state) => {
                const transitionClass = getTransitionClass(state)
                return (
                    <div
                        ref={forkedRef}
                        className={classNames(_className, transitionClass)}
                        role="alert"
                        {...otherProps}>
                        {children}
                        {(dismissible) &&
                        <CloseButton
                            onClick={() => setVisible(false)}
                        />
                        }
                    </div>
                )
            }}
        </Transition>
    )
})

export { InfoBox }

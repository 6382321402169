import React, { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    Col, InfoBox, Button,
    Input, Label, ToggleSwitch,
    SlidePanelHeader, SlidePanelTitle, SlidePanelBody, CloseButton
} from '@evertel/web/ui' 
import { itemProps, SchemaFieldItemProps } from '../data/_schemaItemProps'
import OptionsBuilder, { Option } from './OptionsBuilder'
import { FileTypesSelector } from "./FileTypesSelector"
import { SchemaMediaController } from '../../../controller'
import TextTypeSelector from './TextTypeSelector'

interface ItemEditFormProps {
    field: SchemaFieldItemProps|null
    onChange: (props: any) => void
    onChangeListLayoutField: (props: any) => void
    /* callback will be resolved in SchemaBuilderFull */
    onAction: (action: string, props: any) => void
    mediaController: SchemaMediaController
    onClose: () => void
}

const ItemEditForm: React.FC<ItemEditFormProps> = ({
    field,
    onChange,
    onChangeListLayoutField,
    onAction,
    onClose,
    mediaController
}) => {

    if (!field) return null

    // grab the field's config
    const fieldConfig = itemProps[field.type]

    if (!fieldConfig) {
        console.error(`No matching config for ${field.type}`)
        return null
    }

    // console.log('FIELD DATA', field, {fieldConfig})
    // console.log('MEDIA', mediaController.media)

    const [_field, setField] = useState<SchemaFieldItemProps>({} as SchemaFieldItemProps)

    useEffect(() => {
        const props = {} as SchemaFieldItemProps

        // map config to state and populate with field data
        for (const [key, value] of Object.entries(fieldConfig)) {
            switch ((value as any)?.type) {
                case 'string':
                case 'number':
                    props[key] = field[key] || ''
                    break
                case 'boolean':
                    props[key] = field[key] || fieldConfig[key].default || false
                    break
                case 'options':
                    props[key] = field[key] || []
                    break
                default:
                    props[key] = field[key] || ''
                    break
            }
        }
        setField(props)
    }, [field])

    useEffect(() => {
        if (!onChange) return

        if (field.type === 'list' || field.internalSchemaId) {
            onChangeListLayoutField({
                [field.id]: {
                    ..._field,
                    internalSchemaId: field.internalSchemaId
                }
            })
        } else {
            onChange({
                [field.id]: _field
            })
        }
    }, [_field])

    const onChangeField = (field: string, value: string|boolean|Option[]|undefined) => {
        setField((prev) => ({
            ...prev,
            [field]: value
        }))
    }

    const formField = ({ data, ...otherProps }: { data: SchemaFieldItemProps }) => {
        const optional = (data?.label?.search('(optional)') !== -1) ? true : false
        const label = (optional) ? data.label?.replace('(optional)', '') : data.label

        switch (data?.type) {
            case 'string':
            case 'number':
                return (
                    <Col {...otherProps}>
                        <Label
                            text={label}
                            htmlFor={data.id}
                            optional={optional}
                            hint={data.hint}
                            hintPosition="right"
                        />
                        <Input
                            type={(data.type === 'string') ? 'text' : data.type}
                            id={data.id}
                            value={_field[data.id]}
                            onChange={(e) => onChangeField(e.target.id, e.target.value)}
                            maxLength={data.maxLength}
                        />
                    </Col>
                )
            case 'boolean':
                if (data.id === 'multiline' && field.id === 'title') return null
                return (
                    <ToggleSwitch
                        id={data.id}
                        label={label}
                        checked={_field[data.id]}
                        onChange={(checked) => onChangeField(data.id, checked)}
                        {...otherProps}
                    />
                )
            case 'options':
                return (
                    <Col {...otherProps}>
                        <Label
                            text={label}
                            hint={data.hint}
                        />
                        <OptionsBuilder
                            options={_field.options}
                            isCheckbox={field.type === 'checkbox' || field.type === 'checkbox-group'}
                            onChange={(options) => onChangeField(data.id, options)}
                            mediaController={mediaController}
                        />
                    </Col>

                )
            case 'contentType':
                if (_field.multiline || field.id === 'title') return null
                return (
                    <TextTypeSelector
                        label={label}
                        onChange={(types) => onChangeField(data.id, types.toString())}
                        value={_field[data.id]}
                    />

                )
            case 'fileTypes':
                return (
                    <FileTypesSelector
                        label={label}
                        typesAllowed={_field[data.id]}
                        onChange={(types) => onChangeField(data.id, types.toString())}
                    />

                )
            default:
                return null
            }
    }

    // convert object to mappable array
    const fieldProps = Object.keys(fieldConfig).map(key => ({ id: key,  ...fieldConfig[key] }))

    return (
        <>
            <SlidePanelHeader>
                <SlidePanelTitle className="text-capitalize">
                    Edit {(field.type === 'list') ? 'Form' : field.type} Element
                </SlidePanelTitle>
                <CloseButton
                    onClick={onClose}
                />
            </SlidePanelHeader>
            <SlidePanelBody>
                {/* @ts-expect-error: perfectscrollbar */}
                <PerfectScrollbar className="pr-3">
                    <FieldInfoBox
                        field={field}
                    />
                    {fieldProps?.map((f, idx) =>
                        <div
                            key={idx}
                            className="mb-3">
                            {formField({
                                data: f
                            })}
                        </div>
                    )}
                    {(field.type === 'list') &&
                        <Button
                            color="link"
                            className="mt-3"
                            onClick={() => onAction('open-form', {sKey: field.id})}>
                            Edit form fields
                        </Button>
                    }
                </PerfectScrollbar>
            </SlidePanelBody>
        </>
    )
}

const FieldInfoBox = ({
    field
}: {field: SchemaFieldItemProps}) => {

    // if (field.id === 'title') {
    //     return (
    //         <InfoBox color="info">
    //             The "title" field cannot be deleted. The value of this field will be used as the name of each document created with this template.
    //         </InfoBox>
    //     )
    // }
    if (field.type === 'list') {
        return (
            <InfoBox color="info">
                A "Sub Form" is a form that is inserted in another form. 
                Useful for elements that can include one or many entries (i.e. Suspects, Vehicles, etc.)
            </InfoBox>
        )
    }
    return null
}

export {
    ItemEditForm
}
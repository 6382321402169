import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Select from 'react-select'
// evertel
import { CurrentUserStore } from '../../stores'
import { formatForSelect } from '@evertel/utils'


const propTypes = {
    selected: PropTypes.array,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    additionalOptions: PropTypes.array,
    // callbacks
    onSelect: PropTypes.func.isRequired
}

const defaultProps = {
    selected: [],
    isMulti: false,
    placeholder: 'Select...',
    additionalOptions: []
}


const ChildDepartmentsSelect = observer(({
    selected,
    isMulti,
    placeholder,
    onSelect,
    additionalOptions
}) => {

    const parentDepartment = CurrentUserStore.selectedDepartment
    const childDepartments = parentDepartment.childDepartments || []
    const departmentsFilter = formatForSelect([...additionalOptions, {id: parentDepartment.id, name: parentDepartment.name}, ...childDepartments], 'name', 'id') || []


    return (
        <Select
            name="children"
            options={departmentsFilter}
            value={selected}
            onChange={onSelect}
            isMulti={isMulti}
            isSearchable={false}
            placeholder={placeholder}
            className="react-select"
            classNamePrefix="select"
            menuPortalTarget={document.body}
            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
        />
    )
})

ChildDepartmentsSelect.propTypes = propTypes
ChildDepartmentsSelect.defaultProps = defaultProps
export default ChildDepartmentsSelect


import { makeAutoObservable, runInAction, autorun } from 'mobx'
import _ from 'lodash'

import debugModule from 'debug'
const debug = debugModule('app:Push:PushService')

import { decorate, inject, injectable } from '@evertel/di'
import { Api } from '@evertel/api'
import { SessionState } from '@evertel/session'
import { NotificationClicked, NotificationPermissionResult, PushImplementation, PushNotification } from './PushImplementation'

// TODO: Reference and import a PushImplementation hat is platform specific

export type PushEvent = (event: PushNotification) => void
export type NotificationClickedEvent = (event: NotificationClicked) => void

type NotificationPermission = 'prompt' | 'denied' | 'granted' | undefined

class PushService {
    private onPushListeners = new Set<PushEvent>()
    private onNotificationClickedListeners = new Set<NotificationClickedEvent>()
    private initialized = false
    public _notificationPermission: string | undefined = undefined

    constructor(
        private api: Api,
        private session: SessionState,
        private pushImplementation: PushImplementation
    ) {
        makeAutoObservable(this)
    }

    async init() {
        if (this.initialized) return

        await this.pushImplementation.init()
        // TODO: get push token and send it to API ?

        const permission = await this.pushImplementation.getCurrentNotificationPermission()
        runInAction(() => {
            this.notificationPermission = permission
        })

        this.pushImplementation.setOnPermissionChangeCallback((permission) => {
            runInAction(() => {
                this.notificationPermission = permission
            })

            if (permission === 'granted') {
                this.pushImplementation.showLocalNotification()
            }
        })

        // Make sure any message events are passed to listeners
        this.pushImplementation.setOnMessageCallback((notification) => {
            for (const listener of this.onPushListeners) {
                listener(notification)
            }
        })

        this.pushImplementation.setOnNotificationClicked((notification) => {
            for (const listener of this.onNotificationClickedListeners) {
                listener(notification)
            }
        })

        this.initialized = true
    }

    async promptForNotificationPermission() {
        const permission = await this.pushImplementation.promptForNotificationPermission()
        runInAction(() => {
            //don't want to trigger dependency listeners on this every single time it's checked
            if (permission != this.notificationPermission) {
                this.notificationPermission = permission
            }
        })
        return permission
    }

    set notificationPermission(permission) {
        this._notificationPermission = permission
    }
    get notificationPermission() {
        return this._notificationPermission
    }

    async getCurrentNotificationPermission() {
        const permission = await this.pushImplementation.getCurrentNotificationPermission()
        runInAction(() => {
            //don't want to trigger dependency listeners on this every single time it's checked
            if (permission != this.notificationPermission) {
                this.notificationPermission = permission
            }
        })
        return this.notificationPermission
    }

    async getPushToken(): Promise<string> {
        return this.pushImplementation.getPushToken()
    }

    async deletePushToken() {
        return this.pushImplementation.deletePushToken()
    }

    async postPushToken() {
        const token = await this.pushImplementation.getPushToken()
        // TODO: do we need ot have a session.isLoggedIn property ?
        // TODO: do we need a ty cath around this ?
        // Yes, it throws if Authorization is required

        if (!token) return

        if (this.session.currentUserId) {
            const result = await this.api.Routes.BlueUser.postPushTokens(this.session.currentUserId, { value: token })
            debug('app:Push:PushService: PostPushToken Result:', result)
        }
    }

    async getInitialNotification() {
        throw ('Not Implemented')
    }

    async clearInitialNotification() {
        throw ('Not Implemented')
    }

    async showLocalNotification() {
        throw ('Not Implemented')
    }

    addPushNotificationListener(listener: PushEvent) {
        this.onPushListeners.add(listener)
    }

    removePushNotificationListener(listener: PushEvent) {
        this.onPushListeners.delete(listener)
    }

    addNotificationClickedListener(listener: NotificationClickedEvent) {
        this.onNotificationClickedListeners.add(listener)
    }

    removeNotificationClickedListener(listener: NotificationClickedEvent) {
        this.onNotificationClickedListeners.delete(listener)
    }
}

decorate(injectable(), PushService)
decorate(inject(Api), PushService, 0)
decorate(inject(SessionState), PushService, 1)
decorate(inject(PushImplementation), PushService, 2)

export { PushService }
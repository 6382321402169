import React from 'react'
import { ElementType, forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'
import { Link } from '../Link'

export interface PaginationItemProps extends HTMLAttributes<HTMLAnchorElement> {
  active?: boolean
  component?: string | ElementType
  disabled?: boolean
}

const PaginationItem = forwardRef<HTMLAnchorElement, PaginationItemProps>(({
    children,
    className,
    component,
    ...otherProps
}, ref) => {

    const Component = component ?? (otherProps.active ? 'span' : 'a')

    return (
        <li
            className={classNames(
            'page-item',
            {
                active: otherProps.active,
                disabled: otherProps.disabled,
            },
            className
            )}
            {...(otherProps.active && { 'aria-current': 'page' })}>
            {(Component === 'a') ? (
                <Link
                    ref={ref}
                    className="page-link"
                    component={Component}
                    {...otherProps}>
                    {children}
                </Link>
            ) : (
                <Component
                    ref={ref}
                    className="page-link">
                    {children}
                </Component>
            )}
      </li>
    )
})

export { PaginationItem }
import React, { createContext, useContext, ReactNode, FC } from 'react'

type Widgets = Record<string, FC<object>>

type WidgetsProviderProps = {
    value: Widgets,
    children: ReactNode
}

const WidgetsContext = createContext<Widgets>({})

export const WidgetsProvider: FC<WidgetsProviderProps> = ({
    value, children
}) => {

    //console.log('WidgetsProvider', value)
    
    return (
        <WidgetsContext.Provider value={value}>
            {children}
        </WidgetsContext.Provider>
    )
}

export const useWidgets = () => {
    return useContext(WidgetsContext)
}

import React, { forwardRef, createContext, useContext, useEffect } from 'react'
import classNames from 'classnames'
import {
    autoUpdate, flip, size, offset, safePolygon, shift,
    FloatingFocusManager, FloatingList, FloatingNode, FloatingPortal, FloatingTree,
    useClick, useDismiss, useFloating, useFloatingNodeId, useFloatingParentNodeId, useFloatingTree, useHover,
    useInteractions, useListItem, useListNavigation, useMergeRefs, useRole, useTypeahead
} from '@floating-ui/react'
import { Icon, IconProps } from '../Icon'
import { Link, LinkProps } from 'react-router-dom'

interface MenuContextProps {
    getItemProps: ( userProps?: React.HTMLProps<HTMLElement> ) => Record<string, unknown>
    activeIndex: number | null
    setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>
    setHasFocusInside: React.Dispatch<React.SetStateAction<boolean>>
    isOpen: boolean
}

const MenuContext = createContext<MenuContextProps>({
    getItemProps: () => ({}),
    activeIndex: null,
    setActiveIndex: () => { /* */ },
    setHasFocusInside: () => { /* */ },
    isOpen: false
})

interface MenuProps {
    label: string
    renderLabel?: () => JSX.Element | null
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    nested?: boolean
    children?: React.ReactNode
}

export const MenuComponent = forwardRef< HTMLButtonElement, MenuProps & React.HTMLProps<HTMLButtonElement> >(({
    children,
    label,
    renderLabel,
    ...otherProps
}, forwardedRef) => {

    const [isOpen, setIsOpen] = React.useState(false)
    const [hasFocusInside, setHasFocusInside] = React.useState(false)
    const [activeIndex, setActiveIndex] = React.useState<number | null>(null)

    const elementsRef = React.useRef<Array<HTMLButtonElement | null>>([])
    const labelsRef = React.useRef<Array<string | null>>([])
    const parent = React.useContext(MenuContext)

    const tree = useFloatingTree()
    const nodeId = useFloatingNodeId()
    const parentId = useFloatingParentNodeId()
    const item = useListItem()

    const isNested = parentId != null

    const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
        nodeId,
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: isNested ? 'right-start' : 'bottom-start',
        middleware: [
            offset({ mainAxis: isNested ? 0 : 4, alignmentAxis: isNested ? -4 : 0 }),
            flip(),
            shift(),
            size({
                apply({ availableHeight, elements }) {
                    //need to set max height to avail height here???
                    Object.assign(elements.floating.style, {
                        maxHeight: `${availableHeight}px`,
                        overflow: 'auto'
                    })
                },
                padding: 30
            })
        ],
        whileElementsMounted: autoUpdate
    })

    const hover = useHover(context, {
        enabled: isNested,
        delay: { open: 75 },
        handleClose: safePolygon({ blockPointerEvents: true })
    })
    const click = useClick(context, {
        event: 'mousedown',
        toggle: !isNested,
        ignoreMouse: isNested
    })
    const role = useRole(context, { role: 'menu' })
    const dismiss = useDismiss(context, { bubbles: false })
    const listNavigation = useListNavigation(context, {
        listRef: elementsRef,
        activeIndex,
        nested: isNested,
        onNavigate: setActiveIndex
    })
    const typeahead = useTypeahead(context, {
        listRef: labelsRef,
        onMatch: isOpen ? setActiveIndex : undefined,
        activeIndex
    })

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
        [hover, click, role, dismiss, listNavigation, typeahead]
    )

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    useEffect(() => {
        if (!tree) return

        function handleTreeClick() {
            setIsOpen(false)
        }

        function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
            if (event.nodeId !== nodeId && event.parentId === parentId) {
                setIsOpen(false)
            }
        }

        tree.events.on('click', handleTreeClick)
        tree.events.on('menuopen', onSubMenuOpen)

        return () => {
            tree.events.off('click', handleTreeClick)
            tree.events.off('menuopen', onSubMenuOpen)
        }
    }, [tree, nodeId, parentId])

    useEffect(() => {
        if (isOpen && tree) {
            tree.events.emit('menuopen', { parentId, nodeId })
        }
    }, [tree, isOpen, nodeId, parentId])

    const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        if (!isNested) {
            //for the root element this prevents other click events in parent elements,
            //so if a menu is included inside another button, the other button isn't triggered
            event.stopPropagation()
        }
        otherProps.onClick?.(event)
    }, [isNested, otherProps.onClick])

    return (
        <FloatingNode id={nodeId}>
            <button
                ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
                tabIndex={
                    !isNested ? undefined : parent.activeIndex === item.index ? 0 : -1
                }
                role={isNested ? 'menuitem' : undefined}
                data-open={isOpen ? '' : undefined}
                data-nested={isNested ? '' : undefined}
                data-focus-inside={hasFocusInside ? '' : undefined}
                {...getReferenceProps({
                    ...(isNested
                        ? parent.getItemProps({
                            ...otherProps,
                            onFocus(event: React.FocusEvent<HTMLButtonElement>) {
                                otherProps.onFocus?.(event)
                                setHasFocusInside(false)
                                parent.setHasFocusInside(true)
                            }
                        })
                        : {
                            ...otherProps,
                            onClick: handleClick
                        })
                })}
                className={classNames(`${isNested ? 'floating-menu-item' : 'floating-menu-root'}`, otherProps.className)}
            >
                {renderLabel ? renderLabel() : label}
                {isNested && 
                    <span aria-hidden style={{ marginLeft: 'auto', textAlign: 'right'}}>
                        <Icon
                            className='d-block'
                            name="caret-right"
                            type="solid"
                            size={16}
                            style={{ width: 25 }}
                        />                            
                    </span>
                }
            </button>
            <MenuContext.Provider
                value={{
                    activeIndex,
                    setActiveIndex,
                    getItemProps,
                    setHasFocusInside,
                    isOpen
                }}
            >
                <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                    {isOpen && 
                        <FloatingPortal>
                            <FloatingFocusManager
                                context={context}
                                modal={false}
                                initialFocus={isNested ? -1 : 0}
                                returnFocus={!isNested}
                            >
                                <div
                                    ref={refs.setFloating}
                                    className='floating-menu-nested'
                                    style={floatingStyles}
                                    {...getFloatingProps()}
                                >
                                    {children}
                                </div>
                            </FloatingFocusManager>
                        </FloatingPortal>
                    }
                </FloatingList>
            </MenuContext.Provider>
        </FloatingNode>
    )
})

interface BaseMenuItemProps {
    label: string
    disabled?: boolean
    icon?: IconProps
    to?: string
    href?: string
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

type MenuItemProps = BaseMenuItemProps & 
Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseMenuItemProps> &
Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseMenuItemProps> &
Omit<LinkProps, keyof BaseMenuItemProps>

export const MenuHeader: React.FC<BaseMenuItemProps & React.HTMLAttributes<HTMLDivElement>> = ({
    children,
    label,
    disabled,
    ...otherProps
}) => {
    const menu = useContext(MenuContext)

    return (
        <div
            {...otherProps}
            role="menuitem"
            className={classNames('floating-menu-header', otherProps.className)}
            {...menu.getItemProps()}
        >
            {children ? children : label}
        </div>
    )
}

export const MenuItem = forwardRef<HTMLElement, MenuItemProps>(({
    children,
    label,
    disabled,
    icon,
    to,
    href,
    onClick,
    ...otherProps 
}, forwardedRef) => {
    const menu = useContext(MenuContext)
    const item = useListItem({ label: disabled ? null : label })
    const tree = useFloatingTree()
    const isActive = item.index === menu.activeIndex

    const commonProps = {
        ...otherProps,
        ref: useMergeRefs([item.ref, forwardedRef]),
        role: 'menuitem',
        className: classNames('floating-menu-item', otherProps.className),
        tabIndex: isActive ? 0 : -1,
        disabled: disabled,
        ...menu.getItemProps({
            onClick: (event: React.MouseEvent<HTMLElement>) => {
                onClick?.(event)
                tree?.events.emit('click')
            },
            onFocus: (event: React.FocusEvent<HTMLAnchorElement & HTMLButtonElement>) => {
                otherProps.onFocus?.(event)
                menu.setHasFocusInside(true)
            }
        })
    }

    const content = (
        <>
            {icon && 
                <Icon
                    className="mr-3"
                    fixedWidth
                    {...icon}
                />
            }
            {children ? children : <span>{label}</span>}
        </>
    )

    if (href) {
        return <a href={href} target={otherProps.target || '_blank'} rel={otherProps.rel || 'noopener noreferrer'} {...commonProps}>{content}</a>
    } else if (to) {
        return <Link to={to} {...commonProps}>{content}</Link>
    } else {
        return <button type="button" {...commonProps}>{content}</button>
    }
})

export const MenuDivider: React.FC = () => (
    <div className="floating-menu-divider" role="separator" />
)

export const FloatingMenu = forwardRef< HTMLButtonElement, MenuProps & React.HTMLProps<HTMLButtonElement> >(({
    ...props
}, ref) => {
    const parentId = useFloatingParentNodeId()

    if (parentId === null) {
        return (
            <FloatingTree>
                <MenuComponent {...props} ref={ref} />
            </FloatingTree>
        )
    }

    return <MenuComponent {...props} ref={ref} />
})

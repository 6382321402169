import { makeAutoObservable } from 'mobx'
import DepartmentAccess from './models/DepartmentAccess'
import _ from 'lodash'
import { container } from '../di'
import { DepartmentsAccessStore as NewDepartmentsAccessStore } from '@evertel/departments-access'

// Keeps track of our login state and progress
class DepartmentsAccessStore {

    constructor() {
        makeAutoObservable(this)
        this.newDepartmentsAccessStore = container.get(NewDepartmentsAccessStore)
    }

    get departmentsAccess() {
        return this.newDepartmentsAccessStore.objectsArray.map(u => new DepartmentAccess(u))
    }

    updateFromData(data) {
        if (!data) return

        // if data is an array then use it, otherwise its an object, put it in an array, the array objects should be unique by id
        const dataDepartments = _.uniqBy(data.length !== undefined ? data : [data], 'id')

        this.newDepartmentsAccessStore.update(dataDepartments)
    }

    get roleOptions() {
        return [
            { role: 'executive', value: 'executive', label: 'Executive', description: 'Executives have total access to edit and manage all aspects of your agency account.' },
            { role: 'management', value: 'management', label: 'Management', description: 'Management can manage rooms and users They cannot run audits, or assign the Executive role.' },
            { role: 'employee', value: 'employee', label: 'Employee', description: 'Employees can manage their own account information. They only have access to the rooms they are members of.' },
            { role: 'guest', value: 'guest', label: 'Guest', description: 'Guests are non-employees that are invited to specific rooms. They cannot see anything but the contents of the rooms they are in.' }
        ]
    }
}

// Creates a single instance of this store
export default new DepartmentsAccessStore()

import React, { KeyboardEvent, ClipboardEvent, useEffect, useState } from 'react'
import * as EmailValidator from 'email-validator'
import { observer } from 'mobx-react-lite'
import parsePhoneNumberFromString from 'libphonenumber-js'
import classNames from 'classnames'
// evertel
import { Col, Dropdown, CreatableDropdown, CreatableDropdownOption, InfoBox, Input, Label, Row, Tag, Text, Textarea, Button, PeopleSearchSelect, DepartmentSearchSelect } from '@evertel/web/ui'
import { roles } from '@evertel/constants'
import { SendInvitesBody } from '@evertel/invites'
import { APIDataBlueUser, APIDataDepartment } from '@evertel/types'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { SelectedDepartmentAccessController } from '@evertel/departments-access'
import { CurrentUserController } from '@evertel/blue-user'

interface InviteByEmailFormProps {
    inviteType: 'department' | 'room' | 'contact',
    showRole?: boolean,
    showPublicSearch?: boolean,
    departmentId: number,
    onChange: (body: SendInvitesBody) => void,
    className?: string
}

const InviteByEmailForm: React.FC<InviteByEmailFormProps> = observer(({
    inviteType = 'department',
    showRole,
    showPublicSearch,
    departmentId,
    onChange,
    ...otherProps
}) => {
    
    const session = useService(SessionState, [])

    const sessionUserId = session.currentUserId
    const currentUserController = useService(CurrentUserController, [sessionUserId])
    const selectedDepartmentAccessController = useService(SelectedDepartmentAccessController, [departmentId])

    const [role, setRole] = useState((inviteType === 'room') ? 'guest' : 'employee')
    const [customMessage, setCustomMessage] = useState('')
    const [shareList, setShareList] = useState<string[]>([])
    const [error, setError] = useState('')
    const [openPublicSearch, setOpenPublicSearch] = useState(false)
    const [selectedDepartment, setSelectedDepartment] = useState<APIDataDepartment>()
    const [selectedPeople, setSelectedPeople] = useState<APIDataBlueUser[]>([])

    useEffect(() => {
        selectedDepartmentAccessController.init(sessionUserId)
    }, [sessionUserId, selectedDepartmentAccessController])


    useEffect(() => {
        if (onChange) {
            const selectedUserIds = selectedPeople?.map(p => p.id) || []

            onChange({
                to: [...shareList, ...selectedUserIds] as string[],
                roles: [role],
                message: customMessage
            })
        }
    }, [shareList, role, customMessage, onChange, selectedPeople])

    const validator = (val: string) => {
        const isEmail = val?.includes('@')

        if (isEmail) {
            if (!EmailValidator.validate(val)) {
                return 'Invalid email address'
            } else if (val.length > 80) {
                return 'Value entered is too long'
            }
        } else {
            const phoneNumber = parsePhoneNumberFromString(val, 'US')
            if (!phoneNumber?.isValid()) {
                return 'Invalid phone number'
            }
        }
        return true
    }
    const transform = (val: string) => {
        const isEmail = val?.includes('@')
        if (isEmail) return val

        const cleanedNumber = val.replace(/\D/g, '')
        const formattedNumber = cleanedNumber.substring(0, 3) + '-' + cleanedNumber.substring(3, 6) + '-' + cleanedNumber.substring(6, 10)
    
        return formattedNumber
    }

    const handleChange = (selectedOptions: CreatableDropdownOption[]) => {
        const values = selectedOptions.map((option) => option.value)
        setShareList(values)
    }

    const charactersRemaining = 1000 - (customMessage?.length || 0)
    const isExecutive = selectedDepartmentAccessController.role === 'executive'
    const isAdmin = currentUserController.isAdmin
    
    const handleRoleOptions = () => {
        if (inviteType === 'department') {
            if (isExecutive || isAdmin) return roles.filter(r => r.value !== 'guest')

            return roles.filter(r => r.value !== 'guest' && r.value !== 'executive')
        }

        return roles
    }

    return (
        <Col {...otherProps}>
            {(openPublicSearch) &&
                <Row className="mb-4">
                    <Col>
                        <Row align="between">
                            <Label
                                text="Invite people from other agencies..."
                            />
                            <Button
                                color="link"
                                onClick={() => {
                                    setSelectedPeople([])
                                    setOpenPublicSearch(false)
                                }}>
                                close
                            </Button>
                        </Row>
                        <DepartmentSearchSelect
                            selected={[selectedDepartment] as Record<string, any>[]}
                            onSelect={setSelectedDepartment}
                            placeholder='Select agency first...'
                            className="w-100 mb-2"
                        />
                        <PeopleSearchSelect
                            departmentId={selectedDepartment?.id as number}
                            searchScope="public"
                            showPosition
                            isMulti
                            forceShowDepartment
                            selected={selectedPeople as APIDataBlueUser[]}
                            isDisabled={!selectedDepartment}
                            onSelect={setSelectedPeople as (selected: APIDataBlueUser | APIDataBlueUser[]) => void}
                            className="w-100"
                        />
                    </Col>
                </Row>
            }
            <Row align="between">
                <Label
                    text="To"
                    hint="Email addresses or U.S. mobile numbers. Message and data rates may apply"
                    htmlFor={`sendTo-${inviteType}`}
                />
                {(showPublicSearch && !openPublicSearch) &&
                    <Button
                        color="link"
                        onClick={() => setOpenPublicSearch(true)}>
                        Find people in other agencies
                    </Button>
                }
            </Row>
            <Row>
                <CreatableDropdown
                    id={`sendTo-${inviteType}`}
                    value={shareList.map((value) => ({ value, label: value }))}
                    onChange={handleChange}
                    onError={(error) => setError(error)}
                    validator={validator}
                    transform={transform}
                    placeholder='Please enter an email or phone number'
                    multi={true}
                    className='w-100'
                />

            </Row>
            {(error) &&
                <InfoBox
                    color="danger"
                    className="mt-2 px-3 py-1">
                    {error}
                </InfoBox>
            }
            {(showRole) &&
                <>
                    <Label
                        text="Set a role for all invites"
                        htmlFor="role"
                        className="mt-4"
                    />
                    <Dropdown
                        id="role"
                        options={handleRoleOptions()}
                        menuPortalTarget={null}
                        onChange={(s) => setRole((s as any).value)}
                    />
                </>
            }
            <Label
                text="Custom message"
                htmlFor="customMessage"
                optional
                className="mt-4"
            />
            <Textarea
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value?.substring(0, 1000))}
                id="customMessage"
            />
            <Text
                color="muted"
                size="smaller">
                1000 characters max. You have <strong className={classNames({ 'text-danger': charactersRemaining < 100 })}>{charactersRemaining}</strong> characters remaining.
            </Text>
        </Col>
    )
})

export { InviteByEmailForm }

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { observer } from 'mobx-react-lite'
import 'react-dates/initialize'
import ReactDatePicker from 'react-datepicker'

// evertel
import { Card, Input, Col, Row, Modal, ModalBody, useUI, Checkbox, ModalHeader, Text, Button } from '@evertel/web/ui'
import { Message } from '../../Message'
import { useService } from '@evertel/di'
import { MessageSearchController } from '@evertel/message'
import { Analytics } from '@evertel/analytics'
import { FetchUserService } from '@evertel/blue-user'

interface MessageSearchProps {
    visible: boolean
    modelType: 'room' | 'thread',
    modelId: number, // roomId or threadId
    modelName: string, // room/thread name
    onClose?: () => void
}


const MessageSearchModal: React.FC<MessageSearchProps> = observer(({
    modelType = 'room',
    modelId,
    modelName,
    visible,
    onClose
}) => {

    const messageSearchController = useService(MessageSearchController, [modelId])
    const fetchUserService = useService(FetchUserService, [])
    const analytics = useService(Analytics, [])

    const { addToast } = useUI()

    const [isLoading, setIsLoading] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null])
    const [isRetracted, setIsRetracted] = useState(false)
    const [showOnlyMe, setShowOnlyMe] = useState(false)
    const [showOnlyNotMe, setShowOnlyNotMe] = useState(false)
    const [isUrgent, setIsUrgent] = useState(false)
    const isRoom = (modelType === 'room')

    const resetSearch = () => {
        setSearchText('')
        setDateRange([null, null])
        setIsRetracted(false)
        setShowOnlyMe(false)
        setShowOnlyNotMe(false)
        setIsUrgent(false)
    }

    useEffect(() => {
        if (visible) {
            resetSearch()
            messageSearchController.reset()
            messageSearchController.init(modelId, modelType)

            if (isRoom) {
                analytics.logView('/rooms/search', true)
            } else {
                analytics.logView('/threads/search', true)
            }
        }

    }, [visible, modelId, modelType, messageSearchController, isRoom, analytics])

    const search = useCallback(() => {
        // must be at least two characters
        if (searchText.length < 2) {
            return
        }

        setIsLoading(true)

        try {
            messageSearchController.debouncedSearch()
        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }
        setIsLoading(false)

    }, [messageSearchController, searchText])

    /**
     * There's a chance the owners of the messages won't exist in the store so must be retrieved
     */
    const retrieveMessageUsers = useCallback(() => {
        const ownerIds = messageSearchController.messages.map(m => m.ownerId)
        
        if (modelType === 'room') {
            fetchUserService.fetchSpecificUsersFromRoom(modelId, ownerIds)
        } else {
            fetchUserService.fetchSpecificUsersFromThread(modelId, ownerIds)
        }
    }, [messageSearchController, modelId, modelType])

    useEffect(() => {
        if (messageSearchController.messages.length)
            retrieveMessageUsers()
    }, [messageSearchController.messages])

    useEffect(() => {
        messageSearchController.setSearchTerm(searchText)
        search()
    }, [searchText, messageSearchController, search])


    useEffect(() => {
        const [startDate, endDate] = dateRange

        //both or none
        if ((startDate && endDate) || (!startDate && !endDate)) {
            const adjustedStartDate = startDate 
                ? moment(startDate).startOf('day').toDate()
                : null
            const adjustedEndDate = endDate 
                ? moment(endDate).endOf('day').toDate()
                : null
    
            messageSearchController.setStartDate(adjustedStartDate)
            messageSearchController.setEndDate(adjustedEndDate)

            search()
        }
    }, [dateRange, messageSearchController, search])

    const onCheckBoxChange = (e) => {
        switch (e.name) {
            case 'onlyMe':
                setShowOnlyMe(e.checked)
                setShowOnlyNotMe(false)
                messageSearchController.setOnlyMe(e.checked)
                messageSearchController.setNotMe(false)
                break
            case 'notMe':
                setShowOnlyMe(false)
                setShowOnlyNotMe(e.checked)
                messageSearchController.setOnlyMe(false)
                messageSearchController.setNotMe(e.checked)
                break
            case 'urgent':
                setIsUrgent(e.checked)
                messageSearchController.setUrgent(e.checked)
                break
            case 'retracted':
                setIsRetracted(e.checked)
                messageSearchController.setRetracted(e.checked)
                break
            default:
                break
        }

        search()
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            size="xl">
            <ModalHeader
                title={`Search for Messages in ${modelName}`}
            />
            <ModalBody>
                <Row className="flex-wrap">
                    <Col md={7} className="order-2 order-lg-1 bg-light rounded p-3">
                        {(!!messageSearchController.messagesCount) &&
                            <Text
                                color="muted"
                                italic
                                className="mb-2">
                                {messageSearchController.messagesCount} results
                            </Text>
                        }
                        {(isLoading) &&
                            <Text italic>
                                Searching {modelType}...
                            </Text>
                        }
                        {/* @ts-ignore */}
                        <PerfectScrollbar
                            options={{ suppressScrollX: true }}
                            style={{
                                height: '75vh'
                            }}>

                            {messageSearchController.messages?.map(msg => {
                                if (msg.type === 'user') {
                                    return (
                                        <Card
                                            className="pb-2 page"
                                            key={msg.id}>
                                            <Text
                                                italic
                                                size="smaller"
                                                className="pl-2 pt-2">
                                                Posted {(isRoom) && <>to <Text bold>{modelName}</Text></>} on <Text bold>{moment(msg.publishedDate).format('MMM DD, YYYY')}</Text>
                                            </Text>
                                            <Col>
                                                <Message
                                                    message={msg}
                                                    modelType={modelType}
                                                    showHoverBar={false}
                                                    forceHeader={true}
                                                    highlightString={searchText}
                                                    isFromSearchModal={true}
                                                    allowRedacted={true}
                                                />
                                            </Col>
                                        </Card>
                                    )
                                } else {
                                    return null
                                }
                            })}

                            {(!messageSearchController.messages?.length && !isLoading) &&
                                <Text
                                    color="muted"
                                    italic>
                                    No results. Try modifying your search
                                </Text>
                            }
                        </PerfectScrollbar>
                    </Col>
                    <Col className="order-1 order-lg-2 p-3">

                        <div className="mb-3">
                            <Input
                                type="text"
                                name="searchText"
                                value={searchText}
                                className="p-3"
                                autoFocus
                                style={{
                                    border: '2px solid #8f9ba6'
                                }}
                                placeholder="Search for keywords..."
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </div>
                        <Col className="mb-3">
                            <Text
                                tag="h4"
                                className="pb-2">
                                    Filter by
                            </Text>
                            <div className="border-bottom mb-3">
                                <strong>People</strong>
                            </div>
                            <Checkbox
                                label="Only search messages from me"
                                id="onlyMe"
                                name="onlyMe"
                                checked={showOnlyMe}
                                onChange={onCheckBoxChange}
                            />
                            <Checkbox
                                id="notMe"
                                name="notMe"
                                label="Don't search messages from me"
                                checked={showOnlyNotMe}
                                onChange={onCheckBoxChange}
                            />
                        </Col>

                        <Col className="mb-3">
                            <div className="border-bottom mb-3">
                                <strong>Attributes</strong>
                            </div>
                            <Checkbox
                                id="urgent"
                                name="urgent"
                                label="Urgent Messages Only"
                                checked={isUrgent}
                                onChange={onCheckBoxChange}
                            />
                            <Checkbox
                                id="retracted"
                                name="retracted"
                                label="Retracted Messages Only (by me)"
                                checked={isRetracted}
                                onChange={onCheckBoxChange}
                            />
                        </Col>

                        <Col className="mb-3">
                            <div className="border-bottom mb-3">
                                <strong>Date Range</strong>
                            </div>
                            <ReactDatePicker
                                selectsRange={true}
                                startDate={dateRange[0]}
                                endDate={dateRange[1]}
                                onChange={(update) => {
                                    setDateRange(update)
                                }}
                                isClearable={true}
                                className="form-control"
                                placeholderText="Select range..."
                                monthsShown={2}
                                popperPlacement='auto'
                            />
                        </Col>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
})

export { MessageSearchModal }

import React, {createContext, useContext, ReactNode} from 'react'

type Value = object | undefined

const ValueContext = createContext<Value>({})

export const ValueProvider = ({
    value, children
}: ValueProviderProps) => {
    return <ValueContext.Provider value={value}>
        {children}
    </ValueContext.Provider>
}

type ValueProviderProps = {
    value?: Value,
    children: ReactNode
}

export const useValue = () => {
    return useContext(ValueContext)
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Col, Row, Modal, ModalBody, ModalHeader, RoomSearchSelect, Text, Button, Icon, useUI } from '@evertel/web/ui'
import { SessionState } from '@evertel/session'
import { useService } from '@evertel/di'
import { DepartmentController } from '@evertel/department'
import { APIDataRoom } from '@evertel/types'
import { ForwardMessageInput } from './forward/ForwardMessageInput'
import { ForwardMessagePreview } from './forward/ForwardMessagePreview'
import { MessageForwardController } from '@evertel/message'
interface ForwardMessageModalProps {
    visible: boolean,
    onClose: () => void,
    messageId?: number,
}

const ForwardMessageModal: React.FC<ForwardMessageModalProps> = observer(({
    visible,
    onClose,
    messageId
}) => {

    const session = useService(SessionState, [])
    const history = useHistory()
    const { addToast } = useUI()

    const sessionDepartmentId = session.selectedDepartmentId
    const sessionUserId = session.currentUserId

    const forwardMessageController = useService(MessageForwardController, [])
    const departmentController = useService(DepartmentController, [sessionDepartmentId])

    const [selectedRoom, setSelectedRoom] = useState<APIDataRoom>(null)
    const [message, setMessage] = useState<string>('')
    const [isUrgent, setIsUrgent] = useState<boolean>(false)
    const [loading, setIsLoading] = useState(false)

    useEffect(() => {
        forwardMessageController.init(messageId)
    }, [forwardMessageController, messageId])

    useEffect(() => {
        departmentController.init(sessionDepartmentId)
    }, [departmentController, sessionDepartmentId])

    const onCloseModal = () => {
        setSelectedRoom(null)
        setMessage('')
        setIsUrgent(false)

        onClose()
    }

    const toggleUrgent = () => {
        setIsUrgent(!isUrgent)
    }

    const onChangeMessage = (message: string) => {
        setMessage(message)
    }

    const onSubmit = async () => {
        if (!selectedRoom?.id) {
            addToast({
                title: 'Error',
                color: 'danger',
                message: 'No room selected. Try again by adding a room'
            })

            return
        }

        setIsLoading(true)

        const data = { roomId: selectedRoom?.id, messageText: message, isUrgent: isUrgent }

        try {
            await forwardMessageController.forwardMessage(messageId, data)
        } catch (error: any) {
            addToast({
                title: 'Error',
                color: 'danger',
                message: error.message
            })
            setIsLoading(false)
            return
        }
        history.push(`/room/${selectedRoom?.id}`)
        onCloseModal()
        setIsLoading(false)
    }

    return (
        <Modal
            visible={visible}
            onClose={onCloseModal}
            keyboard={true}
            size="lg"
            style={{ padding: 15 }}>
            <ModalHeader>
                <Col>
                    <Text children={'Forward this message'} tag={'h2'} bold={true} />
                    <Text children={`Select 1 room inside ${departmentController.department?.name || 'AGENCY'}`} tag={'h5'} bold={true} color="muted" />
                </Col>
            </ModalHeader>
            <ModalBody>
                <Col>
                    <Row style={{ paddingBottom: 40, zIndex: 10 }}>
                        <RoomSearchSelect
                            className="w-100"
                            searchScope="forward"
                            placeholder="Select room..."
                            departmentId={sessionDepartmentId}
                            isMulti={false}
                            selected={[selectedRoom]}
                            onSelect={(room) => setSelectedRoom(room)}
                        />
                    </Row>
                    <Row>
                        <ForwardMessageInput
                            message={message}
                            isUrgent={isUrgent}
                            toggleUrgent={toggleUrgent}
                            onChangeMessage={onChangeMessage}
                        />
                    </Row>
                    <Row id="forwardMessageInput"/>
                    <Row style={{ paddingTop: 40, paddingBottom: 40 }}>
                        <ForwardMessagePreview forwardMessage={forwardMessageController.message} />
                    </Row>
                    <Row align="right">
                        <Button
                            color="info"
                            loading={loading}
                            onClick={onSubmit}>
                            <Icon name={'share'} /> Forward
                        </Button>
                    </Row>
                </Col>
            </ModalBody>
        </Modal>
    )
})

export {
    ForwardMessageModal
}
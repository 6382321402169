import React from 'react'
import { spinner } from '@evertel/web/assets'

interface SpinnerProps {
    width?: number,
    invert?: boolean,
    style?: any
}

const Spinner: React.FC<SpinnerProps> = ({
    width = 50,
    invert,
    style,
    ...otherProps
}) => {
    const _style = invert ? {filter: 'brightness(1000%)'} : {}

    return (
        <img
            src={spinner}
            className="evertel-spinner"
            alt="Loading..."
            width={width}
            style={{
                ...style,
                ..._style
            }}
            {...otherProps}
        />
    )
}

export { Spinner }

import React from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import { IconLink, IconLinkProps } from '@evertel/web/ui'
import classNames from 'classnames'

interface DrawerNavItemLinkProps extends IconLinkProps {
    selectedTo?: string
}

const DrawerNavItemLink: React.FC<DrawerNavItemLinkProps> = observer(({
    to,
    selectedTo,
    selected,
    className,
    ...otherProps
}) => {
    const location = useLocation()

    const isSelected = React.useMemo(() => {
        if (selected !== undefined) return selected
        if (selectedTo) return location.pathname.includes(selectedTo)
        if (to) return location.pathname.includes(to)
        return false
    }, [location.pathname, to, selectedTo, selected])

    return (
        <IconLink
            to={to}
            selected={isSelected}
            className={classNames('drawer-item-link', className)}
            {...otherProps}
        />
    )
})

export { DrawerNavItemLink }
//******************************************************
// HOC USED TO WRAP WITH CONTEXT
//******************************************************
import { forwardRef } from 'react'



function consumeContext(ChildConsumer, ContextAPI) {
    return forwardRef(({ context: parentContext = {}, ...props }, ref) => (
        <ContextAPI>
            {context => (
                <ChildConsumer
                    {...props}
                    ref={ref}
                    context={{
                        ...parentContext,
                        ...context
                    }}
                />
            )}
        </ContextAPI>
    ))
}

function ContextConsumerHOC(...ContextAPIs) {
    return ComposedComponent => {
        // Recursively consume the APIs only once.
        return ContextAPIs.reduce(consumeContext, ComposedComponent)
    }
}

export default ContextConsumerHOC

import { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Select from 'react-select'
// evertel
import { roles } from '@evertel/constants'


const propTypes = {
    selected: PropTypes.array,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,

    // callbacks
    onSelect: PropTypes.func.isRequired
}

const defaultProps = {
    selected: [],
    isMulti: false,
    placeholder: 'Select...'
}


const RoleSelect = observer(class RoleSelect extends Component {

    bubbleEvent = (propertyName, event) => {
        if (this.props[propertyName] && typeof this.props[propertyName] === 'function') {
            this.props[propertyName](event)
        }
    }

    onSelect = (e) => {
        //console.log(e)

        this.bubbleEvent('onSelect', e)
    }


    render() {
        const { selected, isMulti, placeholder } = this.props

        //console.log('selected', selected)

        return (
            <Select
                name="state"
                options={roles}
                value={selected}
                onChange={this.onSelect}
                isMulti={isMulti}
                placeholder={placeholder}
                className="react-select"
                classNamePrefix="select"
            />
        )
    }
})

RoleSelect.propTypes = propTypes
RoleSelect.defaultProps = defaultProps
export default RoleSelect


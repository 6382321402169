import { makeAutoObservable } from 'mobx'
import { decorate, inject, injectable } from '@evertel/di'
import moment from 'moment'
import { Api } from '@evertel/api'
import debugModule from 'debug'
const debug = debugModule('app:IdleTimerService')

// **********************************************
// This singleton tracks and auto-updates the
// idle time for the current user. No need to
// update the timer manually.
// **********************************************

const ONE_MIN_IN_MS = 60 * 1000
const USER_ACTIVITY_TIMEOUT_POLL_INTERVAL_MS =  ONE_MIN_IN_MS * 10 // every 10 mins
const USER_ACTIVITY_TIMEOUT_THRESHOLD_IN_MIN = 120 // 2hrs

// TODO: This is a Web only implementation and should be move to a Web Lib
class IdleTimerService {
    lastActivity = moment()
    isAppActive = true

    private idleTimerPollInterval = undefined // holds the setInterval timer
    
    onIdleCallback?: () => void

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    // onIdleCallback is called when the user has been idle for the specified amount of time above. in web its used to call logoutService.logout()
    // if we reference LogoutService directly, we would have a circular dependency 
    setOnIdleCallback(onIdleCallback: () => void) {
        this.onIdleCallback = onIdleCallback
    }

    init = () => {
        this.lastActivity = moment()

        this.start()

        document.addEventListener('visibilitychange', this.onVisibilityChange)
        document.addEventListener('mousedown', this.reset)
    }

    onVisibilityChange = () => {
        debug('App Visibility:', document.visibilityState)

        this.isAppActive = !document.hidden

        if (!document.hidden) {
            this.reset()
        }
    }

    start = () => {
        this.stop()

        this.idleTimerPollInterval = setInterval(() => {
            debug('Idle Timer - Time Inactive:', this.idleTimerMinutesInactive)

            if (this.idleTimerMinutesInactive >= USER_ACTIVITY_TIMEOUT_THRESHOLD_IN_MIN) {
                this.destroyTimers()
                if (this.onIdleCallback) this.onIdleCallback()
            }
        }, USER_ACTIVITY_TIMEOUT_POLL_INTERVAL_MS)

    }

    stop = () => {
        if (this.idleTimerPollInterval) clearInterval(this.idleTimerPollInterval)
    }

    reset = () => {
        debug('Idle Timer - reset activity')
        if (this.isAppActive) {
            this.lastActivity = moment()
        }
    }

    destroyTimers = () => {
        this.stop()
        document.removeEventListener('mousedown', this.reset)
        document.removeEventListener('visibilitychange', this.onVisibilityChange)
    }

    get idleTimerMinutesInactive() {
        const now = moment()
        return now.diff(this.lastActivity, 'minutes')
    }
}

decorate(injectable(), IdleTimerService)
decorate(inject(Api), IdleTimerService, 0)

export { IdleTimerService }

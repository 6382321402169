import React, { useMemo, useEffect, useCallback, useState, useRef } from 'react'
import {
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    PaginationState,
    SortingState,
    ColumnDef,
    flexRender,
    OnChangeFn,
    FilterFn,
    Column,
    Row,
    RowData
} from '@tanstack/react-table'
import classNames from 'classnames'
import Select from 'react-select'
import { debounce } from 'lodash'

//evetel
import { Icon } from '../Icon'
import { ContentPlaceholder } from '../ContentPlaceholder'
import { InfoBox } from '../InfoBox'
import { Input } from '../../form'
import { Spinner } from '../Spinner'

// Constant for pagination threshold
const PAGINATION_FULL_DISPLAY_THRESHOLD = 8



// Types and Interfaces
declare module '@tanstack/react-table' {
    //global modification to type for column Meta data
    interface ColumnMeta<TData extends RowData, TValue,> {
        align: string
    }
}

export type TanTableColumnDef<T,> = ColumnDef<T> | {
    size?: number | string;
}

interface TanTableOptions<T,> {
    // Pagination options
    pageSizeList: number[]
    pageSize: number
    pageNumber: number
    showPagination: boolean
    showTotal: boolean

    // Sorting options
    sortName?: string
    sortOrder?: 'asc' | 'desc' | null
    enableSortingRemoval?: boolean
    sortUp?: React.ReactNode
    sortDown?: React.ReactNode
    sortOff?: React.ReactNode

    // Table display options
    responsive?: boolean
    responsiveHeight?: string
    fixedHeader?: boolean
    truncateCells?: boolean
    emptyState?: React.ReactNode
    scrollOnPageChange?: boolean

    // Column options
    defaultColumn?: Partial<ColumnDef<T>>
    globalSearch?: boolean
    globalSearchFn?: FilterFn<T>
    globalSearchValue?: string
    globalSearchDebounce?: number
    columnVisibility?: Record<string, boolean>;
    onColumnVisibilityChange?: (columnVisibility: Record<string, boolean>) => void;  
  
    // row sub-component expansion 
    renderSubComponent?: (props: { row: Row<T> }) => React.ReactNode
    getRowCanExpand?: (row: Row<T>) => boolean

    // Callbacks
    onPageChange?: (page: number) => void
    onPageSizeChange?: (pageSize: number) => void
    onSortChange?: (sortName: string, sortOrder: 'asc' | 'desc' | null) => void
    onGlobalSearchChange?: (value: string) => void
}

interface TanTableProps<T,> extends React.TableHTMLAttributes<HTMLTableElement> {
    // Core props
    data: T[]
    columns: TanTableColumnDef<T>[]
    options: Partial<TanTableOptions<T>>

    // Row-related props
    rowProps?: React.HTMLAttributes<HTMLTableRowElement>
    onRowClick?: (row: Row<T>) => void

    // Remote data handling
    remote?: boolean
    rowCount?: number

    // Loading state
    loading?: boolean
}

// Default options
const defaultOptions: Partial<TanTableOptions<any>> = {
    pageSizeList: [25, 50, 100],
    pageSize: 99999, //-1 is unlimited page size (apparently -1 breaks this?)
    pageNumber: 1,
    showPagination: true,
    showTotal: true,

    sortOrder: null,
    enableSortingRemoval: false,
    sortUp: <Icon name='sort-up'/>,
    sortDown: <Icon name='sort-down'/>,
    sortOff: <Icon name='sort' color='muted'/>,

    responsive: false,
    responsiveHeight: 'calc(100vh - 150px)',
    fixedHeader: true,
    truncateCells: false,
    scrollOnPageChange: true,

    defaultColumn: {size: 100},
    globalSearch: false,
    globalSearchDebounce: 300 // Default debounce time in milliseconds
}

const useContainerWidth = () => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [containerWidth, setContainerWidth] = useState(0)

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth)
            }
        }

        const resizeObserver = new ResizeObserver(updateWidth)
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current)
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current)
            }
        }
    }, [])

    return { containerRef, containerWidth }
}

const getColumnWidth = (column: Column<any, unknown>, containerWidth: number) => {
    const size = column.columnDef.size
    
    if (typeof size === 'string') {
        // @ts-expect-error too new for our typing
        if (size.endsWith('%')) {
            const percentage = parseFloat(size)
            return `${Math.floor(containerWidth * (percentage / 100))}px`
        }
        return size
    }
    
    return column.getSize() ? `${column.getSize()}px` : 'auto'
}

const getAlignmentClass = (column: Column<any, unknown>) => {
    switch (column.columnDef.meta?.align) {
        case 'left':
            return 'text-start'
        case 'center':
            return 'text-center'
        case 'right':
            return 'text-end'
        default:
            return '' // No class if no alignment is specified
    }
}

export function TanTable<T extends object,>({
    // Core props
    data,
    columns,
    options,

    // Row-related props
    rowProps,
    onRowClick,

    // Remote data handling
    remote = false,
    rowCount,

    // Loading state
    loading = false,

    // HTML table props
    className,
    ...tableProps
}: TanTableProps<T>) {

    const { containerRef, containerWidth } = useContainerWidth()
    const responsiveRef = useRef<HTMLDivElement>(null)

    // Merge provided options with default options
    const mergedOptions = useMemo(() => ({ ...defaultOptions, ...options }), [options])

    // State for pagination and sorting
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: mergedOptions.pageNumber - 1,
        pageSize: mergedOptions.pageSize
    })

    const [sorting, setSorting] = useState<SortingState>(
        mergedOptions.sortName 
            ? [{ id: mergedOptions.sortName, desc: mergedOptions.sortOrder === 'desc' }] 
            : []
    )

    const [globalFilter, setGlobalFilter] = useState(mergedOptions.globalSearchValue || '')

    // Callbacks for pagination and sorting changes
    const handlePageChange = useCallback((newPage: number) => {
        const currentPage = pagination.pageIndex + 1
        const externalPage = mergedOptions.pageNumber

        if (newPage !== currentPage) {
            setPagination(prev => ({ ...prev, pageIndex: newPage - 1 }))
        }
        if (newPage !== externalPage) {
            mergedOptions.onPageChange?.(newPage)
        }

        if (mergedOptions.scrollOnPageChange) {
            if (containerRef.current) {
                containerRef.current.scrollIntoView({
                    behavior: 'instant',
                    block: 'start'
                })
            }
            if (responsiveRef.current) {
                responsiveRef.current.scrollTo({top: 0})
            }
        }

    }, [pagination.pageIndex, mergedOptions.pageNumber, mergedOptions.onPageChange, mergedOptions.scrollOnPageChange])

    const handlePageSizeChange = useCallback((newPageSize: number) => {
        if (newPageSize !== pagination.pageSize) {
            setPagination(prev => ({ ...prev, pageSize: newPageSize }))
        }
        if (newPageSize !== mergedOptions.pageSize) {
            mergedOptions.onPageSizeChange?.(newPageSize)
        }
    }, [pagination.pageSize, mergedOptions.pageSize, mergedOptions.onPageSizeChange])

    const handleSortChange: OnChangeFn<SortingState> = useCallback((updater) => {
        setSorting(old => {
            const newSorting = (typeof updater === 'function' ) ? updater(old) : []
            
            const newSortName = newSorting.length > 0 ? newSorting[0].id : ''
            const newSortOrder = newSorting.length > 0 ? (newSorting[0].desc ? 'desc' : 'asc') : null

            const sortChanged = newSortName !== mergedOptions.sortName || newSortOrder !== mergedOptions.sortOrder

            // Notify external controller if sort has changed
            if (sortChanged) {
                mergedOptions.onSortChange?.(newSortName, newSortOrder)
            }

            return newSorting
        })
    }, [mergedOptions.sortName, mergedOptions.sortOrder, mergedOptions.onSortChange])

    // Debounced function for remote search
    const debouncedRemoteSearch = useCallback(
        debounce((value: string) => {
            if (mergedOptions.onGlobalSearchChange) {
                mergedOptions.onGlobalSearchChange(value)
            }
        }, remote ? mergedOptions.globalSearchDebounce : 100),
        [remote, mergedOptions.onGlobalSearchChange, mergedOptions.globalSearchDebounce]
    )

    // Global search handler with immediate local update and debounced remote update
    const handleGlobalSearchChange = useCallback((value: string) => {
        // Immediately update the local filter for responsive local searching
        setGlobalFilter(value)

        // Trigger the debounced remote search
        debouncedRemoteSearch(value)
    }, [debouncedRemoteSearch])

    // Process columns to ensure enableSorting is set
    const processedColumns = useMemo(() => {
        return columns.map((column: ColumnDef<T>) => ({
            ...column,
            enableSorting: column.enableSorting ?? false
        }))
    }, [columns])

    // Initialize TanStack table
    const table = useReactTable({
        data,
        columns: processedColumns as ColumnDef<T>[],
        defaultColumn: mergedOptions.defaultColumn,
        pageCount: remote ? Math.ceil((rowCount ?? 0) / pagination.pageSize) : undefined,
        state: {
            pagination,
            sorting,
            globalFilter: remote ? undefined : globalFilter, // Only use globalFilter for non-remote data
            columnVisibility: mergedOptions.columnVisibility
        },
        onColumnVisibilityChange: mergedOptions.onColumnVisibilityChange,
        onPaginationChange: setPagination,
        onSortingChange: handleSortChange,
        onGlobalFilterChange: remote ? undefined : setGlobalFilter, // Only set global filter for non-remote data
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: options.getRowCanExpand ?? (() => true),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: remote ? undefined : getFilteredRowModel(), // Disable client-side filtering for remote data
        globalFilterFn: remote ? undefined : (mergedOptions.globalSearchFn || 'auto'),
        manualPagination: remote,
        manualSorting: remote,
        manualFiltering: remote,
        enableMultiSort: false,
        enableSortingRemoval: mergedOptions.enableSortingRemoval,
        sortDescFirst: false
    })

    // Sync external page number with internal state
    useEffect(() => {
        if (mergedOptions.pageNumber - 1 !== pagination.pageIndex) {
            setPagination(prev => ({ ...prev, pageIndex: mergedOptions.pageNumber - 1 }))
        }
    }, [mergedOptions.pageNumber])

    // Sync external page size with internal state
    useEffect(() => {
        if (mergedOptions.pageSize !== pagination.pageSize) {
            setPagination(prev => ({ ...prev, pageSize: mergedOptions.pageSize }))
        }
    }, [mergedOptions.pageSize])

    // Sync external sorting with internal state
    useEffect(() => {
        const newSorting = mergedOptions.sortName && mergedOptions.sortOrder
            ? [{ id: mergedOptions.sortName, desc: mergedOptions.sortOrder === 'desc' }]
            : []
        if (JSON.stringify(newSorting) !== JSON.stringify(sorting)) {
            setSorting(newSorting)
        }
    }, [mergedOptions.sortName, mergedOptions.sortOrder])

    // Sync external global search value with internal state
    useEffect(() => {
        if (mergedOptions.globalSearchValue !== undefined && mergedOptions.globalSearchValue !== globalFilter) {
            setGlobalFilter(mergedOptions.globalSearchValue)
        }
    }, [mergedOptions.globalSearchValue])

    // Calculate pagination range
    const paginationRange = useMemo(() => {
        const totalPageCount = table.getPageCount()
        const currentPage = pagination.pageIndex + 1
        const range = []

        if (totalPageCount <= PAGINATION_FULL_DISPLAY_THRESHOLD) {
            for (let i = 1; i <= totalPageCount; i++) {
                range.push(i)
            }
        } else {
            if (currentPage <= 3) {
                range.push(1, 2, 3, 4, '...', totalPageCount)
            } else if (currentPage >= totalPageCount - 2) {
                range.push(1, '...', totalPageCount - 3, totalPageCount - 2, totalPageCount - 1, totalPageCount)
            } else {
                range.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPageCount)
            }
        }

        return range
    }, [table.getPageCount(), pagination.pageIndex])

    // Calculate pagination display values
    const paginationDisplayValues = useMemo(() => {
        const totalRowCount = remote ? (rowCount ?? 0) : data.length
        const currentPageRowCount = table.getRowModel().rows.length
        
        // For remote data, we assume the data is already filtered by the server
        const isFiltered = !remote && mergedOptions.globalSearch && currentPageRowCount < totalRowCount
    
        const from = totalRowCount === 0 ? 0 : (pagination.pageIndex * pagination.pageSize) + 1
        const to = totalRowCount === 0 ? 0 : Math.min((pagination.pageIndex + 1) * pagination.pageSize, remote ? totalRowCount : (isFiltered ? currentPageRowCount : totalRowCount))
    
        return {
            from,
            to,
            total: remote ? totalRowCount : (isFiltered ? currentPageRowCount : totalRowCount),
            rawTotal: totalRowCount,
            isFiltered
        }
    }, [pagination.pageIndex, pagination.pageSize, remote, rowCount, data.length, table.getRowModel().rows.length, mergedOptions.globalSearch])

    const pageSizeOptions = useMemo(() => {
        if (!mergedOptions.pageSizeList || mergedOptions.pageSizeList.length === 0) {
            return []
        }
        const currentPageSize = pagination.pageSize
        if (currentPageSize !== -1 && !mergedOptions.pageSizeList.includes(currentPageSize)) {
            return [...mergedOptions.pageSizeList, currentPageSize].sort((a, b) => a - b)
        }
        return mergedOptions.pageSizeList
    }, [mergedOptions.pageSizeList, pagination.pageSize])

    // Render loading state
    const renderLoadingState = () => {
        let loadingRows = 10
        if (remote && pagination.pageSize && mergedOptions.pageSizeList?.length) {
            loadingRows = Math.min(pagination.pageSize, 100)
        }
        return (
            <>
                {[...Array(loadingRows)].map((_, rowIndex) => (
                    <tr key={`loading-row-${rowIndex}`}>
                        {columns.map((column, colIndex) => (
                            <td key={`loading-cell-${rowIndex}-${colIndex}`}>
                                <ContentPlaceholder
                                    width='100%'
                                    height={17}
                                    className='my-1'
                                />
                            </td>
                        ))}
                    </tr>
                ))}
            </>
        )
    }

    return (
        <div
            ref={containerRef}
            className={classNames( 'tan-table-container' )}>
            {mergedOptions.globalSearch && (
                <div className="d-flex justify-content-end mb-3">
                    <div className="input-group" style={{ maxWidth: '250px' }}>
                        <Input
                            type="text"
                            value={globalFilter}
                            onChange={(e) => handleGlobalSearchChange(e.target.value)}
                            placeholder="Search..."
                            className="form-control"
                        />
                        {globalFilter && (
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => handleGlobalSearchChange('')}
                                aria-label="Clear search"
                            >
                                <Icon name="x" />
                            </button>
                        )}
                    </div>
                </div>
            )}
            <div 
                ref={responsiveRef}
                {...(mergedOptions.responsive && {
                    className: classNames('table-responsive', className),
                    style: { maxHeight: `max(200px, ${mergedOptions.responsiveHeight})` }
                })}
            >
                <table
                    {...tableProps}
                    className={classNames(
                        'table',
                        {
                            'table-layout-fixed': !mergedOptions.responsive,
                            'table-fixed-header': mergedOptions.fixedHeader,
                            'table-truncate-cells': mergedOptions.truncateCells
                        },
                        className
                    )}
                >
                    {/* Column widths */}
                    <colgroup>
                        {table.getVisibleLeafColumns().map((column, colIndex) => (
                            <col key={`col-${column.id}-${colIndex}`} style={{ width: getColumnWidth(column, containerWidth) }} />
                        ))}
                    </colgroup>

                    <thead className={classNames({ 'sticky-top pb-2': mergedOptions.fixedHeader })}>
                        {table.getHeaderGroups().map((headerGroup, hgIndex) => (
                            <tr key={`headerGroup-${headerGroup.id}-${hgIndex}`}>
                                {headerGroup.headers.map((header, headerIndex) => (
                                    <th
                                        key={`header-${header.id}-${headerIndex}`}
                                        colSpan={header.colSpan}
                                        style={{
                                            maxWidth: getColumnWidth(header.column, containerWidth)
                                        }}
                                        className={classNames(getAlignmentClass(header.column))}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div
                                                className={classNames(
                                                    'tan-table-header',
                                                    { 'cursor-pointer select-none': header.column.getCanSort() }
                                                )}
                                                onClick={header.column.getToggleSortingHandler()}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                        e.preventDefault()
                                                        header.column.getToggleSortingHandler()(e)
                                                    }
                                                }}
                                                role={header.column.getCanSort() ? 'button' : undefined}
                                                tabIndex={header.column.getCanSort() ? 0 : undefined}
                                                aria-sort={header.column.getCanSort()
                                                    ? header.column.getIsSorted() === 'asc'
                                                        ? 'ascending'
                                                        : header.column.getIsSorted() === 'desc'
                                                            ? 'descending'
                                                            : 'none'
                                                    : undefined}
                                                aria-label={`Sort by ${header.column.columnDef.header as string}`}
                                            >
                                                <span className="tan-table-header-text">
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                </span>
                                                {header.column.getCanSort() && (
                                                    <span className="tan-table-sort-icon" aria-hidden="true">
                                                        {{
                                                            asc: mergedOptions.sortUp,
                                                            desc: mergedOptions.sortDown
                                                        }[header.column.getIsSorted() as string] ?? mergedOptions.sortOff}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody>
                        {loading ? (
                            renderLoadingState()
                        ) : table.getRowModel().rows.length > 0 ? (
                            table.getRowModel().rows.map((row, rowIndex) => (
                                <React.Fragment key={`row-${row.id}-${rowIndex}`}>
                                    <tr
                                        {...rowProps}
                                        onClick={() => onRowClick && onRowClick(row)}
                                        onKeyDown={(e) => {
                                            if (onRowClick && (e.key === 'Enter' || e.key === ' ')) {
                                                e.preventDefault()
                                                onRowClick(row)
                                            }
                                        }}
                                        className={classNames(
                                            { 'cursor-pointer': onRowClick },
                                            rowProps?.className
                                        )}
                                        role={onRowClick ? 'button' : undefined}
                                        aria-label={row.getCanExpand() ? 'Press to select or expand' : 'Press to select'}
                                        tabIndex={onRowClick ? 0 : undefined}
                                        aria-expanded={row.getCanExpand() ? row.getIsExpanded() : undefined}
                                    >
                                        {row.getVisibleCells().map((cell, cellIndex) => (
                                            <td key={`cell-${cell.id}-${cellIndex}`}
                                                style={{
                                                    maxWidth: getColumnWidth(cell.column, containerWidth)
                                                }}
                                                className={classNames(getAlignmentClass(cell.column))}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                    {row.getIsExpanded() && options.renderSubComponent && (
                                        <tr
                                            role="region"
                                            aria-label={`Details for ${row.id}`}
                                        >
                                            <td colSpan={row.getVisibleCells().length}>
                                                {options.renderSubComponent({ row })}
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={columns.length}>
                                    {mergedOptions.emptyState || <InfoBox color='info'>There is no data to display</InfoBox>}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Pagination stuff */}
            {mergedOptions.showPagination && (
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center m-2">
                    {/* Page totals */}
                    <div className="order-2 order-md-1 mb-2 mb-md-0" /*style={{flexBasis: 250}}*/ >
                        {(mergedOptions.showTotal && (!loading || paginationDisplayValues.total)) ? (
                            <div>
                                {paginationDisplayValues.total > 0 ? (
                                    <>
                                        Showing {paginationDisplayValues.from} to {paginationDisplayValues.to} of {paginationDisplayValues.total} entries
                                        {paginationDisplayValues.isFiltered && ` (filtered from ${paginationDisplayValues.rawTotal} total entries)`}
                                    </>
                                ) : (
                                    'No entries to display'
                                )}
                            </div>
                        ) : ( loading &&
                            // Show loading on when the total count goes away and loading
                            <span>
                                <Spinner width={30} /> Loading...
                            </span>
                        )}
                    </div>
                    {/* Pagination links */}
                    <div className="order-1 order-md-2 mb-2 mb-md-0 d-flex justify-content-center mx-3">
                        {table.getPageCount() > 1 && (
                            <ul className="pagination mb-0">
                                {table.getPageCount() > PAGINATION_FULL_DISPLAY_THRESHOLD && (
                                    <li className={classNames('page-item', { disabled: !table.getCanPreviousPage() })}>
                                        <button 
                                            className="page-link" 
                                            onClick={() => handlePageChange(pagination.pageIndex)}
                                            disabled={!table.getCanPreviousPage()}
                                            aria-label="Previous page"
                                        >
                                            <Icon name="chevron-left" />
                                        </button>
                                    </li>
                                )}
                                {paginationRange.map((page, index) => (
                                    <li
                                        key={index}
                                        className={classNames('page-item', {
                                            active: page === pagination.pageIndex + 1,
                                            disabled: page === '...'
                                        })}
                                    >
                                        <button
                                            className="page-link"
                                            onClick={() => (page !== '...' ? handlePageChange(page as number) : null)}
                                            disabled={page === '...'}
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                                {table.getPageCount() > PAGINATION_FULL_DISPLAY_THRESHOLD && (
                                    <li className={classNames('page-item', { disabled: !table.getCanNextPage() })}>
                                        <button 
                                            className="page-link" 
                                            onClick={() => handlePageChange(pagination.pageIndex + 2)}
                                            disabled={!table.getCanNextPage()}
                                            aria-label="Next page"
                                        >
                                            <Icon name="chevron-right" />
                                        </button>
                                    </li>
                                )}
                            </ul>
                        )}
                    </div>
                    {/* Page Size Selector */}
                    {mergedOptions.pageSize !== -1 && pageSizeOptions.length > 0 && (
                        <div className="order-3 order-md-3 flex-shrink-0">
                            <Select
                                isSearchable={false}
                                menuPlacement='auto'
                                value={{ value: pagination.pageSize, label: `Show ${pagination.pageSize}` }}
                                onChange={(option) => handlePageSizeChange(Number(option?.value))}
                                options={pageSizeOptions.map(size => ({ value: size, label: `Show ${size}` }))}
                                aria-label="Number of rows per page"
                                className="react-select"
                                classNamePrefix="select"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
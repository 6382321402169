import React from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { Row, Col, Text } from '@evertel/web/ui'
import { DocumentIcon } from './DocumentIcon'
import { APIDataDocument, APIDataDocumentSchema } from '@evertel/types'

interface Props {
    schema: APIDataDocumentSchema
    document: APIDataDocument
    revisionId?: number
}

const DocumentFooter: React.FC<Props> = observer(({
    document,
    schema,
    revisionId = 0
}) => {
    return (
        <Row className="m-0">
            <DocumentIcon
                schema={schema}
                width={35}
                height={35}
            />
            <Col xs={11} className='ml-2'>
                <Text bold>
                    {schema?.name}
                </Text>
                <Text
                    size={12}
                    color="muted">
                    Last Updated: {moment(document?.updatedDate).format('ll @ HH:mm')} &nbsp;|&nbsp; {`ID: d${document?.id}.s${document?.documentSchemaId}.r${revisionId}`}
                </Text>
            </Col>
        </Row>
    )
})

export {
    DocumentFooter
}

import React, { createContext, forwardRef, HTMLAttributes, useState } from 'react'
import classNames from 'classnames'

export interface SidebarNavProps extends HTMLAttributes<HTMLUListElement> {
    className?: string
}

interface ContextProps {
  visibleGroup: string
  setVisibleGroup: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const NavContext = createContext({} as ContextProps)

const SidebarNav = forwardRef<HTMLUListElement, SidebarNavProps>(({
    children,
    className,
    ...otherProps
}, ref) => {

    const [visibleGroup, setVisibleGroup] = useState('')
    const CNavContextValues = {
        visibleGroup,
        setVisibleGroup
    }
    const classes = classNames('sidebar-nav', className)

    return (
        <ul
            ref={ref}
            className={classes}
            {...otherProps}>
            <NavContext.Provider value={CNavContextValues}>
                {React.Children.map(children, (child, index) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child as React.ReactElement<any>, {
                            key: index,
                            idx: `${index}`
                        })
                    }
                    return true
                })}
            </NavContext.Provider>
        </ul>
    )
})

export { SidebarNav }
SidebarNav.displayName = 'SidebarNav'

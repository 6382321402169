/* eslint-disable @typescript-eslint/ban-ts-comment */
import { makeAutoObservable, runInAction } from 'mobx'
// evertel
import { APIDataDocument, APIDataMedia, APIDataDocumentACL, APIDataAny, APIDataDocumentSchemaRevision } from '@evertel/types'
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { MediaUtils } from '../utils'
import { DocumentStore } from '../store'
import { DocumentSchemaStore } from '@evertel/stores'
import { normalize, validate, hasErrors, countErrors } from '@evertel/schema-parser'

type Platform = 'web' | 'mobile'

class EverdocMediaUplaodErrorEdit extends Error {
    document: APIDataDocument

    constructor(document, args) {
        super(args)
        this.name = 'EverdocMediaUploadError'
        this.document = document
    }
}
class DocumentEditController {
    documentId = 0
    name = ''
    data: APIDataAny = {}
    documentSchemaId = 0
    documentSchemaRevisionId = 0
    documentSchemaRevisions = []
    media: APIDataMedia[] = []
    ACL: APIDataDocumentACL[] = []
    schemaMedia: APIDataMedia[] = []
    loaded = false
    errors?: any = []
    platform: Platform
    mediaUploadError = false

    constructor(
        private documentStore: DocumentStore,
        private documentSchemaStore: DocumentSchemaStore,
        private api: Api,
        private mediaUtils: MediaUtils
    ) {
        makeAutoObservable(this)
    }

    async load(documentId: number, platform: Platform): Promise<APIDataDocument | undefined> {
        if (!documentId) throw new Error('Missing a document ID! (error: RN.DEC-1')

        this.loaded = false
        let revision = []

        const document = await this.fetchDocument(documentId)

        if (document?.documentSchemaRevisionId) {
            revision = await this.fetchDocumentRevisions(document.documentSchemaId, {
                where: {
                    id: document.documentSchemaRevisionId
                }
            })
        }
        
        runInAction(() => {
            this.loaded = true
            this.documentId = documentId || 0
            this.documentSchemaRevisionId = document?.documentSchemaRevisionId || 0
            this.documentSchemaRevisions = revision || []
            this.name = document?.name
            this.documentSchemaId = document?.documentSchema?.id
            this.data = document?.data as APIDataAny
            this.media = document?.media ?? []
            this.ACL = document?.ACL ?? []
            this.platform = platform

            // update stores
            this.documentSchemaStore.update(document?.documentSchema)
            this.documentStore.update(document)
        })

        // return the model not the raw data
        return this.documentStore.findById(document?.id as number)
    }

    updateData = (data: APIDataDocument) => {
        // called from views to hold the data as they edit the document (state)
        runInAction(() => {
            this.data = data as APIDataAny
        })
    }

    fetchDocument = async (documentId: number): Promise<APIDataDocument | undefined> => {
        let document: APIDataDocument | undefined

        try {
            document = await this.api.Routes.Document.getById(documentId, {
                'include': [
                    { 'relation': 'media' },
                    { 'relation': 'ACL' },
                    {
                        'relation': 'documentSchema',
                        'scope': {
                            'include': 'media'
                        }
                    }
                ]
            })
        } catch (error: any) {
            console.error('DocumentEditController.fetchDocument() ERROR:, ' + error.message)
        }

        return document
    }

    fetchDocumentRevisions = async (documentSchemaId: number, filter: any): Promise<APIDataDocumentSchemaRevision[]> => {
        return await this.api.Routes.DocumentSchema.getRevisions(documentSchemaId, filter)
    }

    update = async (notify: { isUrgent: boolean } | null): Promise<APIDataDocument | undefined> => {
        let document
        // normalize schema
        const schema = normalize(this.documentSchema?.schema)

        //console.log('RAW', toJS(this.documentSchema?.schema))
        //console.log('NORMALIZED', toJS(schema))

        // check for errors in data
        const errors = validate(
            // @ts-ignore
            this.data,
            {
                type: 'form',
                items: schema['layouts']['full']
            }
        )

        // if we have any errors, stop and throw error back to view
        if (hasErrors(errors)) {
            runInAction(() => {
                this.errors = errors
            })

            throw new Error('Please fill out all *required fields before saving.')
        }


        try {
            // process any media first
            const data = await this.mediaUtils.processDocumentMedia(
                this.platform,
                this.data,
                this.documentId,
                schema['layouts']['full']
            )
        
            // post new data
            document = await this.api.Routes.Document.putById(this.documentId, {
                name: this.name,
                data
            })

        } catch (error: any) {
            throw new EverdocMediaUplaodErrorEdit(document, error.message)
        }


        // if save & notify...
        if (notify) this.notify(notify.isUrgent)
        
        // update the DocumentStore data
        this.documentStore.update(document)

        // return the updated document model
        return this.documentStore.findById(this.documentId)
    }

    setName = (name: string) => {
        this.name = name
    }

    delete = async () => {
        // can only delete documents not previously shared
        if (!this.hasPreviousShares) {
            const resp = await this.api.Routes.Document.delById(this.documentId)

            runInAction(() => {
                // if deleted, remove from the DocumentStore
                // @ts-ignore
                if (resp?.count) {
                    this.documentStore.deleteById(this.documentId)
                }
            })
        } else {
            throw 'This cannot be deleted since it has been shared. If you want to remove this EverDoc from view, you can archive it.'
        }
    }

    archive = async (isArchived: boolean): Promise<APIDataDocument> => {
        const resp = await this.api.Routes.Document.putById(this.documentId, { isArchived })

        runInAction(() => {
            this.documentStore.update(resp)
        })

        return this.documentStore.findById(resp.id as number) as APIDataDocument
    }

    notify = (isUrgent: boolean): void => {
        this.api.Routes.Document.postNotify(this.documentId, isUrgent ?? false)
    }

    downloadCSV = async (documentId: number): Promise<Blob> => {
        // @ts-expect-error we treat it as a blob
        return this.api.Routes.Document.getDownloadCsv(documentId)
    }

    get document() {
        return this.documentStore.findById(this.documentId)
    }

    get documentSchema() {
        // returns the schema at the revision version of the doc
        if (this.documentSchemaRevisionId) {
            const revision = this.documentSchemaRevisions?.find(r => r.id === this.documentSchemaRevisionId)
            if (revision) {
                return {
                    ...this.documentSchemaStore.findById(this.documentSchemaId),
                    schema: revision.schema
                }
            } else {
                return this.documentSchemaStore.findById(this.documentSchemaId)
            }
        } else {
            return this.documentSchemaStore.findById(this.documentSchemaId)
        }
    }

    get errorsCount(): number {
        return countErrors(this.errors)
    }

    get hasPreviousShares() {
        return !!(this.ACL?.filter(acl => acl.accessType !== 'OWNER'))?.length ?? false
    }
}

decorate(injectable(), DocumentEditController)
decorate(inject(DocumentStore), DocumentEditController, 0)
decorate(inject(DocumentSchemaStore), DocumentEditController, 1)
decorate(inject(Api), DocumentEditController, 2)
decorate(inject(MediaUtils), DocumentEditController, 3)

export { DocumentEditController, EverdocMediaUplaodErrorEdit }

import { makeAutoObservable, runInAction } from 'mobx'
// evertel
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { uniqBy } from 'lodash'
import { APIDataReactionUserThrough } from '@evertel/types'
import { BlueUserStore, DEFAULT_USER_SCOPE } from '@evertel/blue-user'
import { DepartmentsAccessStore } from '@evertel/departments-access'


class MessageReactedByController {
    messageId = 0
    modelType: 'room'|'thread' = 'room'
    reactedBy: APIDataReactionUserThrough[] = []
    reactedByCount = 0
    isInfiniteScroll? = true
    page = 1
    limit = 30

    constructor(
        private api: Api,
        private blueUserStore: BlueUserStore,
        private departmentsAccessStore: DepartmentsAccessStore
    ) {
        makeAutoObservable(this)
    }

    init = async (
        messageId: number,
        modelType: 'room'|'thread',
        isInfiniteScroll = true
    ) => {

        if (!messageId) throw new Error('Missing a message ID! (error: SHARED.MRBC-1')

        this.messageId = messageId
        this.modelType = modelType
        this.isInfiniteScroll = isInfiniteScroll
        this.page = 1

        await this.fetchReactedBy()
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    fetchReactedBy = async () => {
        if (!this.messageId) return
        
        let count = this.reactedByCount
        let results = []

        // grab the total count if we don't have it already
        if (!this.reactedByCount) {
            count = await this.fetchReactedByCount()
        }

        // stop from fetching if we already got them all
        if (count > this.reactedBy?.length) {
            const endpoint = (this.modelType === 'room') ? 'RoomMessage' : 'ThreadMessage'
            results = await this.api.Routes[endpoint].getReactedByThrough(this.messageId, this.filter)

            runInAction(() => {
                this.reactedBy = (this.isInfiniteScroll) ? uniqBy([...this.reactedBy, ...results], 'id') : results
                this.blueUserStore.update(results.map(r => r.user))
                this.departmentsAccessStore.update(results.map(r => r.user.departmentsAccess).flat())
            })
        }
    }

    fetchReactedByCount = async () => {
        const endpoint = (this.modelType === 'room') ? 'RoomMessage' : 'ThreadMessage'
        const res = await this.api.Routes[endpoint].getReactedByThroughCount(this.messageId, this.filter?.where)

        runInAction(() => {
            this.reactedByCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    fetchNextPage = () => {
        this.page = this.page + 1
        this.fetchReactedBy()
    }

    get filter() {
        return {
            include: [
                {
                    relation: 'user',
                    scope: DEFAULT_USER_SCOPE
                },
                {
                    relation: 'reaction'
                }
            ],
            where: {},
            limit: this.limit,
            skip: this.limit * (this.page - 1),
            order: 'createdDate DESC'
        }
    }
}

decorate(injectable(), MessageReactedByController)
decorate(inject(Api), MessageReactedByController, 0)
decorate(inject(BlueUserStore), MessageReactedByController, 1)
decorate(inject(DepartmentsAccessStore), MessageReactedByController, 2)

export { MessageReactedByController }

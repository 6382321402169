import { observable, action, runInAction, makeObservable } from 'mobx'
import DepartmentsStore from '../DepartmentsStore'
import AppStore from '../AppStore'
import api from '../../api'
import _ from 'lodash'


// Keeps track of our login state and progress
class DepartmentsStoreAdmin {
    departments = []
    count = 0
    isBusy = false
    error = null

    constructor() {
        makeObservable(this, {
            departments: observable,
            count: observable,
            isBusy: observable,
            error: observable,
            search: action,
            clearSearch: action,
            getDepartmentsCount: action,
            createDepartment: action,
            fetchDepartment: action,
            addDepartmentToStore: action,
            fetchUsersAccess: action,
            fetchUsersAccessCount: action
        })

        
    }

    // Search contacts api then updates this.resultsIds observable, debounced
    search = _.debounce(async (filter) => {
        this.isBusy = true
        this.error = null
        let departments = []

        try {
            // get users via search contacts
            departments = await api.Department.getDepartmentsBySearch(filter)

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })
        }

        // save results
        runInAction(() => {
            this.isBusy = false
            this.departments = departments
        })
    }, 50, {leading: true})

    async clearSearch() {
        //this.resultsIds.clear()
        this.departments.clear()
        this.isBusy = false
        this.error = null
        this.search.cancel()
    }

    getDepartmentsCount = async (where) => {
        try {
            const count = (await api.Department.getDepartmentsCount(where)).count

            runInAction(() => {
                this.count = count
            })
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'DepartmentsStoreAdmin.getDepartmentsCount()',
                error: e.message || e,
                where
            })
        }
    }

    async createDepartment(data) {
        this.error = null
        this.isBusy = true

        try {
            const department = await api.Department.create(data)
            DepartmentsStore.updateFromData(department)
            return department
        } catch (e) {
            AppStore.logError({
                type: 'API post',
                message: 'DepartmentsStoreAdmin.createDepartment()',
                error: e.message || e,
                data
            })

            runInAction(() => {
                this.error = e
            })
        }

        runInAction(() => {
            this.isBusy = false
        })
    }

    async fetchDepartment(id) {
        if (!id) throw new Error('fetchDepartment requires a department ID')

        try {
            const department = await api.Department.get(id, {
                include: [ 'publicMedia']
            })
            return department
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'DepartmentsStoreAdmin.fetchDepartment()',
                error: e.message || e,
                departmentId: id
            })
        }
    }

    addDepartmentToStore = (department) => {
        DepartmentsStore.updateFromData(department)
    }

    async fetchUsersAccess(id, filter) {
        if (!id) throw new Error('fetchUsersAccess requires a department ID')

        try {
            const department = await api.Department.getUsersAccess(id, {filter})
            return department
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'DepartmentsStoreAdmin.fetchUsersAccess()',
                error: e.message || e,
                departmentId: id,
                filter
            })
        }
    }

    // used to return user counts by role, etc
    async fetchUsersAccessCount(id, where) {
        if (!id) throw new Error('fetchUsersAccess requires a department ID')

        try {
            const count = (await api.Department.getUsersAccessCount(id, where)).count
            return count
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'DepartmentsStoreAdmin.fetchUsersAccessCount()',
                error: e.message || e,
                departmentId: id,
                where
            })
        }
    }
}

export default new DepartmentsStoreAdmin()

import { observable, action, runInAction, computed, makeObservable } from 'mobx'
import api from '../api'
import AppStore from './AppStore'
import DepartmentsStore from './DepartmentsStore'
import DepartmentsAccessStore from './DepartmentsAccessStore'
import CurrentUserStore from './CurrentUserStore'
import AnalyticsStore from './AnalyticsStore'
import InviteStore from './InviteStore'
import NavigationStore from './NavigationStore'
import VerifiedEmailDomainsStore from './VerifiedEmailDomainsStore'
import _ from 'lodash'
import { Toaster } from '../components'
import { RegistrationController, AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { container } from '../di'
import { SessionState } from '@evertel/session'
import { DeviceState } from '@evertel/device'

// Keeps track of our login state and progress
class RegistrationStore {
    registrationSteps: any  // {account: 'complete', confirmemail: 'complete', department: 'complete', profile: 'complete', crisisRoomSelected: 'complete', isCrisisResponse: boolean}
    legacyRegistrationStep = undefined
    isBusy = false
    error: any
    email = ''
    password = '' // used for background login after email verification
    registrationController: RegistrationController
    authenticationController: AuthenticationController
    sessionState: SessionState
    deviceState: DeviceState

    constructor() {
        this.registrationController = container.get(RegistrationController)
        this.authenticationController = container.get(AuthenticationController)
        this.sessionState = container.get(SessionState)
        this.deviceState = container.get(DeviceState)

        makeObservable(this, {
            registrationSteps: observable,
            legacyRegistrationStep: observable,
            isBusy: observable,
            error: observable,
            email: observable,
            password: observable,
            init: action,
            goToNextStep: action,
            updateRegistrationSteps: action,
            isRegistrationComplete: computed,
            setRegisteredEmail: action,
            createAccount: action('Registration create account'),
            createDemoDepartment: action('Registration add user to DEMO'),
            clear: action
        })
    }

    init() {
        this.registrationSteps = _.get(CurrentUserStore, 'meta.registration') || {}
        this.legacyRegistrationStep = _.get(CurrentUserStore, 'meta.registrationStep') || null

        // convert legacy steps to current registration steps
        if (this.legacyRegistrationStep && _.isEmpty(this.registrationSteps)) {
            const registrationSteps = {
                account: 'complete',
                confirmemail: (this.legacyRegistrationStep === 'complete' || CurrentUserStore.emailVerified) ? 'complete' : null,
                department: (this.legacyRegistrationStep === 'Profile' || this.legacyRegistrationStep === 'SecurityQuestions' || this.legacyRegistrationStep === 'PinPrimer' || this.legacyRegistrationStep === 'complete') ? 'complete' : null,
                profile: (this.legacyRegistrationStep === 'SecurityQuestions' || this.legacyRegistrationStep === 'PinPrimer' || this.legacyRegistrationStep === 'complete') ? 'complete' : null,
                pin: (this.legacyRegistrationStep === 'Notifications' || this.legacyRegistrationStep === 'complete') ? 'complete' : null,
                notifications: (this.legacyRegistrationStep === 'complete') ? 'complete' : null
            }

            this.updateRegistrationSteps(registrationSteps)
        }

        // if they registered before any registration step tracking, set them as complete
        if (!this.legacyRegistrationStep && _.isEmpty(this.registrationSteps)) {
            this.updateRegistrationSteps({
                account: 'complete',
                confirmemail: 'complete',
                // department: 'complete',
                // profile: 'complete',
                pin: 'complete',
                notifications: 'complete'
            })
        }
    }

    async goToNextStep() {
        // 1. account (default, no authToken required) [/Pages/Register/Register.js]
        // 2. confirm email [/Pages/Landing/EmailVerification.js]
        // 3. department (can be skipped if invited or isCrisisReponse: true) [/Pages/Register/Department.js]
        // 4. profile (can be skipped if not police or fire) [/Pages/Register/Profile.js]
        // 6. crisis room select (only if isCrisisResponse: true) []

        // make a copy of registrationSteps so we can modify before resetting
        const currentSteps = { ...this.registrationSteps }

        if (!CurrentUserStore.emailVerified && !currentSteps.confirmemail) {
            // user has not confirmed their email
            NavigationStore.navigate('/email-verification')

        } else if (!CurrentUserStore.departmentsAccess?.length && !currentSteps.department) {
            // user does not belong to any departments

            // check if user has a pending invite token...
            if (InviteStore.pendingInviteToken) {
                // ...if so, consume it
                await InviteStore.consumePendingInvite(true)

                // if consuming invite fails, take them to select their department
                if (InviteStore.error) {
                    Toaster.show('error', InviteStore.error?.message || InviteStore.error)
                    NavigationStore.navigate('/register/department')
                    return
                }

                // if consuming invite is successful, navigate to the appropriate next screen
                switch (InviteStore.lastInviteType) {
                    case 'DEPARTMENT_INVITE': {
                        // fetch the department invited to
                        const department = (InviteStore.invitedDepartmentId) ? await DepartmentsStore.fetchDepartmentById(InviteStore.invitedDepartmentId) : null

                        if (department) {
                            // set the invited dept as the selected dept
                            this.sessionState.setSelectedDeparmentId(department.id)

                            if ((department.type === 'police' || department.type === 'fire') && InviteStore.invitedDepartmentsAccess?.role !== 'guest') {
                                currentSteps.department = 'complete'
                                NavigationStore.navigate('/register/profile')
                            } else {
                                currentSteps.department = 'complete'
                                currentSteps.profile = 'skipped'
                                this.goToNextStep()
                            }
                        }

                        this.updateRegistrationSteps(currentSteps)
                        break
                    }
                    case 'ROOM_INVITE':
                    case 'USER_INVITE':
                        // skip the Department and Profile steps
                        // and navigate them to the next step
                        currentSteps.department = 'skipped'
                        currentSteps.profile = 'skipped'

                        this.updateRegistrationSteps(currentSteps)

                        this.goToNextStep()
                        break
                    default:
                        NavigationStore.navigate('/register/department')
                }
            } else {
                // user does not have an invite token...

                // if registering for crisis response, skip department and profile
                if (currentSteps.isCrisisResponse || localStorage.getItem('crisis')) {
                    currentSteps.isCrisisResponse = true
                    currentSteps.department = 'skipped'
                    currentSteps.profile = 'skipped'
                    this.updateRegistrationSteps(currentSteps)
                    this.goToNextStep()
                    return
                }

                // otherwise, take them to the join department step
                NavigationStore.navigate('/register/department')
            }

        } else if (!this.registrationSteps.profile) {
            const department = CurrentUserStore.selectedDepartment || null

            // if department type is not police or fire, we skip profile
            if (department && (department.type === 'police' || department.type === 'fire')) {
                NavigationStore.navigate('/register/profile')
            } else {
                currentSteps.profile = 'skipped'
                this.updateRegistrationSteps(currentSteps)

                this.goToNextStep()
            }

        } else if (currentSteps.isCrisisResponse) {
            // if they are registering for crisis response and they have not selcted a crisis room...
            // check if thier email domain matches any government domains
            const email = await CurrentUserStore.email
            const emailDomain = email.substring(email.lastIndexOf('@') + 1)
            const isGovernmentEmail = await VerifiedEmailDomainsStore.getDomain(emailDomain)

            if (isGovernmentEmail) {
                // take them to crisis room search
                NavigationStore.restoreNavigation()
            } else {
                // take them to 'must have a gov email notice' screen
                NavigationStore.navigate('/register/crisisnoaccess')
            }

        } else {
            // all steps are complete
            NavigationStore.restoreNavigation()
        }
    }

    async updateRegistrationSteps(steps: any) {
        if (!api.isProduction) console.log('update registration steps', steps)
        this.registrationSteps = steps
        await CurrentUserStore.update({ meta: { registration: steps } })
    }

    get isRegistrationComplete() {
        //this will be hit if not initialized
        if (!this.registrationSteps) return true
        return (
            this.registrationSteps.confirmemail &&
            this.registrationSteps.department &&
            this.registrationSteps.profile) ?
            true : false
    }

    setRegisteredEmail(email: string) {
        runInAction(() => {
            this.email = email
        })
        return
    }

    async createAccount({ email, password, firstName, lastName }: any) {
        this.email = email
        this.password = password
        this.isBusy = true
        this.error = null

        if (!email || !password || !firstName || !lastName) {
            this.error = { message: 'Please fill out all fields' }
            return
        }

        const registerBody = {
            email,
            password,
            firstName,
            lastName,
            deviceToken: this.deviceState.deviceToken
        }

        let response: any
        // Create account
        try {
            response = await this.registrationController.registerInAuth0(registerBody)

            // log to analytics
            AnalyticsStore.logEvent({
                category: 'User',
                action: 'registered',
                label: undefined,
                value: undefined,
                nonInteraction: undefined,
                transport: undefined
            })

            if (!response) {
                this.error = 'Invalid Sign up. User already exists'
            }

            runInAction(() => {
                this.isBusy = false
            })

            return response

        } catch (e: any) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({
                type: 'API post',
                message: 'RegistrationStore.createAccount()',
                error: e.message || e, email
            })
            return
        }

    }

    async createDemoDepartment({ type, roles }: any) {
        this.isBusy = true
        this.error = null

        // Create demo
        let demoDepartment: any
        try {
            demoDepartment = await api.Department.createDemo({ type: type || 'police', roles: roles || ['management'] })
            DepartmentsStore.updateFromData(demoDepartment.department)
            DepartmentsAccessStore.updateFromData(demoDepartment.departmentsAccess)
        } catch (e: any) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({ type: 'API post', message: 'RegistrationStore.createDemoDepartment()', error: e.message || e })
            return
        }

        runInAction(() => {
            this.isBusy = false
        })

        return demoDepartment
    }

    getProfessionOptions() {
        return [
            { label: 'Law Enforcement', value: 'police' },
            { label: 'Firefighting', value: 'fire' },
            { label: 'Paramedics', value: 'paramedics' },
            { label: 'Military', value: 'military' },
            { label: 'Government', value: 'government' }
        ]
    }

    clear() {
        this.registrationSteps = {}
        this.legacyRegistrationStep = undefined
    }
}

export default new RegistrationStore()

import React, { useEffect } from 'react'
import { useDidUpdate } from '@mantine/hooks'
import { observer } from 'mobx-react-lite'
import { Button, Icon, Row, Text, Card, CardBody, useUI } from '@evertel/web/ui'
import Wrapper from './Wrapper'
import { useService } from '@evertel/di'
import { DocumentMediaWriteWidgetController } from '../../controller'
import { DocumentMedia } from '../media/DocumentMedia'

export interface MediaWriteWidgetProps {
    id: number | string,
    value: number[],
    onChange: (id: string | number, value: { uploaded: number[]; deleteQueue: number[]; uploadQueue: File[]; }) => void,
    title: string,
    required?: boolean,
    hint?: string,
    scope?: string,
    allowMultiple?: boolean,
    visualMedia?: boolean,
    documentId?: number,
    typesAllowed?: ['images' | 'videos' | 'files' | 'audio' | 'application'] | string
}

const MediaWriteWidget: React.FC<MediaWriteWidgetProps> = observer(({
    id,
    value,
    onChange,
    title,
    required,
    hint,
    scope,
    allowMultiple = true,
    typesAllowed,
    visualMedia,
    documentId,
    ...otherProps
}) => {

    const mediaWriteWidgetController = useService(DocumentMediaWriteWidgetController, [])

    let _fileTypes = undefined

    const { addToast } = useUI()

    //console.log(id, title, value)

    useEffect(() => {
        mediaWriteWidgetController.init(value || [])

        // if (!documentId) {
        //     console.error('ERROR: Missing meta in MediaWriteWidget. You forgot to add the meta ServiceProvider.')
        //     return
        // }
    }, [])

    useDidUpdate(() => {
        if (onChange && scope !== 'schemaBuilder') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange(id, mediaWriteWidgetController.mediaArrays)
        }
    }, [mediaWriteWidgetController.mediaArrays])

    if (Array.isArray(typesAllowed)) {
        // convert array to usable string for HTML file field
        const types = []
        if (typesAllowed?.includes('images')) types.push('image/*')
        if (typesAllowed?.includes('videos')) types.push('video/*')
        if (typesAllowed?.includes('audio')) types.push('audio/*')
        if (typesAllowed?.includes('files') || typesAllowed?.includes('application')) types.push('application/*')
        _fileTypes = String(types)
    } else {
        _fileTypes = typesAllowed
    }

    const getFileFormats = () => {
        const typesSet = new Set()

        if (typesAllowed?.includes('images') || !typesAllowed) {
            typesSet.add('jpg, jpeg')
            typesSet.add('png')
        }
        if (typesAllowed?.includes('videos') || !typesAllowed) {
            typesSet.add('mp4')
            typesSet.add('mov')
            typesSet.add('wmv')
        }
        if (typesAllowed?.includes('audio') || !typesAllowed) {
            typesSet.add('mp3')
            typesSet.add('m4a')
            typesSet.add('wav')
        }
        if (typesAllowed?.includes('application') || typesAllowed?.includes('files') || !typesAllowed) {
            typesSet.add('pdf')
            typesSet.add('docx')
            typesSet.add('xlsx')
            typesSet.add('txt')
            typesSet.add('ppt')
        }
        return Array.from(typesSet).join(', ')
    }

    const onUpload = async (files) => {
        try {
            await mediaWriteWidgetController.addToUploadQueue(files)
        } catch (error: any) {
            addToast({
                color: 'danger',
                title: 'Something went wrong',
                message: error.message
            })
        }
    }

    return (
        <Wrapper
            title={title}
            required={required}
            hint={hint}
            {...otherProps}>
            <Card>
                <CardBody>
                    <div>
                        <Button
                            color="secondary"
                            disabled={scope === 'schemaBuilder'}
                            outline
                            upload={(allowMultiple) ? 'multiple' : true}
                            onUpload={onUpload}
                            fileTypes={_fileTypes}>
                            <Icon
                                name="upload"
                                color="secondary"
                                className="mr-1"
                            />
                            Add File
                        </Button>
                    </div>
                    <Text
                        color="muted"
                        className="mt-2"
                        size={11}>
                        (Allowed file types: {getFileFormats()})
                    </Text>
                    <Row className="mt-4 flex-wrap">
                        {mediaWriteWidgetController?.mediaArrays?.uploaded?.map((id) =>
                            <DocumentMedia
                                key={id}
                                documentId={documentId as number}
                                mediaId={id}
                                width={50}
                                height={50}
                                className="mr-3"
                                onDelete={(mediaId: number) => mediaWriteWidgetController.deleteUploaded(mediaId)}
                            />
                        )}
                    </Row>
                    { mediaWriteWidgetController?.uploadQueue.size>0 && <p className="m-2">Added media:</p> }
                    <Row className="flex-wrap">
                        {Array.from(mediaWriteWidgetController?.uploadQueue, (([id, file]) => {
                            return (
                                <DocumentMedia
                                    key={id}
                                    documentId={documentId as number}
                                    mediaId={id}
                                    localFile={file}
                                    width={50}
                                    height={50}
                                    className="mr-3"
                                    onDelete={(mediaId: number) => mediaWriteWidgetController.deleteFromUploadQueue(mediaId)}
                                />
                            )
                        }))}
                    </Row>
                </CardBody>
            </Card>
        </Wrapper>
    )
})

export {
    MediaWriteWidget
}

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import moment from 'moment'
import anchorme from 'anchorme'
import ReactPlayer from 'react-player'
// evertel
import { MemoizedEventMessageInlay, MemoizedForwardedMessageTile, HoverToolbar, MessageBody, MessageHeader } from './elements'
import { APIDataReaction, APIDataRoom, APIDataRoomMessage, APIDataThreadMessage } from '@evertel/types'
import { useService } from '@evertel/di'
import { MessageController, MessageReactionsController } from '@evertel/message'
import { Row, Col, RetryButton } from '@evertel/web/ui'
import { ProfilePic, useUserDetail } from '@evertel/web/user'
import { RoomContext } from '@evertel/web/room'
import { CurrentUserController, UserController } from '@evertel/blue-user'
import { ReactionBar } from '@evertel/web/reactions'
import { DepartmentsAccessController } from '@evertel/departments-access'
import { CurrentUserEmojisController } from '@evertel/emojis'
import { MessageWallContext } from './MessageWallContext'
import { getNonGuestPrimaryDepartmentsAccess, localId, positionElementRelativeToTarget } from '@evertel/utils'
import { SessionState } from '@evertel/session'
import { NormalizedMedia } from '@evertel/media'
import { MessageBodyProps } from '../types'
import debugModule from 'debug'
const debug = debugModule('app:Message')

interface EmojiParam {
    native: APIDataReaction
}
interface MessageProps {
    message: APIDataRoomMessage | APIDataThreadMessage,
    modelType: 'room' | 'thread',
    showHoverBar?: boolean, // show post hover toolbar (ex. turned off on message search)
    isScrolling?: boolean, // informs if item is scrolling in list
    forceHeader?: boolean // for the message header to show always (used in messages search for example)
    highlightString?: string // string searched for in message search (will highlight matching string in post body)
    isFromSearchModal?: boolean // used to filter if this component is rendered by the MessageSearchModal, in that case we do not allow adding new reactions
    allowRedacted?: boolean
}

const BodyType = (type: string, data: MessageBodyProps): JSX.Element => {
    switch (type) {
        case 'event':
            return <MemoizedEventMessageInlay message={data.message} />
        case 'forwarded':
            return <MemoizedForwardedMessageTile message={data.message} media={data.media} userDepartmentsAccess={data.userDepartmentsAccess} />
        case 'user':
        case 'user_audio':
        default:
            return <MessageBody message={data.message} media={data?.media} highlightString={data?.highlightString} />
    }
}

const Message: React.FC<MessageProps> = observer(({
    message,
    modelType,
    showHoverBar = true,
    isScrolling = false,
    forceHeader,
    highlightString,
    isFromSearchModal = false,
    allowRedacted = false
}) => {

    const {
        setSelectedMessageId,
        setIsReactedByModalOpen,
        setIsForwardMessageModalOpen,
        setEmojiPickerState,
        emojiPickerState,
        selectedMessageId,
        modelData // room data | thread data
    } = useContext(MessageWallContext)

    const { roomController } = useContext(RoomContext)

    const messageController = useService(MessageController, [message?.id])
    const messageReactionsController = useService(MessageReactionsController, [message?.id])
    const ownerId = message.ownerId || (message.meta as any)?.createdById || 1
    const userController = useService(UserController, [ownerId])
    const currentUserController = useService(CurrentUserController, [])
    const daController = useService(DepartmentsAccessController, [ownerId])
    const currentUserEmojisController = useService(CurrentUserEmojisController, [])
    const session = useService(SessionState, [])

    const messageRef = useRef<HTMLDivElement>(null)
    const { openUserDetail } = useUserDetail()

    const [isMouseOver, setIsMouseOver] = useState(false)
    const [isRetracted, setIsRetracted] = useState(false)


    useState(() => {
        let urls = anchorme.list(message?.text)

        const forwardedText = message?.meta?.forwardedMessage?.text || ''
        if (message.type === 'forwarded' && forwardedText) {
            const forwardedUrls = anchorme.list(forwardedText)
            urls = [...urls, ...forwardedUrls]
        }

        const videoUrls = urls.map(u => {
            if (ReactPlayer.canPlay(u.string)) {
                return u
            }
            return null
        })
            ?.filter(v => v)
            ?.map(v => ({
                id: localId(),
                contentType: 'video',
                fileName: '',
                mimeType: 'video/*',
                url: v.string,
                isLocalMedia: false,
                width: 1920,
                height: 1080,
                contentLength: 0,
                state: 'remote'
            }))

        messageController.init(message, modelType, videoUrls as NormalizedMedia[])
        userController.init(ownerId)
        messageReactionsController.init(message, modelType)
        //currentUserEmojisController
    })

    useEffect(() => {
        daController.init(ownerId)
    }, [ownerId])

    useEffect(() => {
        messageController.startUploadMedia()
    }, [])

    useEffect(() => {
        const node = messageRef.current
        if (node && isRetracted) {
            // Capture the current height of the element
            const currentHeight = node.scrollHeight + 'px'
            node.style.height = currentHeight // Set the initial height

            // Ensure the height is applied before starting the transition
            requestAnimationFrame(() => {
                // this works nicely, but when it re-renders, there's still a jump when it re-renders
                // possibly in the future when virtuoso save state isn't broken, that could be used
                // node.style.height = '0px'
                // node.style.padding = '0px'
            })
        }
    }, [isRetracted])


    const onClickHoverToolbarOption = ({ option, messageId, params }: { option: string, messageId: number, params: APIDataReaction }) => {
        switch (option) {
            case 'retract':
                setIsRetracted(true)
                setTimeout(() => {
                    // give time for the animation before retracting
                    messageController.retract()
                }, 500)
                break
            case 'forward-message':
                setSelectedMessageId(message?.id)
                setIsForwardMessageModalOpen(true)
                break
            case 'reaction':
                onSelectReaction(params)
                break
            default:
                break
        }
    }

    const showReactedBy = () => {
        setSelectedMessageId(message?.id)
        setIsReactedByModalOpen(true)
    }

    const showHeader = useCallback(() => {
        if (forceHeader) return true

        if (message.type === 'event' || message.isUrgent) {
            return true
        }

        if ((message as any).prevOwnerId === message.ownerId) {
            const durationFromPrevPost = moment(message.publishedDate || message.createdDate).diff(moment((message as any).prevMessageDate), 'minutes')
            return (durationFromPrevPost > 3)
        }
        return true

    }, [message, forceHeader])

    const onSelectReaction = async (reaction: APIDataReaction) => {
        if (isFromSearchModal) return

        setEmojiPickerState({
            visible: false,
            params: {}
        })

        // emoji will be in a different key depending on where it's coming from and if it's custom
        const emoji = reaction.text || (reaction as any).native || (reaction as any).colons

        try {
            const toggleResult = await messageReactionsController.toggleReaction(emoji)
            if (toggleResult === 'added') {
                currentUserEmojisController.incrementFrequent(emoji)
            } else {
                currentUserEmojisController.decrementFrequent(emoji)
            }
        } catch (err) {
            //do nothing
        }
    }

    const onOpenEmojiPicker = (target: string) => {
        positionElementRelativeToTarget('#emoji-picker', target)
        setEmojiPickerState({
            visible: true,
            params: {
                onSelectEmoji: onSelectReaction
            }
        })
    }

    const onMouseEnter = () => {
        setIsMouseOver(true)
    }

    const onMouseLeave = () => {
        if (!emojiPickerState?.visible) {
            setIsMouseOver(false)
        }
    }

    const onRetryPost = async () => {
        await messageController.retryUploadMedia()
    }

    if (!message || (message?.isRetracted && !allowRedacted)) return null

    const user = userController?.user || {}
    const isGuest = (modelType !== 'room' || user?.isBot) ? false : daController.selectedDepartmentRole === 'guest' || false
    const isCurrentUser = ownerId === session.currentUserId
    const hasFailedMedia = messageController?.media.filter(m => m.state === 'failed').length > 0
    const departmentsAccess = daController.departmentsAccess

    let canRetractTest: boolean
    if (modelType === 'room') {
        canRetractTest = !(modelData as APIDataRoom)?.isArchived && (isCurrentUser || currentUserController.canManage || currentUserController.canExecutive )
    } else if (modelType === 'thread') {
        canRetractTest = isCurrentUser
    }
    const canRetract = canRetractTest

    return (
        <Row
            //key={message.id}
            ref={messageRef}
            id={`message-${message.id}`}
            aria-roledescription="message"
            {...(showHoverBar && !isScrolling) && { onMouseEnter: onMouseEnter }}
            {...(showHoverBar) && { onMouseLeave: onMouseLeave }}
            className={classNames(
                'message',
                'post-' + message.id,
                {
                    'no-header': !showHeader,
                    'hovered': isMouseOver && showHoverBar,
                    'retracted': isRetracted
                }
            )}>
            <Col
                valign="top"
                className="left-col pt-1">
                {(showHeader()) &&
                    <ProfilePic
                        userId={user?.id}
                        firstName={user?.firstName}
                        lastName={user?.lastName}
                        imageUrl={user?.publicImage}
                        isGuest={isGuest}
                        onClick={openUserDetail}
                    />
                }
            </Col>
            <Col valign="top">
                {(showHeader()) &&
                    <MessageHeader
                        message={message}
                        user={user}
                        isGuest={isGuest}
                        userDepartmentsAccess={departmentsAccess}
                        onClickUser={openUserDetail}
                        isRoom={modelType === 'room'}
                        roomDepartment={roomController?.room?.departmentId || null}
                    />
                }

                {messageController.message.id && BodyType(message.type, { message: messageController?.message, media: messageController?.media, highlightString, userDepartmentsAccess: departmentsAccess }) }

                {(!!messageReactionsController?.message?.reactions?.length) &&
                    <ReactionBar
                        messageId={message.id}
                        reactions={messageReactionsController?.message?.reactions}
                        onSelectReaction={onSelectReaction}
                        onShowEmojiPicker={onOpenEmojiPicker}
                        onShowReactedBy={showReactedBy}
                        showCountLink={!isFromSearchModal}
                        showPickerButton={!isFromSearchModal}
                        canReact={!(modelData as APIDataRoom)?.isArchived || !isFromSearchModal}
                        emojiButtonId={`emojiButton-${message.id}`}
                    />
                }
            </Col>
            <Col
                valign="top"
                className="right-col">
                {(showHoverBar) &&
                    <HoverToolbar
                        visible={isMouseOver}
                        messageId={message.id}
                        canRetract={canRetract}
                        canReact={!(modelData as APIDataRoom)?.isArchived}
                        canForward={(['user', 'user_audio'].includes(message?.type)) && (((modelData as APIDataRoom)?.options as any)?.departmentMembersCanJoinAsMember)}
                        onOpenEmojiPicker={onOpenEmojiPicker}
                        onClickOption={onClickHoverToolbarOption}
                    />
                }

                {hasFailedMedia &&
                    <RetryButton
                        postId={message.id}
                        onClick={onRetryPost}
                    />
                }
            </Col>
        </Row>
    )
})

export { Message }

import { useState, useEffect } from 'react'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height
    }
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            if (!document.fullscreenElement) {
                setWindowDimensions(getWindowDimensions())
            }
        }

        function handleFullscreenChange() {
            if (!document.fullscreenElement) {
                setWindowDimensions(getWindowDimensions())
            }
        }

        window.addEventListener('resize', handleResize)
        document.addEventListener('fullscreenchange', handleFullscreenChange)

        return () => {
            window.removeEventListener('resize', handleResize)
            document.removeEventListener('fullscreenchange', handleFullscreenChange)
        }
    }, [])

    return windowDimensions
}

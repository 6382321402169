import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface NavTitleProps extends HTMLAttributes<HTMLLIElement> {
    className?: string
}

const NavTitle = forwardRef<HTMLLIElement, NavTitleProps>(({
    children,
    className,
    ...otherProps
}, ref) => {

        const _className = classNames('nav-title', className)

        return (
            <li
                ref={ref}
                className={_className}
                {...otherProps}>
                {children}
            </li>
        )
})

export { NavTitle }
NavTitle.displayName = 'NavTitle'

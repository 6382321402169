import React, {
    useState,
    useEffect,
    useContext,
    Suspense
} from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

// evertel
import { RoomSettingsForm } from './RoomSettingsForm'
import {
    Button,
    Text,
    Input,
    Row,
    Col,
    ToggleSwitch,
    Icon,
    InfoBox,
    Spinner,
    useUI
} from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { RoomSettingsController, SharedRoomController } from '@evertel/room'
import { RoomContext } from '../../RoomContext'
import { useInvite } from '@evertel/web/invites'
import { SessionState } from '@evertel/session'
import { DepartmentsAccessController } from '@evertel/departments-access'
import { getRole } from '@evertel/utils'

const CloneRoomModal = React.lazy(() => import('../modals/CloneRoomModal').then(module => ({ default: module.CloneRoomModal })))
const SharedRoomModal = React.lazy(() => import('../modals/SharedRoomModal').then(module => ({ default: module.SharedRoomModal })))
const ICRoomModal = React.lazy(() => import('../modals/ICRoomModal').then(module => ({ default: module.ICRoomModal })))

interface RoomSettingsProps {
    isOpen: boolean
}

const RoomSettings: React.FC<RoomSettingsProps> = observer(() => {

    const session = useService(SessionState, [])
    const {
        roomController,
        toggleAside,
        asideOpenComplete,
        canManageRoom
    } = useContext(RoomContext)
    const { room } = roomController
    
    const settingsController = useService(RoomSettingsController, [])
    const daController = useService(DepartmentsAccessController, [session.currentUserId])
    const shareRoomController = useService(SharedRoomController, [room?.id])
    
    const { openInvite } = useInvite()
    const { addToast, isConfirmed } = useUI()
    const history = useHistory()


    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [hasChanges, setHasChanges] = useState(false)
    const [showSharedRoomModal, setShowSharedRoomModal] = useState(false)
    const [showCloneRoomModal, setShowCloneRoomModal] = useState(false)
    const [showICRoomModal, setShowICRoomModal] = useState(false)

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            setHasChanges(false)

            await settingsController.init(room?.id)
            daController.init(session.currentUserId)
            shareRoomController.init(room?.id)

            setName(settingsController.room?.name || '')
            setDescription(settingsController.room?.description || '')

            setIsLoading(false)
        })()
    }, [room.id, settingsController.room])


    const onChangeName = (e) => {
        const name = e.target.value

        if (name?.trim()?.length === 0) {
            setHasChanges(false)
        } else {
            setHasChanges(true)
        }

        setName(name)
    }

    const onChangeDescription = (e) => {
        setDescription(e.target.value)
        setHasChanges(true)
    }

    const onSave = async () => {
        setIsLoading(true)

        try {
            await settingsController.update({
                name,
                description
            })

            setHasChanges(false)

        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }

        setIsLoading(false)
    }

    // handles each setting toggle
    const onToggleOption = async (option, value) => {
        try {
            await settingsController.update(
                ['isSearchable', 'isArchived'].includes(option)
                    ? {
                        [option]: value
                    }
                    : {
                        options: {
                            [option]: value
                        }
                    }
            )
        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }
    }

    const onOpenInvite = () => {
        openInvite('guest', [{
            label: room?.name,
            value: room?.id
        }])
    }

    const leaveRoom = async () => {
        try {
            const confirmed = await isConfirmed({
                title: 'Leave Room?',
                message: 'Are you sure you want to remove yourself as a member of this room?',
                acceptButton: {
                    label: 'Yes, remove me',
                    color: 'danger'
                }
            })
            if (!confirmed) return
    
            await roomController.leaveRoom()
    
            addToast({
                color: 'success',
                message: `You have been removed from #${room?.name}`
            })
    
            session.deleteRoomfromNavigationHistory(room.id)
    
            history.push('/landing')
    
            toggleAside('settings', 'close')
    
        } catch (error: any) {
            addToast({
                color: 'danger',
                title: 'Error',
                message: error?.message || error
            })
        }
    }

    if (!room || !asideOpenComplete) return null

    const options = room.options as any
    const canLeave = !options.autoAddDepartmentMembers && options?.roomMembersCanLeave
    const isGuest =  getRole(daController.selectedDepartmentsAccess?.roles) === 'guest'
    const activeLinkAvailable = !!shareRoomController.latestActiveInvite
    const canShowRegionalCollaboration = (activeLinkAvailable || (!activeLinkAvailable && !isGuest))

    return (
        <>
            <Row className="mb-5">
                <Col className="flex-grow-1">
                    <Text bold>
                        Room Name
                    </Text>
                    <Input
                        type="text"
                        name="name"
                        value={name}
                        disabled={!canManageRoom || room?.isArchived || isGuest}
                        onChange={onChangeName}
                        placeholder="E.g. Squad name"
                    />
                    {(canManageRoom || description) &&
                    <>
                        <Text
                            bold
                            className="mt-2">
                            Description
                        </Text>
                        <Input
                            type="text"
                            name="description"
                            value={description}
                            disabled={!canManageRoom || room?.isArchived || isGuest}
                            onChange={onChangeDescription}
                            placeholder="Enter a room description"
                        />
                    </>
                    }
                    {(hasChanges) &&
                        <Row
                            align="right"
                            className="my-3">
                            <Button
                                size="sm"
                                onClick={onSave}
                                color="success"
                                className="fade-in"
                                loading={isLoading}
                                disabled={isLoading}>
                                Save Changes
                            </Button>
                        </Row>
                    }
                </Col>
            </Row>

            {(canManageRoom) &&
                <>
                    {(!isGuest) &&
                        <RoomSettingsForm
                            roomId={room.id}
                            isSearchable={room.isSearchable}
                            isArchived={room.isArchived}
                            onChange={onToggleOption}
                            options={options}
                            onOpenICModal={() => setShowICRoomModal(true)}
                        />}
                    
                    { (canShowRegionalCollaboration) &&
                        <InfoBox
                            variant="outline"
                            color="muted"
                            className="my-5">
                            <Text
                                tag="div"
                                bold>
                            Regional Collaboration
                            </Text>
                            <Text
                                tag="div"
                                color="muted"
                                size="small">
                            Work with other agencies in this room.
                            </Text>
                            <Row className="mt-2">
                                {(!isGuest) &&
                            <Button
                                color="info"
                                outline
                                size="sm"
                                onClick={onOpenInvite}
                                disabled={room?.isArchived}
                                className="mr-2">
                                <Icon
                                    name="user-plus"
                                    className="mr-1"
                                />
                                Invite Guests
                            </Button>
                                }
                                <Button
                                    color="info"
                                    outline
                                    size="sm"
                                    onClick={() => setShowSharedRoomModal(true)}
                                    disabled={room?.isArchived}
                                    className="mr-2">
                                    <Icon
                                        name="link-horizontal"
                                        className="mr-1"
                                    />
                                Share Link
                                </Button>
                            </Row>
                        </InfoBox>}
                </>
            }

            <Row className="pb-2 border-bottom border-light">
                <Col
                    xs={1}
                    align="center"
                    valign="top"
                    className="p-0">
                    <Icon
                        name={(settingsController.userRoomNotificationsEnabled) ? 'bell' : 'bell-slash'}
                        type={(settingsController.userRoomNotificationsEnabled) ? 'solid' : undefined}
                        color={(settingsController.userRoomNotificationsEnabled) ? 'success' : 'muted'}
                        className="mr-2"
                    />
                </Col>
                <Col
                    xs={9}
                    className="pl-0">
                    <Text bold>
                        My Room Notifications
                    </Text>
                    <Text
                        color="muted"
                        size="smaller">
                        Enable/disable new message notifications in this room (effects you only).
                    </Text>
                </Col>
                <Col align="right">
                    <ToggleSwitch
                        name="roomNotifications"
                        id="roomNotifications"
                        onChange={(checked) => settingsController.updateUserRoomNotificationSettings(checked)}
                        checked={settingsController.userRoomNotificationsEnabled}
                        optionLabels={['On', 'Off']}
                    />
                </Col>
            </Row>
            {(canManageRoom && !isGuest) && 
                <Row
                    className="py-2 border-bottom border-light">
                    <Col
                        xs={1}
                        align="center"
                        valign="top"
                        className="p-0">
                        <Icon
                            name="clone"
                            color="muted"
                            className="mr-2"
                        />
                    </Col>
                    <Col
                        align="left"
                        xs={9}
                        className="pl-0">
                        <Button
                            color="link"
                            onClick={() => setShowCloneRoomModal(true)}>
                            Clone Room
                        </Button>
                    </Col>
                </Row>
            }
            {(canLeave) &&
                <Row
                    className="py-2 border-bottom border-light">
                    <Col
                        xs={1}
                        align="center"
                        valign="top"
                        className="p-0">
                        <Icon
                            name="xmark"
                            color="muted"
                            className="mr-2"
                        />
                    </Col>
                    <Col
                        align="left"
                        xs={9}
                        className="pl-0">
                        <Button
                            color="link"
                            onClick={leaveRoom}>
                            Leave Room
                        </Button>
                    </Col>
                </Row>
            }

            <Text
                color="muted"
                size="smaller"
                italic
                style={{
                    position: 'absolute',
                    bottom: 5
                }}>
                Room ID: {room.id}
            </Text>

            <Suspense fallback={<Spinner />}>
                <SharedRoomModal
                    visible={showSharedRoomModal}
                    onClose={() => setShowSharedRoomModal(false)}
                    room={room}
                    userIsGuest={isGuest}
                />
                <CloneRoomModal
                    visible={showCloneRoomModal}
                    onClose={() => setShowCloneRoomModal(false)}
                />
                <ICRoomModal
                    visible={showICRoomModal}
                    onClose={() => setShowICRoomModal(false)}
                />
            </Suspense>
        </>
    )
})

export { RoomSettings }


import { APIDataDocument } from '@evertel/types'
import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { DocumentSchemaStore } from '@evertel/stores'
import { debounce } from 'lodash'

class RoomDocumentsSearch {
    documents: APIDataDocument[] = []
    documentSchemaIds: number[] = []
    documentsCount = 0
    roomId = 0
    searchTerm = ''
    page = 1
    limit = 100
    isLoading = false

    constructor(
        private api: Api,
        private documentSchemaStore: DocumentSchemaStore
    ) {
        makeAutoObservable(this)
    }

    init(roomId: number) {
        this.roomId = roomId
        this.documents = []
        this.documentSchemaIds = []
        this.documentsCount = 0
        this.page = 1
        this.searchTerm = ''
        this.fetchDocuments()
    }

    setSearchTerm(value: string) {
        this.searchTerm = value
        this.page = 1
        this.documents = []
        this.documentSchemaIds = []
        this.documentsCount = 0
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    fetchDocuments = debounce(async () => {
        let count = this.documentsCount
        let results = []

        runInAction(() => {
            this.isLoading = true
        })

        // grab the total count of docs in this room
        if (!this.documentsCount) {
            count = await this.fetchDocumentsCount()
        }

        // stop from fetching if we already got them all
        if (count > this.documents?.length) {
            results = await this.api.Routes.Room.getDocumentsSearch(this.roomId, this.filter)
        }

        runInAction(() => {
            const newDocs = [...this.documents]
            newDocs.push(...results)
            this.documents = newDocs
            this.documentSchemaIds = newDocs.map(d => d.documentSchemaId)
            this.isLoading = false
        })
    }, 500)

    async fetchDocumentsCount() {
        const res = await this.api.Routes.Room.getDocumentsSearchCount(this.roomId, this.filter.where)

        runInAction(() => {
            this.documentsCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    fetchNextDocuments() {
        this.page = this.page + 1
        this.fetchDocuments()
    }

    reset() {
        this.documents = []
        this.documentSchemaIds = []
        this.documentsCount = 0
        this.page = 1
        this.searchTerm = ''
        this.limit = 100
    }

    get filter() {
        return {
            where: {
                searchTerm: this.searchTerm
            },
            order: 'updatedDate DESC',
            limit: this.limit,
            skip: (this.page - 1) * this.limit
        }
    }

    get documentSchemas() {
        return this.documentSchemaStore.findByIds(this.documentSchemaIds)
    }
}

decorate(injectable(), RoomDocumentsSearch)
decorate(inject(Api), RoomDocumentsSearch, 0)
decorate(inject(DocumentSchemaStore), RoomDocumentsSearch, 1)

export { RoomDocumentsSearch }
import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

export interface PaginationProps extends HTMLAttributes<HTMLUListElement> {
  align?: 'start' | 'center' | 'end'
  className?: string
  size?: 'sm' | 'lg'
}

const Pagination = forwardRef<HTMLUListElement, PaginationProps>(({
    children,
    align,
    className,
    size,
    ...otherProps
}, ref) => {

    return (
        <nav ref={ref} {...otherProps}>
            <ul
                className={classNames(
                    'pagination',
                    {
                        [`justify-content-${align}`]: align,
                        [`pagination-${size}`]: size,
                    },
                    className
                )}>
                {children}
            </ul>
        </nav>
    )
})


export { Pagination }
import React, { forwardRef } from 'react'
import ReactSelect, { MultiValue, Props, SingleValue } from 'react-select'
import classNames from 'classnames'
import Select from 'react-select/dist/declarations/src/Select'

export type SelectOption = {
    label: string
    value: string
}

export interface DropdownSelectProps extends Props {
    value?: string | number,
    options: SelectOption[],
    onChange?: (
        newValue: MultiValue<{ label: string; value: string; }>|SingleValue<{ label: string; value: string; }>
    ) => void,
    placeholder?: string
}

const Dropdown = forwardRef<Select, DropdownSelectProps>(({
    value: initialValue,
    options = [{value: '', label: ''}],
    onChange,
    placeholder = 'Please select...',
    className,
    menuPortalTarget = document.body,
    ...otherProps
}, ref) => {

    return (
        <ReactSelect
            ref={ref}
            defaultValue={(initialValue)
                ? {
                    label: options?.find((o) => o.value === initialValue)?.label || '',
                    value: initialValue
                } : null
            }
            getOptionLabel={(option: SelectOption) => option.label}
            getOptionValue={(option: SelectOption) => option.value}
            options={options}
            onChange={(newValue: MultiValue<{ label: string; value: string; }>|SingleValue<{ label: string; value: string; }>) => {
                onChange && onChange(newValue)
            }}
            placeholder={placeholder}
            menuPortalTarget={menuPortalTarget}
            className={classNames('react-select', className)}
            classNamePrefix="select"
            {...otherProps}
        />
    )
})

export { Dropdown }

//==============================================
// DETERMINES IF ELEMENT IS POSITIONED OUT OF
// VIEWPORT
//==============================================
interface OutProps {
    top:number|boolean,
    left: number|boolean,
    bottom: number|boolean,
    right: number|boolean,
    any: boolean,
    all: boolean
}

export function isOutOfViewport(elem: HTMLBodyElement) {
    // get element's position
    const bounding = elem.getBoundingClientRect()

    // check if it's out of the viewport on each side
    const out: OutProps = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        any: false,
        all: false
    }
    out.top = bounding.top < 0
    out.left = bounding.left < 0
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth)
    out.any = out.top || out.left || out.bottom || out.right
    out.all = out.top && out.left && out.bottom && out.right

    return out
}

//==============================================
// DETERMINES IF ELEMENT WILL BE POSITIONED OUT
// OF VIEWPORT BASED ON POS. PASSED IN
//==============================================
interface willBeOutOfViewportProps {top: number, left: number, right: number, bottom: number}
export function willBeOutOfViewport({top, left, right, bottom}: willBeOutOfViewportProps) {

    // check if it's out of the viewport on each side
    const out: OutProps = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        any: false,
        all: false
    }
    out.top = (top < 0) ? top : false
    out.left = (left < 0) ? left : false
    out.bottom = (bottom > (window.innerHeight || document.documentElement.clientHeight)) ? bottom - (window.innerHeight || document.documentElement.clientHeight) : false
    out.right = (right > (window.innerWidth || document.documentElement.clientWidth)) ? right - (window.innerWidth || document.documentElement.clientWidth) : false
    out.any = (out.top || out.left || out.bottom || out.right) ? true : false
    out.all = (out.top && out.left && out.bottom && out.right) ? true : false

    // returns false if not out of bounds, and distance out of bounds if true
    return out
}

//==============================================
// POSITIONS EL. RELATIVE TO ANOTHER TARGET EL.
//==============================================
export function positionElementRelativeToTarget(
    elementSelector: string,
    targetSelector: string,
    loc = 'top',
    keepOnScreen = true,
    pxFromEdge = 10): void
{

    // grab element and position
    const el: HTMLBodyElement|null = document.querySelector(elementSelector)
    const elPosition = el?.getBoundingClientRect() || {width: 50, height: 50}
    // grab target and position
    const target = document.querySelector(targetSelector)
    const targetPosition = target?.getBoundingClientRect() || {left: 0, top: 0}

    let left = (loc === 'left') ? targetPosition.left - elPosition.width : targetPosition.left
    let top = (loc === 'top') ? targetPosition.top - elPosition.height : targetPosition.top
    const bottom = top + elPosition.height
    const right = left + elPosition.width

    if (keepOnScreen) {
        interface isOutProps { left: number, right: number, top: number, bottom: number }
        const isOut = willBeOutOfViewport({top, left, bottom, right}) as isOutProps

        if (isOut.left) left = 0
        if (isOut.top) top = 0
        if (isOut.right) left = left - isOut.right
        if (isOut.bottom) top = top - isOut.bottom
    }

    // adjust position factoring in desired distance to keep el from edge of viewport (pxFromEdge)
    if (top < pxFromEdge) top = pxFromEdge
    if (left + elPosition.width > (window.innerWidth - pxFromEdge)) left = left - pxFromEdge

    if (el) {
        el.style.top = top + 'px'
        el.style.left = left + 'px'
    }
}

//==============================================
// IS COLOR LIGHT OR DARK (HEX | RGB)?
//==============================================
export function lightOrDark(color: string): 'light'|'dark' {
    let r: number, g: number, b: number

    // check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // if RGB --> store the red, green, blue values in separate variables
        const rgbArray = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/) || []
  
        r = parseInt(rgbArray[1])
        g = parseInt(rgbArray[2])
        b = parseInt(rgbArray[3])
    } else {
        // if HEX --> Convert it to RGB: http://gist.github.com/983661
        const hex: number = +('0x' + color.slice(1).replace( color.length < 5 ? /./g : '', '$&$&'))
  
        r = hex >> 16
        g = hex >> 8 & 255
        b = hex & 255
    }
  
    // HSP equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    )
  
    // using the HSP value, determine whether the color is light or dark
    if (hsp > 175) {
        return 'light'
    } else {
        return 'dark'
    }
}

//==============================================
// LIGHTEN OR DARKEN A HEX COLOR
//==============================================
export function newShade(hexColor: string, magnitude: number) {
    // hex must be a full hex value of 6 chars plus the hash.
    // to lighten pass a positive magnitude.
    // to darken pass a negative magnitude.
    hexColor = hexColor.replace(`#`, ``)

    if (hexColor.length === 6) {
        const decimalColor = parseInt(hexColor, 16)

        let r = (decimalColor >> 16) + magnitude
        r > 255 && (r = 255)
        r < 0 && (r = 0)

        let g = (decimalColor & 0x0000ff) + magnitude
        g > 255 && (g = 255)
        g < 0 && (g = 0)

        let b = ((decimalColor >> 8) & 0x00ff) + magnitude
        b > 255 && (b = 255)
        b < 0 && (b = 0)

        return `#${(g | (b << 8) | (r << 16)).toString(16)}`
    } else {
        return hexColor
    }
}

//==================================================
// REORDER LIST (used for drag and drop reorders)
//==================================================
export function reorder(list: any[], startIndex: number, endIndex: number) {
    const result = Array.isArray(list) ? list : Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
  
    return result
}
import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ColumnDef } from '@tanstack/react-table'

// Evertel components and utilities
import { useService } from '@evertel/di'
import { InfoBox, Collapse, Badge, Button, TanTable, Input, Row, Icon } from '@evertel/web/ui'

// Stores and Controllers
import { HistoryTableController } from '@evertel/web/admin'

import { NavigationStore, AppStore, UsersStore } from '../stores'
import Lightbox from './Lightbox'

interface HistoryTableProps {
    type?: string
    model: {
        id: number
    }
}

interface HistoryItem {
    id: string
    createdDate: string
    action: string
    data?: any
    blueUserId?: string
    createdBy?: {
        id: string
        firstName: string
        lastName: string
    }
}

const HistoryTable: React.FC<HistoryTableProps> = observer(({ type, model }) => {

    const historyController = useService(HistoryTableController, [model.id])

    const [collapseStates, setCollapseStates] = useState<Record<string, boolean>>({})


    useEffect(() => {
        if (!AppStore.appLoading) {
            historyController.init(model.id)
        }
    }, [AppStore.appLoading, model.id, historyController])

    useEffect(() => {
        //this will trigger the initial search
        historyController.debouncedSearch()
    }, [
        historyController,
        historyController.limit,
        historyController.page,
        historyController.sortBy,
        historyController.sortOrder
    ])

    const formatDate = useCallback((date: string) => {
        return moment(date).format('MM/DD/YYYY HH:mm:ss')
    }, [])

    const toggleDetail = useCallback((id: string) => {
        setCollapseStates(prev => ({ ...prev, [id]: !prev[id] }))
    }, [])

    const setLightBoxImages = useCallback((images: any) => {
        Lightbox.show({ media: images })
    }, [])

    // Formatting functions
    const formatAction = useCallback((action: string) => {
        let color = 'info'
        const actionWords = action.split('_')

        if (actionWords.includes('added') || actionWords.includes('created') || actionWords.includes('published') || actionWords.includes('verified')) {
            color = 'success'
        } else if (actionWords.includes('removed')) {
            color = 'danger'
        } else if (actionWords.includes('run')) {
            color = 'warning'
        } else if (actionWords.includes('updated')) {
            color = 'secondary'
        }

        return <Badge color={color} className='text-white text-capitalize'>{actionWords.join(' ')}</Badge>
    }, [])

    const formatDescription = useCallback((action: string, row: HistoryItem) => {
        if (row && row.data) {
            switch (action) {
                case 'member_added':
                    return <span><Link to={`/manage/user/${row.data.userId}`}>{row.data.firstName} {row.data.lastName}</Link> was added to the agency</span>

                case 'member_role_added':
                    return (
                        <span>
                            <Link to={`/manage/user/${row.data.userId}`}>{row.data.firstName} {row.data.lastName}</Link> was given the <strong className="text-capitalize">{row.data.role}</strong> role
                            {row.data.subAction === 'verification_code' ? ' by using the agency access code' : ''}
                        </span>
                    )
  
                case 'member_role_removed':
                    return <span><Link to={`/manage/user/${row.data.userId}`}>{row.data.firstName} {row.data.lastName}</Link> role as <strong className="text-capitalize">{row.data.role}</strong> was removed</span>
  
                case 'member_removed':
                    return <span><strong>{row.data.firstName} {row.data.lastName}</strong> was removed from the agency</span>
  
                case 'member_details_updated':
                    return <span><Link to={`/manage/user/${row.data.userId}`}>{row.data.firstName} {row.data.lastName}'s</Link> profile was updated</span>
  
                case 'member_verified':
                    return (
                        <span>
                            <Link to={`/manage/user/${row.data.userId}`}>{row.data.firstName} {row.data.lastName}</Link> was <strong>verified</strong>
                            {row.data.subAction === 'verification_code' ? ' by using the agency access code' : ''}
                        </span>
                    )
  
                case 'member_unverified':
                    return <span><Link to={`/manage/user/${row.data.userId}`}>{row.data.firstName} {row.data.lastName}</Link> was <strong>unverified</strong></span>
  
                case 'room_created':
                    return <span>Room <Link to={`/room/${row.data.roomId}`}>{row.data.name}</Link> was created</span>
  
                case 'department_invite_consumed':
                    if (!row.data.invite) return 'User accepted invite to the agency using an invite token'
                    return <span><Link to={`/manage/user/${row.blueUserId}`}>{row.data.invite.to}</Link> accepted an agency invite using token: {row.data.invite.token}</span>
  
                case 'room_invite_consumed':
                    if (!row.data.invite) return 'User accepted invite to the room using an invite token'
                    return <span><Link to={`/manage/user/${row.blueUserId}`}>{row.data.invite.to}</Link> accepted a room invite to room <Link to={`/room/${row.data.roomId}`}>{row.data.name}</Link> using token: {row.data.invite.token}</span>
        
                case 'room_member_added':
                    return <span><Link to={`/manage/user/${row.data.userId}`}>{row.data.firstName} {row.data.lastName}</Link> was added to room <Link to={`/room/${row.data.roomId}`}>{row.data.name}</Link> as member</span>
      
                case 'room_manager_added':
                    return <span><Link to={`/manage/user/${row.data.userId}`}>{row.data.firstName} {row.data.lastName}</Link> was added to room <Link to={`/room/${row.data.roomId}`}>{row.data.name}</Link> as manager</span>
  
                case 'room_member_removed':
                    return <span><Link to={`/manage/user/${row.data.userId}`}>{row.data.firstName} {row.data.lastName}</Link> was removed from room <Link to={`/room/${row.data.roomId}`}>{row.data.name}</Link></span>
      
                case 'room_manager_removed':
                    return <span><Link to={`/manage/user/${row.data.userId}`}>{row.data.firstName} {row.data.lastName}</Link> was removed from room <Link to={`/room/${row.data.roomId}`}>{row.data.name}</Link></span>
  
                case 'department_invite_sent':
                    return <span>Sent an agency invite to: {row.data.invites.map((i: {to: string}) => i.to).join(', ')}</span>
  
                case 'room_invite_sent':
                    return <span>Sent an invite to the room <Link to={`/room/${row.data.roomId}`}>{row.data.name}</Link> to: {row.data.invites.map((i: {to: string}) => i.to).join(', ')}</span>
  
                case 'audit_run':
                    return <span>A message audit was run (id: {row.data.auditId})</span>
  
                case 'subject_added':
                    if (!row.data.subject) return 'A suspect was added'
                    return <span><strong>{row.data.subject.name || 'General description'}</strong> was added as a suspect</span>
  
                case 'subject_removed':
                    if (!row.data.subject) return 'A suspect was removed'
                    return <span><strong>{row.data.instance.name || 'General description'}</strong> was removed as a suspect</span>
  
                case 'vehicle_added':
                    if (!row.data.vehicle) return 'A vehicle was added'
                    return <span><strong>{row.data.vehicle.year} {row.data.vehicle.make} {row.data.vehicle.model}</strong> was added as a vehicle</span>
  
                case 'vehicle_removed':
                    if (!row.data.vehicle) return 'A vehicle was removed'
                    return <span><strong>{row.data.vehicle.year} {row.data.vehicle.make} {row.data.vehicle.model}</strong> was removed as a vehicle</span>
  
                case 'media_added':
                case 'media_removed': {
                    const img = { url: row.data.url, mimetype: row.data.mimetype, fileName: row.data.fileName }
                    return (
                        <span>
                            <Button color="link" className="p-0" onClick={() => setLightBoxImages(img)}>{row.data.fileName}</Button>
                        </span>
                    )
                }
  
                case 'updated':
                case 'subject_update':
                case 'vehicle_update': {
                    const keys = row.data.changeSet ? Object.keys(row.data.changeSet) : []
                    const msg = keys.join(', ')

                    return (
                        <div>
                            <span className='text-capitalize'>
                                Changed: {msg}
                                <Button color='link' size='sm' onClick={() => toggleDetail(row.id)} className="ml-1">
                                    <Icon name={collapseStates[row.id] ? 'eye-slash' : 'eye'} />
                                </Button>
                            </span>
                            <Collapse visible={collapseStates[row.id]}>
                                <div className="bg-light p-2" style={{ maxWidth: '50vw' }}>
                                    {keys.map((key) => (
                                        <div key={key}>
                                            <strong className='text-capitalize'>{key}</strong>
                                            <InfoBox color='success' className='mb-1 p-2' style={{ wordWrap: 'break-word' }}>
                                                <strong>To:</strong> {JSON.stringify(row.data.changeSet[key].to)}
                                            </InfoBox>
                                            <InfoBox color='danger' className="p-2" style={{ wordWrap: 'break-word' }}>
                                                <strong>From:</strong> {JSON.stringify(row.data.changeSet[key].from)}
                                            </InfoBox>
                                        </div>
                                    ))}
                                </div>
                            </Collapse>
                        </div>
                    )
                }

                default:
                    return null
            }
        }
        return null
    }, [collapseStates, setLightBoxImages, toggleDetail])

    // Table configuration
    const columns = useMemo<ColumnDef<HistoryItem>[]>(() => [
        {
            accessorKey: 'createdDate',
            header: 'Date',
            cell: ({ getValue }) => formatDate(getValue() as string),
            enableSorting: true,
            size: 130
        },
        {
            accessorKey: 'action',
            header: 'Action',
            cell: ({ getValue }) => formatAction(getValue() as string),
            enableSorting: true,
            size: 150
        },
        {
            accessorKey: 'action',
            header: 'Description',
            cell: ({ getValue, row }) => {
                return (
                    <div style={{textWrap: 'wrap'}}>
                        {formatDescription(getValue() as string, row.original)}
                    </div>
                )
            },
            enableSorting: false,
            size: 450
        },
        {
            accessorKey: 'createdBy',
            header: 'By User',
            cell: ({ getValue }) => {
                const user = getValue() as HistoryItem['createdBy']
                if (!user) return null
                return (
                    <Link to={`/manage/user/${user.id}`} className="btn btn-link">
                        {user.firstName + ' ' + user.lastName}
                    </Link>
                )

            },
            enableSorting: false,
            size: 100
        }
    ], [formatAction, formatDescription, formatDate])

    const handleSearch = useCallback((field: 'blueUserId' | 'action' | 'data', value: string) => {
        switch (field) {
            case 'blueUserId':
                historyController.setBlueUserIdSearch(value)
                break
            case 'action':
                historyController.setActionSearch(value)
                break
            case 'data':
                historyController.setDataSearch(value)
                break
        }
        historyController.debouncedSearch()
    }, [historyController,
        historyController.limit,
        historyController.page,
        historyController.sortBy,
        historyController.sortOrder])

    return (
        <>
            <Lightbox ref={(l: any) => Lightbox.lightboxInstance = l} />
            <Row className="mb-3">
                <Input
                    type="number"
                    placeholder="Search by User ID"
                    value={historyController.blueUserIdSearch}
                    onChange={(e) => handleSearch('blueUserId', e.target.value)}
                />
                <Input
                    className="mx-3"
                    placeholder="Search by Action"
                    value={historyController.actionSearch}
                    onChange={(e) => handleSearch('action', e.target.value)}
                />
                <Input
                    placeholder="Search by Data"
                    value={historyController.dataSearch}
                    onChange={(e) => handleSearch('data', e.target.value)}
                />
            </Row>
            <TanTable
                className='table-hover'
                remote={true}
                loading={historyController.isLoading}
                data={historyController.history}
                rowCount={historyController.historyCount}
                columns={columns}
                options={{
                    responsive: true,
                    responsiveHeight: 'calc(100vh - 280px)',
                    truncateCells: true,
                    pageSize: historyController.limit,
                    pageNumber: historyController.page,
                    sortName: historyController.sortBy,
                    sortOrder: historyController.sortOrder,
                    onPageChange: (page) => historyController.setPage(page),
                    onPageSizeChange: (pageSize) => historyController.setLimit(pageSize),
                    onSortChange: (sortName, sortOrder) => historyController.setSorting(sortName, sortOrder as 'asc' | 'desc'),
                    emptyState: <span>No matching logs</span>
                }}
            />
        </>
    )
})

export { HistoryTable }
import {
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react'
import classNames from 'classnames'
import Select, { Props, ControlProps, components as reactSelectComponents }  from 'react-select'
import { observer } from 'mobx-react-lite'

// evertel
import {
    ContentPlaceholder,
    Icon,
    Text,
    Col,
    Row,
    CustomAsyncPaginate
} from '@evertel/web/ui'
import { useUserDetail } from '@evertel/web/user'
import { useService } from '@evertel/di'
import { RoomDirectorySearchController, SearchScopes } from '@evertel/room'
import { APIDataBlueUser, APIDataRoom } from '@evertel/types'
import { DepartmentsAccessController } from '@evertel/departments-access'
import { SessionState } from '@evertel/session'
import { RoomContext } from '../RoomContext'
import { SelectUserDisplay } from './SelectUserDisplay'
import { formatForSelect } from '@evertel/utils'
import { CurrentUserController } from '@evertel/blue-user'

interface RoomDirectorySearchSelectProps extends Props {
    room: APIDataRoom,
    canManageRoom?: boolean,
    selected?: Record<string, any>[],
    showAddMembersButton?: boolean,
    onAddMembersClick?: () => void,
    menuIsOpen?: boolean,
    showFilters?: boolean,
    additionalFilters?: [],
    onEndReachedThreshold?: number,
    searchFilter?: Record<string, any>,
    className?: string,
    onSelect?: (user: APIDataBlueUser) => void
}

const filterOptions = [
    { value: 'all', label: 'Everyone' },
    { value: 'managers', label: 'Managers' },
    { value: 'members', label: 'Members' },
    { value: 'guest', label: 'Guests' }
]

interface CustomControlProps {
    renderAdditionalControls: () => React.ReactNode;
}
interface ExtendedSelectProps extends Props {
    customControlProps: CustomControlProps;
}

const CustomControl = ({ ...props }: ControlProps<unknown, false> & { selectProps: ExtendedSelectProps }) => {
    const { selectProps } = props
    const { renderAdditionalControls } = selectProps.customControlProps as CustomControlProps
  
    return (
        <>
            <reactSelectComponents.Control {...props} />
            {renderAdditionalControls()}
        </>
    )
}

const RoomDirectorySearchSelect: React.FC<RoomDirectorySearchSelectProps> = observer(({
    selected = [],
    room,
    canManageRoom,
    showAddMembersButton,
    onAddMembersClick,
    menuIsOpen = true,
    placeholder = 'Find members...',
    className,
    onSelect,
    showFilters,
    additionalFilters,
    onEndReachedThreshold = 80,
    ...otherProps
}) => {
    const session = useService(SessionState, [])
    const roomDirectorySearchController = useService(RoomDirectorySearchController, [room?.id])
    const { roomController } = useContext(RoomContext)
    const { openUserDetail } = useUserDetail()
    const currentUserController = useService(CurrentUserController, [session.currentUserId])

    const [isLoading, setIsLoading] = useState(true)
    const [isSearching, setIsSearching] = useState(false)

    const [key, setKey] = useState(room?.id)
    const [selectedFilter, setSelectedFilter] = useState<SearchScopes | 'guest'>('all')

    useEffect(() => {

        setIsLoading(true)

        roomDirectorySearchController.init( room.id, false )
        onChangeSearchScope(selectedFilter)

        //needs to render once with loading true to reload async paginate
        setTimeout(() => { setIsLoading(false)}, 1)
        return () => {
            roomDirectorySearchController.destroy()
        }
    }, [room.id])

    const search = useCallback(async (
        searchString: string,
        loadedOptions: APIDataBlueUser[],
        additional: { 
            page: number
            // filter: string
        }
    ) => {

        if (roomDirectorySearchController.searchTerm !== searchString) {
            // only set searchTerm if it changes
            roomDirectorySearchController.setSearchTerm(searchString)
        }

        roomDirectorySearchController.setPage(additional?.page)

        try {
            setIsSearching(true)
            await roomDirectorySearchController.search()

        } catch (error) {
            console.error('ERROR: RoomDirectorySearchSelect.search():', error)
        } finally {
            setIsSearching(false)
        }
        // grab results and format them for the Select
        const results = formatForSelect(roomDirectorySearchController.usersLatestFetch, 'firstName+lastName', 'id')
        const hasMore = (roomDirectorySearchController.usersLatestFetch.length >= roomDirectorySearchController.limit)

        return {
            options: results || [],
            hasMore,
            additional: {
                page: additional?.page + 1 || 1
                //filter: selectedFilter
            }
        }
    }, [room?.id])

    const onChangeSearchScope = (scope: SearchScopes | 'guest') => {
        if (scope === 'guest') {
            roomDirectorySearchController.setSearchScope('all')
            roomDirectorySearchController.setSearchRole(scope)
        } else {
            roomDirectorySearchController.setSearchScope(scope)
            roomDirectorySearchController.setSearchRole('all')
        }
        setSelectedFilter(scope)
    }

    const onSelectOption = (e) => {
        if (onSelect) onSelect(e)
    }

    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number): boolean => {
        if (isSearching || (scrollHeight===0 && clientHeight===0 && scrollTop === 0)) return false
        const maxPixelThreshold = 300
        const percentThreshold = 100 - onEndReachedThreshold

        const pxFromBottom = scrollHeight - clientHeight - scrollTop
        const percentThresholdFromBottomInPx = ((percentThreshold / 100) * (scrollHeight - clientHeight))
    
        // Determine the threshold to use (whichever is smaller)
        const thresholdToUse = Math.min(percentThresholdFromBottomInPx, maxPixelThreshold)
        return pxFromBottom < thresholdToUse
    }

    const resetSelect = () => {
        // change the key to force the list to refresh
        setKey((prevKey) => prevKey + 1)
    }

    useEffect(()=>{
        if (roomDirectorySearchController.notificationUpdate > 0)
        {
            resetSelect()
            roomController.fetchAllUsersIds()
        }
    }, [roomDirectorySearchController.notificationUpdate])

    const isCurrentUserGuest = currentUserController.getRoleInDepartment(roomController.room?.departmentId) === 'guest'
    showAddMembersButton = showAddMembersButton && canManageRoom && !room?.isArchived && !isCurrentUserGuest

    const renderAdditionalControls = () => (
        <>
            {showFilters && (
                <Select
                    options={filterOptions}
                    value={filterOptions.find(option => option.value === selectedFilter)}
                    onChange={(s) => onChangeSearchScope(s.value as SearchScopes)}
                    isSearchable={false}
                    className="react-select mt-2"
                    classNamePrefix="select"
                />
            )}
            {showAddMembersButton && (
                <button
                    className="btn btn-outline-success mt-2 w-100"
                    style={{padding: '8px 12px'}}
                    onClick={() => onAddMembersClick && onAddMembersClick()}
                >
                    <Row
                        align="left"
                        valign="center"
                        className="cursor-pointer"
                    >
                        <Icon
                            name="user-plus"
                            color="white"
                            className="mr-2 bg-success align-items-center justify-content-center d-flex"
                            style={{
                                borderRadius: 5,
                                width: 40,
                                height: 40
                            }}
                        />
                        <strong>Add People</strong>
                    </Row>
                </button>
            )}
        </>
    )

    if (isLoading || !room?.id) {
        return (
            <ContentPlaceholder
                className={className}
                style={{
                    height: 38
                }}
            />
        )
    }

    return (
        <Col>
            <CustomAsyncPaginate
                keyName={key + selectedFilter}
                searchOnly={true}
                loadOptions={search}
                debounceTimeout={300}
                autoFocus={true}
                additional={{
                    page: 1
                    //filter: selectedFilter
                }}
                loadingMessage={() => 'Loading directory...'}
                defaultOptions
                value={selected}
                shouldLoadMore={shouldLoadMore}
                onChange={onSelectOption}
                noOptionsMessage={() => 'No matching users'}
                placeholder={placeholder}
                menuIsOpen={menuIsOpen}
                components={{ 
                    Option: SelectUserDisplay,
                    Control: CustomControl
                }}
                className={classNames('w-100', className )}
                customProps={{
                    room,
                    searchScope: roomDirectorySearchController.searchScope,
                    openUserDetail,
                    isCurrentUserGuest
                }}
                customControlProps={{ renderAdditionalControls }}
                {...otherProps}
            />
        </Col>
    )
})

export { RoomDirectorySearchSelect }

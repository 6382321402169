
import { observer } from 'mobx-react'
// evertel
import { Text, Row } from '@evertel/web/ui'
import { HTMLAttributes, useEffect } from 'react'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { DepartmentController } from '@evertel/department'
import { HeaderButtonSort } from './HeaderButtonSort'
import { HeaderButtonAdd } from './HeaderButtonAdd'
import { HeaderButtonSettings } from './HeaderButtonSettings'
import { SelectedDepartmentAccessController } from '@evertel/departments-access'


interface DrawerHeaderProps extends HTMLAttributes<HTMLDivElement> {
    onClick?: () => void
}


const DrawerHeader: React.FC<DrawerHeaderProps> = observer(({
    ...otherProps
}) => {

    const session = useService(SessionState, [])
    const departmentController = useService(DepartmentController, [])
    const selectedDepartmentAccessController = useService(SelectedDepartmentAccessController, [session.selectedDepartmentId])

    useEffect(() => {
        (async () => {
            if (!session.selectedDepartmentId) return

            await departmentController.init(session.selectedDepartmentId)
            await selectedDepartmentAccessController.init(session.currentUserId)

        })()
    }, [departmentController, selectedDepartmentAccessController, session.currentUserId, session.selectedDepartmentId])

    return (
        <Row
            align="between"
            className="drawer-header"
            {...otherProps}>
            <Text className="dept-name">
                {departmentController.shortName}
            </Text>
            <Row>
                <HeaderButtonSort />
                <HeaderButtonAdd
                    className="mx-2"
                    userRole={selectedDepartmentAccessController.role}
                />
                <HeaderButtonSettings
                    userRole={selectedDepartmentAccessController.role}
                    className="mr-1"
                />
            </Row>
        </Row>
    )
})

export { DrawerHeader }

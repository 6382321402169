import React, { forwardRef, useState, useEffect, ChangeEventHandler } from 'react'
import classNames from 'classnames'

export interface TextareaProps {
    id?: string,
    className?: string
    /** Delay onChange event while typing. If set to true onChange event will be delayed 500ms, you can also provide the number of milliseconds you want to delay the onChange event.*/
    delay?: boolean | number
    disabled?: boolean
    onChange?: ChangeEventHandler<HTMLTextAreaElement>
    value?: string|string[]|number
    rows?: number
    cols?: number
    placeholder?: string
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({
    id,
    className,
    delay,
    disabled,
    onChange,
    value,
    placeholder,
    rows = 4,
    cols = 1,
    children,
    ...otherProps
}, ref) => {

    const [text, setText] = useState<React.ChangeEvent<HTMLTextAreaElement>>()

    useEffect(() => {
        const timeOutId = setTimeout(() =>
            (text && onChange) && onChange(text),
            (typeof delay === 'number') ? delay : 500
        )
        return () => clearTimeout(timeOutId)

    }, [text])

    const _className = classNames(
        'form-control',
        {
        //   'is-invalid': invalid,
        //   'is-valid': valid,
        },
        className,
      )

    return (
        <textarea
            ref={ref}
            id={id}
            value={value || ''}
            rows={rows}
            cols={cols}
            className={_className}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(event) => ((delay) ? setText(event) : (onChange) && onChange(event))}
            {...otherProps}>
            {children}
        </textarea>
    )
})

export { Textarea }
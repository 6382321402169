import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
// evertel
import { Icon, FloatingMenu, MenuItem, MenuHeader, useUI } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { UserSettingsController } from '@evertel/blue-user'
import { SessionState } from '@evertel/session'
import { PushService } from '@evertel/push'
import { HeaderController } from '../controller'

type HeaderNotificationsDropdownProps = React.ButtonHTMLAttributes<HTMLButtonElement>

const HeaderNotificationsDropdown: React.FC<HeaderNotificationsDropdownProps> = observer(({
    ...props
}) => {

    const { showAlertBar } = useUI()
    const session = useService(SessionState, [])
    const userSettingsController = useService(UserSettingsController, [])
    const headerController = useService(HeaderController, [])
    const pushService = useService(PushService, [])

    useEffect(() => {
        userSettingsController.init(session.currentUserId)
    }, [session.currentUserId, userSettingsController])


    const notificationsEnabled = pushService?.notificationPermission === 'granted' && !userSettingsController?.disableNotifications

    const onToggle = async () => {
        const permission = await pushService.getCurrentNotificationPermission()

        if (permission === 'granted') {
            userSettingsController.toggleAllNotifications()
        } else if (permission === 'default') {
            headerController.promptSystemNotifications()
        } else {
            showAlertBar({ type: 'denied', isOpen: true })
        }
    }

    return (
        <FloatingMenu
            label="Notifications"
            renderLabel={() => (
                <Icon
                    name={notificationsEnabled ? 'bell' : 'bell-slash'}
                    size={16}
                    className={`d-block ${notificationsEnabled ? 'text-success' : 'text-danger'}`}
                    style={{width: 20, textAlign: 'center'}}
                />
            )}
            {...props}
        >
            <MenuHeader label="Notifications" />
            <MenuItem
                label={notificationsEnabled ? 'Mute All Notifications' : 'Enable Notifications'}
                icon={{
                    name: notificationsEnabled ? 'bell-slash' : 'bell'
                }}
                onClick={() => onToggle()}
            />
        </FloatingMenu>
    )
})

export { HeaderNotificationsDropdown }

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import classNames from 'classnames'
import { Droppable } from 'react-beautiful-dnd'
import { Card, CardBody, Text } from '@evertel/web/ui' 
import { schemaItemTypes, schemaItemTypesLayout } from '../data/_schemaItemTypes'
import { ToolboxItem } from './ToolboxItem'
import { SchemaBuilderController } from '../../../controller'
import { useWindowDimensions } from '@evertel/web/hooks'

interface ToolboxProps {
    controller: SchemaBuilderController
}

const Toolbox: React.FC<ToolboxProps> = ({
    controller,
    ...otherProps
}) => {

    const { height: windowHeight } = useWindowDimensions()

    return (
        <div
            className={classNames('sticky-container', { 'position-fixed': windowHeight > 750 })}>
            {/* @ts-ignore */}
            <Droppable
                droppableId="toolbox"
                isDropDisabled={false}>
                {provided => (
                    <Card
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="toolbox"
                        color="light"
                        {...otherProps}>
                        <CardBody>
                            <Text
                                tag="div"
                                bold
                                className="pb-2">
                                Layout
                            </Text>
                            {schemaItemTypesLayout.layout.map((el, idx) => {
                                const item = schemaItemTypes.find(i => i.id === el)
                                if (!item) return null
                                return (
                                    <ToolboxItem
                                        key={item?.id}
                                        index={idx}
                                        item={item}
                                    /> 
                                )
                            })}
                            <Text
                                tag="div"
                                bold
                                className="pb-2">
                                Form Elements
                            </Text>
                            {schemaItemTypesLayout.form.map((el, idx) => {
                                const item = schemaItemTypes.find(i => i.id === el)
                                if (!item) return null
                                return (
                                    <ToolboxItem
                                        key={`${item?.id}-${idx}`}
                                        index={idx}
                                        item={item}
                                        exclude={(controller.schemaKey !== 'root') ? ['list'] : undefined}
                                    /> 
                                )
                            })}
                        </CardBody>
                    </Card>
                )}
            </Droppable>
        </div>
    )
}

export { Toolbox }
Toolbox.displayName = 'SchemaBuilder Toolbox'

import React from 'react'
import { Row, ToggleSwitch, Text } from '@evertel/web/ui'
import Wrapper from './Wrapper'

interface BulletinUrgentProps {
    id: string,
    value: boolean,
    onChange: (id: string, value: boolean) => void,
    title: string,
    required: boolean,
    errors: string[]
}

const BulletinUrgentWriteWidget: React.FC<BulletinUrgentProps> = ({
    id,
    value,
    onChange,
    title,
    required,
    errors
}) => {
    return (
        <Wrapper
            title={title}
            required={required}
            errors={errors}>
            <ToggleSwitch
                id="isUrgent"
                checked={value}
                onChange={(value) => {
                    onChange(id, value)
                }}
            />
        </Wrapper>
    )
}


const BulletinUrgentReadWidget = ({
    value
}: { value: boolean }) => {

    if (!value) {
        return null
    }

    return (
        <Row
            align="center"
            style={{
                background: '#FF0000',
                padding: 0.5
            }}>
            <Text
                bold
                color='white'>
                URGENT
            </Text>
        </Row>
    )
}

export {
    BulletinUrgentWriteWidget,
    BulletinUrgentReadWidget
}

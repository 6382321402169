import { useContext } from 'react'
import { ServiceContext  } from './ServiceContext'

export const findService = (id:string, services: any) => {
    const service = services && services.find((service:any) => {
        return service.id === id
    })

    if (!service) {
        throw new Error(`Service "${id}" not found.`)
    }

    return service.value
}

export const useService = (id: string) => {
    const context = useContext(ServiceContext)

    return findService(id, context)
}

import React, { forwardRef, HTMLAttributes, ReactNode, createContext, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import { Transition } from 'react-transition-group'
// evertel
import { useForkedRef } from '@evertel/hooks'
import { Backdrop } from '../Backdrop'
import ModalContent from './ModalContent'
import ModalDialog from './ModalDialog'

interface Props extends HTMLAttributes<HTMLDivElement> {
    /** Align the modal in the center or top of the screen. */
    alignment?: 'top'|'center'
    /** Align the modal in the center or top of the screen. */
    backdrop?: boolean|'static'
    /** Transition time to open/close in milliseconds */
    duration?: number
    /** Set modal to covers the entire user viewport. */
    fullscreen?: boolean|'sm'|'md'|'lg'|'xl'|'xxl'
    /** Closes the modal when escape key is pressed. */
    keyboard?: boolean
    onClose?: () => void
    onCloseDone?: () => void
    /** Callback fired when the modal is shown, its backdrop is static and a click outside the modal or an escape key press is performed with the keyboard option set to false. */
    onClosePrevented?: () => void
    onShow?: () => void
    /** Generates modal using createPortal. */
    portal?: boolean
    scrollable?: boolean
    size?: 'sm'|'md'|'lg'|'xl'
    /** Remove animation to create modal that simply appear rather than fade in to view. */
    transition?: boolean
    /** By default the component is unmounted after close animation, if you want to keep the component mounted set this property to false. */
    unmountOnClose?: boolean,
    visible?: boolean
    children: ReactNode
    className?: string
}

interface ModalContextProps {
    visible?: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean|undefined>>
    handleClose?: () => void
}

export const ModalContext = createContext({} as ModalContextProps)

// Global modal stack
let modalStack: Array<() => void> = []

const Modal = forwardRef<HTMLDivElement, Props>(({
    alignment,
    backdrop = true,
    duration = 150,
    fullscreen,
    keyboard = true,
    onClose,
    onCloseDone,
    onClosePrevented,
    onShow,
    portal = true,
    scrollable,
    size,
    transition = true,
    unmountOnClose = true,
    visible = false,
    children,
    className,
    ...otherProps
}, ref) => {

    const modalRef = useRef<HTMLDivElement>(null)
    const modalContentRef = useRef<HTMLDivElement>(null)
    const forkedRef = useForkedRef(ref, modalRef)
    const previouslyFocusedElement = useRef<HTMLElement | null>(null)

    const [_visible, setVisible] = useState(visible)
    const [staticBackdrop, setStaticBackdrop] = useState(false)
    const [mouseDownTarget, setMouseDownTarget] = useState<EventTarget | null>(null)

    useEffect(() => {
        setVisible(visible)
    }, [visible])

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose()
        } else {
            setVisible(false)
        }
    }, [onClose, setVisible])

    const handleDismiss = useCallback(() => {
        if (backdrop === 'static') {
            setStaticBackdrop(true)
            onClosePrevented && onClosePrevented()
            return
        }
        return handleClose()
    }, [backdrop, handleClose, onClosePrevented])

    const contextValues = {
        visible: _visible,
        setVisible,
        handleClose
    }

    useEffect(() => {
        if (visible && !_visible) {
            // Modal is about to open
            previouslyFocusedElement.current = document.activeElement as HTMLElement
        } else if (!visible && _visible) {
            // Modal is about to close
            if (previouslyFocusedElement.current && typeof previouslyFocusedElement.current.focus === 'function') {
                previouslyFocusedElement.current.focus()
            }
        }
    }, [visible, _visible])

    useEffect(() => {
        if (_visible) {
            modalStack.push(handleDismiss)
        } else {
            modalStack = modalStack.filter(dismiss => dismiss !== handleDismiss)
        }

        return () => {
            modalStack = modalStack.filter(dismiss => dismiss !== handleDismiss)
        }
    }, [_visible, handleDismiss])

    const handleMouseDown = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            //this ensures that the mouse down and up happen in the same place
            setMouseDownTarget(event.target)
        }
    }, [])

    const handleMouseUp = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget && event.target === mouseDownTarget) {
            //currentTarget is the object the event was attached to, only allowing dismissal if the background was directly clicked
            handleDismiss()
        }
        setMouseDownTarget(null)
    }, [mouseDownTarget, handleDismiss])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                event.stopPropagation()
                if (modalStack.length > 0) {
                    modalStack[modalStack.length - 1]()
                }
            }
        }

        //this might work better attached to document?
        //difficult to get this triggering after child elements
        if (_visible && modalRef.current) {
            modalRef.current.addEventListener('keydown', handleKeyDown)
        }

        return () => {
            if (modalRef.current) {
                modalRef.current.removeEventListener('keydown', handleKeyDown)
            }
        }
    }, [_visible])

    useLayoutEffect(() => {
        if (staticBackdrop) {
            setTimeout(() => setStaticBackdrop(false), duration)
        }
    }, [staticBackdrop, duration])

    const getTransitionClass = (state: string) => {
        return state === 'entering'
            ? 'd-block'
            : state === 'entered'
                ? 'show d-block'
                : state === 'exiting'
                    ? 'd-block'
                    : ''
    }

    const _className = classNames(
        'modal',
        {
            'modal-static': staticBackdrop,
            fade: transition
        },
        className
    )

    // set focus to modal after open
    useLayoutEffect(() => {
        if (_visible) {
            document.body.classList.add('modal-open')

            if (backdrop) {
                document.body.style.overflow = 'hidden'
                document.body.style.paddingRight = '0px'
            }

            setTimeout(() => {
                modalRef.current?.focus()
            }, (!transition) ? 0 : duration)

        } else {
            if (modalStack.length === 0) {
                document.body.classList.remove('modal-open')

                if (backdrop) {
                    document.body.style.removeProperty('overflow')
                    document.body.style.removeProperty('padding-right')
                }
            }
        }
        return () => {
            if (modalStack.length === 0) {
                document.body.classList.remove('modal-open')
                if (backdrop) {
                    document.body.style.removeProperty('overflow')
                    document.body.style.removeProperty('padding-right')
                }
            }
        }
    }, [_visible, backdrop, transition, duration])

    const modal = (ref?: React.Ref<HTMLDivElement>, transitionClass?: string) => {
        return (
            <ModalContext.Provider value={contextValues}>
                <div
                    ref={ref}
                    className={classNames(_className, transitionClass)}
                    tabIndex={-1}
                    role="dialog"
                    aria-modal="true"
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}>
                    <ModalDialog
                        alignment={alignment}
                        fullscreen={fullscreen}
                        scrollable={scrollable}
                        size={size}>
                        <ModalContent
                            ref={modalContentRef}
                            {...otherProps}>
                            {children}
                        </ModalContent>
                    </ModalDialog>
                </div>
            </ModalContext.Provider>
        )
    }

    const content = (
        <>
            <Transition
                in={_visible}
                mountOnEnter
                nodeRef={modalRef}
                onEnter={onShow}
                onExit={onClose}
                onExited={onCloseDone}
                unmountOnExit={unmountOnClose}
                timeout={(!transition) ? 0 : duration}>
                {(state) => {
                    const transitionClass = getTransitionClass(state)
                    return modal(forkedRef, transitionClass)
                }}
            </Transition>
            {backdrop && (
                <Backdrop 
                    visible={_visible} 
                />
            )}
        </>
    )

    return (typeof window !== 'undefined' && portal)
        ? createPortal(content, document.body)
        : content
})

export default Modal
Modal.displayName = 'Modal'
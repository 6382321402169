import React, { ElementType, forwardRef, ReactNode } from 'react'
import classNames from 'classnames'

interface InputGroupTextProps {
    className?: string;
    tag?: ElementType;
    children?: ReactNode;
}
interface InputGroupTextProps extends React.HTMLAttributes<HTMLElement> {
    className?: string;
    tag?: ElementType;
    children?: ReactNode;
}

export const InputGroupText = forwardRef<HTMLElement, InputGroupTextProps>(({
    tag: Tag = 'span',
    className,
    children,
    ...otherProps
}, ref) => {
    const classes = classNames('input-group-text', className)

    return (
        <Tag
            ref={ref}
            className={classes}
            {...otherProps}
        >
            {children}
        </Tag>
    )
})

import { makeAutoObservable, runInAction } from 'mobx'
import { debounce } from 'lodash'
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataDocumentSchemaCategory } from '@evertel/types'

class SchemaCategorySearchController {
    categories: APIDataDocumentSchemaCategory[] = []
    categoriesLatestFetch: APIDataDocumentSchemaCategory[] = []
    categoriesCount = 0
    departmentId = 0
    searchTerm = ''
    page = 1
    limit = 25
    sortBy = 'name'
    sortOrder: 'asc' | 'desc' = 'desc'
    isLoading = false
    error: Error | null = null

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = (departmentId: number) => {
        this.departmentId = departmentId
        this.reset()
    }

    reset = () => {
        this.resetMeta()
        this.searchTerm = ''
        this.sortBy = 'name'
        this.sortOrder = 'asc'
        this.error = null
    }

    resetMeta = () => {
        this.categories = []
        this.categoriesLatestFetch = []
        this.categoriesCount = 0
        this.page = 1
        this.isLoading = false
    }

    setSearchTerm = (value: string) => {
        this.searchTerm = value
        this.resetMeta()
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
        this.resetMeta()
    }

    setSorting = (sortBy: string, sortOrder: 'asc' | 'desc') => {
        this.sortBy = sortBy
        this.sortOrder = sortOrder
        this.resetMeta()
    }

    debouncedSearchInternal = debounce(async () => {
        try {
            await this.search()
        } catch (error) {
            runInAction(() => {
                this.error = error instanceof Error ? error : new Error('An unknown error occurred')
                this.isLoading = false
            })
        }
    }, 300)

    debouncedSearch = () => {
        this.isLoading = true
        return this.debouncedSearchInternal()
    }

    search = async () => {
        if (!this.departmentId) return

        runInAction(()=> {
            this.isLoading = true
        })

        let count = this.categoriesCount
        let results: APIDataDocumentSchemaCategory[] = []


        // Fetch the count if it doesn't exist
        if (!count) {
            count = await this.fetchCategoriesCount()
        }

        this.categoriesLatestFetch = []

        // Fetch categories if there are more to fetch
        if (count > this.categories.length) {
            results = await this.fetchCategories()
        }

        runInAction(() => {
            this.categoriesLatestFetch = results
            this.categories = [...this.categories, ...results]
            this.categoriesCount = count
            this.isLoading = false
        })
       
    }

    fetchCategories = async (): Promise<APIDataDocumentSchemaCategory[]> => {
        if (this.departmentId) {
            return this.api.Routes.Department.getDocumentSchemaCategories(this.departmentId, this.filter)
        } else {
            return this.api.Routes.DocumentSchemaCategory.get(this.filter)
        }
    }

    fetchCategoriesCount = async (): Promise<number> => {
        let response
        if (this.departmentId) {
            response = await this.api.Routes.Department.getDocumentSchemaCategoriesCount(this.departmentId, this.filter.where)
        } else {
            response = await this.api.Routes.DocumentSchemaCategory.getCount(this.filter.where)
        }
        return response.count ?? 0
    }

    get filter() {
        return {
            where: {
                departmentId: this.departmentId,
                name: {
                    like: `%${this.searchTerm}%`
                }
            },
            skip: (this.page - 1) * this.limit,
            limit: this.limit,
            order: `${this.sortBy} ${this.sortOrder}`
        }
    }
}

decorate(injectable(), SchemaCategorySearchController)
decorate(inject(Api), SchemaCategorySearchController, 0)

export { SchemaCategorySearchController }
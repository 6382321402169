import { ContainerModule } from '@evertel/di'
import { DepartmentStore } from './store'
import { DepartmentController, DepartmentInvitesSearchController, DepartmentUserController, DepartmentSSOController, DepartmentsSearchController, ChildDepartmentController } from './controllers'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(DepartmentStore).toSelf().inSingletonScope()
        bind(DepartmentController).toSelf()
        bind(DepartmentSSOController).toSelf()
        bind(DepartmentInvitesSearchController).toSelf()
        bind(DepartmentUserController).toSelf()
        bind(DepartmentsSearchController).toSelf()
        bind(ChildDepartmentController).toSelf()
    })
]

export { DI_MODULES }

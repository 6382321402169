import { Component } from 'react'
import { Button, Row, Col, Badge } from '@evertel/web/ui'
import 'video-react/dist/video-react.css'
//import { Player, BigPlayButton } from 'video-react'
import classNames from 'classnames'
// evertel
import { Icon, SecuredImage, SecuredVideo } from '.'
import { getMediaType, scaleMedia } from '@evertel/utils'
import Colors from '../scss/_colors.module.scss'
import { DeviceStore, AnalyticsStore } from '../stores'
import { formatBytes } from '../utilities/numberHelpers'

// *************************************************
// Pass in array of images and/or videos and Lightbox will display in large format with thumbnails to click through
// Pass in currentItem to start at specific image
// *************************************************


//============================================================================================================
class Lightbox extends Component {

    static lightboxInstance

    static show(config) {
        Lightbox.lightboxInstance.showModal(config)
    }

    static hide() {
        Lightbox.lightboxInstance.hideModal()
    }

    state = {
        currentItem: 0
    }

    showModal = (config = {}) => {
        this.setState({
            isOpen: true,
            media: config.media, // array of media
            currentItem: config.currentItem || 0 // starting index
        },
        () => {
            // log to analytics
            AnalyticsStore.logModalView('lightbox')
        }
        )
    }

    hideModal = () => {
        this.setState({
            isOpen: false
        })
    }

    //=============================
    // ACTIONS
    //=============================

    gotoImage = (item) => {
        if (item === 'prev') {
            this.setState({ currentItem: this.state.currentItem - 1 })
        } else if (item === 'next') {
            this.setState({ currentItem: this.state.currentItem + 1 })
        } else {
            this.setState({ currentItem: item })
        }
        this.renderImage()
    }

    closeLightbox = (event) => {
        const modalId = document.getElementById('lightboxBackdrop')
        // make sure event only happens if they click the backdrop
        if (modalId || event.target.id === 'close' || event.target.parentNode.id === 'close') {
            this.hideModal()
        }
    }

    //===============================
    // RENDERERS
    //===============================

    renderArrowPrev = () => {
        if (this.state.currentItem === 0) return null

        return (
            <Button color="primary" size="lg" onClick={() => this.gotoImage('prev')} className="mr-2">
                <Icon name="arrow-left" />
            </Button>
        )
    }

    renderArrowNext = () => {
        if (this.state.currentItem === (this.state.media.length - 1)) return null

        return (
            <Button color="primary" size="lg" onClick={() => this.gotoImage('next')} className="ml-2">
                <Icon name="arrow-right" />
            </Button>
        )
    }

    renderImage = () => {
        let { currentItem, media } = this.state
        const imageMaxWidth = Math.floor(DeviceStore.screenWidth * 0.9)
        const imageMaxHeight = Math.floor(DeviceStore.screenHeight * 0.8)

        if (!media.length) return null

        if (currentItem > media.length) {
            currentItem = 0
        }

        const mediaItem = media[currentItem]

        const mediaType = getMediaType(mediaItem)

        if (mediaType === 'image') {
            // scale image maintaining aspect ratio
            // grab the size (version) closest to mediaMaxHeight
            const version = mediaItem.getClosestVersionByHeight(imageMaxHeight)
            const scaledDimensions = scaleMedia({
                maxWidth: imageMaxWidth,
                maxHeight: imageMaxHeight,
                naturalHeight: version.height,
                naturalWidth: version.width
            })

            return (
                <SecuredImage
                    data={mediaItem}
                    onClick={this.hideModal || null}
                    loadingIndicator={true}
                    resizeMode="contain"
                    width={scaledDimensions.width}
                    height={scaledDimensions.height}
                    style={{
                        backgroundColor: Colors.gray900
                    }}
                />
            )
        } else if (mediaType === 'video') {
            // scale video maintaining aspect ratio
            const scaledVideoDimensions = scaleMedia({
                maxWidth: imageMaxWidth,
                maxHeight: imageMaxHeight,
                naturalHeight: media.meta?.height || 1080,
                naturalWidth: media.meta?.width || 1920
            })

            return (
                <SecuredVideo
                    data={mediaItem}
                    width={scaledVideoDimensions.width}
                    height={scaledVideoDimensions.height}
                />
            )
        }
    }

    renderThumbnails() {
        const { media, currentItem } = this.state

        if (media?.length > 1) {
            return (
                media.map((item, idx) =>
                    (getMediaType(item) === 'image') ?
                        <SecuredImage
                            data={item}
                            width={150}
                            height={100}
                            resizeMode="cover"
                            key={idx}
                            onClick={() => this.gotoImage(idx)}
                            className="mr-2 border-light"
                            style={{
                                backgroundColor: Colors.gray900,
                                opacity: (currentItem !== idx) ? 0.5 : 1.0
                            }}
                        />
                        :
                        <div onClick={() => this.gotoImage(idx)} className="cursor-pointer">
                            <SecuredVideo
                                data={item}
                                width={150}
                                height={100}
                                key={idx}
                                controls={false}
                                style={{
                                    backgroundColor: 'black',
                                    opacity: (currentItem !== idx) ? 0.5 : 1.0
                                }}
                            />
                        </div>
                )
            )
        }
    }


    //-----------------------------------------------------------------------------------------------------------
    render() {
        const { isOpen, media, currentItem } = this.state

        if (!isOpen || !media.length) return null

        return (
            <div className={classNames('lightbox-backdrop', 'animated', {fadeIn: isOpen})} id="lightboxBackdrop" style={{animationDuration: '0.3s'}}>
                <Button
                    color="light"
                    id="close"
                    className="close"
                    onClick={this.closeLightbox}>
                    <Icon
                        id="close"
                        name="times"
                        color="white"
                        style={{ fontSize: '2rem' }}
                    />
                </Button>
                <Row className="my-auto bg-dark p-3 rounded shadow image-container">
                    <Col>
                        <Row className="d-flex justify-content-center mb-1">
                            {(media[currentItem]) &&
                                <Badge color="dark">
                                    {media[currentItem].fileName} ({formatBytes(media[currentItem].contentLength)})
                                </Badge>
                            }
                        </Row>
                        <Row>
                            {this.renderImage()}
                        </Row>
                        {(media.length > 1) &&
                            <Row className="mt-2 d-flex justify-content-center thumbs">
                                {this.renderThumbnails()}
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Lightbox

import React, { useState, useEffect, useCallback } from 'react'
import { Col, Row } from '@evertel/web/ui'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import LoadingBarStripes from './LoadingBarStripes'
import { getFileExt } from '@evertel/utils'
import { formatBytes } from '../utilities/numberHelpers'
import { truncate } from '../utilities/stringHelpers'
import { AnalyticsStore } from '../stores'
import { Icon } from '.'
import { IconName } from '@fortawesome/fontawesome-svg-core'

export interface SecuredFileData {
    fileName: string;
    url?: string;
    fileUrl?: string;
    contentLength: number;
}

interface SecuredFileProps {
    data: SecuredFileData | null;
    trimNameAtChar?: number;
}

interface FileAttributes {
    iconName: string;
    iconColor: string;
    iconTypeString: string;
}

const SecuredFile: React.FC<SecuredFileProps> = observer(({
    data,
    trimNameAtChar = 100,
    ...otherProps
}) => {
    const [fileAttributes, setFileAttributes] = useState<FileAttributes>({
        iconName: 'file-alt',
        iconColor: 'secondary',
        iconTypeString: 'Download File'
    })
    const [loadingFailed, setLoadingFailed] = useState(false)
    const [loadingFile, setLoadingFile] = useState(false)

    const setFileAttributesFromType = useCallback((fileType: string) => {
        const attributes: FileAttributes = {
            iconName: 'file-alt',
            iconColor: 'secondary',
            iconTypeString: 'Download File'
        }

        switch (fileType) {
            case 'xlsx':
            case 'xls':
                attributes.iconName = 'file-excel'
                attributes.iconColor = 'success'
                attributes.iconTypeString = 'Excel Spreadsheet'
                break
            case 'pdf':
                attributes.iconName = 'file-pdf'
                attributes.iconColor = 'danger'
                attributes.iconTypeString = 'PDF'
                break
            case 'doc':
            case 'docx':
                attributes.iconName = 'file-word'
                attributes.iconColor = 'blue'
                attributes.iconTypeString = 'Word Document'
                break
            case 'zip':
            case 'rar':
            case 'tgz':
                attributes.iconName = 'file-archive'
                attributes.iconColor = 'dark'
                attributes.iconTypeString = 'File Archive'
                break
            case 'ppt':
            case 'pptx':
            case 'pptsx':
            case 'pps':
                attributes.iconName = 'file-powerpoint'
                attributes.iconColor = 'danger'
                attributes.iconTypeString = 'PowerPoint Presentation'
                break
            case 'csv':
                attributes.iconName = 'file-csv'
                attributes.iconTypeString = 'CSV File'
                break
        }

        setFileAttributes(attributes)
    }, [])

    useEffect(() => {
        if (data) {
            const fileExt = getFileExt(data)
            setFileAttributesFromType(fileExt)
        }
    }, [data, setFileAttributesFromType])

    const downloadFile = useCallback(() => {
        if (!data) return

        setLoadingFile(true)

        const download = (name: string, url: string) => {
            const a = document.createElement('a')
            document.body.appendChild(a)
            a.download = name
            a.href = url
            a.target = '_blank'
            a.click()
            document.body.removeChild(a)
        }

        download(data.fileName, data.url || data.fileUrl || '')
        setLoadingFile(false)

        AnalyticsStore.logEvent({
            category: 'User',
            action: 'download_file',
            label: fileAttributes.iconTypeString === 'Download File' ? 'Unknown Filetype' : fileAttributes.iconTypeString
        })
    }, [data, fileAttributes.iconTypeString])

    if (!data) {
        return <span className="text-muted"><em>Loading...</em></span>
    }

    return (
        <button
            className={classNames('file', 'cursor-pointer', 'p-1', `border-${fileAttributes.iconColor}`)} 
            onClick={downloadFile}
            tabIndex={0}
            {...otherProps}
        >
            <Row valign='start'>
                <Icon
                    className="d-flex mr-1"
                    name={fileAttributes.iconName as IconName}
                    color={fileAttributes.iconColor}
                    style={{ fontSize: '2.25rem' }}
                />
                <Col className='text-wrap text-break'>
                    {loadingFile ? (
                        <>
                            <small className="text-muted">Downloading...</small>
                            <LoadingBarStripes percentLoaded={100} />
                        </>
                    ) : (
                        <>
                            <strong>{truncate(data.fileName, trimNameAtChar)}</strong>
                            <small className={loadingFailed ? 'text-danger' : 'text-muted'}>
                                {loadingFailed ? 'Failed to load' : (
                                    <>{fileAttributes.iconTypeString} ({formatBytes(data.contentLength)})</>
                                )}
                            </small>
                        </>
                    )}
                </Col>
            </Row>
        </button>
    )
})

export default SecuredFile
//==============================================
// RETURNS A NEGATIVE ID FOR ANY LOCAL ELEMENT
//==============================================
let lastId = -1
export function localId() {
    let id = -Date.now()
    while (id === lastId) id = -Date.now()

    lastId = id
    return id
}

//==============================================
// DEEP COPY
//==============================================
export function deepCopy(objOrArray) {
    return JSON.parse(JSON.stringify(objOrArray))
}

//==============================================
// RETURN USER'S PRIMARY DEPARTMENT ACCESS without GUEST
//==============================================
export function getNonGuestPrimaryDepartmentsAccess (departmentsAccess) {
    if (!departmentsAccess) return null

    const departmentsAccessExcludeGuests = departmentsAccess.filter(da => getRole(da.roles) !== 'guest')

    if (!departmentsAccessExcludeGuests) return null

    // if they only belong to one dept, then return it as primary
    if (departmentsAccessExcludeGuests.length === 1) return departmentsAccessExcludeGuests[0]

    // otherwise return the department flagged as isPrimary or the first department
    return departmentsAccessExcludeGuests.find(d => d.isPrimary === true) || departmentsAccessExcludeGuests[0]
}

//==============================================
// RETURN USER'S PRIMARY DEPARTMENT ACCESS including a GUEST ROLE
//==============================================
export function getPrimaryDepartmentsAccess(departmentsAccess) {
    departmentsAccess = departmentsAccess || []
    const firstPrimary = departmentsAccess.find(d => d.isPrimary && d.isVerified)
    const firstNonGuestVerified = departmentsAccess.find(d => d.isVerified && getRole(d.roles) !== 'guest')
    const firstVerified = departmentsAccess.find(d => d.isVerified)

    return firstPrimary || firstNonGuestVerified || firstVerified || (departmentsAccess.length && departmentsAccess[0]) || null
}

//==============================================
// RETURN USER'S DEPARTMENT ACCESS OBJ FOR THE
// DEPT ID PASSED IN
//==============================================
export function getCurrentDepartmentsAccess (departmentsAccess, currentDepartmentId) {
    if (!departmentsAccess || !departmentsAccess.length || !currentDepartmentId) return null
    return departmentsAccess.find(d => d.departmentId === parseInt(currentDepartmentId, 10))
}

//==============================================
// RETURNS TRUE ROLE
//==============================================
export function getRole(rolesArray: Array<any>): 'executive' | 'management' | 'employee' | 'guest' {
    if (!rolesArray || !rolesArray.length) return 'guest'

    // works because checks are in order of priority
    if (rolesArray.includes('executive')) return 'executive'
    if (rolesArray.includes('management')) return 'management'
    if (rolesArray.includes('employee')) return 'employee'
    if (rolesArray.includes('guest')) return 'guest'
    return 'guest'
}

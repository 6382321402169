import React, { useState, useEffect, ReactNode, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
// evertel
import { formatForSelect } from '@evertel/utils'
import { APIDataRoom } from '@evertel/types'
import { useService } from '@evertel/di'
import { RoomSearchScopeProps, RoomsSearchController } from '@evertel/room'
import { DepartmentController } from '@evertel/department'
import { JoinRoomController } from '@evertel/room'

import { ContentPlaceholder } from '../elements'
import { MultiValue, Props, SingleValue } from 'react-select'
import { CustomAsyncPaginate } from './CustomAsyncPaginate'
import { SessionState } from '@evertel/session'

interface CustomProps {
    joinRoomController: JoinRoomController,
    showDept: boolean,
    onClose: () => void,
    onOpenExtraInfoModal: (roomId: number) => void
}

interface RoomSearchSelectProps extends Props {
    departmentId?: number,
    selected?: APIDataRoom[],
    autofocus?: boolean,
    placeholder?: string,
    maxMenuHeight?: number,
    className?: string,
    whereFilter?: any,
    onEndReachedThreshold?: number,
    customComponents?: ReactNode
    customProps?: CustomProps,
    /**
     * managed = rooms the current user manages.
     * member = rooms the current user is a member of.
     * public = public/crisis/incident-command rooms.
     * department = all rooms in the department
     */
    searchScope: RoomSearchScopeProps,
    onSelect?: (room: APIDataRoom[]) => void
}


const RoomSearchSelect: React.FC<RoomSearchSelectProps> = observer(({
    searchScope = 'department',
    departmentId,
    selected = [],
    placeholder = 'Search by room name...',
    onSelect,
    whereFilter,
    className,
    customComponents,
    customProps,
    onEndReachedThreshold = 80,
    ...otherProps
}) => {

    const roomsSearchController = useService(RoomsSearchController, [departmentId])
    const session = useService(SessionState, [])
    const [searchTerm, setSearchTerm] = useState('')

    const [isLoading, setIsLoading] = useState(true)
    const [isSearching, setIsSearching] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        if (!departmentId && searchScope !== 'public') return

        roomsSearchController.init({
            searchScope,
            currentUserId: session.currentUserId,
            departmentId,
            infiniteScroll: false
        })

        //needs to render once with loading true to reload async paginate
        setTimeout(() => { setIsLoading(false)}, 1)

    }, [departmentId, searchScope])

    const onSelectOption = (rooms: MultiValue<APIDataRoom> | SingleValue<APIDataRoom>) => {
        if (onSelect){
            setSearchTerm(null)
            onSelect(rooms as APIDataRoom[])
        }
    }

    const search = useCallback(async (
        searchString: string,
        loadedOptions: APIDataRoom[],
        { page }: { page: number }
    ) => {

        if (roomsSearchController.searchTerm !== searchString) {
            roomsSearchController.setSearchTerm(searchString)
        }
        //react select will cache pages and then ask for specific pages,
        //so if someone does multiple searches, then scrolls down on some,
        //then goes back to an old search, we'll be left with a request for
        //a page that's beyond what we've already requested but no longer have
        //saved since the search has changed in between.  So we need to set the page
        //afterwards
        roomsSearchController.setPage(page)

        try {
            setIsSearching(true)
            await roomsSearchController.search()
            setIsSearching(false)

        } catch (error) {
            setIsSearching(false)
            console.error('ERROR: RoomSearchSelect search():', error)
        }

        const searchLower = searchString.toLowerCase()
        // grab results and format them for the Select
        const results = formatForSelect(roomsSearchController.roomsLatestFetch, 'name', 'id') //.filter(({ name }) => name.toLowerCase().includes(searchLower))
        const hasMore = (roomsSearchController.roomsLatestFetch.length >= roomsSearchController.limit)

        return {
            options: results || [],
            hasMore,
            additional: {
                page: page + 1 || 1
            }
        }
    }, [departmentId, whereFilter, searchScope])

    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number): boolean => {
        if (isSearching) return false
        const maxPixelThreshold = 300
        const percentThreshold = 100 - onEndReachedThreshold

        const pxFromBottom = scrollHeight - clientHeight - scrollTop
        const percentThresholdFromBottomInPx = ((percentThreshold / 100) * (scrollHeight - clientHeight))
    
        // Determine the threshold to use (whichever is smaller)
        const thresholdToUse = Math.min(percentThresholdFromBottomInPx, maxPixelThreshold)

        // console.log('px from bottom / threshold, result', pxFromBottom, thresholdToUse, pxFromBottom <= thresholdToUse)    
        return pxFromBottom <= thresholdToUse
    }

    if (isLoading) {
        return (
            <ContentPlaceholder
                className={className}
                style={{
                    height: 38
                }}
            />
        )
    }

    return (
        <CustomAsyncPaginate
            name="rooms"
            keyName={`${searchScope}-${session.currentUserId}-${departmentId}`}
            loadOptions={search}
            debounceTimeout={500}
            additional={{
                page: 1
            }}
            defaultOptions
            closeMenuOnSelect={!otherProps?.isMulti}
            shouldLoadMore={shouldLoadMore}
            value={selected}
            onChange={(customComponents) ? undefined : onSelectOption}
            noOptionsMessage={() => 'No matching rooms'}
            placeholder={placeholder}
            className={className}
            style={{ innerHeight: '100%' }}
            {...(customComponents && { components: customComponents })}
            {...(customProps && { customProps: customProps })}
            {...otherProps}
        />
    )
})

export { RoomSearchSelect }

import React, { createContext, useContext, useCallback } from 'react'
import { EToasterProps } from '../../UIContext'
import { decorate, injectable } from 'inversify'

class ToastService {
    private static instance: ToastService
    private addToastCallback: ((config: EToasterProps) => void) | null = null

    public registerAddToast(callback: (config: EToasterProps) => void) {
        this.addToastCallback = callback
    }

    public addToast(config: EToasterProps) {
        if (this.addToastCallback) {
            this.addToastCallback(config)
        } else {
            console.warn('Toast service not initialized')
        }
    }
}

decorate(injectable(), ToastService)

export { ToastService }
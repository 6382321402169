import { useEffect } from 'react'
import { Button, Card, CardBody, Col, Container, Row, InfoBox } from '@evertel/web/ui'
import { autorun } from 'mobx'
import { Link } from 'react-router-dom'
// evertel
import { AnalyticsStore, NavigationStore, AppStore } from '../../../stores'



const CrisisSplash = () => {

    useEffect(() => {
        autorun((init) => {
            if (!AppStore.appLoading) {
                AnalyticsStore.logPageView('/crisissplash')

                init.dispose()
            }
        })
    })


    return (
        <div className="app fadeIn animated crisis-splash" style={{ height: '100vh', overflow: 'auto' }}>
            <Row align='center' valign='center' className="p-4" style={{ minHeight: '100%' }}>
                <Card className="" style={{ maxWidth: 800 }}>
                    <CardBody className="p-4">
                        <div className="pb-4">
                            <h1 className="pb-3 weight-900" style={{ fontSize: '3rem' }}>
                                Responding to a crisis event?
                            </h1>
                            <h4 className="pb-3">
                                If you're responding to a crisis event and your agency is not registered on
                                Evertel you can still collaborate with the agencies responding.<br />
                                You will be taken through a quick and free registration.
                            </h4>
                            <InfoBox color="warning">
                                <strong>A government email address is required.</strong>
                            </InfoBox>
                            <Row align="right" className="mt-4">
                                <Button
                                    color="link"
                                    className="mr-5"
                                    size="md"
                                    onClick={() => NavigationStore.navigate('/login')}>
                                    Back
                                </Button>
                                <Button
                                    color="secondary"
                                    className="weight-900"
                                    size="lg"
                                    onClick={() => NavigationStore.navigate('/register?crisis=true')}>
                                    Continue...
                                </Button>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </Row>
        </div>
    )
}

export default CrisisSplash


import { Api } from '@evertel/api'
import { BlueUserStore } from '../store'
import { decorate, inject, injectable } from '@evertel/di'
import moment from 'moment'
import { APIDataBlueUser } from '@evertel/types'
import { DepartmentsAccessStore } from '@evertel/departments-access'

type fetchedUserRecord = {
    id: number,
    lastFetch: moment.Moment
}

export const DEFAULT_USER_SCOPE = {
    fields: ['id', 'firstName', 'lastName', 'publicImage', 'publicMedia', 'isBot', 'isAdmin', 'emailVerified', 'email'],
    include: [{
        relation: 'departmentsAccess',
        scope: {
            fields: ['id', 'isVerified', 'roles', 'badgeNumber', 'departmentId', 'positionString', 'assignmentString', 'isPrimary'],
            include: [
                {
                    relation: 'department',
                    scope: {
                        fields: ['id', 'name', 'city', 'state']
                    }
                }, {
                    relation: 'assignment'
                }, {
                    relation: 'position'
                }
            ]
        }
    }]
}

// A service to optimize and minimize how often we fetch user profiles
class FetchUserService {
    private recentlyFetched: fetchedUserRecord[] = []

    constructor(
        private api: Api,
        private userStore: BlueUserStore,
        private departmentAccessStore: DepartmentsAccessStore
    ) {
        // makeAutoObservable(this)
    }

    // Returns a list of id's filtered by recently fetched id's
    private filterIdsByRecentlyFetched = (userIds: number[]) => {
        const fiveMinAgo = moment().subtract(5, 'minutes')
        this.recentlyFetched = this.recentlyFetched.filter(r => r.lastFetch > fiveMinAgo)
        const ignoreIds = this.recentlyFetched.map(r => r.id)
        const results = userIds.filter(id => !ignoreIds.includes(id))
        return results
    }

    private wasRecentlyFetched = (userId: number) => {
        const fiveMinAgo = moment().subtract(5, 'minutes')
        this.recentlyFetched = this.recentlyFetched.filter(r => r.lastFetch > fiveMinAgo)
        return this.recentlyFetched.some(r => r.id === userId)
    }

    addRecentlyFetchedUsers = (users: APIDataBlueUser[]) => {
        const lastFetch = moment()

        this.userStore.update(users)

        const departmentsAccesses = users.map(u=> u.departmentsAccess).flat()
        this.departmentAccessStore.update(departmentsAccesses)

        // TODO: Store user.departmentsAccess[].department in DepartmentStore OR remove the relation 'department' from defaultUserScope

        this.recentlyFetched.push(...users.map(u => ({id: u.id, lastFetch})))
    }

    addRecentlyFetchedUser = (user: APIDataBlueUser) => {
        const lastFetch = moment()
        this.userStore.update(user)
        this.recentlyFetched.push({ id: user.id, lastFetch })
    }

    removeRecentlyFetchedUser = (userId: number) => {
        // Remove the user from the userStore
        this.userStore.deleteById(userId)

        const index = this.recentlyFetched.findIndex(item => item.id === userId)
        if (index !== -1) {
            this.recentlyFetched.splice(index, 1)
        }
    }

    async fetchCurrentUser(userId: number): Promise<APIDataBlueUser | undefined> {
        if (!userId) return undefined
        if (this.wasRecentlyFetched(userId)) return this.userStore.findById(userId)

        // small hack, when this function gets called multiple times, we don't get a response early enough to avoid
        // the same API call to be repeated.
        this.addRecentlyFetchedUser({ id: userId })
        try {
            const usersFetched = await this.api.Routes.BlueUser.getById(userId)
            this.addRecentlyFetchedUser(usersFetched)
        } catch (e) {
            this.removeRecentlyFetchedUser(userId)
        }
        return this.userStore.findById(userId)
    }

    async fetchUserAsAdmin(userId: number): Promise<APIDataBlueUser | undefined> {
        return this.fetchCurrentUser(userId)
    }

    async fetchSpecificUsersFromRoom(roomId: number, ids: number[]): Promise<APIDataBlueUser[]> {
        const lessIds = this.filterIdsByRecentlyFetched(ids)

        if (!roomId) return []
        if (!lessIds.length) return this.userStore.findByIds(ids)

        // TODO: we need to add support for pagination, this rout returns a maximum of 100 users
        if (lessIds.length > 100) throw Error('TODO add support for a limit of 100')

        const usersFetched = await this.api.Routes.Room.getUsers(roomId, { where: { id: { inq: lessIds} }, ...DEFAULT_USER_SCOPE })

        this.addRecentlyFetchedUsers(usersFetched)

        return this.userStore.findByIds(ids)
    }


    async fetchSpecificUsersFromThread(threadId: number, ids: number[]): Promise<APIDataBlueUser[]> {
        const lessIds = this.filterIdsByRecentlyFetched(ids)

        if (!threadId) return []
        if (!lessIds.length) return this.userStore.findByIds(ids)

        // TODO: we need to add support for pagination, this rout returns a maximum of 100 users
        if (lessIds.length > 100) throw Error('TODO add support for a limit of 100')

        const usersFetched = await this.api.Routes.Thread.getUsers(threadId, { where: { id: { inq: lessIds} }, ...DEFAULT_USER_SCOPE })
        this.addRecentlyFetchedUsers(usersFetched)

        return this.userStore.findByIds(ids)
    }

    async fetchSpecificUsersFromDepartment(departmentId: number, ids: number[]): Promise<APIDataBlueUser[]> {
        const lessIds = this.filterIdsByRecentlyFetched(ids)

        if (!departmentId) return []
        if (!lessIds.length) return this.userStore.findByIds(ids)

        // TODO: we need to add support for pagination, this rout returns a maximum of 100 users
        if (lessIds.length > 100) throw Error('TODO add support for a limit of 100')

        const usersFetched = await this.api.Routes.Department.getUsers(departmentId, { where: { id: { inq: lessIds} }, ...DEFAULT_USER_SCOPE })
        this.addRecentlyFetchedUsers(usersFetched)

        return this.userStore.findByIds(ids)
    }

    async fetchSpecificUserFromDepartment(departmentId: number, id: number): Promise<APIDataBlueUser | undefined> {
        const lessIds = this.filterIdsByRecentlyFetched([id])

        if (!departmentId) return undefined
        if (!lessIds.length) return this.userStore.findById(id)

        //TODO: This endpoint ignores the filter, fix it
        const userFetched = await this.api.Routes.Department.getUserById(departmentId, id)
        this.addRecentlyFetchedUsers([userFetched])

        return this.userStore.findById(id)
    }

    async fetchUsersForForwardedOwnerIds(departmentId: number, forwardedOwnerIds: number[]): Promise<APIDataBlueUser[]> {
        await Promise.all(forwardedOwnerIds.map(async id => {
            // Fetch user for each id
            await this.fetchSpecificUserFromDepartment(departmentId, id)
        }))
        return this.userStore.findByIds(forwardedOwnerIds)
    }

    async fetchSpecificUserFromDocument(documentId: number, id: number): Promise<APIDataBlueUser | undefined> {
        if (!this.wasRecentlyFetched(id)) {
            const user = await this.api.Routes.Document.getUserById(documentId, id)
            this.addRecentlyFetchedUser(user)
        }
        return this.userStore.findById(id)
    }
}

decorate(injectable(), FetchUserService)
decorate(inject(Api), FetchUserService, 0)
decorate(inject(BlueUserStore), FetchUserService, 1)
decorate(inject(DepartmentsAccessStore), FetchUserService, 2)

export { FetchUserService }
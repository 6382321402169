import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { makeAutoObservable } from 'mobx'

class DepartmentUserController {

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    updateAttributes = async (departmentId: number, userId: number, details: any) => {
        return await this.api.Routes.Department.putUsersDepartmentDetails(departmentId, userId, details)
    }

}

decorate(injectable(), DepartmentUserController)
decorate(inject(Api), DepartmentUserController, 0)

export { DepartmentUserController }
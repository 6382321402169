/* eslint-disable camelcase */
import { makeAutoObservable, runInAction } from 'mobx'
import { Api } from '@evertel/api'
import { decorate, inject, injectable } from 'inversify'

export type Authenticator = {
    confirmed: boolean
    created_at: string
    id: string
    last_auth_at: string
    name: string
    type: string
}

class AuthenticationController {

    authenticators: Authenticator[] = []
    isLoading = false
    mfaToken = ''
    oobCode = ''

    constructor(private api: Api) {
        makeAutoObservable(this)
    }

    loginToAuth0 = async (body: any) => {
        const credentials = {
            username: body.email,
            password: body.password,
            deviceToken: body.deviceToken
        }

        return await this.api.Routes.Auth0.postOauthToken(credentials)
    }

    postLogin = async (body: any) => {
        return await this.api.Routes.BlueUser.postLoginAuth0(body)
    }

    changePassword = async (userId: number, body: any) => {
        await this.api.Routes.BlueUser.postPasswordChange(userId, body)
    }

    resendVerificationEmail = async (email: string): Promise<void> => {
        await this.api.Routes.Auth0.postResendVerificationEmail(email)
    }

    forgotPassword = async (email: string) => {
        await this.api.Routes.BlueUser.postPasswordResetRequest({ email })
    }

    resetForgotPassword = async (body: {password:string, confirmation: string}, token: string) => {
        const { password, confirmation } = body
        await this.api.Routes.BlueUser.postPasswordReset({ password, confirmation }, token)
    }

    changeEmail = async (id: number, body: any) => {
        return await this.api.Routes.BlueUser.putAuth0Email(id, body)
    }

    enrollMFA = async (options: any) => {
        return await this.api.Routes.Auth0.postMfaEnroll(options)
    }

    enrollMFAInit = async (body: { email: string }) => {
        return await this.api.Routes.BlueUser.postAuth0EnrollInit(body)
    }

    enrollMFAToken = async (body: any, token: string) => {
        return await this.api.Routes.BlueUser.postAuth0EnrollMfa(body, token)
    }

    getAuthenticators = async (userId: number) => {
        runInAction(() => {
            this.isLoading = true
        })

        const authenticators = await this.api.Routes.Auth0.getAuthenticators(userId)

        runInAction(() => {
            this.authenticators = authenticators as any as Authenticator[]
            this.isLoading = false
        })
        return
    }

    getAuthenticatorsByEmail = async (email: string) => {
        runInAction(() => {
            this.isLoading = true
        })

        const authenticators = await this.api.Routes.Auth0.getAuthenticatorsByEmail(email)

        runInAction(() => {
            this.authenticators = authenticators as any as Authenticator[]
            this.isLoading = false
        })
        return
    }

    deleteAuthenticator = async (userId: number, authenticatorId: string, email: string, password: string, deviceToken: string) => {
        runInAction(() => {
            this.isLoading = true
        })

        await this.api.Routes.Auth0.delAuthenticatorById(authenticatorId, {
            username: email,
            password,
            deviceToken
        })

        // refresh authenticators list
        this.getAuthenticators(userId)

        runInAction(() => {
            this.isLoading = false
        })
        return
    }

    updateAuthenticator = async (userId: number, params: any) => {
        return await this.api.Routes.BlueUser.putAuth0Authenticator(userId, params)
    }

    challengeUser = async (mfaToken: string, options: any) => {
        return await this.api.Routes.Auth0.postMfaChallenge(mfaToken, options)
    }

    verifyChallenge = async (params: any) => {
        return await this.api.Routes.Auth0.postVerifyChallenge(params)
    }

    setMFAParams = (mfaToken: string, oobCode: string) => {
        runInAction(() => {
            this.mfaToken = mfaToken,
            this.oobCode = oobCode
        })
        return
    }

    enterpriseLogin = async (email: string) => {
        return await this.api.Routes.Auth0.postEnterpriseLogin(email)
    }

    findAuthenticatorById = (authenticatorId: string) => {
        return this.authenticators?.find(a => a.id === authenticatorId)
    }

    get hasEmailAuthenticator() {
        return !!this.authenticators?.find(a => a.type ==='email' && a.confirmed === true)
    }

    get hasSMSAuthenticator() {
        return !!this.authenticators?.find(a => a.type ==='sms' && a.confirmed === true)
    }
}

//Dependency injection with Inversify
decorate(injectable(), AuthenticationController)
decorate(inject(Api), AuthenticationController, 0)


export { AuthenticationController }
import { Component, Fragment } from 'react'
import { Modal, ModalBody, Row, Col, Textarea, InfoBox } from '@evertel/web/ui'
import { observer } from 'mobx-react'
// evertel
import { Button, Toaster } from '.'
import { AppStore, AnalyticsStore } from '../stores'
import api from '../api'


//============================================================================================================
const HelpModal = observer(class HelpModal extends Component {
//============================================================================================================
    static helpInstance

    static show(config) {
        HelpModal.helpInstance.showModal(config)
    }

    static hide() {
        HelpModal.helpInstance.hideModal()
    }

    state = {
        isOpen: false,
        action: 'ask-question',
        title: 'Submit Feedback',
        message: '',
        sending: false,
        error: null,
        feedbackSent: false
    }

    showModal(config = {}) {
        this.setState({
            isOpen: true,
            action: config.action,
            title: (config.action === 'ask-question') ? 'Ask a Question' : (config.action === 'report-bug') ? 'Report a Problem' : (config.action === 'make-suggestion') ? 'Make a Suggestion' : 'Submit Feedback'
        })

        if (this.textarea) this.textarea.focus()
    }

    hideModal = () => {
        this.setState({
            isOpen: false,
            sending: false,
            feedbackSent: false,
            error: null,
            message: ''
        })
    }

    onChange = (e) => {
        this.setState({
            message: e.target.value
        })
    }

    submit = async () => {
        const { message, sending, action } = this.state

        if (sending) return

        //make sure they entered a message
        if (!message || !message.trim().length) {
            this.setState({ error: 'Please add your feedback'})
            return
        }

        try {
            this.setState({
                sending: true,
                error: null
            })

            //set content
            const title = (action === 'report-bug') ? 'ISSUE:' : (action === 'make-suggestion') ? 'FEATURE REQUEST:' : (action === 'ask-question')  ? 'QUESTION:' : 'FEEDBACK:'
            const userInfo = await AppStore.getDevelopmentInfo()
            const msg =
                title + ' \n' +
                '"' + message + '"\n' +
                '\n----------------------------------------\n' +
                userInfo

            // direct api request
            await api.App.sendFeedback(msg)

            // success
            this.setState({
                message: '',
                feedbackSent: true
            })

            AnalyticsStore.logEvent({
                category: 'User',
                action: 'send_feedback',
                label: action
            })

        } catch (error) {
            if (!api.isProduction) console.log('ERROR SUBMITTING FEEDBACK: ', error)
            Toaster.show('error', error.message || error)
        }

        // done
        this.setState({
            sending: false
        })
    }

    renderFeedback = () => {
        const { message, sending, error, feedbackSent } = this.state

        if (feedbackSent){
            return (
                <Row>
                    <Col className="text-center pt-2">
                        <>
                            <h3 className="text-success mt-2 fadeIn animated">
                                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                </svg>
                                Your feedback has been sent!
                            </h3>
                            <Button color="primary" size="lg" onClick={this.hideModal}>Ok!</Button>
                        </>

                    </Col>
                </Row>
            )
        }
        
        return (
            <>
                <Row>
                    <Col>
                        {error && <InfoBox color="danger">{error}</InfoBox>}
                        <Textarea
                            ref={t => this.textarea = t}
                            value={message}
                            rows={5}
                            onChange={this.onChange}
                            placeholder="Type your feedback here..."
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center pt-2">
                        <Button
                            color="success"
                            className="weight-900"
                            loading={sending}
                            onClick={this.submit}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }

    //----------------------------------------------------------------------------------------------------------------------------------
    render() {
    //----------------------------------------------------------------------------------------------------------------------------------
        const { isOpen, title } = this.state

        return (
            <Modal
                visible={isOpen}
                onClose={this.hideModal}
                size="lg">
                <ModalBody className="py-4 px-5 mx-5 mb-4">
                    {/* TITLE */}
                    <Row className="mb-5">
                        <Col className="text-center">
                            <h1>{title}</h1>
                            <h3>Fill out the form below and we'll get back to you with any follow up shortly!</h3>
                        </Col>
                    </Row>
                    {this.renderFeedback()}
                </ModalBody>
            </Modal>
        )
    }
})

export default HelpModal

import React from 'react'
import { ElementType, forwardRef, HTMLAttributes, ReactNode, useContext } from 'react'
import classNames from 'classnames'
import { ModalContext } from './Modal'
import { CloseButton } from '../CloseButton'
import { Text } from '../Text'

interface Props extends HTMLAttributes<HTMLDivElement> {
    children?: ReactNode
    className?: string
    title?: string
    subtitle?: string
    tag?: ElementType,
    /** Add a close button to the header. */
    closeButton?: boolean
}

const ModalHeader = forwardRef<HTMLDivElement, Props>(({
    title,
    subtitle,
    tag = 'h3',
    children,
    className,
    closeButton = true,
    ...otherProps
}, ref) => {

    const { handleClose } = useContext(ModalContext)
    const _className = classNames('modal-header', className)

    const closeClicked = () => {
        handleClose()
    }

    return (
        <>
            <div
                ref={ref}
                className={_className}
                {...otherProps}>
                {title &&
                    <Text
                        tag={tag}
                        heavy
                        className="modal-title">
                        {title}
                    </Text>
                }
                {children}
                {(closeButton) &&
                <CloseButton
                    onClick={() => closeClicked()}
                    size="lg"
                />
                }
            </div>
            {subtitle && <div
                className='modal-subtitle'
            >
                <Text
                    tag="h4"
                    color="muted"
                    className="modal-subtitle pb-4"
                >
                    {subtitle || ''}
                </Text>
            </div>}
        </>
    )
})

export default ModalHeader
ModalHeader.displayName = 'ModalHeader'

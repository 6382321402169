import React, {
    useRef,
    Suspense,
    useEffect,
    useState
} from 'react'
import { createPortal } from 'react-dom'
import { observer } from 'mobx-react-lite'
import { useHistory, useParams } from 'react-router-dom'

// evertel
import { MessageWall } from '@evertel/web/message'
import { DocumentModalContextProvider } from '@evertel/web/document'
import {
    RoomHeader,
    RightGutter,
    RoomSettings,
    RoomRequests,
    RoomMediaSearch,
    RoomDirectory
} from './elements'
import {
    PhotoGallery,
    Spinner,
    SlidePanel,
    SlidePanelHeader,
    Row,
    Text,
    CloseButton,
    SlidePanelBody,
    Badge,
    InfoBox,
    Col
} from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { RoomController } from '@evertel/room'
import { SessionState } from '@evertel/session'
import { formatNumber } from '@evertel/utils'
import { DepartmentEmojisController } from '@evertel/emojis'
import { CurrentUserController, UserSettingsController } from '@evertel/blue-user'
import { DrawerController } from '@evertel/message'
import { RoomContext } from './RoomContext'
import { RoomJoinRequestsController } from '@evertel/room'

// dynamic imports for code splitting
const MessageSearchModal = React.lazy(() => import('@evertel/web/message').then(module => ({ default: module.MessageSearchModal })))

const Room = observer(() => {

    const { id: currentUrlIdString } = useParams<{id: string}>()
    const currentRoomId = currentUrlIdString ? parseInt(currentUrlIdString, 10) : undefined

    const session = useService(SessionState, [])

    const userSettingsController = useService(UserSettingsController, [])
    const currentUserController = useService(CurrentUserController, [])

    const drawerController = useService(DrawerController, [session.selectedDepartmentId])
    const roomController = useService(RoomController, [currentRoomId])
    const departmentEmojisController = useService(DepartmentEmojisController, [session.selectedDepartmentId])
    const roomJoinRequestsController = useService(RoomJoinRequestsController, [currentRoomId])

    const history = useHistory()
    const messages = useRef()

    const [isLoading, setIsLoading] = useState(false)
    const [showAsideTab, setShowAsideTab] = useState(null)
    const [openAside, setOpenAside] = useState(false)
    const [asideOpenComplete, setAsideOpenComplete] = useState(false)
    const [isMessageSearchModalOpen, setIsMessageSearchModalOpen] = useState(false)

    const asideTabs = {
        directory: {
            title: 'Room Members',
            component: RoomDirectory
        },
        settings: {
            title: 'Room Settings',
            component: RoomSettings
        },
        'media-search': {
            title: 'File Search',
            component: RoomMediaSearch
        },
        closed: {
            title: null,
            component: () => null
        }
    }

    useEffect(() => {
        document.body.classList.add('aside-menu-hide')
        document.addEventListener('keydown', handleEscape)

        return () => {
            document.body.classList.remove('aside-menu-hide')
            document.removeEventListener('keydown', handleEscape)
        }
    }, [openAside])

    const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            toggleAside(showAsideTab, 'close')
        }
    }


    useEffect(() => {
        (async () => {
            
            if (!currentRoomId) {
                history.push('/landing')
                return
            }

            setIsLoading(true)

            try {
                // initialize controllers
                await roomController.init(currentRoomId)

                const departmentId = roomController.room?.departmentId
                const isInterAgencyRoom = drawerController.interAgencyRooms.some(room => departmentId === room.departmentId)

                //update the selectedDepartmentId if a room is entered that doesn't match the current department
                if (departmentId && departmentId !== session.selectedDepartmentId && !isInterAgencyRoom) {
                    session.setSelectedDeparmentId(departmentId)
                    // useEffect will be triggered again after this, so no point in running init requests
                    //     and we'd want to keep isLoading true for now
                    return
                }

                departmentEmojisController.init(session.selectedDepartmentId)
                userSettingsController.init(session.currentUserId)

            } catch (error) {
                console.error('Room init', error.message)
            }

            setIsLoading(false)
        })()

    }, [currentRoomId, session?.selectedDepartmentId])

    useEffect(()=>{
        const canManageRequests = roomController?.canCurrentUserManageRoom && currentUserController.getRoleInDepartment(roomController.room?.departmentId) !== 'guest'
        if (canManageRequests) {
            roomJoinRequestsController.init(currentRoomId)
        }
    }, [roomController.roomId, roomController.managerIds])

    const toggleAside = (tab = 'directory', intent: 'open'|'close') => {
        if (intent === 'close') {
            setOpenAside(false)
            setShowAsideTab(null)
        } else if (intent === 'open') {
            setShowAsideTab(tab)
            setOpenAside(true)
        }
    }

    const contextValues = {
        canManageRoom: roomController?.canCurrentUserManageRoom,
        roomController,
        departmentEmojisController,
        userSettingsController,
        roomJoinRequestsController,
        setIsMessageSearchModalOpen,
        toggleAside,
        asideOpenComplete
    }

    const { room } = roomController
    const AsideComponent = asideTabs[showAsideTab]?.component || asideTabs.closed.component

    if (!room) {
        return (
            <InfoBox
                color="warning"
                className="m-3">
                Sorry, this room was not found or you do not have access to it
            </InfoBox>
        )
    }

    return (
        <RoomContext.Provider value={contextValues}>
            <PhotoGallery>
                <DocumentModalContextProvider>
                    <Row valign='stretch'>
                        <Col className="w-100">
                            <RoomHeader/>
                            <MessageWall
                                ref={messages}
                                modelId={roomController.id}
                                modelType="room"
                                modelData={room}
                                allowedToPost={roomController.allowedToPost}
                            />
                        </Col>
                        <RightGutter
                            activeTab={(openAside) && showAsideTab}
                            toggleAside={toggleAside}
                        />
                        {createPortal(
                            <SlidePanel
                            //embed
                                backdrop={false}
                                placement="end"
                                visible={openAside}
                                onHide={() => setAsideOpenComplete(false)}
                                onShowComplete={() => setAsideOpenComplete(true)}
                                className="room-aside"
                            >
                                <SlidePanelHeader>
                                    <Text
                                        heavy
                                        className="text-capitalize"
                                    >
                                        {asideTabs[showAsideTab]?.title}
                                        {(showAsideTab === 'directory') &&
                                            <Badge color="muted" className="ml-2">
                                                {formatNumber(roomController.usersCount)}
                                            </Badge>
                                        }
                                    </Text>
                                    <CloseButton
                                        onClick={() => toggleAside(showAsideTab, 'close')}
                                    />
                                </SlidePanelHeader>
                                <SlidePanelBody>
                                    <AsideComponent/>
                                </SlidePanelBody>
                            </SlidePanel>,
                            document.getElementById('slide-panel-root')
                        )}
                    </Row>

                    <Suspense fallback={<Spinner />}>
                        <MessageSearchModal
                            visible={isMessageSearchModalOpen}
                            onClose={() => setIsMessageSearchModalOpen(false)}
                            modelType="room"
                            modelId={roomController.room?.id}
                            modelName={roomController.room?.name}
                        />
                    </Suspense>

                </DocumentModalContextProvider>
            </PhotoGallery>
        </RoomContext.Provider>
    )
})

export { Room }

import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { GroupBase, OptionProps, SelectInstance } from 'react-select'
import { AsyncPaginate, AsyncPaginateProps } from 'react-select-async-paginate'



export type CustomOptionProps<Option, CustomProps,> = OptionProps<Option, boolean, GroupBase<Option>> & {
    selectProps: {
        customProps: CustomProps;
    } & OptionProps<Option, boolean, GroupBase<Option>>['selectProps'];
}

type BaseProps = AsyncPaginateProps<unknown, GroupBase<unknown>, unknown, boolean>
interface CustomAsyncPaginateProps extends Omit<BaseProps, 'isMulti'|'maxMenuHeight'> {
    searchOnly?: boolean
    isMulti?: boolean
    keyName?: React.Key
    customProps?: any
    customControlProps?: any
    callbacks?: any
    style?: any
    maxMenuHeight?: number | string
}

const CustomAsyncPaginate: React.FC<CustomAsyncPaginateProps> = observer(({
    keyName,
    searchOnly = false,
    className,
    classNamePrefix,
    maxMenuHeight,
    ...otherProps
}) => {
    const [inputValue, setInputValue] = useState('')

    const selectRef = useRef<SelectInstance<unknown>>(null)
    const inputRef = useRef(null)

    useEffect(() => {
        // Find and store a reference to the input element
        if (selectRef.current) {
            const input = selectRef.current.inputRef
            if (input) {
                inputRef.current = input
            }
        }
    }, [])

    /**
     * This is useful for when the react-select element is only being used for searching
     * Otherwise since no item is selected, when clicking away from the search, the value
     * typed in will go away.
     */
    const onInputChange = (textInput, { action }) => {
        if (action === 'input-change') {
            setInputValue(textInput)
            return textInput
        }
        return inputValue
    }

    const handleKeyDown = (event) => {
        const focusedElement = document.activeElement as HTMLElement
        
        if (event.key === 'Escape') {
            if (inputRef.current && focusedElement !== inputRef.current) {
                event.stopPropagation()
                inputRef.current.focus()
            }
        } else if (event.key === 'Enter' || event.key === ' ') {
            if (focusedElement && focusedElement !== inputRef.current) {
                // Manually trigger click for non-input focused elements
                focusedElement.click()
            }
        }
    }

    return (
        <AsyncPaginate
            key={keyName}
            selectRef={selectRef}

            {...searchOnly && {
                inputValue: inputValue,
                onInputChange: onInputChange,
                tabSelectsValue: false,
                onKeyDown: handleKeyDown
            }}

            className={classNames('react-select', className)}
            classNamePrefix="select"

            // @ts-expect-error A string still works eg: calc(100vh - 100px)
            maxMenuHeight={maxMenuHeight}

            {...otherProps}
        />
    )
})

export { CustomAsyncPaginate }
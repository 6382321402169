/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useService } from '@evertel/di'
import { ContentPlaceholder, Dropdown, DropdownSelectProps } from '@evertel/web/ui'
import { DepartmentStore } from '@evertel/department'
import { DocumentSchemaStore } from '@evertel/stores'
import { DocumentSchemaPickerController } from '../../controller'

interface DocumentSchemaDropdownProps extends Omit<DropdownSelectProps, 'options'> {
    by: {type: string, id?: number} | undefined,
    schemaId: number,
    placeholder?: string,
    onSelect: (value: number|null, label: string|null) => void
}

const DocumentSchemaSearchSelect: React.FC<DocumentSchemaDropdownProps> = observer(({
    by,
    schemaId,
    placeholder = 'Select...',
    onSelect,
    ...otherProps
}) => {
    
    const documentSchemaPickController = useService(DocumentSchemaPickerController, [])
    const documentSchemaStore = useService(DocumentSchemaStore, [])
    const departmentStore = useService(DepartmentStore, [])

    useEffect(() => {

        documentSchemaPickController.loaded = false;

        (async () => {
            if (by?.type === 'department' && by?.id) {
                await documentSchemaPickController.fetchSchemas(by.id)
            } else {
                await documentSchemaPickController.fetchSchemas()
            }
        })()

    }, [by?.id])

    if (!documentSchemaPickController.loaded) {
        return (
            <ContentPlaceholder height={37}/>
        )
    }

    return (
        <Dropdown
            value={schemaId}
            // @ts-ignore
            options={
                documentSchemaPickController.schemas.map((schema) => {
                    const department = departmentStore.findById(schema.departmentId as number)
                    const extra = (by?.type === 'department') ? `` : department?.shortName

                    return {
                        name: schema.name,
                        extra: extra,
                        //need this here for searchability
                        label: schema?.name + (extra ? ` (${extra})` : ''),
                        value: schema.id
                    }                   
                })
            }
            formatOptionLabel={function(data: any) {
                let label = data.name ?? data.label
                if (data.extra) {
                    label = label + ` <span class="text-muted">(${data.extra})</span>`
                }
                return (
                    <span dangerouslySetInnerHTML={{ __html: label }} />
                )
            }}
            onChange={(option) => {
                if (option === null) {
                    onSelect(undefined, undefined)
                    return
                }
                // @ts-ignore
                onSelect(option.value, option.label)
            }}
            placeholder={placeholder}
            {...otherProps}
        />
    )
})

export {
    DocumentSchemaSearchSelect
}

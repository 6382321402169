import React from 'react'
import { BootstrapTable as BootstrapTableComponent, TableHeaderColumn as TableHeaderColumnComponent } from 'react-bootstrap-table'

const BootstrapTable = (props) => {
    // @ts-expect-error old junk
    return <BootstrapTableComponent {...props} />
}

const TableHeaderColumn = (props) => {
    // @ts-expect-error old junk
    return <TableHeaderColumnComponent {...props} />
}

export { BootstrapTable, TableHeaderColumn }

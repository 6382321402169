import PropTypes from 'prop-types'
import classNames from 'classnames'

const propTypes = {
    show: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object
}

const defaultProps = {
    show: false,
    className: ''
}

const MediaUploadWindowOverlay = ({ show, className, style, ...otherProps }) => {
    const classes = classNames('animated', {'visible opacity-5': show, 'invisible opacity-0': !show, [className]: className})

    return (
        <div id="dropzone" className={classes} style={style} {...otherProps}>
            <img src="/assets/img/photos-stack.png" alt="Upload Media" width="250" style={{pointerEvents: 'none'}}/>
            <h1 className="text-white" style={{pointerEvents: 'none'}}>Attach and Post Files</h1>
            <small className="text-white" style={{pointerEvents: 'none'}}>Drag & drop images, videos and documents</small>
        </div>
    )
}

MediaUploadWindowOverlay.propTypes = propTypes
MediaUploadWindowOverlay.defaultProps = defaultProps
export default MediaUploadWindowOverlay

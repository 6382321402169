
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
// evertel
import { useService } from '@evertel/di'
import { Col, Row, Text } from '@evertel/web/ui'
import { DrawerNavDropdownLink } from '@evertel/web/drawer'
import { CategorySchemasController } from '@evertel/document'
import { APIDataDocumentSchema, APIDataDocumentSchemaCategory } from '@evertel/types'
import { DocumentIcon } from '@evertel/web/document'
import { OptionsMenu } from './OptionsMenu'

interface CategoryProps {
    schemas?: APIDataDocumentSchema[],
    category?: APIDataDocumentSchemaCategory,
    isVisible: boolean,
    onClick: (schemaId: number) => void
    onEditSchema: (schemaId: number) => void
    onCloneSchema: (schema: APIDataDocumentSchema) => void
    canManage: boolean
}

export const Category: React.FC<CategoryProps> = observer(({
    schemas,
    category,
    isVisible,
    onClick,
    onEditSchema,
    onCloneSchema,
    canManage
}) => {

    const schemaController = useService(CategorySchemasController, [])

    const displayedSchemas = category ? schemaController.schemas : schemas

    useEffect(() => {
        if (category) {
            schemaController.init(category.id as number)
        }
    }, [category?.id, schemaController])

    return (
        <DrawerNavDropdownLink
            label={category ? category.name as string : 'All'}
            isOpen={isVisible}>
            {displayedSchemas?.map((schema: APIDataDocumentSchema, index) =>
                <div
                    key={index}
                    className='position-relative'
                >
                    <button
                    //TODO: make this an anchor since it only goes to another page so there's url visibility on browser footer
                        key={schema.id}
                        className="d-flex align-items-center drawer-item-link"
                        onClick={() => onClick(schema.id as number)}
                    >
                        <Row valign="center" style={{width: 240}}>
                            <DocumentIcon
                                schema={schema}
                                className="mr-2"
                            />
                            <Text
                                color="gray300"
                                className="text-truncate">
                                {schema.name}
                            </Text>
                        </Row>
                    </button>
                    <div className="d-flex align-items-center position-absolute h-100 mx-2 top-0 end-0">
                        {(canManage) &&
                                <OptionsMenu
                                    onCreate={onClick}
                                    onEdit={onEditSchema}
                                    onClone={onCloneSchema}
                                    schema={schema}
                                    canManage={canManage}
                                />
                        }
                    </div>
                </div>
            )}
            {(!displayedSchemas?.length) &&
                    <Row className="picker-item">
                        <Text
                            color="muted"
                            style={{fontStyle: 'italic'}}>
                            No documents in this category
                        </Text>
                    </Row>
            }
        </DrawerNavDropdownLink>
    )
})
import { observer } from 'mobx-react-lite'
import { components as reactSelectComponents } from 'react-select'
import { CustomOptionProps } from '@evertel/web/ui'

// evertel
import {
    Icon,
    Text,
    FloatingMenu, MenuDivider, MenuItem,
    useUI
} from '@evertel/web/ui'
import { UserTileRawData } from '@evertel/web/user'
import { getRole } from '@evertel/utils'
import { APIDataBlueUser, APIDataRoom } from '@evertel/types'
import { useContext, useEffect, useState } from 'react'
import { useService } from '@evertel/di'
import { CurrentUserController } from '@evertel/blue-user'
import { RoomContext } from '../RoomContext'

interface SelectCustomProps {
    room: APIDataRoom
    openUserDetail: (userId: number) => void
    isCurrentUserGuest: boolean
}

const SelectUserDisplay = observer((props: CustomOptionProps<APIDataBlueUser, SelectCustomProps>) => {
    const { data: roomUser, selectProps, innerRef } = props
    const {
        room,
        openUserDetail,
        isCurrentUserGuest
    } = selectProps.customProps

    const { isConfirmed, addToast } = useUI()

    const { roomController, canManageRoom } = useContext(RoomContext)

    const currentUserController = useService(CurrentUserController, [])

    const isUserManager = roomController?.managerIds?.includes(roomUser.id)
    const isUserMember = roomController?.memberIds?.includes(roomUser.id)


    const [hasInternalFocus, setHasInternalFocus] = useState(false)
    const [optionElement, setOptionElement] = useState<HTMLDivElement | null>(null)

    const handleRef = (element: HTMLDivElement | null) => {
        setOptionElement(element)
        if (typeof innerRef === 'function') {
            innerRef(element)
        }
    }
    useEffect(() => {
        const checkFocus = () => {
            if (optionElement && document.activeElement) {
                setHasInternalFocus(optionElement.contains(document.activeElement))
            }
        }

        // Check focus on mount and when focus changes anywhere in the document
        checkFocus()
        document.addEventListener('focusin', checkFocus)
        document.addEventListener('focusout', checkFocus)

        return () => {
            document.removeEventListener('focusin', checkFocus)
            document.removeEventListener('focusout', checkFocus)
        }
    }, [optionElement])

    const handleMouseEnter = () => {
        const focusedElement = document.activeElement as HTMLElement

        if (focusedElement.tagName.toLowerCase() !== 'input') {
            //is something else in the select is focused, remove that focus
            focusedElement.blur()
            if (optionElement) {
                optionElement.focus()
            }
        }
        setHasInternalFocus(true)
    }

    const handleMouseLeave = () => {
        // document.activeElement.blur()
        setHasInternalFocus(false)
    }

    // this allows us to update the list without triggering
    // a new search when users are removed
    if (!isUserManager && !isUserMember) return null


    const usersRoomDepartmentsAccess = roomUser.departmentsAccess?.find(da => da.departmentId === room?.departmentId) || {}
    const isRoomUserGuest = !!(getRole(usersRoomDepartmentsAccess?.roles) === 'guest')
    const isArchived = room?.isArchived
    const isAgencyWideRoom = room?.options?.autoAddDepartmentMembers

    //if there are no managers, then a agency manager can add a new manager
    const canManageOrphanedRoom = roomController?.managerIds?.length === 0 && currentUserController.canManage

    const canDelete = canManageRoom &&
        !isArchived &&
        (!isAgencyWideRoom || (isAgencyWideRoom && isRoomUserGuest))

    const canToggleManager = (canManageRoom || canManageOrphanedRoom) && !room?.isArchived && !isCurrentUserGuest

    const primaryDeptsAccess = roomUser.departmentsAccess?.find(d => d.isPrimary)

    const removeUser = async () => {
        try {
            const confirmed = await isConfirmed({
                message: 'Are you sure you want to remove this user?',
                acceptButton: {
                    label: 'Remove',
                    color: 'danger'
                }
            })
            if (!confirmed) return
    
            await roomController.removeUser(roomUser.id)

        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }
    }

    const updateRole = async () => {
        try {
            if (isUserManager) {
                await roomController?.addMember(roomUser.id)
            } else {
                roomController?.addManager(roomUser.id)
            }
        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }
    }

    const isFocused = hasInternalFocus

    const newProps = {
        ...props,
        isFocused,
        innerRef: handleRef
    }
    newProps.innerProps = {
        ...newProps.innerProps,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
    }

    return (
        <reactSelectComponents.Option {...newProps}>
            <UserTileRawData
                user={roomUser}
                imageWidth={40}
                className="mb-1 directory-list-item"
                isManager={isUserManager}
                isGuest={isRoomUserGuest}
                subComponent={
                    <Text
                        size="small"
                        color="muted"
                        className="text-truncate">
                        {usersRoomDepartmentsAccess?.positionString}
                        {(isRoomUserGuest && primaryDeptsAccess) && ` @ ${primaryDeptsAccess?.department?.shortName || primaryDeptsAccess?.department?.name}`}
                    </Text>
                }
                rightColComponent={
                    <FloatingMenu
                        label="User Options"
                        className="item-options-btn px-3"
                        renderLabel={() => (
                            <Icon
                                name="ellipsis-v"
                                color="muted"
                            />
                        )}
                    >
                        <MenuItem
                            label="View User"
                            onClick={() => openUserDetail(roomUser.id)}
                        />
                        {canToggleManager && (
                            <MenuItem
                                label={isUserManager ? 'Make room Member' : 'Make room Manager'}
                                onClick={updateRole}
                            />
                        )}
                        {canDelete && (
                            <>
                                <MenuDivider />
                                <MenuItem
                                    label="Remove from room"
                                    onClick={removeUser}
                                    disabled={!canDelete}
                                    className="text-danger"
                                />
                            </>
                        )}

                    </FloatingMenu>
                }
            />
        </reactSelectComponents.Option>
    )
})

export { SelectUserDisplay }
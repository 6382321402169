
import { makeAutoObservable } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { DepartmentsAccessStore } from '../store'
import { Api } from '@evertel/api'
import { SessionState } from '@evertel/session'

class DepartmentsAccessController {
    userId: number

    constructor(
        private api: Api,
        private departmentsAccessStore: DepartmentsAccessStore,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    init(userId: number) {
        if (!userId) return

        this.userId = userId

    }

    get departmentsAccess() {
        return this.departmentsAccessStore.find(da => da.blueUserId === this.userId)
    }

    get primaryDepartmentsAccess() {
        return this.departmentsAccess.find(da => da.isPrimary === true)
    }

    get selectedDepartmentsAccess() {
        return this.departmentsAccess.find(da => da.departmentId === this.session.selectedDepartmentId)
    }

    get selectedDepartmentRole() {
        if (!this.selectedDepartmentsAccess) return undefined

        // this works because it's in order of priority and will return their actual (highest) role
        if (this.selectedDepartmentsAccess.roles?.includes('executive')) return 'executive'
        if (this.selectedDepartmentsAccess.roles?.includes('management')) return 'management'
        if (this.selectedDepartmentsAccess.roles?.includes('employee')) return 'employee'
        if (this.selectedDepartmentsAccess.roles?.includes('guest')) return 'guest'

        return undefined
    }
}

decorate(injectable(), DepartmentsAccessController)
decorate(inject(Api), DepartmentsAccessController, 0)
decorate(inject(DepartmentsAccessStore), DepartmentsAccessController, 1)
decorate(inject(SessionState), DepartmentsAccessController, 2)

export { DepartmentsAccessController }
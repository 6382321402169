import { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//========================================================================================================
export class ToasterComponent extends Component {
//========================================================================================================

    constructor(props) {
        super(props)

        //set defaults
        this.state = {
            autoClose: this.props.autoClose !== undefined ? this.props.autoClose : 5000,
            position: this.props.position || 'top-right'
        }

        this.toaster = this.props.toaster || Toaster
        this.toaster._register(this)
    }

    componentWillUnmount() {
        this.toaster._unregister(this)
    }

    //==========================================
    // GETTERS & SETTERS
    //==========================================

    get position() {
        return this.props.position
    }

    get autoClose() {
        return this.props.autoClose
    }


    //---------------------------------------------------------------------------------------
    render() {
    //---------------------------------------------------------------------------------------

        return (
            <ToastContainer position={this.state.position} autoClose={this.state.autoClose} />
        )
    }
}



//==========================================================================================================
export class ToasterNotification {
//==========================================================================================================

    constructor() {
        this.toasterCache = new Set()
    }

    //==========================================
    // REGISTER/UNREGISTER SERVICE
    //==========================================

    _register(toaster) {
        this.toasterCache.add(toaster)
    }

    _unregister(toasterToRemove) {
        this.toasterCache.forEach(toaster => {
            if (toaster === toasterToRemove) {
                this.toasterCache.delete(toaster)
            }
        })
    }

    //==========================================
    // EXPOSED METHODS
    //==========================================

    show(type, message) {
        if (type === 'default') {
            toast(message)
        } else {
            toast[type](message)
        }
    }

    isShowing(toasterName) {
        let showing
        this.toasterCache.forEach(toaster => {
            if (toaster.name === toasterName) {
                showing = toaster.show
            }
        })
        return showing
    }
}

const Toaster = new ToasterNotification()
export default Toaster

import { makeAutoObservable } from 'mobx'
import { injectable, decorate } from '@evertel/di'
import { NormalizedMedia } from '@evertel/media'
import { APIDataMedia } from '@evertel/types'
import { formatBytes, getFileAttributes, getFileExt } from '@evertel/utils'


class MediaController {
    media: NormalizedMedia = {} as NormalizedMedia
    width = 400
    height = 300

    constructor(

    ) {
        makeAutoObservable(this)
    }

    init = async (media: NormalizedMedia, width?: number, height?: number) => {
        this.width = width
        this.height = height
        this.media = media
    }

    updateDimensions (width: number, height: number) {
        this.width = width
        this.height = height
    }

    resolveVersion = (): number => {
        if (!this.media?.versions?.length || !this.width || !this.height) return undefined

        let versions = this.media?.versions
        const area = this.width * this.height

        // select last version by default (original)
        let selected = versions.length - 1

        // remove last one
        versions = versions.slice(0, -1)

        let i = versions.length - 1

        while (i >= 0) {
            const version = versions[i]

            // smaller size version found?
            if (version.width * version.height < area) {
                break
            }

            // select smaller but fit size version
            selected = i

            i--
        }

        return selected // index of closest version
    }

    get isLocal() {
        return this.media.isLocalMedia
    }

    get url() {
        // if already a blob or not an image, simply return the url
        if (this.media.url?.includes('blob') || this.media.contentType !== 'image') return this.media.url

        // images only:
        // grab the closest optimized version based on width/height
        const versionIndex = this.resolveVersion()
        const version = (versionIndex !== undefined) ? this.media.versions[versionIndex].id : 'optimized'

        return `${this.media.url}?ver=${version}`
    }

    // To get the full original download for images
    get downloadUrl() {
        if (this.media.url?.includes('blob') || this.media.contentType !== 'image') return this.media.url
        return `${this.media.url}?ver=original`
    }

    get fileExtension() {
        return getFileExt(this.media)
    }

    get humanizedFileSize() {
        return formatBytes(this.media?.contentLength, 1)
    }

    get hasPreview() {
        // used for videos
        const noPreviewPlatforms = ['mixcloud', 'player.vimeo.com/external', 'soundcloud', 'twitch']
        return (this.media?.id < 0) && !noPreviewPlatforms.some(el => this.media.url.includes(el))
    }

    get fileTypeAttributes() {
        // used for application files
        return getFileAttributes(this.media.fileName)
    }
}

decorate(injectable(), MediaController)

export {
    MediaController
}

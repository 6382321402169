/* eslint-disable camelcase */
import { Api } from '@evertel/api'
import { decorate, inject, injectable } from 'inversify'


interface RegisterBody {
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    deviceToken: string
}

class RegistrationController {    

    constructor(
        private api: Api
    ) {}
    

    registerInAuth0 = async (body: RegisterBody): Promise<void> => {
        return await this.api.Routes.Auth0.postSignup(body)
    }


    getEmailToken = async (userId: number): Promise<boolean> => {
        return await this.api.Routes.Auth0.postVerifyEmail(userId)
    }

}

// Dependency injection with Inversify
decorate(injectable(), RegistrationController)
decorate(inject(Api), RegistrationController, 0)

export { RegistrationController }
import React, { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

type Span = 'auto'|number|string|boolean|null

type BreakPointObject = {
    span?: Span
    offset?: number|string|null
    order?: 'first'|'last'|number|string|null
}

type ColType = Span|BreakPointObject

interface Props extends HTMLAttributes<HTMLDivElement> {
    height?: number // optional to override default
    align?: 'left'|'right'|'center'|'start'|'end'
    valign?: 'top'|'bottom'|'center'|'start'|'end'|'between'|'around'
    flex?: boolean
    children?: React.ReactNode
    className?: string
    xs?: ColType
    sm?: ColType
    md?: ColType
    lg?: ColType
    xl?: ColType
    xxl?: ColType
}

const BREAKPOINTS = [
    'xxl' as const,
    'xl' as const,
    'lg' as const,
    'md' as const,
    'sm' as const,
    'xs' as const,
]

const Col = forwardRef<HTMLDivElement, Props>(({
    height,
    align,
    valign = 'center',
    flex = true,
    children,
    className,
    style,
    ...otherProps
}, ref) => {

    const repsonsiveClassNames: string[] = []

    if (flex) {
        repsonsiveClassNames.push('flex-col')
    }

    BREAKPOINTS.forEach((bp) => {
        const breakpoint = otherProps[bp]

        delete otherProps[bp]
  
        const infix = (bp === 'xs') ? '' : `-${bp}`
  
        if (typeof breakpoint === 'number' || typeof breakpoint === 'string') {
            repsonsiveClassNames.push(`col${infix}-${breakpoint}`)
        }
        if (typeof breakpoint === 'boolean') {
            repsonsiveClassNames.push(`col${infix}`)
        }
        if (breakpoint && typeof breakpoint === 'object') {
            if (typeof breakpoint.span === 'number' || typeof breakpoint.span === 'string') {
                repsonsiveClassNames.push(`col${infix}-${breakpoint.span}`)
            }
            if (typeof breakpoint.span === 'boolean') {
                repsonsiveClassNames.push(`col${infix}`)
            }
            if (typeof breakpoint.order === 'number' || typeof breakpoint.order === 'string') {
                repsonsiveClassNames.push(`order${infix}-${breakpoint.order}`)
            }
            if (typeof breakpoint.offset === 'number') {
                repsonsiveClassNames.push(`offset${infix}-${breakpoint.offset}`)
            }
        }
    })


    if (flex) {
        if (valign === 'top') valign = 'start'
        if (valign === 'bottom') valign = 'end'
        repsonsiveClassNames.push(`justify-content-${valign}`)

        if (align === 'left') align = 'start'
        if (align === 'right') align = 'end'
        if (align) repsonsiveClassNames.push(`align-items-${align}`)

    } else {
        repsonsiveClassNames.push(`text-${align}`)
    }
  
    const _className = classNames(repsonsiveClassNames, className)
    const _style = { ...style, height }

    return (
        <div
            className={_className}
            ref={ref}
            style={_style}
            {...otherProps}>
            {children}
        </div>
    )
})

export { Col }

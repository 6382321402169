import { useState, useContext } from 'react'
import { observer } from 'mobx-react-lite'
// evertel
import { Text, TabPane, Nav, NavItem, NavLink, TabContent, Col } from '@evertel/web/ui'
import { ThreadContext } from '../../Thread'
import { ThreadMediaSearchSelect } from '../ThreadMediaSearchSelect'


const ThreadMediaSearch = observer(() => {

    const { threadController } = useContext(ThreadContext)
    const thread = threadController.thread

    const [activeTab, setActiveTab] = useState<'files'|'docs'>('files')

    if (!thread) return null

    return (
        <Col>
            <Nav
                variant="underline"
                role="tablist"
                style={{
                    fontSize: '0.9rem'
                }}>
                <NavItem>
                    <NavLink
                        active={activeTab === 'files'}
                        onClick={() => setActiveTab('files')}>
                        <Text>
                            Files/Images
                        </Text>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent>
                <TabPane
                    role="tabpanel"
                    visible={activeTab === 'files'}>
                    <div className="pt-3">
                        Find files shared in this room
                    </div>
                    <ThreadMediaSearchSelect
                        threadId={threadController.id}
                        menuIsOpen
                        maxMenuHeight="calc(100vh - 220px)"
                        className="no-bg search space-below"
                    />
                </TabPane>
            </TabContent>
        </Col>
    )
})

export { ThreadMediaSearch }

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Wrapper from './Wrapper'
import { useService } from '@evertel/di'
import { useService as useGlobalService } from '@evertel/service' 
import { DocumentMediaReadWidgetController } from '../../controller'
import { Image, View } from '@react-pdf/renderer'

export interface MediaReadWidgetProps {
    id: number|string,
    value: number[],
    title: string,
    description: string
}

const MediaReadWidget: React.FC<MediaReadWidgetProps> = observer(({
    value,
    title,
    description
}) => {

    const mediaReadWidgetController = useService(DocumentMediaReadWidgetController, [])
    const meta = useGlobalService('meta')

    const [images, setImages] = useState([])

    // TODO store auth code in login service for retrieval
    const authToken = localStorage.getItem('authorization')

    useEffect(() => {
        (async () => {
            await mediaReadWidgetController.init(
                meta.document,
                value
            )
            
            setImages(mediaReadWidgetController.media)
        })()
    }, [])

    if (!images?.length) return null

    return (
        <Wrapper
            title={title}
            description={description}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between'
            }}>
                {images?.map((image) => 
                    <Image
                        key={image.id}
                        src={{ uri: image.url, method: 'GET', headers: {Authorization: authToken}, body: '' }}
                        style={{
                            width: '45vw',
                            height: '32vw',
                            objectFit: 'contain',
                            marginBottom: 10
                        }}
                    />
                )}
            </View>
        </Wrapper>
    )
})

export {
    MediaReadWidget
}

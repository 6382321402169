import React from 'react'
import { ElementType, forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

export interface NavProps extends HTMLAttributes<HTMLDivElement | HTMLUListElement | HTMLOListElement> {
    className?: string
    component?: string | ElementType
    layout?: 'fill'|'justified'
    variant?: 'tabs'|'pills'|'underline'|'box'
}

const Nav = forwardRef<HTMLDivElement | HTMLUListElement | HTMLOListElement, NavProps>(({
    children,
    className,
    component: Component = 'ul',
    layout,
    variant,
    ...otherProps
}, ref) => {

        const _className = classNames(
            'nav',
            {
                [`nav-${layout}`]: layout,
                [`nav-${variant}`]: variant
            },
            className
        )

        return (
            <Component
                ref={ref}
                className={_className}
                role="navigation"
                {...otherProps}>
                {children}
            </Component>
        )
})

export { Nav }
Nav.displayName = 'Nav'

import { ContainerModule } from '@evertel/di'
import { DocumentStore, DocumentACLStore } from './store/'
import { DocumentSearchController, DepartmentDocumentSchemasController, DocumentViewController, DepartmentSchemaCategoriesController, CategorySchemasController,
    DocumentACLsController, DocumentEditController, DocumentACLPrincipalsController, SchemaCategorySearchController, SchemaCategoryController,
    GlobalSchemaCategoriesController, CloneSchemaController, DocumentCreateController, DocumentSearchByRoomController, DocumentSchemaPickerController } from './controller'
import { MediaUtils } from './utils'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(DocumentStore).to(DocumentStore).inSingletonScope()
        bind(DocumentACLStore).to(DocumentACLStore).inSingletonScope()

        bind(DocumentSearchController).toSelf()
        bind(DepartmentDocumentSchemasController).toSelf()
        bind(DocumentViewController).toSelf()
        bind(DocumentACLsController).toSelf()
        bind(DocumentEditController).toSelf()
        bind(DocumentCreateController).toSelf()
        bind(DocumentACLPrincipalsController).toSelf()
        bind(SchemaCategorySearchController).toSelf()
        bind(SchemaCategoryController).toSelf()
        bind(DepartmentSchemaCategoriesController).toSelf()
        bind(CategorySchemasController).toSelf()
        bind(GlobalSchemaCategoriesController).toSelf()
        bind(CloneSchemaController).toSelf()
        bind(DocumentSearchByRoomController).toSelf()
        bind(DocumentSchemaPickerController).toSelf()
        
        bind(MediaUtils).toSelf()
    })
]

export { DI_MODULES }

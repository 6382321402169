
import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { SessionState } from '@evertel/session'
import { Api } from '@evertel/api'

const UNREAD_COUNTS_POLL_INTERVAL_MS = 1000 * 15 // 15 seconds
const UNREAD_COUNTS_POLL_ENABLED = true

interface RoomUnreads {
    roomId: number;
    departmentId: number;
    count: number;
    urgent: number;
    messageIds: number[];
}
interface ThreadUnreads {
    count: number,
    messageIds: number[],
    threadId: number,
    urgent: number
}

class UnreadCountsState {
    roomUnreads: RoomUnreads[] = []
    threadUnreads: ThreadUnreads[] = []
    private pollInterval = undefined // holds the setInterval timer

    constructor(
        private api: Api,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    startPoll = () => {
        this.stopPoll()
        if (!UNREAD_COUNTS_POLL_ENABLED) return

        this.pollInterval = setInterval(() => {
            this.fetchUnreadCounts()
        }, UNREAD_COUNTS_POLL_INTERVAL_MS)
    }

    stopPoll = () => {
        if (this.pollInterval) {
            clearInterval(this.pollInterval)
            this.pollInterval = undefined
        }
    }

    // Extend the interval timer if its set
    resetPollInterval =() => {
        if (this.pollInterval) {
            this.startPoll()
        }
    }

    fetchUnreadCounts = async () => {
        this.resetPollInterval()
        if (!this.session.currentUserId) return

        await Promise.all([
            this.api.Routes.BlueUser.getRoomMessagesCountUnread(this.session.currentUserId),
            this.api.Routes.BlueUser.getThreadMessagesCountUnread(this.session.currentUserId)
        ])
            .then((results) => {
                runInAction(() => {
                    this.roomUnreads = results[0]
                    this.threadUnreads = results[1]
                })
            })
            .catch((error: any) => {
                console.error(error.message)
            })
    }

    getRoomUnread = (roomId: number) => {
        return this.roomUnreads?.find(u => u.roomId === roomId)
    }

    getThreadUnread = (threadId: number) => {
        return this.threadUnreads?.find(u => u.threadId === threadId)
    }

    getDeptRoomUnreads = (deptId: number) => {
        return this.roomUnreads?.filter(d => d.departmentId === deptId)
    }

    markReadByRoomId = (roomId: number) => {
        this.roomUnreads = this.roomUnreads?.filter(r => r.roomId !== roomId)
    }

    markReadByThreadId = (threadId: number) => {
        this.threadUnreads = this.threadUnreads?.filter(r => r.threadId !== threadId)
    }

    get roomUnreadCountsByDept(): Record<number, number> {
        // this will return an object {departmentId: unreadCount} ordered by most unreads to least
        if (!this.roomUnreads?.length) return {}
        return this.roomUnreads?.reduce((a, {departmentId, count}) => (a[departmentId] = (a[departmentId] || 0) + count, a), {})
    }

    get roomUnreadUrgentsByDept(): Record<number, boolean> {
        if (!this.roomUnreads?.length) return {}
        return this.roomUnreads?.reduce((a, {departmentId, urgent}) => {
            if (urgent > 0) {
                a[departmentId] = true
            }
            return a
        }, {} as Record<number, boolean>)
    }

    get threadsMessageUnreadCount(): number {
        return this.threadUnreads.reduce((total, thread) => total + thread.count, 0)
    }
}

decorate(injectable(), UnreadCountsState)
decorate(inject(Api), UnreadCountsState, 0)
decorate(inject(SessionState), UnreadCountsState, 1)

export { UnreadCountsState }

import React, { forwardRef, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import moment from 'moment'
import ReactPlayer from 'react-player/lazy'
import {
    Image,
    FileTile,
    Video,
    AudioTile,
    Col,
    DeleteButtonOverlay,
    Row,
    Text,
    Icon,
    FileProps,
    VideoProps,
    ImageProps,
    AudioProps,
    ProgressCircle
} from './../../index'
import { MediaController } from './controller'
import { useService } from '@evertel/di'
import { APIDataMedia } from '@evertel/types'
import { ImageResizeMode } from 'react-native'
import { NormalizedMedia } from '@evertel/media'
import { DownloadButton } from './DownloadButton'

interface MediaProps {
    media: NormalizedMedia
    variant?: 'tile' | 'file-list' | 'file-attachment',
    width: number,
    height: number,
    rounded?: boolean,
    className?: string,
    style?: object,
    allowDownload?: boolean,
    onClick?: () => void,
    onDelete?: (media: APIDataMedia | File) => void,
    resizeMode?: ImageResizeMode,
    memo?: boolean // memoize or not. default = false

    // the following can be used to pass specific props to each media type.
    // any props here will override props above
    videoProps?: Omit<VideoProps, 'url'>,
    imageProps?: Omit<ImageProps, 'url'>,
    fileProps?: Omit<FileProps, 'url' | 'fileName'>,
    audioProps?: Omit<AudioProps, 'url'>
}

const Media = observer(forwardRef<HTMLImageElement & ReactPlayer & HTMLAudioElement, MediaProps>(({
    media,
    variant = 'tile',
    width,
    height,
    rounded,
    className,
    style = {},
    allowDownload,
    onClick,
    onDelete,
    memo,
    videoProps,
    imageProps,
    fileProps,
    audioProps,
    ...otherProps
}, ref) => {

    const mediaController = useService(MediaController, [])

    useState(() => {
        //must initialize in useState since it's synchronous so audioTile will be provided info on first render
        mediaController.init(media, width, height)
    })

    useEffect(() => {
        mediaController.updateDimensions(width, height)
    }, [width, height])

    if (!media) return null

    const renderMediaElement = () => {
        switch (media.contentType) {
            case 'image':
                return (
                    <Image
                        ref={ref}
                        url={mediaController?.url}
                        fileName={mediaController?.media.fileName}
                        width={width}
                        height={height}
                        {...(variant === 'tile' && { className })}
                        rounded={rounded}
                        resizeMode='contain'
                        variant={variant}
                        onClick={onClick}
                        {...imageProps}
                        {...otherProps}
                    />
                )
            case 'video':
                return (
                    <Video
                        ref={ref}
                        url={mediaController?.url}
                        width={width}
                        height={height}
                        className={className}
                        rounded={rounded}
                        light={mediaController.hasPreview}
                        {...videoProps}
                        {...otherProps}
                    />
                )
            case 'audio':
                return (
                    <AudioTile
                        ref={ref}
                        url={mediaController?.url}
                        fileName={mediaController.media?.fileName}
                        fileSize={mediaController.humanizedFileSize}
                        variant={variant}
                        {...audioProps}
                        {...otherProps}
                    />
                )
            case 'file':
            case 'application':
            case 'text':
            default:
                return (
                    <FileTile
                        ref={ref}
                        url={mediaController?.url}
                        fileName={mediaController.media?.fileName}
                        fileSize={mediaController.media?.contentLength}
                        {...fileProps}
                        {...otherProps}
                    />
                )
        }
    }

    const combinedStyle = {
        width: 'fit-content',
        ...style // This will merge and overwrite as needed
    }

    if (media.state === 'uploading') {
        return (
            <div
                className={classNames('media-item', className)}
                style={combinedStyle}
            >
                <div
                    style={{ width: width, height: height }}
                    className='media-loading mt-1'
                >
                    <ProgressCircle
                        progress={media?.uploadProgress * 100}
                        radius={15}
                        stroke={3}
                    />
                    <Text>Uploading...</Text>
                </div>
            </div>
        )
    }
    
    if (['file-list', 'file-attachment'].includes(variant)) {
        return (
            <Row
                className={classNames('media-item',
                    `media-item-${media.contentType}`, 
                    `media-${variant}`, 
                    {'m-1 p-1 pr-2 d-flex flex-grow-0 mw-100': (variant==='file-attachment') },
                    className)}
                style={combinedStyle}
            >
                <Col
                    style={{ width: width }}
                    className='mr-2 flex-grow-0'
                >
                    {React.createElement(
                        onClick ? 'button' : 'div',
                        {
                            className: 'p-0',
                            style: { display: 'block' },
                            ...(onClick && { onClick })
                        },
                        ['application', 'text', 'file', 'audio', 'video'].includes(media.contentType) ? (
                            <div
                                className='mr-2 d-flex justify-content-center align-items-center rounded'
                                style={{
                                    width: width,
                                    height: width,
                                    backgroundColor: mediaController.fileTypeAttributes.iconColor
                                }}
                            >
                                <Icon
                                    name={mediaController.fileTypeAttributes.iconName}
                                    color='white'
                                    size={width * 0.5}
                                />
                            </div>
                        ) : (
                            renderMediaElement()
                        )
                    )}
                </Col>
                <Col align='left' style={{minWidth: 0}}>
                    <Text
                        size='small'
                        bold
                        className='text-truncate'
                    >
                        {mediaController.media.fileName}
                    </Text>
                    <Text
                        color='muted'
                        size='small'
                    >
                            ({mediaController.humanizedFileSize})
                        {variant === 'file-list' && mediaController.media?.updatedDate && (
                            ` Shared on ${moment(mediaController.media?.updatedDate).format('l')}`
                        )}
                    </Text>
                </Col>
                <DownloadButton
                    fileUrl={mediaController?.downloadUrl}
                    fileName={mediaController.media?.fileName}
                    allowDownload={allowDownload}
                />
                {onDelete && (
                    <DeleteButtonOverlay
                        onClick={() => onDelete(media)}
                    />
                )}
            </Row>
        )
    }
    

    return (
        <div
            className={classNames(
                'media-item',
                `media-item-${media.contentType}`,
                className
            )}
            style={combinedStyle}
        >
            {renderMediaElement()}
        </div>
    )

}))

export { Media }
import React, { memo } from 'react'
import { InfoBox, Text } from '@evertel/web/ui'

export interface UnknownWidgetProps {
    id: number|string,
    value: string,
    title: string,
    required?: boolean,
    scope?: string
}

const UnknownWidget: React.FC<UnknownWidgetProps> = memo(({
    title
}) => {

    return (
        <InfoBox color="info">
            <Text>
                {`To view this ${title} field, you must update your app`}
            </Text>
        </InfoBox>
    )
})

export {
    UnknownWidget
}

import { makeAutoObservable, runInAction } from 'mobx'
// Use the decorate function for dependency injection
import { decorate, injectable, inject } from 'inversify'

import { debounce } from 'lodash'
import { Api } from '@evertel/api'


class HistoryTableController {
    history: any[] = []
    historyLatestFetch: any[] = []
    departmentId: number | null = null
    blueUserIdSearch = ''
    actionSearch = ''
    dataSearch = ''
    page = 1
    limit = 50
    historyCount = 0
    isInfiniteScroll = false
    isLoading = false
    sortBy = 'id'
    sortOrder: 'asc' | 'desc' = 'desc'

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init(departmentId: number, isInfiniteScroll = false) {
        this.departmentId = departmentId
        this.isInfiniteScroll = isInfiniteScroll
        this.resetState()
    }

    setBlueUserIdSearch(value: string) {
        this.resetSearchMeta()
        this.blueUserIdSearch = value
    }

    setActionSearch(value: string) {
        this.resetSearchMeta()
        this.actionSearch = value
    }

    setDataSearch(value: string) {
        this.resetSearchMeta()
        this.dataSearch = value
    }

    setPage(page: number) {
        this.page = page
    }

    setLimit(limit: number) {
        this.limit = limit
        this.resetSearchMeta()
    }

    setSorting(sortBy: string, sortOrder: 'asc' | 'desc') {
        this.sortBy = sortBy
        this.sortOrder = sortOrder
        this.resetSearchMeta()
    }

    private debouncedSearchInternal = debounce(async () => {
        try {
            await this.search()
        } catch (e) {
            /*  */
        }
        runInAction(() => {
            this.isLoading = false
        })
        return 
    }, 300)

    debouncedSearch = () => {
        this.isLoading = true
        return this.debouncedSearchInternal()
    }

    search = async () => {
        if (!this.departmentId) return

        runInAction(() => {
            this.isLoading = true
        })
        
        let count = this.historyCount
        let results: any[] = []

        // grab the total count of history items
        if (!this.historyCount) {
            count = await this.fetchHistoryCount()
        }

        this.historyLatestFetch = []

        // stop from fetching if we already got them all
        if (count > this.history?.length) {
            results = await this.api.Routes.Department.getHistory(this.departmentId, this.filter)
        }

        const apiDataResults = this.isInfiniteScroll ? [...this.history, ...results] : results

        runInAction(() => {
            this.historyLatestFetch = results
            this.history = apiDataResults
            this.isLoading = false
        })  
    }

    fetchNextHistory() {
        this.page = this.page + 1
        this.search()
    }

    async fetchHistoryCount() {
        const res = await this.api.Routes.Department.getHistoryCount(this.departmentId, this.filter.where)

        runInAction(() => {
            this.historyCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    resetSearchMeta() {
        this.page = 1
        this.history = []
        this.historyCount = 0
    }

    resetState() {
        this.resetSearchMeta()
        this.blueUserIdSearch = ''
        this.actionSearch = ''
        this.dataSearch = ''
        this.sortBy = 'id'
        this.sortOrder = 'desc'
    }

    get filter() {
        const f: any = {
            where: {},
            include: ['createdBy'],
            limit: this.limit,
            skip: (this.page - 1) * this.limit,
            order: `${this.sortBy} ${this.sortOrder}`
        }

        if (this.blueUserIdSearch.trim() !== '') {
            f.where['blueUserId'] = this.blueUserIdSearch.trim()
        }

        if (this.actionSearch.trim() !== '') {
            f.where['action'] = { like: `%${this.actionSearch.trim().replace(/\s+/g, '_')}%` }
        }

        if (this.dataSearch.trim() !== '') {
            f.where['data'] = { like: `%${this.dataSearch.trim()}%` }
        }

        return f
    }
}

decorate(injectable(), HistoryTableController)
decorate(inject(Api), HistoryTableController, 0)

export { HistoryTableController }
import { decorate, injectable } from 'inversify'
//evertel
import { LogoutImplementation } from '@evertel/logout'

class WebLogoutImplementation implements LogoutImplementation {
    private db: any

    init(db: IDBDatabase) {
        this.db = db
    }

    async logout() {
        // clear local db tables but keep db open
        this.db?.tables?.forEach(table => {
            table?.delete(1)
        })

    }
}

decorate(injectable(), WebLogoutImplementation)

export { WebLogoutImplementation }
import React, { createContext, useState, useContext } from 'react'
import { JoinRoomModal } from './JoinRoomModal'

export type SearchScope = 'public'|'department'

export interface JoinRoomContextProps {
    openJoinRoom: (searchScope?: SearchScope) => void,
    closeJoinRoom: () => void
}

export const JoinRoomContext = createContext<JoinRoomContextProps>({
    openJoinRoom: (searchScope?: SearchScope) => {
        //
    },
    closeJoinRoom: () => {
        //
    }
})

export const JoinRoomContextProvider: React.FC<{children?: JSX.Element}> = ({
    children
}) => {

    const [visible, setVisible] = useState(false)
    const [searchScope, setSearchScope] = useState<SearchScope>('department')

    const openJoinRoom = (searchScope: SearchScope = 'department') => {
        if (searchScope === 'department' || searchScope === 'public') {
            setSearchScope(searchScope)
        }
        setVisible(true)
    }

    const closeJoinRoom = () => {
        setVisible(false)
    }

    return (
        <JoinRoomContext.Provider value={{ openJoinRoom, closeJoinRoom }}>
            {children}
            <JoinRoomModal
                visible={visible}
                searchScope={searchScope}
                onClose={() => setVisible(false)}
            />
        </JoinRoomContext.Provider>
    )
}

export const JoinRoomConsumer = JoinRoomContext.Consumer

export const useJoinRoom = () => {
    return useContext(JoinRoomContext)
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import classNames from 'classnames'
import { Droppable, Draggable, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { Icon } from '@evertel/web/ui'
import { ServiceProvider } from '@evertel/service'
import { WidgetsProvider, Delegator } from '@evertel/schema-parser'
import { WriteWidgets, SpacerWidget } from '../../schema-widgets'
import { SchemaFieldItemProps } from '../data/_schemaItemProps'
import { SchemaMediaController } from '../../../controller'
import { SessionState } from '@evertel/session'
import { useService } from '@evertel/di'

interface DroppableRowProps {
    rowIndex: number
    row: any
    isRowDropDisabled?: boolean
    onDelete: (itemId: string, rowIndex: number) => void
    onDeleteRow: (rowIndex: number) => void
    onEdit: (item: SchemaFieldItemProps) => void
    onItemReorder: (itemId: string, rowIndex: number, currentIndex: number, newIndex: number) => void
    onClickToEditListSchema: (internalSchemaId: string) => void
    mediaController: SchemaMediaController
    schemaId: number
}

const DnDRow: React.FC<DroppableRowProps> = ({
    rowIndex,
    row,
    onDelete,
    onDeleteRow,
    onEdit,
    onItemReorder,
    onClickToEditListSchema,
    mediaController,
    schemaId
    //isRowDropDisabled
}) => {

    const session = useService(SessionState, [])

    const getDragOverStyle = (snapshot: DraggableStateSnapshot, row: any) => {
        //console.log(snapshot.combineTargetFor, snapshot.combineWith)
        if (snapshot.combineTargetFor) {
            if (snapshot.combineTargetFor === 'list-toolbox' && row.items?.length) {
                return 'drop-row deny'
            }
            if (snapshot.combineTargetFor === 'list-toolbox' && !row.items?.length) {
                return 'drop-row accept'
            }
            if (snapshot.combineTargetFor !== 'list-toolbox') {
                const hasList = !!row.items?.find((i: any) => i.type === 'list')
                if (hasList) {
                    return 'drop-row deny'
                } else {
                    return 'drop-row accept'
                }
            }
        }
        return 'drop-row'
    }

    return (
        <>
            {/* @ts-ignore */}
            <Draggable
                draggableId={`row-${rowIndex}`}
                index={rowIndex}>
                    {(dragRowProvided, dragRowSnapshot) => (
                        <div
                            ref={dragRowProvided.innerRef}
                            className="dnd-row"
                            {...dragRowProvided.draggableProps}>
                                <RowToolbar
                                    index={rowIndex}
                                    onDelete={onDeleteRow}
                                    {...dragRowProvided.dragHandleProps}
                                />
                                {/* this droppable is needed to give a consistent drop target that references the row
                                    otherwise, the drop target on combine will be the field item and be very difficult to respond to */}
                                {/* @ts-ignore */}
                                <Droppable
                                    droppableId={`row-drop-${rowIndex}`}
                                    type="row">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className={getDragOverStyle(dragRowSnapshot, row)}>
                                                {/* this is to handle legacy structure. current structure doesn't have spacers at this level */}
                                                {(row.type === 'spacer') ?
                                                    <SpacerWidget
                                                        scope="schemaBuilder"
                                                    />
                                                    :
                                                    <ServiceProvider services={[{id: 'meta', value: {
                                                        user: session?.currentUserId,
                                                        department: session?.selectedDepartmentId,
                                                        scope: 'schemaBuilder'
                                                    }}]}>
                                                        <WidgetsProvider value={WriteWidgets}>
                                                            {(row.items?.map(({id, type, ...itemProps}: {id: string, type: string}, idx: number) =>{
                                                                return (
                                                                    <>
                                                                        {/* @ts-ignore */}
                                                                        <Draggable
                                                                            key={`${id}-${idx}`}
                                                                            draggableId={id} // this must match the field id in the schema
                                                                            index={idx}>
                                                                                {(provided, snapshot) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps} 
                                                                                        key={idx}
                                                                                        className={classNames('schema-item', {'bg-info': snapshot.isDragging})}
                                                                                        style={{
                                                                                            ...provided.draggableProps.style,
                                                                                            transform: snapshot.isDragging
                                                                                                ? provided.draggableProps.style?.transform
                                                                                                : 'translate(0px, 0px)'
                                                                                        }}>
                                                                                            {/* @ts-ignore */}
                                                                                            <Delegator
                                                                                                id={id}
                                                                                                type={type}
                                                                                                index={idx}
                                                                                                scope="schemaBuilder"
                                                                                                schemaId={schemaId}
                                                                                                {...type === 'list' && {onClickToEditListSchema: onClickToEditListSchema}}
                                                                                                {...(type === 'checkbox' || type === 'checkbox-group') && {mediaController: mediaController}}
                                                                                                {...itemProps}
                                                                                            />
                                                                                            <ItemToolbar
                                                                                                item={{
                                                                                                    id,
                                                                                                    type,
                                                                                                    ...itemProps
                                                                                                } as any}
                                                                                                index={idx}
                                                                                                rowIndex={rowIndex}
                                                                                                itemsCount={row.items?.length}
                                                                                                onDelete={onDelete}
                                                                                                onEdit={onEdit}
                                                                                                onReorder={onItemReorder}
                                                                                                {...provided.dragHandleProps}
                                                                                            />
                                                                                    </div>
                                                                                )}
                                                                        </Draggable>
                                                                    </>
                                                                )
                                                            }
                                                            ))}
                                                        </WidgetsProvider>
                                                    </ServiceProvider>
                                                }
                                            </div>
                                        )}
                                </Droppable>
                        </div>
                    )}
                    
            </Draggable>
        </>
    )

}

interface RowToolbarProps {
    index: number,
    onDelete: (index: number) => void
}

const RowToolbar: React.FC<RowToolbarProps> = ({
    index,
    onDelete,
    ...otherProps
}) => {
    return (
        <div
            className="row-toolbar"
            {...otherProps}
            >
            <Icon
                name="arrows-up-down-left-right"
                fixedWidth
                
            />
            <Icon
                name="trash-can"
                onClick={() => onDelete(index)}
                fixedWidth
            />
        </div>
    )
}

interface ItemToolbarProps {
    item: SchemaFieldItemProps,
    rowIndex: number,
    index: number,
    itemsCount?: number, 
    onDelete: (itemId: string, rowIndex: number) => void
    onEdit: (item: SchemaFieldItemProps) => void
    onReorder: (itemId: string, rowIndex: number, currentIndex: number, newIndex: number) => void
}

const ItemToolbar: React.FC<ItemToolbarProps> = ({
    item,
    index,
    rowIndex,
    itemsCount = 1,
    onDelete,
    onEdit,
    onReorder,
    ...otherProps
}) => {

    return (
        <div
            className="item-toolbar">
            <Icon
                name="arrows-up-down-left-right"
                fixedWidth
                {...otherProps}
                //data-tooltip="Move field"
            />
            <Icon
                name="pencil"
                onClick={() => onEdit(item)}
                fixedWidth
                //data-tooltip="Edit field"
            />
            {(index > 0) &&
                <Icon
                    name="arrow-left-to-line"
                    onClick={() => onReorder(item.id, rowIndex, index, index-1)}
                    fixedWidth
                    //data-tooltip="Move to the left"
                />
            }
             {(itemsCount > 1 && index < (itemsCount - 1)) &&
                <Icon
                    name="arrow-right-to-line"
                    onClick={() => onReorder(item.id, rowIndex, index, index+1)}
                    fixedWidth
                    //data-tooltip="Move to the right"
                />
            }
            <Icon
                name="trash-can"
                onClick={() => onDelete(item.id, rowIndex)}
                fixedWidth
                //data-tooltip="Delete field"
            />
        </div>
    )
}

export {
    DnDRow
}
import PropTypes from 'prop-types'

const propTypes = {
    message: PropTypes.string
}

const SuccessCheckmark = ({ message }) => {
    return (
        <h3 className="text-success mt-2 fadeIn animated">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            {message}
        </h3>
    )
}

SuccessCheckmark.propTypes = propTypes
export default SuccessCheckmark

import React, { ReactElement, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, HeaderAlert, Icon, useUI } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { PushService } from '@evertel/push'

export interface PermissionAlertBarProps {
    type: string,
    isOpen: boolean,
}


const PermissionAlert: React.FC<PermissionAlertBarProps> = observer(({
    type,
    isOpen
}) => {

    const pushService = useService(PushService, [])
    const { closeAlertBar } = useUI()


    const [message, setMessage] = useState<ReactElement | undefined>(undefined)
    const [color, setColor] = useState<string | undefined>('')

    useEffect(() => {
        defineMessage()
    }, [type])


    const refresh = () => {
        document.location.reload()
    }

    const enableNotifications = async () => {
        if ('Notification' in window) {
            // if they have denied notifications, open the help article on how to enable
            if (pushService?.notificationPermission === 'denied') {
                goTo('help-notifications')

            } else {
                pushService.promptForNotificationPermission()
                closeAlertBar()
            }
        }
    }

    const goTo = (loc: string) => {
        if (loc === 'changelog') {
            window.open('https://getevertel.com/changelog/', '_blank')
        } else if (loc === 'help-notifications') {
            window.open('https://getevertel.com/help-center/allow-desktop-notifications/', '_blank')
        }
    }

    const setAskNotificationPermissionStatus = () => {
        // this sets to local storage. if 'never' we won't ask for permission again
        localStorage.setItem('notPerm', 'never')
        closeAlertBar()
    }

    const defineMessage = () => {
        let color, message
        switch (type) {
            case 'default':
                message = <span><Icon name="flag" className='pr-2' /> Evertel needs your permission to enable desktop notifications
                    <Button color="dark" className="py-0 ml-1" onClick={enableNotifications}>Enable Notifications</Button></span>
                color = 'yellow'
                break
            case 'denied':
                message = <span>
                    <Icon name="flag" className="pr-2" /> We strongly recommend enabling notifications if you'll be using Evertel on this computer.<br />
                    <Button color="success" className="py-0 ml-1" onClick={enableNotifications}>Enable Notifications</Button>
                    <Button color="dark" className="py-0 ml-3" onClick={setAskNotificationPermissionStatus}>Never ask on this computer</Button>
                </span>
                color = 'red'
                break
            default:
                break
        }


        setMessage(message)
        setColor(color)
    }

    return (
        <HeaderAlert isOpen={isOpen} color={color} onClick={() => closeAlertBar()}>
            {message}
        </HeaderAlert>
    )
})

export { PermissionAlert }
import React, { ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import Select, { Props, ControlProps, components as reactSelectComponents }  from 'react-select'
// evertel
import { Button, ContentPlaceholder, Icon, Text, Col, Row, Badge, useUI, CustomAsyncPaginate, CustomOptionProps } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { InviteStatus, RoomInvitesController } from '@evertel/room'
import { APIDataBlueUser, APIDataInvite, APIDataRoom } from '@evertel/types'
import moment from 'moment'

interface RoomInvitesSearchSelectProps extends Props {
    room: APIDataRoom
    canManageRoom?: boolean,
    selected?: Record<string, any>[],
    disabled?: boolean,
    menuIsOpen?: boolean,
    showFilters?: boolean,
    additionalFilters?: [],
    onEndReachedThreshold?: number,
    searchFilter?: Record<string, any>,
    className?: string,
    onSelect?: (user: APIDataBlueUser) => void
}

const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'valid', label: 'Pending' },
    { value: 'consumed', label: 'Accepted' },
    { value: 'expired', label: 'Expired' },
    { value: 'deleted', label: 'Deleted/Cancelled' }
]

interface CustomControlProps {
    renderAdditionalControls: () => React.ReactNode;
}
interface ExtendedSelectProps extends Props {
    customControlProps: CustomControlProps;
}

const CustomControl = ({ ...props }: ControlProps<unknown, false> & { selectProps: ExtendedSelectProps }) => {
    const { selectProps } = props
    const { renderAdditionalControls } = selectProps.customControlProps as CustomControlProps
  
    return (
        <>
            {/* <reactSelectComponents.Control {...props} /> */}
            {renderAdditionalControls()}
        </>
    )
}

const RoomInvitesSearchSelect: React.FC<RoomInvitesSearchSelectProps> = observer(({
    selected = [],
    room,
    canManageRoom,
    disabled,
    menuIsOpen = true,
    placeholder = 'Select a status below...',
    className,
    onSelect,
    showFilters,
    additionalFilters,
    onEndReachedThreshold = 80,
    ...otherProps
}) => {

    const invitesController = useService(RoomInvitesController, [room?.id])

    const [isLoading, setIsLoading] = useState(true)
    const [isSearching, setIsSearching] = useState(false)

    const [key, setKey] = useState(room?.id)
    const [selectedFilter, setSelectedFilter] = useState<InviteStatus|'all'>('valid')

    useEffect(() => {
        if (!room?.id) return

        setIsLoading(true)

        invitesController.init( room?.id, {} )
        onChangeSearchStatus(selectedFilter)
        //needs to render once with loading true to reload async paginate
        setTimeout(() => { setIsLoading(false)}, 1)

    }, [room?.id])

    useEffect(() => {
        // we use a key to force the AsyncPaginate component
        // to reload when outside params change
        setKey(room?.id)
    }, [room?.id])

    const search = useCallback(async (
        searchString: string,
        loadedOptions: APIDataRoom[],
        additional: { page: number, filter: string }
    ) => {
        try {
            setIsSearching(true)
            await invitesController.fetchInvites()
            setIsSearching(false)

        } catch (error) {
            console.error(error.message)
        }

        const hasMore = false

        return {
            options: invitesController.invitesLatestFetch || [],
            hasMore,
            additional: {
                page: 1, //additional?.page + 1 || 1,
                filter: additional.filter
            }
        }
    }, [room?.id])

    const onChangeSearchStatus = (status: InviteStatus|'all') => {
        const filter = {
            where: {
                isPersistent: false
            }
        }
        if (status !== 'all') {
            filter.where['status'] = status
        }
        invitesController.setFilter(filter)
        setSelectedFilter(status)
    }

    const resetSelect = () => {
        // change the key to force the list to refresh
        setKey((prevKey) => prevKey + 1)
    }

    const onSelectOption = (e) => {
        if (onSelect) onSelect(e)
    }

    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number): boolean => {
        if (isSearching) return false
        const maxPixelThreshold = 300
        const percentThreshold = 100 - onEndReachedThreshold

        const pxFromBottom = scrollHeight - clientHeight - scrollTop
        const percentThresholdFromBottomInPx = ((percentThreshold / 100) * (scrollHeight - clientHeight))
    
        // Determine the threshold to use (whichever is smaller)
        const thresholdToUse = Math.min(percentThresholdFromBottomInPx, maxPixelThreshold)

        // console.log('px from bottom / threshold, result', pxFromBottom, thresholdToUse, pxFromBottom <= thresholdToUse)    
        return pxFromBottom <= thresholdToUse
    }

    const renderAdditionalControls = () => (
        <>
            {/* this comment prevents formatting lines  */}
            {(showFilters) &&
                <Select
                    options={filterOptions}
                    value={filterOptions.find(option => option.value === selectedFilter)}
                    onChange={(s) => onChangeSearchStatus(s.value as InviteStatus)}
                    className="react-select"
                    classNamePrefix="select"
                    isSearchable={false}
                />
            }
        </>
    )

    if (isLoading || !room?.id) {
        return (
            <ContentPlaceholder
                className={className}
                style={{
                    height: 38
                }}
            />
        )
    }

    return (
        <Col>
            <CustomAsyncPaginate
                keyName={key + selectedFilter}
                searchOnly={true}
                loadOptions={search}
                debounceTimeout={250}
                additional={{
                    page: 1
                }}
                defaultOptions
                value={selected}
                shouldLoadMore={shouldLoadMore}
                // isDisabled={true}
                isSearchable={false}
                onChange={onSelectOption}
                noOptionsMessage={() => `No matching invites`}
                placeholder={placeholder}
                menuIsOpen={menuIsOpen}
                components={{ 
                    Option: UserInviteItem,
                    Control: CustomControl
                }}
                className={classNames('w-100', className)}
                customProps={{
                    room,
                    resetSelect,
                    invitesController,
                    canManageRoom
                }}
                customControlProps={{ renderAdditionalControls }}
                cacheUniqs={[room?.id]}
                {...otherProps}
                // menuPortalTarget={document.body}
                // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />

        </Col>
    )
})

interface SelectCustomProps {
    data: APIDataInvite,
    invitesController: RoomInvitesController,
    resetSelect: () => void,
    canManageRoom: boolean
}

const UserInviteItem = observer((props: CustomOptionProps<APIDataInvite, SelectCustomProps>) => {
    const { data, selectProps  } = props

    //console.log('Invite', data)

    const { invitesController, resetSelect, canManageRoom } = selectProps.customProps

    const [isLoading, setIsLoading] = useState(false)
    const { addToast, isConfirmed } = useUI()

    const isExistingUser = !!data.toUserId
    let user: any = {}

    if (isExistingUser) {
        user = {
            firstName: data.content?.toUserFirstName || data.content?.to || data.to,
            lastName: data.content?.toUserLastName,
            publicImage: data.toUser?.publicImage,
            publicMedia: data.toUser?.publicMedia,
            departmentName: data.content?.departmentName
        }
    } else {
        user = {
            firstName: data.to,
            lastName: ''
        }
    }

    const deleteInvite = async () => {
        try {
            const confirmed = await isConfirmed({
                message: 'Are you sure you want to cancel this invite?',
                acceptButton: {
                    label: 'Delete',
                    color: 'danger'
                }
            })
            if (!confirmed) return
    
            await invitesController.delete(data.id)
            resetSelect()
    
        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }
    }

    const status = (data.status === 'valid') ? 'pending' : (data.status === 'consumed') ? 'accepted' : data.status

    //allow text selection
    props.innerProps.onMouseDown = (e) => e.stopPropagation()
  
    return (
        <reactSelectComponents.Option {...props}>
            <div className="">
                <div className="d-flex justify-content-between align-items-start">
                    <div>
                        <strong>To: </strong>
                        <span>{user.firstName} {user.lastName}</span>
                    </div>
                    <Badge
                        color={status === 'pending' ? 'info' : status === 'accepted' ? 'success' : 'danger'}
                        // size={10}
                        className="text-capitalize"
                    >
                        {status}
                    </Badge>
                </div>
                <div className="text-muted" style={{fontSize: '0.8rem'}}>
                    {user?.departmentName && (
                        <div className="mt-1">
                            <strong>Agency:</strong>
                            <span>{user.departmentName}</span>
                        </div>
                    )}
  
                    <div className="mt-1">
                        <strong>Invited By:</strong>
                        <span> {data.content?.fromUserFirstName} {data.content?.fromUserLastName} </span>
                    </div>
  
                    <div className="mt-1">
                        <strong>Invited Role:</strong>
                        <span className="text-capitalize">{data.setConsumerRole}</span>
                    </div>
  
                    <div className="mt-1">
                        <strong>Sent On:</strong>
                        <span>{moment(data.createdTime).format('L @ H:mm')}</span>
                    </div>
  
                    {status === 'pending' && (
                        <Row align="between">
                            <div className="mt-1">
                                <strong>Expires On:</strong>
                                <span>{moment(data.expiresDate).format('L @ H:mm')}</span>
                            </div>
                            {canManageRoom && (
                                <Button
                                    color="outline-danger"
                                    className="px-2 py-1"
                                    size="sm"
                                    onClick={deleteInvite}
                                    loading={isLoading}
                                >
                                    Cancel Invite
                                </Button>
                            )}
                        </Row>
                    )}
                </div>
            </div>
        </reactSelectComponents.Option>
    )
})

export { RoomInvitesSearchSelect }

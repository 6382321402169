import { useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { observer } from 'mobx-react'

import { UIContextProvider } from '@evertel/web/ui'
import { JoinRoomContextProvider } from '@evertel/web/room'
// css
import './scss/style.scss'
// containers
import { EvertelLayout } from './containers'
// pages
import {
    Login, Page404, Page500, Register, RegisterProfile, RegisterDepartment, CrisisSplash,
    ForgotPassword, ConsumeInvite, ResetPassword, EnrollMfaInit, Version,
    CrisisNoAccess, EmailVerification, ConfirmAuth0TFA
} from './views/Pages'
// utilities
import { ToasterComponent as Toaster } from './components/Toaster'
import { SpinnerComponent as Spinner } from './components/LoadingIndicator'
//nimport { Tooltip } from 'react-tooltip'
import ErrorBoundary from './utilities/errorBoundary'
import ElectronBoundary from './utilities/ElectronBoundary'
import * as Electron from './utilities/electronInterface'

// fontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faPlusSquare, faUsers, faCheckSquare, faHandshake, faPlus, faPaperPlane, faBuilding, faEnvelope, faGauge,
    faCircleInfo, faCircleXmark, faEllipsisVertical, faLocationDot, faTableLayout, faTachometerAlt, faBell, faPaperPlaneTop,
    faCheckCircle, faDiamond, faCirclePlus, faShieldKeyhole, faCaretDown, faCaretRight, faStar, faFileAlt
} from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { faBold, faItalic, faList, faListOl, faStrikethrough, faLink, faBracketsCurly } from '@fortawesome/pro-regular-svg-icons'
import { faJira, faHubspot, faMailchimp, faApple, faAndroid } from '@fortawesome/free-brands-svg-icons'

// Dependency injection
import 'reflect-metadata'
import { ContainerProvider, useService } from '@evertel/di'
import { container as diContainer } from './di'

// UI
import { CurrentMediaPlayingProvider } from '@evertel/hooks'

// stores
import { AppStore, NavigationStore } from './stores'
import { InviteContextProvider } from '@evertel/web/invites'
import { CreateRoomContextProvider } from '@evertel/web/room'
import { UserDetailModalContextProvider } from '@evertel/web/user'
import { DeviceState } from '@evertel/device'
import { Analytics } from '@evertel/analytics'
import { CreateThreadContextProvider } from '@evertel/web/thread'
import { SessionState } from '@evertel/session'
import { ThemeState } from '@evertel/web/theme'
import { PushService } from '@evertel/push'
import { SSOAccessToken } from '@evertel/web/security'
import debugModule from 'debug'
import LoadingIndicator from './components/LoadingIndicator'

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DEBUG_WEB) {
    debugModule.enable(process.env.REACT_APP_DEBUG_WEB)
}

//import { ipcRenderer } from 'electron'

library.add(fal, faPlusSquare, faUsers, faCheckSquare, faHandshake, faPlus, faPaperPlane, faBuilding, faEnvelope, faGauge, faCircleInfo,
    faCircleXmark, faEllipsisVertical, faLocationDot, faTableLayout, faTachometerAlt, faBell, faBold, faItalic, faList, faListOl, faStrikethrough,
    faLink, faBracketsCurly, faPaperPlaneTop, faCheckCircle, faDiamond, faCirclePlus, faJira, faHubspot, faMailchimp, faApple, faAndroid, faShieldKeyhole,
    faCaretDown, faCaretRight, faStar, faFileAlt)

const App = observer(() => {
    const analytics = diContainer.get(Analytics)
    const pushService = diContainer.get(PushService)
    const deviceState = useService(DeviceState, [])
    const sessionState = useService(SessionState, [])
    const themeState = useService(ThemeState, [])

    useEffect(() => {
        //need indicator showing before anything else
        //will be disabled inside AppStore.init()
        LoadingIndicator.show('app-loading', 'full-overlay')

        //want screen to be dark or light right away
        themeState.init()

        //session state restores userID and authToken from localStorage
        sessionState.init('web')

        //device state restores deviceToken from localStorage
        deviceState.init('web')

        //app store validates authToken and turns off LoadingIndicator
        AppStore.init()

        //analytics meh
        analytics.init()

        //currentUserId must be a dependency or it won't run again after logging out,
    }, [sessionState.currentUserId])

    useEffect(() => {
        pushService.init()
        // initialize AppStore   
        Electron.wireUp()
    }, [])

    useEffect(() => {
        if (sessionState.currentUserId && pushService.notificationPermission === 'granted') {
            pushService.postPushToken()
        }
    }, [sessionState.currentUserId, pushService.notificationPermission])

    return (
        <ErrorBoundary>
            <HashRouter ref={navigatorRef => NavigationStore.setTopLevelNavigator(navigatorRef)}>
                <UIContextProvider>
                    <UserDetailModalContextProvider>
                        <InviteContextProvider>
                            <CreateThreadContextProvider>
                                <CreateRoomContextProvider>
                                    <JoinRoomContextProvider>
                                        <CurrentMediaPlayingProvider>
                                            <ElectronBoundary>
                                                <Spinner name="page-loading"></Spinner>
                                                <Spinner name="app-loading"></Spinner>
                                                <Toaster name="mainToaster" position="top-right" autoClose={5000} />
                                                { !AppStore.appLoading &&
                                                <Switch>
                                                    <Route exact path="/login" name="Login" component={Login} />
                                                    <Route exact path="/login/forgotpassword" name="Forgot Password" component={ForgotPassword} />
                                                    <Route exact path="/login/crisissplash" name="Responding to a Crisis Event" component={CrisisSplash} />
                                                    <Route exact path="/register" name="Register Page" component={Register} />
                                                    <Route exact path="/register/profile" name="Register Profile" component={RegisterProfile} />
                                                    <Route exact path="/register/department" name="Register Department" component={RegisterDepartment} />
                                                    <Route exact path="/register/crisisnoaccess" name="Crisis No Access" component={CrisisNoAccess} />
                                                    <Route exact path="/404" name="Page 404" component={Page404} />
                                                    <Route exact path="/500" name="Page 500" component={Page500} />

                                                    <Route exact path="/invite/:token" name="Consume Invite" component={ConsumeInvite} />
                                                    <Route exact path="/email-verification/:token?" name="Verify Your Email" component={EmailVerification} />
                                                    <Route exact path="/reset-password/:token" name="Reset Password" component={ResetPassword} />
                                                    <Route exact path="/enroll-mfa/:token" name="Enroll Mfa" component={EnrollMfaInit} />
                                                    <Route exact path="/two-factor/" name="Two Factor Security" component={ConfirmAuth0TFA} />
                                                    <Route exact path="/ver" name="Version" component={Version} />

                                                    <Route exact path="/access_token=:params" name="SSO Access Token" component={SSOAccessToken} />
                                                    <Route path="/" name="Home" component={EvertelLayout} />
                                                </Switch>
                                                }
                                            </ElectronBoundary>
                                        </CurrentMediaPlayingProvider>
                                    </JoinRoomContextProvider>
                                </CreateRoomContextProvider>
                            </CreateThreadContextProvider>
                        </InviteContextProvider>
                    </UserDetailModalContextProvider>
                </UIContextProvider>
            </HashRouter>
        </ErrorBoundary>
    )
})

const WrappedApp = observer((props) => {
    return (
        <ContainerProvider value={diContainer}>
            <App {...props} />
        </ContainerProvider>
    )
})

export default WrappedApp

import React, { forwardRef, useEffect, useState, useCallback, useRef, AudioHTMLAttributes } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import classNames from 'classnames'

import Waveform from 'react-audio-waveform'
import { getAudioPeaks } from '@evertel/utils'
import { useCurrentMediaPlaying, useForkedRef } from '@evertel/hooks'
import { Api } from '@evertel/api'
import { useService } from '@evertel/di'
import { Col, Row } from '../layout'
import { Spinner } from './Spinner'
import { Text } from './Text'
import { Icon } from './Icon'
import { err } from 'react-native-svg/lib/typescript/xml'

interface HTMLMediaElementWithCaptureStream extends HTMLMediaElement{
    captureStream(): MediaStream;
}
export interface AudioProps extends AudioHTMLAttributes<HTMLMediaElementWithCaptureStream | HTMLDivElement> {
    url: string,
    fileName?: string,
    fileSize?: string | number,
    fileDuration?: number,
    controls?: boolean,
    width?: number,
    height?: number,
    rounded?: boolean,
    variant?: 'tile' | 'file-list',
    fit?: 'contain' | 'cover' | 'fill' | 'scale' | 'none' | 'scale-down' | 'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset',
}

const AudioTile = observer(forwardRef<HTMLAudioElement, AudioProps>(({
    url,
    fileName = '',
    fileSize,
    fileDuration = 0,
    controls = true,
    preload = 'auto',
    width = 350,
    height = 50,
    className,
    fit,
    rounded = true,
    variant = 'tile',
    ...otherProps
}, ref) => {

    const validWidth = typeof width === 'number' && !isNaN(width) ? width : 350
    const validHeight = typeof height === 'number' && !isNaN(height) ? height : 50

    const api = useService(Api, [])
    const { currentMediaPlaying, setCurrentMediaPlaying } = useCurrentMediaPlaying()

    const player = useRef<HTMLMediaElementWithCaptureStream | null>(null)
    const forkedRef = useForkedRef(ref, player)
    const timeline = useRef(null)

    const [audioCtx, setAudioCtx] = useState<AudioContext | false>(false)
    const [playing, setPlaying] = useState(false)
    const [canPlay, setCanPlay] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(fileDuration)
    const [peaksData, setPeaksData] = useState<Float32Array>()
    const [fakePeaksData, setFakePeaksData] = useState<Float32Array>()
    const [playbackRate, setPlaybackRate] = useState(1)
    const [error, setError] = useState(false)

    const playbackRates = [1, 1.25, 1.5, 2, 3]

    useEffect(() => {
        const audioCtx = getContext()
        setAudioCtx(audioCtx)

        if (!duration && fileDuration) setDuration(fileDuration)

        // gets audio file duration once the file has fully downloaded
        player.current?.addEventListener('canplaythrough', handleCanPlayThrough)

        if (player.current?.readyState >= 3 || preload === 'none') { // HAVE_FUTURE_DATA or higher
            //in case canplay event already triggered
            //if we don't preload, then canPlay will never trigger until they press play
            handleCanPlay()
            if (preload === 'none') {
                setFakePeaksData(generateFakePeaksData(url))
            }

        }
        // hides spinner once enough has downloaded to play
        player.current?.addEventListener('canplay', handleCanPlay)

        // fires when playback position has changed
        player.current?.addEventListener('timeupdate', updateTime)

        player.current?.addEventListener('error', (e) => {
            setError(true)
        })

        return () => {
            // clean up listeners on unmount
            player.current?.removeEventListener('canplay', handleCanPlay)
            player.current?.removeEventListener('timeupdate', updateTime)
            player.current?.removeEventListener('canplaythrough', handleCanPlayThrough)
        }
    }, [player.current, duration])

    useEffect(() => {
        // if other media begins to play, pause this
        if (!currentMediaPlaying || currentMediaPlaying === url || !playing) {
            return
        }
        onPlay()

    }, [currentMediaPlaying])

    useEffect(() => {
        if (!player.current) return
        player.current.playbackRate = playbackRate
    }, [playbackRate])

    const handlePlaybackRateChange = () => {
        const currentIndex = playbackRates.indexOf(playbackRate)
        const nextIndex = (currentIndex + 1) % playbackRates.length
        setPlaybackRate(playbackRates[nextIndex])
    }

    const handleCanPlay = () => {
        setCanPlay(true)
    }

    const handleCanPlayThrough = () => {
        if (!peaksData?.length && audioCtx) {
            startWaveformCreation()
        }
        setDuration(player.current?.duration)
    }

    const updateTime = useCallback(() => {
        setCurrentTime(player.current?.currentTime)

        // stop if we have reached the end
        if (player.current?.currentTime >= player.current?.duration) {
            setPlaying(false)
        }
    }, [player.current])

    const onPlay = (): void => {
        if (player.current?.paused) {
            player.current.play()
            setPlaying(true)

            // set context to inform other media players
            setCurrentMediaPlaying(url)
        } else {
            player.current?.pause()
            setPlaying(false)
        }
    }

    /**
     * When the waveform is clicked, this moves the time to that position
     */
    const handleWaveformClick = (time) => {
        //sets the player to play at the time the user clicked
        if (player.current) {
            player.current.currentTime = time
            setCurrentTime(time)
        }
    }

    //===================================
    // WAVEFORM HELPERS
    //===================================

    const getContext = () => {
        if (window.AudioContext) {
            const audioCtx = new AudioContext()
            return audioCtx
        } else {
            return false
        }
    }

    const startWaveformCreation = async () => {
        if (!player.current) {
            //technically this shouldn't be hit
        } else {
            extractAudioDataFromUrl(url)
        }
        //if we want to use waveform in the future
        // if (player.current?.captureStream) {
        //     extractAudioDataFromElement()
        // }
    }

    /**
     * Attempts to get audio stream from element instead of downloading it again
     * This actually is only useful for creating a waveform, not a volume peaks graph
     */
    // const extractAudioDataFromElement = async () => {
    //     if (!audioCtx || !player.current) return
        
    //     try {

    //         const analyser = audioCtx.createAnalyser()
    //         const stream = player.current.captureStream()
    //         const mediaStreamSource = audioCtx.createMediaStreamSource(stream)
    //         mediaStreamSource.connect(analyser)

    //         const bufferLength = analyser.fftSize
    //         const dataArray = new Float32Array(bufferLength)
    //         analyser.getFloatTimeDomainData(dataArray)
    //         // Generate peaks data
    //         const peaksData = getAudioPeaks(dataArray, bufferLength / 100, true)
    //         setPeaksData(peaksData.data[0])

    //     } catch (err) {
    //         extractAudioDataFromUrl(url)
    //         return
    //     }
    // }

    const extractAudioDataFromUrl = async (path: string) => {
        if (!audioCtx) return

        const buffer = await getAudioBuffer(path, audioCtx) as []
        const peaksData = getAudioPeaks(buffer, buffer?.length / 100, true)

        setPeaksData(peaksData.data[0])
    }

    const getAudioBuffer = async (path: string, audioCtx: AudioContext) => {
        const response = await api.agent.getAsRaw(path, { responseType: 'arraybuffer' })
        const audioData = await response.data

        return new Promise((resolve, reject) => {
            audioCtx.decodeAudioData(audioData, buffer => {
                return resolve(buffer)
            })
        })
    }

    return (
        <Row
            className={classNames('audio-player', className)}
            valign="center"
            style={{ width: validWidth, height: validHeight }}
            {...otherProps}>
            {(url) &&
                <>
                    <audio
                        ref={forkedRef}
                        preload={preload}
                        crossOrigin='use-credentials'
                    >
                        <source
                            src={url}
                        />
                    </audio>
                    <Col style={{ flexGrow: 0 }}
                        className="p-0 mr-3">
                        {(canPlay) &&
                            <button
                                className={(playing) ? 'pause' : 'play'}
                                onClick={onPlay}
                            ><span className="visually-hidden">{playing ? 'pause' : 'play'}</span></button>
                        }
                        {(!canPlay && !error) && <Spinner />}
                        {(error) && <Icon name="cloud-exclamation" color="muted" />}
                    </Col>
                    <Col className="p-0 pt-1">
                        <Waveform
                            ref={timeline}
                            onClick={handleWaveformClick}
                            barWidth={2}
                            peaks={peaksData || fakePeaksData}
                            height={validHeight - 10}
                            pos={currentTime}
                            duration={duration}
                            style={{ cursor: 'col-resize' }}
                            color="#acb4bc"
                            progressGradientColors={[[0, '#20a8d8'], [1, '#ffc107']]}
                        />
                    </Col>
                    <Col style={{ flexGrow: 0 }}
                        className="p-0 ml-1">
                        <div
                            className="duration">
                            {moment(currentTime * 1000).format('m:ss')} / {moment(duration * 1000).format('m:ss')}
                        </div>
                        {(player.current && !player.current.paused && duration > 10) &&
                            <div
                                className="audio-speed"
                                onClick={handlePlaybackRateChange}>
                                {playbackRate}X
                            </div>
                        }
                    </Col>
                </>
            }
        </Row >
    )
}
))

function generateFakePeaksData(url: string) {
    // Extract the filename from the URL
    const filename = url.substring(url.lastIndexOf('/') + 1)

    // Convert filename characters to numbers
    const charCodes = Array.from(filename).map(char => char.charCodeAt(0))

    // Generate Float32Array data
    const length = 100  // Define the length of the array
    const floatArray = new Float32Array(length)

    // Generate wave pattern with variability
    for (let i = 0; i < length; i++) {
        let sum = 0
        for (let j = 0; j < charCodes.length; j++) {
            // Vary the frequency and amplitude based on character codes
            const frequency = 0.1 + (charCodes[j] % 10) * 0.01
            const amplitude = 1 + (charCodes[j] % 5) * 0.2
            sum += amplitude * Math.sin((i + charCodes[j]) * frequency)
        }
        // Add some random noise
        // sum += Math.random() * 0.2 - 0.1
        floatArray[i] = sum / charCodes.length // Normalize the sum
    }
    console.log(url, floatArray)
    return floatArray
}

export { AudioTile }

import { ContainerModule } from '@evertel/di'
import { CurrentUserEmojisController, DepartmentEmojisController } from './controller'

const DI_MODULES = [
    new ContainerModule((bind) => {    
        bind(CurrentUserEmojisController).toSelf()
        bind(DepartmentEmojisController).toSelf()
    })
]

export { DI_MODULES }

import { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import api from '../api'
import Waveform from 'react-audio-waveform'
import extractPeaks from '../utilities/extractAudioPeaks'
import { AnalyticsStore, DeviceStore } from '../stores'
import { Spinner, Icon } from '.'

const propTypes = {
    data: PropTypes.object.isRequired,
    showPlayBackRate: PropTypes.bool
}

const defaultProps = {
    showPlayBackRate: true
}

//============================================================================================================
class SecuredAudio extends Component {

    _isMounted = false // used to prevent setState on asynchronous requests on unmounted components

    state = {
        source: '',
        mediaWidth: '100%',
        loadingMsg: 'Loading audio...',
        error: false,
        duration: 0, // duration of track
        currentTime: 0, // current playback position
        canPlay: false, // set to true when enough of the file has downloaded to play
        peaksData: [], // holds audio peaks data for waveform,
        playbackRate: 1 // playback rate of audo track. 1 = normal speed
    }

    //=============================================
    // LIFECYCLE METHODS
    //=============================================

    componentDidMount = () => {
        this._isMounted = true

        const audioCtx = this.getContext()
        this.setState({ audioCtx })

        // convert file to blob to hide URL
        this.setBlob()
    }

    componentDidUpdate = (prevProps) => {
        if ((prevProps.data.url !== this.props.data.url)) {
            this.setBlob()
        }
        if (this.player && !this.state.listenersAdded) {
            this.setEventListeners()
        }
        // stop playing media if prop is set (used to stop players when another player starts)
        if (this.player && DeviceStore.currentMediaFilePlaying && (DeviceStore.currentMediaFilePlaying.id !== this.props.data.id)) {
            if (!this.player.paused) {
                this.play() // this will pause player if it's playing
            }
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false

        if (this.player) {
            this.player.removeEventListener('canplaythrough', this.setDuration)
            this.player.removeEventListener('canplay', this.fileReady)
            this.player.removeEventListener('timeupdate', this.timeUpdate)
        }
    }

    //=============================================
    // AUDIO HELPERS
    //=============================================

    setBlob = () => {
        const { data } = this.props

        if (!data.isBlob && !data?.url?.startsWith('blob:')) {
            api.agent.get(data.url, { responseType: 'blob' })
                .then(response => {
                    if (this._isMounted) {
                        // set source to blobified data to hide real URL
                        this.setState({ source: URL.createObjectURL(response) })
                    }
                })
                .catch(error => {
                    if (this._isMounted) {
                        console.log('Error Retrieving Audio (' + error.message + ')')
                        this.setState({
                            loadingMsg: 'Unable to load this audio file',
                            error: true
                        })
                    }
                })
            // now set event listeners
            this.setEventListeners()
        } else {
            if (this._isMounted) {
                // already a blob (new posts use blob)
                this.setState({ source: data.url })
                this.setEventListeners()
            }
        }
    }

    setEventListeners = () => {
        if (this.player && this._isMounted) {
            // gets audio file duration once the file has fully downloaded
            this.player.addEventListener('canplaythrough', this.setDuration)

            // hides spinner once enough has downloaded to play
            this.player.addEventListener('canplay', this.fileReady)

            // fires when playback position has changed
            this.player.addEventListener('timeupdate', this.timeUpdate)

            this.setState({ listenersAdded: true })
        }
    }

    //===================================
    // LISTENER CALLBACKS
    //===================================

    setDuration = () => {
        if (this.state.peaksData.length === 0 && this.state.audioCtx) {
            this.getFile(this.state.source)
        }
        if (this._isMounted) this.setState({ duration: this.player.duration })
    }

    fileReady = () => {
        if (this._isMounted) this.setState({ canPlay: true })
    }

    //===================================
    // WAVEFORM HELPERS
    //===================================

    getContext = () => {
        window.AudioContext =
            window.AudioContext ||
            window.webkitAudioContext ||
            window.mozAudioContext ||
            window.oAudioContext

        if (window.AudioContext) {
            const audioCtx = new AudioContext()
            return audioCtx
        } else {
            return false
        }
    }

    getFile = async (path) => {
        const buffer = await this.getAudioBuffer(path, this.state.audioCtx)
        //const audioData = buffer.getChannelData(0)
        const peaksData = extractPeaks(buffer, buffer.length / 64, true)
        if (this._isMounted) this.setState({ peaksData: peaksData.data[0] })
    }

    getAudioBuffer = async (path, audioCtx) => {
        const response = await fetch(path)
        const audioData = await response.arrayBuffer()
        return new Promise((resolve, reject) => {
            audioCtx.decodeAudioData(audioData, buffer => {
                return resolve(buffer)
            })
        })
    }

    //===================================
    // PLAYER ACTIONS
    //===================================

    play = () => {
        // start audio
        if (this.player.paused) {
            DeviceStore.setCurrentMediaFilePlaying(this.props.data) // this tells all other media to stop

            const playPromise = this.player.play()

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        // Automatic playback started!
                        this.playBttn.className = 'pause'
                    })
                    .catch(error => {
                        console.log('playback prevented: ', error)
                    })
            }

            // log event to GA
            AnalyticsStore.logEvent({
                category: 'User',
                action: 'play_audio'
            })
        } else { // pause audio
            this.player.pause()
            this.playBttn.className = 'play'
        }
    }

    setCurrentTime = (secs) => {
        this.player.currentTime = secs
    }

    // synchronizes playhead position with current point in audio
    timeUpdate = () => {
        if (this._isMounted) {
            this.setState({ currentTime: this.player.currentTime })

            // stop if we have reached the end
            if (this.player.currentTime === this.state.duration) {
                this.playBttn.className = 'play'
                this.player.playbackRate = 1
                this.setState({ playbackRate: 1 })
            }
        }
    }

    setPlaybackRate = () => {
        if (this._isMounted) {
            let playbackRate = this.state.playbackRate
            if (playbackRate === 4) {
                playbackRate = 1
            } else {
                playbackRate = playbackRate * 2
            }
            this.setState({
                playbackRate: playbackRate
            })
            this.player.playbackRate = playbackRate
        }
    }

    //===================================
    // HELPERS
    //===================================

    // returns click as decimal (.77) of the total timelineWidth
    clickPercent = (event) => {
        const timelineWidth = this.getTimelineWidth()
        return (event.clientX - this.getPosition(this.timeline)) / timelineWidth
    }
    // returns elements left position relative to top-left of viewport
    getPosition = (el) => {
        return el.getBoundingClientRect().left
    }
    // returns the timeline (scrubBar) width
    getTimelineWidth = () => {
        return this.timeline.offsetWidth
    }


    //-----------------------------------------------------------------------------------------------------------
    render() {
        const { source, canPlay, error } = this.state

        return (
            <div className="audio" {...this.props.otherProps}>
                {source &&
                    <audio ref={ref => this.player = ref} preload="true">
                        <source src={source} />
                    </audio>
                }
                <div ref={ref => this.playerUI = ref} className="audio-player">
                    {(!source) &&
                        <div className="w-100 text-muted">
                            <em>
                                {(!error) ?
                                    <Spinner />
                                    :
                                    <Icon name="frown" style={{fontSize: '1.5rem'}} />
                                } {this.state.loadingMsg}</em>
                        </div>
                    }
                    {source &&
                        <div className="w-100">
                            <div className="play-bttn float-left">
                                {canPlay &&
                                    <button
                                        ref={ref => this.playBttn = ref}
                                        className="play"
                                        onClick={this.play}
                                    />
                                }
                                {!canPlay && <Spinner />}
                            </div>
                            <div className="timeline float-left w-75 px-2">
                                <Waveform
                                    ref={ref => this.timeline = ref}
                                    onClick={this.setCurrentTime}
                                    barWidth={1}
                                    peaks={this.state.peaksData}
                                    height={40}
                                    pos={this.state.currentTime}
                                    duration={this.state.duration}
                                    style={{cursor: 'col-resize'}}
                                    color="#acb4bc"
                                    progressGradientColors={[[0, '#20a8d8'], [1, '#ffc107']]}
                                />
                            </div>
                            <div className="float-right">
                                <div ref={ref => this.time = ref} className="duration">
                                    {moment(this.state.currentTime * 1000).format('m:ss')} / {moment(this.state.duration * 1000).format('m:ss')}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {(this.player && this.props.showPlayBackRate && !this.player.paused && this.state.duration > 10) &&
                    <div className="audio-speed" onClick={this.setPlaybackRate}>{this.state.playbackRate}X</div>
                }
            </div>
        )
    }
}

SecuredAudio.propTypes = propTypes
SecuredAudio.defaultProps = defaultProps
export default SecuredAudio

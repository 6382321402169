import { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalHeader } from '@evertel/web/ui'
import classNames from 'classnames'
// evertel
import { Button, Icon } from '.'

const propTypes = {
    type: PropTypes.oneOf(['block', 'overlay', 'modal']),
    className: PropTypes.string,
    children: PropTypes.node,
    isOpen: PropTypes.bool, //used for overlay and modal
    modalHeaderTitle: PropTypes.string
}

const defaultProps = {
    type: 'block',
    isOpen: false
}

const Notice = ({ type, isOpen, modalHeaderTitle, children, className, ...otherProps }) => {

    const [isNoticeOpen, setIsNoticeOpen] = useState(isOpen)

    const toggleNotice = () => {
        setIsNoticeOpen(!isNoticeOpen)
    }

    if (type === 'block' && isNoticeOpen) {
        return (
            <div className={classNames('fadeIn', { [className]: className })} {...otherProps}>
                <Button
                    className="float-right m-2"
                    onClick={toggleNotice}>
                    <Icon name="times" />
                </Button>
                {children}
            </div>
        )
    } else if (type === 'modal') {
        return (
            <Modal
                visible={isNoticeOpen}
                onClose={toggleNotice}
                className={classNames({ [className]: className })}>
                <ModalHeader>
                    {modalHeaderTitle}
                </ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
            </Modal>
        )
    } else {
        // future expansion, overlays, etc
        return null
    }
}

Notice.propTypes = propTypes
Notice.defaultProps = defaultProps
export default Notice

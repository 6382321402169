/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment/moment'
import {
    Document as PDFDocument,
    Page as PDFPage,
    Text as PDFText,
    View, StyleSheet
} from '@react-pdf/renderer'
import { ServiceProvider } from '@evertel/service'
import { Document, WidgetsProvider } from '@evertel/schema-parser'
import { ContainerProvider, Container } from '@evertel/di'
import { ReadWidgets as ReadPdfWidgets } from './schema-pdf-widgets/ReadWidgets'
import { APIDataDocument, APIDataDocumentSchema } from '@evertel/types'
import DepartmentHeader from './schema-pdf-widgets/DepartmentHeader'

interface DocumentPDFProps {
    user: number,
    departmentId: number,
    document: APIDataDocument,
    value: any,
    schema: APIDataDocumentSchema,
    container: Container,
    canEdit?: boolean
}

const DocumentPDF: React.FC<DocumentPDFProps> = observer(({
    user,
    departmentId,
    document,
    schema,
    value,
    container,
    canEdit
}) => {

    const styles = StyleSheet.create({
        section: {
            
        },
        metaStyle: {
            fontSize: 10,
            color: 'gray'
        },
        footer: {
            position: 'absolute',
            fontSize: 8,
            bottom: 0,
            right: 0,
            left: 0,
            height: 30,
            textAlign: 'center',
            color: '#666666'
        },
        docName: {
            position: 'absolute',
            left: 20
        },
        pageNumbers: {
            position: 'absolute',
            right: 20
        }
    })
    // @ts-ignore
    if (!canEdit || !document || !schema) return <PDFDocument/>

    return (
        // @ts-ignore
        <PDFDocument
            title={`${document.name || 'EverDoc'}-${schema.name}.pdf`}
            creator="Evertel">
            {/* @ts-ignore */}
            <PDFPage
                wrap
                size="LETTER"
                style={{
                    padding: 20,
                    paddingBottom: 70,
                    fontSize: 11
                }}>
                <ContainerProvider value={container}>
                    <ServiceProvider services={[{
                        id: 'meta',
                        value: {
                            user: user,
                            department: departmentId,
                            document: document.id,
                            documentSchema: schema.id
                        }
                    }]}>
                        {/* @ts-ignore */}
                        <View style={styles.section}>
                            <DepartmentHeader departmentId={departmentId}/>
                            <WidgetsProvider value={ReadPdfWidgets}>
                                <Document
                                    layout="full"
                                    schema={schema.schema as { layouts: Record<string, any>, id?: number }}
                                    schemaId={schema.id}
                                    value={value}
                                />
                                {/* <PDFText style={styles.metaStyle}>
                                    Last update date: {moment(document.updatedDate).format('LL')}
                                </PDFText>
                                <PDFText style={styles.metaStyle}>
                                    Made in Evertel
                                </PDFText> */}
                            </WidgetsProvider>
                        </View>
                    </ServiceProvider>
                    {/* @ts-ignore */}
                    <View
                        fixed
                        style={styles.footer}>
                        {/* @ts-ignore */}
                        <PDFText style={styles.docName}>
                            {`${document.name || 'EverDoc'}-${schema.name}.pdf`} | {moment().format('LL')}
                        </PDFText>
                        {/* @ts-ignore */}
                        <PDFText
                            style={styles.pageNumbers}
                            render={({ pageNumber, totalPages }) => (
                                (totalPages > 1) && `${pageNumber} / ${totalPages}`
                            )}
                        />
                    </View>
                </ContainerProvider>
            </PDFPage>
        </PDFDocument>
    )
})

export {
    DocumentPDF
}
import { Api } from '@evertel/api'
import { BlueUserStore } from '@evertel/blue-user'
import { DeviceState } from '@evertel/device'
import { PushService } from '@evertel/push'
import { SessionState } from '@evertel/session'
import { decorate, inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'



class HeaderController {
    pushNotificationPermission? = ''
    showAlert = true
    isCrisis = false

    constructor(
        private api: Api,
        private session: SessionState,
        private device: DeviceState,
        private userStore: BlueUserStore,
        private pushService: PushService
    ) {
        makeAutoObservable(this)
    }

    init = async () => {
        const permission = await this.pushService.getCurrentNotificationPermission()

        runInAction(() => {
            this.pushNotificationPermission = permission
            if (localStorage.getItem('crisis')) {
                this.isCrisis = true
            }
        })
    }

    clearCrisisQueryString = () => {
        localStorage.removeItem('crisis')
    }


    allowedToPrompt = () => {
        return !localStorage.getItem('notPerm')
    }

    promptSystemNotifications = async () => {
        this.pushNotificationPermission = await this.pushService.promptForNotificationPermission()
    }

    getDeviceInfo = () => {
        const currentUser = this.userStore.findById(this.session.currentUserId)

        return {
            CurrentUser: {
                id: this.session.currentUserId,
                fullName: currentUser?.firstName + ' ' + currentUser?.lastName
            },
            Device: {
                agent: this.device.agent,
                platform: this.device.platform,
                device: this.device.device,
                deviceId: this.device.deviceId,
                os: this.device.os
            },
            Session: {
                navigationHistory: this.session.navigationHistory
            }
        }
    }

    sendFeedback = async (message: string, data: object) => {
        await this.api.Routes.App.postFeedback(message, data)
    }

    setShowAlert = (option: boolean) => {

        runInAction(() => {
            this.showAlert = option
        })
    }
}

decorate(injectable(), HeaderController)
decorate(inject(Api), HeaderController, 0)
decorate(inject(SessionState), HeaderController, 1)
decorate(inject(DeviceState), HeaderController, 2)
decorate(inject(BlueUserStore), HeaderController, 3)
decorate(inject(PushService), HeaderController, 4)

export { HeaderController }
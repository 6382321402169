import React, { forwardRef, HTMLAttributes, useEffect, useState, useRef, ReactElement } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

export interface ToastContainerProps extends HTMLAttributes<HTMLDivElement> {
    className?: string
    placement?: 'top-start'|'top-center'|'top-end'|'middle-start'|'middle-center'|'middle-end'|'bottom-start'|'bottom-center'|'bottom-end'|string
    /** Adds new `Toast` to `Toaster`. */
    push?: ReactElement
}

const ToastContainer = forwardRef<HTMLDivElement, ToastContainerProps>(({
    children,
    className,
    placement,
    push,
    ...otherProps
}, ref) => {

    const [toasts, setToasts] = useState<ReactElement[]>([])
    const index = useRef<number>(0)

    useEffect(() => {
        index.current++
        if (push) addToast(push)
    }, [push])

    const addToast = (push: ReactElement) => {
        setToasts((state) => [
            ...state,
            React.cloneElement(push, {
                index: index.current,
                key: index.current,
                onClose: (index: number) => setToasts((state) => state.filter((i) => i.props.index !== index))
            })
        ])
    }

    const _className = classNames(
        'toaster toast-container p-3',
        {
            'position-fixed': placement,
            'top-0': placement && placement.includes('top'),
            'top-50 translate-middle-y': placement && placement.includes('middle'),
            'bottom-0': placement && placement.includes('bottom'),
            'start-0': placement && placement.includes('start'),
            'start-50 translate-middle-x': placement && placement.includes('center'),
            'end-0': placement && placement.includes('end'),
        },
        className
    )

    const toaster = (ref?: React.Ref<HTMLDivElement>) => {
        return toasts.length > 0 || children ? (
            <div
                ref={ref}
                className={_className}
                {...otherProps}>
                {children}
                {toasts.map((toast) => React.cloneElement(toast, { visible: true }))}
            </div>
        ) : null
    }

    return (typeof window !== 'undefined' && placement)
      ? createPortal(toaster(ref), document.body)
      : toaster(ref)
})

export default ToastContainer
ToastContainer.displayName = 'ToastContainer'

import React, { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import {
    PageBody, Row, Col,
    Icon, Badge, Text, Input, Label, Button,
    CardHeaderActions, Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody, useUI, TanTable
} from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { SchemaCategoryController, SchemaCategorySearchController } from '@evertel/document'
import { APIDataDocumentSchemaCategory } from '@evertel/types'
import { SessionState } from '@evertel/session'
import { ColumnDef } from '@tanstack/react-table'

const SchemaCategories = observer(() => {

    const session = useService(SessionState, [])

    const searchController = useService(SchemaCategorySearchController, [session?.selectedDepartmentId])
    const categoryController = useService(SchemaCategoryController, [session?.selectedDepartmentId])

    const { addToast, isConfirmed } = useUI()

    const [selectedCategory, setSelectedCategory] = useState<APIDataDocumentSchemaCategory>({ name: '' })
    const [showCategoryFormModal, setShowCategoryFormModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // initialize
        searchController.init(session?.selectedDepartmentId || 0)
        categoryController.init(session?.selectedDepartmentId || 0)
    }, [session?.selectedDepartmentId])

    useEffect(() => {
        searchController.debouncedSearch()
    }, [searchController, searchController.limit, searchController.page, searchController.sortBy, searchController.sortOrder])

    const onSelectCategory = (category: APIDataDocumentSchemaCategory) => {
        setSelectedCategory(category)
        setShowCategoryFormModal(true)
    }

    const onClickCreateCategory = () => {
        setSelectedCategory({ name: '' })
        setShowCategoryFormModal(true)
    }

    const onSave = async () => {
        try {
            setIsLoading(true)

            if (selectedCategory?.id) {
                await categoryController.update(selectedCategory)
            } else {
                await categoryController.add(selectedCategory.name as string)
            }
            searchController.resetMeta()
            searchController.debouncedSearch()

            setIsLoading(false)
        } catch (error: any) {
            setIsLoading(false)
            addToast({
                color: 'danger',
                title: 'Oops!',
                message: error.message
            })
        }
        setShowCategoryFormModal(false)
    }

    const onDelete = async (id: number) => {
        const deleteIt = async () => {
            try {
                await categoryController.delete(id)
                searchController.resetMeta()
                searchController.debouncedSearch()
            } catch (error: any) {
                addToast({
                    color: 'danger',
                    title: 'Oops!',
                    message: error.message
                })
            }
        }

        const confirmed = await isConfirmed({
            title: 'Delete Category?',
            message: 'Are you sure you want to delete this category? This category will also be removed from any templates assigned to it.',
            acceptButton: {
                label: 'Delete',
                color: 'danger'
            }
        })
        if (confirmed) {
            deleteIt()
        }
    }

    const columns = useMemo<ColumnDef<APIDataDocumentSchemaCategory>[]>(() => [
        {
            accessorKey: 'name',
            header: 'Category Name',
            enableSorting: true,
            cell: ({ row }) => (
                <Text
                    onClick={() => onSelectCategory(row.original)}
                    color="secondary"
                    bold
                >
                    {row.original.name}
                </Text>
            )
        },
        {
            accessorKey: 'createdDate',
            header: 'Created Date',
            enableSorting: true,
            cell: ({ getValue }) => (
                <Text tag="span" color="muted">
                    {moment(getValue() as string).format('L')}
                </Text>
            )
        },
        {
            id: 'actions',
            cell: ({ row }) => (
                <Button
                    color="danger"
                    onClick={() => onDelete(row.original.id as number)}
                    ghost
                    size="sm"
                >
                    <Icon name="xmark" color="muted" />
                </Button>
            ),
            meta: { align: 'right' }
        }
    ], [])

    return (
        <PageBody>
            <Card>
                <CardHeader className='flex-wrap'>
                    <Row className='mb-2'>
                        <Text tag="h3" className="float-left">
                            <Icon name="sitemap" size={20} className="mr-2" />
                                Manage EverDoc Template Categories
                        </Text>
                        {!!searchController.categoriesCount && (
                            <Badge color="gray" className="ml-2">
                                {searchController.categoriesCount}
                            </Badge>
                        )}
                    </Row>
                    <CardHeaderActions className='text-nowrap'>
                        <Button color="success" onClick={onClickCreateCategory}>
                                    Create Category
                        </Button>
                    </CardHeaderActions>
                </CardHeader>
                <CardBody>
                    <TanTable
                        remote={true}
                        data={searchController.categories}
                        rowCount={searchController.categoriesCount}
                        columns={columns}
                        loading={searchController.isLoading}
                        options={{
                            pageSize: searchController.limit,
                            pageNumber: searchController.page,
                            sortName: searchController.sortBy,
                            sortOrder: searchController.sortOrder,
                            onPageChange: (page) => searchController.setPage(page),
                            onPageSizeChange: (pageSize) => searchController.setLimit(pageSize),
                            onSortChange: (sortName, sortOrder) => searchController.setSorting(sortName, sortOrder)
                        }}
                    />
                </CardBody>
            </Card>

            <Modal
                visible={showCategoryFormModal}
                onClose={() => setShowCategoryFormModal(false)}
                size="md"
            >
                <ModalHeader
                    title={!selectedCategory?.id ? 'Create a Category' : 'Edit Category'}
                />
                <ModalBody>
                    <Label text="Category Name" htmlFor="categoryName" />
                    <Input
                        name="categoryName"
                        value={selectedCategory?.name || ''}
                        onChange={(e) => setSelectedCategory((prev) => ({
                            ...prev,
                            name: e.target.value
                        }))}
                    />
                    <Button
                        color="success"
                        className="mt-4"
                        loading={isLoading}
                        onClick={onSave}
                    >
                        Save
                    </Button>
                </ModalBody>
            </Modal>

        </PageBody>
    )
})

export { SchemaCategories }
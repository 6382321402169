import { observable, action, computed, makeObservable, runInAction} from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { DocumentSchemaStore } from '@evertel/stores'
import { DepartmentStore } from '@evertel/department'
import { Api } from '@evertel/api'
import { APIDataDepartment } from '@evertel/types'

class DocumentSchemaPickerController {
    _loaded = false
    // Selected document schema
    selectedId: number
    // All document schemas
    schemaIds!: Set<number>

    constructor(
        private api: Api,
        private documentSchemaStore: DocumentSchemaStore,
        private departmentStore: DepartmentStore
    ) {
        makeObservable(this, {
            _loaded: observable,
            loaded: computed,
            schemas: computed,
            selectedId: observable,
            schemaIds: observable
        })
    }

    get loaded() {
        return this._loaded
    }
    set loaded(val) {
        this._loaded = val
    }

    fetchSchemas = async (departmentId?: number) => {
        
        this.loaded = false

        const documentSchemas = await this.api.Routes.Document.getSearchDocumentSchema({
            where: { departmentId },
            include: [{'relation': 'department'}]
        })

        this.documentSchemaStore.update(documentSchemas)

        this.departmentStore.update(
            documentSchemas.map(({department}) => {
                return department as APIDataDepartment
            })
        )

        const newSchemaIds = new Set(documentSchemas.map(({id}) => id) as number[])

        runInAction(() => {
            this.loaded = true
            this.schemaIds = newSchemaIds
        })
    }

    get schemas() {
        return this.documentSchemaStore.findByIds([...this.schemaIds])
    }

    setSelectedId = (id: number) => {
        this.selectedId = id
    }
}

decorate(injectable(), DocumentSchemaPickerController)
decorate(inject(Api), DocumentSchemaPickerController, 0)
decorate(inject(DocumentSchemaStore), DocumentSchemaPickerController, 1)
decorate(inject(DepartmentStore), DocumentSchemaPickerController, 2)

export {
    DocumentSchemaPickerController
}

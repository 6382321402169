import React, { forwardRef, HTMLAttributes, useEffect, useState } from 'react'
import classNames from 'classnames'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { Icon } from '@evertel/web/ui'

export type RoomOptions = {
    autoAddDepartmentMembers: boolean
    departmentManagersCanReadMessages: boolean
    departmentMembersCanJoinAsMember: boolean
    departmentMembersCanReadMessages: boolean
    detectScreenshots: boolean
    roomMembersCanLeave: boolean
    roomMembersCanMessage: boolean
}

interface RoomTypeIconProps extends HTMLAttributes<HTMLDivElement> {
    roomOptions?: RoomOptions,
    isSearchable?: boolean,
    color?: string,
    size?: number,
    className?: string
}

const RoomTypeIcon = forwardRef<SVGSVGElement, RoomTypeIconProps>(({
    roomOptions,
    isSearchable,
    color = 'muted',
    size,
    className,
    ...otherProps
}, ref) => {

    const [icon, setIcon] = useState('')

    useEffect(() => {
        if (isSearchable) {
            // IC rooms
            setIcon('bolt')
        } else if (!roomOptions?.departmentMembersCanJoinAsMember) {
            // private
            setIcon('lock')
        } else {
            // IC room
            setIcon('hashtag')
        }
    }, [roomOptions])


    const _className = classNames(
        'room-type-icon',
        className
    )

    return (
        <Icon
            ref={ref}
            name={icon as IconName}
            color={color}
            size={size}
            className={_className}
            {...otherProps}
        />
    )
})

export { RoomTypeIcon }
RoomTypeIcon.displayName = 'RoomTypeIcon'

import { makeAutoObservable } from 'mobx'
import { injectable, decorate } from '@evertel/di'
import { APIDataMedia } from '@evertel/types'
import { getFileExt, formatBytes } from '@evertel/utils'

class VideoController {
    video: APIDataMedia|File = {}
    width = 400
    height = 225

    constructor(

    ) {
        makeAutoObservable(this)
    }

    init = (video: APIDataMedia, width?: number, height?: number) => {
        this.video = video
        if (width) this.width = width
        if (height) this.height = height
    }

    get isLocal() {
        return this.video instanceof File
    }

    get url() {
        if (this.isLocal) {
            // if a local file, return blob
            return URL.createObjectURL(this.video as File)
        }

        return (this.video as APIDataMedia)?.url
    }

    get fileName() {
        return (this.isLocal) ? (this.video as File)?.name : (this.video as APIDataMedia)?.fileName
    }

    get fileExtension() {
        return getFileExt(this.video)
    }

    get humanizedFileSize() {
        return formatBytes((this.isLocal) ? (this.video as File)?.size : (this.video as APIDataMedia)?.contentLength, 1)
    }

    get hasPreview() {
        const noPreviewPlatforms = ['mixcloud', 'player.vimeo.com/external', 'soundcloud', 'twitch']
        return (this.video as APIDataMedia).id < 0 && !noPreviewPlatforms.some(el => this.url.includes(el))
    }
}

decorate(injectable(), VideoController)

export {
    VideoController
}

import React from 'react'
import { observer } from 'mobx-react-lite'
import {
    Checkbox,
    Row,
    Col,
    Icon,
    Text,
    ButtonDropdown,
    ButtonDropdownDivider,
    ButtonDropdownMenu,
    ButtonDropdownItem,
    ButtonDropdownToggle,
    ButtonDropdownItemPlain
} from '@evertel/web/ui'
import { APIDataDocumentACL } from '@evertel/types'
import { DocumentACLsController } from '@evertel/document'

export interface ACLButtonProps {
    acl: APIDataDocumentACL
    onChangeACL: (access: string, acl: APIDataDocumentACL) => void
    documentACLController: DocumentACLsController
    disabled?: boolean
}

export const ACLButton: React.FC<ACLButtonProps> = observer(({
    acl,
    documentACLController,
    onChangeACL,
    disabled
}) => {

    const isOwner = acl?.accessType === DocumentACLsController.accessTypes.OWNER

    const humanizeAccessType = () => {
        if (!acl) return 'Add Access'
        if (acl.accessType === DocumentACLsController.accessTypes.OWNER) return 'Owner'
        if (acl.accessType === DocumentACLsController.accessTypes.EDIT_AND_SHARE) return 'Edit, Share'
        if (acl.accessType === DocumentACLsController.accessTypes.EDIT) return 'Edit'
        if (acl.accessType === DocumentACLsController.accessTypes.READ_AND_SHARE) return 'View, Share'
        return 'View'
    }

    if (isOwner) {
        return (
            <Text
                color="muted"
                size={12}
                italic
                className="mr-4">
                {humanizeAccessType()}
            </Text>
        )
    }

    return (
        <ButtonDropdown alignment="end">
            <ButtonDropdownToggle color="link">
                {humanizeAccessType()}
            </ButtonDropdownToggle>
            <ButtonDropdownMenu>
                <ButtonDropdownItemPlain>
                    <Col className="pl-2 pt-2">
                        <Row className="p-0">
                            <Checkbox
                                name="view"
                                onChange={() => onChangeACL('read', acl)}
                                checked={documentACLController?.isViewer(acl.accessType)}
                                className="mr-2"
                                disabled={disabled}
                            />
                            <Text bold color='secondary'>
                                View Only
                            </Text>
                        </Row>
                        <Text
                            color="muted"
                            className="pl-2">
                            All members in this room can view but not edit
                        </Text>
                    </Col>
                </ButtonDropdownItemPlain>
                <ButtonDropdownItemPlain>
                    <Col className="pl-2">
                        <Row className="p-0">
                            <Checkbox
                                name="edit"
                                onChange={() => onChangeACL('edit', acl)}
                                checked={documentACLController?.isEditor(acl.accessType)}
                                className="mr-2"
                                disabled={disabled}
                            />
                            <Text bold color='secondary'>
                                View and Edit
                            </Text>
                        </Row>
                        <Text
                            color="muted"
                            className="pl-2">
                            All members in this room can view and edit
                        </Text>
                    </Col>
                </ButtonDropdownItemPlain>
                <ButtonDropdownItemPlain>
                    <Col className="pl-2">
                        <Row className="p-0">
                            <Checkbox
                                name="share"
                                onChange={() => onChangeACL('share', acl)}
                                checked={documentACLController?.canShareDocument(acl.accessType)}
                                className="mr-2"
                                disabled={disabled}
                            />
                            <Text bold color='secondary'>
                                Share
                            </Text>
                        </Row>
                        <Text
                            color="muted"
                            className="pl-2">
                            All members in this room can share this document with others
                        </Text>
                    </Col>
                </ButtonDropdownItemPlain>
                {(acl.id && !disabled) &&
                    <>
                        <ButtonDropdownItem
                            onClick={() => onChangeACL('delete', acl)}>
                            <Icon
                                name="trash"
                                color="danger"
                            />
                            <Text color="danger">
                                Remove access
                            </Text>
                        </ButtonDropdownItem>
                    </>
                }
            </ButtonDropdownMenu>
        </ButtonDropdown>
    )
})

import React from 'react'
import isElectron from 'is-electron'

import ElectronBoundary from './ElectronBoundary'

export const wireUp = () => {
    if (isElectron()) {
        // window.ipcRenderer is added from electron's main.js script (as part of preload.js)
        window.ipcRenderer.on('toggleURL', (event, arg) => {
            if (window.confirm('Changing connection to ' + arg + '.  This will log you out and restart.  Proceed?')) {
                ElectronBoundary.logout()
                window.ipcRenderer.send('system-switch-approved')
            }
        })

        window.ipcRenderer.on('menuSelected', (event, arg) => {
            menuSelected(arg)
        })
    } else {
        console.log('No messages wired: isElectron() FALSE')
    }
}

export const isProduction = () => {
    let electronProduction
    if (isElectron()) {
        console.log('READING FROM ipcRenderer.sendSync')
        electronProduction = window.ipcRenderer.sendSync('getApiSetting')
        console.log('READING FROM ipcRenderer.sendSync:  value read is ', electronProduction)
        console.log('*********  setting api from electron:  production=', electronProduction ? 'PRODUCTION' : 'STAGING')
    }

    return electronProduction
}

export const loggedIn = (options => {
    if (isElectron()) {
        window.ipcRenderer.send('loggedIn', options)
    }
})

export const loggedOut = (options => {
    if (isElectron()) {
        window.ipcRenderer.send('loggedOut', options)
    }
})

const menuSelected = (options) => {
    switch (options.menuItem) {
        case 'createRoom':
            ElectronBoundary.showCreateRoom()
            break
        case 'createDirectMessage':
            ElectronBoundary.showAddThread()
            break
        case 'invitePerson':
            ElectronBoundary.invite()
            break
        case 'joinNewDepartment':
            ElectronBoundary.joinNewDepartment()
            break
        // case 'switchDepartment':
        //     ElectronBoundary.switchDepartment(options.deptId)
        //     break
        case 'myProfile':
            ElectronBoundary.showMyProfile()
            break
        case 'notifications':
            ElectronBoundary.showNotifications()
            break
        case 'preferences':
            ElectronBoundary.showPreferences()
            break
        case 'security':
            ElectronBoundary.showSecurity()
            break
        case 'departments':
            ElectronBoundary.showDepartments()
            break
        case 'logout':
            ElectronBoundary.logout(options.all)
            break
        case 'report-bug':
        case 'make-suggestion':
        case 'ask-question':
            ElectronBoundary.help(options.menuItem)
            break
    }
}
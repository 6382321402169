import React, { useCallback, useState, useEffect, useMemo, ComponentType, forwardRef, useRef } from 'react'
import { Virtuoso, VirtuosoHandle, StateSnapshot, Components, LogLevel } from 'react-virtuoso'
// evertel
import { APIDataRoomMessage } from '@evertel/types'
import { Message } from './Message'
import { DateBlock } from './elements'
import { observer } from 'mobx-react-lite'

interface MessageListProps {
    modelId: number,
    modelType: 'room' | 'thread',
    listRef?: any, // ref for the windowed-list component
    listHeaderComponent?: ComponentType<{ context?: any; }>, // component to display at top of list
    isLoading?: boolean, // is data loading. useful for paging/lazy-loading
    onBottomStateChange?: () => void,

    // Windowed List Props
    data: APIDataRoomMessage[], // loaded data
    groups?: string[],
    groupCounts?: number[],
    renderItem?: () => void, // callback for each item to be rendered. callback includes {index, isScrolling, style}
    onStartReached?: () => void,
    className?: string // applied to outer container of list
}

const INITIAL_MESSAGE_START = 1000000

const MessageList = observer(forwardRef<VirtuosoHandle, MessageListProps>(({
    modelId,
    modelType,
    data = [],
    onStartReached,
    listHeaderComponent
}, listRef) => {

    const [initialDate, setInitialDate] = useState<Date | null>(null)
    const [isScrolling, setIsScrolling] = useState(false)
    const [restoreStateFrom, setRestoreStateFrom] = useState<StateSnapshot | undefined>()

    const stateSnapshots = React.useRef<{ [key: string]: StateSnapshot | undefined }>({})
    const localListRef = useRef<VirtuosoHandle>(null)

    const getStateKey = () => `${modelType}-${modelId}`

    useEffect(() => {
        if (initialDate === null && data.length > 0) {
            //grab the newest date from the end of the data
            setInitialDate(data[data.length - 1].createdDate)
            setRestoreStateFrom(stateSnapshots.current[getStateKey()])
        }
        return () => {
            //saving the state here fails as virtuoso has already deconstructed
        }
    }, [data, initialDate])

    useEffect(() => {
        setInitialDate(null)
    }, [modelType, modelId])

    const [firstItemIndex, initialTopMostItemIndex, initialMessageIndex] = useMemo(() => {
        if (initialDate && data.length) {
            // Key off initialDate on all new message loads so virtuoso properly keeps track of new items
            const indexedDate = data.findLastIndex(msg => msg.createdDate <= initialDate)
            return [INITIAL_MESSAGE_START - indexedDate, data.length - 1, indexedDate]
        }
        return [INITIAL_MESSAGE_START, 0]
    }, [data, initialDate])

    const localStartReached = () => {
        onStartReached()
    }

    const saveState = () => {
        localListRef.current?.getState((snapshot) => {
            console.log('SNAPSHOT FINAL', getStateKey(), snapshot)
            stateSnapshots.current[getStateKey()] = snapshot
        })
    }

    if (!data.length || !initialDate) {
        return (
            <div style={{ height: '100%' }}>
                {React.createElement(listHeaderComponent)}
            </div>
        )
    }

    return (
        <Virtuoso
            tabIndex={-1}
            id="virtuoso-scroller"
            //logLevel={LogLevel.DEBUG}
            key={getStateKey()}
            computeItemKey={(key, msg) => `item-${msg.id}`}
            ref={localListRef}
            // Save/restore works and scrolls to last location:
            // Issues: 1) won't scroll for very bottom items
            //         2) offset broken when header exists
            // restoreStateFrom={restoreStateFrom}
            // rangeChanged={(range) => saveState()}
            // context={{ isScrolling }}
            // scrollerRef={(ref) => { scrollerRef.current = ref }}

            data={data}
            firstItemIndex={firstItemIndex}
            //can't have both restore and initialTopMostItemIndex at the same time
            {...(!restoreStateFrom && { initialTopMostItemIndex: initialTopMostItemIndex })}
            // alignToBottom={true}

            startReached={localStartReached}

            defaultItemHeight={200}
            increaseViewportBy={{ top: 600, bottom: 300 }} //number of px above current view to pre-render
            atBottomThreshold={350} //scroll to bottom if within this many px on new message
            followOutput={'smooth'}
            //isScrolling={setIsScrolling} //causes many re-loads

            itemContent={(index, msg) => {
                //NOTE: index is an arbitrary Virtuoso offset id, not any useful data array index
                return (<MessageMe msg={msg} modelType={modelType} isScrolling={isScrolling} index={index} />)
            }}
            components={{
                // Header: () => { return <h1 style={{ height: 100 }}> hello</ h1> }
                Header: listHeaderComponent
            }}
        />
    )
}))

// const mountCount = []
function MessageMe({ msg, modelType, isScrolling, index }) {

    //for testing
    // useEffect(() => {
    //     mountCount[index] = (mountCount[index] ?? 0) + 1
    // }, [])
    // const renderCount = useRef(0)
    // renderCount.current += 1

    if (!msg) return null

    return (
        /* Must collapse border with wrapper or scrolling breaks */
        <div style={{ padding: '1px' }}>
            {msg.dateChanged &&
                <DateBlock
                    date={msg.createdDate}
                />
            }
            {/* <h3>Render#:{renderCount.current}</h3> */}
            {/* <h1>{msg.ownerId} --- {msg.id}</h1> */}
            {/* <h3>Mount#:{mountCount[index]}</h3> */}
            {/* <p>{JSON.stringify(msg)}</p> */}
            {/* <p>{msg.text}</p> */}
            <Message
                message={msg}
                modelType={modelType}
                isScrolling={isScrolling}
            />
        </div>
    )
}

/*
 * helper method to set multiple refs when using forwardRef but
 * a local ref is still needed.  No way to tell if the passed ref
 * is a ref or a callback function
 */
function setRefs(...refs) {
    return (instance) => {
        refs.forEach(ref => {
            if (typeof ref === 'function') {
                ref(instance)
            } else if (ref) {
                ref.current = instance
            }
        })
    }
}

export { MessageList }

import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import DepartmentsAccessStore from '../DepartmentsAccessStore'
import AppStore from '../AppStore'
import Media from './Media'
import api from '../../api'
import moment from 'moment'
import { container } from '../../di'
import RegistrationStore from '../RegistrationStore'
import CurrentUserStore from '../CurrentUserStore'
import { AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { SessionState } from '@evertel/session'
import _ from 'lodash'

export default class User {
    id = 0
    firstName = undefined
    lastName = undefined
    phoneNumber = undefined
    isAdmin = false
    isEnabled = false
    isBot = false
    meta = {}
    publicImage = undefined
    publicMedia = undefined
    isSearchable = true
    lastUpdated = undefined
    email = undefined
    emailVerified = false
    passwordCreatedDate = undefined
    signupDate = undefined
    auth0 = {}
    CRMdata = []

    isBusy = false
    error = null
    lastFetched = undefined

    constructor(data) {
        this.authenticationController = container.get(AuthenticationController)
        this.session = container.get(SessionState)
        makeObservable(this, {
            id: observable,
            firstName: observable,
            lastName: observable,
            phoneNumber: observable,
            isAdmin: observable,
            isEnabled: observable,
            isBot: observable,
            meta: observable,
            publicImage: observable,
            publicMedia: observable,
            isSearchable: observable,
            lastUpdated: observable,
            email: observable,
            emailVerified: observable,
            passwordCreatedDate: observable,
            signupDate: observable,
            auth0: observable,
            CRMdata: observable,
            isBusy: observable,
            error: observable,
            updateFromData: action('User updateFromData'),
            departmentsAccess: computed,
            selectedDepartmentsAccess: computed,
            guestDepartmentsAccess: computed,
            selectedDepartmentRole: computed,
            isInSelectedDepartment: computed,
            fullName: computed,
            fullNameShort: computed,
            initials: computed,
            primaryDepartmentsAccess: computed,
            passwordTTL: computed,
            minimumDepartmentPasswordTTL: computed,
            updateLastFetched: action('updateLastFetched'),
            changeEmail: action('Set user email'),
            update: action('User update'),
            joinDepartment: action('Add user to department'),
            deleteProfilePic: action('Delete profile pic'),
            fetchMessagesSentCount: action,
            fetchCRMdata: action,
            addCRMdata: action,
            updateCRMdata: action,
            deleteCRMdata: action,
            drawerRoomSort: computed,
            drawerThreadSort: computed,
            drawerDisplay: computed
        })

        if (!data.id) throw new Error('id is required')
        this.id = data.id
        this.updateFromData(data)
    }

    updateFromData(data) {
        // Updates whatever fields are passed in individually
        const fields = ['firstName', 'lastName', 'phoneNumber', 'isAdmin', 'isEnabled', 'isBot', 'meta', 'isSearchable', 'publicImage', 'lastUpdated', 'signupDate', 'email', 'emailVerified', 'passwordCreatedDate', 'fetched', 'publicMedia', 'auth0']
        for (const f of fields) {
            if (data[f] !== undefined) {
                switch (f) {
                    case 'fetched':
                        this.lastFetched = Date.now()
                        break
                    case 'publicMedia':
                        this[f] = data[f] && new Media(data[f])
                        break
                    default:
                        this[f] = data[f]
                }
            }
        }
    }

    get departmentsAccess() {
        return DepartmentsAccessStore.departmentsAccess.filter(da => da.blueUserId === this.id)
    }

    // Returns this users department access that matches the department I have selected
    get selectedDepartmentsAccess() {
        return this.departmentsAccess.find(da => da.departmentId === AppStore.selectedDepartmentId) || false
    }

    get guestDepartmentsAccess() {
        return DepartmentsAccessStore.departmentsAccess.find(d => d.role === 'guest')
    }

    // Returns this users role that matches the department I have selected
    get selectedDepartmentRole() {
        return this.selectedDepartmentsAccess.role
    }

    get isInSelectedDepartment() {
        return !!this.selectedDepartmentsAccess
    }

    // firstName + lastName
    get fullName() {
        return `${this.firstName} ${this.lastName}`
    }

    // First letter of first name, last name
    get fullNameShort() {
        return `${this.firstName.substring(0, 1).toUpperCase()}. ${this.lastName}`
    }

    // initials
    get initials() {
        return `${this.firstName.substring(0, 1).toUpperCase()} ${this.lastName.substring(0, 1).toUpperCase()}`
    }

    // Returns this users primary department, this user is not You
    get primaryDepartmentsAccess() {
        if (!this.departmentsAccess) return null

        const departmentsAccessExcludeGuests = this.departmentsAccess.filter(da => da.role !== 'guest')
    
        if (!departmentsAccessExcludeGuests) return null
    
        // if they only belong to one dept, then return it as primary
        if (departmentsAccessExcludeGuests.length === 1) return departmentsAccessExcludeGuests[0]
    
        // otherwise return the department flagged as isPrimary or the first department

        const firstPrimary = departmentsAccessExcludeGuests.find(d => d.isPrimary && d.isVerified)
        const firstVerified = departmentsAccessExcludeGuests.find(d => d.isVerified)
        return firstPrimary || firstVerified || (departmentsAccessExcludeGuests.length && departmentsAccessExcludeGuests[0]) || null
    }

    get passwordTTL() {
        if (CurrentUserStore.enterprise) return 'never'
        // grab passwordTTLs from all user's departments
        const departmentsAccessNotGuest = this.departmentsAccess?.filter((da) => da.role !== 'guest')
        const passwordTTLs = departmentsAccessNotGuest.map(da => da.department.passwordTTL)
        // filter out any that never expire
        const activePasswordTTLs = passwordTTLs.filter(ttl => ttl > 0)
        // if none have active TTL's then return 'never' and stop
        if (!activePasswordTTLs.length) return 'never'
        // if active TTL's, grab the shortest
        const shortestPasswordTTL = Math.min(...activePasswordTTLs)

        // determine number of days since password was created
        const passwordCreatedDate = moment(this.passwordCreatedDate)
        const today = moment()
        const daysSinceCreated = today.diff(passwordCreatedDate, 'days')

        // return the difference between days since created and shortest allowed passwordTTL
        return Math.floor(shortestPasswordTTL / (3600*24)) - daysSinceCreated
    }

    get minimumDepartmentPasswordTTL() {
        // grab passwordTTLs from all user's departments
        const passwordTTLs = this.departmentsAccess.map(da => da.department.passwordTTL)
        // filter out any that never expire
        const activePasswordTTLs = passwordTTLs.filter(ttl => ttl > 0)
        // if none have active TTL's then return 'never' and stop
        if (!activePasswordTTLs.length) return 'never'
        // if active TTL's, grab the shortest
        const shortest = Math.min(...activePasswordTTLs)

        // convert to days
        return Math.floor(shortest / (3600*24))
    }

    updateLastFetched() {
        this.lastFetched = Date.now()
    }

    async changeEmail(email, password) {
        this.isBusy = true
        this.error = null

        try {
            const resp = await this.authenticationController.changeEmail(this.id, { email, password })

            runInAction(() => {
                this.isBusy = false
            })

            RegistrationStore.setRegisteredEmail(email)
            return resp
        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({type: 'API put', message: 'User Model changeEmail()', error: e.message || e, userId: this.id})
        }
    }

    async update(data) {
        if (!data) return

        try {
            const userData = await api.BlueUser.update(this.id, data)

            runInAction(() => {
                this.updateFromData(userData)
            })
        } catch (e) {
            AppStore.logError({type: 'API put', message: 'User Model update()', error: e.message || e, userId: this.id})

            runInAction(() => {
                this.isBusy = false
                this.error = e
            })
            return
        }
    }

    async joinDepartment(deptId) {
        this.isBusy = true
        this.error = null

        try {
            const departmentsAccess = await api.Department.joinDepartment(deptId, this.id)
            DepartmentsAccessStore.updateFromData(departmentsAccess)

            runInAction(() => {
                this.isBusy = false
            })

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({
                type: 'API put',
                message: 'User Model joinDepartment()',
                error: e.message || e,
                userId: this.id, deptId
            })
        }
    }

    async deleteProfilePic() {
        this.isBusy = true
        this.error = false

        try {
            await api.BlueUser.deleteProfileImage(this.id)

            runInAction(() => {
                this.isBusy = false
                this.publicImage = null
                this.publicMedia = null
            })
        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({
                type: 'API delete',
                message: 'User Model deleteProfilePic()',
                error: e.message || e,
                userId: this.id
            })
        }
    }

    async fetchMessagesSentCount() {
        const deptId = this.primaryDepartmentsAccess?.departmentId
        if (!deptId) return {}

        try {
            const data = await api.Department.getReportUsersWithMessageCounts(deptId, {
                filter: {
                    where:
                    {
                        id: this.id
                    }
                }
            })
            return (data) ? data[0] : {}
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'User Model fetchMessagesSentCount()',
                error: e.message || e,
                userId: this.id
            })
        }
    }

    //======================================
    // CRM DATA
    //======================================

    async fetchCRMdata() {
        try {
            const filter =   {include: {
                relation: 'children',
                scope: {
                    include: 'children'
                }
            }
            }
            const data = await api.BlueUser.getCRMdata(this.id, filter)

            runInAction(() => {
                this.CRMdata = data
            })
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'User Model fetchCRMdata()',
                error: e.message || e,
                userId: this.id
            })
        }
    }

    async addCRMdata(data) {
        // {
        //     "key": "string",
        //     "valueString": "string",
        //     "valueType": "string",
        //     "valueNumber": 0,
        //     "valueDate": "2020-11-09T23:06:16.431Z",
        //     "userId": 0,
        //     "parentId": 0,
        // }
        try {
            const newData = await api.BlueUser.postCRMdata(this.id, data)

            runInAction(() => {
                this.CRMdata.push(newData)
            })

            return newData
        } catch (e) {
            AppStore.logError({
                type: 'API post',
                message: 'User Model addCRMdata()',
                error: e.message || e,
                userId: this.id,
                data
            })
        }
    }

    async updateCRMdata(crmDataId, data) {
        // {
        //     "id": "number",
        //     "key": "string",
        //     "valueString": "string",
        //     "valueType": "string",
        //     "valueNumber": 0,
        //     "valueDate": "2020-11-09T23:06:16.431Z",
        //     "parentId": 0,
        // }
        try {
            const newData = await api.BlueUser.updateCRMdata(this.id, crmDataId, data)

            const idx = this.CRMdata.findIndex(d => d.id === data.id)

            runInAction(() => {
                this.CRMdata[idx] = newData
            })
        } catch (e) {
            runInAction(() => {
                this.error = e
            })

            AppStore.logError({
                type: 'API put',
                message: 'User Model updateCRMdata()',
                error: e.message || e,
                userId: this.id,
                data
            })
        }
    }

    async deleteCRMdata(CRMdataId) {
        try {
            await api.BlueUser.deleteCRMdata(this.id, CRMdataId)

            runInAction(() => {
                this.CRMdata = this.CRMdata.filter(d => d.id !== CRMdataId)
            })
        } catch (e) {
            AppStore.logError({
                type: 'API delete',
                message: 'User Model deleteCRMdata()',
                error: e.message || e,
                userId: this.id,
                CRMdataId
            })
        }
    }

    get drawerRoomSort() {
        // can be either 'alpha', 'oldest', 'newest' or 'unread'
        return this.meta?.appearance?.drawerRoomSort || 'alpha'
    }
    
    get drawerThreadSort() {
        // can be either 'alpha', 'oldest', 'newest' or 'unread'
        return this.meta?.appearance?.drawerThreadSort || 'unread'
    }

    get drawerDisplay() {
        // can be either 'medium' or 'condensed'
        return this.meta?.appearance?.drawerDisplay || 'condensed'
    }

}
import React, { HTMLAttributes, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Col, InfoBox, Row, useUI } from '@evertel/web/ui'
import { InviteByEmailForm } from './InviteByEmailForm'
import { useService } from '@evertel/di'
import { SendInvitesBody, SendInvitesController } from '@evertel/invites'

interface ContactInviteProps extends HTMLAttributes<HTMLDivElement|HTMLSpanElement> {
    departmentId: number,
    onClose: () => void,
    currentUserId?: number,
    inviteBody?: SendInvitesBody,
    invitedRooms?: {label: string, value: number}[],
}

const ContactInviteForm: React.FC<ContactInviteProps> = observer(({
    departmentId,
    onClose
}) => {

    const sendInvitesController = useService(SendInvitesController, [])

    const { addToast } = useUI()

    const [isLoading, setIsLoading] = useState(false)
    const [inviteBody, setInviteBody] = useState<SendInvitesBody>()
    const [error, setError] = useState<string>()

    const send = async () => {
        if (!inviteBody?.to?.length || !inviteBody?.roles?.length) {
            setError('Please make sure you have at least one invite to send')
            return
        }

        setIsLoading(true)

        try {
            await sendInvitesController.sendUserInvite(inviteBody as SendInvitesBody)

            addToast({
                color: 'success',
                message: 'Your invites have been sent!'
            })

            onClose()

        } catch (error: any) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }

        setIsLoading(false)
    }

    return (
        <Col>
            <InfoBox color="info">
                Invite others to join you in a secure conversation in Evertel! These can be people inside 
                or outside your agency (anyone invited here will not become a member of your agency).
            </InfoBox>
            {(error) &&
                <InfoBox
                    color="danger"
                    className="fadeIn">
                    {error}
                </InfoBox>
            }
            <InviteByEmailForm
                departmentId={departmentId}
                className="mt-4"
                inviteType="contact"
                showPublicSearch
                onChange={setInviteBody}
            />
            <Row
                align="center"
                className="mt-4">
                <Button
                    color="success"
                    loading={isLoading}
                    onClick={send}>
                    Send Invites
                </Button>
            </Row>
        </Col>
    )
})

export { ContactInviteForm }

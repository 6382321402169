import { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import classNames from 'classnames'
// evertel
import { Toaster } from '..'
import { RoomsSearchStore, CurrentUserStore } from '../../stores'
import { formatForSelect } from '../../utilities'
import { AsyncPaginate } from 'react-select-async-paginate'


const propTypes = {
    selected: PropTypes.array,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    searchFilter: PropTypes.object,
    maxMenuHeight: PropTypes.number,
    noOptionsMessage: PropTypes.string,
    className: PropTypes.string,
    whereFilter: PropTypes.object,
    onEndReachedThreshhold: PropTypes.number,
    type: PropTypes.oneOf(['department', 'public', 'managed', 'roomsCanPostBulletinsTo']),
    // callbacks
    onSelect: PropTypes.func
}

const defaultProps = {
    selected: [],
    isMulti: false,
    placeholder: 'Search or Select...',
    noOptionsMessage: 'No matches found',
    onEndReachedThreshhold: 80,
    type: 'department'
}


const RoomSearchSelect = observer(({
    selected,
    isMulti,
    placeholder,
    noOptionsMessage,
    onSelect,
    whereFilter,
    maxMenuHeight,
    className,
    components,
    type,
    onEndReachedThreshhold,
    ...otherProps
}) => {
    const [search, setSearch] = useState('')

    const onSelectOption = (e) => {
        if (onSelect) onSelect(e)
    }

    const searchRooms = async (searchString, {action}, additional) => {
        if (action !== 'input-blur' && action !== 'menu-close') {
            setSearch(searchString)
        }

        // search
        const usersRole = CurrentUserStore.selectedDepartmentRole
        const searchScope = (type) ? type : (usersRole === 'executive') ? 'department' : 'members'

        const rooms = await RoomsSearchStore.search({
            searchString,
            searchScope,
            whereFilter,
            page: additional?.page || 1
        })

        // error check
        const error = RoomsSearchStore.error
        if (error) {
            Toaster.show('error', error.message || error)
        }

        // grab results and format them for the Select
        const results = formatForSelect(rooms, 'name', 'id')
        const hasMore = RoomsSearchStore.rooms?.length < RoomsSearchStore.count

        return {
            options: results || [],
            hasMore,
            additional: {
                page: additional?.page + 1 || 1
            }
        }
    }

    const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
        // determines when to load next page
        const precentageScroll = scrollTop / (scrollHeight - clientHeight) * 100
        if (precentageScroll > onEndReachedThreshhold) return true
        return false
    }


    return (
        <AsyncPaginate
            name="rooms"
            key={type}
            loadOptions={searchRooms}
            additional={{
                page: 1
            }}
            inputValue={search}
            onInputChange={searchRooms}
            defaultOptions
            shouldLoadMore={shouldLoadMore}
            isMulti={isMulti}
            value={selected}
            onChange={onSelectOption}
            noOptionsMessage={() => noOptionsMessage}
            placeholder={placeholder}
            maxMenuHeight={maxMenuHeight}
            components={components}
            //menuPosition='fixed'
            className={classNames('react-select', {[className]: className})}
            classNamePrefix="select"
            {...otherProps}
        />
    )
})

RoomSearchSelect.propTypes = propTypes
RoomSearchSelect.defaultProps = defaultProps
export default RoomSearchSelect


import { APIDataDepartmentUserThrough, APIMetaDataDepartmentUserThroughPropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

class DepartmentsAccessStore extends Store<APIDataDepartmentUserThrough> {
    constructor() {
        super(APIMetaDataDepartmentUserThroughPropertyNames)    
    }
}

decorate(injectable(), DepartmentsAccessStore)

export { DepartmentsAccessStore }
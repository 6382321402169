import { APIDataMedia } from '@evertel/types'
import { NormalizedMedia } from './NormalizedMediaType'
import { getFileTypeFromMimetype } from '@evertel/utils'

export const APIDataMediaToNormalizedMedia = ( (m:APIDataMedia):NormalizedMedia => ({
    id: m.id,
    fileName: m.fileName,
    contentType: getFileTypeFromMimetype(m.mimetype),
    contentLength: m.contentLength,
    url: m.url,
    mimeType: m.mimetype,
    width: (m.meta as any)?.width,
    height: (m.meta as any)?.height,
    duration: (m.meta as any)?.duration,
    orientation: (m.meta as any)?.orientation,
    isLocalMedia: false,
    state: 'remote',
    versions: (m.meta as any)?.versions,
    uploadProgress: 0, // 0 - 1.0
    updatedDate: m.updatedDate
}))
import { Agent } from '@evertel/types'

export default class App {
    constructor(private getAgent: () => Agent) {}

    sendFeedback = (msg: string) => this.getAgent().post(`App/feedback`, {msg})

    log = (msg: any) => this.getAgent().post(`App/log`, {msg})

    logError = (msg: string) => this.getAgent().post(`App/logError`, {msg})

}

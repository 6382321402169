import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { getNonGuestPrimaryDepartmentsAccess } from '@evertel/utils'
import { Row, Col, Button, InfoBox } from '@evertel/web/ui'
import { ProfilePic } from '@evertel/web/user'
import { APIDataRoomJoinRequest } from '@evertel/types'

interface RequestItemProps {
    request: APIDataRoomJoinRequest;
    onAccept: (request: APIDataRoomJoinRequest) => Promise<void>;
    onReject: (request: APIDataRoomJoinRequest) => Promise<void>;
    onBlock: (request: APIDataRoomJoinRequest) => Promise<void>;
    className?: string;
}

export const RequestItem: React.FC<RequestItemProps> = observer(({
    request,
    onAccept,
    onReject,
    onBlock,
    ...otherProps
}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [blockConfirmation, setBlockConfirmation] = useState(false)

    const user = request?.requestedBy
    const selectedDepartmentsAccess = getNonGuestPrimaryDepartmentsAccess(user.departmentsAccess)

    const handleAction = async (action: 'accept' | 'reject' | 'block') => {
        setIsLoading(true)
        try {
            switch (action) {
                case 'accept':
                    await onAccept(request)
                    break
                case 'reject':
                    await onReject(request)
                    break
                case 'block':
                    await onBlock(request)
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    const renderButtons = () => {
        if (blockConfirmation) {
            return (
                <InfoBox color="danger">
                    Blocked users will no longer be able to request to join this room. You can unblock them at any time.
                    <Row className="mt-2">
                        <Button size="sm" color="muted" className="mr-2" onClick={() => setBlockConfirmation(false)}>
                            Cancel
                        </Button>
                        <Button size="sm" color="danger" disabled={isLoading} onClick={() => handleAction('block')}>
                            Block
                        </Button>
                    </Row>
                </InfoBox>
            )
        }

        return (
            <>
                {request.status === 'pending' && (
                    <Button
                        size="sm"
                        color="success"
                        className="mr-2"
                        disabled={isLoading}
                        onClick={() => handleAction('accept')}
                    >
                        Accept
                    </Button>
                )}
                {(request.status === 'pending' || request.status === 'blocked') && (
                    <Button
                        size="sm"
                        color={request.status === 'blocked' ? 'link' : 'warning'}
                        className="mr-2"
                        disabled={isLoading}
                        onClick={() => handleAction('reject')}
                    >
                        {request.status === 'blocked' ? 'Unblock & Reject' : 'Reject'}
                    </Button>
                )}
                {(request.status === 'pending' || request.status === 'rejected') && (
                    <Button
                        size="sm"
                        color={request.status === 'rejected' ? 'link' : 'danger'}
                        disabled={isLoading}
                        onClick={() => setBlockConfirmation(true)}
                    >
                        Block
                    </Button>
                )}
            </>
        )
    }

    return (
        <Row {...otherProps} valign='top' className="px-3 py-3 hover-light">
            <Col className="flex-grow-0 pr-2 mr-3">
                <ProfilePic
                    userId={user.id}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    imageUrl={user.publicImage}
                    width={100}
                    height={100}
                />
            </Col>
            <Col>
                <p className="mb-0 font-weight-bold">
                    {`${user.firstName} ${user.lastName}`}
                </p>
                <div className="small">
                    {selectedDepartmentsAccess ? (
                        <>
                            <div className="text-capitalize">
                                {selectedDepartmentsAccess.positionString} {selectedDepartmentsAccess?.badgeNumber && `(Badge/ID: ${selectedDepartmentsAccess?.badgeNumber})`}
                            </div>
                            <div>
                                {selectedDepartmentsAccess.department?.name} · {selectedDepartmentsAccess.department?.city}, {selectedDepartmentsAccess.department?.state}
                            </div>
                        </>
                    ) : (
                        request.meta && (
                            <>
                                <div>{request.meta?.position}</div>
                                <div>{request.meta?.department} · {request.meta?.location}</div>
                            </>
                        )
                    )}
                    {request.meta?.message && (
                        <div>Reason: {request.meta?.message}</div>
                    )}
                    <div className={`text-capitalize ${request.status === 'pending' ? 'text-muted' : 'text-danger'}`}>
                        {request.status === 'pending'
                            ? `Requested: ${moment(request.createdDate).format('ll @ HH:mm')}`
                            : `${request.status}: ${moment(request.updatedDate).format('ll @ HH:mm')}`
                        }
                    </div>
                </div>
                <Row className="mt-2">
                    {renderButtons()}
                </Row>
            </Col>
        </Row>
    )
})
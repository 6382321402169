/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import Wrapper from './Wrapper'
import { TextReadWidget } from './TextWidget'


export interface DateWriteWidgetProps {
    id: string,
    value: Date|string,
    onChange: (id: string, date: Date|[Date|null,Date|null]|null) => void,
    title: string,
    required?: boolean,
    showTime?: boolean,
    selectRange?: boolean,
    scope?: string,
    errors?: string[],
    placeholder?: string
}

const DateWriteWidget: React.FC<DateWriteWidgetProps> = memo(({
    id,
    value,
    onChange,
    title,
    required,
    showTime = false,
    selectRange = false,
    scope,
    errors,
    placeholder = 'Select a date...',
    ...otherProps
}) => {

    const dateISO = (value) ? new Date(value).toISOString() : null
    const date = (dateISO) ? new Date(dateISO) : null

    return (
        <Wrapper
            title={title}
            required={required}
            errors={errors}
            {...otherProps}>
                {/* @ts-ignore */}
                <DatePicker
                    selected={date}
                    onChange={(date) => (onChange) && onChange(id, date)}
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showTimeInput={showTime}
                    selectsRange={selectRange}
                    inline={selectRange}
                    placeholderText={placeholder}
                    disabled={scope === 'schemaBuilder'}
                    className="form-control"                    
                />
        </Wrapper>
    )
})

// ----------------------------------------------------------------------------------------

export interface DateReadWidgetProps {
    value: string,
    title: string,
    empty?: string,
}

const DateReadWidget: React.FC<DateReadWidgetProps> = ({
    value = '',
    ...otherProps
}) => {

    //console.log('DATE', value, {...otherProps})

    return (
        <TextReadWidget
            value={(value) ? moment(value).format('L') : undefined}
            {...otherProps}
        />
    )
}

export {
    DateWriteWidget,
    DateReadWidget
}

import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
// evertel
import { Icon, FloatingMenu, MenuItem, Row, Text, ContentPlaceholder } from '@evertel/web/ui'
import { ProfilePic } from '@evertel/web/user'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { CurrentUserController } from '@evertel/blue-user'
import { LogoutService } from '@evertel/logout'

const HeaderProfileDropdown = observer(({
    ...props
}) => {

    const history = useHistory()
    const session = useService(SessionState, [])
    const logoutService = useService(LogoutService, [])
    const currentUserController = useService(CurrentUserController, [session?.currentUserId])

    const user = currentUserController.user

    const placeholderContent  = () => {
        return (
            <Row valign="center" style={{ opacity: 0.2 }}>
                <ContentPlaceholder
                    className='mr-1'
                    width={60}
                    height={18}
                />
                <ContentPlaceholder
                    className='mr-2'
                    width={60}
                    height={18}
                />
                <Icon
                    name="caret-down"
                    color="muted"
                    type="solid"
                    size={10}
                    className="mr-2"
                />
                <ContentPlaceholder
                    width={30}
                    height={30}
                />
            </Row>
        )
    }

    const normalContent = () => {
        return (
            <Row valign="center">
                <Text
                    color="muted"
                    className="hidden-mobile mr-1 mt-1">
                    {currentUserController.fullName}
                </Text>
                <Icon
                    name="caret-down"
                    color="muted"
                    type="solid"
                    size={10}
                    className="mr-2"
                />
                <ProfilePic
                    userId={user?.id as number}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                    imageUrl={user?.publicImage}
                    width={30}
                    height={30}
                />
            </Row>
        )
    }
        
    return (
        <FloatingMenu
            label="User Menu"
            className="mr-3"
            renderLabel={() => !user ? placeholderContent() : normalContent()}
        >
            <MenuItem
                label="My Profile"
                icon={{ name: 'user' }}
                to={`/account/profile/${currentUserController.user?.id}`}
            />
            <MenuItem
                label="Preferences"
                icon={{ name: 'sliders-h' }}
                to="/account/preferences/"
            />
            <MenuItem
                label="Security"
                icon={{ name: 'lock' }}
                to="/account/security/"
            />
            <MenuItem
                label="Notifications"
                icon={{ name: 'bell' }}
                to="/account/notifications/"
            />
            <MenuItem
                label="My Agencies"
                icon={{ name: 'list-alt' }}
                to={`/account/departments/${currentUserController.user?.id}`}
            />
            <MenuItem
                label="Logout"
                icon={{ name: 'sign-out-alt' }}
                onClick={() => logoutService.logout()}
                className="text-danger"
            />
            <MenuItem
                label="Logout on all my devices"
                icon={{ name: 'mobile-alt' }}
                onClick={() => logoutService.logoutAllDevices()}
            />
        </FloatingMenu>
    )
})

export { HeaderProfileDropdown }

import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import { Agent } from '@evertel/types'
import { ApiError } from './ApiError'
import * as qs from 'qs'

export class AxiosInstanceWrapper implements Agent {
    instance: AxiosInstance

    constructor(instance?: AxiosInstance) {
        this.instance = instance || axios.create({
            // Specific parameter serialization, we want the parameter values to be JSON
            // strings before uri encoding, we also don't want to send NULL values.
            paramsSerializer: {
                serialize: (params) => {
                    // for every key in params that is not value NULL or undefined, 
                    // return a key with the value JSON stringified
                    const paramsJSONSerialized = Object.keys(params).reduce( (o, k) => { 
                        if (params[k] !== null && params[k] !== undefined) {
                            if ((params[k] instanceof Date)) {
                                o[k] = params[k].toISOString()
                            } else if (typeof params[k] === 'object') {
                                o[k] = JSON.stringify(params[k])
                            } else {
                                o[k] = params[k]
                            }
                        }
                        return o
                    }, {})

                    return qs.stringify(paramsJSONSerialized)
                }
            }
        })

        // Make sure error is standardized on our API response
        this.addResponseInterceptor(
            null,
            (error: any) => {
                error.status = error.status || error.response?.status

                // Generalize all axios errors to an API error response object
                const message = error.message || error.response?.message
                    || (error.status === 400 && 'Invalid request.')
                    || (error.status === 404 && 'Not found.')
                    || (error.status === 500 && 'Server side error.')
                    || 'Unknown api error.'

                const url = error.url || (error.config && error.config.url)
                // Throw Api error object
                const reError = new ApiError(message, url, error.status || 600) as Error
                const data = (error.response && error.response.data)

                // Apply server error messages fields
                if (data && data.error) {
                    Object.assign(reError, data.error)
                }

                return Promise.reject(reError)
            }
        )
    }

    setBaseURL = (baseURL: string) => {
        this.instance.defaults.baseURL = baseURL
    }

    setWithCredentials = (withCredentials: boolean) => {
        this.instance.defaults.withCredentials = withCredentials
    }

    setHeader = (key: string, value: string | number) => {
        this.instance.defaults.headers[key] = value
    }

    getHeader = (key: string) => {
        return this.instance.defaults.headers[key]
    }

    addRequestInterceptor(onFulfilled: any) {
        this.instance.interceptors.request.use(onFulfilled)
    }

    addResponseInterceptor(onFulfilled: any, onRejected: any) {
        this.instance.interceptors.response.use(onFulfilled, onRejected)
    }

    _getOnce =  {}

    // GET groups similar request until while the first one is in flight
    // then returns the result to all calls of the first request.
    get = async <ResponseType>(url: string, config?: AxiosRequestConfig) => {
        const requestSignature = url + (config?.params ? JSON.stringify(config.params) : '')
        if (this._getOnce[requestSignature]) {

            // TODO: we should add some logging/debugging of this, multiple calls
            // for the same information may be a bug in some cases
            return new Promise<ResponseType> (((resolve, reject) => {
                this._getOnce[requestSignature].push({resolve, reject})
            }))
        } else {
            this._getOnce[requestSignature] = []
    
            try {
                const response = await this.instance.get<ResponseType>(
                    url,
                    config
                )
                this._getOnce[requestSignature].forEach(o => o.resolve(response.data))
                return response.data
            } catch (e) {
                this._getOnce[requestSignature].forEach(o => o.reject(e))
                throw e
            } finally {
                delete this._getOnce[requestSignature]
            }

        }
    }

    getAsRaw = async (url: string, config?: AxiosRequestConfig) => {
        const response = await this.instance.get(
            url,
            config
        )

        return response
    }

    post = async <ResponseType>(url: string, data?: any, config?: AxiosRequestConfig) => {
        const response = await this.instance.post<ResponseType>(
            url,
            data,
            config
        )

        return response.data
    }

    put = async <ResponseType>(url: string, data?: any, config?: AxiosRequestConfig) => {
        const response = await this.instance.put<ResponseType>(
            url,
            data,
            config
        )

        return response.data
    }

    delete = async <ResponseType>(url: string, config?: AxiosRequestConfig) => {
        const response = await this.instance.delete<ResponseType>(
            url,
            config
        )

        return response.data
    }

    del = this.delete
}

const instance = new AxiosInstanceWrapper()

export default instance



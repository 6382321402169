import { Agent } from '@evertel/types'

export default class BlueUser {
    constructor(private getAgent: () => Agent) {}

    // LOGIN/PASSWORD
    resetPassword = (userId: number, body: unknown) => this.getAgent().post(`BlueUsers/${userId}/password/change`, body)
    forgotPassword = (email: any) => this.getAgent().post(`BlueUsers/password/reset/request`, {email})
    resetForgotPassword = (token: any, body: unknown) => this.getAgent().post(`BlueUsers/password/reset`, body, {params: { token }})

    // CRM
    getCRMdata = (userId: number, filter: undefined) => this.getAgent().get(`BlueUsers/${userId}/CRMData`, {params: {filter}})
    getCRMdataCount = (userId: any, where: any) => this.getAgent().get(`BlueUsers/${userId}/CRMData/count`, {params: {where}})
    postCRMdata = (userId: number, data: unknown) => this.getAgent().post(`BlueUsers/${userId}/CRMData`, data)
    updateCRMdata = (userId: number, CRMid: any, data: unknown) => this.getAgent().put(`BlueUsers/${userId}/CRMData/${CRMid}`, data)
    deleteCRMdata = (userId: number, CRMid: any) => this.getAgent().delete(`BlueUsers/${userId}/CRMData/${CRMid}`)

    // SETTINGS
    getUserSettings = (userId: number, filter: undefined) => this.getAgent().get(`BlueUsers/${userId}/settings`, {params: { filter }})
    updateUserSettings = (userId: number, body: unknown) => this.getAgent().put(`BlueUsers/${userId}/settings`, body)

    // DEVICES
    getDevice = (userId: number, deviceId: undefined) => this.getAgent().get(`BlueUsers/${userId}/devices/${deviceId}`)
    getDevices = (userId: any, filter: any) => this.getAgent().get(`BlueUsers/${userId}/devices`, {params: { filter }})
    getDeviceSettings = (userId: any, deviceId: any, filter: any) => this.getAgent().get(`BlueUsers/${userId}/devices/${deviceId}`, {params: { filter }})
    updateDeviceSettings = (userId: number, deviceId: undefined, body: unknown) => this.getAgent().put(`BlueUsers/${userId}/devices/${deviceId}/settings`, body)

    post = (body: unknown) => this.getAgent().post(`BlueUsers`, body)

    getById = (userId: number, filter: { include: ({ relation: string } | { relation: string; scope: { include: ({ relation: string } | { relation: string; scope: { include: string[] } })[] } })[] | ({ relation: string } | { relation: string; scope: { include: ({ relation: string } | { relation: string; scope: { include: string[] } })[] } })[] | ({ relation: string } | { relation: string; scope: { include: ({ relation: string } | { relation: string; scope: { include: string[] } })[] } })[] }) => this.getAgent().get(`BlueUsers/${userId}`, {params: { filter }})
    update = (userId: number, body: unknown) => this.getAgent().put(`BlueUsers/${userId}`, body)
    getUsers = (filter: any) => this.getAgent().get(`BlueUsers`, {params: { filter }}) //admin only

    // ROOMS
    getManagedRooms = (userId: number, filter: { where: any; include: any; order: any; limit: any; skip: number }) => this.getAgent().get(`BlueUsers/${userId}/managedRooms`, {params: { filter }})
    getManagedRoomsCount = (userId: number, where: any) => this.getAgent().get(`BlueUsers/${userId}/managedRooms/count`, {params: { where }})
    getRooms = (userId: number, filter: {
        include: { relation: string; scope: { fields: string[] } }[]
        // ROOMS
        where: { isArchived: boolean }; skip: number; limit: number; order: string; fields: string[]
    }) => this.getAgent().get(`BlueUsers/${userId}/rooms`, {params: { filter }})
    getRoomsCount = (userId: number, where: undefined) => this.getAgent().get(`BlueUsers/${userId}/rooms/count`, {params: { where }})
    getRoomMessagesCountUnread = (userId: number) => this.getAgent().get(`BlueUsers/${userId}/roomMessages/countUnread`)

    // EMAIL
    getEmail = (userId: number) => this.getAgent().get(`BlueUsers/${userId}/email`)

    testAccess = () => this.getAgent().get(`BlueUsers/testAccess`)

    // THREADS
    threads = (userId: number, filter: {
        include: {
            relation: string; scope: {
                fields: string[]; include: {
                    relation: string; scope: {
                        fields: string[]; include: ({ relation: string; scope: { fields: string[] } } | { relation: string; scope?: undefined })[] // EMAIL
                    }
                }[]
            }
        }; skip: number; limit: number; order: string; fields: string[]
    }) => this.getAgent().get(`BlueUsers/${userId}/threads`, {params: { filter }})
    threadsCount = (userId: number, where: any) => this.getAgent().get(`BlueUsers/${userId}/threads/count`, {params: { where }})
    getBotThreadId = (userId: any) => this.getAgent().get(`BlueUsers/${userId}/botThread`)
    getThreadMessagesCountUnread = (userId: number) => this.getAgent().get(`BlueUsers/${userId}/threadMessages/countUnread`)

    // INVITES
    sendInvites = (userId: number, body: unknown) => this.getAgent().post(`BlueUsers/${userId}/sendInvites`, body)
    getInvites = (userId: any, filter: { where: { type: string } }) => this.getAgent().get(`BlueUsers/${userId}/invites`, {params: { filter }})

    // SEARCH
    getUsersBySearch = (filter: any) => this.getAgent().get(`BlueUsers/search`, {params: { filter }}) //admin only
    getUsersCountBySearch = (where: any) => this.getAgent().get(`BlueUsers/search/count`, {params: { where }})
    searchContacts = (userId: number, filter: any) => this.getAgent().get(`BlueUsers/${userId}/search/contacts`, {params: { filter }})
    searchContactsCount = (userId: any, where: any) => this.getAgent().get(`BlueUsers/${userId}/search/contacts/count`, {params: { where }})
    searchPublic = (userId: number, departmentIds: any, filter: {
        order: string; limit: number; fields: string[]; include: {
            relation: string; scope: {
                fields: string[]; include: ({ relation: string; scope: { fields: string[] } } | { relation: string; scope?: undefined })[] // EMAIL
            }
        }[]; where: { searchTerm: any }
    }) => this.getAgent().get(`BlueUsers/${userId}/search/public`, {params: { departmentIds, filter }})
    searchPublicCount = (userId: any, departmentIds: any, where: any) => this.getAgent().get(`BlueUsers/${userId}/search/public/count`, {params: { departmentIds, where }})

    getDeptartmentsAccess = (userId: any, filter: any) => this.getAgent().get(`BlueUsers/${userId}/departmentsAccess`, {params: { filter }})

    // PROFILE PIC
    postProfileImage = (userId: any) => this.getAgent().post(`BlueUsers/${userId}/publicMedia/upload`)
    getProfileImage = (userId: any) => this.getAgent().get(`BlueUsers/${userId}/publicMedia/download`)
    deleteProfileImage = (userId: number) => this.getAgent().delete(`BlueUsers/${userId}/publicMedia`)

    postPushToken = (userId: number, pushToken: never) => this.getAgent().post(`BlueUsers/${userId}/pushTokens`, { value: pushToken } )

    // REPORTS
    getActivityReport = (duration: number) => this.getAgent().get(`BlueUsers/reports/activity`, {params: { duration }})

    // ROOM JOIN REQUESTS
    getRoomJoinRequests = (userId: number, filter: undefined) => this.getAgent().get(`BlueUsers/${userId}/roomJoinRequests`, {params: { filter }})
    getRoomJoinRequestsCount = (userId: any, where: any) => this.getAgent().get(`BlueUsers/${userId}/roomJoinRequests/count`, {params: { where }})

    // LOGOUT
    logout = () => this.getAgent().post(`BlueUsers/logout`)
    logoutOtherDevices = (userId: number) => this.getAgent().post(`BlueUsers/${userId}/logoutOtherDevices`)
}

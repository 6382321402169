
const deal = (info: Record<string, any>, id: string) => {
    if (
        typeof info !== 'undefined'
        && info !== null
        && typeof id !== 'undefined'
    ) {
        return info[id]
    }

    return info
}

export { deal }

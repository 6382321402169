import { observable, action, runInAction, makeObservable } from 'mobx'
import api from '../api'
import AppStore from './AppStore'


class UserCRMStore {

    isBusy = false
    error = null

    keys = []

    constructor() {
        makeObservable(this, {
            isBusy: observable,
            error: observable,
            keys: observable,
            fetchUniqueKeys: action('fetchKeys')
        })

            
    }

    async fetchUniqueKeys() {
        // if we already have the keys don't get them again
        if (this.keys?.length) return

        this.isBusy = true
        this.error = null

        try {
            const keys = await api.UserCRM.getUniqueKeys()

            runInAction(() => {
                this.keys = keys
            })

        } catch (e) {
            runInAction(() => {
                this.error = e
            })

            AppStore.logError({
                type: 'API get',
                message: 'UserCRMStore.fetchUniqueKeys()',
                error: e.message || e
            })
        }

        runInAction(() => {
            this.isBusy = false
        })
    }
}

export default new UserCRMStore()

import { useEffect } from 'react'

// evertel
import {
    Row,
    Col,
    Icon,
    Text
} from '@evertel/web/ui'
import { JoinRoomActionButton } from './elements/JoinRoomActionButton'
import { DepartmentController } from '@evertel/department'
import { useService } from '@evertel/di'
import { observer } from 'mobx-react'

export const RoomListResultItem = observer(({
    data: room,
    innerProps,
    selectProps
}) => {
    const {
        joinRoomController,
        onClose,
        showDept,
        onOpenExtraInfoModal
    } = selectProps.customProps

    const departmentController = useService(DepartmentController, [room.departmentId])
    
    useEffect(() => {
        if (room.departmentId && showDept) {
            departmentController.init(room.departmentId)
        }
    }, [room?.id, showDept])

    if (!room || room.isArchived || !room.name) return null

    const joinedRooms = joinRoomController.joinedRoomIds
    const joined = !!joinedRooms?.find(r => r === room.id)
    const isPrivate = !room.opts?.departmentMembersCanJoinAsMember
    const isPublic = room.isSearchable

    return (
        <Row
            className="p-2 border-bottom border-muted hover-light"
            style={{ minHeight: 60 }}
            {...innerProps}>
            <Col
                className="p-0">
                <Row>
                    <Icon
                        name="hashtag"
                        color="muted"
                        className="pr-1"
                    />
                    <Text bold>
                        {room.name || 'Unnamed Room'}
                    </Text>
                    {isPrivate && 
                        <Icon name="lock" color="muted" className="ml-2" />
                    }
                    {isPublic && 
                        <Icon name="bolt" color="info" className="ml-2" />
                    }
                    {(joined) &&
                        <Text
                            color="success"
                            size="small"
                            bold>
                            <Icon
                                name="check"
                                color="success"
                                className="ml-2"
                            />
                            Joined
                        </Text>
                    }
                </Row>
                {(showDept) &&
                    <Row>
                        <Text
                            color="info"
                            size="small"
                            className="ml-3">
                            {departmentController.department?.name || ''}
                        </Text>
                    </Row>
                }
                <Text
                    color="muted"
                    size="small"
                    className="ml-3">
                    {room.description || ''}
                </Text>
            </Col>
            <Col
                align="right">
                <JoinRoomActionButton
                    room={room}
                    joinRoomController={joinRoomController}
                    closeModal={onClose}
                    openExtraInfoModal={() => onOpenExtraInfoModal(room.id)}
                />
            </Col>
        </Row>
    )
})
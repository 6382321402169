import { makeAutoObservable } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { MediaVersionResolver } from '@evertel/media'
import { APIDataMedia } from '@evertel/types'
import { formatBytes, getFileExt } from '@evertel/utils'

type MetaVersions = {
    versions: { width: number; height: number; id: number; }[] 
}

class ImageController {
    image: APIDataMedia|File = {}
    width = 400
    height = 300

    constructor(
        private versionResolver: MediaVersionResolver
    ) {
        makeAutoObservable(this)
    }

    init = (image: APIDataMedia, width?: number, height?: number) => {
        this.image = image
        if (width) this.width = width
        if (height) this.height = height
    }
    
    get isLocal() {
        return this.image instanceof File
    }

    get url() {
        if (this.isLocal) {
            // if a local file, return blob
            return URL.createObjectURL(this.image as File)
        }

        if ((this.image as APIDataMedia)?.url?.includes('blob')) return (this.image as APIDataMedia).url

        // grab the closest optimized version based on width/height
        const meta = (this.image as APIDataMedia).meta as MetaVersions
        const versionIndex = (meta.versions?.length && this.width && this.height) ? this.versionResolver.resolve(this.width, this.height, meta?.versions) : undefined
        const version = (versionIndex !== undefined) ? meta.versions[versionIndex].id : 'optimized'

        return `${(this.image as APIDataMedia)?.url}?ver=${version}`
    }

    get fileName() {
        return (this.isLocal) ? (this.image as File)?.name : (this.image as APIDataMedia)?.fileName
    }

    get fileExtension() {
        return getFileExt(this.image)
    }

    get humanizedFileSize() {
        return formatBytes((this.isLocal) ? (this.image as File)?.size : (this.image as APIDataMedia)?.contentLength, 1)
    }
}

decorate(injectable(), ImageController)
decorate(inject(MediaVersionResolver), ImageController, 0)

export {
    ImageController
}

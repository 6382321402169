import React, { memo } from 'react'
import { Input, Text, Link, Textarea } from '@evertel/web/ui'
import Wrapper from './Wrapper'

export interface TextWriteWidgetProps {
    id: number|string,
    value: string,
    onChange: (id: string|number, value: string) => void,
    title: string,
    text: string,
    required?: boolean,
    multiline?: boolean,
    placeholder?: string,
    scope?: string,
    errors?: string[],
    contentType?: string
}

const TextWriteWidget: React.FC<TextWriteWidgetProps> = memo(({
    id,
    value = '',
    onChange,
    title,
    text,
    required,
    multiline,
    placeholder,
    contentType = 'text',
    errors,
    scope,
    ...otherProps
}) => {

    if (!title && text) {
        return (
            <Wrapper
                title={text}
            />
        )
    }

    return (
        <Wrapper
            title={title || text}
            required={required}
            errors={errors}
            {...otherProps}>
                {(!multiline) ?
                    <Input
                        value={value}
                        onChange={(e) => {
                            onChange && onChange(id, e.target.value)
                        }}
                        disabled={scope === 'schemaBuilder'}
                        type={contentType}
                        placeholder={placeholder}
                    />
                    :
                    <Textarea
                        value={value}
                        onChange={(e) => {
                            onChange && onChange(id, e.target.value)
                        }}
                        disabled={scope === 'schemaBuilder'}
                        placeholder={placeholder}
                    />
                }
        </Wrapper>
    )
})

// ----------------------------------------------------------------------------------------

export interface TextReadWidgetProps {
    value?: string,
    title?: string,
    text?: string,
    empty?: string,
    multiline?: boolean,
    contentType?: string,
    truncate?: boolean
}

const TextReadWidget: React.FC<TextReadWidgetProps> = ({
    value = '',
    title,
    text,
    multiline,
    empty,
    contentType = 'text',
    truncate
}) => {
    if (typeof value === 'object') {
        return null
    }

    if (!title && text) {
        return (
            <Wrapper
                title={text}
            />
        )
    }

    if (truncate && value.length > 100) {
        value = `${value.slice(0, 100)}...`
    }

    return (
        <Wrapper
            value={value}
            title={title}
            empty={empty}>
            {(contentType !== 'url' && contentType !== 'email') ?
                <Text
                    color={(!value) ? 'secondary' : undefined}
                    {...multiline && {style: { whiteSpace: 'pre-line' }}}>
                    {value || '---'}
                </Text>
            :
                <Link href={(contentType === 'email') ? `mailto:${value}` : value}>
                    {value || '---'}
                </Link>
            }
        </Wrapper>
    )
}

export {
    TextWriteWidget,
    TextReadWidget
}

import { decorate, inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import { APIDataRoomJoinRequestRule } from '@evertel/types'
import { Api } from '@evertel/api'
import { RoomStore } from '@evertel/stores'
import { uniqBy } from 'lodash'

export type RuleTypes = 'departmentDistanceFromPoint'|'departmentTypes'|'departmentStates'

class ICRoomSettingsController {
    roomId = 0
    filter: any = null
    rules: APIDataRoomJoinRequestRule[] = []

    constructor(
        private api: Api,
        private roomStore: RoomStore
    ) {
        makeAutoObservable(this)
    }

    init = async (roomId: number, filter?: any) => {
        this.roomId = roomId
        this.filter = filter

        await this.fetchJoinRequestRules()
    }

    fetchJoinRequestRules = async () => {
        const rules = await this.api.Routes.Room.getRoomJoinRequestRules(this.roomId, this.filter)

        runInAction(() => {
            this.rules = rules
        })
    }

    createJoinRequestRule = async (data) => {
        if (!data) {
            console.error('ICRoomSettingsController postJoinRequestRules(): Must provide data')
            return
        }

        const rule = await this.api.Routes.Room.postRoomJoinRequestRules(this.roomId, data)

        runInAction(() => {
            this.rules = uniqBy([...this.rules, rule], 'id')
        })
    }

    updateJoinRequestRule = async (ruleId: number, data: any) => {
        if (!ruleId || !data) {
            console.error('ICRoomSettingsController putJoinRequestRules(): Must provide a roomId and data')
            return
        }

        const rule = await this.api.Routes.Room.putRoomJoinRequestRulesById(this.roomId, ruleId, data)

        runInAction(() => {
            this.rules = uniqBy([...this.rules, rule], 'id')
        })
    }

    createOrUpdateJoinRequestRule = async (type: RuleTypes, value: any) => {
        const data = {
            permission: 'accept',
            type,
            value
        }

        const rule = this.rules?.find(r => r.type === type)

        if (!rule) {
            await this.createJoinRequestRule(data)
        } else {
            await this.updateJoinRequestRule(rule.id, data)
        }
    }

    deleteJoinRequestRule = async (ruleId: number) => {
        if (!ruleId) {
            console.error('ICRoomSettingsController deleteJoinRequestRule(): Must provide a ruleId')
            return
        }

        await this.api.Routes.Room.delRoomJoinRequestRulesById(this.roomId, ruleId)

        runInAction(() => {
            this.rules = this.rules.filter(r => r.id !== ruleId)
        })
    }

    deleteJoinRequestRuleByType = async (type: RuleTypes) => {
        const updatedJoinRequestRules = await Promise.all(this.rules?.filter(async (rule) => {
            // Different type?
            if (rule.type !== type) {
                // Keep it
                return true
            }

            await this.deleteJoinRequestRule(rule.id)

            return false
        }))

        runInAction(async () => {
            this.rules = updatedJoinRequestRules
        })
    }

    get activeRadiusRule() {
        return this.rules.find(r => r.type === 'departmentDistanceFromPoint' && r.permission === 'accept') || {}
    }

    get activeStatesRule() {
        return this.rules.find(r => r.type === 'departmentStates' && r.permission === 'accept') || {}
    }

    get activeDepartmentTypesRule() {
        return this.rules.find(r => r.type === 'departmentTypes' && r.permission === 'accept') || {}
    }
}

decorate(injectable(), ICRoomSettingsController)
decorate(inject(Api), ICRoomSettingsController, 0)
decorate(inject(RoomStore), ICRoomSettingsController, 1)

export { ICRoomSettingsController }
import { APIDataDocument, APIMetaDataDocumentPropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'
import moment from 'moment'

interface APIDataDocumentWithFetchDate extends APIDataDocument {
    fetchedDate?: moment.Moment;
}
class DocumentStore extends Store<APIDataDocumentWithFetchDate> {
    constructor() {
        // TODO: review replacing storing media here for a DocumentMediaController
        super([...APIMetaDataDocumentPropertyNames, 'fetchedDate', 'media'])
    }

    update(data: APIDataDocument | APIDataDocument[] | undefined){
        if (data === undefined) return

        // make data always an array
        if (!Array.isArray(data)) {
            data = [data]
        } 

        // iterate over data
        for (const i of data as APIDataDocumentWithFetchDate[]) {
            if (!i?.id || isNaN(i.id)) continue

            i.fetchedDate = moment()
        }

        return super.update(data)

    }
}

decorate(injectable(), DocumentStore)

export { DocumentStore, APIDataDocumentWithFetchDate }
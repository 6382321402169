import ReactGA, { OutboundLinkArgs } from 'react-ga'
import api from '../api'
import AppStore from './AppStore'
import CurrentUserStore from './CurrentUserStore'

interface EventParams {
    action: string;
    category: string;
    label?: string;
    value?: number;
    nonInteraction?: boolean;
    transport?:  'beacon' | 'xhr' | 'image';
}

interface DimensionData {
    [key: string]: string | number | boolean;
}

class AnalyticsStore {
    async init(): Promise<void> {
        ReactGA.initialize('G-J9P9XZGDSM', {
            testMode: !api.isProduction, // Boolean. Optional. Enables test mode.
            debug: false, // Boolean. Optional. If set to true, will output additional feedback to the console.
            titleCase: false, // Boolean. Optional. If set to false, strings will not be converted to title case before sending to GA.
            gaOptions: { // Object. Optional.
                userId: AppStore.userId
            }
        })

        if (!api.isProduction) console.log('AnalyticsStore.init()')
    }

    async logEvent({ action, category, label = '', value, nonInteraction = false, transport }: EventParams): Promise<void> {
        if (CurrentUserStore.isAdmin) return // don't log admin activity to analytics

        if (!action || !category) {
            if (!api.isProduction) console.warn('ERROR LOGGING EVENT TO GA. Missing "action" or "category"')
            return
        }

        ReactGA.event({
            category, // String. Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
            action, // String. Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
            label, // String. Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
            value, // Int. Optional. A means of recording a numerical value against an event. E.g. a rating, a score, etc.
            nonInteraction, // Boolean. Optional. If an event is not triggered by a user interaction, but instead by our code (e.g. on page load, it should be flagged as a nonInteraction event to avoid skewing bounce rate data.
            transport // String. Optional. This specifies the transport mechanism with which hits will be sent. Valid values include 'beacon', 'xhr', or 'image'.
        })
    }

    async logPageView(page: string, title: string | null = null): Promise<void> {
        if (CurrentUserStore.isAdmin) return // don't log admin activity to analytics

        if (!page) {
            if (!api.isProduction) console.warn('ERROR LOGGING PAGEVIEW IN GA. Missing "page"')
            return
        }

        ReactGA.pageview(
            page, // String. e.g. '/get-involved/other-ways-to-help'
            [], // trackerNames -> Array. Optional. A list of extra trackers to run the command on
            title // String. Optional. e.g. 'Other Ways to Help'
        )
    }

    async logModalView(modal: string): Promise<void> {
        if (CurrentUserStore.isAdmin) return // don't log admin activity to analytics

        if (!modal) {
            if (!api.isProduction) console.warn('ERROR LOGGING MODALVIEW TO GA. Missing "modal"')
            return
        }

        ReactGA.modalview(modal) // String. E.g. 'login', 'read-terms-and-conditions'
    }

    async logTiming(category: string, variable: string, value: number, label: string | null = null): Promise<void> {
        if (CurrentUserStore.isAdmin) return // don't log admin activity to analytics

        ReactGA.timing({
            category, // String. Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
            variable, // 'load',
            value, // 20, in milliseconds
            label // 'CDN libs'
        })
    }

    async logOutboundLink(link: string): Promise<void> {
        if (CurrentUserStore.isAdmin) return // don't log admin activity to analytics

        ReactGA.outboundLink({
            label: link // String. Required. Description of where the outbound link points to. Either as a URL, or a string.
        }, () => {})
    }

    async setDimension(data: DimensionData): Promise<void> {
        if (CurrentUserStore.isAdmin) return // don't log admin activity to analytics
    
        const fields = Object.keys(data)
        const values = Object.values(data)
    
        if (fields.length === 0 || values.length === 0) {
            if (!api.isProduction) console.warn('ERROR SETTING DIMENSION IN GA. Missing "field" or "value"')
            return
        }
    
        // ReactGA.set expects an object with key-value pairs
        ReactGA.set(data)
    }

    // use to log errors and crashes to GA
    async logException(description: string, fatal: boolean = false): Promise<void> {
        if (CurrentUserStore.isAdmin) return // don't log admin activity to analytics

        ReactGA.exception({
            description, // String. Optional. Description of what happened.
            fatal // Bool. Optional. Set to true if it was a fatal exception.
        })
    }
}

export default new AnalyticsStore()
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Icon, Row } from '@evertel/web/ui'
import { ToolBarOption } from './CreateMessage'

interface CreateMessageFormatBarProps {
    onClickOption: (option: ToolBarOption) => void
}

const CreateMessageFormatBar: React.FC<CreateMessageFormatBarProps> = observer(({
    onClickOption
}) => {

    return (
        <Row
            valign="center"
            className="message-format-bar">
            <Icon
                name="bold"
                type="regular"
                onClick={() => onClickOption('bold')}
                className="mr-1"
                fixedWidth
                data-tooltip="Bold"
                data-tooltip-pos="top"
            />
            <Icon
                name="italic"
                type="regular"
                onClick={() => onClickOption('italic')}
                className="mx-1"
                fixedWidth
                data-tooltip="Italic"
                data-tooltip-pos="top"
            />
            {/* <Icon
                name="strikethrough"
                type="regular"
                onClick={() => onClickOption('strike')}
                className="mx-1"
                fixedWidth
                data-tooltip="Strikethrough"
                data-tooltip-pos="top"
            /> */}
            {/* <Icon
                name="pipe"
                color="muted"
                className="mx-3 pipe"
            />
            <Icon
                name="link"
                type="regular"
                onClick={() => onClickOption('link')}
                className="mx-1"
                fixedWidth
                data-tooltip="Link"
                data-tooltip-pos="top"
            /> */}
            <Icon
                name="pipe"
                color="muted"
                className="mx-3 pipe"
            />
            <Icon
                name="list"
                type="regular"
                onClick={() => onClickOption('list')}
                className="mx-1"
                fixedWidth
                data-tooltip="Bulleted List"
                data-tooltip-pos="top"
            />
            <Icon
                name="list-ol"
                type="regular"
                onClick={() => onClickOption('ol-list')}
                className="mx-1"
                fixedWidth
                data-tooltip="Numbered List"
                data-tooltip-pos="top"
            />
            <Icon
                name="pipe"
                color="muted"
                className="mx-3 pipe"
            />
            <Icon
                name="brackets-curly"
                type="regular"
                onClick={() => onClickOption('block')}
                className="mx-1"
                fixedWidth
                data-tooltip="Block"
                data-tooltip-pos="top"
            />
        </Row>
    )
})

export { CreateMessageFormatBar }

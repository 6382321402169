import React, { useState, useEffect, useContext, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import Select from 'react-select'

// evertel
import { InfoBox, useUI } from '@evertel/web/ui'
import { RoomContext } from '../../RoomContext'
import { RequestItem } from './RequestItem'

type FilterStatus = 'pending' | 'accepted' | 'rejected' | 'blocked'

const filterOptions = [
    { value: 'pending', label: 'Pending' },
    { value: 'blocked', label: 'Blocked' }
]

interface RoomRequestsProps {
    maxMenuHeight: string;
}

interface APIDataRoomJoinRequest {
    id: number;
    // Add other properties as needed
}

const RoomRequests: React.FC<RoomRequestsProps> = observer(({ maxMenuHeight }) => {
    const { roomController, roomJoinRequestsController } = useContext(RoomContext)
    const [selectedFilter, setSelectedFilter] = useState<FilterStatus>('pending')
    const [isLoading, setIsLoading] = useState(true)
    const { addToast } = useUI()

    useEffect(() => {
        setIsLoading(true);

        (async () => {
            try {
                await roomJoinRequestsController.init(roomController.room?.id)
            } finally {
                setIsLoading(false)
            }
        })()

    }, [roomController.room?.id])

    const requests = (() => {
        switch (selectedFilter) {
            case 'accepted':
                return roomJoinRequestsController.acceptedJoinRequests
            case 'rejected':
                return roomJoinRequestsController.rejectedJoinRequests
            case 'blocked':
                return roomJoinRequestsController.blockedJoinRequests
            case 'pending':
            default:
                return roomJoinRequestsController.pendingJoinRequests
        }
    })()

    const handleAction = async (
        actionFn: (requestId: number) => Promise<void>,
        request: APIDataRoomJoinRequest
    ): Promise<void> => {
        try {
            await actionFn(request.id)
        } catch (error) {
            addToast({
                color: 'danger',
                message: error?.message ?? 'An error occurred'
            })
        }
    }

    const onAccept = async (request: APIDataRoomJoinRequest): Promise<void> => 
        handleAction(roomJoinRequestsController.acceptRequest, request)

    const onReject = async (request: APIDataRoomJoinRequest): Promise<void> => 
        handleAction(roomJoinRequestsController.rejectRequest, request)

    const onBlock = async (request: APIDataRoomJoinRequest): Promise<void> => 
        handleAction(roomJoinRequestsController.blockRequest, request)

    return (
        <>
            <Select
                options={filterOptions}
                value={filterOptions.find(option => option.value === selectedFilter)}
                onChange={(s) => s && setSelectedFilter(s.value as FilterStatus)}
                className="react-select mb-3"
                classNamePrefix="select"
                isSearchable={false}
                isDisabled={isLoading}
            />
            <div style={{ maxHeight: maxMenuHeight, overflow: 'auto' }}>
                {isLoading ? (
                    <InfoBox color="info">Loading join requests...</InfoBox>
                ) : !requests.length ? (
                    <InfoBox color="info">
                        There are no {selectedFilter} requests
                    </InfoBox>
                ) : (
                    requests.map((request) => (
                        <RequestItem
                            key={request.id}
                            request={request}
                            onAccept={onAccept}
                            onReject={onReject}
                            onBlock={onBlock}
                            className="mb-3 pb-3 border-bottom"
                        />
                    ))
                )}
            </div>
        </>
    )
})

export { RoomRequests }
import React from 'react'
import { ElementType, forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

export interface DropdownItemPlainProps extends HTMLAttributes<HTMLSpanElement> {
    className?: string
    /** Component used for the root node. Either a string to use a HTML element or a component. */
    component?: string | ElementType
}

const ButtonDropdownItemPlain = forwardRef<HTMLSpanElement, DropdownItemPlainProps>(({
    children,
    className,
    component: Component = 'span',
    ...otherProps
}, ref) => {

    const _className = classNames('drop-item', className)

    return (
        <Component
            ref={ref}
            className={_className}

            {...otherProps}>
            {children}
        </Component>
    )
}
)

export default ButtonDropdownItemPlain
ButtonDropdownItemPlain.displayName = 'ButtonDropdownItemPlain'
import React, { forwardRef, HTMLAttributes, ReactElement, useEffect, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Text, Col, Row, Icon, ContentPlaceholder } from '@evertel/web/ui'
import { DepartmentAvatar } from '@evertel/web/department'
import { RoomController } from '@evertel/room'
import { DepartmentController } from '@evertel/department'
import { useService } from '@evertel/di'
import { RoomTypeIcon, RoomOptions } from '../RoomTypeIcon'
import { SessionState } from '@evertel/session'

interface RoomTileProps extends HTMLAttributes<HTMLDivElement> {
    roomId: number,
    showRoomTypeIcon?: boolean,
    showMembersCount?: boolean,
    showDepartment?: boolean,
    subComponent?: ReactElement,
    className?: string
}

const RoomTile = observer(forwardRef<HTMLDivElement, RoomTileProps>(({
    roomId,
    showRoomTypeIcon = true,
    showMembersCount = false,
    showDepartment = true,
    subComponent,
    className,
    ...otherProps
}, ref) => {

    const session = useService(SessionState, [])
    const roomController = useService(RoomController, [])
    const departmentController = useService(DepartmentController, [])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)

        roomController.init(roomId).finally(() => {
            setIsLoading(false)
        })

    }, [roomId])

    useEffect(() => {
        if (showDepartment && roomController.room) {
            departmentController.init(
                roomController.room.departmentId as number
            )
        }
    }, [roomController.room])

    const _className = classNames(
        'room-tiles',
        'p-0',
        'd-flex',
        className
    )

    const room = roomController.room
    const department = departmentController.department

    if (isLoading) {
        return (
            <>
                <ContentPlaceholder width="50%" className="mb-1"/>
                <ContentPlaceholder width="40%"/>
            </>
        )
    }

    return (
        <div
            ref={ref}    
            className={_className}
            {...otherProps}>
            <Col className="p-0">
                <Text bold>
                    {(showRoomTypeIcon) &&
                        <RoomTypeIcon
                            roomOptions={room?.options as RoomOptions}
                            isSearchable={room?.isSearchable}
                            size={12}
                            className="mr-1"
                        />
                    }
                    {room?.name || 'Room '+room?.id}
                </Text>
                <Row className="m-0">
                    {(showMembersCount) &&
                        <Text
                            color="muted"
                            size={12}
                            className="mr-2">
                            <Icon
                                name="user"
                                color="muted"
                                size={10}
                                className="mr-1"
                            />
                            {roomController.usersCount?.toLocaleString()}
                        </Text>
                    }
                    {(showDepartment && department) &&
                        <Text
                            color="muted"
                            size={12}>
                            {department?.name}
                            {/* show dept avatar if not the current dept. */}
                            {(session?.selectedDepartmentId !== department?.id) &&
                                <DepartmentAvatar
                                    departmentId={department.id}
                                    width={12}
                                    height={12}
                                    className="ml-1"
                                />
                            }
                        </Text>
                    }
                </Row>
            </Col>
        </div>
    )
}))

export { RoomTile }
RoomTile.displayName = 'RoomTile'

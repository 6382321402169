import { makeAutoObservable, runInAction } from 'mobx'
import moment from 'moment'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { APIDataInvite } from '@evertel/types'

class SharedRoomController {
    roomId = 0
    invites = []

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = async (roomId: number) => {
        if (!roomId) return
        
        this.roomId = roomId

        this.fetchActivePersistentGuestInvites()
    }

    async fetchActivePersistentGuestInvites() {

        const invites = await this.api.Routes.Room.getInvites(this.roomId, {
            where: {
                setConsumerRole: 'guest',
                isPersistent: true,
                status: 'valid'
            }
        })

        runInAction(() => {
            this.invites = invites
        })
    }

    async createInvite() {
        await this.api.Routes.Room.postInvites(this.roomId, 30, 'guest', false)

        // refresh invites list
        await this.fetchActivePersistentGuestInvites()
    }

    async deleteInvite(inviteId: number) {
        if (!inviteId) {
            console.error('Missing inviteId in SharedRoomController.createInvite()')
        }

        await this.api.Routes.Room.delInvitesById(this.roomId, inviteId)

        runInAction(() => {
            this.invites = this.invites.filter(i => i.id !== inviteId)
        })
    }

    get latestActiveInvite(): APIDataInvite {
        // grab the invite with the greatest expiration date
        const dates = this.invites?.map(l => moment(l.expiresDate)) || []
        const invite = this.invites?.find(link => moment(link.expiresDate).valueOf() === moment.max(dates).valueOf())
        return invite
    }
}

decorate(injectable(), SharedRoomController)
decorate(inject(Api), SharedRoomController, 0)

export { SharedRoomController }

import * as React from 'react'
import { useState, useEffect, useMemo } from 'react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { observer } from 'mobx-react'
// evertel
import { useContainer, useService } from '@evertel/di'
import { DI_TYPES, WebAnalyticsStoreInterface, WebCurrentUserStoreInterface } from '@evertel/types'
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, InfoBox, Input, InputGroup, InputGroupText, Button, Icon, Text } from '@evertel/web/ui'
import { AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { DeviceState } from '@evertel/device'

interface StateType {
    error: any,
    phoneNumber?: string|undefined
    password: string,
    passwordMatch: boolean,
    enrollSuccessful: boolean,
    isLoading: boolean,
}

interface Props {
    isOpen: boolean,
    onClose: () => void,
    enrollmentInitiated: (phoneNumber: string) => void
}

const EnrollMultiFactorModal: React.FC<Props> = observer(({
    isOpen = false,
    onClose,
    enrollmentInitiated
}) => {

    const container = useContainer()

    const analyticsStore = useMemo(() => container.get<WebAnalyticsStoreInterface>(DI_TYPES.LegacyAnalyticsStore), [container])
    const currentUserStore = useMemo(() => container.get<WebCurrentUserStoreInterface>(DI_TYPES.LegacyCurrentUserStore), [container])
    const deviceState = useService(DeviceState, [])
    const authenticationController = useMemo(() => container.get(AuthenticationController), [container])

    const [state, setState] = useState<StateType>({
        error: null,
        phoneNumber: currentUserStore.phoneNumber || '', // default to the number in their profile (they can change it)
        password: '',
        passwordMatch: false,
        enrollSuccessful: false,
        isLoading: false
    })

    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        if (isOpen) {
            // reset state
            setState(() => ({
                error: null,
                phoneNumber: currentUserStore.phoneNumber || '',
                password: '',
                passwordMatch: false,
                enrollSuccessful: false,
                isLoading: false
            }))

            analyticsStore.logModalView('/account/security/enroll-mfa')
        }
    }, [isOpen])

    const submitEnrollMfa = async () => {
        const { password, phoneNumber } = state

        setState((prevState) => ({
            ...prevState,
            error: null
        }))

        if (!password || !password.trim() || !phoneNumber || !phoneNumber.trim()) {
            setState((prevState) => ({
                ...prevState,
                error: 'Please fill out all fields'
            }))
            return
        }

        setState((prevState) => ({
            ...prevState,
            isLoading: true
        }))

        // validate phone number if it was typed in
        let number = phoneNumber
        const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US')

        if (!parsedNumber?.isValid()) {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                error: 'Invalid phone number'
            }))
            return
        }
        number = parsedNumber.number

        // save typed number to their profile IF they don't have a number already saved
        if (!currentUserStore.phoneNumber) {
            await currentUserStore.update({
                phoneNumber: number
            })
        }

        try {
            const email = await currentUserStore.email

            const body = {
                username: email,
                password: password.trim(),
                phoneNumber: number,
                deviceToken: deviceState.deviceToken
            }

            const response = await authenticationController.enrollMFA(body)
            authenticationController.setMFAParams(response?.access_token, response?.oob_code)

            enrollmentInitiated(number)

        } catch (error: any) {
            setState((prevState) => ({
                ...prevState,
                error: error.message,
                isLoading: false
            }))
            return
        }

        setState((prevState) => ({
            ...prevState,
            enrollSuccessful: true,
            isLoading: false
        }))
    }

    const onChange = (e: any) => {
        setState((prevState) => ({
            ...prevState,
            [e?.target?.name]: e?.target?.value
        }))
    }

    const { password, phoneNumber, isLoading, error } = state

    return (
        <Modal
            visible={isOpen}
            className="join-dept-modal"
            onClose={onClose}
        >
            <ModalHeader title="Enroll in SMS Authentication" />
            <ModalBody>
                <Row>
                    <Col>
                        <Text>
                            To enroll in SMS authentication, please enter your mobile number and account password below.
                        </Text>
                        {(error) &&
                            <InfoBox color="danger">
                                {error}
                            </InfoBox>
                        }

                        <Text color="muted">
                            Mobile Number
                        </Text>
                        <InputGroup className="mb-4">
                            <InputGroupText>
                                <Icon
                                    name="phone"
                                />
                            </InputGroupText>
                            
                            <Input
                                type="text"
                                name="phoneNumber"
                                autoComplete="off"
                                placeholder="Type your mobile phone number"
                                value={phoneNumber}
                                onChange={onChange}
                            />
                        </InputGroup>
                        <div className="invalid-feedback" />


                        <Text color="muted">
                            Login Password
                        </Text>

                        <InputGroup className='mb-4'>
                            <InputGroupText>
                                <Icon name="lock" />
                            </InputGroupText>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={password}
                                autocomplete="off"
                                placeholder="Type your current password"
                                onChange={onChange}
                            />
                            <InputGroupText className='btn-ghost-muted' tag="button" onClick={() => setShowPassword(!showPassword)}>
                                <Icon
                                    name={showPassword ? 'eye-slash' : 'eye'}
                                />
                            </InputGroupText>
                        </InputGroup>

                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="text-center">
                <Row align="right">
                    <Col>
                        <Button
                            color='success'
                            onClick={submitEnrollMfa}
                            disabled={isLoading}
                            loading={isLoading}>
                            <Text>Enroll</Text>
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    )
})

export {
    EnrollMultiFactorModal
} 
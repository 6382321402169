import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Col, Text, Spinner } from '@evertel/web/ui'
import { MessageWallContext } from '../../../MessageWallContext'
import MessageHeaderRoom from './MessageHeaderRoom'
import MessageHeaderThread from './MessageHeaderThread'

interface MessageListHeaderProps {
    isLoading: boolean
}

const MessageListHeader: React.FC<MessageListHeaderProps> = observer(({
    isLoading
}) => {

    const { modelType, modelData } = useContext(MessageWallContext)
    const isRoom = modelType === 'room'

    if (isLoading) {
        // return null
        return (
            <div
                style={{
                    padding: '2rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                <Spinner />
                <Text
                    size="small"
                    color="muted">
                    Decrypting messages...
                </Text>
            </div>
        )
    } else {
        return (
            //must collapse border or virtuoso "alignToBottom" has issues, must not have margin either
            <div style={{ padding: 1 }}>
                <Col align="center" className="mt-4">
                    {isRoom ? <MessageHeaderRoom modelData={modelData} /> : <MessageHeaderThread modelData={modelData} />}
                </Col>
                <JaggedLine />
            </div>
        )
    }
})

const JaggedLine = () => {
    return (
        <div style={{
            height: 70,
            width: '100%',
            position: 'relative',
            marginTop: 20
        }}>
            <div
                style={{
                    position: 'absolute',
                    width: '200%',
                    height: 20,
                    transform: 'translate(-25%) scale(0.5)',
                    background: 'linear-gradient(45deg, transparent, transparent 49%, gray 49%, transparent 51%)',
                    backgroundSize: '40px 40px'
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    width: '200%',
                    height: 20,
                    transform: 'translate(-25%) scale(0.5)',
                    background: 'linear-gradient(-45deg, transparent, transparent 49%, gray 49%, transparent 51%)',
                    backgroundSize: '40px 40px'
                }}
            />
        </div>
    )
}

export { MessageListHeader }

import { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon, Col, Badge, Text, Row } from '@evertel/web/ui'
import { RoomContext } from '../RoomContext'
import { useService } from '@evertel/di'
import { DepartmentController } from '@evertel/department'


const RoomHeader = observer(() => {

    const { roomController, userSettingsController } = useContext(RoomContext)
    const departmentController = useService(DepartmentController, [])

    useEffect(() => {
        (async () => {
            await departmentController.init(roomController.room?.departmentId)
        })()
    }, [roomController.room?.departmentId])

    const room = roomController.room

    if (!room) return null

    const roomNotificationSettings = userSettingsController.roomNotificationSettings[room.id]
    const roomNotificationsEnabled = (roomNotificationSettings) ? roomNotificationSettings.roomMessage?.enabled : true
    const isPrivate = !(room.options as any)?.departmentMembersCanJoinAsMember

    return (
        <Row valign="center" className="page-toolbar">
            <Col>
                <Row valign="center">
                    <Icon
                        name="hashtag"
                        color="muted"
                        className="mr-1"
                    />
                    <Text
                        tag="h5"
                        heavy
                        className={classNames('m-0 mr-2', 'text-truncate', {'text-decoration-line-through': !!room.isArchived})}>
                        {room.name || 'No Name'}
                    </Text>
                    {(roomController.isInteragnecy) &&
                        <Badge color="secondary">
                            {departmentController.shortName}
                        </Badge>
                    }
                    {(!!isPrivate) &&
                        <Icon
                            name="lock"
                            size={13}
                            className="px-2"
                        />
                    }
                    {(room.isSearchable) &&
                        <Badge
                            color="blue"
                            className="mr-2">
                            <Icon
                                name="bolt"
                                color="white"
                                size={13}
                                className="pr-2"
                            />
                            Incident Command
                        </Badge>

                    }
                    {(roomController.isInteragnecy) &&
                        <Icon
                            name="handshake"
                            color="success"
                            size={13}
                            className="px-2"
                        />
                    }
                    {(!roomNotificationsEnabled) &&
                        <Icon
                            name="bell-slash"
                            color="muted"
                            size={13}
                            className="px-2 hidden-mobile"
                        />
                    }
                    {(!!room.isArchived) &&
                        <Badge
                            color="danger"
                            className="mr-2">
                            {/* <Icon
                                name="archive"
                                size={13}
                                className="px-2"
                            /> */}
                            Archived
                        </Badge>
                    }
                    {(!!room.description) &&
                        <Text
                            size="small"
                            color="muted"
                            className="text-truncate">
                            {room.description}
                        </Text>
                    }
                </Row>
            </Col>
        </Row>
    )
})

export { RoomHeader }

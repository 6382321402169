import { makeAutoObservable, runInAction} from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { DocumentSchemaStore } from '@evertel/stores'
import { DepartmentStore } from '@evertel/department'
import { Api } from '@evertel/api'
import { APIDataDepartment } from '@evertel/types'

class DocumentSchemaPickerController {
    loaded = false
    selected = 0 // selected document schema
    all: Set<number> = new Set() // all document schemas

    constructor(
        private api: Api,
        private documentSchemaStore: DocumentSchemaStore,
        private departmentStore: DepartmentStore
    ) {
        makeAutoObservable(this)
    }

    loadByDepartment = async (
        departmentId: number
    ) => {

        const documentSchemas = await this.api.Routes.Department.getDocumentSchemas(departmentId, {
            where: {
                isEnabled: true
            },
            include: [{'relation': 'department'}]
        })

        await this.documentSchemaStore.update(documentSchemas)

        await this.departmentStore.update(
            documentSchemas.map(({department}) => {
                return department as APIDataDepartment
            })
        )

        const all = documentSchemas.map(({id}) => id) as number[]

        await this.load(all)
    }

    loadByContextUser = async (
    ) => {
        const documentSchemas = await this.api.Routes.Document.getSearchDocumentSchema({
            include: [{'relation': 'department'}]
        })

        await this.documentSchemaStore.update(documentSchemas)

        this.departmentStore.update(
            documentSchemas.map(({department}) => {
                return department as APIDataDepartment
            })
        )

        const all = documentSchemas.map(({id}) => id) as number[]

        await this.load(all)
    }

    load = async (
        allIds: number[]
    ) => {
        const documentSchemas = this.documentSchemaStore.findByIds(allIds)

        const ids = documentSchemas.map(({departmentId}) => departmentId) as number[]

        const departments = await this.api.Routes.Department.get({
            where: {id: {inq: ids}}
        })

        await this.departmentStore.update(departments)

        runInAction(() => {
            this.loaded = true
            this.all = new Set(allIds)
        })
    }

    pick = async (id: number) => {
        runInAction(() => {
            this.selected = id
        })
    }
}

decorate(injectable(), DocumentSchemaPickerController)
decorate(inject(Api), DocumentSchemaPickerController, 0)
decorate(inject(DocumentSchemaStore), DocumentSchemaPickerController, 1)
decorate(inject(DepartmentStore), DocumentSchemaPickerController, 2)

export {
    DocumentSchemaPickerController
}

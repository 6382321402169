import React from 'react'
import { forwardRef, ReactNode } from 'react'
import classNames from 'classnames'
import { Button } from '../Button'

export interface BreadcrumbItemProps {
    active?: boolean
    className?: string
    onClick?: () => void
    children?: ReactNode
}

const BreadcrumbItem = forwardRef<HTMLLIElement, BreadcrumbItemProps>(({
    children,
    active,
    className,
    onClick,
    ...otherProps
}, ref) => {

    const _className = classNames(
        'breadcrumb-item',
        {
            active: active
        },
        className
    )

    return (
        <li
            ref={ref}
            className={_className}
            {...(active && { 'aria-current': 'page' })}
            {...otherProps}>
            {(onClick) ?
                <Button
                    color="link"
                    onClick={onClick}>
                    {children}
                </Button>
                :
                children
            }
        </li>
    )
})

export { BreadcrumbItem }

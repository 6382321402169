import { APIDataDepartment, APIMetaDataDepartmentPropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

class ChildDepartmentStore extends Store<APIDataDepartment> {
    constructor() {
        super([...APIMetaDataDepartmentPropertyNames])
    }
}

decorate(injectable(), ChildDepartmentStore)

export { ChildDepartmentStore }
import { ContainerModule } from '@evertel/di'
import { HeaderController, ResetPasswordController } from './controller'


export const DI_WEB_HEADER_MODULE = [
    new ContainerModule((bind) => {
        bind(HeaderController).toSelf()
        bind(ResetPasswordController).toSelf()
    })
]

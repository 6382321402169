export const botMessageFilters = [
    {label: 'People', value: 'people'},
    {label: 'Agencies', value: 'agencies'},
    {label: 'Roles', value: 'roles'},
    {label: 'States', value: 'states'},
    {label: 'Agency Size', value: 'agencySize'},
    //{label: 'Is Champion', value: 'champion'},
    {label: 'Agency Type', value: 'agencyType'},
    {label: 'Country', value: 'country'}
    //{label: 'In Trial', value: 'inTrial'}
]

import set from 'lodash/set'

const setValue = (path: string, data: any, value: string) => {
    set(data, path, value)
}

// const setField = (data, path, value) => {
//     const names = path.split(DIVIDER)
//
//     let updated = {}
//
//     for (const [k, v] of Object.entries(data)) {
//         if (k === names[0]) {
//             if (names.length > 1) {
//                 updated[k] = setField(
//                     v,
//                     names.slice(1).join(DIVIDER),
//                     value
//                 )
//             } else {
//                 updated[k] = value
//             }
//         } else {
//             updated[k] = v
//         }
//     }
//
//     return updated
// }

export {
    setValue
}

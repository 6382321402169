import React from 'react'
import { HTMLAttributes, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
// evertel
import { CloseButton } from '../CloseButton'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { APIDataMedia } from '@evertel/types'
import { Row, Col } from '../../layout'
import { Text } from '../Text'
import { formatBytes, getMediaType } from '@evertel/utils'
import { useService } from '@evertel/di'
import { UserController } from '@evertel/blue-user'
  
interface PhotoGalleryActionBarProps extends HTMLAttributes<HTMLDivElement> {
    media: APIDataMedia,
    onClose: () => void,
    onZoomIn: () => void,
    onZoomOut: () => void,
    onReset: () => void,
    onDownload: () => void
}
  
const PhotoGalleryActionBar: React.FC<PhotoGalleryActionBarProps> = observer(({
    media,
    onClose,
    onZoomIn,
    onZoomOut,
    onReset,
    onDownload
}) => {

    const userController = useService(UserController, [])
    const mediaType = getMediaType(media)

    useEffect(() => {
        userController.init(media?.ownerId)
    }, [media])

    return (
        <Row
            align="between"
            className="lightbox-actionbar">
            <Col>
                <Text
                    color="white"
                    bold>
                    {media?.fileName}
                </Text>
                <Text
                    color="muted"
                    size="small">
                    {formatBytes(media?.contentLength, 1)}
                    <Text color="dark" className="mx-2">|</Text>
                    {moment(media?.updatedDate).format('ll')}
                    <Text color="dark" className="mx-2">|</Text>
                    {userController.fullName}
                </Text>
            </Col>
            <Col align="right">
                <Row>
                    <Button
                        color="muted"
                        size="sm"
                        className="mr-2"
                        ghost
                        onClick={onZoomIn}>
                        <Icon
                            name="magnifying-glass-plus"
                            //color="white"
                            size={20}
                        />
                    </Button>
                    <Button
                        color="muted"
                        size="sm"
                        className="mr-2"
                        ghost
                        onClick={onZoomOut}>
                        <Icon
                            name="magnifying-glass-minus"
                            //color="white"
                            size={20}
                        />
                    </Button>
                    <Button
                        color="muted"
                        size="sm"
                        className="mr-2"
                        ghost
                        onClick={onReset}>
                        <Icon
                            name="arrows-to-circle"
                            //color="white"
                            size={20}
                        />
                    </Button>
                    <Button
                        color="muted"
                        size="sm"
                        className="mr-2"
                        ghost
                        onClick={onDownload}>
                        <Icon
                            name="cloud-arrow-down"
                            //color="white"
                            size={20}
                        />
                    </Button>
                    <CloseButton
                        onClick={onClose}
                    />
                </Row>
            </Col>
        </Row>
    )
})
  
export { PhotoGalleryActionBar }

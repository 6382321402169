
//===================================================
// CALL BEFORE INSERTING TEXT INTO CONTENTEDITABLE
//===================================================

export function convertToHTML(value) {
    // convert text with line breaks into the appropriate markup with <br>.
    value = value.trim()
    value = value.replace(/\n+\s+\n+/g, '\n\n')
    value = value.replace(/\n\n+/g, '\n\n')
    value = value.replace(/\n/g, '<br>')
    value = value.replace(/\s+/g, ' ')

    return value
}

//===================================================
// CALL BEFORE SUBMITTING TO API
//===================================================

export function convertToText(value) {
    // Convert `&amp;` to `&`.
    value = value.replace(/&amp;/gi, '&')

    // Replace spaces.
    value = value.replace(/&nbsp;/gi, ' ')
    //value = value.replace(/\s+/g, ' ') // this line removes carriage returns

    // Remove "<b>".
    value = value.replace(/<b>/gi, '')
    value = value.replace(/<\/b>/gi, '')

    // Remove "<strong>".
    value = value.replace(/<strong>/gi, '')
    value = value.replace(/<\/strong>/gi, '')

    // Remove "<i>".
    value = value.replace(/<i>/gi, '')
    value = value.replace(/<\/i>/gi, '')

    // Remove "<em>".
    value = value.replace(/<em>/gi, '')
    value = value.replace(/<\/em>/gi, '')

    // Remove "<u>".
    value = value.replace(/<u>/gi, '')
    value = value.replace(/<\/u>/gi, '')

    // Tighten up "<" and ">".
    value = value.replace(/>\s+/g, '>')
    value = value.replace(/\s+</g, '<')

    // Replace "<br>".
    value = value.replace(/<br>/gi, '\n')

    // Replace "<div>" (from Chrome).
    value = value.replace(/<div>/gi, '\n')
    value = value.replace(/<\/div>/gi, '')

    // Replace "<p>" (from IE).
    value = value.replace(/<p>/gi, '\n')
    value = value.replace(/<\/p>/gi, '')

    // No more than 2x newline, per "paragraph".
    value = value.replace(/\n\n+/g, '\n\n')

    // Whitespace before/after.
    value = value.trim()

    return value
}

//===================================================
// CALLED ON PASTE
//===================================================

export function sanitizePastedContent(e) {
    // stop default behavior
    e.preventDefault()

    // get text representation of clipboard
    var text = (e.originalEvent || e).clipboardData.getData('text/plain')

    // insert text manually
    document.execCommand('insertHTML', false, text)
}

//===================================================
// NORMALIZE HTML
//===================================================

export function normalizeHtml(str: string): string {
    return str && str.replace(/&nbsp;|\u202F|\u00A0/g, ' ').replace(/<br \/>/g, '<br>')
}

//===================================================
// GETS CURSOR/CARET POSITION
//===================================================

export function getCaretPosition(el: HTMLElement) {
    if (!el) return 0
    
    let position = 0
    const isSupported = typeof window.getSelection !== "undefined"

    if (isSupported) {
        const selection = window.getSelection()

        if (selection.rangeCount !== 0) {
            const range = window.getSelection().getRangeAt(0)
            const preCaretRange = range.cloneRange()
            preCaretRange.selectNodeContents(el)
            preCaretRange.setEnd(range.endContainer, range.endOffset)

            position = preCaretRange.toString().length
        }
    }
    return position
}

import { updateValues } from '@evertel/schema-parser'
import { injectable, inject, decorate } from '@evertel/di'
import { APIDataMedia, APIDataDocumentSchema } from '@evertel/types'
import { Api } from '@evertel/api'
import { MediaUploadService } from '@evertel/media'


class MediaUtils {
    constructor(
        private api: Api,
        private mediaUploadService: MediaUploadService
    ) {
    }

    processDocumentMedia = async (
        platform: 'web' | 'mobile' = 'mobile',
        data: any,
        documentId: number,
        schema: APIDataDocumentSchema
    ): Promise<any> => {

        const processMediaValue = async (value: Record<string, any>): Promise<number[]> => {
            // if no media or the already-uploaded array ignore
            if (!value || typeof value.uploaded === 'undefined') return null

            // delete media
            await Promise.all(value.deleteQueue.map(async (mediaId: number) => {
                try {
                    await this.api.Routes.Document.delMediaById(documentId, mediaId)
                } catch (e) {
                    console.error(e)
                }
            }))

            // upload media
            let added: number[] = []
            if (platform === 'mobile') {
                added = await Promise.all(value.uploadQueue.map(async (
                    mediaItem: APIDataMedia & { upload: (url: string) => APIDataMedia }
                ) => {
                    const { id } = await mediaItem.upload(`Documents/${documentId}/media/upload`)
                    if (!id) return { error: 'Media could not be uploaded' }
                    return id
                }))
            } else {
                for (const media of value.uploadQueue) {
                    this.mediaUploadService.addUploadableMedia(documentId, 'document', media)
                }

                const uploadableMedia = this.mediaUploadService.getMediaUploadsForModel(documentId, 'document')

                await this.mediaUploadService.startMediaUploads(uploadableMedia, async (mediaUploads) => {
                    added = mediaUploads.filter((m: { state: string }) => m.state === 'complete').map(m => m.remoteMedia?.id)
                })
            }

            // convert uploaded array to array of IDs
            const uploaded = value.uploaded?.map((u: APIDataMedia) => u.id || u) || []

            return [...uploaded, ...added]
        }
        
        return await updateValues('media', schema, data, processMediaValue)

    }
}

decorate(injectable(), MediaUtils)
decorate(inject(Api), MediaUtils, 0)
decorate(inject(MediaUploadService), MediaUtils, 1)

export { MediaUtils }

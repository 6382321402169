import React from 'react'
import { HTMLAttributes, forwardRef, useRef } from 'react'
import classNames from 'classnames'
import { Transition } from 'react-transition-group'
import { useForkedRef } from '@evertel/hooks'

export interface TabPaneProps extends HTMLAttributes<HTMLDivElement> {
    className?: string
    onHide?: () => void
    onShow?: () => void
    visible?: boolean
}

const TabPane = forwardRef<HTMLDivElement, TabPaneProps>(({
    children,
    className,
    onHide,
    onShow,
    visible,
    ...otherProps
}, ref) => {

    const tabPaneRef = useRef()
    const forkedRef = useForkedRef(ref, tabPaneRef)

    const getTransitionClass = (state: string) => {
        return state === 'entered' && 'show'
    }

    const _className = classNames(
        'tab-pane',
        'fade',
        {
            active: visible,
        },
        className,
    )

    return (
        <Transition
            in={visible}
            nodeRef={tabPaneRef}
            onEnter={onShow}
            onExit={onHide}
            timeout={150}>
            {(state) => {
                const transitionClass = getTransitionClass(state)
                return (
                    <div
                        ref={forkedRef}
                        className={classNames(_className, transitionClass)}
                        {...otherProps}>
                        {children}
                    </div>
                )
            }}
        </Transition>
    )
})

export { TabPane }
TabPane.displayName = 'TabPane'

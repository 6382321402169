import { APIDataDocumentSchema, APIMetaDataDocumentSchemaPropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

class DocumentSchemaStore extends Store<APIDataDocumentSchema> {
    constructor() {
        super([...APIMetaDataDocumentSchemaPropertyNames, 'media'])    
    }
}

decorate(injectable(), DocumentSchemaStore)

export { DocumentSchemaStore }
import React from 'react'
import moment from 'moment'
import TextReadWidget from './TextWidget'

const DateReadWidget = ({
    value = '',
    ...props
}) => {
    return (
        <TextReadWidget
            value={(value) ? moment(value).format('L') : undefined}
            {...props}
        />
    )
}

export default DateReadWidget


import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { RoomStore } from '@evertel/stores'
import { SessionState } from '@evertel/session'
import { CurrentUserController } from '@evertel/blue-user'
import { Api } from '@evertel/api'
import { APIDataDepartmentUserThrough, APIDataRoom } from '@evertel/types'
import { uniq } from 'lodash'

class RoomController {
    roomId = 0
    managersFetched = false
    managerIds: number[] = []
    memberIds: number[] = []

    constructor(
        private api: Api,
        private roomStore: RoomStore,
        private session: SessionState,
        private currentUserController: CurrentUserController
    ) {
        makeAutoObservable(this)
    }

    init = async (roomId: number, filter?: any) => {
        if (!roomId) return
        
        this.roomId = roomId

        await Promise.all([
            this.fetchRoom(filter),
            this.fetchAllUsersIds()
        ])
    }

    fetchRoom = async (filter?: any) => {
        const room = await this.api.Routes.Room.getById(this.roomId, filter)

        runInAction(() => {
            this.roomStore.update(room)
        })
    }

    async fetchAllUsersIds() {        
        return Promise.all([this.fetchManagerIds(), this.fetchMemberIds()])
    }

    fetchManagerIds = async () => {
        const managers = await this.api.Routes.Room.getManagers(this.roomId, {
            fields: ['id']
        })

        runInAction(() => {
            this.managerIds = managers.map(m => m.id)
            this.managersFetched = true
        })

        return this.managerIds
    }

    async fetchMemberIds() {
        const memberIds = await this.api.Routes.Room.getMembers(this.roomId, {
            fields: ['id']
        })

        runInAction(() => {
            this.memberIds = memberIds?.map(m => m.id)
        })

        return this.memberIds
    }

    async leaveRoom() {
        const isManager = this.managerIds.includes(this.session.currentUserId)

        if (isManager) {
            await this.api.Routes.Room.delManagersById(this.roomId, this.session.currentUserId)
        } else {
            await this.api.Routes.Room.delMembersById(this.roomId, this.session.currentUserId)
        }

        runInAction(() => {
            this.roomStore.deleteById(this.roomId)
        })
    }

    get canCurrentUserManageRoom(): boolean {
        return this.managerIds.includes(this.session.currentUserId)
            || this.currentUserController.canExecutive
    }

    get id(): number {
        return this.roomId
    }
    
    get room(): APIDataRoom {
        return this.roomStore.findById(this.roomId)
    }

    get isInteragnecy() {
        return this.room?.departmentId !== this.session.selectedDepartmentId
    }

    get allowedToPost() {
        // if archived, they can never post
        if (this.room?.isArchived) return false
    
        // executives can always post
        if (this.currentUserController.canExecutive) return true

        // grab room options and determine if they are a manager of this room
        const options = this.room?.options as any
        const isManager = this.managerIds.includes(this.session.currentUserId)

        if (isManager) {
            // if set, if a manager in this room, and if managers are allowed to post
            return options?.roomManagersCanMessage === undefined || options?.roomManagersCanMessage
        } else {
            // if set, if a member in this room, and if members are allowed to post
            return options?.roomMembersCanMessage === undefined || options?.roomMembersCanMessage
        }
    }

    async addMember(userId: number) {
        if (!userId) {
            console.error('Missing userId in RoomController.addMember()')
            return
        }

        try {
            await this.api.Routes.Room.putMembersById(this.roomId, userId)

            runInAction(() => {
                this.memberIds.push(userId)
                this.managerIds = this.managerIds.filter(m => m !== userId)
            })
        } catch (error: any) {
            throw new Error(error.message) // rethrow to view
        }
    }

    async addManager(userId: number) {
        if (!userId) {
            console.error('Missing userId in RoomController.addManager()')
            return
        }

        try {
            await this.api.Routes.Room.putManagersById(this.roomId, userId)

            runInAction(() => {
                this.managerIds.push(userId)
                this.memberIds = this.memberIds.filter(m => m !== userId)
            })
        } catch (error: any) {
            throw new Error(error.message) // rethrow to view
        }
    }

    async removeUser(userId: number) {
        if (!userId) {
            console.error('Missing userId in RoomController.removeUser()')
            return
        }

        const isManager = this.managerIds?.find(m => m === userId)

        if (isManager) {
            await this.removeManager(userId)
        } else {
            await this.removeMember(userId)
        }
    }

    private async removeMember(userId: number) {
        if (!userId) {
            console.error('Missing userId in RoomDirectorController.removeMember()')
            return
        }

        try {
            await this.api.Routes.Room.delMembersById(this.roomId, userId)

            runInAction(() => {
                this.memberIds = this.memberIds.filter(m => m !== userId)
            })
        } catch (error: any) {
            throw new Error(error.message) // rethrow to view
        }
    }

    private async removeManager(userId: number) {
        if (!userId) {
            console.error('Missing userId in RoomDirectorController.removeManager()')
            return
        }

        try {
            await this.api.Routes.Room.delManagersById(this.roomId, userId)

            runInAction(() => {
                this.managerIds = this.managerIds.filter(m => m !== userId)
            })
        } catch (error: any) {
            throw new Error(error.message) // rethrow to view
        }
    }

    get usersCount() {
        return uniq([...this.memberIds, ...this.managerIds])?.length || 0
    }


}

decorate(injectable(), RoomController)
decorate(inject(Api), RoomController, 0)
decorate(inject(RoomStore), RoomController, 1)
decorate(inject(SessionState), RoomController, 2)
decorate(inject(CurrentUserController), RoomController, 3)

export { RoomController }
import React, { ElementType, HTMLAttributes, forwardRef, memo, useEffect, useRef, useState } from 'react'
import { marked } from 'marked'
import { useForkedRef } from '@evertel/hooks'

export type ContentEditableEvent = React.SyntheticEvent<any, Event> & { target: { value: string } }

export interface ContentEditableProps extends HTMLAttributes<HTMLDivElement> {
    html: string,
    disabled?: boolean,
    tag?: ElementType,
    onChange: (event: ContentEditableEvent) => void,
    onBlur?: () => void,
    onKeyDown?: () => void,
    onKeyUp?: () => void
}

const ContentEditable = memo(forwardRef<HTMLDivElement, ContentEditableProps>(({
    html,
    tag,
    onChange,
    onBlur,
    onKeyDown,
    onKeyUp,
    disabled = false,
    children,
    ...otherProps
}, ref) => {

    const Tag: ElementType = tag || 'div'

    const [lastHtml, setLastHtml] = useState(html)
    const [caretPos, setCaretPos] = useState(0)

    const field = useRef(null)
    const forkedRef = useForkedRef(ref, field)

    useEffect(() => {
        if (!field?.current) return

        // perhaps React (whose VDOM gets outdated because we often prevent
        // rerendering) did not update the DOM. So we update it manually now.
        if (html !== field.current.innerHTML) {
            field.current.innerHTML = html
        }

        //setLastHtml(html)
        //replaceCaret(field.current)

    }, [html])

    const emitChange = (originalEvt: React.SyntheticEvent<any>) => {
        if (!field?.current) return

        const _html = marked.parseInline(field.current.innerHTML)

        if (onChange && _html !== lastHtml) {
            // clone event with Object.assign to avoid
            // "Cannot assign to read only property 'target' of object"
            const evt = Object.assign({}, originalEvt, {
                target: {
                    value: _html
                }
            })
            onChange(evt)
        }

        setLastHtml(_html)
    }

    return (
        // @ts-ignore
        <Tag
            {...otherProps}
            ref={forkedRef}
            onInput={emitChange}
            onBlur={onBlur || emitChange}
            onKeyUp={onKeyUp || emitChange}
            onKeyDown={onKeyDown || emitChange}
            contentEditable={!disabled}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    )
}))

export { ContentEditable }

import { makeAutoObservable, runInAction } from 'mobx'
import { decorate, inject, injectable } from '@evertel/di'
import { Api } from '@evertel/api'
import { SessionState } from '@evertel/session'
import { BlueUserStore } from '../store'
import { DepartmentsAccessStore } from '@evertel/departments-access'
import { DepartmentStore } from '@evertel/department'
import { differenceBy } from 'lodash'

const CURRENT_USER_POLL_INTERVAL_MS = 1000 * 25 // 25 seconds
const CURRENT_USER_POLL_ENABLED = true

class CurrentUserPollService {
    private pollInterval = undefined // holds the setInterval timer

    constructor(
        private api: Api,
        private sessionState: SessionState,
        private userStore: BlueUserStore,
        private departmentsAccessStore: DepartmentsAccessStore,
        private departmentStore: DepartmentStore
    ) {
        makeAutoObservable(this)
    }

    startPoll = () => {
        this.stopPoll()
        if (!CURRENT_USER_POLL_ENABLED) return

        this.pollInterval = setInterval(() => {
            this.fetchUserDetails()
        }, CURRENT_USER_POLL_INTERVAL_MS)
    }

    stopPoll = () => {
        if (this.pollInterval) {
            clearInterval(this.pollInterval)
            this.pollInterval = undefined
        }
    }

    // Extend the interval timer if its set
    resetPollInterval =() => {
        if (this.pollInterval) {
            this.startPoll()
        }
    }

    async fetchUserDetails() {
        this.resetPollInterval()
        if (!this.sessionState.currentUserId) return

        const userDetails = await this.api.Routes.BlueUser.getById(this.sessionState.currentUserId, {
            include: [{
                relation: 'publicMedia'
            }, {
                relation: 'departmentsAccess',
                scope: {
                    include: [{
                        relation: 'assignment'
                    }, {
                        relation: 'position'
                    }, {
                        relation: 'department',
                        scope: {
                            include: ['publicMedia']
                        }
                    }]
                }
            }]
        })

        runInAction(() => {
            // Store this user
            this.userStore.update(userDetails)
            this.departmentsAccessStore.update(userDetails.departmentsAccess)
            this.departmentStore.update(userDetails.departmentsAccess.map(da => da.department))

            // We need to delete any departments access that doesn't exist in the remote data, but we have locally
            const localDa = this.departmentsAccessStore.find(o => o.blueUserId === userDetails.id)
            const deleteDa = differenceBy(localDa, userDetails.departmentsAccess, 'id')

            for (const da of deleteDa) {
                this.departmentsAccessStore.deleteById(da.id)
            }
        })
    }
}

decorate(injectable(), CurrentUserPollService)
decorate(inject(Api), CurrentUserPollService, 0)
decorate(inject(SessionState), CurrentUserPollService, 1)
decorate(inject(BlueUserStore), CurrentUserPollService, 2)
decorate(inject(DepartmentsAccessStore), CurrentUserPollService, 3)
decorate(inject(DepartmentStore), CurrentUserPollService, 4)

export { CurrentUserPollService }
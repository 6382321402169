import React from 'react'
import { createContext, useState, useCallback, ReactElement, useContext } from 'react'
import { Confirm, Text, AppLoader, PermissionAlert, NetworkAlert } from './elements'
import { ToastContainer, Toast, ToasterHeader, ToasterBody, ToastService } from './elements/toaster'
import { useService } from '@evertel/di'

type ToggleIntent = 'open' | 'toggle' | 'close'
export interface ConfirmProps {
    title?: string,
    message?: string,
    isOpen?: boolean,
    proceed?: (value?: unknown | undefined) => void,
    cancel?: () => void,
    cancelButton?: {
        label: string,
        color: string,
        onClick?: () => void
    },
    acceptButton?: {
        label: string,
        color: string,
        onClick?: () => void
    },
    headerClassName?: string,
    forceChoice?: boolean
}

export interface EToasterProps {
    visible?: boolean,
    title?: string,
    message: string,
    autohide?: boolean,
    animation?: boolean,
    className?: string,
    color?: string,
    delay?: number,
    closeButton?: boolean,
    onShow?: () => void,
    onClose?: () => void
}
export interface AppLoaderProps {
    name: string,
    type?: string,
    isOpen: boolean,
    children?: JSX.Element
}

export interface AppLoaderProps {
    name: string,
    isOpen: boolean,
    type?: string,
    children?: JSX.Element
}

export interface HeaderAlertProps {
    type: string | undefined,
    isOpen: boolean
}

export interface UIContextProps {
    confirm?: ConfirmProps,
    setConfirm: (config: ConfirmProps) => any,
    isConfirmed: ({ title, message, cancelButton, acceptButton, headerClassName }: IsConfirmedProps) => Promise<boolean>,
    toasts?: EToasterProps,
    addToast: (config: EToasterProps) => void,
    toggleLeftNav: (intent: ToggleIntent) => void,
    leftNavOpen?: boolean,
    appLoader?: AppLoaderProps,
    setAppLoader: (config: AppLoaderProps) => void,
    showAppLoader: ({ name, isOpen }: ShowAppLoaderProps) => void,
    alertBar?: HeaderAlertProps,
    showAlertBar: ({ type, isOpen }: HeaderAlertProps) => void,
    closeAlertBar: () => void
}


export const UIContext = createContext<UIContextProps>({
    setConfirm: (config: ConfirmProps) => {
        //
    },
    addToast: (config: EToasterProps) => {
        //
    },
    toggleLeftNav: (intent: ToggleIntent) => {
        //
    },
    isConfirmed: (config: IsConfirmedProps): any => {
        //
    },
    setAppLoader: (config: AppLoaderProps) => {
        //
    },
    showAppLoader: () => {
        //
    },
    showAlertBar: () => {
        //
    },
    closeAlertBar: () => {
        //
    }
})

type IsConfirmedProps = Omit<ConfirmProps, 'isOpen, proceed, cancel'>
type ShowAppLoaderProps = Omit<AppLoaderProps, 'type'>

export const UIContextProvider: React.FC<{children?: JSX.Element}> = ({
    children
}) => {

    const [toast, setToast] = useState<ReactElement>()
    const [confirm, setConfirm] = useState<ConfirmProps>()
    const [leftNavOpen, setLeftNavOpen] = useState(true)
    const [appLoader, setAppLoader] = useState<AppLoaderProps>()
    const [alertBar, setAlertBar] = useState<HeaderAlertProps>()

    const addToast = useCallback((toastProps) => {
        setToast(<Toaster {...toastProps} />)
    }, [setToast])


    const toggleLeftNav = (intent: 'open' | 'toggle' | 'close' = 'toggle') => {
        // opens/closes the left nav
        if (intent === 'toggle') {
            requestAnimationFrame(() => {
                document.body.classList.toggle('drawer-hide')
            })
            setLeftNavOpen(!leftNavOpen)
        } else if (intent === 'open') {
            requestAnimationFrame(() => {
                document.body.classList.remove('drawer-hide')
            })
            setLeftNavOpen(true)
        } else {
            requestAnimationFrame(() => {
                document.body.classList.add('drawer-hide')
            })
            setLeftNavOpen(false)
        }
    }

    const isConfirmed = ({
        title,
        message,
        cancelButton,
        acceptButton,
        headerClassName,
        forceChoice
    }: IsConfirmedProps): Promise<boolean> => {

        const promise = new Promise((resolve, reject) => {
            setConfirm({
                title, // string
                message, // string
                cancelButton, // {label: '', color: '', ...buttonProps}
                acceptButton, // {label: '', color: '', ...buttonProps}
                headerClassName, // string
                forceChoice, // bool
                isOpen: true, // bool
                proceed: resolve, // func
                cancel: reject // func
            })
        })

        return promise.then(
            () => {
                setConfirm((prevState) => ({
                    ...prevState,
                    isOpen: false
                }))
                return true
            },
            () => {
                setConfirm((prevState) => ({
                    ...prevState,
                    isOpen: false
                }))
                return false
            }
        )
    }

    const showAppLoader = ({
        name,
        isOpen
    }: ShowAppLoaderProps) => {
        setAppLoader({
            name,
            isOpen
        })
    }

    const showAlertBar = ({ type, isOpen }: HeaderAlertProps) => {
        setAlertBar({
            type,
            isOpen
        })
    }

    const closeAlertBar = () => {
        setAlertBar({
            type: '',
            isOpen: false
        })
    }

    return (
        <UIContext.Provider value={{
            confirm,
            setConfirm,
            isConfirmed,
            addToast,
            toggleLeftNav,
            leftNavOpen,
            showAppLoader,
            appLoader,
            setAppLoader,
            showAlertBar,
            alertBar,
            closeAlertBar
        }}>
            <Confirm {...confirm} />
            <AppLoader {...appLoader} />
            <PermissionAlert {...alertBar} />
            <NetworkAlert />
            <ToastRegistrar addToast={addToast} />
            <ToastContainer
                placement="top-end"
                push={toast}
            />
            {children}
        </UIContext.Provider>
    )
}

export const UIConsumer = UIContext.Consumer

export const useUI = () => {
    return useContext(UIContext)
}

export const ToastRegistrar: React.FC<{ addToast: (config: EToasterProps) => void }> = ({
    addToast
}) => {

    const toastService = useService(ToastService)

    React.useEffect(() => {
        toastService.registerAddToast(addToast)
    }, [addToast])
  
    return null
}

const Toaster: React.FC<EToasterProps> = ({
    ...toastProps
}) => {
    return (
        <Toast {...toastProps} animation="slide">
            {(toastProps?.title) &&
                <ToasterHeader closeButton={true}>
                    <Text
                        //color={(lightOrDark(color) === 'light') ? 'primary' : 'white'}
                        color="white"
                        bold>
                        {toastProps.title}
                    </Text>
                </ToasterHeader>
            }
            <ToasterBody>
                <Text
                    //color={(lightOrDark(color) === 'light') ? 'primary' : 'white'}
                    color="white">
                    {toastProps?.message}
                </Text>
            </ToasterBody>
        </Toast>
    )
}

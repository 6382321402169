// formats total bytes into freindly file size
export function formatBytes(bytes, decimals) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// returns number formatted with commas
export function formatNumber(num) {
    if (!num) return
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

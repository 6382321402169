// TODO: Eliminate the use of this file, our base api is set in ../api/index.ts
// Default HOST is staging
let backendHost = process.env.BLUE_API || 'https://staging-api.evertelapp.com/api'

const hostname = window && window.location && window.location.hostname

if (process.env.REACT_APP_API_ENV === 'local') {
    backendHost = 'http://localhost:3000/api'
}

// Production API
if (hostname === 'evertelapp.com' || hostname === 'www.evertelapp.com') {
    backendHost = 'https://api.evertelapp.com/api'
}

if (hostname === 'dev.evertelapp.com') {
    backendHost = 'https://dev-api.evertelapp.com/api'
}

const API_ROOT = `${backendHost}`

const GOOGLE_API_KEY = 'AIzaSyChZxzna4W6pmBoe3PFibh9saY_DOU1T3Q'

export {
    API_ROOT, GOOGLE_API_KEY
}

import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'
import { Colors } from '../../prop-types'

interface CardProps extends HTMLAttributes<HTMLDivElement | HTMLButtonElement> {
    className?: string
    color?: Colors
    textColor?: string
    as?: 'div' | 'button'
}

const Card = forwardRef<HTMLDivElement | HTMLButtonElement, CardProps>(({
    children,
    className,
    color,
    textColor,
    as = 'div',
    ...otherProps
}, ref) => {

    const _className = classNames(
        'card',
        {
            [`bg-${color}`]: color,
            [`text-${textColor}`]: textColor,
            'cursor-pointer': otherProps.onClick || as === 'button'
        },
        className
    )

    const Element = as

    return (
        <Element
            ref={ref}
            className={_className}
            {...(as === 'button' ? { type: 'button' } : {})}
            {...otherProps}>
            {children}
        </Element>
    )
})

export { Card }
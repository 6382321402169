import { Agent } from '@evertel/types'
import { Moment } from 'moment'

export default class Department {
    constructor(private getAgent: () => Agent) {}

    get = (deptId: number, filter: { include: string[] } | undefined) => this.getAgent().get(`Departments/${deptId}`, {params: {filter}})
    update = (deptId: number, body: unknown) => this.getAgent().put(`Departments/${deptId}`, body)
    create = (body: unknown) => this.getAgent().post(`Departments`, body) // admin only

    getVerificationCode = (deptId: number) => this.getAgent().get(`Departments/${deptId}/verificationCode`)
    getPositions = (type: undefined) => this.getAgent().get(`Departments/positions`, {params: {type}})
    getAssignments = (type: undefined) => this.getAgent().get(`Departments/assignments`, {params: {type}})
    createDemo = (data: unknown) => this.getAgent().post(`Departments/demo`, data)
    verifyCode = (deptId: any, code: any) => this.getAgent().get(`Departments/${deptId}/verify/${code}`)
    verifyUserWithCode = (deptId: any, code: any) => this.getAgent().put(`Departments/${deptId}/verify/${code}`)
    joinDepartment = (deptId: any, userId: number) => this.getAgent().put(`Departments/${deptId}/users/${userId}`)
    requestAccess = (deptId: any, email: unknown) => this.getAgent().post(`Departments/${deptId}/requestAccess`, email)
    postPublicImage = (deptId: any) => this.getAgent().post(`Departments/${deptId}/publicImage`)

    // CHILD DEPTS
    getChildDepartments = (deptId: number, filter: any) => this.getAgent().get(`Departments/${deptId}/childDepartments`, {params: {filter}})

    // ROOMS
    getRoomConfigs = (deptId: number) => this.getAgent().get(`Departments/${deptId}/predefinedRoomConfigs`)
    addRoom = (deptId: number, data: unknown) => this.getAgent().post(`Departments/${deptId}/room`, data)

    // CRM
    getCRMdata = (deptId: number, filter: undefined) => this.getAgent().get(`Departments/${deptId}/CRMData`, {params: {filter}})
    getCRMdataCount = (deptId: any, where: any) => this.getAgent().get(`Departments/${deptId}/CRMData/count`, {params: {where}})
    postCRMdata = (deptId: number, data: unknown) => this.getAgent().post(`Departments/${deptId}/CRMData`, data)
    updateCRMdata = (deptId: number, CRMid: any, data: unknown) => this.getAgent().put(`Departments/${deptId}/CRMData/${CRMid}`, data)
    deleteCRMdata = (deptId: number, CRMid: any) => this.getAgent().delete(`Departments/${deptId}/CRMData/${CRMid}`)

    // INVITES
    sendInvites = (deptId: any, body: unknown) => this.getAgent().post(`Departments/${deptId}/sendInvites`, body)
    createInvite = (deptId: number, body: unknown) => this.getAgent().post(`Departments/${deptId}/invites`, body)
    getInvites = (deptId: number, filter: any) => this.getAgent().get(`Departments/${deptId}/invites`, {params: {filter}})
    deleteInvite = (deptId: number, inviteId: any) => this.getAgent().delete(`Departments/${deptId}/invites/${inviteId}`)

    // DEPARTMENT ROOMS
    getRooms = (deptId: number, filter: { filter?: { scope: { fields: string[] } }; where?: any; include?: any; order?: any; limit?: any; skip?: number }) => this.getAgent().get(`Departments/${deptId}/rooms`, {params: {filter}})
    getRoomsCount = (deptId: number, where: any) => this.getAgent().get(`Departments/${deptId}/rooms/count`, {params: {where}})

    // SEARCH (ADMIN ONLY)
    getDepartmentsBySearch = (filter: { where: { name: { like: string } }; order: string }) => this.getAgent().get(`Departments`, {params: {filter}})
    getDepartmentsCount = (where: { where: { isDemo: boolean; options: { regexp: string }; subscriptionExpiresDate: { gt: Moment } } }) => this.getAgent().get(`Departments/count`, {params: {where}}) //admin only

    // DEPARTMENT USERS
    getUsers = (deptId: any, filter: any) => this.getAgent().get(`Departments/${deptId}/users`, {params: {filter}})
    getUsersBySearch = (deptId: any, filter: { order: any; limit: number; skip: number; fields: string[]; include: { relation: string; scope: { fields: string[]; include: ({ relation: string; scope: { fields: string[] } } | { relation: string; scope?: undefined })[] } }[]; where: any }) => this.getAgent().get(`Departments/${deptId}/users/search`, {params: {filter}})
    getUsersCountBySearch = (deptId: any, where: { where: any }) => this.getAgent().get(`Departments/${deptId}/users/search/count`, {params: {where}})
    getUsersCount = (deptId: any, where: any) => this.getAgent().get(`Departments/${deptId}/users/count`, {params: {where}})
    getAvailableCapacities = (deptId: number) => this.getAgent().get(`Departments/${deptId}/availableCapacities`)
    updateUserDetails = (deptId: number, userId: number, body: unknown) => this.getAgent().put(`Departments/${deptId}/users/${userId}/details`, body )
    leaveDepartment = (deptId: number, userId: number) => this.getAgent().delete(`Departments/${deptId}/users/${userId}`)
    updateUserVerified = (deptId: number, userId: number, params: any) => this.getAgent().put(`Departments/${deptId}/users/${userId}/verify`, null, {params: params})
    setRole = (deptId: number, userId: number, params: any) => this.getAgent().put(`Departments/${deptId}/users/${userId}/roles`, null, {params: params})
    deleteRole = (deptId: number, userId: number, role: any) => this.getAgent().delete(`Departments/${deptId}/users/${userId}/roles?role=${role}`)
    getUsersByDepartmentsAccess = (filter: any) => this.getAgent().get(`DepartmentUserThrough`, {params: {filter}}) // admin only
    getUsersAccess = (deptId: any, filter: { filter: any }) => this.getAgent().get(`Departments/${deptId}/usersAccess`, {params: {filter}}) // admin only
    getUsersAccessCount = (deptId: any, where: any) => this.getAgent().get(`Departments/${deptId}/usersAccess/count`, {params: {where}}) // admin only
    getLinkedDepartmentUsersBySearch = (deptId: any, filter: any) => this.getAgent().get(`Departments/${deptId}/users/linked/search`, {params: {filter}})
    getLinkedDepartmentUsersCountBySearch = (deptId: any, where: any) => this.getAgent().get(`Departments/${deptId}/users/linked/search/count`, {params: {where}})

    // REPORTS
    getReportRoomMessagesCount = (deptId: number) => this.getAgent().get(`Departments/${deptId}/reports/roomMessages/count`)
    getReportDirectMessagesCount = (deptId: number) => this.getAgent().get(`Departments/${deptId}/reports/directMessages/count`)
    getReportRoomMessageCountPerDay = (deptId: number, startDate?: Date, endDate?: Date) => this.getAgent().get(`Departments/${deptId}/reports/roomMessages/countPerDay`, { params: { startDate, endDate }})
    getReportDirectMessageCountPerDay = (deptId: number, startDate?: Date, endDate?: Date) => this.getAgent().get(`Departments/${deptId}/reports/directMessages/countPerDay`, { params: { startDate, endDate }})
    getReportUsersWithMessageCounts = (deptId: any, filter: { filter: { where: { id: number } } } | null) => this.getAgent().get(`Departments/${deptId}/reports/usersWithMessageCounts`, {params: filter})
    getReportUsersWithMessageCountsCount = (deptId: any, where: unknown) => this.getAgent().get(`Departments/${deptId}/reports/usersWithMessageCounts/count`, {params: {where}})
    getReportMessageCountsByRole = (deptId: any) => this.getAgent().get(`Departments/${deptId}/reports/messageCountsByRole`)
    getReportMessageCountsByRoom = (deptId: any, params: null) => this.getAgent().get(`Departments/${deptId}/reports/allRoomsWithMessageCounts`, {params: params})
    getReportSubscriptionsExpiration = (daysRange: any) => this.getAgent().get(`Departments/reports/subscriptionsExpiration`, {params: { daysRange }})

    // AUDITS & HISTORY LOGS
    postAuditPreview = (deptId: any, data: unknown) => this.getAgent().post(`Departments/${deptId}/audits/preview`, data)
    postAudit = (deptId: any, data: unknown) => this.getAgent().post(`Departments/${deptId}/audits/run`, data)
    getAudits = (deptId: number, filter: any) => this.getAgent().get(`Departments/${deptId}/audits`, {params: {filter}})
    getAuditsCount = (deptId: number, filter: any) => this.getAgent().get(`Departments/${deptId}/audits/count`, {params: {filter}})
    getAuditById = (deptId: any, auditId: any) => this.getAgent().get(`Departments/${deptId}/audits/${auditId}`)
    getAuditDownload = (deptId: any, auditId: any) => this.getAgent().get(`Departments/${deptId}/audits/${auditId}/download`)
    getHistory = (deptId: number, filter: { include: string[]; limit: any; skip: number; order: string }) => this.getAgent().get(`Departments/${deptId}/history`, {params: {filter}})
    getHistoryCount = (deptId: number, where: { description: { like: string } }) => this.getAgent().get(`Departments/${deptId}/history/count`, {params: {where}})

    // EMOJIS
    getEmojis = (deptId: number, filter: undefined) => this.getAgent().get(`Departments/${deptId}/emojis`, {params: {filter}})
    createEmoji = (deptId: any, body: unknown) => this.getAgent().post(`Departments/${deptId}/emojis`, body)
    getEmojiById = (deptId: any, emojiId: any) => this.getAgent().get(`Departments/${deptId}/emojis/${emojiId}`)
    updateEmoji = (deptId: any, emojiId: any) => this.getAgent().put(`Departments/${deptId}/emojis/${emojiId}`)
    uploadEmoji = (deptId: any, emojiId: any) => this.getAgent().post(`Departments/${deptId}/emojis/${emojiId}/image/upload`)

    // GOOGLE APIs
    getGeopointFromAddress = (address: string, googleMapsAPIKey: any) => fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleMapsAPIKey}`).then(response => response.json())
}

import React from 'react'
import { ElementType, forwardRef } from 'react'
import classNames from 'classnames'
import { Link, LinkProps } from '../Link'

export interface ButtonDropdownItemProps extends LinkProps {
    className?: string
    /** Component used for the root node. Either a string to use a HTML element or a component. */
    component?: string|ElementType
}

const ButtonDropdownItem = forwardRef<HTMLButtonElement|HTMLAnchorElement, ButtonDropdownItemProps>(({
    children,
    className,
    component = 'a',
    ...otherProps
}, ref) => {

    const _className = classNames('drop-item', className)

    if (otherProps.disabled === true) {
        otherProps.onClick = null
    }

    return (
        <Link
            ref={ref}
            className={_className}
            component={component}
            {...otherProps}>
            {children}
        </Link>
    )
})

export default ButtonDropdownItem
ButtonDropdownItem.displayName = 'ButtonDropdownItem'

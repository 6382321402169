import { observable, action, runInAction, makeObservable } from 'mobx'
import api from '../api'
import AppStore from './AppStore'

class DepartmentReportsStore {
    isBusy = false
    error = null

    constructor() {
        makeObservable(this, {
            isBusy: observable,
            error: observable,
            getUserMessageCounts: action,
            getUsersWithMessageCounts: action,
            getUsersWithMessageCountsCount: action,
            getUserMessageCountsByRole: action,
            getReportRoomMessageCountPerDay: action,
            getReportDirectMessageCountPerDay: action
        })


    }

    async getUserMessageCounts(departmentId, filter = null) {
        this.isBusy = true
        this.error = null

        try {
            const messageCounts = await api.Routes.Department.getReportsAllRoomsWithMessageCounts(departmentId, filter)

            runInAction(() => {
                this.isBusy = false
            })

            return messageCounts
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API get', message: 'DepartmentReportsStore.getUserMessageCounts()', error: e.message || e})
            return
        }
    }

    async getUsersWithMessageCounts(departmentId, filter = null, startDate, endDate) {
        this.isBusy = true
        this.error = null

        try {
            const users = await api.Routes.Department.getReportsUsersWithMessageCounts(departmentId, filter, startDate, endDate)

            runInAction(() => {
                this.isBusy = false
            })

            return users
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({
                type: 'API get',
                message: 'DepartmentReportsStore.getUsersWithMessageCounts()',
                error: e.message || e
            })
            return
        }
    }

    async getUsersWithMessageCountsCount(departmentId, params = null) {
        this.isBusy = true
        this.error = null

        try {
            const count = (await api.Routes.Department.getReportsUsersWithMessageCountsCount(departmentId, params)).count

            runInAction(() => {
                this.isBusy = false
            })

            return count
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API get', message: 'DepartmentReportsStore.getUserMessageCountsCount()', error: e.message || e})
            return
        }
    }

    async getUserMessageCountsByRole(departmentId) {
        this.isBusy = true
        this.error = null

        try {
            const count = await api.Routes.Department.getReportsMessageCountsByRole(departmentId)

            runInAction(() => {
                this.isBusy = false
            })

            return count
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API get', message: 'DepartmentReportsStore.getUserMessageCountsByRole()', error: e.message || e})
            return
        }
    }

    async getReportRoomMessageCountPerDay(departmentId, startDate = null, endDate = null) {
        this.isBusy = true
        this.error = null

        try {
            const count = await api.Routes.Department.getReportsRoomMessagesCountPerDay(departmentId, startDate, endDate)

            runInAction(() => {
                this.isBusy = false
            })

            return count
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API get', message: 'DepartmentReportsStore.getReportRoomMessageCountPerDay()', error: e.message || e})
            return
        }
    }

    async getReportDirectMessageCountPerDay(departmentId, startDate = null, endDate = null) {
        this.isBusy = true
        this.error = null

        try {
            const count = await api.Routes.Department.getReportsDirectMessagesCountPerDay(departmentId, startDate, endDate)

            runInAction(() => {
                this.isBusy = false
            })

            return count
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API get', message: 'DepartmentReportsStore.getReportDirectMessageCounPerDay()', error: e.message || e})
            return
        }
    }
}

const delay = (s) => {
    return new Promise(resolve => setTimeout(resolve, s * 1000))
}

export default new DepartmentReportsStore()

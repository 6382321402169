import { ContainerModule } from '@evertel/di'
import { RoomDocumentsSearch, RoomsSearchController, RoomController, CloneRoomController, RoomSettingsController,
    RoomDirectorySearchController, SharedRoomController, RoomJoinRequestsController,
    RoomInvitesController, ICRoomSettingsController, JoinRoomController, CreateRoomController, RoomForwardedMessageController } from './controllers'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(RoomDocumentsSearch).toSelf(),
        bind(RoomsSearchController).toSelf(),
        bind(RoomController).toSelf(),
        bind(CloneRoomController).toSelf(),
        bind(RoomSettingsController).toSelf()
        bind(RoomDirectorySearchController).toSelf()
        bind(SharedRoomController).toSelf()
        bind(RoomJoinRequestsController).toSelf()
        bind(RoomInvitesController).toSelf()
        bind(ICRoomSettingsController).toSelf()
        bind(JoinRoomController).toSelf()
        bind(CreateRoomController).toSelf()
        bind(RoomForwardedMessageController).toSelf()
    })
]

export { DI_MODULES }

import * as React from 'react'
import { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
// evertel
import { useContainer, useService } from '@evertel/di'
import { DI_TYPES, WebAnalyticsStoreInterface, WebCurrentUserStoreInterface } from '@evertel/types'
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, InfoBox, Input, InputGroup, InputGroupText, Button, Icon, Text } from '@evertel/web/ui'
import { AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { DeviceState } from '@evertel/device'

interface StateType {
    error: any,
    phoneNumber?: string|undefined
    password: string,
    isLoading: boolean,
}

interface Props {
    isOpen: boolean,
    onClose: () => void,
    authenticatorId: string
}

const UnenrollMultiFactorModal: React.FC<Props> = observer(({
    isOpen = false,
    authenticatorId,
    onClose
}) => {

    const container = useContainer()

    const analyticsStore = useMemo(() => container.get<WebAnalyticsStoreInterface>(DI_TYPES.LegacyAnalyticsStore), [container])
    const currentUserStore = useMemo(() => container.get<WebCurrentUserStoreInterface>(DI_TYPES.LegacyCurrentUserStore), [container])
    const deviceState = useService(DeviceState, [])
    const authenticationController = useMemo(() => container.get(AuthenticationController), [container])

    const [state, setState] = useState<StateType>({
        error: null,
        password: '',
        isLoading: false
    })

    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        if (isOpen) {
            // reset state
            setState(() => ({
                error: null,
                password: '',
                isLoading: false
            }))

            analyticsStore.logModalView('/account/security/unenroll-mfa')
        }
    }, [isOpen])

    const submitUnenrollMfa = async () => {
        const { password } = state

        setState((prevState) => ({
            ...prevState,
            error: null
        }))

        if (!password || !password.trim()) {
            setState((prevState) => ({
                ...prevState,
                error: 'You must enter your password to unenroll in SMS'
            }))
            return
        }

        setState((prevState) => ({
            ...prevState,
            isLoading: true
        }))

        try {
            const email = await currentUserStore.email

            await authenticationController.deleteAuthenticator(
                currentUserStore.id,
                authenticatorId,
                email,
                password.trim(),
                deviceState.deviceToken
            )

            onClose()

        } catch (error: any) {
            setState((prevState) => ({
                ...prevState,
                error: error.message,
                isLoading: false
            }))
            return
        }

        setState((prevState) => ({
            ...prevState,
            isLoading: false
        }))
    }

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        setState((prevState) => ({
            ...prevState,
            [e?.target?.name]: e?.target?.value
        }))
    }

    const { password, isLoading, error } = state
    const authenticator = authenticationController.findAuthenticatorById(authenticatorId)

    return (
        <Modal
            visible={isOpen}
            className="join-dept-modal"
            onClose={onClose}
        >
            <ModalHeader title="Unenroll in SMS Authentication" />
            <ModalBody>
                <Row>
                    <Col>
                        {(error) &&
                            <InfoBox color="danger">
                                {error}
                            </InfoBox>
                        }

                        <Text className="pb-4">
                            Please enter your password to unenroll {authenticator?.name}
                        </Text>

                        <Text color="muted">
                            Login Password
                        </Text>

                        <InputGroup className='mb-4'>
                            <InputGroupText>
                                <Icon name="lock" />
                            </InputGroupText>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={password}
                                autocomplete="off"
                                placeholder="Type your current password"
                                onChange={onChange}
                            />
                            <InputGroupText className='btn-ghost-muted' tag="button" onClick={() => setShowPassword(!showPassword)}>
                                <Icon
                                    name={showPassword ? 'eye-slash' : 'eye'}
                                />
                            </InputGroupText>
                        </InputGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="text-center">
                <Row align="right">
                    <Col>
                        <Button
                            color='success'
                            onClick={submitUnenrollMfa}
                            disabled={isLoading}
                            loading={isLoading}>
                            <Text>Unenroll</Text>
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    )
})

export {
    UnenrollMultiFactorModal
} 
import { APIDataBlueUser, APIMetaDataBlueUserPropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

class BlueUserStore extends Store<APIDataBlueUser> {
    constructor() {
        super(APIMetaDataBlueUserPropertyNames)    
    }

    // update(data: APIDataBlueUser | APIDataBlueUser[]): void {
    //     super.update
    // }
}

decorate(injectable(), BlueUserStore)

export { BlueUserStore }
import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
// evertel
import { DepartmentStore } from '../store'
import { ChildDepartmentStore } from '@evertel/stores'
import { Api } from '@evertel/api'
import { APIDataDepartment } from '@evertel/types'

class ChildDepartmentController {
    parentDepartmentId = 0
    childDepartmentIds = []

    constructor(
        private api: Api,
        private departmentStore: DepartmentStore,
        private childDepartmentStore: ChildDepartmentStore
    ) {
        makeAutoObservable(this)
    }

    init = (departmentId: number) => {
        this.parentDepartmentId = departmentId
        this.fetchDepartment()
        this.fetchChildDepartments()
    }

    fetchDepartment = async () => {
        if (!this.parentDepartmentId) return undefined

        let department = this.departmentStore.findById(this.parentDepartmentId)
        if (department) return department

        department = await this.api.Routes.Department.getById(this.parentDepartmentId)

        // save department in the departmentStore
        if (department) this.departmentStore.update(department)

        return department
    }

    fetchChildDepartments = async () => {
        if (!this.parentDepartmentId) return undefined

        try {
            const childDepartments = await this.api.Routes.Department.getChildDepartments(this.parentDepartmentId)

            if (Array.isArray(childDepartments) && childDepartments.length) {
                this.childDepartmentStore.update(childDepartments)

                const childDepartmentIds = childDepartments.map(child => child.id)

                runInAction(() => {
                    this.childDepartmentIds = childDepartmentIds
                })
            }
        } catch (e) {
            /* do nothing */
        }

        return this.childDepartments
    }

    get parentId() {
        return this.parentDepartmentId
    }

    get childIds() {
        return this.childDepartmentIds
    }

    get parentDepartment(): APIDataDepartment {
        return this.departmentStore.findById(this.parentDepartmentId)
    }

    get childDepartments(): APIDataDepartment[] {
        const departments = this.childDepartmentStore.findByIds(this.childDepartmentIds)
        return departments
    }
}

decorate(injectable(), ChildDepartmentController)
decorate(inject(Api), ChildDepartmentController, 0)
decorate(inject(DepartmentStore), ChildDepartmentController, 1)
decorate(inject(ChildDepartmentStore), ChildDepartmentController, 2)

export { ChildDepartmentController }
import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import api from '../../api'

import AppStore from '../AppStore'
import { localId } from '../../utilities'
import { getImageDimensions, getMediaType, getVideoMetadata } from '@evertel/utils'
import { mediaMultipartUpload } from '@evertel/media'
import { container } from '../../di'
import { SessionState } from '@evertel/session'
import { DeviceState } from '@evertel/device'

class Media {
    id = 0
    mimetype = undefined
    url = undefined
    requiresAuth = undefined
    description = undefined
    previewUrl = undefined
    fileName = undefined
    contentLength = 0
    meta = {}
    ownerId = 0
    localData  = undefined
    progressTotal = 1
    progressCompleted = 0
    isBusy = true
    error = null
    localStatus = undefined
    sessionState = undefined
    deviceState = undefined

    get progress() {
        return this.progressCompleted / this.progressTotal
    }

    get versions() {
        return (this.meta && this.meta.versions) || []
    }

    constructor(data) {
        this.sessionState = container.get(SessionState)
        this.deviceState = container.get(DeviceState)

        makeObservable(this, {
            id: observable,
            mimetype: observable,
            url: observable,
            requiresAuth: observable,
            description: observable,
            previewUrl: observable,
            fileName: observable,
            contentLength: observable,
            meta: observable,
            ownerId: observable,
            localData: observable.ref,
            progressTotal: observable,
            progressCompleted: observable,
            isBusy: observable,
            error: observable.ref,
            localStatus: observable,
            progress: computed,
            versions: computed,
            updateFromData: action('Media updateFromData'),
            updateProgress: action('Update progress'),
            upload: action('Media upload')
        })

        this.updateFromData(data)

        
    }

    static async fromLocalMedia(media, options = false) {
        //console.log('media instance of media', media instanceof Media, JSON.stringify({ media, options }, null, 2))

        if (media instanceof Media) return media // do nothing its already converted

        // archived: false
        // file: {
        //      lastModified: 1589256334699
        //      lastModifiedDate: Mon May 11 2020 21:05:34 GMT-0700 (Pacific Daylight Time)
        //      name: "juliaadolphe_jonathanadolphe-247x300.jpeg"
        //      size: 27122
        //      type: "image/jpeg"
        //      webkitRelativePath: ""
        //      _relativePath: ""
        // }
        // fileExtension: "jpeg"
        // fileSize: 27122
        // fileType: "image/jpeg"
        // filename: "juliaadolphe_jonathanadolphe-247x300.jpeg"
        // filenameWithoutExtension: "juliaadolphe_jonathanadolphe-247x300"
        // id: t4dub09om
        // origin: 1
        // relativePath: ""
        // released: true
        // serverId: null
        // source: {
        //      lastModified: 1589256334699
        //      lastModifiedDate: Mon May 11 2020 21:05:34 GMT-0700 (Pacific Daylight Time)
        //      name: "juliaadolphe_jonathanadolphe-247x300.jpeg"
        //      size: 27122
        //      type: "image/jpeg"
        //      webkitRelativePath: ""
        //      _relativePath: ""
        // }
        // status: 2
        // transferId: null

        // media.getMetadata = {
        //      color: null
        //      crop:
        //      aspectRatio: null
        //      center: {x: 0.5, y: 0.5}
        //      flip: {horizontal: false, vertical: false}
        //      rotation: 0
        //      zoom: 1
        //      output:
        //      client: (5) ["crop", "resize", "filter", "markup", "output"]
        //      quality: null
        //      type: "image/jpeg"
        //      resize: {
        //          mode: "cover"
        //          size:
        //              height: 960
        //              width: 960
        //          upscale: true
        //      }
        //}

        if (media) {
            let fileName = media.fileName || media.filename || media.file.name
            const extension = media.fileExtension
            const mimetype = media.mimetype || media.fileType || media.file.type || 'application/octet-stream'
            const url = URL.createObjectURL(media.file)
            const fileType = getMediaType(mimetype)
            const { fileSize, lastModified } = media
            const { width, height, duration } = (fileType === 'image') ? await getImageDimensions(url) : (fileType === 'video') ? await getVideoMetadata(url) : {}
            const orientation = (width > height) ? 'landscape' : 'portrait'

            const mediaObj = {
                id: localId(),
                mimetype,
                fileName,
                url,
                localData: {
                    extension,
                    file: media.file,
                    id: media.id
                },
                meta: { fileSize },
                ownerId: this.sessionState.currentUserId
            }

            if (fileType === 'image') mediaObj.meta = {...mediaObj.meta, orientation, height, width, lastModified}
            if (fileType === 'video') mediaObj.meta = {...mediaObj.meta, orientation, height, width, duration }

            //'fromLocalMedia media result', JSON.stringify(mediaObj, null, 2))
            return new Media(mediaObj)
        } else {
            //console.log('fromLocalMedia media result null')
            return null
        }


        // fromLocalMedia media result {
        //     "id": -1590530527233,
        //     "mimetype": "image/jpeg",
        //     "fileName": "IMG_3591 - Copy.JPG",
        //     "url": "blob:http://localhost:3000/0f00a2ba-d47c-491c-b065-7f3d06a70654",
        //     "localData": {
        //       "_relativePath": "",
        //       "extension": "JPG"
        //     },
        //     "meta": {
        //       "fileSize": 1312793,
        //       "orientation": "landscape",
        //       "height": 2538,
        //       "width": 3463,
        //       "duration": 12344 (if video)
        //     },
        //     "ownerId": 123
        // }
    }

    getClosestVersionByHeight(height) {
        // create a list of versions, we want to include hte original size in the
        // search/calculation too
        const versionsPlusOriginal = [...this.versions, {
            width: this.meta?.width || 400,
            height: this.meta?.height || 300,
            id: 'original'
        }]

        // compare each height and hold on to the closest match
        let closestHeightVersion = versionsPlusOriginal[0]
        for (const version of versionsPlusOriginal) {
            const a = closestHeightVersion
            const b = version
            closestHeightVersion = Math.abs(b.height - height) < Math.abs(a.height - height) ? b : a
        }

        // return the version, add url like getVersion does
        return ({
            ...closestHeightVersion,
            url: `${this.url}?ver=${closestHeightVersion.id}&u=${this.id}`
        })
    }

    getVersion(id) {
        const found = this.versions.find(v => v.id === id)

        // if found, return the relevant info and  add url
        if (found) {
            return {
                width: found.width,
                height: found.height,
                url: `${this.url}?ver=${found.id}&u=${this.id}`,
                id: found.id
            }
        }

        // make up an original if not found
        return {
            width: this.meta.width,
            height: this.meta.height,
            url: `${this.url}?ver=original&u=${this.id}`,
            id: 'original'
        }
    }

    updateFromData(data) {
        if (data.id) this.id = data.id
        if (data.mimetype) this.mimetype = data.mimetype
        if (data.url) this.url = data.url
        if (data.requiresAuth) this.requiresAuth = data.requiresAuth
        if (data.description) this.description = data.description
        if (data.previewUrl) this.previewUrl = data.previewUrl
        if (data.fileName) this.fileName = data.fileName
        if (data.contentLength) this.contentLength = data.contentLength
        if (data.meta) this.meta = data.meta
        if (data.ownerId) this.ownerId = data.ownerId
        if (data.localData) this.localData = data.localData
        if (data.progressTotal) this.progressTotal = data.progressTotal
        if (data.progressCompleted) this.progressCompleted = data.progressCompleted
        if (data.localStatus) this.localStatus = data.localStatus
        if (data.isBusy) this.isBusy = data.isBusy
        if (data.error) this.error = data.error
    }

    updateProgress(completed, total) {
        this.progressCompleted = completed
        this.progressTotal = total
    }

    async upload(url) {
        let uploadedMedia
        if (this.localStatus !== undefined && this.localStatus !== 'failed') return

        this.localStatus = 'uploading'
        this.isBusy = true
        this.error = null

        const [modelName] = url.split('/')
        const modelId = parseInt(modelName, 10)

        try {
            uploadedMedia = await mediaMultipartUpload(api,
                {
                    fileName: this.fileName,
                    mimeType: this.mimetype,
                    contentLength: this.localData.file.size,
                    meta: this.meta,
                    onGetPartFormData: async (partNumber, start, end) => {
                        const fileData = this.localData.file.slice(start, end)
                        const formData = new FormData()
                        formData.append('part', fileData)
                        return formData 
                    },
                    // onPartComplete?: AsyncOnPartCompleteFn
                    onProgress: this.updateProgress,
                    modelName,
                    modelId,
                    userId: this.sessionState.currentUserId,
                    deviceId: this.deviceState.deviceId
                })

            console.log('Media Upload', uploadedMedia)
            
            // don't let the server replace our local URL for a remote URL
            delete uploadedMedia.url

            runInAction(() => {
                this.isBusy = false
                this.progressTotal = 1
                this.progressCompleted = 1 // set 100% progress
                this.localStatus = 'complete'
                this.updateFromData(uploadedMedia)
            })

        } catch (error) {
            runInAction(() => {
                this.isBusy = false
                this.error = error
                this.localStatus = 'failed'
            })
        }
    }
}

export default Media


import { observer } from 'mobx-react'
// evertel

import {
    Icon,
    FloatingMenu, MenuItem
} from '@evertel/web/ui'
import { HTMLAttributes } from 'react'
import { useInvite } from '@evertel/web/invites'
import { useCreateRoom, useJoinRoom } from '@evertel/web/room'
import { useCreateThread } from '@evertel/web/thread'

interface HeaderButtonAddProps extends HTMLAttributes<HTMLButtonElement> {
    userRole?: string
}

const HeaderButtonAdd: React.FC<HeaderButtonAddProps> = observer(({
    userRole,
    ...otherProps
}) => {

    const { openInvite } = useInvite()
    const { openCreateRoom } = useCreateRoom()
    const { openCreateThread } = useCreateThread()
    const { openJoinRoom } = useJoinRoom()

    const openInviteModal = () => {
        if (userRole && userRole !== 'employee') {
            openInvite('member')
        } else {
            openInvite('contact')
        }
    }

    const isManagementRole = () => {
        return userRole && ['management', 'executive'].includes(userRole) 
    }

    const isGuestRole = () => {
        return userRole && userRole === 'guest'
    }

    return (
        <FloatingMenu
            label="Add"
            renderLabel={() => (
                <Icon
                    name="plus-circle"
                    color="muted"
                    size={16}
                />
            )}
            {...otherProps}
        >
            {!isGuestRole() && (
                <MenuItem
                    label="Join Room"
                    icon={{ name: 'circle-plus' }}
                    onClick={() => openJoinRoom()}
                />
            )}
            {isManagementRole() && (
                <MenuItem
                    label="Create Room"
                    icon={{ name: 'hashtag' }}
                    onClick={() => openCreateRoom()}
                />
            )}
            <MenuItem
                label="Send Direct Message"
                icon={{ name: 'comment-alt-lines' }}
                onClick={() => openCreateThread()}
            />
            {isManagementRole() && (
                <MenuItem
                    label="Invite People"
                    icon={{ name: 'users' }}
                    onClick={() => openInviteModal()}
                />
            )}
        </FloatingMenu>
    )
})

export { HeaderButtonAdd }

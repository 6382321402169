import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { APIDataDocumentSchemaCategory } from '@evertel/types'
import { Api } from '@evertel/api'


class DepartmentSchemaCategoriesController {
    departmentId = 0
    categories = []

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = async (departmentId: number) => {
        this.departmentId = departmentId
        
        await this.fetchDepartmentSchemaCategories()
    }

    fetchDepartmentSchemaCategories = async (): Promise<APIDataDocumentSchemaCategory[]> => {
        if (!this.departmentId) return []

        // fetch schemas
        const categories = await this.api.Routes.Department.getDocumentSchemaCategories(this.departmentId, {
            order: 'name ASC'
        })

        // update observables
        runInAction(() => {
            this.categories = categories
        })

        return categories
    }
}

decorate(injectable(), DepartmentSchemaCategoriesController)
decorate(inject(Api), DepartmentSchemaCategoriesController, 0)

export { DepartmentSchemaCategoriesController }

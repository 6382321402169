import { APIDataDocumentACL, APIMetaDataDocumentACLPropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

class DocumentACLStore extends Store<APIDataDocumentACL> {
    constructor() {
        super(APIMetaDataDocumentACLPropertyNames)    
    }
}

decorate(injectable(), DocumentACLStore)

export { DocumentACLStore }
import React, { useEffect, useState } from 'react'
import ReactPasswordStrength from 'react-password-strength'
import classNames from 'classnames'
// evertel
import { InfoBox, Col, Input, InputGroup, InputGroupText, Row, Modal, ModalBody, ModalHeader, ModalFooter, Button, Icon, SuccessCheckmark, Text } from '@evertel/web/ui'
import { observer } from 'mobx-react-lite'
import { AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { ResetPasswordController } from '../controller'

interface ResetPasswordModalProps {
    isOpen: boolean,
    onClose: () => void
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = observer(({
    isOpen,
    onClose
}) => {

    const authenticationController = useService(AuthenticationController, [])
    const resetPasswordController = useService(ResetPasswordController, [])
    const session = useService(SessionState, [])

    const [error, setError] = useState('')
    const [passwordFields, setPasswordFields] = useState({
        oldPassword: '',
        confirmation: '',
        showMinPasswordLevel: true,
        newPassword: '',
        isPasswordValid: false,
        passwordSuggestions: [],
        passwordWarning: ''
    })

    const currentUserId = session.currentUserId
    const [passwordsMatch, setPasswordsMatch] = useState(true)
    const [passwordMatchError, setPasswordMatchError] = useState(false)
    const [passwordHasBeenReset, setPasswordHasBeenReset] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    useState(() => {
        resetPasswordController.init(currentUserId)
    })

    useEffect(() => {
        comparePasswords()
    }, [passwordFields.newPassword, passwordFields.confirmation])

    const submitPasswordReset = async () => {
        const { oldPassword, newPassword, confirmation } = passwordFields

        //make sure all fields are filled out
        if (!oldPassword || !newPassword || !confirmation) {
            setError('Please fill out all fields')
            return
        }

        const body = {
            oldPassword: oldPassword.trim(),
            newPassword: newPassword.trim(),
            confirmation: confirmation.trim()
        }


        try {
            setIsLoading(true)

            await authenticationController.changePassword(session.currentUserId, body)

            setIsLoading(false)
            setPasswordHasBeenReset(true)

            // auto close modal after checkmark animation is done
            setTimeout(() => {
                //user needs to be retrieved again, refresh should do it
                window.location.reload()
            }, 2000)

        } catch (error: any) {
            if (typeof error === 'string' ) {
                setError(error)
            } else if (error?.message) {
                setError(error.message)
            } else {
                setError('There was an error chaging the password.')
            }
            setIsLoading(false)
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordFields({
            ...passwordFields,
            [e.target.name]: e.target.value
        })
    }

    const comparePasswords = () => {
        setPasswordsMatch(passwordFields.confirmation === passwordFields.newPassword)
    }

    const onPasswordChange = (e: any, result: any) => {
        setPasswordFields((prevState) => ({
            ...prevState,
            showMinPasswordLevel: true,
            newPassword: e.password,
            isPasswordValid: e.isValid,
            passwordSuggestions: result && result.feedback.suggestions,
            passwordWarning: result && result.feedback.warning
        }))
    }

    const handleShowPassword = (target: string) => {
        if (target === 'confirmation') {
            setShowConfirmPassword(!showConfirmPassword)
        } else if (target === 'old') {
            setShowOldPassword(!showOldPassword)
        } else {
            setShowPassword(!showPassword)
        }
    }

    const daysBeforeExpires = resetPasswordController.passwordTTLdays
    const departmentMaxPasswordAge = resetPasswordController.minimumDepartmentPasswordTTLdays
    const hasExpired = (daysBeforeExpires !== undefined) && daysBeforeExpires < 1
    const neverExpires = !daysBeforeExpires

    const onCheckClose = () => {
        const daysRemaining = resetPasswordController.passwordTTLdays
        if ((daysRemaining === undefined) || daysRemaining >= 1) {
            //only allow closing if it's not expired
            onClose()
        }
    }

    const { oldPassword, newPassword, confirmation } = passwordFields

    return (
        <Modal
            className="join-dept-modal"
            visible={isOpen}
            onClose={onCheckClose}
            backdrop={hasExpired ? 'static' : true}
        >
            <ModalHeader
                closeButton={!hasExpired}
                className={classNames('weight-900 h3 mr-3', { 'text-danger justify-content-center': hasExpired })}
                title={(hasExpired) ? 'Your password has expired!' : 'Reset your account password'}
            />
            <ModalBody>
                <Row>
                    <Col>
                        {!neverExpires &&
                            <InfoBox color="warning">
                                Your agency requires you to change your password every {departmentMaxPasswordAge} days.
                                <br /><strong>{hasExpired ? 'Please reset to a new password.' : `Your current password expires in ${daysBeforeExpires} days.`}</strong>
                            </InfoBox>
                        }
                        {/* ERROR BLOCK */}
                        {error &&
                            <InfoBox color="danger">
                                {error}
                            </InfoBox>
                        }

                        {/* OLD PASSWORD */}
                        <InputGroup className="mb-4">
                            <InputGroupText>
                                <Icon name="key" />
                            </InputGroupText>
                            <Input
                                type={showOldPassword ? 'text' : 'password'}
                                name="oldPassword"
                                value={oldPassword}
                                // autoComplete="off"
                                placeholder="Type your current (old) password"
                                onChange={onChange}

                            />
                            <InputGroupText className='btn-ghost-muted' tag="button" onClick={() => handleShowPassword('old')}>
                                <Icon
                                    name={showOldPassword ? 'eye-slash' : 'eye'}
                                />
                            </InputGroupText>
                        </InputGroup>

                        <hr />

                        {/* NEW PASSWORD */}
                        <div className="text-left pb-2">
                            Password must be:
                            <ul>
                                <li>A minimum of 8 characters</li>
                                <li>Not be a dictionary or proper word</li>
                                <li>Not be identical to the previous ten (10) passwords</li>
                            </ul>
                        </div>
                        <InputGroup>
                            <InputGroupText className={passwordFields.isPasswordValid ? 'bg-success border-success' : ''}>
                                <Icon name="lock" />
                            </InputGroupText>
                            <ReactPasswordStrength
                                minLength={8}
                                minScore={2}
                                scoreWords={['very weak', 'weak', 'good', 'strong', 'very strong']}
                                changeCallback={onPasswordChange}
                                inputProps={{
                                    type: showPassword ? 'text' : 'password',
                                    name: 'newPassword',
                                    autoComplete: 'off',
                                    placeholder: 'Create a new password',
                                    className: 'form-control no-focus'
                                }}
                            />
                            <InputGroupText className='btn-ghost-muted' tag="button" onClick={() => handleShowPassword('new')}>
                                <Icon
                                    name={showPassword ? 'eye-slash' : 'eye'}
                                />
                            </InputGroupText>
                        </InputGroup>
                        <div className="text-left pb-2">
                            <small className="text-muted">
                                {passwordFields.passwordWarning &&
                                    <div className="text-danger">{passwordFields.passwordWarning}.</div>
                                }
                                {passwordFields.passwordSuggestions ?
                                    passwordFields.passwordSuggestions.map((s, idx) =>
                                        <div key={idx}>{(idx === 0) && ' Suggestions:'}{' ' + s}</div>
                                    )
                                    : 'Minimum 8 characters.'
                                }
                                &nbsp;
                            </small>
                        </div>

                        {/* CONFIRM PASSWORD */}
                        <InputGroup>
                            <InputGroupText className={(confirmation && passwordsMatch) ? 'bg-success border-success' : (passwordFields.confirmation && !passwordsMatch) ? 'bg-danger border-danger' : ''}>
                                <Icon name="lock" />
                            </InputGroupText>
                            <Input
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmation"
                                value={confirmation}
                                autoComplete="off"
                                placeholder="Repeat new password"
                                onChange={onChange}
                            />
                            <InputGroupText className='btn-ghost-muted' tag="button" onClick={() => handleShowPassword('confirmation')}>
                                <Icon
                                    name={showConfirmPassword ? 'eye-slash' : 'eye'}
                                />
                            </InputGroupText>
                        </InputGroup>
                        {(confirmation && !passwordsMatch) &&
                            <div className="text-left pb-2 text-danger"><small>Your passwords do not match</small></div>
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {!passwordHasBeenReset ?
                    <Row align='right'>
                        <Button
                            onClick={submitPasswordReset}
                            className="btn btn-success btn-ladda float-right"
                            loading={isLoading}
                            disabled={!passwordFields.isPasswordValid || isLoading || !(newPassword && confirmation)}>
                            Update
                        </Button>
                    </Row>
                    :
                    <Row align='center'>
                        <SuccessCheckmark message={'Your password has been updated'} />
                    </Row>
                }
            </ModalFooter>
        </Modal>

    )
})

export { ResetPasswordModal }
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useParams } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader, Button, Text, Row, Col, useUI } from '@evertel/web/ui'
import { WriteWidgets } from '../schema-widgets'
import { WidgetsProvider, Document } from '@evertel/schema-parser'
import { APIDataDocumentSchema } from '@evertel/types'
import { DocumentIcon } from '../elements/DocumentIcon'
import { useService } from '@evertel/di'
import { CloneSchemaController } from '@evertel/document'

interface Props {
    visible?: boolean,
    schema: APIDataDocumentSchema,
    onClose: () => void,
    onUse: (schema: APIDataDocumentSchema) => void,
    isLoading: boolean
}

const SchemaPreviewModal: React.FC<Props> = observer(({
    visible,
    schema,
    onClose,
    onUse,
    isLoading
}) => {

    const { target } = useParams()
    const history = useHistory()
    const { addToast } = useUI()

    const cloneController = useService(CloneSchemaController, [])

    const onCloneSchema = async () => {
        const newName = schema.name + ' (copy)'

        try {
            const newSchema = await cloneController.cloneSchema(schema.id, 0, newName)
            onClose()
            setTimeout(() => history.push(`/everdocs/schema/edit/${newSchema.id}/global`))

        } catch (error: any) {
            addToast({
                color: 'danger',
                title: 'Clone Unsuccessful',
                message: error.message || error
            })
        }
    }

    return (
        <Modal
            visible={visible}
            onClose={(isLoading) ? undefined : onClose}
            size="xl">
            <ModalHeader
                className="bg-gray100"
                closeButton={!isLoading}>
                    <Row className="w-100">
                        <Col xs={1}>
                            <DocumentIcon
                                schema={schema}
                                width={60}
                                height={60}
                                className="mr-2"
                            />
                        </Col>
                        <Col>
                            <Text
                                tag="h3"
                                bold>
                                {schema?.name}
                            </Text>
                            <Text color="muted">
                                {schema?.description}
                            </Text>
                        </Col>
                        <Col align="right">
                            {(target === 'global') ?
                                <Row>
                                    <Button
                                        onClick={onCloneSchema}
                                        color="link"
                                        className="mr-5">
                                        <Text>
                                            Clone this Template
                                        </Text>
                                    </Button>
                                    <Button
                                        onClick={() => history.push(`/everdocs/schema/edit/${schema.id}/global`)}
                                        color="warning"
                                        className="mr-5">
                                        <Text>
                                            Edit this Template
                                        </Text>
                                    </Button>
                                </Row>
                            :
                                <Button
                                    onClick={() => onUse(schema as APIDataDocumentSchema)}
                                    color="info"
                                    className="mr-5"
                                    loading={isLoading}>
                                    <Text color="white">
                                        Use this Template
                                    </Text>
                                </Button>
                            }
                        </Col>
                    </Row>
            </ModalHeader>
            <ModalBody className="p-5">
                <WidgetsProvider value={WriteWidgets}>
                    {(schema?.schema) &&
                        <Document
                        layout="full"
                        schema={schema?.schema as any} 
                        value={undefined}                        
                    />}
                </WidgetsProvider>
            </ModalBody>
        </Modal>
    )
})

export {
    SchemaPreviewModal
}
import { APIDataThreadMessage, APIMetaDataThreadMessagePropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

class ThreadMessagesStore extends Store<APIDataThreadMessage> {
    constructor() {
        super([...APIMetaDataThreadMessagePropertyNames, 'media', 'reactions'])
    }
}

decorate(injectable(), ThreadMessagesStore)

export { ThreadMessagesStore }

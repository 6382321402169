
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
// evertel
import { useService } from '@evertel/di'
import { Col, Row, useUI, Text, InfoBox, Icon, MultilinePlaceholder } from '@evertel/web/ui'
import { SessionState } from '@evertel/session'
import { UserController } from '@evertel/blue-user'
import { DrawerNavDropdownLink, DrawerNavItemLink } from '../elements'
import { SelectedDepartmentAccessController } from '@evertel/departments-access'
import { CloneSchemaController, DepartmentDocumentSchemasController, DepartmentSchemaCategoriesController } from '@evertel/document'
import { APIDataDocumentSchema, APIDataDocumentSchemaCategory } from '@evertel/types'
import { DocumentIcon } from '@evertel/web/document'
import { Category } from './Category'
import { OptionsMenu } from './OptionsMenu'

interface DrawerProps {
    tag?: string
}


const DocumentsDrawer: React.FC<DrawerProps> = observer(() => {
    const session = useService(SessionState, [])
    const schemaCategoriesController = useService(DepartmentSchemaCategoriesController, [])
    const departmentDocumentSchemasController = useService(DepartmentDocumentSchemasController, [])
    const departmentsAccessController = useService(SelectedDepartmentAccessController, [])
    const userController = useService(UserController, [])
    const cloneController = useService(CloneSchemaController, [])

    const history = useHistory()
    const { addToast, toggleLeftNav } = useUI()

    const [isCategoryVisible, setIsCategoryVisible] = useState<(string|undefined)[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const canEditTemplates = ((departmentsAccessController.role === 'executive' || departmentsAccessController.role === 'management'))

    useEffect(() => {
        (async () => {
            if (session?.selectedDepartmentId) {
                setIsLoading(true)

                await schemaCategoriesController.init(session.selectedDepartmentId)
                await departmentDocumentSchemasController.init(session.selectedDepartmentId)
                userController.init(session.currentUserId)
                departmentsAccessController.init(session.currentUserId)

                setIsLoading(false)

                // set all categories to 'open'
                // const catNames = controller.categories?.map((cat: APIDataDocumentSchemaCategory) => cat.name)
                // setIsCategoryVisible(catNames)
            }
        })()
    }, [session?.selectedDepartmentId, session.currentUserId, schemaCategoriesController, departmentDocumentSchemasController, userController, departmentsAccessController])


    const onCloneSchema = async (schema: APIDataDocumentSchema) => {
        if (!schema?.id || !session?.selectedDepartmentId) return

        const newName = schema.name + ' (copy)'

        try {
            const newSchema = await cloneController.cloneSchema(schema.id, session?.selectedDepartmentId, newName)

            addToast({
                color: 'success',
                title: 'Your template has been cloned',
                message: 'Edit and enable this template when ready!'
            })

            toggleLeftNav('close')
            history.push(`/everdocs/schema/edit/${newSchema.id}`)

        } catch (error: any) {
            addToast({
                color: 'danger',
                title: 'Clone Unsuccessful',
                message: error.message || error
            })
        }
    }

    const onCreateDoc = (schemaId: number) => {
        toggleLeftNav('close')
        history.push(`/everdocs/add/${schemaId}`)
    }


    if (isLoading) {
        return (
            <MultilinePlaceholder sections={3} />
        )
    }

    if (departmentsAccessController.role === 'guest') {
        return null
    }

    return (
        <>
            {(canEditTemplates) &&
                <>
                    <DrawerNavItemLink
                        label="Manage Template Categories"
                        icon={{name: 'folder'}}
                        to='/everdocs/schema/categories'
                    />
                    <DrawerNavItemLink
                        label="Create a New Template"
                        icon={{name: 'plus-circle'}}
                        to='/everdocs/schema/add'
                    />
                    <DrawerNavItemLink
                        label="Browse Template Catalog"
                        icon={{name: 'rectangle-history'}}
                        to='/everdocs/schema/templates'
                        className="mb-4"
                    />
                </>
            }

            {/* ALL */}
            <Category
                schemas={departmentDocumentSchemasController.enabledDocumentSchemas}
                isVisible={false}
                onClick={onCreateDoc}
                onEditSchema={(schemaId) => history.push(`/everdocs/schema/edit/${schemaId}`)}
                onCloneSchema={onCloneSchema}
                canManage={canEditTemplates}
            />

            {schemaCategoriesController.categories?.map((category: APIDataDocumentSchemaCategory, index) =>
                <Category
                    key={`${category.id}-${index}`}
                    category={category}
                    isVisible={isCategoryVisible.includes(category.name as string)}
                    onClick={(schemaId) => onCreateDoc(schemaId)}
                    onEditSchema={(schemaId) => history.push(`/everdocs/schema/edit/${schemaId}`)}
                    onCloneSchema={onCloneSchema}
                    canManage={canEditTemplates}
                />
            )}

            {(canEditTemplates) &&
                <DrawerNavDropdownLink
                    label="Disabled Templates">
                    {departmentDocumentSchemasController.disabledDocumentSchemas?.map((schema: APIDataDocumentSchema) =>
                        <Row
                            key={schema.id}
                            align="between"
                            className="drawer-item-link">
                            <Row
                                valign="center"
                                style={{width: 240}}>
                                <DocumentIcon
                                    schema={schema}
                                    className="mr-2"
                                />
                                <Text
                                    color="gray300"
                                    italic
                                    className="text-truncate">
                                    {schema.name}
                                </Text>
                            </Row>
                            <Col align="right">
                                {(canEditTemplates) &&
                                    <OptionsMenu
                                        onEdit={(schemaId) => history.push(`/everdocs/schema/edit/${schemaId}`)}
                                        onClone={onCloneSchema}
                                        schema={schema}
                                        canManage={canEditTemplates}
                                    />
                                }
                            </Col>
                        </Row>
                    )}
                    {(!departmentDocumentSchemasController.disabledDocumentSchemas?.length) &&
                        <Row className="picker-item">
                            <Text
                                color="muted"
                                style={{fontStyle: 'italic'}}>
                                No documents in this category
                            </Text>
                        </Row>
                    }
                </DrawerNavDropdownLink>
            }

            {(canEditTemplates) &&
                <InfoBox
                    onClick={() => history.push('/everdocs/schema/templates')}
                    color="secondary"
                    className="cursor-pointer mt-5">
                    <Row className="mb-2">
                        <Icon
                            name="rectangle-history"
                            className="mr-2"
                            size={25}
                            color="blue"
                        />
                        <Text
                            //tag="h4"
                            bold
                            color="blue">
                            Template Catalog
                        </Text>
                        <Icon
                            name="chevron-double-right"
                            className="ml-2"
                            color="blue"
                            size={11}
                        />
                    </Row>
                    <Text>
                        Browse our catalog of pre-made EverDoc templates!
                    </Text>
                </InfoBox>
            }
        </>
    )
})

export { DocumentsDrawer }


import React, { forwardRef, useRef, InputHTMLAttributes, KeyboardEventHandler, ReactNode } from 'react'
import classNames from 'classnames'
import { useForkedRef } from '@evertel/hooks'

interface ToggleSwitchProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'|'onChange'> {
    id: string
    color?: string
    label?: string|ReactNode
    size?: 'lg'|'xl'
    checked?: boolean
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
    type?: 'checkbox'|'radio'
    optionLabels?: string[]
    disabled?: boolean
    onChange?: (checked: boolean) => void
}

const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>(({
    id,
    name,
    label,
    color = 'success',
    checked,
    onClick = (e) => { /* void */ },
    onChange,
    optionLabels = ['Yes', 'No'],
    disabled,
    ...otherProps
}, ref) => {


    const inputRef = useRef<HTMLDivElement>(null)
    const forkedRef = useForkedRef(ref, inputRef)

    const handleKeyPress: KeyboardEventHandler<HTMLLabelElement> = (e) => {
        if (e.key !==  ' ') return
    
        e.preventDefault()
        if (onChange) onChange(!checked)
    }

    const _id: string = id || 'i' + Math.random()
  
    return (
        <div {...otherProps} className={classNames('mr-2', otherProps.className)}>
            <div className="toggle-switch" onClick={(e) => { inputRef.current.focus(); onClick(e) } }>
                <input
                    ref={forkedRef}
                    type="checkbox"
                    name={name}
                    className="toggle-switch-checkbox"
                    id={_id}
                    checked={checked || false}
                    onChange={(e) => (onChange) && onChange(e.target.checked)}
                    disabled={disabled}
                />
                <label
                    className="toggle-switch-label"
                    onKeyDown={handleKeyPress}
                    htmlFor={_id}>
                    <span
                        className={
                            (disabled)
                                ? 'toggle-switch-inner toggle-switch-disabled'
                                : 'toggle-switch-inner'
                        }
                        data-yes={optionLabels[0]}
                        data-no={optionLabels[1]}
                    />
                    <span
                        className={
                            (disabled)
                                ? 'toggle-switch-switch toggle-switch-disabled'
                                : 'toggle-switch-switch'
                        }
                    />
                </label>
            </div>
            {(label && _id) &&
                <label htmlFor={_id}>{label}</label>
            }
        </div>
    )
})

export { ToggleSwitch }

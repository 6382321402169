
import { APIDataBlueUser } from '@evertel/types'
import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { uniqBy } from 'lodash'
import { SessionState } from '@evertel/session'
import { BlueUserStore } from '../store'

/**
 * admin = searches all users in database. *only admin roles can do this.
 * department = searches all users in the department.
 * public = searches all users in another department that are isSearchable.
 * contact = searches the current user's contacts/DMs list.
 */
export type SearchScopeProps = 'admin'|'department'|'public'|'contact'

const DEFAULT_LIMIT = 50

type InitParams = {
    searchScope: SearchScopeProps
    departmentId: number
    infiniteScroll?: boolean
    limit?: number
    filter?: Record<string, any>
    whereFilter?: Record<string, any>
}

class UsersSearchController {
    searchScope = 'department'
    departmentId = 0
    users: APIDataBlueUser[] = []
    usersLatestFetch: APIDataBlueUser[] = []
    usersCount = 0
    searchTerm = ''
    page = 1
    limit = DEFAULT_LIMIT
    infiniteScroll = false
    _filter = {}
    whereFilter = {}

    constructor(
        private api: Api,
        private session: SessionState,
        private userStore: BlueUserStore
    ) {
        makeAutoObservable(this)
    }

    init({
        searchScope,
        departmentId,
        infiniteScroll = false,
        filter = {}
    }: InitParams) {

        if ((!departmentId) && searchScope !== 'admin') {
            console.log('ERROR: USC-1')
            return
        }
        if (!['admin', 'department', 'public', 'contact'].includes(searchScope as SearchScopeProps)) {
            throw new Error('Invalid UserSearch type provided')
        }


        this.searchScope = searchScope
        this.infiniteScroll = infiniteScroll
        this.departmentId = departmentId
        this.setFilter(filter)

        this.resetSearch()

    }

    setSearchTerm(value: string) {
        this.resetSearchMeta()
        this.searchTerm = value
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    setLimit(limit: number) {
        this.limit = limit
    }

    setFilter(extraFilter: Record<string, any>) {
        this._filter = extraFilter
    }

    setWhereFilter(extraFilter: Record<string, any>) {
        this.whereFilter = extraFilter
    }

    search = async () => {
        if ((!this.departmentId) && this.searchScope !== 'admin') return

        let count = this.usersCount
        let newUsers = []

        // grab the total count of rooms for this search (once each new search)
        if (!this.usersCount) {
            count = await this.fetchUsersCount()
        }

        this.usersLatestFetch = []

        // stop if we already fetched them all
        if (this.users?.length < count) {

            try {
                if (this.searchScope === 'admin') {
                // search rooms the current user manages (includes all agencies by default)
                    newUsers = await this.api.Routes.BlueUser.getSearchAdmin(this.filter)

                } else if (this.searchScope === 'contact') {
                // search the current user's contacts/DM users
                    newUsers = await this.api.Routes.BlueUser.getSearchContacts(this.session?.currentUserId, this.filter)

                } else if (this.searchScope === 'department') {
                // search all users in the current department
                    newUsers = await this.api.Routes.Department.getUsersSearch(this.departmentId, this.filter)

                } else if (this.searchScope === 'public') {
                // search isSearchable users in another department
                    newUsers = await this.api.Routes.BlueUser.getSearchPublic(this.session.currentUserId, [this.departmentId], this.filter)
                }

                this.userStore.update(newUsers)

            } catch (e) {
                console.log('ERROR: /libs/shared/blue-user/UsersSearchController search()', e)
                throw e
            }
        }

        runInAction(() => {
            this.usersLatestFetch = newUsers
            this.users = (this.infiniteScroll) ? uniqBy([...this.users, ...newUsers], 'id') : newUsers
        })

        // we return the new array as some components only need this.
        // however, you should usually reference 'this.users' ovservable
        return this.usersLatestFetch

    }

    async fetchUsersCount() {
        let count = 0

        try {
            if (this.searchScope === 'admin') {
                count = (await this.api.Routes.BlueUser.getSearchAdminCount(this.filter.where)).count

            } else if (this.searchScope === 'contact') {
                count = (await this.api.Routes.BlueUser.getSearchContactsCount(this.session?.currentUserId, this.filter.where)).count

            } else if (this.searchScope === 'department') {
                count = (await this.api.Routes.Department.getUsersSearchCount(this.departmentId, this.filter.where)).count

            } else if (this.searchScope === 'public') {
                // public users
                count = (await this.api.Routes.BlueUser.getSearchPublicCount(this.session?.currentUserId, [this.departmentId], this.filter.where)).count
            }

            runInAction(() => {
                this.usersCount = count
            })
        } catch (e) {
            if (!this.api.isProduction) {
                console.log('ERROR: /libs/shared/UsersSearchController fetchUsersCount()', e)
            }
            throw e
        }

        runInAction(() => {
            this.usersCount = count ?? 0
        })

        return count ?? 0
    }

    loadNextPage(): void {
        this.page = this.page + 1
        this.search()
    }

    resetSearchMeta(): void {
        this.users = []
        this.usersCount = 0
        this.page = 1
    }

    resetSearch(): void {
        this.resetSearchMeta()
        this.searchTerm = ''
        this.limit = DEFAULT_LIMIT
    }

    get filter() {
        return {
            where: {
                searchTerm: this.searchTerm || '',
                and: [
                    this.whereFilter || {},
                    {or: [
                        { isBot: false },
                        { isBot: null }
                    ]}
                ]
            },
            order: 'lastName ASC',
            ...this._filter,
            //fields: ['id', 'name', 'departmentId'],
            skip: (this.page - 1) * this.limit,
            limit: this.limit
        }
    }
}

decorate(injectable(), UsersSearchController)
decorate(inject(Api), UsersSearchController, 0)
decorate(inject(SessionState), UsersSearchController, 1)
decorate(inject(BlueUserStore), UsersSearchController, 2)

export { UsersSearchController }
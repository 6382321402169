import { ContainerModule } from '@evertel/di'
import { BlueUserStore } from './store'
import { CurrentUserController, UserInvitesSearchController, UserController, UsersSearchController, CurrentUserDepartmentsController,
    UnreadCountsState, UserSettingsController, UserWidgetController } from './controllers'
import { FetchUserService, CurrentUserPollService } from './services'


const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(BlueUserStore).toSelf().inSingletonScope()
        bind(UnreadCountsState).toSelf().inSingletonScope()
        bind(FetchUserService).toSelf().inSingletonScope() 
        bind(CurrentUserPollService).toSelf().inSingletonScope() 

        bind(UserInvitesSearchController).toSelf()
        bind(CurrentUserController).toSelf()
        bind(UserController).toSelf()
        bind(UsersSearchController).toSelf()
        bind(CurrentUserDepartmentsController).toSelf()
        bind(UserSettingsController).toSelf()
        bind(UserWidgetController).toSelf()
    })
]

export { DI_MODULES }

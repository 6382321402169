import { Api } from '@evertel/api'
import { slice, sum } from 'lodash'

const CHUNK_SIZE = 1024 * 1024 * 5 // AWS minimum part size is 5mb for all parts except the last
const PARALLEL_UPLOAD_TIME_PER_PART_MS = 5000
const MAX_RETRY = 3

export type AsyncGetPartFormDataFn = (partNumber: number, start: number, end: number) => Promise<FormData>
export type AsyncOnPartCompleteFn = (partNumber: number) => Promise<void>
export type OnProgressFn = (completed: number, total: number) => void

export interface MultipartUploadableMedia {
    fileName: string
    mimeType: string
    contentLength: number
    meta: any
    onGetPartFormData: AsyncGetPartFormDataFn
    onPartComplete?: AsyncOnPartCompleteFn
    onProgress: OnProgressFn,
    modelName: string
    modelId: number
    userId: number
    deviceId: number
}

export const Options = {
    maxParallelUploadCount: 10
}

const createSequentialFilePartsArray = (contentLength: number) => {
    const totalPartsCount = Math.ceil(contentLength / CHUNK_SIZE)

    if (totalPartsCount <= 0) {
        return []
    }

    const sequentialArray = Array.from({ length: totalPartsCount }, (_, index) => index + 1)
    return sequentialArray.map(partNumber => {
        const start = (partNumber - 1) * CHUNK_SIZE
        const partContentLength = Math.min(CHUNK_SIZE, contentLength - start)
        return {
            start,
            end: start + partContentLength, // needed to keep track of every part size
            partContentLength,
            partNumber
        }
    })
}

export async function mediaMultipartUpload(api: Api, media: MultipartUploadableMedia, options = Options) {
    let currentUploadId = ''
    let mediaMultipartId: number
    let progressCompleted = 0 // progress is measured from 0 - 1
    let progressTotal = 1
    const partsProgress: number[] = []

    const { fileName, mimeType, contentLength, onGetPartFormData, onPartComplete, onProgress, modelName, modelId, deviceId, userId } = media
    let { meta } = media

    console.log('mediaMultipartUpload upload', { fileName, mimeType, contentLength, meta })
    if (!fileName) throw new Error('Missing fileName.')
    if (!mimeType) throw new Error('Missing mimeType.')
    if (!api) throw new Error('Missing API reference.')

    progressTotal = contentLength

    meta = meta || {}
    // helps simplify retry code later
    meta.modelName = modelName
    meta.modelId = modelId

    // Recursively Retry Upload Part
    const uploadPart = async (api: Api, partNumber: number, partContentLength: number, formData: FormData, retryCount = 0) => {
        try {
            // try to Upload part to API
            return await api.Routes.MediaMultiPartUpload.postUploadPart(mediaMultipartId, partNumber, currentUploadId, partContentLength, formData, (event: any) => {
                try 
                {
                    partsProgress[partNumber] = event.loaded
                    progressCompleted = sum(partsProgress)
                    // Progress update
                    if (onProgress) onProgress(progressCompleted, progressTotal)
                } catch (e) {
                    console.log ('YOU CANT DO THIS,', e)
                }
            })
        } catch (e: any) {
            // Failed to upload
            if (retryCount < MAX_RETRY) return uploadPart(api, partNumber, partContentLength, formData, retryCount + 1)
            throw e
        }
    }

    const existingMediaMultiPart = await api.Routes.BlueUser.getMediaMultiPartUploads(userId)
    // TODO: also need to check that its fro the same ModelId and ModelName, otherwise we may complete an uplaod for a different message
    const previousUploadExists = existingMediaMultiPart.find((m) =>
        m.fileName == fileName &&
        m.mimetype == mimeType &&
        m.deviceId == deviceId &&
        (m.postProcessing as any)?.meta?.modelName == modelName &&
        (m.postProcessing as any)?.meta?.modelId == modelId)

    // Create array of file parts with start and end positions for reading later
    let fileParts = createSequentialFilePartsArray(contentLength)

    if (!previousUploadExists) {
        // Initialize MediaMultiPartUpload
        const postMediaUploadStartRouts = {
            RoomMessages: () => api.Routes.RoomMessage.postMediaUploadStart(modelId, { fileName, mimeType, meta }),
            ThreadMessages: () => api.Routes.ThreadMessage.postMediaUploadStart(modelId, { fileName, mimeType, meta }),
            RoomMessage: () => api.Routes.RoomMessage.postMediaUploadStart(modelId, { fileName, mimeType, meta }),
            ThreadMessage: () => api.Routes.ThreadMessage.postMediaUploadStart(modelId, { fileName, mimeType, meta }),
            Document: () => api.Routes.Document.postMediaUploadStart(modelId, { fileName, mimeType, meta }),
            DocumentSchema: () => api.Routes.DocumentSchema.postMediaUploadStart(modelId, { fileName, mimeType, meta }),
            Departments: () => api.Routes.Department.postMediaPublicUploadStart(modelId, { fileName, mimeType, meta }),
            BlueUsers: () => api.Routes.BlueUser.postMediaPublicUploadStart(modelId, { fileName, mimeType, meta }),
            Bots: () => api.Routes.Bot.postMediaUploadStart({ fileName, mimeType, meta })
        }
        try {
            const mediaMultipartParams = await postMediaUploadStartRouts[modelName](modelId, { fileName, mimeType, meta })
            currentUploadId = mediaMultipartParams?.uploadId
            mediaMultipartId = mediaMultipartParams?.id
        } catch (error) {
            throw new Error(error)
        }
    } else {
        // PreviousUploadExists
        // Filter out fileParts that have already uploaded if previousUploadExists
        const successfulParts = await api.Routes.MediaMultiPartUpload.getParts(previousUploadExists.id)
        const successfulPartNumbers = successfulParts.map(p => p.partNumber)

        // Set uploadId to existing MediaMultiPartUpload
        currentUploadId = previousUploadExists.uploadId
        mediaMultipartId = previousUploadExists.id
        //  Add completed time
        fileParts.filter(p => successfulPartNumbers.includes(p.partNumber)).forEach(p => {
            partsProgress[p.partNumber] = p.partContentLength
        })
        // Remove completed parts from fileParts
        fileParts = fileParts.filter(p => !successfulPartNumbers.includes(p.partNumber))
    }

    // Upload parallelUploadCount number of parts, if upload is faster than PARALLEL_UPLOAD_TIME_PER_PART_MS, increment
    // parallelUploadCount to optimize for PARALLEL_UPLOAD_TIME_PER_PART_MS
    let parallelUploadCount = 1
    for (let index = 0; index < fileParts.length;) {
        // Slice parallelUploadCount amount of parts
        const lessParts = slice(fileParts, index, index + parallelUploadCount)
        // Increment the index
        index += parallelUploadCount

        const startTime = Date.now()

        try {
            // upload all lessParts
            await Promise.all(lessParts.map(async filePart => {
                // Get FormData part from client app
                const formData = await onGetPartFormData(filePart.partNumber, filePart.start, filePart.end)
                // Upload part, recursively retry
                await uploadPart(api, filePart.partNumber, filePart.partContentLength, formData)
                // Callback the part uploaded
                if (onPartComplete) onPartComplete(filePart.partNumber)
            }))
        } catch (error) {
            // TODO: maybe recast errors to MediaMultipartUploadError, but I don't know what all errors we might see
            throw new Error(`Failed to upload media ${error}`)
        }

        // increment parallelUploadCount by the upload time over PARALLEL_UPLOAD_TIME_PER_PART_MS rounded down
        const uploadTime = (Date.now() - startTime) || 1
        const diff = Math.floor(PARALLEL_UPLOAD_TIME_PER_PART_MS / uploadTime)
        parallelUploadCount = Math.min(parallelUploadCount + diff, options.maxParallelUploadCount)
    }


    // Complete upload
    return await api.Routes.MediaMultiPartUpload.postUploadComplete(mediaMultipartId, currentUploadId)
}

import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { decorate, inject, injectable } from 'inversify'
import { SessionState } from '@evertel/session'
import { BlueUserStore } from '@evertel/blue-user'
import { DepartmentStore } from '@evertel/department'
import { Api } from '@evertel/api'
import { APIDataReaction, APIDataRoomMessage, APIDataThreadMessage } from '@evertel/types'
import { uniqBy } from 'lodash'


class MessageReactionsController {
    message: APIDataRoomMessage | APIDataThreadMessage = {}
    modelType: 'room' | 'thread' = 'room'
    reactions = []
    reactionsCount = 0
    reactedBy = []
    reactedByCount = 0

    constructor(
        private session: SessionState,
        private userStore: BlueUserStore,
        private departmentStore: DepartmentStore,
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = (message: APIDataRoomMessage | APIDataThreadMessage, modelType: 'room' | 'thread') => {
        this.message = message
        this.modelType = modelType
    }

    toggleReaction = async (emojiText: string): Promise<'added' | 'removed'> => {
        const existingReaction = this.message.reactions?.find(r => r.text === emojiText)
        let userReactionExists = false
        if (existingReaction) {
            userReactionExists = !!existingReaction.reactedByThrough?.find(rb => rb.blueUserId === this.session.currentUserId)
        }
        if (!existingReaction || (existingReaction && !userReactionExists)) {
            //add it
            await this.addReaction(emojiText)
            return 'added'
        } else {   //IE: (existingReaction && userReactionExists) {
            //remove it
            await this.removeReaction(emojiText)
            return 'removed'
        }
    }

    addReaction = async (text: string) => {
        try {
            const endpoint = (this.modelType === 'room') ? 'RoomMessage' : 'ThreadMessage'
            const updatedReactionData = await this.api.Routes[endpoint].putReactions(this.message.id, text)
            runInAction(() => {
                this.message.reactions = uniqBy([updatedReactionData, ...this.message.reactions], 'id')
            })

        } catch (e) {
            throw new Error(e.message)
        }
    }

    removeReaction = async (text) => {
        const existingReaction = this.message.reactions.find(r => r.text === text)
        const isMyReaction = existingReaction.reactedByThrough?.find(rb => rb.blueUserId === this.session.currentUserId)
        const isTheLastReaction = isMyReaction && existingReaction?.count === 1

        // we have the last reaction, remove it before we make the API call
        if (isTheLastReaction){
            runInAction(() => {
                this.message.reactions = this.message.reactions.filter(r => r.text !== text)
            })
        }

        try {
            const endpoint = (this.modelType === 'room') ? 'RoomMessage' : 'ThreadMessage'
            const updatedReactionData = await this.api.Routes[endpoint].delReactions(this.message.id, text)

            // only add back if new count is greater than zero
            if (updatedReactionData.count) {
                runInAction(() => {
                    this.message.reactions = uniqBy([updatedReactionData, ...this.message.reactions], 'id')
                })
            }

        } catch (e) {
            throw new Error(e.message)
        }
    }

    clearReactedBy() {
        this.reactedBy = []
    }
}

decorate(injectable(), MessageReactionsController)
decorate(inject(SessionState), MessageReactionsController, 0)
decorate(inject(BlueUserStore), MessageReactionsController, 1)
decorate(inject(DepartmentStore), MessageReactionsController, 2)
decorate(inject(Api), MessageReactionsController, 3)

export { MessageReactionsController }

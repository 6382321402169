import { useState, useEffect, useMemo } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
// evertel
import { Button, Text, Toast } from '@evertel/web/ui'
import { useContainer, useService } from '@evertel/di'
import { DI_TYPES, WebAppStoreInterface, WebAnalyticsStoreInterface, WebCurrentUserStoreInterface } from '@evertel/types'
import { AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { Modal, Col, Container, Row, Input, InfoBox, ModalHeader, ModalBody, InputGroup } from '@evertel/web/ui'
import { DeviceState } from '@evertel/device'

interface StateType {
    confirmIsLoading: boolean,
    code: string,
    message?: {
        text: string,
        type: 'error'|'success'|'info'|'warning'|undefined,
        theme: 'light'|'dark'|undefined
    },
    error: any
}

interface Props {
    isOpen: boolean,
    onClose: () => void,
    phoneNumber?: string|number
}

const ConfirmEnrollMFA: React.FC<Props> = observer(({
    isOpen = false,
    onClose,
    phoneNumber
}) => {

    const container = useContainer()

    const appStore = useMemo(() => container.get<WebAppStoreInterface>(DI_TYPES.LegacyAppStore), [container])
    const analyticsStore = useMemo(() => container.get<WebAnalyticsStoreInterface>(DI_TYPES.LegacyAnalyticsStore), [container])
    const deviceState = useService(DeviceState, [])
    const authenticationController = useMemo(() => container.get(AuthenticationController), [container])
    const currentUserStore = useMemo(() => container.get<WebCurrentUserStoreInterface>(DI_TYPES.LegacyCurrentUserStore), [container])

    const [state, setState] = useState<StateType>({
        confirmIsLoading: false,
        code: '',
        message: undefined,
        error: null
    })

    useEffect(() => {
        autorun((init) => {
            if (!appStore.appLoading) {
                analyticsStore.logPageView('/account/security/confirm-enrollment')
                init.dispose()
            }
        })
    }, [])

    const onChangeCode = (e: any) => {
        setState((prevState) => ({
            ...prevState,
            code: e.target.value
        }))
    }

    const confirmMFA = async () => {
        const { code } = state

        setState((prevState) => ({
            ...prevState,
            message: undefined
        }))

        if (!code) {
            setState((prevState) => ({
                ...prevState,
                error: 'Please enter your security code'
            }))
            return
        }

        setState((prevState) => ({
            ...prevState,
            confirmIsLoading: true
        }))

        const mfaToken = authenticationController.mfaToken
        const oobCode = authenticationController.oobCode

        if (!mfaToken || !oobCode) {
            setState((prevState) => ({
                ...prevState,
                confirmIsLoading: false,
                error: 'Missing tokens'
            }))
            return
        }

        try {
            const params = {
                mfaToken: mfaToken,
                oobCode: oobCode,
                bindingCode: code,
                deviceToken: deviceState.deviceToken
            }

            await authenticationController.verifyChallenge(params)

        } catch (error: any) {
            setState((prevState) => ({
                ...prevState,
                confirmIsLoading: false,
                error: error.message
            }))
            return
        }
        
        setState((prevState) => ({
            ...prevState,
            confirmIsLoading: false,
            message: {
                text: 'Enrollment Successful',
                type: 'success',
                theme: 'light'
            }
        }))
        
        // refresh authenticators list
        const userId = currentUserStore.id
        if (userId) await authenticationController.getAuthenticators(userId)
        onClose()
    }

    const { code, error, confirmIsLoading, message } = state

    return (            
        <Modal
            visible={isOpen}
            className="join-dept-modal"
            onClose={onClose}
            backdrop='static'
            keyboard={false}>
            <Container>
                {(message) &&
                    <Toast
                        message={message.text}
                        type={message.type}
                        theme={message.theme}
                    />
                }
                <ModalHeader title="Confirm SMS MFA Enrollment" />
                <ModalBody>
                    <Row>
                        <Col>
                            {(error) &&
                                <InfoBox color="danger">
                                    {error}
                                </InfoBox>
                            }

                            <Text className="pb-4">
                                A text message with a six-digit security code has been sent to your phone 
                                number ending in <span className="text-secondary">******{String(phoneNumber).slice(-4)}</span>
                            </Text>
                            <InputGroup className="mb-4">
                                <Input
                                    type="text"
                                    value={code}
                                    onChange={onChangeCode}
                                    placeholder="Enter security code..."
                                /> 
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            align="center"
                            className="mt-4">
                            <Button
                                intent="success"
                                disabled={confirmIsLoading}
                                progress={confirmIsLoading}
                                onClick={() => confirmMFA()}>
                                <Text>Verify Security Code</Text>
                            </Button> 
                        </Col>    
                    </Row>    
                </ModalBody>   
            </Container>
        </Modal>
    )  
})

export {
    ConfirmEnrollMFA
} 
import React from 'react'
import { observer } from 'mobx-react-lite'
import { DocumentIcon } from '../../elements/DocumentIcon'
import {
    Row, Col, Button,
    ColorPicker, IconPicker,
    Modal, ModalBody, ModalHeader, ModalFooter
} from '@evertel/web/ui'
import { SchemaBuilderController } from '../../../controller'

interface DocIconPickerProps {
    visible: boolean
    onClose: () => void
    controller: SchemaBuilderController
}

const DocIconPickerModal: React.FC<DocIconPickerProps> = observer(({
    visible,
    controller,
    onClose
}) => {

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            size="lg"
            style={{ width: "65vw" }}
            alignment="center">
            <ModalHeader>
                Choose a Color and Icon
            </ModalHeader>
            <ModalBody>
                <Col>
                    <Row>
                        <DocumentIcon
                            schema={{
                                icon: controller.icon,
                                color: controller.color
                            }}
                            width={50}
                            height={50}
                        />
                    </Row>
                    <Row>
                        <ColorPicker
                            value={controller.color}
                            className="mt-3"
                            onChange={(color) => controller.setIcon({
                                icon: controller.icon,
                                color: color
                            })}
                        />
                    </Row>
                    <Row className="mt-4">
                        <IconPicker
                            value={controller.icon}
                            onChange={(icon) => controller.setIcon({
                                icon: icon,
                                color: controller.color
                            })}
                        />
                    </Row>
                </Col>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <Button
                    color="info"
                    onClick={onClose}>
                    Done
                </Button>
            </ModalFooter>
        </Modal>
    )
})

export {
    DocIconPickerModal
}
import { APIDataBlueUser, APIDataRoom } from '@evertel/types'
import { decorate, inject, injectable } from 'inversify'
import { debounce } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import { Api } from '@evertel/api'


class RoomsByUserController {
    userRooms: APIDataRoom[] = []
    roomsCount = 0
    userId = 0
    searchTerm = ''
    page = 1
    limit = 10
    isLoading = false
    sortBy = 'id'
    sortOrder = 'ASC'
    roomsManagedByUser: APIDataRoom[] = []

    constructor(private api: Api) {
        makeAutoObservable(this)
    }

    init = async (userId: number) => {
        this.userId = userId
        this.userRooms = []
        this.roomsCount = 0
        this.page = 1
        this.searchTerm = ''
    }

    setSearchTerm(value: string) {
        this.searchTerm = value
        this.page = 1
        this.userRooms = []
        this.roomsCount = 0
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    setSort = (sortBy: string, sortOrder: string) => {
        this.sortBy = sortBy
        this.sortOrder = sortOrder
    }

    private debouncedSearchInternal = debounce(async () => {
        await this.search()
        return 
    }, 300)

    debouncedSearch = () => {
        this.isLoading = true
        return this.debouncedSearchInternal()
    }

    search = async () => {
        let count = this.roomsCount
        let rooms: APIDataRoom[] = []

        runInAction(() => {
            this.isLoading = true
        })

        if (!this.roomsCount) {
            count = await this.searchRoomsByUserCount()
        }

        if (count > this.userRooms?.length) {
            rooms = await this.searchRoomsByUser()
        }

        runInAction(() => {
            this.userRooms = rooms
            this.isLoading = false
        })

    }

    async searchRoomsByUser() {
        const rooms = await this.api.Routes.BlueUser.getRooms(this.userId, this.filter)

        return rooms || []
    }

    searchRoomsByUserCount = async () => {
        const roomsCount = await this.api.Routes.BlueUser.getRoomsCount(this.userId, this.filter)

        runInAction(() => {
            this.roomsCount = roomsCount?.count ?? 0
        })

        return roomsCount?.count ?? 0
    }

    isManager = (roomId: number) => {
        const room = this.userRooms.filter(room => room.id === roomId)[0]

        if (!room) return

        const userCanManage = room?.managers?.filter((manager: APIDataBlueUser) => manager.id === this.userId)[0]

        return !!userCanManage
    }

    get filter() {
        return {
            where: {
                and: [
                    {
                        or: [
                            { name: { like: `%${this.searchTerm}%` } },
                            { departmentId: { like: `%${this.searchTerm}%` } },
                            { options: { like: `%${this.searchTerm}%` } }
                        ]
                    }
                ]
            },
            order: (`${this.sortBy} ${this.sortOrder}`),
            limit: this.limit,
            skip: (this.page - 1) * this.limit,
            include: [{
                relation: 'managers',
                scope: { fields: ['id'] }
            }]
        }
    }
}

decorate(injectable(), RoomsByUserController)
decorate(inject(Api), RoomsByUserController, 0)

export { RoomsByUserController }
export function exportDocumentAsCSV(data: Blob, from: string) {
    const blob = new Blob([data])
    const link = document.createElement('a')

    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `${from}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
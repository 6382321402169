import { APIDataBlueUser, APIDataDepartment, APIDataRoom } from '@evertel/types'
import { injectable, inject, decorate } from '@evertel/di'
import { makeAutoObservable, runInAction } from 'mobx'
import { DocumentACLStore } from '../../store'
import { Api } from '@evertel/api'


export interface Principals {
    users: APIDataBlueUser[]
    rooms: APIDataRoom[]
    departments: APIDataDepartment[]
}

class DocumentACLPrincipalsController {
    documentId = 0
    principals: Principals|null = null

    constructor(
        private api: Api,
        private documentACLStore: DocumentACLStore
    ) {
        makeAutoObservable(this)
    }

    init = (documentId: number, filter?: any) => {
        this.documentId = documentId
        this.getPrincipalsByDocumentId(documentId, filter)
    }

    async getPrincipalsByDocumentId(documentId: number, filter?: any): Promise<void> {
        try {
            const principals = await this.api.Routes.Document.getAclPrincipals(documentId, filter)

            runInAction(() =>
                this.principals = principals
            )

        } catch (e) {
            console.log(e)
            throw e
        }
    }

    get principalUsers() {
        return this.principals?.users
    }

    get principalRooms() {
        return this.principals?.rooms
    }

    get principalDepartments() {
        return this.principals?.departments
    }
}

decorate(injectable(), DocumentACLPrincipalsController)
decorate(inject(Api), DocumentACLPrincipalsController, 0)
decorate(inject(DocumentACLStore), DocumentACLPrincipalsController, 1)

export { DocumentACLPrincipalsController }

import { makeAutoObservable, runInAction } from 'mobx'
// evertel
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { uniqBy } from 'lodash'
import { APIDataRead } from '@evertel/types'


class MessageReadsController {
    messageId = 0
    modelType: 'room'|'thread' = 'room'
    reads: APIDataRead[] = []
    readsCount = 0
    isInfiniteScroll = true
    page = 1
    limit = 100
    _filter = {}

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = async (messageId: number, modelType: 'room'|'thread', isInfiniteScroll = true) => {

        if (!messageId) throw new Error('Missing a message ID! (error: SHARED.MRC-1')

        if (this.messageId !== messageId) {
            this.messageId = messageId
            this.readsCount = 0
            this.reads = []
        }

        this.modelType = modelType
        this.isInfiniteScroll = isInfiniteScroll
        this.page = 1

        await this.fetchReads()
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    setLimit(limit: number) {
        this.limit = limit
    }

    setFilter(filter: Record<string, any>) {
        this._filter = filter
    }

    fetchReads = async () => {
        if (!this.messageId) return
        
        let count = this.readsCount
        let results = []

        // grab the total count of reads if we don't have it already
        if (!this.readsCount) {
            count = await this.fetchReadsCount()
        }

        // stop from fetching if we already got them all
        if (count > this.reads?.length) {
            const endpoint = (this.modelType === 'room') ? 'RoomMessage' : 'ThreadMessage'
            results = await this.api.Routes[endpoint].getReads(this.messageId, this.filter)

            runInAction(() => {
                this.reads = (this.isInfiniteScroll) ? uniqBy([...this.reads, ...results], 'id') : results
            })
        }
    }

    fetchReadsCount = async () => {
        const endpoint = (this.modelType === 'room') ? 'RoomMessage' : 'ThreadMessage'
        const res = await this.api.Routes[endpoint].getReadsCount(this.messageId, this.filter?.where)

        runInAction(() => {
            this.readsCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    fetchNextPage = () => {
        this.page = this.page + 1
        this.fetchReads()
    }

    get filter() {
        return {
            include: [
                {
                    relation: 'readBy'
                }],
            where: {},
            ...this._filter,
            limit: this.limit,
            skip: (this.page - 1) * 100
        }
    }
}

decorate(injectable(), MessageReadsController)
decorate(inject(Api), MessageReadsController, 0)

export { MessageReadsController }

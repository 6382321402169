import { decorate, injectable } from '@evertel/di'
import { NotificationPermission, NotificationPermissionResult, PushImplementation, PushNotification } from '../src/PushImplementation'

class MockPushImplementation implements PushImplementation {
    calls = []

    async init() {
        this.calls.push('init')
    }

    promptForNotificationPermission(): NotificationPermissionResult {
        this.calls.push('promptForNotificationPermission')
        return Promise.resolve('default')
    }
    getCurrentNotificationPermission(): NotificationPermissionResult {
        this.calls.push('getCurrentNotificationPermission')
        return Promise.resolve('default')
    }
    getPushToken(): Promise<string> {
        this.calls.push('getPushToken')
        return Promise.resolve('test_token')
    }
    deletePushToken(): Promise<void> {
        throw new Error('Method not implemented.')
    }
    getInitialNotification(): Promise<PushNotification> {
        throw new Error('Method not implemented.')
    }
    clearInitialNotification(): Promise<void> {
        throw new Error('Method not implemented.')
    }
    showLocalNotification(): Promise<void> {
        throw new Error('Method not implemented.')
    }
    setOnMessageCallback(callback: (message: PushNotification) => void): void {
        this.calls.push('setOnMessageCallback')
    }

    setOnPermissionChangeCallback(callback: (permission: NotificationPermission) => void): void {
        this.calls.push('setOnPermissionChangeCallback')
    }
}

decorate(injectable(), MockPushImplementation)

export { MockPushImplementation }

import { HTMLAttributes, ReactNode, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { Icon, Badge, Text, Row, Col, Collapse } from '@evertel/web/ui'


interface DrawerNavItemDropdownProps extends HTMLAttributes<HTMLDivElement> {
    label: string
    unreadCount?: number
    hasUrgentUnreads?: boolean
    isOpen?: boolean
    onClick?: (isOpen: boolean) => void,
    children?: ReactNode
}


const DrawerNavDropdownLink: React.FC<DrawerNavItemDropdownProps> = observer(({
    label,
    onClick,
    unreadCount,
    isOpen,
    hasUrgentUnreads,
    children,
    ...otherProps
}) => {

    const [isCollapsed, setIsCollapsed] = useState(!isOpen)

    const onToggle = () => {
        onClick && onClick(isCollapsed)
        setIsCollapsed(!isCollapsed)
    }

    return (
        <Col {...otherProps}>
            <button
                className={classNames('drawer-item-dropdown justify-content-between btn btn-link', {'hover-action': onClick})}
                onClick={onToggle}>
                <Row valign="center">
                    <Icon
                        name={'chevron-down'}
                        color="secondary"
                        size="sm"
                        className={classNames('chevron mr-2', 
                            {
                                'closed': isCollapsed
                            })}
                    />
                    <Text
                        className={classNames('text-truncate',
                            {
                                'open': isOpen
                            })}>
                        {label}
                    </Text>
                </Row>
                {(!!unreadCount && isCollapsed) &&
                    <Badge
                        position='top-start'
                        color={(hasUrgentUnreads) ? 'danger' : 'warning'}
                    >
                        {unreadCount}
                    </Badge>
                }
            </button>
            <Collapse visible={!isCollapsed}>
                {children}
            </Collapse>
        </Col>
    )
})

export { DrawerNavDropdownLink }

import React, { memo, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
// evertel
import {
    Card,
    CardBody,
    ContentPlaceholder,
    Text,
    Badge,
    Row,
    Col,
    Media,
    Icon
} from '@evertel/web/ui'
import { DocumentViewController } from '@evertel/document'
import { DocumentIcon } from './DocumentIcon'
import { DepartmentStore } from '@evertel/department'
import { useService } from '@evertel/di'
import { APIDataMedia } from '@evertel/types'
import { getMimetype } from '@evertel/utils'
import { APIDataMediaToNormalizedMedia } from '@evertel/media'


interface TileProps {
    documentId: number
    departmentId: number
    onClick: (documentId: number | undefined) => void
    className?: string
}


const DocumentTile: React.FC<TileProps> = observer(({
    documentId,
    departmentId,
    onClick,
    className
}) => {

    const documentViewController = useService(DocumentViewController, [documentId])
    const departmentStore = useService(DepartmentStore, [departmentId])

    useState(() => {
        documentViewController.loadFromStoreOrFetch(documentId)
    })

    const document = documentViewController.document
    const schema = documentViewController.documentSchema
    const department = departmentStore.findById(departmentId)

    //used in place of isLoading
    if (!documentViewController?.document) {
        return (
            <Card
                className={classNames('document-tile', className)}
                style={{
                    borderLeftColor: '#999',
                    borderLeftWidth: 5
                }}>
                <CardBody>
                    <ContentPlaceholder
                        width='70%'
                        color="gray200"
                        className="mt-1"
                    />
                    <Row className="mt-2">
                        <ContentPlaceholder
                            width={20}
                            height={26}
                            color="gray200"
                            className="mr-2"
                        />
                        <ContentPlaceholder
                            width='40%'
                            color="gray200"
                            className="mt-1"
                        />
                    </Row>
                </CardBody>
            </Card>
        )
    }

    const images: APIDataMedia[] = documentViewController?.document?.media?.filter(i => getMimetype(i) === 'image') // just grab images

    return (
        <Card
            className={classNames('document-tile', className)}
            onClick={() => onClick(document.id)}
            as="button"
            style={{
                borderLeftColor: schema?.color,
                borderLeftWidth: 5
            }}>
            <CardBody>
                <Row>
                    <Col
                        xs={8}
                        className="p-0"
                    >
                        {(document?.isArchived) &&
                            <Row>
                                <Badge color="muted">
                                    Archived
                                </Badge>
                            </Row>
                        }
                        <Row>
                            <Text bold style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {document.name || 'Untitled Document'}
                            </Text>
                        </Row>
                        <Row className="mt-2">
                            <DocumentIcon
                                schema={schema}
                            />
                            <Text
                                color="muted"
                                size={12}
                                className="ml-2"
                                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {schema?.name} {(department) && `(${department.shortName})`}
                            </Text>
                        </Row>
                    </Col>
                    <Col
                        xs={2}
                        align="right"
                        className="p-0"
                    >
                        {(!!images?.length) &&
                            <Media
                                media={APIDataMediaToNormalizedMedia(images[0])}
                                width={50}
                                height={50}
                                imageProps={{ flexibility: 'fixed', resizeMode: 'cover' }}
                                rounded
                            />
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
})

export {
    DocumentTile
}

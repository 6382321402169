import { useState, Fragment } from 'react'
import { observer } from 'mobx-react'
// evertel
import React from 'react'
import { useHistory, useParams } from 'react-router'


const SSOAccessToken: React.FC = observer(() => {
    const history = useHistory()
    const params = useParams<any>()
    const parsed = new URLSearchParams('access_token=' + params.params)
    const accessToken = parsed.get('access_token')

    useState(() => {
        if (accessToken) {
            history.push('/login?access_token=' + accessToken)
        } else {
            history.push('/login')
        }
    })

    return (    
        <Fragment>
            One moment please...
        </Fragment>
    )  
})

export {
    SSOAccessToken
} 
import { Api } from '@evertel/api'
import { decorate, injectable } from '@evertel/di'

class LogoutImplementation {

    init(_db?: IDBDatabase) {
        throw ('Not Implemented')
    }

    async logout() {
        throw ('Not Implemented')
    }

}

decorate(injectable(), LogoutImplementation)

export { LogoutImplementation }
import { ContainerModule } from '@evertel/di'
import { Analytics } from './Analytics'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(Analytics).toSelf().inSingletonScope()
    })
]

export {
    DI_MODULES
}
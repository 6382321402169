import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import AppStore from '../AppStore'
import DepartmentsStore from '../DepartmentsStore'
import UsersStore from '../UsersStore'
import DepartmentsAccessStore from '../DepartmentsAccessStore'
import AnalyticsStore from '../AnalyticsStore'
import api from '../../api'
import { DepartmentUserController } from '@evertel/department'
import { container } from '../../di'
import { SessionState } from '@evertel/session'
import { DepartmentsAccessStore as NewDepartmentsAccessStore } from '@evertel/departments-access'

export default class DepartmentAccess {
    id = 0
    badgeNumber = undefined
    isPrimary = false
    isVerified = false
    isVerifyDisabled = false
    roles = []
    departmentId = 0
    blueUserId = 0
    assignmentString = undefined
    positionString = undefined
    isBusy = false
    error = null

    constructor(data) {
        this.departmentUserController = container.get(DepartmentUserController)
        this.session = container.get(SessionState)
        this.newDepartmentsAccessStore = container.get(NewDepartmentsAccessStore)
        
        makeObservable(this, {
            id: observable,
            badgeNumber: observable,
            isPrimary: observable,
            isVerified: observable,
            isVerifyDisabled: observable,
            roles: observable,
            departmentId: observable,
            blueUserId: observable,
            assignmentString: observable,
            positionString: observable,
            isBusy: observable,
            error: observable,
            updateFromData: action,
            department: computed,
            user: computed,
            position: computed,
            assignment: computed,
            role: computed,
            updateUserDetails: action,
            updateUserVerified: action,
            setRole: action,
            deleteRole: action,
            leaveDepartment: action
        })

        if (!data.id) throw new Error('id is required')
        this.id = data.id
        this.updateFromData(data)

        
    }

    updateFromData(data) {
        // Updates whatever fields are passed in
        const fields = ['badgeNumber', 'isPrimary', 'isVerified', 'isVerifyDisabled', 'roles', 'departmentId', 'blueUserId', 'assignmentString', 'positionString']
        for (const f of fields) {
            if (data[f] !== undefined) this[f] = data[f]
        }

        // Push Department,
        if (data.department) {
            DepartmentsStore.updateFromData(data.department)
        }

    }

    get department() {
        return DepartmentsStore.departments.find(d => d.id === this.departmentId)
    }

    get user() {
        return UsersStore.users.find(u => u.id === this.blueUserId)
    }

    get position() {
        return this.positionString
    }

    get assignment() {
        return this.assignmentString
    }

    get role() {
        if (!this.roles) return null

        //this works because it's in order of priority and will return their actual (highest) role
        if (this.roles.includes('executive')) return 'executive'
        if (this.roles.includes('management')) return 'management'
        if (this.roles.includes('employee')) return 'employee'
        if (this.roles.includes('guest')) return 'guest'

        return null
    }

    //returns true if user has explicit role passed in
    hasRole(role) {
        return this.roles.includes(role)
    }

    async updateUserDetails(body) {
        try {
            runInAction(() => {
                this.error = null
                this.isBusy = true
            })

            const details = await this.departmentUserController.updateAttributes(this.departmentId, this.blueUserId, body)
            this.updateFromData(details)

            runInAction(() => {
                this.isBusy = false
            })

            //log to analytics
            AnalyticsStore.logEvent({
                category: 'User',
                action: 'edit_user_department_profile'
            })
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API put', message: 'DepartmentAccess Model updateUserDepartmentDetails()', error: e.message || e})
            return
        }
    }

    async updateUserVerified(params) {
        try {
            runInAction(() => {
                this.error = null
                this.isBusy = true
            })

            const details = await api.Department.updateUserVerified(this.departmentId, this.blueUserId, params)
            this.updateFromData(details)

            runInAction(() => {
                this.isBusy = false
            })

            //log to analytics
            AnalyticsStore.logEvent({
                category: 'User',
                action: 'edit_user_verified'
            })
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API put', message: 'DepartmentAccess Model updateUserVerified()', error: e.message || e})
            return
        }
    }

    async setRole(params) {
        try {
            runInAction(() => {
                this.error = null
                this.isBusy = true
            })

            const da = await api.Department.setRole(this.departmentId, this.blueUserId, params)
            this.updateFromData(da)

            runInAction(() => {
                this.isBusy = false
            })

            //log to analytics
            AnalyticsStore.logEvent({
                category: 'User',
                action: 'edit_user_role'
            })
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API put', message: 'DepartmentAccess Model setRole()', error: e.message || e, params})
            return
        }
    }

    async deleteRole(role) {
        try {
            runInAction(() => {
                this.error = null
                this.isBusy = true
            })

            const da = await api.Department.deleteRole(this.departmentId, this.blueUserId, role)

            const user = UsersStore.getUserById(this.blueUserId)
            user.updateFromData({
                departmentsAccess: da
            })

            runInAction(() => {
                this.isBusy = false
            })

        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API delete', message: 'DepartmentAccess Model deleteRole()', error: e.message || e, role})
            return
        }
    }

    async leaveDepartment() {
        try {
            runInAction(() => {
                this.error = null
                this.isBusy = true
            })

            const result = await api.Department.leaveDepartment(this.departmentId, this.blueUserId)

            // if removed, then filter out from our store too
            if (result.count) {
                runInAction(() => {
                    this.newDepartmentsAccessStore.deleteById(this.id)
                })
            }

            runInAction(() => {
                this.isBusy = false
            })

            AnalyticsStore.logEvent({
                category: 'User',
                action: 'leave_department'
            })
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API delete', message: 'DepartmentAccess Model leaveDepartment()', error: e.message || e, departmentId: this.departmentId})
            return
        }
    }
}

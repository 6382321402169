import React from 'react'
import { Dropdown, Text, Row } from '@evertel/web/ui'
import Wrapper from './Wrapper'
import { SingleValue } from 'react-select';

const options = [
    {
        label: '',
        value: ''
    },
    {
        label: 'Attempt to Identify',
        value: 'attempt-to-identify'
    },
    {
        label: 'Attempt to Locate',
        value: 'attempt-to-locate'
    },
    {
        label: 'Officer Safety',
        value: 'officer-safety'
    },
    {
        label: 'Missing Person',
        value: 'missing-person'
    },
    {
        label: 'Information Only',
        value: 'information-only'
    }
]

type Value = SingleValue<{ label: string; value: string; }>

interface BulletingTypeProps {
    id: number,
    value: string,
    onChange: (id: number, value: Value) => void,
    title: string,
    required: boolean,
    errors: string[]
}

const BulletinTypeWriteWidget: React.FC<BulletingTypeProps> = ({
    id,
    value,
    onChange,
    title,
    required,
    errors
}) => {

    return (
        <Wrapper
            title={title}
            required={required}
            errors={errors}>
            <Dropdown
                value={value}
                onChange={(value) => {
                    onChange(id, value as Value)
                }}
                options={options}
            />
        </Wrapper>
    )
}


const BulletinTypeReadWidget = ({
    value
}: { value: string }) => {

    if (!value) {
        return null
    }

    const option = options.find((option) => option.value === value)

    if (!option) {
        return null
    }

    return (
        <Row
            align="center"
            style={{
                background: '#FFC107',
                padding: 0.5
            }}>
            <Text
                color="secondary">
                {option.label}
            </Text>
        </Row>
    )
}

export {
    BulletinTypeWriteWidget,
    BulletinTypeReadWidget
}

import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { DocumentSchemaStore } from '@evertel/stores'
import { APIDataDocumentSchema } from '@evertel/types'
import { Api } from '@evertel/api'


class DepartmentDocumentSchemasController {
    departmentId = 0
    documentSchemaIds = []

    constructor(
        private documentSchemaStore: DocumentSchemaStore,
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = async (departmentId: number) => {
        this.departmentId = departmentId
        
        await  this.fetchDepartmentDocumentSchemas()
    }

    fetchDepartmentDocumentSchemas = async (): Promise<APIDataDocumentSchema[]> => {
        if (!this.departmentId) return []

        // fetch schemas
        const schemas = await this.api.Routes.Department.getDocumentSchemas(this.departmentId, {
            order: 'name ASC'
        })

        // save resulting schemas in the documentSchemaStore
        if (schemas?.length) this.documentSchemaStore.update(schemas)

        // update observables
        runInAction(() => {
            this.documentSchemaIds = schemas.map((ds) => ds.id)
        })
        return schemas
    }

    get documentSchemas() {
        // return ALL document schemas for the department
        return this.documentSchemaStore.findByIds(this.documentSchemaIds)
    }

    get enabledDocumentSchemas() {
        // return all ENABLED document schemas for the department
        const docs = this.documentSchemaStore.findByIds(this.documentSchemaIds)
        return docs.filter(d => d.isEnabled === true)
    }

    get disabledDocumentSchemas() {
        // return all DISABLED document schemas for the department
        const docs = this.documentSchemaStore.findByIds(this.documentSchemaIds)
        return docs.filter(d => d.isEnabled === false)
    }
}

decorate(injectable(), DepartmentDocumentSchemasController)
decorate(inject(DocumentSchemaStore), DepartmentDocumentSchemasController, 0)
decorate(inject(Api), DepartmentDocumentSchemasController, 1)

export { DepartmentDocumentSchemasController }

import React from 'react'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
    className?: string
    align?: 'left'|'right'|'center',
    color?: string
}

const ModalFooter = forwardRef<HTMLDivElement, Props>(({
    children,
    className,
    align,
    color,
    ...otherProps
}, ref) => {

    const _className = classNames(
        'modal-footer',
        className,
        {
            'text-center': align === 'center',
            'text-left': align === 'left',
            'text-right': align === 'right',
            [`bg-${color}`]: color,
        }
    )

    return (
        <div
            ref={ref}    
            className={_className}
            {...otherProps}>
            {children}
        </div>
    )
})

export default ModalFooter
ModalFooter.displayName = 'ModalFooter'

import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataMedia } from '@evertel/types'

class SchemaFieldMediaController {
    loaded = false
    schemaId = 0
    media: APIDataMedia[] = []
    uploaded: Map<number, APIDataMedia> = new Map()
    deleteQueue: Set<number> = new Set()
    uploadQueue: Map<number, File> = new Map()

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = async (schemaId?: number, mediaIds?: number[]) => {
        // if no schemaId, we are creating a new shcema, no need to fetch anything
        if (schemaId && !isNaN(schemaId)) {
            this.schemaId = schemaId

            const filter = (Array.isArray(mediaIds) && mediaIds?.length) ?
                {
                    where: {
                        id: { inq: mediaIds}
                    }
                }
                :
                undefined

            // fetch media
            const media = await this.api.Routes.DocumentSchema.getMedia(schemaId, filter)
    
            // add to the 'uploaded' set
            media.forEach((mediaItem) => {
                runInAction(() => {
                    this.uploaded.set(
                        mediaItem.id as number,
                        mediaItem
                    )
                })
            })

            runInAction(() => {
                this.media = media
            })
        }

        this.loaded = true
    }

    deleteUploaded = (mediaId: number): void => {
        // remove media from uploaded array
        this.uploaded.delete(mediaId)

        // add mediaId to the list of media to delete on save
        this.deleteQueue.add(
            mediaId
        )
    }

    addToUploadQueue = (file: File): {id: number, file: File} => {
        const id = (Date.now() + Math.random())
        this.uploadQueue.set(
            id,
            file
        )
        return {id, file}
    }

    deleteFromUploadQueue = (mediaId: number): void => {
        this.uploadQueue.delete(
            mediaId
        )
    }

    getLocalFileURL = (file: File) => {
        return URL.createObjectURL(file as File)
    }

    get mediaArrays() {
        return {
            uploaded: Array.from(this.uploaded?.values()),
            deleteQueue: Array.from(this.deleteQueue?.values()),
            uploadQueue: Array.from(this.uploadQueue?.values()),
            test: this.uploadQueue
        }
    }
}

decorate(injectable(), SchemaFieldMediaController)
decorate(inject(Api), SchemaFieldMediaController, 0)

export {
    SchemaFieldMediaController
}

import React, { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'


export type BreakPointObject = {
    cols?: 'auto'|number|string|null
    gutter?: number|string|null
    gutterX?: number|string|null
    gutterY?: number|string|null
}

interface Props extends HTMLAttributes<HTMLDivElement> {
    height?: number // optional to override default
    align?: 'left'|'right'|'center'|'start'|'end'|'around'|'between'
    valign?: 'top'|'bottom'|'center'|'start'|'end'|'stretch'
    flex?: boolean
    children?: React.ReactNode
    className?: string
    xs?: BreakPointObject
    sm?: BreakPointObject
    md?: BreakPointObject
    lg?: BreakPointObject
    xl?: BreakPointObject
    xxl?: BreakPointObject
}

const BREAKPOINTS = [
    'xxl' as const,
    'xl' as const,
    'lg' as const,
    'md' as const,
    'sm' as const,
    'xs' as const
]

const Row = forwardRef<HTMLDivElement, Props>(({
    height,
    align = 'left',
    valign = 'start',
    flex = true,
    children,
    className,
    ...otherProps
}, ref) => {

    const repsonsiveClassNames: string[] = []

    BREAKPOINTS.forEach((bp) => {
        const breakpoint = otherProps[bp]
        delete otherProps[bp]
  
        const infix = (bp === 'xs') ? '' : `-${bp}`
  
        if (typeof breakpoint === 'object') {
            if (breakpoint.cols) {
                repsonsiveClassNames.push(`row-cols${infix}-${breakpoint.cols}`)
            }
            if (typeof breakpoint.gutter === 'number') {
                repsonsiveClassNames.push(`g${infix}-${breakpoint.gutter}`)
            }
            if (typeof breakpoint.gutterX === 'number') {
                repsonsiveClassNames.push(`gx${infix}-${breakpoint.gutterX}`)
            }
            if (typeof breakpoint.gutterY === 'number') {
                repsonsiveClassNames.push(`gy${infix}-${breakpoint.gutterY}`)
            }
        }
    })

    if (valign) {
        if (valign === 'top') valign = 'start'
        if (valign === 'bottom') valign = 'end'
        repsonsiveClassNames.push(`align-items-${valign}`)
    }

    if (align) {
        if (align === 'left') align = 'start'
        if (align === 'right') align = 'end'
        repsonsiveClassNames.push(`justify-content-${align}`)
    }

    if (flex) repsonsiveClassNames.push('flex-row')
  
    const _className = classNames(repsonsiveClassNames, className)
    const _style = { ...otherProps?.style, height }

    return (
        <div
            className={_className}
            ref={ref}
            style={_style}
            {...otherProps}>
            {children}
        </div>
    )
})

export { Row }

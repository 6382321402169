import { Component } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    percentLoaded: PropTypes.number
}

//=================================================================================================
class LoadingBarStripes extends Component {
//=================================================================================================

    //---------------------------------------------------------------------------------------------------------------------------------------
    render() {
    //---------------------------------------------------------------------------------------------------------------------------------------

        return (
            <div className="stripesLoader" style={{backgroundPosition: `${this.props.percentLoaded}%`}}></div>
        )
    }
}

LoadingBarStripes.propTypes = propTypes
export default LoadingBarStripes

import React from 'react'
import { observer } from 'mobx-react-lite'
// evertel
import { Row, Col, Card, CardBody } from '@evertel/web/ui'
import { Document, WidgetsProvider } from '@evertel/schema-parser'
import { WriteWidgets } from '../schema-widgets'
import { SchemaBuilderController } from '../../controller'


interface SchemaBuilderPreviewProps {
    controller: SchemaBuilderController
}

const SchemaBuilderPreview: React.FC<SchemaBuilderPreviewProps> = observer(({
    controller
}) => {

    return (
        <Row>
            <Col valign="top">
                <Card>
                    <CardBody>
                        <WidgetsProvider value={WriteWidgets}>
                            <Document
                                layout="full"
                                schema={controller.schema as any}
                                value={undefined}
                            />
                        </WidgetsProvider>
                    </CardBody>
                </Card>
            </Col>
            <Col
                md={3}
                valign="top">
                
            </Col>
        </Row>
    )
})

export { SchemaBuilderPreview }

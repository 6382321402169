import { makeAutoObservable } from 'mobx'
// evertel
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataUserCRM } from '@evertel/types'
import { SessionState } from '@evertel/session'
import { isArray } from 'lodash'

export interface SendInvitesBody {
    to: string[]|number[], // array of emails and phone numbers (can include userIds if sending to existing users)
    message: string,
    roles: string[],
    addAsRoomManager?: boolean,
    addUserCRM?: APIDataUserCRM[]
}


class SendInvitesController {

    constructor(
        private api: Api,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    sendRoomInvite = async (roomIds: number[], body: SendInvitesBody) => {
        if (!roomIds || !body?.to) return
        if (!isArray(roomIds)) roomIds = [roomIds]

        await Promise.all(roomIds?.map(async (roomId: number) => {
            await this.api.Routes.Room.postSendInvites(roomId, body)
        }))
    }

    sendDepartmentInvite = async (departmentId: number, body: Omit<SendInvitesBody, 'addAsRoomManager'>) => {
        if (!body?.to) return
        await this.api.Routes.Department.postSendInvites(departmentId, body)
    }

    sendUserInvite = async (body: Omit<SendInvitesBody, 'roles'|'addAsRoomManager'>) => {
        if (!body?.to) return
        await this.api.Routes.BlueUser.postSendInvites(this.session.currentUserId, body)
    }
}

decorate(injectable(), SendInvitesController)
decorate(inject(Api), SendInvitesController, 0)
decorate(inject(SessionState), SendInvitesController, 1)

export { SendInvitesController }

import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
// evertel
import { ServiceProvider } from '@evertel/service'
import { Document, WidgetsProvider } from '@evertel/schema-parser'
import { useService } from '@evertel/di'
import { DocumentEditController, DocumentACLsController, EverdocMediaUplaodErrorEdit } from '@evertel/document'
import { WriteWidgets } from './schema-widgets'
import { DocumentIcon, DocumentMenu } from './elements'
import { DocumentShareModal } from './acl/DocumentShareModal'
import { exportDocumentAsCSV } from '../utils'
import { APIDataDocument, APIDataDocumentSchema } from '@evertel/types'
import {
    Button, Icon, Text, InfoBox, Badge,
    PageBody, ContentPlaceholder,
    Card, CardHeader, CardBody, CardHeaderActions,
    ButtonDropdown, ButtonDropdownItem, ButtonDropdownToggle, ButtonDropdownMenu, ButtonDropdownDivider, Breadcrumb, useUI, Input
} from '@evertel/web/ui'
import { SchemaMediaController } from '../controller'
import { SessionState } from '@evertel/session'


interface DocumentEditProps {
    departmentId: number,
    documentId: number,
    isLoadingCallback: (isLoading: boolean) => void
}

const DocumentEdit: React.FC<DocumentEditProps> = observer(({
    departmentId,
    documentId,
    isLoadingCallback
}) => {

    const docName = useRef<HTMLInputElement>(null)

    const documentEditController = useService(DocumentEditController, [])
    const documentACLController = useService(DocumentACLsController, [])
    const schemaMediaController = useService(SchemaMediaController, [])
    const session = useService(SessionState, [])

    const [isLoading, setIsLoading] = useState(false)
    const [showShareModal, setShowShareModal] = useState(false)
    const [hasChanged, setHasChanged] = useState(false)
    const [name, setName] = useState('')

    const { isConfirmed } = useUI()
    const { addToast } = useUI()
    const history = useHistory()
    //const location = useLocation()

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            // initialize controllers
            try {
                await documentEditController.load(documentId, 'web')
                await documentACLController.init(documentId, session.currentUserId)
                await schemaMediaController.init(documentEditController.documentSchemaId)

                setName(documentEditController.name)

            } catch (error) {
                console.error(error)
            }
            setIsLoading(false)
        })()
    }, [])

    useEffect(() => {
        // tell the legacy app the loading status
        isLoadingCallback(isLoading)
    }, [isLoading])

    const onSave = async (notify: { isUrgent: boolean } | null): Promise<void> => {
        setIsLoading(true)

        try {
            await documentEditController.update(notify)

            addToast({
                color: 'success',
                title: 'Document Saved',
                message: `Your document has been saved ${(notify) ? 'and rooms have been notified.' : ''}`
            })

            setHasChanged(false)

            //history.goBack()

        } catch (error: any) {
            if (error instanceof EverdocMediaUplaodErrorEdit) {
                addToast({
                    color: 'warning',
                    title: 'Be aware',
                    message: `Document edited with some media issues`
                })
            } else {
                addToast({
                    color: 'danger',
                    title: 'Something went wrong',
                    message: error.message
                })
            }
        }

        setIsLoading(false)
    }

    const deleteDocument = async () => {
        const deleteIt = async () => {
            try {
                const data = documentEditController.data as { title: string }

                await documentEditController.delete()

                addToast({
                    color: 'success',
                    title: 'Delete successful',
                    message: `"${data?.title || 'Your document'}" has been permanently deleted`
                })

                history.push('/everdocs/')

            } catch (error: any) {
                addToast({
                    color: 'danger',
                    title: 'Error',
                    message: error?.message || error
                })
            }
        }

        const confirmed = await isConfirmed({
            title: 'Delete EverDoc?',
            message: 'Since you have not shared this document with others it can be deleted. Are you sure you want to delete this EverDoc?',
            acceptButton: {
                label: 'Delete',
                color: 'danger'
            }
        })
        if (confirmed) {
            deleteIt()
        }
    }

    const onNameFocus = () => {
        docName.current.select()
    }

    const onChangeName = (e: any) => {
        setName(e?.target?.value)
        setHasChanged(true)
        documentEditController.setName(e?.target?.value)
    }

    const onChange = (value: APIDataDocument): void => {
        setHasChanged(true)
        documentEditController.updateData(value)
    }

    const onToggleArchive = async () => {
        const isArchived = documentEditController.document?.isArchived
        setIsLoading(true)
        try {
            await documentEditController.archive(!isArchived)
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    const exportCSV = async () => {
        try {
            const blob = await documentEditController.downloadCSV(documentId)
            exportDocumentAsCSV(blob, name)

        } catch (error: any) {
            console.error(error.message)
        }
    }

    if (!documentEditController.loaded) {
        return (
            <DocumentPlaceholder />
        )
    }

    if (!documentEditController?.document) return null

    const canShare = documentACLController?.canShare
    const { isArchived, id } = documentEditController?.document as APIDataDocument
    const data = documentEditController?.data as { title: string }

    const breadcrumbs = [
        { label: 'EverDocs', onClick: () => history.push('/everdocs') },
        { label: `Edit Document (${documentEditController?.documentSchema?.name})`, active: true }
    ]
    // if (location.state?.from === 'room' && location.state?.id) {
    //     breadcrumbs[0] = {label: 'Room', onClick: () => history.push(`/room/${location.state.id}`)}
    // }


    return (
        <PageBody>
            <Breadcrumb
                items={breadcrumbs}
            />
            <Card>
                <CardHeader>
                    <DocumentIcon
                        schema={documentEditController?.documentSchema as APIDataDocumentSchema}
                        width={30}
                        height={30}
                    />
                    <Input
                        ref={docName}
                        value={name}
                        onChange={onChangeName}
                        onFocus={onNameFocus}
                        style={{ width: '50%' }}
                        className="doc-name"
                    />
                    {(isArchived) &&
                        <Badge
                            color="gray"
                            className="ml-2">
                            Archived
                        </Badge>
                    }
                    <CardHeaderActions>
                        <DocumentMenu
                            //there is no onEdit or onShare so the button, but one has to be true to display this component
                            //so the user can see the Export and archive buttons
                            canEdit={true}
                            canShare={false}
                            document={documentEditController.document}
                            userId={session.currentUserId}
                            departmentId={session.selectedDepartmentId}
                            schema={documentEditController.documentSchema}
                            onExportAsCSV={() => exportCSV()}
                            onToggleArchive={onToggleArchive}
                            {...(!documentEditController.hasPreviousShares) && { onDelete: deleteDocument }}
                        />
                        <Button
                            color={(!canShare || isArchived) ? 'gray' : 'secondary'}
                            className="mx-3"
                            disabled={!canShare || isArchived}
                            onClick={() => setShowShareModal(true)}
                            data-tooltip={(!canShare) ?
                                'You do not have sharing permissions for this document' :
                                `Shared with ${(documentACLController.aclsNotOwner?.length === 1) ? `1 room` : `${documentACLController.aclsNotOwner?.length} rooms`}`}
                            data-tooltip-pos="top">
                            <Icon
                                name={(canShare && !isArchived) ? 'share' : 'lock'}
                                className="mr-1"
                                color={(!canShare || isArchived) ? 'gray600' : 'white'}
                            />
                            <Text color={(!canShare || isArchived) ? 'muted' : 'white'}>
                                Share
                            </Text>
                        </Button>
                        <SaveButton
                            onSave={onSave}
                            disabled={!hasChanged}
                            isArchived={isArchived as boolean}
                            isLoading={isLoading}
                            documentACLController={documentACLController}
                        />
                    </CardHeaderActions>
                </CardHeader>
                <CardBody>
                    {(!!documentEditController.errors?.length) &&
                        <InfoBox color="danger">
                            <Text>
                                {`Please review the document, there ${(documentEditController.errors.length === 1) ?
                                    'is 1 error' : `are ${documentEditController.errors.length} errors`}`}
                            </Text>
                        </InfoBox>
                    }
                    <ServiceProvider services={[
                        {
                            id: 'meta', value: {
                                user: session.currentUserId,
                                department: departmentId,
                                document: documentId,
                                documentSchema: documentEditController.documentSchema?.id
                            }
                        }]}>
                        <WidgetsProvider value={WriteWidgets}>
                            <Document
                                layout="full"
                                schema={documentEditController.documentSchema?.schema}
                                schemaId={documentEditController.documentSchemaId}
                                value={data}
                                documentId={documentId}
                                onChange={onChange}
                                errors={documentEditController.errors}
                            />
                        </WidgetsProvider>
                    </ServiceProvider>
                </CardBody>
            </Card>

            <DocumentShareModal
                isVisible={showShareModal}
                onClose={() => setShowShareModal(false)}
                documentId={id as number}
                departmentId={departmentId}
                currentUserId={session.currentUserId}
                documentName={data?.title}
            />
        </PageBody>
    )
})

const DocumentPlaceholder = () => {
    return (
        <>
            <ContentPlaceholder width="75%" />
            <ContentPlaceholder width="75%" className="my-2" />
            <ContentPlaceholder width="70%" className="mb-5" />
            <ContentPlaceholder width="75%" />
            <ContentPlaceholder width="75%" className="my-2" />
            <ContentPlaceholder width="70%" className="mb-5" />
            <ContentPlaceholder width="75%" />
            <ContentPlaceholder width="75%" className="my-2" />
            <ContentPlaceholder width="70%" className="mb-5" />
        </>
    )
}


interface SaveButtonProps {
    onSave: (notify: { isUrgent: boolean } | null) => void
    isLoading: boolean,
    isArchived: boolean,
    disabled?: boolean,
    documentACLController: DocumentACLsController
}

const SaveButton: React.FC<SaveButtonProps> = ({
    onSave,
    isLoading,
    isArchived,
    disabled,
    documentACLController
}) => {

    // if not shared with any rooms, no need to prompt to notify
    if (!documentACLController.aclsNotOwner?.length) {
        return (
            <Button
                color="success"
                onClick={() => onSave(null)}
                disabled={isLoading || disabled}>
                <Text
                    color="white"
                    bold>
                    Save & Update
                </Text>
            </Button>
        )
    }

    return (
        <ButtonDropdown
            alignment="end"
            disabled={isLoading || disabled}>
            <ButtonDropdownToggle
                color="success"
                disabled={disabled}>
                <Text color="white">
                    Save & Update
                </Text>
            </ButtonDropdownToggle>
            <ButtonDropdownMenu>
                <ButtonDropdownItem disabled>
                    <Text>
                        Would you like to send notifications of this<br />
                        update to rooms this document is shared with?
                    </Text>
                </ButtonDropdownItem>
                <ButtonDropdownDivider />
                <ButtonDropdownItem
                    onClick={() => onSave(null)}>
                    <Icon
                        name="bell-slash"
                        color="success"
                    />
                    <Text color="success">
                        Save (don't notify)
                    </Text>
                </ButtonDropdownItem>
                <ButtonDropdownDivider />
                <ButtonDropdownItem
                    disabled={isArchived}
                    onClick={() => onSave({ isUrgent: false })}>
                    <Icon
                        name="bell-on"
                        color={(isArchived) ? 'muted' : 'secondary'}
                    />
                    <Text color={(isArchived) ? 'muted' : 'secondary'}>
                        Save & Notify
                    </Text>
                </ButtonDropdownItem>
                <ButtonDropdownItem
                    disabled={isArchived}
                    onClick={() => onSave({ isUrgent: true })}>
                    <Icon
                        name="exclamation-triangle"
                        color={(isArchived) ? 'muted' : 'danger'}
                    />
                    <Text color={(isArchived) ? 'muted' : 'danger'}>
                        Save & Notify as Urgent
                    </Text>
                </ButtonDropdownItem>
            </ButtonDropdownMenu>
        </ButtonDropdown>
    )
}

export {
    DocumentEdit
}

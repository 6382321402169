import { makeAutoObservable } from 'mobx'
// evertel
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { SessionState } from '@evertel/session'
import { RoomStore, ThreadStore } from '@evertel/stores'
import { DepartmentsAccessStore } from '@evertel/departments-access'


class ConsumeInviteController {

    constructor(
        private api: Api,
        private session: SessionState,
        private roomStore: RoomStore,
        private departmentAccessStore: DepartmentsAccessStore,
        private threadStore: ThreadStore
    ) {
        makeAutoObservable(this)
    }

    consumeInvite = async (token: string) => {
        if (!token) return

        const response = await this.api.Routes.Invite.postConsumeById(token) as any

        switch (response?.action) {
            case 'ROOM_INVITE':
                this.roomStore.update(response.room)
                this.departmentAccessStore.update(response.departmentsAccess)
                break
            case 'USER_INVITE':
                this.threadStore.update(response.thread)
                break
            case 'DEPARTMENT_INVITE':
                this.departmentAccessStore.update(response.departmentsAccess)
                break
            default:
                break
        }
    }
}

decorate(injectable(), ConsumeInviteController)
decorate(inject(Api), ConsumeInviteController, 0)
decorate(inject(SessionState), ConsumeInviteController, 1)
decorate(inject(RoomStore), ConsumeInviteController, 2)
decorate(inject(DepartmentsAccessStore), ConsumeInviteController, 3)
decorate(inject(ThreadStore), ConsumeInviteController, 4)

export { ConsumeInviteController }

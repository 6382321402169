import { decorate, injectable, unmanaged } from 'inversify'
import { pickBy } from 'lodash'
import { makeObservable, observable, action, computed } from 'mobx'

class Store<DataType extends { id?: number },> {
    /** Observable Map of DataType objects*/
    objectsMap: Map<number, DataType> = new Map([])

    /** Computed array from objectMap.values() */
    get objectsArray() {
        return Array.from(this.objectsMap.values())
    }

    constructor(readonly fields: string[]) {
        makeObservable(this, {
            objectsMap: observable,
            update: action,
            deleteById: action,
            objectsArray: computed,
            clear: action
        })
    }

    clear() {
        this.objectsMap.clear()
    }

    /**
     * Merges in or inserts data received from Api calls
     * @param data can be an object or an array of objects
     * @returns 
     */
    update(data: DataType | DataType[] | undefined){
        if (data === undefined) return

        // make data always an array
        if (!Array.isArray(data)) {
            data = [data]
        } 

        // iterate over data
        for (const i of data) {
            if (!i?.id || isNaN(i.id)) continue

            const id = Number(i.id)

            // if we hve this object in objectsMap, merge in new data
            if (this.objectsMap.has(id)){
                // Pick fields using lodash.pickBy<>() that are listed in 'this.fields' and whose value is not undefined, we don't want to merge in undefined values
                const pickedData = pickBy<DataType>(i, (v, k)=> this.fields.includes(k) && v !== undefined) as DataType
    
                // merge picked fields in to existing object
                // should keep the object observable fields 
                Object.assign(this.objectsMap.get(id) as DataType, pickedData as DataType)
            } else {
                const pickedData = pickBy<DataType>(i, (v, k)=> this.fields.includes(k) && v !== undefined)
                //insert new object
                this.objectsMap.set(id, pickedData as DataType )
            }
        }
    }

    /**
     * Find one Object by Id
     * @param id Id of DataType object
     * @returns DataType or undefined
     */
    findById(id: number): DataType | undefined{
        id = Number(id)
        // can not do anything with a NaN id
        if (isNaN(id)) return undefined
        return this.objectsMap.get(id)
    }

    /**
     * Find array of objects by ID
     * @param ids Array of Ids
     * @returns Array of found DataType objects
     */
    findByIds(ids: (number | undefined)[]): DataType[] {
        return ids.map(id => Number(id)).filter(id => !isNaN(id)).map(id => this.objectsMap.get(id)).filter(i => i) as DataType[]
    }

    /**
     * Find one object based on predicate function
     * @param predicate Function (o:DataType) => boolean
     * @returns 
     */
    findOne(predicate: (o:DataType) => boolean):DataType | undefined {
        for (const v of this.objectsMap.values()) {
            if (predicate(v)) return v
        }
        return undefined
    }

    /**
     * Find array of objects based on predicate function
     * @param predicate 
     * @returns 
     */
    find(predicate: (o:DataType) => boolean):DataType[] {
        const result = []
        for (const v of this.objectsMap.values()) {
            if (predicate(v)) result.push(v)
        }
        return result
    }

    /**
     * Remove an item from the store
     */
    deleteById(id: number): boolean{
        return this.objectsMap.delete(id)
    }
}

decorate(injectable(), Store)
decorate(unmanaged(), Store, 0 ) // Tells inversify to ignore Store.constructor() parameter 0 since we use it as a constant value for configuration

export { Store }
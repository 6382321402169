import React from 'react'
import ToastContainer from "./ToastContainer"
import Toast from "./Toast"
import ToasterHeader from "./ToasterHeader"
import ToasterBody from "./ToasterBody"
import { EToasterProps, useUI } from '../../UIContext'
import { Text } from '../Text'

const ToasterNotice: React.FC<EToasterProps> = () => {
    // @ts-ignore
    const toast: EToasterProps = useUI()
    
    const {
        visible,
        autohide,
        animation,
        className,
        message,
        title,
        color,
        delay,
        closeButton,
        onShow,
        onClose
    } = toast

    const onClosed = (e: any) => {
        // @ts-ignore
        toast.hide()
        // @ts-ignore
        onClose && onClose(e)
    }

    return (
        <ToastContainer placement="top-end">
            <Toast
                autohide={autohide}
                // @ts-ignore
                animation={animation}
                delay={delay}
                visible={visible}
                color={color}
                className={className}
                onShow={onShow}
                onClose={onClosed}>
                {(title) &&
                    <ToasterHeader closeButton={closeButton}>
                        <Text
                            //color={(lightOrDark(color) === 'light') ? 'primary' : 'white'}
                            color="white"
                            bold>
                            {title}

                        </Text>
                    </ToasterHeader>
                }
                <ToasterBody>
                    <Text
                        //color={(lightOrDark(color) === 'light') ? 'primary' : 'white'}
                        color="white">
                        {message}
                    </Text>
                </ToasterBody>
            </Toast>
        </ToastContainer>
    )
}

export default ToasterNotice
ToasterNotice.displayName = 'ToasterNotice'


import { observer } from 'mobx-react'
import classNames from 'classnames'
// evertel
import { Icon, Badge, Text, Row, Col } from '@evertel/web/ui'
import { IconName, IconStyle } from '@fortawesome/fontawesome-common-types'
import { HTMLAttributes, useEffect } from 'react'
import { useService } from '@evertel/di'
import { DepartmentController } from '@evertel/department'
import { SessionState } from '@evertel/session'
import { Link, LinkProps } from 'react-router-dom'


interface DrawerNavRoomLinkProps extends LinkProps {
    label: string
    icon: IconName
    iconType?: IconStyle
    selected?: boolean
    unreadCount?: number
    hasUrgentUnreads?: boolean
    muted?: boolean
    departmentId?: number
    className?: string
}


const DrawerNavRoomLink: React.FC<DrawerNavRoomLinkProps> = observer(({
    label,
    icon,
    iconType = 'light',
    selected,
    unreadCount,
    muted,
    hasUrgentUnreads,
    departmentId,
    to,
    className,
    ...otherProps
}) => {

    const departmentController = useService(DepartmentController, [])
    const session = useService(SessionState, [])

    useEffect(() => {
        if (departmentId && departmentId !== session.selectedDepartmentId) {
            departmentController.init(departmentId)
        }
    })

    return (
        <Link
            to={to}
            className={classNames('d-flex justify-content-between drawer-item-link', className, {
                'hover-action': to,
                'active': selected,
                'muted': muted,
                'unread': unreadCount
            })}
            {...otherProps}>
            <Row valign="center">
                <Icon
                    name={icon || 'hashtag'}
                    color={(hasUrgentUnreads) ? 'danger' : undefined}
                    type={iconType}
                    className="mr-3"
                />
                {/* <Badge className="mr-3" style={{width: 20, height: 20, backgroundColor: stringToHslColor(label)}}>
                    <Icon
                        name={icon || 'hashtag'}
                    />
                </Badge> */}
                <Text
                    color={(hasUrgentUnreads) ? 'danger' : undefined}
                    className="text-truncate">
                    {label}
                </Text>
            </Row>
            <Col align="right">
                <div>
                    {(departmentId && departmentId !== session.selectedDepartmentId) &&
                        <Badge
                            color="gray900"
                            textColor="gray600"
                            className={classNames({'mr-2': unreadCount})}>
                            {departmentController.shortName}
                        </Badge>
                    }
                    {(!!unreadCount) &&
                        <Badge
                            color={(muted) ? 'light-primary' : (hasUrgentUnreads) ? 'danger' : 'warning'}>
                            {unreadCount}
                        </Badge>
                    }
                </div>
            </Col>
        </Link>
    )
})

export { DrawerNavRoomLink }

import { computed } from 'mobx'
import { omit } from 'lodash'


/**
 * FORMATS ARRAY OF OBJECTS INTO A USABLE ARRAY
 * FOR A 'react-select' COMPONENT
 * 
 * Important note: react-select will break if an items data has the key "options", hence renaming it to `opts` here.
 * https://github.com/JedWatson/react-select/issues/3706
 */
export function formatForSelect(array: any[], labelKey: string, valueKey: string, def = 'Name not provided', defualtLabelKey?: string) {
    const labelFields = labelKey.split('+')

    if (!array) return []
    if (!Array.isArray(array)) array = [array]
    if (array[0] === null || array[0] === undefined) return []

    return computed(() => array.map((item) => {
        let label = (labelFields.length === 1) ? item[labelKey] : item[labelFields[0]] + ' ' + item[labelFields[1]]

        if (!label || label === ' ') {
            label = item[defualtLabelKey] ? item[defualtLabelKey] : def
        }

        return ({
            ...omit(item, ['options']),
            label: label,
            value: item[valueKey],
            opts: item.options || []
        })
    })).get()
}
import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface PageBodyProps extends HTMLAttributes<HTMLDivElement|HTMLSpanElement> {
    className?: string
}

const PageBody = forwardRef<HTMLDivElement, PageBodyProps>(({
    className,
    children,
    ...otherProps
}, ref) => {

    const _className = classNames(
        'animated',
        'm-4',
        className
    )

    return (
        <div
            ref={ref}    
            className={_className}
            {...otherProps}>
            {children}
        </div>
    )
})

export default PageBody
PageBody.displayName = 'PageBody'

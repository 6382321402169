import { Component } from 'react'

// used to handle electron incoming communication
class ElectronBoundary extends Component {

    static showCreateRoom = () => {
    }

    static showAddThread = () => {
    }

    static invite = () => {
    }

    static joinNewDepartment = () => {
    }

    static showMyProfile = () => {
    }

    static showNotifications = () => {
    }

    static showPreferences = () => {
    }

    static showSecurity = () => {
    }

    static showDepartments = () => {
    }

    static logout = (all) => {
    }

    static help = (req) => {
    }

    render() {
        return this.props.children
    }
}

export default ElectronBoundary

import { Component } from 'react'
import PropTypes from 'prop-types'
import { InfoBox, Button, Modal, ModalBody } from '@evertel/web/ui'
import moment from 'moment'
import { LoadingIndicator } from '.'
import withContext from '../utilities/withContext.js'
import { EvertelContextConsumer } from '../utilities/EvertelContext.js'
import ReactGA from 'react-ga'

const propTypes = {
    isOpen: PropTypes.bool,
    logout: PropTypes.func
}

const defaultProps = {
    isOpen: false
}

//============================================================================================================
class SubscriptionNagModal extends Component {
//============================================================================================================

    state = {
        daysLeft: 100
    }

    componentDidMount = () => {
        if (!this.props.context.isAdmin) {
            const { subscriptionExpiresDate } = this.props.context

            if (subscriptionExpiresDate) {
                const today  = moment()
                const expiresDate = moment(subscriptionExpiresDate)
                const daysLeft = expiresDate.diff(today, 'days')
                this.setState({ daysLeft })
                if (subscriptionExpiresDate && daysLeft <= 0) {
                    this.props.context.setStateVar('showSubscriptionNagModal', true)
                }
            }
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({
                open: this.props.isOpen
            })
            //log model view on open
            if (this.props.isOpen) {
                ReactGA.modalview('/subscription-nag')
            }
        }
    }

    toggleModal = (e) => {
        this.props.context.setStateVar('showSubscriptionNagModal', !this.props.context.showSubscriptionNagModal)
    }

    logout = () => {
        this.toggleModal()
        this.props.logout()
    }

    switchDept = async (deptId) => {
        !LoadingIndicator.isShowing('loading') && LoadingIndicator.show('loading', 'full-overlay')

        await this.props.context.switchDept(deptId)

        //give UI time to re-render before removing loader
        setTimeout(() => {
            LoadingIndicator.isShowing('loading') && LoadingIndicator.hide('loading', 'full-overlay')
        }, 2000)
    }


    //----------------------------------------------------------------------------------------------------------------------------------
    render() {
    //----------------------------------------------------------------------------------------------------------------------------------

        return (

            <Modal isOpen={this.props.context.showSubscriptionNagModal} className="modal-lg">
                <ModalBody className="py-4 mb-4">
                    <center><img src="assets/img/squadboard-scene.png" alt="Trial Expired" /></center>
                    <h1 className="text-primary mb-4">Oops! It looks like your agency's trial period has expired.</h1>
                    Thanks for trying Evertel! You've created some rooms, made some connections and shared intel instantly, so here are a couple options going forward...
                    <InfoBox color="info" className="mt-3">
                        <strong>Upgrade</strong> - To upgrade, please call us at <strong>(702) 849-0323</strong> or email us at <a href="mailto:support@getevertel.com">support@getevertel.com</a>
                    </InfoBox>
                    <InfoBox color="info">
                        <strong>Extend your trial</strong> - If you didn't get a chance to fully tryout {this.props.context.appName} or need a little more time to evaluate, just let us know by calling <strong>(702) 849-0323</strong> or email us at <a href="mailto:support@getevertel.com">support@getevertel.com</a>.
                    </InfoBox>
                    {this.props.context.myDepts && this.props.context.myDepts.length > 1 &&
                        <div className="text-center mt-4">
                            <h5>Switch Agency</h5>
                            {this.props.context.myDepts.filter(d => d.departmentId !== this.props.context.deptId).map((dept, idx) =>
                                <Button color="primary" className="mr-2 mb-2" key={idx} onClick={() => this.switchDept(dept.departmentId)}>{dept.department.name}</Button>
                            )}
                        </div>
                    }
                    <div className="text-center mt-3">
                        <Button color="dark" size="sm" onClick={this.logout}>Logout</Button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

SubscriptionNagModal.propTypes = propTypes
SubscriptionNagModal.defaultProps = defaultProps
export default withContext(EvertelContextConsumer)(SubscriptionNagModal)

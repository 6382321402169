import { makeAutoObservable, runInAction } from 'mobx'
import { BlueUserStore } from '@evertel/blue-user'
import { DepartmentStore } from '@evertel/department'
import { DepartmentsAccessStore } from '@evertel/departments-access'
import { SessionState } from '@evertel/session'
import moment from 'moment'
import { decorate, inject, injectable } from 'inversify'

class ResetPasswordController {
    userId = 0

    constructor(
        private departmentsAccessStore: DepartmentsAccessStore,
        private session: SessionState,
        private departmentStore: DepartmentStore,
        private blueUserStore: BlueUserStore
    ) {
        makeAutoObservable(this)
    }

    init = (userId: number) => {
        this.userId = userId
    }

    get departmentsAccess() {
        return this.departmentsAccessStore.find(da => da.blueUserId === this.userId)
    }

    get passwordTTLdays() {
        if (!this.userId) return

        const user = this.blueUserStore.findById(this.userId)
        if (!user) return

        if (user.auth0 && user.auth0.enterprise) return

        const shortestPasswordTTLdays = this.minimumDepartmentPasswordTTLdays
        if (shortestPasswordTTLdays === undefined) return

        const passwordCreatedDate = moment(user.passwordCreatedDate)
        const daysSinceCreated = moment().diff(passwordCreatedDate, 'days')

        return shortestPasswordTTLdays - daysSinceCreated
    }

    get minimumDepartmentPasswordTTLdays() {
        const nonGuestDepartmentIds = this.departmentsAccess
            ?.filter(da => !da.roles?.includes('guest'))
            .map(da => da.departmentId) ?? []

        const departments = this.departmentStore.findByIds(nonGuestDepartmentIds)

        const activePasswordTTLs = departments
            .map(d => d.passwordTTL)
            .filter((ttl): ttl is number => ttl !== undefined && ttl > 0)

        if (!activePasswordTTLs.length) return

        const shortestPasswordTTL = Math.min(...activePasswordTTLs)

        // convert to days
        return Math.floor(shortestPasswordTTL / (3600 * 24))
    }

}

decorate(injectable(), ResetPasswordController)
decorate(inject(DepartmentsAccessStore), ResetPasswordController, 0)
decorate(inject(SessionState), ResetPasswordController, 1)
decorate(inject(DepartmentStore), ResetPasswordController, 2)
decorate(inject(BlueUserStore), ResetPasswordController, 3)

export { ResetPasswordController }
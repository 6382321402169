import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
// evertel
import { useContainer } from '@evertel/di'
import { AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { AnalyticsStore, NavigationStore } from '../../../stores'
import { ConfirmEnrollMFA } from '@evertel/web/security'
import { Toaster } from '../../../components'
import { InfoBox, Button, Icon, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupText, Row } from '@evertel/web/ui'


const EnrollMfaInit = observer(() => {
    const container = useContainer()
    const authenticationController = useMemo(() => container.get(AuthenticationController), [container])

    const [state, setState] = useState({
        token: null,
        phoneNumber: '',
        password: '',
        isBusy: false,
        alertText: '',
        alertColor: 'danger'
    })

    const [showPassword, setShowPassword] = useState(false)

    const [confirmEnrollmentModal, setConfirmedEnrollmentModal] = useState(false)

    useEffect(() => {
        const token = window.location.hash?.split('enroll-mfa/')[1] || null // grab token from URL

        if (!token) {
            setState((prevState) => ({
                ...prevState,
                alertText: 'Unable to retrieve password reset token. Please click on your reset link to try again.',
                alertColor: 'danger'
            }))
        }

        setState((prevState) => ({
            ...prevState,
            token: token
        }))
        AnalyticsStore.logPageView('/enroll-mfa')
    }, [])

    const redirectToLogin = () => {
        NavigationStore.navigate('/login')
    }

    const enrollInit = async () => {
        const { phoneNumber, password } = state

        setState((prevState) => ({
            ...prevState,
            alertText: ''
        }))

        if (!phoneNumber || !password) {
            setState((prevState) => ({
                ...prevState,
                alertText: 'Please fill out all fields',
                alertColor: 'danger'
            }))
            return
        }

        const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US')
        if (!parsedNumber || !parsedNumber.isValid()) {
            setState((prevState) => ({
                ...prevState,
                alertText: 'Invalid phone number',
                alertColor: 'danger'
            }))
            return
        }

        setState((prevState) => ({
            ...prevState,
            isBusy: true
        }))

        const enrollMfaBody = {
            phoneNumber: parsedNumber.number,
            password: password
        }

        try {
            const response = await authenticationController.enrollMFAToken(enrollMfaBody, state.token)
            authenticationController.setMFAParams(response.access_token, response.oob_code)

        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                isBusy: false,
                alertText: error.message || error,
                alertColor: 'danger'
            }))

            Toaster.show('error', error.message || error)
            return
        }

        setState((prevState) => ({
            ...prevState,
            isBusy: false
        }))

        toggleEnrollmentInitiated()
    }

    const toggleEnrollmentInitiated = () => {
        setConfirmedEnrollmentModal(!confirmEnrollmentModal)
    }


    return (
        <div className="app fadeIn animated" style={{ height: '100vh', overflow: 'auto' }}>
            <Row align='center' valign='center' className="p-4" style={{ minHeight: '100%' }}>
                <Card className="p-4" style={{ maxWidth: 650 }}>
                    <CardBody>
                        <h1><img src={'assets/img/evertel-shield-110x110.png'} width="40" align="left" alt="Evertel" /> &nbsp;Enroll in SMS authentication</h1>
                        {state.alertText && <InfoBox color={state.alertColor}>{state.alertText}</InfoBox>}
                        {/* PHONE NUMBER */}
                        <InputGroup className='mt-4'>
                            <InputGroupText>
                                <Icon name="phone" />
                            </InputGroupText>
                            <Input type="text" name="phoneNumber" autocomplete="off" placeholder="Phone number" value={state.phoneNumber} onChange={(e) => setState({ ...state, phoneNumber: e.target.value })} />
                        </InputGroup>
                        {/* CONFIRM PASSWORD */}
                        <InputGroup className='mt-4'>
                            <InputGroupText>
                                <Icon name="lock" />
                            </InputGroupText>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                name="confirmation"
                                value={state.password}
                                autocomplete="off"
                                placeholder="Type your current password"
                                onChange={(e) => setState({ ...state, password: e.target.value })}
                            />
                            <InputGroupText className='btn-ghost-muted' tag="button" onClick={() => setShowPassword(!showPassword)}>
                                <Icon
                                    name={showPassword ? 'eye-slash' : 'eye'}
                                />
                            </InputGroupText>
                        </InputGroup>

                        <Row className="mt-4">
                            <Col className="text-left">
                                <div>
                                    <Button
                                        color="link"
                                        onClick={redirectToLogin}>
                                                Back to login
                                    </Button>
                                </div>
                            </Col>
                            <Col className="text-right">
                                <Button
                                    color="success"
                                    type="submit"
                                    className="px-4"
                                    loading={state.isBusy}
                                    disabled={state.isBusy}
                                    onClick={enrollInit}>
                                                Enroll
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Row>

            {confirmEnrollmentModal && <ConfirmEnrollMFA
                isOpen={confirmEnrollmentModal}
                onClose={() => setConfirmedEnrollmentModal(false)}
                phoneNumber={state.phoneNumber}
            />}

        </div>
    )
})

export default EnrollMfaInit
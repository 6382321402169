import React, { useState, useEffect, useCallback, ReactNode } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
// evertel
import { formatForSelect } from '@evertel/utils'
import { APIDataBlueUser } from '@evertel/types'
import { useService } from '@evertel/di'
import { UsersSearchController } from '@evertel/blue-user'
import { GroupBase, MultiValue, SingleValue } from 'react-select'
import { ContentPlaceholder } from '../elements'
import { CustomAsyncPaginate } from './CustomAsyncPaginate'

interface UserSearchSelectProps {
    currentUserId: number,
    departmentId: number,
    selected?: APIDataBlueUser[],
    isMulti?: boolean,
    placeholder?: string,
    searchFilter?: any,
    defaultOptions?: boolean,
    maxMenuHeight?: number,
    noOptionsMessage?: string,
    className?: string,
    whereFilter?: any,
    isDisabled?: boolean,
    onEndReachedThreshold?: number,
    components?: ReactNode
    /**
     * admin = searches all users in database. *only admin roles can do this.
     * department = searches all users in the department.
     * public = searches all users in another department that are isSearchable.
     * contact = searches the current user's contacts/DMs list.
     */
    searchScope: 'admin'|'public'|'department'|'contact',
    onSelect: (user: MultiValue<APIDataBlueUser> | SingleValue<APIDataBlueUser>) => void
}


const UserSearchSelect: React.FC<UserSearchSelectProps> = observer(({
    searchScope = 'department',
    currentUserId,
    departmentId,
    selected = [],
    isMulti = false,
    defaultOptions = true,
    placeholder = 'Search or select people...',
    noOptionsMessage = 'No matches found',
    onSelect,
    whereFilter,
    maxMenuHeight,
    isDisabled = false,
    components,
    onEndReachedThreshold = 80,
    ...otherProps
}) => {

    const usersSearchController = useService(UsersSearchController, [])

    const [isLoading, setIsLoading] = useState(true)
    const [isSearching, setIsSearching] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        if (!departmentId && searchScope !== 'admin') return

        usersSearchController.init({
            searchScope,
            departmentId
        })

        //needs to render once with loading true to reload async paginate
        setTimeout(() => { setIsLoading(false)}, 1)

    }, [searchScope, departmentId])


    const onSelectOption = (user: MultiValue<APIDataBlueUser> | SingleValue<APIDataBlueUser>) => {
        if (onSelect){
            usersSearchController.setSearchTerm('')
            onSelect(user)
        }
    }

    const search = useCallback( async (
        searchString: string,
        loadedOptions: APIDataBlueUser[],
        additional: { page: number }
    ) => {
        if (usersSearchController.searchTerm !== searchString) {
            // only set searchTerm if it changes
            usersSearchController.setSearchTerm(searchString)
        }
        usersSearchController.setPage(additional?.page)

        try {
            setIsSearching(true)
            await usersSearchController.search()
            setIsSearching(false)
        } catch (error) {
            if (__DEV__) console.error('ERROR: /libs/web/ui/form/UserSearchSelect search():', error)
        }

        // grab results and format them for the Select
        const results = formatForSelect(usersSearchController.usersLatestFetch, 'firstName+lastName', 'id', 'Name not provided', 'email')
        const hasMore = (usersSearchController.usersLatestFetch.length >= usersSearchController.limit)

        return {
            options: results || [],
            hasMore,
            additional: {
                page: additional?.page + 1 || 1
            }
        }
    }, [departmentId, whereFilter, searchScope])

    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number): boolean => {
        if (isSearching) return false
        const maxPixelThreshold = 300
        const percentThreshold = 100 - onEndReachedThreshold

        const pxFromBottom = scrollHeight - clientHeight - scrollTop
        const percentThresholdFromBottomInPx = ((percentThreshold / 100) * (scrollHeight - clientHeight))
    
        // Determine the threshold to use (whichever is smaller)
        const thresholdToUse = Math.min(percentThresholdFromBottomInPx, maxPixelThreshold)

        // console.log('px from bottom / threshold, result', pxFromBottom, thresholdToUse, pxFromBottom <= thresholdToUse)    
        return pxFromBottom <= thresholdToUse
    }

    if (isLoading) {
        return (
            <ContentPlaceholder
                className={otherProps.className}
                style={{
                    height: 38
                }}
            />
        )
    }

    return (
        <CustomAsyncPaginate
            name="users"
            keyName={searchScope}
            loadOptions={search}
            additional={{
                page: 1
            }}
            isClearable
            debounceTimeout={300}
            defaultOptions={defaultOptions}
            shouldLoadMore={shouldLoadMore}
            isMulti={isMulti}
            value={selected}
            isDisabled={isDisabled}
            onChange={onSelectOption}
            noOptionsMessage={() => noOptionsMessage}
            placeholder={placeholder}
            maxMenuHeight={maxMenuHeight}
            menuPlacement="auto"
            components={components || {}}
            {...otherProps}
        />
    )
})

export { UserSearchSelect }

import { observable, action, runInAction, makeObservable } from 'mobx'
import api from '../api'
import AppStore from './AppStore'
import AnalyticsStore from './AnalyticsStore'
import UsersStore from './UsersStore'
import { container } from '../di'
import { SessionState } from '@evertel/session'

// Keeps track of our login state and progress
class UserSettingsStore {
    settings = {}
    error = null
    isBusy = false

    constructor() {
        this.session = container.get(SessionState)

        makeObservable(this, {
            settings: observable.deep,
            error: observable,
            isBusy: observable,
            getUserSettings: action,
            updateUserSettings: action,
            toggleAllNotifications: action
        })

        
    }

    async getUserSettings() {
        runInAction(() => {
            this.isBusy = true
            this.error = null
        })

        try {
            const userSettings = await api.BlueUser.getUserSettings(AppStore.userId)

            runInAction(() => {
                this.settings = userSettings
                this.isBusy = false
            })
        } catch (e) {
            AppStore.logError({type: 'API get', message: 'UserSettingsStore.getUserSettings()', error: e.message || e})

            runInAction(() => {
                this.error = e
                this.isBusy = false
            })
        }
    }

    async updateUserSettings(settings) {
        if (!settings) throw new Error('UPDATE USER SETTINGS: settings cannot be undefined or empty')

        runInAction(() => {
            this.isBusy = true
            this.error = null
        })

        try {
            const userSettings = await api.BlueUser.updateUserSettings(AppStore.userId, settings)

            //console.log('updateUserSettings response', userSettings)

            runInAction(() => {
                this.settings = userSettings
                this.isBusy = false
            })
        } catch (e) {
            AppStore.logError({type: 'API put', message: 'UserSettingsStore.updateUserSettings()', error: e.message || e})

            runInAction(() => {
                this.error = e
                this.isBusy = false
            })
            return
        }
    }

    toggleAllNotifications = async () => {
        // if notifications are not supported alert user
        if (!('Notification' in window)) {
            alert('Sorry, notifications are not supported in your browser')
            return
        }

        // open request permission dialogue (will only work in HTTPS)
        // will not show a dialogue if already 'granted'
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                // update settings
                this.updateUserSettings({
                    disableNotifications: !this.settings.disableNotifications
                })

                if (this.settings.disableNotifications) {
                    new Notification('A notification from Evertel!', {
                        icon: 'https://www.getevertel.com/wp-content/uploads/2019/06/favicon-114.png',
                        body: 'Notifications are enabled for this browser!'
                    })
                } else {
                    new Notification('A notification from Evertel!', {
                        icon: 'https://www.getevertel.com/wp-content/uploads/2019/06/favicon-114.png',
                        body: 'Notifications are disabled for this browser!'
                    })
                }
            } else if (permission === 'denied') {
                this.updateUserSettings({
                    disableNotifications: true
                })
            }
        })

        // log to analytics
        AnalyticsStore.logEvent({
            category: 'User',
            action: 'edit_all_notifications_settings'
        })
    }
}

export default new UserSettingsStore()

import { Api } from '@evertel/api'
import { RoomMessagesStore } from '@evertel/stores'
import { decorate, inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'


class MessageForwardController {
    messageId = 0

    constructor(
        private api: Api,
        private roomMessagesStore: RoomMessagesStore
    ) {
        makeAutoObservable(this)
    }

    init = async (roomMessageId: number) => {
        this.messageId = roomMessageId
    }

    forwardMessage = async (roomMessageId: number, data: { roomId: number, messageText: string, isUrgent: boolean }) => {
        return await this.api.Routes.RoomMessage.postForward(roomMessageId, data)
    }

    get message() {
        return this.roomMessagesStore.findById(this.messageId)
    }
}

decorate(injectable(), MessageForwardController)
decorate(inject(Api), MessageForwardController, 0)
decorate(inject(RoomMessagesStore), MessageForwardController, 1)

export { MessageForwardController }
export const departmentTypes = [
    {
        label: 'Police',
        value: 'police'
    },
    {
        label: 'Fire',
        value: 'fire'
    },
    {
        label: 'Emergency Management',
        value: 'emergency-management'
    },
    {
        label: 'Paramedic',
        value: 'paramedic'
    },
    {
        label: 'Government',
        value: 'government'
    },
    {
        label: 'Military',
        value: 'military'
    }
]

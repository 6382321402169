import React from 'react'
import { observer } from 'mobx-react-lite'
import { Label, Text, Col } from '@evertel/web/ui'

interface WrapperProps {
    value?: string|string[]|Record<string, any>,
    title?: string,
    hint?: string,
    description?: string,
    required?: boolean,
    empty?: string,
    errors?: any,
    children?: React.ReactNode,
    className?: string
}

const Wrapper: React.FC<WrapperProps> = observer(({
    value,
    title,
    hint,
    description,
    required,
    empty,
    errors,
    children,
    ...otherProps
}) => {

    title = (value !== '' || empty !== '') ? title : undefined
    // @ts-expect-error: shush
    const { visualMedia, typesAllowed, preview, documentId, schemaId, ...remainingProps } = otherProps

    return (
        <Col
            className="mb-3" //default if className isn't passed
            valign="top"
            {...remainingProps}>
            {(title) &&
                <Label
                    text={title}
                    required={required}
                    hint={hint}
                    bold
                    {...description && {className: 'm-0'} }
                />
            }

            {(description) &&
                <Text
                    color="muted"
                    className="mb-2">
                    {description}
                </Text>
            }

            {children}

            {(errors) &&
                <Text
                    color='danger'
                    className="mt-1">
                    {errors}
                </Text>
            }
        </Col>
    )
})

export default Wrapper

import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import Delegator from './Delegator'
import {deal} from './utils/Deal'

const Container = ({
    templates,
    value: parentValue,
    errors: parentErrors,
    onChange,
    items,
    ...props
}) => {
    const LayoutTemplate = useCallback(templates.layout, [])

    return <LayoutTemplate
        {...props}
    >
        {items?.map(({id: itemId, ...itemProps}, i) => {
            const itemValue = deal(parentValue, itemId)
            const itemErrors = deal(parentErrors, itemId)

            return <Delegator
                key={i}
                id={itemId}
                value={itemValue}
                errors={itemErrors}
                onChange={onChange}
                {...itemProps}
            />
        })}
    </LayoutTemplate>
}

Container.propTypes = {
    templates: PropTypes.shape({
        layout: PropTypes.func.isRequired
    }).isRequired,
    items: PropTypes.array
}

export default Container

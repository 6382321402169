import { decorate, inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import { APIDataAny, APIDataRoom } from '@evertel/types'
import { Api } from '@evertel/api'
import { RoomStore } from '@evertel/stores'
import { SessionState } from '@evertel/session'

type CloneConfig = { [x: string]: APIDataAny }

class CloneRoomController {

    constructor(
        private api: Api,
        private roomStore: RoomStore,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    async cloneRoom(roomId: number, config: CloneConfig): Promise<APIDataRoom> {
        const clone = await this.api.Routes.Room.postClone(roomId, config) as APIDataRoom

        if (clone) {
            runInAction(() => {
                this.roomStore.update(clone)
                this.session.addRoomIdIBelongTo(clone.id)
            })
        }

        return clone
    }
}

decorate(injectable(), CloneRoomController)
decorate(inject(Api), CloneRoomController, 0)
decorate(inject(RoomStore), CloneRoomController, 1)
decorate(inject(SessionState), CloneRoomController, 2)

export { CloneRoomController }
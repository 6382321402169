import React from 'react'
import { HTMLAttributes, forwardRef } from 'react'
import classNames from 'classnames'

interface TabContentProps extends HTMLAttributes<HTMLDivElement> {
    className?: string
}

const TabContent = forwardRef<HTMLDivElement, TabContentProps>(({
    children,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('tab-content', className)

    return (
        <div
            ref={ref}
            className={_className}
            {...otherProps}
        >
            {children}
        </div>
    )
})

export { TabContent }
TabContent.displayName = 'TabContent'

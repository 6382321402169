import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { DocumentSchemaStore } from '@evertel/stores'
import { Api } from '@evertel/api'


class CategorySchemasController {
    categoryId = 0
    schemas = []

    constructor(
        private documentSchemaStore: DocumentSchemaStore,
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = async (categoryId: number) => {
        this.categoryId = categoryId
        this.schemas = []
        
        await this.fetchDocumentSchemasByCategory()
    }

    fetchDocumentSchemasByCategory = async (): Promise<void> => {
        if (!this.categoryId) return
        
        // fetch schemas
        const schemas = await this.api.Routes.DocumentSchemaCategory.getDocumentSchemas(this.categoryId, {
            order: 'name ASC'
        })

        // save resulting schemas in the documentSchemaStore
        if (schemas?.length) this.documentSchemaStore.update(schemas)

        // update observables
        runInAction(() => {
            this.schemas = schemas
        })
    }
}

decorate(injectable(), CategorySchemasController)
decorate(inject(DocumentSchemaStore), CategorySchemasController, 0)
decorate(inject(Api), CategorySchemasController, 1)

export { CategorySchemasController }

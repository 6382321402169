import { DIVIDER } from './common'

const resolveValue = (path, data) => {
    const [first, ...rest] = path.split(DIVIDER)

    if (rest.length > 0) {
        if (first.includes('[]')) {
            let concatenated = []

            if (typeof data[first.replace('[]', '')] === 'undefined') {
                return []
            }

            data[first.replace('[]', '')].forEach((value, i) => {
                let result = resolveValue(
                    rest.join(DIVIDER),
                    value
                )

                result = result.map(({path, value}) => {
                    return {
                        path: `${first.replace('[]', '')}[${i}]${DIVIDER}${path}`,
                        value: value
                    }
                })

                concatenated = [
                    ...concatenated,
                    ...result
                ]
            })

            return concatenated

        } else {
            const result = resolveValue(
                rest.join(DIVIDER),
                data[first]
            )

            return result.map(({path, value}) => {
                return {
                    path: `${first.replace('[]', '')}${DIVIDER}${path}`,
                    value: value
                }
            })
        }
    } else {
        if (first.includes('[]')) {
            if (typeof data[first.replace('[]', '')] === 'undefined') {
                return []
            }

            return data[first.replace('[]', '')].map((value, i) => {
                return {
                    path: `${first.replace('[]', '')}[${i}]`,
                    value: value
                }
            })
        } else {
            return [
                {
                    path: first,
                    value: data[first]
                }
            ]
        }
    }
}

export {
    resolveValue
}

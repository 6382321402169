import React from 'react'
import Select from 'react-select'
import { departmentTypes } from '@evertel/constants'

interface Props {
    selected?: any[],
    isMulti?: boolean,
    placeholder?: string,
    onSelect: (any) => void,
    menuPlacement?: string
}

const DepartmentTypesSelect: React.FC<Props> = ({
    selected,
    isMulti,
    placeholder = 'Select...',
    onSelect,
    ...otherProps
}) => {

    const _onSelect = (e) => {
        onSelect && onSelect(e)
    }

    return (
        <Select
            name="department-type"
            options={departmentTypes}
            value={selected}
            onChange={_onSelect}
            isMulti={isMulti}
            placeholder={placeholder}
            className="react-select"
            classNamePrefix="select"
            {...otherProps}
        />
    )
}

export { DepartmentTypesSelect }

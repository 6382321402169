import { ContainerModule } from '@evertel/di'
import { PushService } from './PushService'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(PushService).toSelf().inSingletonScope()
    })
]

export {
    DI_MODULES
}
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { decorate, inject, injectable } from '@evertel/di'
import { APIDataMedia } from '@evertel/types'
import { Api } from '@evertel/api'
import { APIDataMediaToNormalizedMedia, MediaBlobber, NormalizedMedia } from '@evertel/media'

class DocumentMediaReadWidgetController {
    media: NormalizedMedia[]|[] = []

    constructor(
        private api: Api,
        private mediaBlobber: MediaBlobber
    ) {
        makeAutoObservable(this)
    }

    init = async (documentId: number, mediaIds: Array<number> = []) => {
        //console.log('MEDIA CONTROLLER INIT', toJS(mediaIds))
        if (!documentId) return

        // @ts-expect-error this is here for bad data that has been seen in old documents.
        if (mediaIds?.uploaded) mediaIds = mediaIds.uploaded

        try {
            const media = await this.api.Routes.Document.getMedia(documentId, {
                where: {
                    id: { inq: mediaIds }
                }
            })

            const normalizedMedia = media.map(APIDataMediaToNormalizedMedia)

            runInAction(() => {
                this.media = normalizedMedia || []
            })
        } catch (error) {
            console.error(error.message)
        }
    }

    // blobMedia = async (media: APIDataMedia, width: number, height: number) => {
    //     // blobs one media item
    //     const blob = await this.mediaBlobber.resolveBlob(
    //         media,
    //         width,
    //         height
    //     )

    //     return {
    //         id: media.id as number,
    //         type: media.mimetype,
    //         name: media.fileName as string,
    //         size: media.contentLength as number,
    //         url: URL.createObjectURL(blob)
    //     }
    // }

    // blobbedImages = async (): Promise<APIDataMedia[]> => {
    //     // blobs all images (used for PDF exports)
    //     const images: APIDataMedia[] = []

    //     for (const image of this.images) {
    //         const blob = await this.mediaBlobber.resolveBlob(image)
    
    //         images.push({
    //             url: URL.createObjectURL(blob),
    //             ...image
    //         })
    //     }

    //     return images
    // }

    get visualMedia(): NormalizedMedia[] {
        return this.media
            .filter((media: NormalizedMedia) => {
                return (
                    (media.mimeType as string).includes('image/')
                    || (media.mimeType as string).includes('video/')
                )
            })
    }

    get images(): NormalizedMedia[] {
        return this.media.filter((media: NormalizedMedia) => media.mimeType?.includes('image/'))
    }

    get files(): NormalizedMedia[] {
        return this.media
            .filter((media: NormalizedMedia) => {
                return (
                    !(media.mimeType as string).includes('image/')
                    && !(media.mimeType as string).includes('video/')
                )
            })
    }
}

decorate(injectable(), DocumentMediaReadWidgetController)
decorate(inject(Api), DocumentMediaReadWidgetController, 0)
decorate(inject(MediaBlobber), DocumentMediaReadWidgetController, 1)

export {
    DocumentMediaReadWidgetController
}

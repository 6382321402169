import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Row, Checkbox, Image, Text } from '@evertel/web/ui'
import Wrapper from './Wrapper'
import { SchemaFieldMediaController, SchemaMediaController } from '../../controller'
import { useService } from '@evertel/di'

export interface CheckboxWriteWidgetProps {
    id: string,
    value: string|string[],
    onChange?: (id: string|number, value: string|boolean|string[]|undefined) => void,
    title: string,
    label?: string,
    required?: boolean,
    hint?: string,
    description?: string,
    scope?: string,
    errors?: string[],
    allowMultiple?: boolean,
    options: {value: string, label: string, image: number|File}[],
    mediaController: SchemaMediaController,
    schemaId?: number
}

const CheckboxWriteWidget: React.FC<CheckboxWriteWidgetProps> = observer(({
    id,
    value,
    onChange,
    title,
    label,
    required,
    options,
    hint,
    scope,
    errors,
    allowMultiple = true,
    description,
    schemaId,
    ...otherProps
}) => {

    //console.log('CHECKBOX', {schemaId}, {value}, {options}, {...otherProps})

    const fieldMediaController = useService(SchemaFieldMediaController, [schemaId])

    const [values, setValues] = useState(Array.isArray(value) ? value : (value) ? [value] : [])

    useEffect(() => {
        const mediaIds: number[] = []
        options?.map(o => {
            if (o.image) {
                mediaIds.push(o.image as number)
            }
        })

        fieldMediaController.init(
            schemaId,
            mediaIds
        )
    }, [schemaId])

    useEffect(() => {
        onChange && onChange(id, values)
    }, [values])

    const _onChange = (e: any) => {
        let _value = values

        if (allowMultiple) {
            if (e.checked && !_value?.includes(e.value)) {
                _value.push(e.value as string)
            } else {
                _value = _value?.filter(v => v !== e.value)
            }
        } else {
            _value = (e.checked) ? [e.value] : []
        }

        setValues([..._value])
    }

    // backwards compatibility (some original schemas formatted single checkboxes differently)
    if (!options?.length) {
        return (
            <Checkbox
                id={id}
                name={id}
                value={value}
                checked={!!value}
                onChange={(e) => onChange && onChange(id, e.checked)}
                label={label}
                disabled={scope === 'schemaBuilder'}
            />
        )
    }

    return (
        <Wrapper
            title={title}
            required={required}
            hint={hint}
            description={description}
            errors={errors}
            {...otherProps}>
            <Row className="flex-wrap">
                {options?.map((option, idx) => {
                    const image = (option.image instanceof File) ?
                        fieldMediaController?.getLocalFileURL(option.image as File) as string :
                        fieldMediaController?.mediaArrays?.uploaded?.find(m => m.id === Number(option.image))?.url as string

                    return (
                        <div
                            key={idx}
                            style={{
                                width: (option.image) ? '33%' : '25%'
                            }}>
                            <Checkbox
                                id={option.value}
                                name={option.value}
                                value={option.value}
                                checked={values?.includes(option.value)}
                                onChange={_onChange}
                                label={option.label}
                                disabled={scope === 'schemaBuilder'}
                            />
                            {(option.image && image) &&
                                <div className="mb-3">
                                    <Image
                                        src={image}
                                        resizeMode="contain"
                                        style={{
                                            width: 100,
                                            height: 66
                                        }} 
                                        url={''}                                    />
                                </div>
                            }
                        </div>
                    )
                })}
            </Row>
        </Wrapper>
    )
})

// ----------------------------------------------------------------------------------------

export interface CheckboxReadWidgetProps {
    id: string,
    value?: string[],
    title?: string,
    label?: string,
    schemaId?: number,
    documentId?: number,
    options: {value: string, label: string, image?: number|string}[]
}

const CheckboxReadWidget: React.FC<CheckboxReadWidgetProps> = observer(({
    id,
    value = [],
    title,
    options,
    schemaId,
    label,
    ...otherProps
}) => {

    //console.log('CHECKBOX', {schemaId}, {id}, {label}, {value}, {options}, otherProps)

    const fieldMediaController = useService(SchemaFieldMediaController, [])

    useEffect(() => {
        const mediaIds: number[] = []
        if (options?.length) {
            options.map(o => {
                if (o.image) {
                    mediaIds.push(o.image as number)
                }
            })
        }

        fieldMediaController.init(
            schemaId,
            mediaIds
        )
    }, [schemaId])

    const values = Array.isArray(value) ? value : [value]

    return (
        <Wrapper
            value={value}
            title={title}>
            <Row className="flex-wrap">
                {(!values?.length) &&
                    <Text color="muted">
                        ---
                    </Text>
                }
                {(!options?.length) ?
                    <Checkbox
                        id={id}
                        name={id}
                        label={label}
                        checked={!!value}
                    />
                :
                <>
                    {values?.map((value, idx) => {
                        const found = options?.find(option => option.value === value)
                        const image = found ? fieldMediaController?.mediaArrays?.uploaded?.find(m => m.id === Number(found.image)) : undefined

                        if (!found && !value) return null

                        return(
                            <div
                                key={idx}
                                style={{
                                    width: (found.image) ? '33%' : '25%',
                                    paddingRight: 20
                                }}>
                                <Checkbox
                                    key={idx}
                                    checked={true}
                                    label={found.label || value}
                                />
                                {(found?.image && image) &&
                                    <div className="mb-4">
                                        <Image
                                            src={image?.url as string}
                                            resizeMode="contain"
                                            style={{
                                                width: 100,
                                                height: 66
                                            }}
                                            url={''}                                        />
                                    </div>
                                }
                            </div>
                        )
                    })}
                    </>
                }
            </Row>
        </Wrapper>
    )
})

export {
    CheckboxWriteWidget,
    CheckboxReadWidget
}

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import PerfectScrollbar from 'react-perfect-scrollbar'
//evertel
import { useService } from '@evertel/di'
import {
    Checkbox,
    RoomSearchSelect,
    Row,
    Col,
    Button,
    Text,
    Modal,
    ModalHeader,
    ModalBody,
    InfoBox, useUI
} from '@evertel/web/ui'
import { DocumentACLsController, DocumentACLPrincipalsController } from '@evertel/document'
import { APIDataDocumentACL, APIDataRoom } from '@evertel/types'
import { LoadingPlaceholder } from './LoadingPlaceholder'
import { ACLButton } from './ACLButton'
import { ACL } from './ACL'
interface ShareModalProps {
    isVisible: boolean
    onClose: () => void
    departmentId: number
    currentUserId: number
    documentId: number
    documentName?: string
    backdrop?: boolean
}

export const DocumentShareModal: React.FC<ShareModalProps> = observer(({
    isVisible = false,
    onClose,
    departmentId,
    currentUserId,
    documentId,
    documentName,
    backdrop = true
}) => {

    const documentACLController = useService(DocumentACLsController, [])
    const documentACLPrincipalsController = useService(DocumentACLPrincipalsController, [])

    const { addToast } = useUI()

    const [isLoading, setIsLoading] = useState(false)
    const [selectedRooms, setSelectedRooms] = useState<APIDataRoom[] | null>([])

    useEffect(() => {
        (async () => {
            if (!documentId || !currentUserId) return
            // libs/web/document/src/view/search-documents/DocumentSearch.js is passing documentId as an empty object here
            // TODO: fix or convert DocumentSearch.js to TS to avoid sending wrong types.
            if (typeof documentId === 'object') return

            setIsLoading(true)

            await documentACLController.init(
                documentId,
                currentUserId
            )
            await documentACLPrincipalsController.init(
                documentId
            )
            setIsLoading(false)
        })()
    }, [currentUserId, departmentId, documentId])


    const onChangeACL = (access: string, acl: APIDataDocumentACL, index = -1) => {
        if (acl.id) {
            // this handles changing existing room ACLs
            switch (access) {
                case 'delete':
                    documentACLController.removeACL(acl.id as number)
                    break
                case 'share':
                    documentACLController.toggleAccessTypeShare(acl.id as number)
                    break
                case 'read':
                case 'edit':
                    documentACLController.toggleAccessTypeReadEdit(acl.id as number, access)
                    break
                default:
                    break
            }
        } else {
            // this handles new room ACLs    
            if (index > -1) {
                const newRooms = [...selectedRooms]
                switch (access) {
                    case 'share':
                        switch (acl.accessType) {
                            case DocumentACLsController.accessTypes.EDIT:
                                newRooms[index].accessType = DocumentACLsController.accessTypes.EDIT_AND_SHARE
                                break
                            case DocumentACLsController.accessTypes.READ:
                                newRooms[index].accessType = DocumentACLsController.accessTypes.READ_AND_SHARE
                                break
                            case DocumentACLsController.accessTypes.EDIT_AND_SHARE:
                                newRooms[index].accessType = DocumentACLsController.accessTypes.EDIT
                                break
                            case DocumentACLsController.accessTypes.READ_AND_SHARE:
                                newRooms[index].accessType = DocumentACLsController.accessTypes.READ
                                break
                            default:
                                break
                        }
                        break
                    case 'read':
                        newRooms[index].accessType = DocumentACLsController.accessTypes.READ
                        break
                    case 'edit':
                        newRooms[index].accessType = DocumentACLsController.accessTypes.EDIT
                        break
                    default:
                        break
                }
                setSelectedRooms(newRooms)
            }

        }
    }

    const addRoomAcl = async (rooms: APIDataRoom[]): Promise<void> => {
        if (!rooms || rooms.length < 1) return

        try {
            setIsLoading(true)
            for (let i = 0; i < rooms.length; i++) {
                await documentACLController.addACL(rooms[i].id, DocumentACLsController.principalTypes.Room, rooms[i].accessType, { urgent: rooms[i].urgent })
            }

            setSelectedRooms([])
            setIsLoading(false)
        } catch (error: any) {
            console.error('addRoomACL ERROR:', error)

            addToast({
                color: 'danger',
                title: 'Oops!',
                message: error.message
            })
        } finally {
            setTimeout(() => {
                onClose()
                setSelectedRooms([])
            }, 1000)
        }
    }

    const handleAddRoom = (rooms: APIDataRoom[]) => {
        if (rooms.length > selectedRooms.length) {
            rooms[rooms.length - 1].urgent = false
            rooms[rooms.length - 1].accessType = DocumentACLsController.accessTypes.READ
        }
        setSelectedRooms(rooms)
    }

    const handleNotify = (checked: boolean, index: number) => {
        const newRooms = [...selectedRooms]
        newRooms[index].urgent = checked
        setSelectedRooms(newRooms)
    }

    if (!documentId || !currentUserId) return null

    return (
        <Modal
            visible={isVisible}
            alignment="center"
            backdrop={backdrop}
            onClose={onClose}
            onCloseDone={() => setSelectedRooms([])}
            size="lg">
            <ModalHeader
                title={(documentName) ? `Share "${documentName}"` : 'Share EverDoc'}
            />
            <ModalBody className="pb-5">
                <Row>
                    <Col xs={10}
                        valign="top">
                        <RoomSearchSelect
                            departmentId={departmentId}
                            searchScope="member"
                            placeholder="Add rooms..."
                            onSelect={handleAddRoom}
                            selected={selectedRooms}
                            clearOnSelect
                            className="mb-4"
                            isMulti
                        />
                    </Col>
                    <Col
                        valign="top"
                        align="right"
                        className='ml-2'>
                        <Button
                            loading={isLoading}
                            color="info"
                            onClick={() => addRoomAcl(selectedRooms)}>
                            Share
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} valign="start">
                        {(!isLoading) &&
                            <div
                                className='document-share-tile'>
                                {selectedRooms.map(({ id, name, urgent, accessType }, index) =>
                                    <InfoBox
                                        color="info"
                                        className="fadeIn w-90"
                                        key={id}
                                    >
                                        <Row>
                                            <Col>
                                                <Text
                                                    bold
                                                    className="mb-2">
                                                    {name}
                                                </Text>
                                                <Checkbox
                                                    id="notify"
                                                    checked={!urgent}
                                                    onChange={() => handleNotify(false, index)}
                                                    label="Notify room"
                                                />
                                                <Checkbox
                                                    id="notify-urgent"
                                                    color="danger"
                                                    checked={urgent}
                                                    onChange={() => handleNotify(true, index)}
                                                    label="Notify room as urgent"
                                                />
                                            </Col>
                                            <>
                                                <Text
                                                    bold
                                                    className="mb-2"
                                                >
                                                    Access:&nbsp;
                                                </Text>
                                                <ACLButton
                                                    acl={{ accessType: accessType } as APIDataDocumentACL}
                                                    documentACLController={documentACLController}
                                                    onChangeACL={(access, acl) => onChangeACL(access, acl, index)}
                                                />
                                            </>
                                        </Row>
                                    </InfoBox>
                                )}
                            </div>
                        }
                    </Col>
                    <Col className="ml-4" valign="start">
                        <div className="mb-4">
                            <Text
                                bold
                                size={13}
                                color="muted">
                                People with access
                            </Text>
                            {/* @ts-expect-error: ignore */}
                            <PerfectScrollbar
                                options={{ suppressScrollX: true }}>
                                {(documentACLController.aclUsers?.map(acl =>
                                    <ACL
                                        key={acl.id}
                                        acl={acl}
                                        departmentId={departmentId}
                                        currentUserId={currentUserId}
                                        documentACLController={documentACLController}
                                        documentACLPrincipalsController={documentACLPrincipalsController}
                                        onChangeACL={onChangeACL}
                                    />
                                ))}
                            </PerfectScrollbar>
                        </div>
                        <Text
                            bold
                            size={13}
                            color="muted">
                            Rooms shared with
                        </Text>
                        {/* @ts-expect-error: ignore */}
                        <PerfectScrollbar
                            options={{ suppressScrollX: true }}
                            style={{
                                height: '30vh'
                            }}>
                            {(documentACLController.aclRooms?.map(acl =>
                                <ACL
                                    key={acl.id}
                                    acl={acl}
                                    departmentId={departmentId}
                                    documentACLController={documentACLController}
                                    documentACLPrincipalsController={documentACLPrincipalsController}
                                    onChangeACL={onChangeACL}
                                />
                            ))}
                        </PerfectScrollbar>
                        {(!documentACLController.aclRooms?.length) &&
                            <Text
                                tag="p"
                                color="muted"
                                size={12}
                                className="mt-2">
                                <i>Not currently shared with any rooms</i>
                            </Text>
                        }
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
})
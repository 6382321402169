import { makeAutoObservable } from 'mobx'
import { injectable, decorate } from '@evertel/di'
import { APIDataMedia } from '@evertel/types'
import { getFileExt, formatBytes } from '@evertel/utils'

class AudioController {
    audio: APIDataMedia|File = {}

    constructor(

    ) {
        makeAutoObservable(this)
    }

    init = (audio: APIDataMedia|File) => {
        this.audio = audio
    }

    get isLocal() {
        return this.audio instanceof File
    }

    get url() {
        if (this.isLocal) {
            // if a local file, return blob
            return URL.createObjectURL(this.audio as File)
        }

        return (this.audio as APIDataMedia)?.url
    }

    get fileName() {
        return (this.isLocal) ? (this.audio as File)?.name : (this.audio as APIDataMedia)?.fileName
    }

    get fileExtension() {
        return getFileExt(this.audio)
    }

    get humanizedFileSize() {
        return formatBytes((this.isLocal) ? (this.audio as File)?.size : (this.audio as APIDataMedia)?.contentLength, 1)
    }
}

decorate(injectable(), AudioController)

export {
    AudioController
}

import { makeAutoObservable, runInAction } from 'mobx'
import { decorate, injectable, inject } from 'inversify'
import { debounce } from 'lodash'
import { Api } from '@evertel/api'
import { SessionState } from '@evertel/session'


class AuditsSearchController {
    audits: any[] = []
    count: number = 0
    departmentId: number | null = null
    searchTerm: string = ''
    page: number = 1
    limit: number = 25
    isLoading: boolean = false
    error: any = null
    sortBy: string = 'id'
    sortOrder: 'asc' | 'desc' = 'desc'

    constructor(
        private api: Api,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    init(departmentId: number) {
        this.departmentId = departmentId
        this.resetState()
    }

    setSearchTerm(value: string) {
        this.searchTerm = value
        this.resetSearchMeta()
    }

    setPage(page: number) {
        this.page = page
    }

    setLimit(limit: number) {
        this.limit = limit
        this.resetSearchMeta()
    }

    setSorting(sortBy: string, sortOrder: 'asc' | 'desc') {
        this.sortBy = sortBy
        this.sortOrder = sortOrder
        this.resetSearchMeta()
    }

    private debouncedSearchInternal = debounce(async () => {
        try {
            await this.search()
        } catch (e) {
            runInAction(() => {
                this.error = e
            })
        }
        runInAction(() => {
            this.isLoading = false
        })
    }, 500)

    debouncedSearch = () => {
        this.isLoading = true
        return this.debouncedSearchInternal()
    }

    search = async () => {
        if (!this.departmentId) return

        runInAction(() => {
            this.isLoading = true
            this.error = null
        })

        try {
            const results = await this.api.Routes.Department.getAudits(this.departmentId, this.filter)
            const count = await this.fetchAuditsCount()

            runInAction(() => {
                this.audits = results
                this.count = count
                this.isLoading = false
            })
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isLoading = false
            })
        }
    }

    async fetchAuditsCount() {
        if (!this.departmentId) return 0
        const res = await this.api.Routes.Department.getAuditsCount(this.departmentId, this.filter.where)
        return res?.count ?? 0
    }

    resetSearchMeta() {
        this.page = 1
        this.audits = []
        this.count = 0
    }

    resetState() {
        this.resetSearchMeta()
        this.searchTerm = ''
        this.sortBy = 'id'
        this.sortOrder = 'desc'
    }

    get filter() {
        const filter: any = {
            include: ['createdBy'],
            limit: this.limit,
            skip: (this.page - 1) * this.limit,
            order: `${this.sortBy} ${this.sortOrder}`
        }

        if (this.searchTerm.trim() !== '') {
            filter.where = {
                description: { like: `%${this.searchTerm.trim()}%` }
            }
        }

        return filter
    }

    async checkAuditStatus(auditId: number) {
        if (!this.departmentId) return null
        try {
            const audit = await this.api.Routes.Department.getAuditsById(this.departmentId, auditId)
            return audit.status
        } catch (e) {
            runInAction(() => {
                this.error = e
            })
            return null
        }
    }
}

decorate(injectable(), AuditsSearchController)
decorate(inject(Api), AuditsSearchController, 0)
decorate(inject(SessionState), AuditsSearchController, 1)

export { AuditsSearchController }
import { resolveFields } from './resolveFields'
import { resolveValue } from './resolveValue'
import { setValue } from './setValue'

const updateValues = async (
    type: string,
    schema: any,
    data: any,
    callback: (value: Record<string, any>) => void
) => {

    // grab the object paths to all matching 'types'
    const paths = resolveFields(
        type,
        schema,
        '',
        []
    )

    // loop through each path...
    for (const path of paths) {
        // grab value set for object path
        const values = resolveValue(path, data)

        // loop through each value (a media field will have multiple values for example)
        for (const { path, value } of values) {
            // process the value (if media, callback will upload and return the new mediaId)
            const newValue = await callback(value)

            // set the value (i.e. new mediaId) for the value of the object path
            if (typeof newValue !== 'undefined') {
                setValue(path, data, newValue)
            }
        }
    }

    return data
}

export {
    updateValues
}

import { observable, action, makeObservable, runInAction, computed } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { DepartmentStore } from '@evertel/department'
import { Api } from '@evertel/api'

class DocumentDepartmentPickerController {
    loaded: boolean
    // Selected department
    selected: number | null
    // All departments
    all!: Array<number>

    constructor(
        private api: Api,
        private departmentStore: DepartmentStore
    ) {
        makeObservable(this, {
            loaded: observable,
            selected: observable,
            all: observable,
            load: action,
            department: computed,
            departments: computed
        })

        this.loaded = false
        this.selected = null
    }

    load = async (id: number | null) => {

        const departments = await this.api.Routes.Document.getSearchDepartment()

        await this.departmentStore.update(departments)

        const all = departments.map(({id}) => id) as number[]

        runInAction(() => {
            if (id !== null) {
                this.selected = id
            }
            this.loaded = true
            this.all = all
        })

    }

    clear = () => {
        this.selected = null
    }

    select = (id: number) => {
        this.selected = id
    }

    get department() {
        if (!this.selected) {
            return null
        }

        return this.departmentStore.findById(this.selected)
    }

    get departments() {
        return this.departmentStore.findByIds(this.all)
    }
}

decorate(injectable(), DocumentDepartmentPickerController)
decorate(inject(Api), DocumentDepartmentPickerController, 0)
decorate(inject(DepartmentStore), DocumentDepartmentPickerController, 1)

export {
    DocumentDepartmentPickerController
}

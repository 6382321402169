import React from 'react'
import { Link } from 'react-router-dom'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

import debugModule from 'debug'
const debug = debugModule('app:Push:WebPushImplementation')

// evertel
import { decorate, inject, injectable } from '@evertel/di'
import { NotificationClicked, NotificationPermission, NotificationPermissionResult, PushImplementation, PushNotification, parsePushNotification } from '@evertel/push'
import { ToastService } from '@evertel/web/ui'



/**
 * WebPushImplementation class
 * 
 * This class implements the PushImplementation interface for web browsers,
 * handling push notifications using Firebase Cloud Messaging (FCM).
 */
class WebPushImplementation implements PushImplementation {
    private firebaseApp: any
    private messaging: any
    private token: string = ''
    private onMessageCallback: ((message: PushNotification) => void) | undefined
    private onPermissionChangeCallback: ((permission: NotificationPermission) => void) | undefined
    private onNotificationClickedCallback: ((notificationClicked: NotificationClicked) => void) | undefined

    constructor(private toastService: ToastService) {}

    /**
     * Initialize the WebPushImplementation
     * This method sets up Firebase, registers the service worker, and sets up message handlers
     */
    async init(): Promise<void> {
        debug('Initializing WebPushImplementation')

        // Firebase configuration
        const firebaseConfig = {
            apiKey: 'AIzaSyBd6D1557vYaoxtWiVc0jbByXxSl8dYGfU',
            authDomain: 'blue-12d4a.firebaseapp.com',
            databaseURL: 'https://blue-12d4a.firebaseio.com',
            projectId: 'blue-12d4a',
            storageBucket: 'blue-12d4a.appspot.com',
            messagingSenderId: '311541489188',
            appId: '1:311541489188:web:8e8560f708b2c6ea1f42b1'
        }

        // Initialize Firebase
        this.firebaseApp = initializeApp(firebaseConfig)
        this.messaging = getMessaging(this.firebaseApp)

        // Register service worker
        // This is registered by firebase and will result in duplicate registrations if activated
        // await this.registerServiceWorker()

        // Set up message handler
        this.setupMessageHandler()

        // Set up permission change listener
        this.setupPermissionChangeListener()
    }

    /**
     * Register the Firebase messaging service worker
     */
    private async registerServiceWorker(): Promise<void> {
        if ('serviceWorker' in navigator) {
            try {
                const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js')
                debug('Service Worker registered with scope:', registration.scope)
            } catch (error) {
                debug('Service Worker registration failed:', error)
                throw error
            }
        } else {
            console.warn('app:Push:WebPushImplementation: Service workers are not supported in this browser')
        }
    }

    /**
     * Set up the message handler for incoming push notifications
     */
    private setupMessageHandler(): void {
        onMessage(this.messaging, (payload) => {
            debug('Received foreground message:', payload)
            const pushNotification = parsePushNotification(payload)
            
            if (pushNotification) {
                this.handleForegroundNotification(pushNotification)
            } else {
                console.error('app:Push:WebPushImplementation: Failed to parse push notification:', payload)
            }
        })

        // Listen for messages from the service worker
        navigator.serviceWorker.addEventListener('message', (event) => {
            if (!event.data) return

            debug('Received message event type:', event.data.type, event.data)
            switch (event.data.type) {
                case 'BACKGROUND_MESSAGE': {
                    const pushNotification = parsePushNotification(event.data, false)
                    if (pushNotification) {
                        this.handleBackgroundNotification(pushNotification)
                    }
                    return
                }
                case 'NAVIGATE': {
                    window.location = event.data.url
                    return 
                }
                default:
                    debug('Received message event:', event.data)
            }
        })
    }

    /**
     * Handle a foreground notification
     * @param pushNotification The parsed push notification
     */
    private handleForegroundNotification(pushNotification: PushNotification): void {
        const currentPath = window.location.hash.slice(1) // Remove the leading '#'
        const notificationPath = this.getNotificationPath(pushNotification)

        // Call the message callback if set
        if (this.onMessageCallback) {
            this.onMessageCallback(pushNotification)
        }


        // Don't show notification if user is already in the relevant room/thread
        if (pushNotification.notification) {
            if (currentPath !== notificationPath) {
                this.showToast(pushNotification)
                this.playNotificationSound(pushNotification)
            }
        }

    }

    /**
     * Handle a background notification
     * @param pushNotification The parsed push notification
     */
    private handleBackgroundNotification(pushNotification: PushNotification): void {
        // Play notification sound
        this.playNotificationSound(pushNotification)

        // Call the message callback if set
        if (this.onMessageCallback) {
            this.onMessageCallback(pushNotification)
        }
    }

    /**
     * Show a toast notification
     * @param pushNotification The push notification to display
     */
    private showToast(pushNotification: PushNotification): void {
        let title = 'New Notification'

        if (pushNotification.notification?.title) {
            title = pushNotification.notification?.title
            if (pushNotification.data.type === 'RoomMessage') {
                title = `#${title}`
            }
            if (pushNotification.data.isUrgent) {
                title = `Urgent: ${title}`
            }
        }

        const path = this.getNotificationPath(pushNotification)

        const color = pushNotification.data.isUrgent ? 'danger' : 'info'

        this.toastService.addToast({
            onClick: () => {
                window.history.pushState(null, '', `/#${path}`)
                window.dispatchEvent(new HashChangeEvent('hashchange'))
            },
            color: color,
            title: title,
            // @ts-expect-error it's not a string, but it works
            message: pushNotification.notification?.body
        })
    }

    /**
     * Get the path for the notification based on its type
     * @param pushNotification The push notification
     * @returns The path string
     */
    private getNotificationPath(pushNotification: PushNotification): string {
        if (pushNotification.data.type === 'RoomMessage') {
            return `/room/${pushNotification.data.roomId}`
        } else if (pushNotification.data.type === 'ThreadMessage') {
            return `/thread/${pushNotification.data.threadId}`
        }
        return '/' // Default path if type is unknown
    }

    /**
     * Play the notification sound
     * @param pushNotification The push notification
     */
    private playNotificationSound(pushNotification: PushNotification): void {
        const soundFile = `/assets/audio/notifications/` + (pushNotification.notification?.sound ? (pushNotification.notification?.sound + '.mp3') : 'default.mp3')
        const audio = new Audio(soundFile)
        audio.play().catch(error => console.error('app:Push:WebPushImplementation: Failed to play notification sound:', error))
    }

    /**
     * Set up a listener for permission changes
     */
    private setupPermissionChangeListener(): void {
        if ('permissions' in navigator) {
            navigator.permissions.query({ name: 'notifications' }).then((permissionStatus) => {
                permissionStatus.onchange = () => {
                    const permission = this.mapPermissionStatus(permissionStatus.state)
                    if (this.onPermissionChangeCallback) {
                        this.onPermissionChangeCallback(permission)
                    }
                }
            })
        }
    }

    /**
     * Map the browser's permission status to our NotificationPermission type
     * @param status The browser's permission status
     * @returns The mapped NotificationPermission
     */
    private mapPermissionStatus(status: PermissionState): NotificationPermission {
        switch (status) {
            case 'granted': return 'granted'
            case 'denied': return 'denied'
            default: return 'default'
        }
    }

    /**
     * Prompt the user for notification permission
     * @returns A promise resolving to the notification permission result
     */
    async promptForNotificationPermission(): NotificationPermissionResult {
        if ('Notification' in window) {
            const permission = await Notification.requestPermission()
            return permission
        }
        return 'default'
    }

    /**
     * Get the current notification permission
     * @returns A promise resolving to the current notification permission
     */
    async getCurrentNotificationPermission(): NotificationPermissionResult {
        if ('Notification' in window) {
            return Notification.permission
        }
        return 'default'
    }

    /**
     * Get the FCM token for this device
     * @returns A promise resolving to the FCM token
     */
    async getPushToken(): Promise<string> {
        if (!this.token) {
            try {
                this.token = await getToken(this.messaging, {
                    vapidKey: 'BKulwf-fxOJ35dIWDPRJHPp1qXXy6h29wGrKtASpRQor9O8WExBX0MYXACqNkVHkLeKId0q6OLCnBocv_AObF-A'
                })
                // debug('FCM Token:', this.token)
            } catch (error) {
                console.error('app:Push:WebPushImplementation: Error getting FCM token:', error)
                throw error
            }
        }
        return this.token
    }

    /**
     * Delete the current FCM token
     */
    async deletePushToken(): Promise<void> {
        if (this.token) {
            try {
                await this.messaging.deleteToken(this.token)
                this.token = ''
                debug('FCM token deleted')
            } catch (error) {
                console.error('app:Push:WebPushImplementation: Error deleting FCM token:', error)
                throw error
            }
        }
    }

    /**
     * Get initial notification that launched the app (if any)
     * @returns A promise resolving to the initial notification or undefined
     */
    async getInitialNotification(): Promise<PushNotification | undefined> {
        // In web context, this is typically handled by the service worker
        // and passed to the app via a custom event.
        throw new Error('Method not implemented.')
    }

    /**
     * Clear initial notification
     */
    async clearInitialNotification(): Promise<void> {
        // This method is not typically needed in web context
        throw new Error('Method not implemented.')
    }

    /**
     * Show a local notification
     * This is typically used to confirm that notifications are working
     */
    async showLocalNotification(): Promise<void> {
        if ('Notification' in window && Notification.permission === 'granted') {
            new Notification('Evertel Notifications', {
                body: 'Notifications are now enabled for this browser!',
                icon: 'https://www.getevertel.com/wp-content/uploads/2019/06/favicon-114.png'
            })
        }
    }

    /**
     * Set the callback for when a message is received
     * @param callback The function to call when a message is received
     */
    setOnMessageCallback(callback: (message: PushNotification) => void): void {
        this.onMessageCallback = callback
    }

    /**
     * Set the callback for when the notification permission changes
     * @param callback The function to call when the permission changes
     */
    setOnPermissionChangeCallback(callback: (permission: NotificationPermission) => void): void {
        this.onPermissionChangeCallback = callback
    }

    /**
     * Set the callback for when a notification is clicked
     * @param callback The function to call when a notification is clicked
     */
    setOnNotificationClicked(callback: (notificationClicked: NotificationClicked) => void): void {
        this.onNotificationClickedCallback = callback
    }
}

// Apply the injectable decorator
decorate(injectable(), WebPushImplementation)
decorate(inject(ToastService), WebPushImplementation, 0)

export { WebPushImplementation }
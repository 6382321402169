import { IReactionDisposer, autorun, makeAutoObservable, runInAction } from 'mobx'
// evertel
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { uniqBy } from 'lodash'
import { APIDataMedia, APIDataRead, APIDataRoomMessage, APIDataThreadMessage } from '@evertel/types'
import { RoomMessagesStore, ThreadMessagesStore } from '@evertel/stores'
import { APIDataMediaToNormalizedMedia, MediaUpload, MediaUploadService, NormalizedMedia } from '@evertel/media'

class MessageController {
    message: APIDataRoomMessage | APIDataThreadMessage = {}
    modelType: 'room' | 'thread' = 'room'
    reads: APIDataRead[] = []
    readsCount = 0
    _uploadableMedia: MediaUpload[]
    extraMedia: NormalizedMedia[] = []
    reactionDisposer: IReactionDisposer
    constructor(
        private api: Api,
        private roomMessagesStore: RoomMessagesStore,
        private threadMessagesStore: ThreadMessagesStore,
        private mediaUploadService: MediaUploadService
    ) {
        makeAutoObservable(this)
    }

    init = (message: APIDataRoomMessage | APIDataThreadMessage, modelType: 'room' | 'thread', extraMedia?: NormalizedMedia[]) => {
        if (!message) console.error('Missing message! in MessageController.init')

        this.message = message
        this.extraMedia = extraMedia || [] // this includes media parsed from links in the message
        this.modelType = modelType

    }

    get uploadableMedia() {
        // grab uploadable media for this message
        if (!this._uploadableMedia) {
            this._uploadableMedia = this.mediaUploadService.getMediaUploadsForModel(this.message.id, this.modelType)
        }
        return this._uploadableMedia
    }

    startUploadMedia = async () => {
        // TODO: should this be elsewhere?
        await this.mediaUploadService.startMediaUploads(this.uploadableMedia.filter(m => m.state === 'readyToUpload'), this.onUploadComplete)
    }

    retryUploadMedia = async () => {
        await this.mediaUploadService.startMediaUploads(this.uploadableMedia.filter(m => m.state === 'failed'), this.onUploadComplete)
    }

    fetchReads = async () => {
        if (!this.message?.id) return

        let count = this.readsCount
        let results = []

        // grab the total count of reads if we don't have it already
        if (!this.readsCount) {
            count = await this.fetchReadsCount()
        }

        // stop from fetching if we already got them all
        if (count > this.reads?.length) {
            const endpoint = (this.modelType === 'room') ? 'RoomMessage' : 'ThreadMessage'
            results = await this.api.Routes[endpoint].getReads(this.message.id, this.filter)

            runInAction(() => {
                this.reads = uniqBy([...this.reads, ...results], 'id')
            })
        }
    }

    fetchReadsCount = async () => {
        if (!this.message?.id) return 0

        const res = await this.api.Routes[(this.modelType === 'room') ? 'RoomMessage' : 'ThreadMessage'].getReadsCount(this.message.id, this.filter?.where)

        runInAction(() => {
            this.readsCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    retract = async () => {
        const endpoint = (this.modelType === 'room') ? 'RoomMessage' : 'ThreadMessage'
        const msg = await this.api.Routes[endpoint].putRetract(this.message.id)

        runInAction(() => {
            if ((this.modelType === 'room')) {
                this.roomMessagesStore.update(msg)
            } else {
                this.threadMessagesStore.update(msg)
            }
        })

        //log to analytics
        // AnalyticsStore.logEvent({
        //     category: 'User',
        //     action: 'retract_room_message'
        // })
    }

    // This is used by UploadableMediaState.startUpload(). When any media upload completes check if we should publish the message.
    private onUploadComplete = async (mediaUploads: MediaUpload[]) => {
        // TODO: maybe pay attention to 'error' ?
        if (mediaUploads.every((m: { state: string }) => m.state === 'complete')) {
            if (!this.message.publishedDate) {
                // Publish this message
                const publishedMessage = await this.api.Routes[(this.modelType === 'room') ? 'RoomMessage' : 'ThreadMessage'].putPublish(this.message.id) as APIDataRoomMessage | APIDataThreadMessage

                // Update the message in the store
                if ((this.modelType === 'room')) {
                    this.roomMessagesStore.update(publishedMessage)
                } else {
                    this.threadMessagesStore.update(publishedMessage)
                }
            }
        }
    }

    get mediaUploadProgress() {
        const uploadingMedia = this.uploadableMedia.filter(m => m.state === 'uploading')
        const uploadProgress = uploadingMedia.reduce((r, m) => r + m.uploadProgress, 0)
        return (uploadingMedia.length > 0 && uploadProgress > 0) ? (uploadingMedia.length / uploadProgress) : 0
    }

    get filter() {
        return {
            where: {}
        }
    }

    get media(): NormalizedMedia[] {
        const remoteMedia = this.message.media?.map(APIDataMediaToNormalizedMedia) || []

        const filterRemoteMedia = remoteMedia.filter(m => !this.uploadableMedia.some(um => um.remoteMedia?.id === m.id))

        return [...filterRemoteMedia, ...(this.uploadableMedia?.length ? this.uploadableMedia : []), ...this.extraMedia]
    }

}

decorate(injectable(), MessageController)
decorate(inject(Api), MessageController, 0)
decorate(inject(RoomMessagesStore), MessageController, 1)
decorate(inject(ThreadMessagesStore), MessageController, 2)
decorate(inject(MediaUploadService), MessageController, 3)

export { MessageController }

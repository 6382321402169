import { makeAutoObservable, runInAction } from 'mobx'

import _ from 'lodash'
// evertel
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataRoomMessage, APIDataThreadMessage } from '@evertel/types'
import moment from 'moment'
import { RoomStore, RoomMessagesStore, ThreadStore, ThreadMessagesStore } from '@evertel/stores'
import { FetchUserService, UnreadCountsState } from '@evertel/blue-user'
import { MediaUploadService } from '@evertel/media'
import { PushService, PushNotification } from '@evertel/push'
import debugModule from 'debug'
const debug = debugModule('app:MessageWallController')

const GET_NEW_MESSAGE_POLL_ENABLED = true
const GET_ROOM_MESSAGE_POLL_INTERVAL_MS = 10000
const GET_THREAD_MESSAGE_POLL_INTERVAL_MS = 5000

const DEFAULT_INCLUDE = [{
    relation: 'media',
    scope: {
        fields: ['id', 'mimetype', 'url', 'ownerId', 'requiresAuth', 'description', 'previewUrl', 'fileName', 'contentLength', 'meta']
    }
}, {
    relation: 'reactions',
    scope: {
        include: [{
            relation: 'reactedByThrough',
            scope: {
                fields: ['blueUserId']
            }
        }]
    }
}]
const DEFAULT_FIELDS = ['id', 'text', 'roomId', 'threadId', 'isUrgent', 'isRetracted', 'ownerId', 'type', 'meta', 'publishedDate', 'media', 'createdDate', 'updatedDate', 'isSearchable']

class MessageWallController {
    modelId = 0 // roomId or threadId
    modelType: 'room' | 'thread' = 'room'
    messagesCount = 0
    limit = 44
    errorFetching = null
    _filter = {}

    private pollInterval: any | undefined = undefined
    private messagesStore: RoomMessagesStore | ThreadMessagesStore

    constructor(
        private api: Api,
        private roomStore: RoomStore,
        private roomMessagesStore: RoomMessagesStore,
        private threadStore: ThreadStore,
        private threadMessagesStore: ThreadMessagesStore,
        private unreads: UnreadCountsState,
        private mediaUploadService: MediaUploadService,
        private fetchUserService: FetchUserService,
        private pushService: PushService
    ) {
        makeAutoObservable(this)
    }

    init = (modelId: number, modelType: 'room' | 'thread') => {
        debug('init', modelId)
        if (!modelId) {
            console.error('Missing a modelId! in MessageWallController.init')
            return
        }

        this.modelId = modelId
        this.modelType = modelType
        this.messagesStore = (modelType === 'room') ? this.roomMessagesStore : this.threadMessagesStore

        this.pushService.addPushNotificationListener(this.onPushNotification)
        this.startPoll()
    }

    destroy = () => {
        debug('Destroy start', this.modelId, 'interval', this.pollInterval)
        this.pushService.removePushNotificationListener(this.onPushNotification)
        this.stopPoll()
        debug('Destroy end', this.modelId)
    }

    onPushNotification = (notification: PushNotification) => {
        debug('onPushNotification', this.modelId)
        if (!this.api.isProduction) debug('MessageWallController.onPushNotification', notification)
        // If we get a push for the model and id we are looking at, update
        // TODO: make this smarter to only fetch what we know has changed.
        if (notification.data.type === 'RoomMessage' && this.modelType === 'room' && notification.data.roomId === this.modelId) {
            this.fetchMessages('new')
        } else if (notification.data.type === 'ThreadMessage' && this.modelType === 'thread' && notification.data.threadId === this.modelId) {
            this.fetchMessages('new')
        } else {
            this.unreads.fetchUnreadCounts()
        }
    }

    setLimit(limit: number) {
        this.limit = limit
    }

    /**
     * OLD: Retrieve a number of messages prior to oldest message id that is not retracted
     * NEW: Retrieve all messages with updatedDates greater than the newestUpdatedDate including retracted
     *      We must only include retracted with NEW so we know which ones to remove.
     * @param order 
     */
    fetchMessages = async (order: 'old' | 'new' = 'new') => {
        debug('fetchMessages:', this.modelId)
        this.errorFetching = null
        let count = this.messagesCount

        if (order === 'new') this.resetPoll()

        try {
            // grab the total count of messages in this room/thread
            if (!this.messagesCount) {
                count = await this.fetchMessagesCount({ isRetracted: false })
            }

            // stop from fetching if we already got all messages
            // if (count <= this.messagesSorted?.length) return

            // we only want messages with positive ID
            // Question: why/how would they ever be negative?
            const remoteMessages = this.messagesSorted.filter(m => m.id > 0)
            let whereId, orderBy, limit

            // get messages based on the last id we have, or if we have no messages, get the latest
            if (remoteMessages?.length) {
                // const highestId = remoteMessages.reduce((a, m) => Math.max(a, m.id), 0)
                const lowestId = remoteMessages.reduce((a, m) => Math.min(a, m.id), 999999999999)
                const newestUpdatedDate = remoteMessages.reduce((newest, current) => { return (newest > current.updatedDate) ? newest : current.updatedDate }, remoteMessages[0].updatedDate)

                if (order === 'old') {
                    whereId = { id: { lt: lowestId }, isRetracted: false }
                    orderBy = 'id DESC'
                    limit = this.limit
                } else if (order === 'new') { 
                    whereId = { id: { gt: lowestId }, updatedDate: { gt: newestUpdatedDate} }
                    orderBy = 'id ASC'
                    limit = undefined // No limit on this request so we get all updated cases
                }
            } else { // First request, when no messages are available local.
                orderBy = 'id DESC' // force get newest messages
                whereId = { id: { gt: 0 }, isRetracted: false }
                limit = this.limit 
            }

            let newMessages: (APIDataRoomMessage | APIDataThreadMessage)[] = []

            const filter = {
                include: DEFAULT_INCLUDE,
                unpublished: true, // we want to get all messages to keep synchronized with the backend
                where: whereId,
                fields: DEFAULT_FIELDS,
                order: orderBy, // the order matters when im looking to receive for the next set of (limited to GET_MESSAGE_LIMIT) messages and not just the oldest or newest in the DB
                limit: limit
            }

            if (this.modelType === 'room') {
                newMessages = await this.api.Routes.Room.getMessages(this.modelId, filter)
            } else {
                newMessages = await this.api.Routes.Thread.getMessages(this.modelId, filter)
            }

            // Loop through newMessages, delete retracted id's from messagesStore and newMessages array.
            const redactedMessages = newMessages.filter((m) => m.isRetracted)
            const nonRedactedNewMessages = newMessages.filter((m) => !m.isRetracted)

            runInAction(() => {
                // Delete redacted message !
                redactedMessages.forEach(m => this.messagesStore?.deleteById(m.id))
                this.messagesStore?.update(nonRedactedNewMessages)
            })

            // Grab users from messages for our store.
            const ownerIds = nonRedactedNewMessages.map(m => m.ownerId)
            if (this.modelType === 'room') {
                this.fetchUserService.fetchSpecificUsersFromRoom(this.modelId, ownerIds)
            } else {
                this.fetchUserService.fetchSpecificUsersFromThread(this.modelId, ownerIds)
            }

            if (this.modelType === 'room') {
                this.processForwardedMessage(nonRedactedNewMessages)
            }

            if (order === 'new' && nonRedactedNewMessages.length) {
                // old messages should already be read,
                // minimize calls to the reads table
                this.markAllMessagesAsRead()
            }

        } catch (e) {
            this.errorFetching = e
            throw e
        }
    }

    fetchMessagesCount = async (where?) => {
        let count
        if (this.modelType === 'room') {
            count = (await this.api.Routes.Room.getMessagesCount(this.modelId, where)).count
        } else {
            count = (await this.api.Routes.Thread.getMessagesCount(this.modelId, where)).count
        }

        runInAction(() => {
            this.messagesCount = count || 0
        })

        return count || 0
    }

    /**
     * This will fetch a list of rooms that haven't been fetched before.
     * Note: while this is ok for agency forwarded messages, it is not robust enough
     *       for forwarding between agencies
     * @param forwardedRoomIds 
     * @returns 
     */
    fetchDepartmentRooms = async (forwardedRoomIds: number[]) => {

        const existingRooms = this.roomStore.find(room => forwardedRoomIds.includes(room.id))
        const existingRoomIds = existingRooms.map(room => room.id)
        const unknownRoomIds = forwardedRoomIds.filter(id => !existingRoomIds.includes(id))

        if (!unknownRoomIds.length) return

        const filter = {
            where: {
                id: {
                    inq: unknownRoomIds
                }
            }
        }
        const rooms = await this.api.Routes.Department.getRooms(this.room.departmentId, filter)

        this.roomStore.update(rooms)
    }

    processForwardedMessage = (newMessages: APIDataRoomMessage[]) => {

        const forwardedOwnerIds = newMessages
            .filter(m => m.type === 'forwarded' && _.get(m, 'meta.forwardedMessage.ownerId'))
            .map(m => m.meta.forwardedMessage.ownerId)
        this.fetchUserService.fetchUsersForForwardedOwnerIds(this.room.departmentId, forwardedOwnerIds)

        const forwardedRoomIds = newMessages
            .filter(m => m.type === 'forwarded' && _.get(m, 'meta.forwardedMessage.roomId'))
            .map(m => m.meta.forwardedMessage.roomId)
        this.fetchDepartmentRooms(forwardedRoomIds)
    }



    markAllMessagesAsRead = async () => {
        try {
            if (this.modelType === 'room') {
                await this.api.Routes.Room.postMessagesReads(this.modelId)
            } else {
                await this.api.Routes.Thread.postMessagesReads(this.modelId)
            }
    
            runInAction(() => {
                if (this.modelType === 'room') {
                    this.unreads.markReadByRoomId(this.modelId)
                } else {
                    this.unreads.markReadByThreadId(this.modelId)
                }
            })

        } catch (error) {
            debug('markAllMessagesAsRead', error.message)
        }
    }

    startPoll = () => {
        this.stopPoll()

        if (!GET_NEW_MESSAGE_POLL_ENABLED) return

        this.pollInterval = setInterval(async () => {
            try {
                debug('Poll running', this.modelId)
                await this.fetchMessages('new')
            } catch (error: any) {
                if (error.status === 401) {
                    this.stopPoll()
                }
            }

        }, this.getPollIntervalTime())
    }

    stopPoll() {
        if (this.pollInterval) {
            clearInterval(this.pollInterval)
            this.pollInterval = undefined
        }
    }

    getPollIntervalTime() {
        switch (this.modelType) {
            case 'room':
                return GET_ROOM_MESSAGE_POLL_INTERVAL_MS
            case 'thread':
            default:
                return GET_THREAD_MESSAGE_POLL_INTERVAL_MS
        }
    }

    resetPoll() {
        if (this.pollInterval) {
            this.startPoll()
        }
    }

    get messagesWithDates() {
        // constructs an array of messages with date objects inserted
        const messages = this.messagesVisible // excludes retracted messages
        const newMessagesWithDates = []

        runInAction(() => {
            messages.forEach((msg: any, idx: number) => {
                // add a date object every time the message date changes

                // if no publishedDate (new messages not yet posted to server) use createdDate
                const dateField = (msg.publishedDate) ? 'publishedDate' : 'createdDate'

                // convert dates to moment dates to compare dates (and not time)
                const postDate = moment(msg[dateField]).format('LL')
                const prevPostDate = (idx !== 0) ? moment(messages[idx - 1][dateField]).format('LL') : postDate

                // push Date only if the date is diff than last msg OR it is the first msg loaded
                if ((prevPostDate !== postDate) || (idx === 0)) {
                    msg.dateChanged = true
                } else {
                    msg.dateChanged = false
                }
                if (idx > 0) {
                //using spread on msg does weird MobX things: don't
                    msg.prevMessageDate = messages[idx - 1][dateField] 
                    msg.prevOwnerId = messages[idx - 1].ownerId
                }
                newMessagesWithDates.push(msg)

            })
        })

        return newMessagesWithDates
        // return [...newMessagesWithDates]
    }

    get messagesSorted() {
        if (!this.messagesStore) return []
        // returns messages sorted by publishedDate or createdDate in DESC order (newest date on top).
        // **does NOT filter out retracted or empty messages

        const messages = this.messagesStore.find(m => m.roomId === this.modelId || m.threadId === this.modelId)
        const sorted = messages.slice().sort((a: any, b: any) => {
            // sort by publish date, fall back to created date
            if (a.publishedDate && b.publishedDate) {
                return Date.parse(a.publishedDate) - Date.parse(b.publishedDate)
            } else {
                return Date.parse(a.createdDate) - Date.parse(b.createdDate)
            }
        })
        return sorted as Array<APIDataRoomMessage | APIDataThreadMessage>
    }

    get messagesVisible() {
        const sorted = this.messagesSorted?.filter((m: APIDataRoomMessage | APIDataThreadMessage) => {
            //the order of these if statements is significant

            //if there is a current upload nothing else matters, it should show
            const uploadableMedia = this.mediaUploadService.getMediaUploadsForModel(m.id, this.modelType)
            if (uploadableMedia.length) return true

            // don't return unpublished messages 
            if (!m.publishedDate) return false

            // don't return retracted messages
            if (m.isRetracted) return false

            // always display forwarded messages
            if (m.type === 'forwarded') return true

            // if empty, eg no text and no media, don't show
            if (!m.text && !m.media?.length) return false

            return true
        })

        return sorted
    }

    get room() {
        if (this.modelType === 'thread') return undefined
        return this.roomStore.findById(this.modelId)
    }

    get thread() {
        if (this.modelType === 'room') return undefined
        return this.threadStore.findById(this.modelId)
    }

}

decorate(injectable(), MessageWallController)
decorate(inject(Api), MessageWallController, 0)
decorate(inject(RoomStore), MessageWallController, 1)
decorate(inject(RoomMessagesStore), MessageWallController, 2)
decorate(inject(ThreadStore), MessageWallController, 3)
decorate(inject(ThreadMessagesStore), MessageWallController, 4)
decorate(inject(UnreadCountsState), MessageWallController, 5)
decorate(inject(MediaUploadService), MessageWallController, 6)
decorate(inject(FetchUserService), MessageWallController, 7)
decorate(inject(PushService), MessageWallController, 8)

export { MessageWallController }

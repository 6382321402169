import React, {
    Suspense,
    useContext,
    useState
} from 'react'
import { observer } from 'mobx-react'

// evertel
import {
    Text,
    Spinner,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Col,
    Badge
} from '@evertel/web/ui'
import { RoomContext } from '../../RoomContext'
import { RoomDirectorySearchSelect } from '../RoomDirectorySearchSelect'
import { RoomInvitesSearchSelect } from '../RoomInvitesSearchSelect'
import { useInvite } from '@evertel/web/invites'
import { useService } from '@evertel/di'
import { CurrentUserController } from '@evertel/blue-user'
import { RoomRequests } from './RoomRequests'

const AddRoomMembersModal = React.lazy(() => import('../modals/AddRoomMembersModal').then(module => ({ default: module.AddRoomMembersModal })))

const RoomDirectory: React.FC = observer(() => {

    const currentUserController = useService(CurrentUserController, [])

    const { roomController, canManageRoom, asideOpenComplete, roomJoinRequestsController } = useContext(RoomContext)

    const { room } = roomController

    const [showAddRoomMembersModal, setShowAddRoomMembersModal] = useState(false)
    const [activeTab, setActiveTab] = useState<'directory'|'invites'|'requests'>('directory')

    const { openInvite } = useInvite()

    const openAddMembersToRoom = () => {
        if (currentUserController.getRoleInDepartment(room?.departmentId) === 'guest') {
            openInvite('guest', [{
                label: room?.name,
                value: room?.id
            }])
        } else {
            setShowAddRoomMembersModal(true)
        }
    }

    const canManageRequests = canManageRoom && (currentUserController.getRoleInDepartment(room.departmentId) !== 'guest')

    if (!asideOpenComplete || !room?.id) return null

    return (
        <Col>
            <Nav
                variant="underline"
                role="tablist"
                style={{
                    fontSize: '0.9rem'
                }}
                className="mb-1"
            >
                <NavItem>
                    <NavLink
                        active={activeTab === 'directory'}
                        onClick={() => setActiveTab('directory')}
                    >
                        Directory
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={activeTab === 'invites'}
                        onClick={() => setActiveTab('invites')}
                    >
                        Invites
                    </NavLink>
                </NavItem>
                {canManageRequests &&
                    <NavItem>
                        <NavLink
                            active={activeTab === 'requests'}
                            onClick={() => setActiveTab('requests')}
                        >
                            <div>
                                Requests
                                {
                                    roomJoinRequestsController.pendingCount > 0 &&
                                    <Badge
                                        color='danger'
                                        className='ml-1'
                                        size={11}
                                    >
                                        {roomJoinRequestsController.pendingCount}
                                    </Badge>
                                }
                            </div>
                        </NavLink>
                    </NavItem>
                }
            </Nav>
            <TabContent>
                <TabPane
                    role="tabpanel"
                    visible={activeTab === 'directory'}
                >
                    {(activeTab === 'directory') &&
                        <RoomDirectorySearchSelect
                            room={room}
                            canManageRoom={canManageRoom}
                            className="no-bg search select-inline"
                            maxMenuHeight={'calc(100vh - 300px)' as unknown as number}
                            showAddMembersButton
                            onAddMembersClick={ openAddMembersToRoom }
                            showFilters
                            menuIsOpen
                            onEndReachedThreshold={90}
                        />
                    }
                </TabPane>
                <TabPane
                    role="tabpanel"
                    visible={activeTab === 'invites'}
                >
                    {(activeTab === 'invites') &&
                        <RoomInvitesSearchSelect
                            room={room}
                            canManageRoom={canManageRoom}
                            className="no-bg search"
                            maxMenuHeight={'calc(100vh - 200px)' as unknown as number}
                            showFilters
                            menuIsOpen
                        />
                    }
                </TabPane>
                <TabPane
                    role="tabpanel"
                    visible={activeTab === 'requests'}
                >
                    {(activeTab === 'requests') &&
                        <RoomRequests
                            maxMenuHeight="calc(100vh - 200px)"
                        />
                    }
                </TabPane>
            </TabContent>

            <Suspense fallback={<Spinner/>}>
                <AddRoomMembersModal
                    visible={showAddRoomMembersModal}
                    onClose={() => setShowAddRoomMembersModal(false)}
                />
            </Suspense>
        </Col>
    )
})

export { RoomDirectory }

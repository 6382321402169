import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
//evertel
import { APIDataCount, APIDataRoom } from '@evertel/types'
import { Api } from '@evertel/api'
import { debounce, uniqBy } from 'lodash'

/**
 * managed = rooms the current user manages.
 * member = rooms the current user is a member of.
 * public = public/crisis/incident-command rooms.
 * department = all rooms in the department
 */
type SearchScopeProps = 'managed' | 'member' | 'public' | 'department'
type SortOrder = 'asc' | 'desc'

interface RoomInterface extends APIDataRoom {
    totalMembers?: number
}

interface ManageRoomsFilterInterface {
    roomsStatus: string[]
    roomsType: string[]
    searchScope: SearchScopeProps
}

class ManageRoomsSearchController {
    currentUserId = 0
    departmentId = 0
    rooms: RoomInterface[] = []
    roomsLatestFetch: RoomInterface[] = []
    roomsCount = 0
    searchTerm = ''
    page = 1
    limit = 25
    sortBy = 'createdDate'
    sortOrder: SortOrder = 'desc'
    infiniteScroll = false
    loading = false

    roomsFilter: ManageRoomsFilterInterface = {
        roomsStatus: ['active'],
        roomsType: ['agency-wide'],
        searchScope: 'managed'
    }

    filterOutArchived = true
    askForArchived = true
    roomTypeFilter: { options?: any } = {}

    memberOrManagerOfRoomIds: number[] = []
    usersInRoomsCount = []

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init({
        currentUserId,
        departmentId,
        infiniteScroll = false
    }: {
        currentUserId: number
        departmentId: number
        infiniteScroll?: boolean
    }) {
        if (!currentUserId || (!departmentId && this.roomsFilter.searchScope !== 'public')) {
            console.log('ERROR: ManageRoomsSearchController init')
            return
        }

        this.currentUserId = currentUserId
        this.departmentId = departmentId
        this.infiniteScroll = infiniteScroll
        this.getCurrentUserRooms()
        this.setFilter()
        this.resetSearch()
    }

    setSearchTerm(value: string) {
        this.resetSearchMeta()
        this.searchTerm = value.trim()
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    setSort = (sortBy: string, sortOrder: SortOrder) => {
        this.sortBy = sortBy
        this.sortOrder = sortOrder
        this.resetSearchMeta()
    }

    setLimit = (limit: number) => {
        this.limit = limit || 25
        this.resetSearchMeta()
    }

    setRoomsFilter = (roomsFilter: ManageRoomsFilterInterface) => {
        this.roomsFilter = roomsFilter
        this.setFilter()
    }

    setFilter = () => {
        const { roomsStatus, roomsType } = this.roomsFilter

        const includeAgencyWide = roomsType.includes('agency-wide')
        const includeTeamRooms = roomsType.includes('team')

        //Agency-Wide only
        if (includeAgencyWide && !includeTeamRooms) this.roomTypeFilter.options = { like: '%"autoAddDepartmentMembers":true%' }

        //Team Rooms only
        if (includeTeamRooms && !includeAgencyWide) this.roomTypeFilter.options = { like: '%"autoAddDepartmentMembers":false%' }

        //Agency-Wide and Team Rooms
        if (roomsType.length > 1 && this.roomTypeFilter?.options) delete this.roomTypeFilter.options

        const includeArchived = toJS(roomsStatus).includes('archived')
        const includeActive = toJS(roomsStatus).includes('active')

        //get only archived
        if (includeArchived && !includeActive) {
            this.askForArchived = true
            this.filterOutArchived = false
        }

        //get only active
        if (!includeArchived && includeActive) {
            this.askForArchived = true
            this.filterOutArchived = true
        }

        //Get both
        if (includeArchived && includeActive) {
            this.askForArchived = false
        }

        this.resetSearchMeta()
    }

    private debouncedSearchInternal = debounce(async () => {
        try {
            await this.search()
        } catch (e) {
            /*  */
        }
        runInAction(() => {
            this.loading = false
        })
        return 
    }, 300)


    debouncedSearch = () => {
        this.loading = true
        return this.debouncedSearchInternal()
    }

    search = async () => {
        if (!this.currentUserId || (!this.departmentId && this.roomsFilter.searchScope !== 'public')) {
            console.log('ERROR: ManageRoomsSearchController Invalid Search Params')
            return []
        }
    
        let count = this.roomsCount
        let newRooms: RoomInterface[] = []
        let newRoomsTotalUsers = []
    
        // grab the total count of rooms for this search (once each new search)
        if (!this.roomsCount) {
            count = await this.fetchRoomsCount()
        }
    
        this.roomsLatestFetch = []
    
        // stop if we already fetched them all
        if (this.rooms.length < count) {
            try {
                const fetchData = async () => {
                    if (this.roomsFilter.searchScope === 'managed') {
                        // search rooms the current user manages (includes all agencies by default)
                        return Promise.all([
                            this.api.Routes.BlueUser.getRoomsManaged(this.currentUserId, this.filter),
                            this.api.Routes.BlueUser.getRoomsManagedUserCounts(this.currentUserId, this.filter).catch(() => {return []})
                        ])
                    } else if (this.roomsFilter.searchScope === 'department') {
                        // search all rooms in the current department
                        return Promise.all([
                            this.api.Routes.Department.getRooms(this.departmentId, this.filter),
                            this.api.Routes.Department.getRoomsUserCounts(this.departmentId, this.filter)
                        ])
                    }
                    return [[], []]
                }
    
                // Run both requests in parallel
                [newRooms, newRoomsTotalUsers] = await fetchData()    
    
                runInAction(() => {
                    this.roomsLatestFetch = newRooms
                    this.rooms = this.infiniteScroll ? uniqBy([...this.rooms, ...newRooms], 'id') : newRooms
                    this.usersInRoomsCount = uniqBy([...this.usersInRoomsCount, ...newRoomsTotalUsers], 'id') 
                })
            } catch (e) {
                if (!this.api.isProduction) {
                    console.log('ERROR: ManageRoomsSearchController search failed', e)
                }
                throw e
            }
        }
    
        // we return the new array as some components only need this.
        // however, you should usually reference 'this.rooms' observable
        return this.roomsLatestFetch
    }

    async fetchRoomsCount() {
        let count = 0

        try {
            if (this.roomsFilter.searchScope === 'managed') {
                count = (await this.api.Routes.BlueUser.getRoomsManagedCount(this.currentUserId, this.filter.where)).count
            } else if (this.roomsFilter.searchScope === 'department') {
                count = (await this.api.Routes.Department.getRoomsCount(this.departmentId, this.filter.where)).count
            }

        } catch (e) {
            if (!this.api.isProduction) {
                console.log('ERROR: ManageRoomsSearchController fetchRoomsCount()', e)
            }
            throw e
        }

        runInAction(() => {
            this.roomsCount = count ?? 0
        })

        return this.roomsCount
    }

    getCurrentUserRooms = async () => {
        try {
            const currentUserRooms = await this.api.Routes.BlueUser.getRooms(this.currentUserId, { departmentId: this.departmentId })

            runInAction(() => {
                this.memberOrManagerOfRoomIds = currentUserRooms.map(room => room.id)
            })

            return currentUserRooms

        } catch (e) {
            if (!this.api.isProduction) {
                console.log('ERROR: ManageRoomsSearchController getRoomsCurrentUserRooms()', e)
            }
            throw e
        }
    }

    resetSearchMeta(): void {
        this.page = 1
        this.rooms = []
        this.roomsCount = 0
    }

    resetSearch(): void {
        this.resetSearchMeta()
        this.searchTerm = ''
        this.limit = 25
        this.sortBy = 'createdDate'
        this.sortOrder = 'desc'
    }

    get filter() {
        return {
            where: {
                and: [
                    {
                        name: {
                            like: `%${this.searchTerm}%`
                        }
                    },
                    (this.askForArchived) ?
                        {
                            isArchived: !this.filterOutArchived
                        } : {},
                    toJS(this.roomTypeFilter) || {},
                    {
                        departmentId: this.departmentId
                    }
                ]
            },
            skip: (this.page - 1) * this.limit,
            limit: this.limit,
            order: (`${this.sortBy} ${this.sortOrder}`)
        }
    }
}

decorate(injectable(), ManageRoomsSearchController)
decorate(inject(Api), ManageRoomsSearchController, 0)

export { ManageRoomsSearchController, ManageRoomsFilterInterface }
import { DI_TYPES, WebNavigationStoreInterface } from '@evertel/types'
import { Container } from 'inversify'

//NavigationStore
const _navigationStore = {
    navigate(route) { return }
}

export function initNavigationStore(diContainer: Container) {
    diContainer.bind<WebNavigationStoreInterface>(DI_TYPES.LegacyNavigationStore).toConstantValue({
        navigate(route) { return _navigationStore.navigate(route) }
    })
}

export function setNavigationStore (navigationStore) {
    _navigationStore.navigate = navigationStore.navigate
}
import { makeAutoObservable, runInAction } from 'mobx'
import { debounce } from 'lodash'
import { injectable, inject, decorate } from '@evertel/di'
import { DocumentStore, DocumentACLStore } from '../store'
import { DocumentSchemaStore } from '@evertel/stores'
import { DepartmentStore } from '@evertel/department'
import { APIDataDocument, APIDataDepartment, APIDataDocumentSchema } from '@evertel/types'
import { Api } from '@evertel/api'


type DocumentSearchFilter = {
    state?: string,
    schemaId?: number,
    access?: string,
    text?: string
    by?: {
        type: 'department'|'room'|undefined,
        id?: number
    }
}
class DocumentSearchController {
    isLoading = false
    documentsCount = 0
    documentIds = []
    documentSchemaIds = []
    documentDepartmentIds = []
    documentOwnerPrincipals = {
        users: [],
        rooms: [],
        departments: []
    }
    isInfiniteScroll = true
    page = 1
    limit = 100
    //needs to be lowercase to work with bootstrapTable
    order = 'updatedDate desc'
    filter: DocumentSearchFilter = {
        by: null,
        text: '',
        state: 'published'
    }
    //this allows the page number to be restored when loading the search from a url, otherwise it gets reset
    keepPageOneCycle = false

    constructor(
        private documentStore: DocumentStore,
        private documentSchemaStore: DocumentSchemaStore,
        private departmentStore: DepartmentStore,
        private documentACLStore: DocumentACLStore,
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init({isInfiniteScroll = true}) {
        this.isInfiniteScroll = isInfiniteScroll
        this.resetSearchMeta()
    }

    setPage(pageNumber: number, keep = false) {
        this.keepPageOneCycle = keep

        if (!this.keepPageOneCycle) {
            this.page = pageNumber
        }
    }
    setFilter(filter) {
        this.resetSearchMeta()
        this.filter = filter
    }

    setOrder(newOrder) {
        this.resetSearchMeta()
        this.order = newOrder
    }

    setLimit(limit: number) {
        this.resetSearchMeta()
        this.limit = limit
    }

    resetSearchMeta() {
        this.documentsCount = 0
        this.documentIds = []
        this.setPage(1)
    }

    search = async () => {
        this.isLoading = true

        let count = this.documentsCount

        if (this.keepPageOneCycle) this.keepPageOneCycle = false

        // grab the total count of documents
        if (!this.documentsCount) {
            count = await this.fetchDocumentsCount()
        }

        const where = this.prepareSearchWhere()

        // stop from fetching if we already got them all
        if (count > this.documentIds?.length) {
            let documents: APIDataDocument[]

            const params = {
                where,
                order: this.order,
                limit: this.limit,
                skip: this.limit * (this.page - 1),
                include: ['media', 'documentSchema', 'ACL']
            }

            if (!this.filter.by?.id) {
                // no 'by' params then search all user's docs
                documents = await this.api.Routes.Document.getSearch(params)

            } else if (this.filter.by.type === 'department') {
                documents = await this.api.Routes.Document.getSearch(params)

            } else if (this.filter.by.type === 'room') {
                documents = await this.api.Routes.Room.getDocumentsSearch(this.filter.by.id, params)

            } else {
                throw new Error(`Invalid 'by' when searching documents`)
            }

            const documentIds = documents.map((d) => d.id)
            const ownerPrincipals = await this.fetchDocumentSearchOwnerACLPrincipals(documentIds)
            const acls = documents.map(d => d.ACL)?.flat(1)

            // save the resulting docs in the documentStore
            runInAction(() => {
                this.documentStore.update(documents)
                this.documentACLStore.update(acls)
            })

            // TODO: save the principal Users / Rooms / Departments in respective stores.

            // fetch the available schemas
            const documentSchemas = await this.fetchDocumentSchemas()

            // update observables
            runInAction(() => {
                this.documentsCount = count
                this.documentIds = (this.isInfiniteScroll) ? [...this.documentIds, ...documentIds] : documentIds
                this.documentSchemaIds = documentSchemas.map((ds) => ds.id)
                this.documentOwnerPrincipals = ownerPrincipals
            })
        }
        this.isLoading = false
    }

    async fetchDocumentsCount() {
        const where = this.prepareSearchWhere()

        if (!this.filter?.by?.id) {
            const result = await this.api.Routes.Document.getSearchCount(where)
            return result.count

        } else if (this.filter?.by?.type === 'department') {
            const result = await this.api.Routes.Document.getSearchCount(where)
            return result.count

        } else if (this.filter?.by?.type === 'room') {
            const result = await this.api.Routes.Room.getDocumentsSearchCount(this.filter.by.id, where)
            return result.count
            
        } else {
            throw new Error(`Invalid 'by' when searching documents`)
        }
    }

    async fetchDocumentSearchOwnerACLPrincipals(documentIds: number[]) {
        try {
            const principals = await this.api.Routes.Document.getSearchAclPrincipals({
                where: {
                    documentId: { inq: documentIds },
                    accessType: 'OWNER'
                },
                order: 'createdDate DESC'
            }, {
                fields: ['id', 'firstName', 'lastName', 'email', 'publicImage']
            })
            return principals

        } catch (error) {
            console.log(error)
            return null
        }
    }

    public async fetchDocumentSchemas(): Promise<APIDataDocumentSchema[]> {
        const documentSchemas = await this.api.Routes.Document.getSearchDocumentSchema({
            include: [{'relation': 'department'}]
        })

        runInAction(() => {
            this.documentSchemaStore.update(documentSchemas)

            // add departments to the departmentStore
            this.departmentStore.update(
                documentSchemas.map(({department}) => {
                    return department as APIDataDepartment
                })
            )
        })

        return documentSchemas
    }

    public async fetchDocumentDepartments(): Promise<void> {
        const departments = await this.api.Routes.Document.getSearchDepartment()

        await this.departmentStore.update(departments)

        runInAction(() => {
            this.documentDepartmentIds = departments.map((d) => d.id)
        })
    }

    protected prepareSearchWhere() {
        type Where = {
            departmentId?: number,
            documentSchemaId?: number,
            accessType?: string,
            searchTerm?: string
            isArchived?: boolean
        }

        let where: Where = {
            searchTerm: this.filter.text
        }

        if (this.filter.by?.type === 'department') {
            where = {
                ...where,
                departmentId: this.filter.by.id
            }
        }

        switch (this.filter.state) {
            case 'published':
                where = {
                    ...where,
                    isArchived: false
                }

                break
            case 'archived':
                where = {
                    ...where,
                    isArchived: true
                }

                break
            default:
                throw new Error(`Unknown state: ${this.filter.state} (typeof ${typeof this.filter.state})`)
        }

        if (this.filter.schemaId) {
            where = {
                ...where,
                documentSchemaId: this.filter.schemaId
            }
        }

        if (this.filter.access) {
            where = {
                ...where,
                accessType: this.filter.access
            }
        }

        return where
    }

    get documents() {
        const docs = this.documentStore.findByIds(this.documentIds)
        return docs.map(d => ({
            ...d,
            acl: this.documentACLStore.objectsArray.filter(a => a.documentId === d.id)
        }))
    }

    get documentSchemas() {
        return this.documentSchemaStore.findByIds(this.documentSchemaIds)
    }

    get documentDepartments() {
        return this.departmentStore.findByIds(this.documentDepartmentIds)
    }
}

decorate(injectable(), DocumentSearchController)
decorate(inject(DocumentStore), DocumentSearchController, 0)
decorate(inject(DocumentSchemaStore), DocumentSearchController, 1)
decorate(inject(DepartmentStore), DocumentSearchController, 2)
decorate(inject(DocumentACLStore), DocumentSearchController, 3)
decorate(inject(Api), DocumentSearchController, 4)

export { DocumentSearchController, DocumentSearchFilter }

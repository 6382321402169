import React, { useState, useCallback } from 'react'
import { Modal, ModalBody, Row, Col, Textarea, InfoBox, useUI, Button, ModalHeader } from '@evertel/web/ui'
import { observer } from 'mobx-react-lite'
import { useService } from '@evertel/di'
import { HeaderController } from '../controller'

// Types
type ActionType = 'ask-question' | 'report-bug' | 'make-suggestion' | 'submit-feedback'

interface HelpModalProps {
    isOpen: boolean
    action: ActionType
    onClose: () => void
}

// Constants
const ACTION_TITLES: Record<ActionType, string> = {
    'ask-question': 'Ask a question',
    'report-bug': 'Report a Problem',
    'make-suggestion': 'Make a Suggestion',
    'submit-feedback': 'Submit Feedback'
}

// Component
const HelpModal: React.FC<HelpModalProps> = observer(({
    isOpen,
    action = 'submit-feedback',
    onClose
}) => {
    // Hooks
    const { addToast } = useUI()
    const headerController = useService(HeaderController, [])

    // State
    const [message, setMessage] = useState('')
    const [sending, setSending] = useState(false)
    const [error, setError] = useState('')
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)

    // Handlers
    const handleSubmit = useCallback(async () => {
        if (sending || !message.trim()) {
            setError('Please add your feedback')
            return
        }

        setError('')
        setSending(true)

        try {
            const userInfo = await headerController.getDeviceInfo()
            const formattedMessage = `${ACTION_TITLES[action]}: ${message}`

            await headerController.sendFeedback(formattedMessage, userInfo)

            setMessage('')
            setShowSuccessMessage(true)
            
            // Set a timer to hide the success message after 2 seconds
            setTimeout(() => {
                setShowSuccessMessage(false)
                onClose() // Optionally close the modal after showing the success message
            }, 2000)

        } catch (error: unknown) {
            addToast({
                color: 'danger',
                message: error instanceof Error ? error.message : 'An unknown error occurred'
            })
        } finally {
            setSending(false)
        }
    }, [action, addToast, headerController, message, sending, onClose])

    // Render helpers
    const renderFeedbackSent = () => (
        <Row>
            <Col className="text-center pt-2">
                <h3 className="text-success mt-2 fadeIn animated">
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                    Your feedback has been sent!
                </h3>
            </Col>
        </Row>
    )

    const renderFeedbackForm = () => (
        <>
            <Row>
                <Col>
                    {error && <InfoBox color="danger">{error}</InfoBox>}
                    <Textarea
                        value={message}
                        rows={5}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Type your feedback here..."
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-center pt-2">
                    <Button
                        color="success"
                        className="weight-900"
                        loading={sending}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </>
    )

    // Main render
    return (
        <Modal
            visible={isOpen}
            onClose={onClose}
            size="lg"
        >
            <ModalHeader className='text-center'>
                <h1 className='flex-grow-1'>{ACTION_TITLES[action]}</h1>
            </ModalHeader>
            <ModalBody className="py-4 px-5 mx-5 mb-4">
                {/* TITLE */}
                <Row className="mb-5">
                    <Col className="text-center">
                        <h3>Fill out the form below and we'll get back to you with any follow up shortly!</h3>
                    </Col>
                </Row>
                {showSuccessMessage ? renderFeedbackSent() : renderFeedbackForm()}
            </ModalBody>
        </Modal>
    )
})

export { HelpModal }
import React from 'react'
import { observer } from 'mobx-react'
import { Col, Icon, Row } from '@evertel/web/ui'

interface HeaderAlertBarProps {
    isOpen: boolean,
    color: string,
    onClick: () => void,
    children: React.ReactNode,
}

const HeaderAlert: React.FC<HeaderAlertBarProps> = observer(({
    isOpen,
    color,
    onClick,
    children
}) => {

    if (isOpen) {
        return (
            <Row className={'animated fadeIn p-3 alert-bar ' + color}>
                <Col xs={11} className='text-center'>
                    <strong>
                        {children}
                    </strong>
                </Col>
                <Col>
                    <Icon name="times" className="float-right text-dark cursor-pointer" onClick={onClick} />
                </Col>
            </Row>
        )
    } else {
        return <></>
    }
})

export { HeaderAlert }
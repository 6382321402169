import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation, useParams } from 'react-router-dom'
import { toJS } from 'mobx'
// evertel
import { PageBody, Text, useUI, ContentPlaceholder, Nav, NavItem, NavLink, TabContent, TabPane, Icon, InfoBox } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { SettingsBar } from './elements/SettingsBar'
import { SchemaBuilderController } from '../../controller/schema-builder'
import { SchemaBuilderFull } from './SchemaBuilderFull'
import { SchemaBuilderPreview } from './SchemaBuilderPreview'
import { SchemaBuilderSettings } from './SchemaBuilderSettings'
import { normalize } from '@evertel/schema-parser'
import { SchemaMediaController } from '../../controller'
import { truncate } from '@evertel/utils'
import { SessionState } from '@evertel/session'


interface SchemaBuilderProps {
    props?: any
}

const SchemaBuilder: React.FC<SchemaBuilderProps> = observer(() => {

    const controller = useService(SchemaBuilderController, [])
    const mediaController = useService(SchemaMediaController, [])
    const session = useService(SessionState, [])
    
    const { toggleLeftNav } = useUI()
    const location = useLocation()
    const { id, target } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [activeTab, setActiveTab] = useState<'full'|'preview'|'settings'>('full')

    let schema = {}
    const schemaId = Number(id)
    const departmentId = (location.state?.isAdmin || target === 'global') ? 0 : session.selectedDepartmentId

    useEffect(() => {
        (async () => {
            toggleLeftNav('close')

            setIsLoading(true)
            await controller.init(
                schemaId,
                departmentId
            )
            await mediaController.init(
                schemaId
            )
            setIsLoading(false)
        })()
    }, [])

    if (controller.schemaKey === 'root') {
        schema = (controller.schema?.layouts) ? normalize(controller.schema) : {}
    } else {
        schema = normalize(controller.schema.internalSchemas[controller.schemaKey])
    }

    // console.log('ID', schemaId)
    // console.log('NORMALIZED', toJS(schema))
    // console.log('RAW', toJS(controller.schema))

    const internalSchemaKeys = (controller.schema?.internalSchemas) ? Object.keys(controller.schema.internalSchemas) : []

    return (
        <>
            <SettingsBar
                controller={controller}
            />
            <PageBody>
                <Nav
                    variant="tabs"
                    role="tablist"
                    className="justify-content-center">
                    <NavItem style={{display: 'flex'}} className="d-flex">
                        <NavLink
                            className="text-nowrap"
                            active={activeTab === 'full'}
                            onClick={() => {setActiveTab('full'); controller.setSchemaKey('root')}}
                        >
                            <Icon
                                name="table"
                            />
                            <Text
                                className="ml-2 px-2 py-1"
                                color={(controller.schemaKey === 'root') ? 'secondary' : 'muted'}
                                bold={controller.schemaKey === 'root'}>
                                Form Builder
                            </Text>
                        </NavLink>
                        {internalSchemaKeys?.map((sKey, idx) => {
                            const row = controller.schema.layouts.full.find((r: Record<string, any>) =>
                                r.type === 'row'
                                    && (
                                        (r.items && r.items[0]?.type === 'list' && r.items[0]?.item?.type === `&${sKey}`)
                                        || (r.items && r.items[0]?.type === `&${sKey}`)
                                    )
                            )
                            const list = row?.items?.find((i: Record<string, any>) => i.type === `&${sKey}` || i.item?.type === `&${sKey}`)

                            return (
                                <NavLink
                                    key={idx}
                                    active={activeTab === 'full'}
                                    style={{marginLeft: -10}}
                                    onClick={() => {setActiveTab('full'); controller.setSchemaKey(sKey)}}
                                    className="px-2 text-nowrap"
                                >
                                    <Icon
                                        name={(idx === 0) ? 'chevron-right' : 'pipe'}
                                        color="muted"
                                        size={10}
                                    />
                                    <Text
                                        key={idx}
                                        className="px-2 py-1 text-capitalize"
                                        color={(controller.schemaKey === sKey) ? 'secondary' : 'muted'}
                                        bold={controller.schemaKey === sKey}>
                                        {truncate(list?.title || sKey, 15)}
                                    </Text>
                                </NavLink>
                            )
                        })}
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={activeTab === 'preview'}
                            onClick={() => setActiveTab('preview')}>
                            <Icon
                                name="eye"
                                className="mr-2"
                            />
                            <Text>Preview</Text>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={activeTab === 'settings'}
                            onClick={() => setActiveTab('settings')}>
                            <Icon
                                name="gear"
                                className="mr-2"
                            />
                            <Text>Settings</Text>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent>
                    <TabPane
                        role="tabpanel"
                        visible={activeTab === 'full'}>
                        {(!departmentId) &&
                            <InfoBox color="warning">
                                Global Template
                            </InfoBox>
                        }
                        {(isLoading) ?
                            <ContentPlaceholder/>
                            :    
                            <SchemaBuilderFull
                                schemaId={schemaId}
                                departmentId={departmentId}
                                controller={controller}
                                mediaController={mediaController}
                                schema={schema}
                            />
                        }
                    </TabPane>
                    <TabPane
                        role="tabpanel"
                        visible={activeTab === 'preview'}>
                        {(activeTab === 'preview') &&
                            <SchemaBuilderPreview
                                controller={controller}
                            />
                        }
                    </TabPane>
                    <TabPane
                        role="tabpanel"
                        visible={activeTab === 'settings'}>
                        {(activeTab === 'settings') &&
                            <SchemaBuilderSettings
                                controller={controller}
                                departmentId={departmentId}
                            />
                        }
                    </TabPane>
                </TabContent>
            </PageBody>
        </>
    )
})

export {
    SchemaBuilder
}
import React from 'react'
import { View, Line } from '@react-pdf/renderer'

const SpacerWidget = ({
    border,
    height = 30
}) => {

    if (typeof height === 'string') {
        height = (isNaN(parseInt(height, 10))) ? 30 : parseInt(height, 10)
    }
    
    return (
        <View style={{
            height
        }}> 
            {(border) && <Line/>}
        </View>
    )
}

export { SpacerWidget }
SpacerWidget.displayName = 'Spacer (PDF)'

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Text, Image, View } from '@react-pdf/renderer'
import { DepartmentController } from '@evertel/department'
import { useService } from '@evertel/di'


const DepartmentHeader = observer(({
    departmentId
}: { departmentId: number}) => {

    const deptController = useService(DepartmentController, [])

    useEffect(() => {
        (async () => {
            await deptController.init(
                departmentId
            )
        })()
    }, [departmentId])


    return (
        // @ts-ignore
        <View style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
            padding: 10,
            marginBottom: 20
        }}>
            {/* <Image
                src={'https://eadn-wc01-5964675.nxedge.io/wp-content/uploads/2022/09/RandomSuccessatInnovation-SocialGraphic01-760x394.jpg'}
                style={{
                    width: 200,
                    height: 200,
                    objectFit: 'contain'
                }}
            /> */}
            {/* @ts-ignore */}
            <Text style={{
                fontSize: 16,
                fontWeight: 'bold',
                color: 'white'
            }}>
                {deptController.department.name}
            </Text>
        </View>
    )
})

export default DepartmentHeader
import { makeAutoObservable, runInAction } from 'mobx'
import moment from 'moment'
import { matchPath } from 'react-router-dom'
import { Location } from 'history'
import { decorate, injectable, inject } from '@evertel/di'
import { Api } from '@evertel/api'

//TODO: move this to session service
class SessionState {
    currentUserId = 0 //if currentUserId === 0 we're logged out
    selectedDepartmentId = 0
    navigationHistory = {}
    platform: 'web' | 'mobile' = 'web'
    lastActivity = moment()
    roomIdsIBelongTo = []
    private _recentDepartmentIds: number[] = []

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = (platform: 'web' | 'mobile') => {
        this.platform = platform
        this.lastActivity = moment()

        // repopulate navigationHistory from local storage
        if (platform === 'web') {
            //restore userID
            const storedUserId = localStorage.getItem('userId') || '0'
            this.currentUserId = parseInt(storedUserId)

            //restore accessToken
            const accessToken = localStorage.getItem('authorization') || undefined
            this.api.setAuthToken(accessToken)

            //restore navigationHistory
            const navigationHistory = localStorage.getItem('navigationHistory') || '{}'
            this.navigationHistory = JSON.parse(navigationHistory)

            //restore departmentID (note: this gets reset on login)
            const selectedDepartmentId = localStorage.getItem('selectedDepartmentId') || '0'
            this.selectedDepartmentId = parseInt(selectedDepartmentId)
            this._recentDepartmentIds.unshift(this.selectedDepartmentId)

        } else {
            // mobile get item from storage here
        }
    }

    addRoomIdIBelongTo = (roomId: number) => {
        if (!this.roomIdsIBelongTo.find(item => item === roomId))
            this.roomIdsIBelongTo.push(roomId)
    }

    setCurrentUserId = (userId: number) => {
        this.currentUserId = userId

        if (this.platform === 'web') {
            localStorage.setItem('userId', userId.toString() )
        }
    }

    setSelectedDeparmentId = (departmentId: number) => {
        this.selectedDepartmentId = departmentId
        this._recentDepartmentIds.unshift(this.selectedDepartmentId)

        if (this.platform === 'web') {
            localStorage.setItem('selectedDepartmentId', departmentId?.toString())
        }
    }

    updateNavigationHistory = (location: Location) => {
        // we store navigation history for each department the user belongs to
        const navHistory = this.navigationHistory

        if (!navHistory[this.selectedDepartmentId]) {
            navHistory[this.selectedDepartmentId] = []
        }

        //remove same path from history to prevent duplicates
        navHistory[this.selectedDepartmentId] = this.deletePathFromArray(navHistory[this.selectedDepartmentId], location.pathname)

        // add latest to front of array
        navHistory[this.selectedDepartmentId].unshift(location)

        // only keep the last 10 routes for each department in navigationHistory
        if (navHistory[this.selectedDepartmentId].length > 10) navHistory[this.selectedDepartmentId].pop()

        this.navigationHistory = navHistory


        if (this.platform === 'web') {
            localStorage.setItem('navigationHistory', JSON.stringify(this.navigationHistory))
        } else {
            // mobile storage method here
        }
    }

    deleteRoomfromNavigationHistory = (roomId: number) => {
        // useful when leaving a room
        //need deletePrefix true so replies, /room/:id/reply/:replyId in history also get cleared
        this.deleteLocationPathFromNavigationHistory(`/room/${roomId}`, true)
    }
      
    deleteThreadFromNavigationHistory = (threadId: number) => {
        // useful when closing a thread
        this.deleteLocationPathFromNavigationHistory(`/thread/${threadId}`)
    }

    deleteLocationPathFromNavigationHistoryo = (path: string, deletePrefix = false) => {
        this.navigationHistory[this.selectedDepartmentId] = this.navigationHistory[ this.selectedDepartmentId ]?.filter(
            (h) => {
                if (deletePrefix) {
                    return h.pathname.includes(path)
                } else {
                    return h.pathname !== path
                }

            }
        )
    }
    deleteLocationPathFromNavigationHistory = (path: string, deletePrefix = false) => {
        const departmentHistory = this.navigationHistory[this.selectedDepartmentId]
        if (departmentHistory) {
            this.navigationHistory[this.selectedDepartmentId] = this.deletePathFromArray( departmentHistory, path, deletePrefix )
        }
    }
    
    private deletePathFromArray = (arr: any[], path: string, deletePrefix = false) => {
        return arr.filter((item) => {
            if (deletePrefix) {
                return !item.pathname.startsWith(path)
            } else {
                return item.pathname !== path
            }
        })
    }

    destroy = () => {
        this.setCurrentUserId(0)
        this.setSelectedDeparmentId(0)
        //should this clear it from localStorage too?
        this.navigationHistory = {}
    }

    get currentPathname() {
        // returns current path within the selected department
        const deptHistory = this.navigationHistory[this.selectedDepartmentId]
        return (deptHistory) ? deptHistory[0]?.pathname : undefined
    }

    get previousPath() {
        // returns last path within the selected department
        if (!this.selectedDepartmentId || !this.navigationHistory[this.selectedDepartmentId]) return undefined

        const prev = this.navigationHistory[this.selectedDepartmentId][0]
        if (prev && prev.pathname !== '/login') {
            return `${prev?.pathname}${prev?.hash}${prev?.search}`
        }

        return undefined
    }

    get previousRoomOrThread() {
        // returns last room or thread path and id navigated to within the selected department
        if (!this.selectedDepartmentId) return undefined

        const history = this.navigationHistory[this.selectedDepartmentId]
        if (!history || history.length === 0) return undefined

        const lastLocation = history.find(
            (p) => p.pathname.startsWith('/thread/') || p.pathname.startsWith('/room/')
        )

        if (lastLocation) {
            const { pathname } = lastLocation
            const id = parseInt(pathname.split('/').pop())
            const type = pathname.startsWith('/thread/') ? 'thread' : 'room'

            return {
                path: pathname,
                id,
                type
            }
        }

        return undefined
    }

    get recentDepartmentIds() {
        //remove duplicates and return top 3
        return [...new Set(this._recentDepartmentIds)].slice(0, 3)
    }
}

decorate(injectable(), SessionState)
decorate(inject(Api), SessionState, 0)

export { SessionState }
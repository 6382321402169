
import { makeAutoObservable } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { SessionState } from '@evertel/session'
import { Api } from '@evertel/api'

class DrawerController {

    constructor(
        private api: Api,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    init = async () => {
        //
    }

    get activeSection() {
        if (this.session.currentPathname?.includes('/everdocs')) return 'everdocs'
        if (this.session.currentPathname?.includes('/account')) return 'account'
        if (this.session.currentPathname?.includes('/manage')) return 'manage'
        if (this.session.currentPathname?.includes('/admin')) return 'admin'

        if (this.session.currentPathname?.includes('/room')
            || this.session.currentPathname?.includes('/thread')
            || this.session.currentPathname?.includes('/landing')) return 'messages'


        return 'messages'
    }
}

decorate(injectable(), DrawerController)
decorate(inject(Api), DrawerController, 0)
decorate(inject(SessionState), DrawerController, 1)

export { DrawerController }

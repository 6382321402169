import React, { useState, useCallback } from 'react'
import { useDidUpdate } from '@mantine/hooks'
import Delegator from './Delegator'
import { deal } from './utils/Deal'

interface FormProps {
    id: number|string,
    value: any,
    errors?: Record<string, any>,
    onChange: (id: string|number, value: any) => void,
    items: Record<string, any>[],
    documentId?: number,
    schemaId?: number
}

const Form: React.FC<FormProps> = ({
    id,
    value: initialValue,
    errors,
    onChange,
    items,
    ...otherProps
}) => {
    // Need to handle value before sending it back using onChange, because of the merging
    // For example, if two widgets are setting initial data with their onChange, there will be two merging here
    // and the second should have the updated value from first merging
    const [value, setValue] = useState(initialValue)

    useDidUpdate(() => {
        if (!onChange) {
            return
        }
        onChange(id, value)
    }, [value])

    const handleChange = useCallback((itemId, itemValue) => {
        setValue((prev: any) => {
            return {
                ...prev,
                [itemId]: itemValue
            }
        })
    }, [])

    return (
        <>
            {items?.map(({id: itemId, ...itemProps}, i) => {
                const itemValue = deal(value, itemId)
                const itemErrors = (errors) && deal(errors, itemId)

                return (
                    <Delegator
                        key={i}
                        id={itemId}
                        value={itemValue}
                        errors={itemErrors}
                        onChange={handleChange}
                        {...itemProps}
                        {...otherProps}
                    />
                )
            })}
        </>
    )
}

export default Form

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
// evertel
import { Modal, ModalBody, ModalHeader, Row, Col, Button, Text, useUI, Icon } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { UserController } from '@evertel/blue-user'
import { useWindowDimensions } from '@evertel/web/hooks'
import { DepartmentsAccessController } from '@evertel/departments-access'
import { DepartmentController } from '@evertel/department'
import { CreateThreadController } from '@evertel/thread'
import { DrawerController } from '@evertel/message'
import { getNonGuestPrimaryDepartmentsAccess } from '@evertel/utils'

interface UserDetailModalProps {
    visible: boolean,
    userId: number,
    onClose: () => void,
    onCloseDone?: () => void
}

const UserDetailModal: React.FC<UserDetailModalProps> = observer(({
    visible,
    userId,
    onClose,
    onCloseDone
}) => {

    const history = useHistory()
    const { addToast } = useUI()
    const { height: windowHeight } = useWindowDimensions()
    const session = useService(SessionState, [])
    const userController = useService(UserController, [])
    const departmentsAccessController = useService(DepartmentsAccessController, [])
    const departmentController = useService(DepartmentController, [])
    const createThreadController = useService(CreateThreadController, [])
    const drawerController = useService(DrawerController, [])

    const [isLoading, setIsLoading] = useState(false)
    const [isStartingThread, setIsStartingThread] = useState(false)

    useEffect(() => {
        (async () => {
            if (!userId) return

            setIsLoading(true)

            await Promise.all([
                userController.init(userId),
                departmentsAccessController.init(userId)
            ])
                .then(async () => {
                    const primaryDepartment = getNonGuestPrimaryDepartmentsAccess(departmentsAccessController.departmentsAccess)

                    if (primaryDepartment) {
                        await departmentController.init(primaryDepartment?.departmentId)
                    }
                })

            setIsLoading(false)
        })()
    }, [userId])


    const startThread = async () => {
        if (!userId) return

        // start the thread
        try {
            setIsStartingThread(true)
            const thread = await createThreadController.createThread(userId)
            await drawerController.fetchActiveThreads()
            setIsStartingThread(false)

            onClose()

            // navigate to the new thread
            history.push(`/thread/${thread.id}`)

        } catch (error: any) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }
    }

    const editProfile = () => {
        onClose()
        history.push(`/account/profile/${userId}`)
    }

    const user = userController.user || {}
    const departmentsAccess = getNonGuestPrimaryDepartmentsAccess(departmentsAccessController.departmentsAccess)
    const department = departmentController?.department
    const isMe = (user?.id === session.currentUserId)

    const departmentName = department?.id === departmentsAccess?.departmentId ? department?.name : 'Guest'
    const userPosition = departmentsAccess ? departmentsAccess?.positionString || 'No position set' : ''

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCloseDone={onCloseDone}>
            <ModalHeader />
            <ModalBody className="p-0 pt-3">
                {/* IMAGE */}
                <Row className="bg-primary">
                    {(user?.publicImage || user?.publicMedia) &&
                        <img
                            src={(user.publicMedia) ? user.publicMedia.url + '?ver=500sqr' : user.publicImage}
                            alt={''}
                            width="100%"
                            style={{
                                maxHeight: windowHeight * 0.5,
                                objectFit: 'cover'
                            }}
                        />
                    }
                    {(!user?.publicImage && !user.publicMedia) &&
                        <img
                            src="/assets/img/avatars/user_photo_default.jpg"
                            alt={''}
                            width="100%"
                            style={{
                                maxHeight: windowHeight * 0.35,
                                objectFit: 'cover'
                            }}
                        />
                    }
                </Row>
                {/* NAME */}
                <Row className="p-3">
                    <Col className="pt-3">
                        <Row>
                            <Text tag="h1">
                                {user?.firstName + ' ' + user?.lastName}
                            </Text>
                            {(user?.isAdmin) &&
                                <Icon
                                    name="star"
                                    color="warning"
                                    type="solid"
                                    className="ml-1"
                                />
                            }
                        </Row>
                        <span>
                            <Text
                                tag="h3"
                                color="muted"
                                bold>
                                {departmentName || 'Guest'}
                            </Text>
                            <Text
                                tag="h5"
                                color="muted">
                                {userPosition}
                            </Text>
                        </span>
                    </Col>
                </Row>
                {/* BTTNS */}
                <Row className="p-3 mb-3">
                    {(!isMe) &&
                        <>
                            <Button
                                onClick={startThread}
                                loading={isStartingThread}
                                className="mr-2">
                                DM {user?.firstName}
                            </Button>
                            {(user?.phoneNumber) &&
                                <Button
                                    outline
                                    className="mr-2"
                                    onClick={() => window.open(`tel:${user.phoneNumber}`)}
                                    data-tooltip={user?.phoneNumber}
                                    data-tooltip-pos="top">
                                    Call {user?.firstName}
                                </Button>
                            }
                        </>
                    }
                    {(isMe) &&
                        <Button
                            outline
                            onClick={editProfile}>
                            Edit Profile
                        </Button>
                    }
                </Row>
            </ModalBody>
        </Modal>
    )
})

export { UserDetailModal }

import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
// evertel
import { Reaction as ReactionButton } from '.'
import EmojiButton from './EmojiButton'
import { Button } from '.'

const propTypes = {
    reactions: PropTypes.array,
    onSelectReaction: PropTypes.func,
    onShowPicker: PropTypes.func,
    onShowReactedBy: PropTypes.func,
    showPickerButton: PropTypes.bool,
    showClickAnimation: PropTypes.bool,
    emojiButtonId: PropTypes.string.isRequired,
    showCountLink: PropTypes.bool
}

const defaultProps = {
    reactions: [],
    showPickerButton: true,
    showClickAnimation: true,
    showCountLink: true
}

const ReactionBar = observer(({
    reactions,
    onSelectReaction,
    onShowPicker,
    onShowReactedBy,
    showPickerButton,
    showClickAnimation,
    showCountLink,
    emojiButtonId,
    style
}) => {

    return (
        <div style={style} className="reaction-bar">
            {reactions.slice().sort((a, b) => a.id - b.id).map(item =>
                <ReactionButton
                    key={item.id}
                    reaction={item}
                    onSelected={onSelectReaction}
                    showClickAnimation={showClickAnimation}
                />
            )}
            {showPickerButton &&
                <EmojiButton
                    id={emojiButtonId}
                    onClick={onShowPicker}
                    iconColor="muted"
                />
            }
            {showCountLink &&
                <Button
                    color="link"
                    size="sm"
                    onClick={onShowReactedBy}
                    className="ml-2 p-0">
                    <small>{reactions.reduce((sum, r) => sum + r.count, 0)} reactions</small>
                </Button>
            }
        </div>
    )
})

ReactionBar.propTypes = propTypes
ReactionBar.defaultProps = defaultProps
export default ReactionBar

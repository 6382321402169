import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'
import Steps from './components/Steps'
import {  Toaster, LoadingIndicator } from '../../../components'
import { InfoBox, Card, CardBody, Col, Icon, Button, Container, Input, InputGroup, InputGroupText, Row } from '@evertel/web/ui'
import { RegistrationStore, AnalyticsStore, CurrentUserStore, DepartmentsStore, AppStore, NavigationStore } from '../../../stores'


const RegisterProfile = observer(() => {

    const [state, setState] = useState({
        isReady: false,
        error: false,
        isLoading: false,
        department: {},
        position: null,
        assignment: null,
        badgeNumber: null
    })

    useEffect(() => {
        // listener to run each time appLoading changes
        // this ensures we grab the data AFTER the app
        // inits should we refresh the page
        autorun((init) => {
            if (!AppStore.appLoading) {
                if (AppStore.hasValidAuthToken) {
                    loadOptions()
                    AnalyticsStore.logPageView('/register/profile')
                } else {
                    NavigationStore.navigate('/register')
                }

                init.dispose()
            }
        })
    }, [])

    const loadOptions = async () => {
        const department = await DepartmentsStore.getDepartmentById(AppStore.selectedDepartmentId)

        setState((prevState) => ({
            ...prevState,
            department
        }))
    }

    const onChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const saveDepartmentProfile = async () => {
        const { position, assignment, badgeNumber, department } = state

        let badgeNumberTrim = ''
        let positionTrim = ''
        let assignmentTrim = ''
        if (badgeNumber) {
            badgeNumberTrim = badgeNumber.trim()
        }
        if (position) {
            positionTrim = position.trim()
        }
        if (assignment) {
            assignmentTrim = assignment.trim()
        }

        // validate
        if (!position || !positionTrim.length || !assignment || !assignmentTrim.length || !badgeNumber || !badgeNumberTrim.length) {
            setState((prevState) => ({
                ...prevState,
                error: 'Please fill out all fields'
            }))
            return
        }

        if (positionTrim.length > 100  || assignmentTrim.length > 100 || badgeNumberTrim.length > 100) {
            setState((prevState) => ({
                ...prevState,
                error: 'Input too long'
            }))
            return
        }


        LoadingIndicator.show('page-loading')
        setState((prevState) => ({ 
            ...prevState,
            isLoading: true 
        }))

        // get user's departmentsAccess
        const da = CurrentUserStore.departmentsAccess.find(d => d.departmentId === department.id)

        // should never happen
        if (!da) {
            Toaster.show('error', 'No agency found')
            return
        }

        // update departmentsAccess
        await da.updateUserDetails({
            position: position,
            assignment: assignment,
            badgeNumber: badgeNumberTrim,
            isPrimary: true
        })

        LoadingIndicator.hide('page-loading')
        setState((prevState) => ({ 
            ...prevState,
            isLoading: false 
        }))

        // error check
        const error = da.error
        if (error) {
            Toaster.show('error', error.message || error)
            return
        }

        RegistrationStore.updateRegistrationSteps({...RegistrationStore.registrationSteps, department: 'complete', profile: 'complete'})
        RegistrationStore.goToNextStep()
    }

    const { error, isLoading, position, assignment, badgeNumber } = state

    return (
        <div className="app d-flex justify-content-center align-items-center fadeIn animated registration" style={{ height: '100vh'}}>
            <Row className="justify-content-center">
                <Col md="8">
                    <Steps currentStep={3} totalSteps={3} />
                    <Card className="mx-4">
                        <CardBody className="p-4">
                            <div className="text-center pb-4">
                                <h1>Great! Almost done...</h1>
                                <h3 className="text-muted">Answer the questions below so we can give you the best experience possible</h3>

                                {error && <InfoBox color="danger" className="mt-4">{error}</InfoBox>}
                            </div>
                            <InputGroup className="mb-3">
                                <InputGroupText>
                                    <Icon name="id-badge"/>
                                </InputGroupText>
                                <Input type="text" placeholder="Badge/ID Number" name="badgeNumber" value={badgeNumber} required onChange={onChange} />
                                <div className="invalid-feedback" />
                            </InputGroup>
                            <InputGroup className="mb-4">
                                <Input type="text" placeholder="Rank/Position" name="position" value={position} required onChange={onChange}/>
                                <div className="invalid-feedback" />
                            </InputGroup>
                            <InputGroup className="mb-4">
                                <Input type="text" placeholder="Assignment" name="assignment" value={assignment} required onChange={onChange}/>
                                <div className="invalid-feedback" />
                            </InputGroup>

                            <Button
                                className="my-3 w-100"
                                color="success"
                                loading={isLoading}
                                block
                                onClick={saveDepartmentProfile}>
                                        Save Profile
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
})

export default RegisterProfile

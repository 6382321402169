import React, { forwardRef, HTMLAttributes, ReactElement, useEffect } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Text, Col } from '@evertel/web/ui'
import { UserController } from '@evertel/blue-user'
//import ProfilePic from './ProfilePic'
import { useService } from '@evertel/di'

interface DeptTileProps extends HTMLAttributes<HTMLDivElement> {
    departmentId: number,
    imageWidth?: number,
    subComponent?: ReactElement,
    className?: string
}

const DepartmentTile = observer(forwardRef<HTMLDivElement, DeptTileProps>(({
    departmentId,
    imageWidth = 30,
    subComponent,
    className,
    ...otherProps
}, ref) => {

    const controller = useService(UserController, [])

    useEffect(() => {
        (async () => {
            await controller.init(
                departmentId
            )
        })()
    })

    const _className = classNames(
        'user-tile',
        'p-0',
        'd-flex',
        className
    )

    const department = controller.user

    return (
        <div
            ref={ref}    
            className={_className}
            {...otherProps}>
            {/* <ProfilePic
                userId={userId}
                imageUrl={user?.publicImage}
                width={imageWidth}
                height={imageWidth}
                firstName={user?.firstName || ':'}
                lastName={user?.lastName || ')'}
                className="mr-2"
            /> */}
            <Col className="p-0">
                <Text bold>
                    {controller?.fullName}
                </Text>
                {(subComponent) && React.cloneElement( subComponent )}
            </Col>
        </div>
    )
}))

export { DepartmentTile }
DepartmentTile.displayName = 'DepartmentTile'

import React, { useMemo, useContext } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
// evertel
import { useUI, Icon, Row } from '@evertel/web/ui'
import { Emoji } from '@evertel/web/emojis'
import { MessageWallContext } from '../MessageWallContext'
import { EmojiList } from './EmojiList'

type OptionProps = {
    option: string,
    messageId?: number,
    params?: any
}

interface HoverToolbarProps {
    messageId: number,
    visible: boolean,
    canRetract: boolean,
    canReact?: boolean,
    canForward?: boolean,
    onClickOption: (option: OptionProps) => void,
    onOpenEmojiPicker: (target: string) => void
}

const HoverToolbar: React.FC<HoverToolbarProps> = observer(({
    messageId,
    visible,
    canRetract,
    canReact = true,
    canForward = false,
    onOpenEmojiPicker,
    onClickOption
}) => {

    const { currentUserEmojisController, setIsReadByModalOpen, setSelectedMessageId } = useContext(MessageWallContext)

    const { isConfirmed } = useUI()

    const onClickReads = () => {
        setSelectedMessageId(messageId)
        setIsReadByModalOpen(true)
    }

    const onClickForward = () => {
        onClickOption({
            option: 'forward-message',
            messageId
        })
    }

    const onClickRetract = async () => {
        const confirmed = await isConfirmed({
            title: 'Retract message?',
            message: 'Are you sure you want to retract this message. This will remove it from the conversaton?',
            acceptButton: {
                label: 'Retract',
                color: 'danger'
            }
        })
        if (confirmed) {
            onClickOption({
                option: 'retract',
                messageId
            })
        }
    }
    const emojis = useMemo(() => currentUserEmojisController.getFrequent(5, true), [currentUserEmojisController])

    // important to minimize what is rendered with each message
    if (!visible) return null

    return (
        <Row className="hover-toolbar mt-2">
            {/* REACTIONS */}
            {(canReact) &&
                <div
                    className={classNames('option', { 'reactions': emojis.length > 0 })}
                    id={`msg-reactions-${messageId}`}
                    onClick={() => onOpenEmojiPicker(`#msg-reactions-${messageId}`)}>
                    <EmojiList
                        emojis={emojis}
                        onClickOption={onClickOption}
                        messageId={messageId}
                        skinTone={currentUserEmojisController.skinTone}
                    />
                    <Icon
                        name="grin"
                        className="emoji-btn"
                    />
                </div>
            }

            {/* READS */}
            <div
                className="option"
                onClick={onClickReads}>
                <Icon
                    name="eye"
                />
            </div>

            {/* FORWARD */}
            {(canForward) &&
                <div
                    className="option"
                    onClick={onClickForward}>
                    <Icon
                        name="share"
                    />
                </div>
            }

            {/* RETRACT */}
            {(canRetract) &&
                <div
                    className="option"
                    onClick={onClickRetract}>
                    <Icon
                        name="trash-alt"
                    />
                </div>
            }
        </Row>
    )
})

export { HoverToolbar }

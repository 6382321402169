import { action, makeObservable, runInAction } from 'mobx'
import DepartmentsStore from './DepartmentsStore'
import api from '../api'
import { container } from '../di'
import { VerifiedEmailDomainController } from '@evertel/shared/verified-email-domain'

class VerifiedEmailDomainsStore {

    constructor() {
        this.verifiedEmailDomainController = container.get(VerifiedEmailDomainController)
        makeObservable(this, {
            fetchDepartmentsByDomain: action,
            fetchDepartmentsByDomainCount: action
        })
    }

    getDomain = async (domain) => {        
        return await this.verifiedEmailDomainController.getVerifiedEmailDomain(domain)
    }

    async fetchDepartmentsByDomain(domain, filter) {
        try {
            const departments = await api.VerifiedEmailDomain.getDomainDepartments(domain, {
                ...filter,
                include: 'publicMedia'
            })

            runInAction(() => {
                DepartmentsStore.updateFromData(departments)
            })

            const deptIds = departments.map(d => d.id)
            return DepartmentsStore.departments.filter(d => deptIds.includes(d.id))
        } catch (e) {
            return false
        }
    }

    async fetchDepartmentsByDomainCount(domain, where) {
        if (!domain) return

        try {
            const count = (await api.VerifiedEmailDomain.getDomainDepartmentsCount(domain, where)).count

            return count
        } catch (e) {
            console.log('*** Error: ' + e.message || e)
            return false
        }
    }
}

export default new VerifiedEmailDomainsStore()

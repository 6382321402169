import React from 'react'
import { createContext, useContext, useMemo, useState, ReactNode } from 'react'

type CurrentMediaPlaying = string | undefined

type CurrentMediaPlayingState = {
    currentMediaPlaying: CurrentMediaPlaying,
    setCurrentMediaPlaying: (value: CurrentMediaPlaying) => void
}

const CurrentMediaPlayingContext = createContext<CurrentMediaPlayingState>({
    currentMediaPlaying: undefined,
    setCurrentMediaPlaying: () => {
        //
    }
})

type CurrentMediaPlayingProviderProps = {
    children: ReactNode
}

function CurrentMediaPlayingProvider({
    children
}: CurrentMediaPlayingProviderProps) {

    const [currentMediaPlaying, setCurrentMediaPlaying] = useState<CurrentMediaPlaying>()
    const value = useMemo(
        () => ({ currentMediaPlaying, setCurrentMediaPlaying }),
        [currentMediaPlaying]
    )

    return (
        <CurrentMediaPlayingContext.Provider value={value}>
            {children}
        </CurrentMediaPlayingContext.Provider>
    )
}

function useCurrentMediaPlaying() {
    return useContext(CurrentMediaPlayingContext)
}

export {
    CurrentMediaPlayingProvider,
    useCurrentMediaPlaying
}

import React from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Icon, Col, Row, Text } from '@evertel/web/ui'
import { APIDataDocumentSchemaCategory } from '@evertel/types'


interface CategoryProps {
    category: APIDataDocumentSchemaCategory,
    onSelect: (category: APIDataDocumentSchemaCategory) => void,
    selectedCategoryId: number
}

export const Category: React.FC<CategoryProps> = observer(({
    category,
    onSelect,
    selectedCategoryId
}) => {

    return (
        <Row
            onClick={() => onSelect(category)}
            className={classNames('cursor-pointer', 'border-bottom', 'p-3', {
                //'bg-white': (selectedCategoryId === category.id && theme === 'light'),
                //'bg-dark': (selectedCategoryId === category.id && theme === 'dark')
            })}>
            <Col>
                <Text
                    color={(selectedCategoryId === category.id) ? 'secondary' : undefined}
                    bold>
                    {category?.name}
                </Text>
            </Col>
            <Col align="right">
                <Icon
                    name="chevron-double-right"
                    color={(selectedCategoryId === category.id) ? 'secondary' : undefined}
                    size={10}
                />
            </Col>
        </Row>
    )
})

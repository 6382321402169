import { ReactElement } from 'react'
import classNames from 'classnames'

interface CardGroupProps {
    tag?: ReactElement,
    className?: string
}

const CardGroup = ({
    tag: Tag = 'div',
    className,
    ...otherProps
}) => {

    const classes = classNames(className, 'card-group')
    // @ts-ignore
    return <Tag {...otherProps} className={classes} />
}

export { CardGroup }
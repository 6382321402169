import React, { forwardRef, HTMLAttributes } from 'react'
import { Icon } from './Icon'
import { Button } from './Button'

interface Props extends HTMLAttributes<HTMLButtonElement> {
    onClick?: () => void,
    width?: number
}

const DeleteButtonOverlay = forwardRef<HTMLButtonElement, Props>(({
    onClick,
    width = 25,
    ...otherProps
}, ref) => {

    return (
        <Button
            className="delete-btn-overlay"
            ref={ref}
            color="gray-danger"
            ghost
            onClick={onClick}
            style={{
                display: 'flex',
                position: 'absolute',
                padding: 0,
                top: -5,
                right: -5,
                width,
                height: width,
                borderWidth: 2,
                borderColor: 'white',
                borderRadius: '2em',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            {...otherProps}>
            <Icon
                color="white"
                name="xmark"
                size={width*0.6}
            />
        </Button>
    )
})

export { DeleteButtonOverlay }

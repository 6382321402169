import React, { createContext, ElementType, forwardRef, HTMLAttributes, RefObject, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Manager } from 'react-popper'
import { Placements } from '../../prop-types'
import { useForkedRef } from '@evertel/hooks'

// docs: https://coreui.io/react/docs/components/dropdown/

export type Directions = 'start'|'end'

export type Breakpoints =
    | { xs: Directions }
    | { sm: Directions }
    | { md: Directions }
    | { lg: Directions }
    | { xl: Directions }
    | { xxl: Directions }

export type Alignments = Directions|Breakpoints
  
export interface ButtonDropdownProps extends HTMLAttributes<HTMLDivElement|HTMLLIElement> {
    /** Set aligment of dropdown menu. */
    alignment?: Alignments
    /** Configure the auto close behavior of the dropdown:
        * - `true` - the dropdown will be closed by clicking outside or inside the dropdown menu.
        * - `false` - the dropdown will be closed by clicking the toggle button and manually calling hide or toggle method. (Also will not be closed by pressing esc key)
        * - `'inside'` - the dropdown will be closed (only) by clicking inside the dropdown menu.
        * - `'outside'` - the dropdown will be closed (only) by clicking outside the dropdown menu. */
    autoClose?: 'inside'|'outside'|boolean
    className?: string
    /** Component used for the root node. Either a string to use a HTML element or a component. */
    component?: string|ElementType
    /** Sets a darker color scheme to match a dark navbar. */
    dark?: boolean
    /** Sets a specified  direction and location of the dropdown menu. */
    direction?: 'center'|'dropup'|'dropup-center'|'dropend'|'dropstart'
    onHide?: () => void
    onShow?: () => void
    disabled?: boolean
    /** Describes the placement of your component after Popper.js has applied all the modifiers that may have flipped or altered the originally provided placement property. */
    placement?: Placements
    /** If you want to disable dynamic positioning set this property to `false`. */
    popper?: boolean
    /** Set the dropdown variant to a btn-group, dropdown, input-group, and nav-item. */
    variant?: 'button-group'|'dropdown'|'input-group'|'nav-item'
    visible?: boolean
    borders?: boolean
}

interface ContextProps extends ButtonDropdownProps {
    dropdownToggleRef: RefObject<any>| undefined
    setVisible: React.Dispatch<React.SetStateAction<boolean|undefined>>
}

export const ButtonDropdownContext = createContext({} as ContextProps)
  
const ButtonDropdown = forwardRef<HTMLDivElement|HTMLLIElement, ButtonDropdownProps>(({
    children,
    alignment,
    autoClose = true,
    className,
    dark,
    direction,
    onHide,
    onShow,
    disabled,
    popper = true,
    placement = 'bottom-start',
    variant = 'button-group',
    component = 'div',
    visible = false,
    borders,
    ...otherProps
}, ref ) => {

    const [_visible, setVisible] = useState(visible)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const dropdownToggleRef = useRef(null)
    const forkedRef = useForkedRef(ref, dropdownRef)

    const Component = (variant === 'nav-item') ? 'li' : component

    // disable popper if responsive aligment is set
    if (typeof alignment === 'object') {
        popper = false
    }

    const contextValues = {
        alignment,
        autoClose,
        dark,
        direction: direction,
        dropdownToggleRef,
        placement: placement,
        variant,
        popper,
        visible: _visible,
        setVisible
    }

    const _className = classNames(
        variant === 'nav-item' ? 'nav-item dropdown' : variant,
        {
            show: _visible,
            disabled: disabled,
            borders: borders
        },
        direction === 'center'
            ? 'dropdown-center'
            : direction === 'dropup-center'
            ? 'dropup dropup-center'
            : direction,
        className
    )

    useEffect(() => {
        setVisible(visible)
    }, [visible])

    useEffect(() => {
        if (_visible && onShow) onShow()
        if (!_visible && onHide) onHide()
    }, [_visible])

    const DropdownContent = () => {
        if (variant === 'input-group') {
            return <>{children}</>
        } else {
            return (
                <Component
                    ref={forkedRef}
                    className={_className}
                    {...otherProps}>
                    {children}
                </Component>
            )
        }
    }

    return (
        <ButtonDropdownContext.Provider value={contextValues}>
            {(popper) &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                <Manager>
                    <DropdownContent/>
                </Manager>
            }
            {(!popper) &&
                <DropdownContent/>
            }
        </ButtonDropdownContext.Provider>
    )
})
  
export default ButtonDropdown
ButtonDropdown.displayName = 'ButtonDropdown'

import { observable, runInAction, action, computed, makeObservable } from 'mobx'
import api from '../api'
import AppStore from './AppStore'
import _ from 'lodash'

// Keeps track of our login state and progress
class DepartmentUsersSearchStore {
    users = []
    count = 0
    isBusy = false
    error = null

    constructor() {
        makeObservable(this, {
            users: observable,
            count: observable,
            isBusy: observable,
            error: observable,
            results: computed,
            clearSearch: action,
            fetchSearchCount: action
        })

        
    }

    get results() {
        if (!this.users.length) return []
        return this.users
    }

    // Search contacts api then updates this.resultsIds observable, debounced, paginated
    search = _.debounce(async ({ departmentId, searchString, order, page, where }) => {
        if (!departmentId) return

        runInAction(() => {
            this.isBusy = true
            this.error = null
        })

        if (!page || page === 1) {
            // clear users list
            runInAction(() => {
                this.users = []
            })

            // get total count of matching users (used for paging)
            await this.fetchSearchCount(departmentId, {
                searchTerm: searchString || '',
                ...where,
                and: [
                    { or: [
                        { isBot: false},
                        { isBot: null}
                    ]}
                ]
            })
        }

        // sanitize searchString
        let filterNonPrintableCharacters
        if (searchString?.length) filterNonPrintableCharacters = /^([a-z]|[0-9]|\s)*$/gi.test(searchString)
        if (searchString?.length && !filterNonPrintableCharacters) {
            runInAction(() => {
                this.error = 'Only alphanumeric characters are allowed. Please try again.'
            })
            return
        }

        const limit = 100
        const skip = (page) ? limit * (page - 1) : 0

        const usersCopy = [...this.users]
        let newUsers = []
        try {
            // Get users via search contacts
            newUsers = await api.Department.getUsersBySearch(departmentId, {
                where: {
                    searchTerm: searchString || '',
                    ...where,
                    and: [{
                        or: [
                            { isBot: false},
                            { isBot: null}
                        ]}]
                },
                ...api.defaultUserScope,
                order: order || 'lastName',
                limit,
                skip
            })

            runInAction(() => {
                this.isBusy = false
                this.users = _.uniqBy([...usersCopy, ...newUsers], 'id')
            })

            // search/select uses only newly fetched users
            return newUsers

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({
                type: 'API get',
                message: 'DepartmentUsersSearchStore.search()',
                error: e.message || e
            })
        }

    }, 50, {leading: true})

    async clearSearch() {
        //this.resultsIds.clear()
        this.users.clear()
        this.isBusy = false
        this.error = null
        this.search.cancel()
    }

    async fetchSearchCount(departmentId, where) {
        try {
            const count = (await api.Department.getUsersCountBySearch(departmentId, { where })).count

            runInAction(() => {
                this.count = count
            })

        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'DepartmentUsersSearchStore.fetchSearchCount()',
                departmentId,
                where,
                error: e.message || e
            })

            runInAction(() => {
                this.isBusy = false
                this.error = e
            })
        }
    }
}


// Creates a single instance of this store
export default new DepartmentUsersSearchStore()


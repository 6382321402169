import { MediaReadWidget } from './MediaReadWidget'
import TextReadWidget from './TextWidget'
import Title from './Title'
import MapReadWidget from './MapWidget'
import DropdownReadWidget from './DropdownWidget'
import DateReadWidget from './DateWidget'
import UserReadWidget from './UserWidget'
import CheckboxReadWidget from './CheckboxWidget'
import ListReadWidget from './ListWidget'
import { Row } from './Row'
import { UnknownWidget } from './UnknownWidget'
import { SpacerWidget } from './SpacerWidget'


const ReadWidgets = {
    row: Row,
    unknown: UnknownWidget,
    media: MediaReadWidget,
    text: TextReadWidget,
    title: Title,
    map: MapReadWidget,
    dropdown: DropdownReadWidget,
    date: DateReadWidget,
    datetime: DateReadWidget,
    user: UserReadWidget,
    checkbox: CheckboxReadWidget,
    'checkbox-group': CheckboxReadWidget,
    list: ListReadWidget,
    spacer: SpacerWidget
}

export {
    ReadWidgets
}

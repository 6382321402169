import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { ColumnDef } from '@tanstack/react-table'
// evertel
import { 
    Button, Text, Icon, 
    Card, CardHeader, CardHeaderActions, CardBody,
    Row, Col, InfoBox, PageBody, 
    FloatingMenu, MenuItem, Input, TanTable,
    Badge,
    CardTitle
} from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { ChildDepartmentsSelect, DepartmentUsersListController } from '../'
import { CurrentUserController } from '@evertel/blue-user'
import { ChildDepartmentController, DepartmentController } from '@evertel/department'
import { SelectedDepartmentAccessController } from '@evertel/departments-access'
import { SessionState } from '@evertel/session'
import { formatNumber, getCurrentDepartmentsAccess, getRole } from '@evertel/utils'
import { Analytics } from '@evertel/analytics'
import { useInvite } from '@evertel/web/invites'
import { APIDataDepartment, DI_TYPES, WebUsersStoreInterface } from '@evertel/types'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

const DepartmentUsersList: React.FC = observer(() => {
    const analytics = useService(Analytics, [])

    const history = useHistory()

    const { currentUserId, selectedDepartmentId } = useService(SessionState, [])
    const [selectedChildDepartmentId, setSelectedChildDepartmentId] = useState(selectedDepartmentId)

    const departmentUsersListController = useService(DepartmentUsersListController, [selectedDepartmentId])
    const currentUserController = useService(CurrentUserController, [currentUserId])
    const departmentController = useService(DepartmentController, [selectedChildDepartmentId])
    const selectedDepartmentAccessController = useService(SelectedDepartmentAccessController, [selectedDepartmentId])
    const childDepartmentController = useService(ChildDepartmentController, [selectedDepartmentId])
    const userController: WebUsersStoreInterface = useService(DI_TYPES.LegacyUsersStore)

    const { openInvite } = useInvite()

    const [searchString, setSearchString] = useState<string>('')
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        analytics.logView('/manage/users')
        selectedDepartmentAccessController.init(currentUserId)
        departmentUsersListController.init({departmentId: selectedDepartmentId})

        //only valid for admin and execs:
        childDepartmentController.init(selectedDepartmentId)

        setSelectedChildDepartmentId(selectedDepartmentId)
    }, [selectedDepartmentId])

    useEffect(() => {

        setIsLoading(true);

        (async () => {
            departmentController.init(selectedChildDepartmentId)
            await departmentController.fetchAvailableCapacities()
            setIsLoading(false)
        })()

    }, [selectedChildDepartmentId])

    useEffect(() => {
        departmentUsersListController.debouncedSearch()
    }, [
        departmentUsersListController.page,
        departmentUsersListController.limit,
        departmentUsersListController.sortBy,
        departmentUsersListController.sortOrder
    ])

    useEffect(() => {
        departmentUsersListController.setDepartmentId(selectedChildDepartmentId)
        departmentUsersListController.debouncedSearch()
    }, [selectedChildDepartmentId, departmentUsersListController])

    useEffect(() => {
        departmentUsersListController.setSearchTerm(searchString)
        departmentUsersListController.debouncedSearch()
    }, [searchString, departmentUsersListController])

    const navigateToUser = useCallback((userId: number, user: any) => {
        userController.addUserToStore(user)
        history.push(`/department/${selectedDepartmentId}/user/${userId}`)
    }, [userController])

    const onOpenInvite = useCallback(() => {
        openInvite('member')
    }, [openInvite])

    const canManage = selectedDepartmentAccessController.canManage
    const isAdmin = currentUserController.isAdmin

    const usersCount = departmentUsersListController.usersCount
    const capacities = departmentController.capacities

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            enableSorting: false,
            size: 40
        },
        {
            accessorKey: 'firstName',
            header: 'Name',
            size: 150,
            cell: ({ row }) => (
                <Link className="btn btn-link" to={`/manage/agency/${selectedDepartmentId}/user/${row.original.id}`}>
                    {(row.original.firstName || row.original.lastName) ? `${row.original.firstName} ${row.original.lastName}` : '(Missing Name)'}
                </Link>
            ),
            enableSorting: true
        },
        {
            id: 'departmentsAccessPosition',
            accessorFn: data => getCurrentDepartmentsAccess(data.departmentsAccess, selectedDepartmentId)?.positionString,
            header: 'Position',
            cell: ({ getValue }) => (
                <span className="text-capitalize">
                    {getValue() as string || <span className="text-muted">(Not Provided)</span>}
                </span>
            )
        },
        {
            accessorKey: 'email',
            header: 'Email',
            cell: ({ getValue }) => <a className="btn btn-link" href={`mailto:${getValue()}`}>{getValue() as string}</a>,
            enableSorting: true
        },
        {
            id: 'departmentsAccessRole',
            accessorFn: data => getCurrentDepartmentsAccess(data.departmentsAccess, selectedDepartmentId)?.roles,
            header: 'Role',
            cell: ({ getValue }) => {
                const role = getRole(getValue())
                const color = role === 'management' ? 'text-success' : role === 'guest' ? 'text-warning' : role === 'executive' ? 'text-danger' : ''
                return <span className={`text-capitalize ${color}`}>{role}</span>
            }
        },
        {
            accessorKey: 'signupDate',
            header: 'Signup Date',
            cell: ({ getValue }) => moment(getValue() as string).format('MM/DD/YYYY'),
            size: 110,
            enableSorting: true
        },
        {
            id: 'departmentsAccess',
            accessorFn: data => getCurrentDepartmentsAccess(data.departmentsAccess, selectedDepartmentId)?.isVerified,
            header: 'Access',
            size: 60,
            cell: ({ getValue }) => {
                const status = getValue() === true ? 'Granted' : 'No Access'
                const color = status === 'Granted' ? 'success' : 'secondary'
                return <Badge color={color}>{status}</Badge>
            },
            meta: {
                align: 'right'
            }
        }
    ], [selectedDepartmentId, navigateToUser])

    if (!canManage && !isAdmin) {
        return (
            <InfoBox color='danger' className='m-4'>
                Sorry, you do not have proper credentials to access this page.
            </InfoBox>
        )
    }

    return (
        <PageBody>
            <Card>
                <CardHeader className='flex-wrap align-items-start'>
                    <span className="page-title">
                        <Icon name="users" className="mr-2" />
                        Manage Users ({formatNumber(usersCount)})
                    </span>
                    <CardHeaderActions className='flex-wrap'>
                        {(isAdmin) &&
                            <Button color="success" className="float-right ml-3 text-nowrap" onClick={onOpenInvite}>
                                <Icon name="users" className="mr-2"/> Invite people to {departmentController.shortName}
                            </Button>
                        }
                        <FloatingMenu
                            label="Export"
                            renderLabel={() => (
                                <>
                                    <Icon name="file-pen" className="pr-2" />
                                    <Icon type="solid" name="caret-down" />
                                </>
                            )}
                            className="btn btn-muted text-nowrap ml-3"
                        >
                            <MenuItem
                                label="Export as CSV"
                                icon={{ name: 'file-csv', className: 'mr-1' }}
                                onClick={() => departmentUsersListController.downloadUserListCsv(departmentController.shortName + '-Users-' + new Date().toISOString().split('T')[0])}
                            />
                        </FloatingMenu>
                    </CardHeaderActions>
                </CardHeader>
                <CardBody>
                    <InfoBox color={(capacities?.verifiedMembersAvailable > 10 || isLoading) ? 'info' : 'warning'}>
                        You are using <strong>{capacities?.verifiedMembersCount}</strong> of <strong>{capacities?.verifiedMemberCapacity}</strong> available seats.
                        {(capacities?.verifiedMembersAvailable < 10) &&
                        <span className="pl-1">
                            Email us at <a href="mailto:support@getevertel.com" target="_blank" rel="noopener noreferrer">support@getevertel.com</a> to add more seats.
                        </span>}
                    </InfoBox>
                    <Card color="light" style={{ marginBottom: 5 }}>
                        <CardBody>
                            <Row className="px-3">
                                <Col className='mb-2 px-2'>
                                    <label>Users Name</label>
                                    <Input
                                        type="text"
                                        name="search"
                                        id="search"
                                        placeholder="Search users..."
                                        onChange={(e) => setSearchString(e.target.value)}
                                        value={searchString}
                                    />
                                </Col>
                                <Col className='mb-2 px-2'>
                                    <Text>Filter by Agency</Text>
                                    <ChildDepartmentsSelect 
                                        selected={[departmentController.department]}
                                        onSelect={(dept: APIDataDepartment) => setSelectedChildDepartmentId(dept.id)}
                                        loadOptions={[childDepartmentController.parentDepartment, ...childDepartmentController.childDepartments]}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <TanTable
                        className='table-hover'
                        remote={true}
                        loading={departmentUsersListController.loading}
                        data={departmentUsersListController.users}
                        rowCount={usersCount}
                        columns={columns}
                        options={{
                            columnVisibility: {id: false},
                            responsive: true,
                            responsiveHeight: 'calc(100vh - 180px)',
                            truncateCells: true,
                            pageSize: departmentUsersListController.limit,
                            pageNumber: departmentUsersListController.page,
                            sortName: departmentUsersListController.sortBy,
                            sortOrder: departmentUsersListController.sortOrder as 'asc' | 'desc',
                            onPageChange: (page) => departmentUsersListController.setPage(page),
                            onPageSizeChange: (pageSize) => departmentUsersListController.setLimit(pageSize),
                            onSortChange: (sortName, sortOrder) => departmentUsersListController.setSort(sortName, sortOrder),
                            emptyState: <InfoBox color="secondary" className="m-4">There were no search results.</InfoBox>
                        }}
                    />
                </CardBody>
            </Card>
        </PageBody>
    )
})

export { DepartmentUsersList }
import { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Col, Text, Row } from '@evertel/web/ui'
import { ThreadContext } from '../Thread'
import { useService } from '@evertel/di'
import { ProfilePic } from '@evertel/web/user'
import { SessionState } from '@evertel/session'


const ThreadHeader = observer(() => {

    const { threadController } = useContext(ThreadContext)
    const session = useService(SessionState, [])

    const isOneUser = threadController.isJustMe || threadController.usersNotMe?.length === 1
    const user = (threadController.isJustMe) ? threadController.users.find(u => u.id === session.currentUserId) : threadController.usersNotMe[0]

    return (
        <Row className="page-toolbar">
            <Col xs={8}>
                <Row valign="center">
                    {(isOneUser && user) &&
                        <ProfilePic
                            userId={user.id as number}
                            firstName={user.firstName as string}
                            lastName={user.lastName as string}
                            imageUrl={user.publicImage as string}
                        />
                    }
                    <Text
                        tag="h4"
                        heavy
                        className={classNames('m-0 ml-2')}>
                        {threadController?.name || 'No Name'}
                    </Text>
                </Row>
            </Col>
            <Col align="right">
                
            </Col>
        </Row>
    )
})

export { ThreadHeader }

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import classNames from 'classnames'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { Draggable } from 'react-beautiful-dnd'
import { Icon, Text, Row, Col } from '@evertel/web/ui'

interface ToolBoxItemProps {
    item: {
        id: string,
        name: string,
        icon: IconName|string
    },
    index: number,
    exclude?: string[]
}

const ToolboxItem: React.FC<ToolBoxItemProps> = ({
    item,
    index,
    exclude
}) => {

    const Item = ({ icon, name }: {icon: IconName, name: string}) => {
        return (
            <>
                <Col
                    xs={1}
                    align="center">
                    <Icon name={icon}/>
                </Col>
                <Col>
                    <Text>
                        {name}
                    </Text>
                </Col>
                <Col xs={1}>
                    <Icon
                        name="grip-dots-vertical"
                    />
                </Col>
            </>
        )
    }

    if (exclude?.includes(item.id)) return null

    return (
        <>
            {/* @ts-ignore */}
            <Draggable
                key={item.id}
                draggableId={`${item.id}-toolbox`}
                index={index}>
                    {(provided, snapshot) => (
                        <>
                            <Row
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                    ...provided.draggableProps.style,
                                    transform: snapshot.isDragging
                                    ? provided.draggableProps.style?.transform
                                    : 'translate(0px, 0px)'
                                }}
                                className={classNames(
                                    'toolbox-item',
                                    {
                                        ['bg-info']: snapshot.isDragging,
                                        ['border-info']: snapshot.isDragging
                                    }
                                    )}>
                                <Item
                                    icon={item.icon as IconName}
                                    name={item.name}
                                    />
                            </Row>
                            {/* copy to leave behind while dragging */}
                            {(snapshot.isDragging) &&
                                <div
                                style={{ transform: 'none !important' }}
                                className={classNames('p-2 mb-2 rounded d-flex bg-white', {
                                    //'bg-white': theme === 'light',
                                    //'bg-muted': theme === 'dark'
                                })}>
                                    <Item
                                        icon={item.icon as IconName}
                                        name={item.name}
                                        />
                                </div>
                            }
                        </>
                    )}
            </Draggable>  
        </>
    )
}

export {
    ToolboxItem
}
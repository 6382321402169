import { APIDataRoomMessage, APIMetaDataRoomMessagePropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

class RoomMessagesStore extends Store<APIDataRoomMessage> {
    constructor() {
        super([...APIMetaDataRoomMessagePropertyNames, 'media', 'reactions'])
    }
}

decorate(injectable(), RoomMessagesStore)
const roomMessagesStoreInstance = new RoomMessagesStore()


export { RoomMessagesStore, roomMessagesStoreInstance  }

import { DI_TYPES, WebAppStoreInterface, WebAnalyticsStoreInterface, WebCurrentUserStoreInterface, WebNavigationStoreInterface, WebUsersStoreInterface } from '@evertel/types'
import { Container, loadModules } from '@evertel/di'

import api from '../api'
import { Api } from '@evertel/api'

import { DI_MODULES as SHARED_DEPARTMENT_DI_MODULES } from '@evertel/department'
import { DI_MODULES as SHARED_BLUE_USER_DI_MODULES } from '@evertel/blue-user'
import { DI_MODULES as SHARED_ROOM_DI_MODULES } from '@evertel/room'
import { DI_MODULES as SHARED_DOCUMENT_DI_MODULES } from '@evertel/document'
import { DI_MODULES as SHARED_AUTHENTICATION_DI_MODULES } from '@evertel/shared/feature-authentication-auth0'
import { DI_MODULES as SHARED_VERIFIED_EMAIL_DOMAINS_DI_MODULES } from '@evertel/shared/verified-email-domain'
import { DI_MODULES as SHARED_SESSION_DI_MODULES } from '@evertel/session'
import { DI_MODULES as SHARED_STORE_DI_MODULES } from '@evertel/stores'
import { DI_MODULES as SHARED_MESSAGE_DI_MODULES } from '@evertel/message'
import { DI_MODULES as SHARED_EMOJIS_DI_MODULES } from '@evertel/emojis'
import { DI_MODULES as SHARED_INVITES_DI_MODULES } from '@evertel/invites'
import { DI_MODULES as SHARED_DRAWER_DI_MODULES } from '@evertel/drawer'
import { DI_MODULES as SHARED_DEVICE_DI_MODULES } from '@evertel/device'
import { DI_MODULES as SHARED_THREAD_DI_MODULES } from '@evertel/thread'
import { DI_MODULES as SHARED_MEDIA_MODULE } from '@evertel/media'
import { DI_MODULES as SHARED_DEPARTMENTS_ACCESS_MODULE } from '@evertel/departments-access'
import { DI_MODULES as SHARED_PUSH_MODULE, PushImplementation } from '@evertel/push'
import { DI_MODULES as SHARED_LOGOUT, LogoutImplementation } from '@evertel/logout'

import { DI_WEB_DEPARTMENT_MODULE } from '@evertel/web/department'
import { DI_WEB_DOCUMENT_MODULE } from '@evertel/web/document'
import { DI_WEB_ADMIN_MODULE } from '@evertel/web/admin'
import { DI_WEB_UI_MODULE } from '@evertel/web/ui'
import { DI_WEB_ROOM_MODULE } from '@evertel/web/room'
import { DI_MODULES as DI_WEB_THEME_MODULE } from '@evertel/web/theme'

import AppStore from '../stores/AppStore'
import AnalyticsStore from '../stores/AnalyticsStore'
import CurrentUserStore from '../stores/CurrentUserStore'
//import NavigationStore from '../stores/NavigationStore'

import { Analytics as AnalyticsBase } from '@evertel/analytics'
import { Analytics as AnalyticsImp } from '../analytics/Analytics'
import { UsersStore } from '../stores'
import { initNavigationStore } from './diInterop'
import { WebPushImplementation } from '@evertel/web/push'
import { WebLogoutImplementation } from '@evertel/web/logout'
import { DI_WEB_HEADER_MODULE } from '@evertel/web/header'

const diContainer = new Container()
diContainer.bind(Api).toConstantValue(api)

loadModules(diContainer, SHARED_DEPARTMENT_DI_MODULES)
loadModules(diContainer, SHARED_BLUE_USER_DI_MODULES)
loadModules(diContainer, SHARED_ROOM_DI_MODULES)
loadModules(diContainer, SHARED_DOCUMENT_DI_MODULES)
loadModules(diContainer, SHARED_AUTHENTICATION_DI_MODULES)
loadModules(diContainer, SHARED_VERIFIED_EMAIL_DOMAINS_DI_MODULES)
loadModules(diContainer, SHARED_MEDIA_MODULE)
loadModules(diContainer, SHARED_SESSION_DI_MODULES)
loadModules(diContainer, SHARED_DEPARTMENTS_ACCESS_MODULE)
loadModules(diContainer, SHARED_STORE_DI_MODULES)
loadModules(diContainer, SHARED_MESSAGE_DI_MODULES)
loadModules(diContainer, SHARED_EMOJIS_DI_MODULES)
loadModules(diContainer, SHARED_INVITES_DI_MODULES)
loadModules(diContainer, SHARED_DRAWER_DI_MODULES)
loadModules(diContainer, SHARED_DEVICE_DI_MODULES)
loadModules(diContainer, SHARED_THREAD_DI_MODULES)
loadModules(diContainer, SHARED_PUSH_MODULE)
loadModules(diContainer, SHARED_LOGOUT)

loadModules(diContainer, DI_WEB_DEPARTMENT_MODULE)
loadModules(diContainer, DI_WEB_ADMIN_MODULE)
loadModules(diContainer, DI_WEB_UI_MODULE)
loadModules(diContainer, DI_WEB_ROOM_MODULE)
loadModules(diContainer, DI_WEB_DOCUMENT_MODULE)
loadModules(diContainer, DI_WEB_THEME_MODULE)
loadModules(diContainer, DI_WEB_HEADER_MODULE)

initNavigationStore(diContainer)

diContainer.bind<WebAppStoreInterface>(DI_TYPES.LegacyAppStore).toConstantValue({
    get appLoading() { return AppStore.appLoading }
})
diContainer.bind<WebAnalyticsStoreInterface>(DI_TYPES.LegacyAnalyticsStore).toConstantValue({
    logModalView() { return AnalyticsStore.logModalView() },
    logPageView() { return AnalyticsStore.logPageView() }
})
diContainer.bind<WebCurrentUserStoreInterface>(DI_TYPES.LegacyCurrentUserStore).toConstantValue({
    get id() { return CurrentUserStore.id },
    get email() { return CurrentUserStore.email },
    get auth0() { return CurrentUserStore.auth0 },
    get phoneNumber() { return CurrentUserStore.phoneNumber },
    update(data) { return CurrentUserStore.update(data) }
})
diContainer.bind<WebUsersStoreInterface>(DI_TYPES.LegacyUsersStore).toConstantValue({
    addUserToStore(user) { return UsersStore.addUserToStore(user) }
})

diContainer.bind<AnalyticsBase>(AnalyticsBase).to(AnalyticsImp).inSingletonScope()

diContainer.bind<PushImplementation>(PushImplementation).to(WebPushImplementation).inSingletonScope()

diContainer.bind<LogoutImplementation>(LogoutImplementation).to(WebLogoutImplementation).inSingletonScope()

export const container = diContainer

import React, { useRef, useEffect, useState, useCallback } from 'react'
import ReactPlayer from 'react-player/lazy'
import { observer } from 'mobx-react'
// evertel
import { Icon } from '@evertel/web/ui'
import { AnalyticsStore, DeviceStore } from '../stores'
import { APIDataMedia } from '@evertel/types'


interface SecuredVideoProps {
    data: APIDataMedia
    width?: number
    height?: number
    style?: React.CSSProperties
    rounded?: boolean
    autoPlay?: boolean
    onPlay?: () => void
    controls?: boolean
    loop?: boolean
    light?: boolean
    playbackRate?: number
    volume?: number
    muted?: boolean
}

const SecuredVideo: React.FC<SecuredVideoProps> = observer(({
    data,
    width,
    height,
    style,
    rounded = false,
    autoPlay = false,
    onPlay,
    controls = true,
    loop = false,
    light = false,
    playbackRate = 1.0,
    volume = 0.8,
    muted = false
}) => {
    const [isMounted, setIsMounted] = useState(false)
    const [source, setSource] = useState('')
    const [error, setError] = useState<string | null>(null)
    const [isPlaying, setIsPlaying] = useState(autoPlay)

    const player = useRef<ReactPlayer | null>(null)

    const roundedStyle: React.CSSProperties | undefined = rounded ? { borderRadius: 5, overflow: 'hidden' } : undefined

    const loadVideo = useCallback(() => {
        if (!ReactPlayer.canPlay(data.url)) {
            onError()
            return
        }

        if (isMounted) {
            // already a blob (new posts use blob)
            setSource(data.url)
        }
    }, [isMounted, data.url])

    useEffect(() => {
        setIsMounted(true)
        loadVideo()

        // on unmount
        return () => {
            setIsMounted(false)
        }
    }, [loadVideo])

    const onPlayEvent = () => {
        setIsPlaying(true)

        DeviceStore.setCurrentMediaFilePlaying(data) // this tells all other media to stop

        // parent callback
        if (onPlay) onPlay()

        // log event to GA
        AnalyticsStore.logEvent({
            category: 'User',
            action: 'play_video'
        })
    }

    const onPause = () => {
        // also called when video play completes if loop is false
        setIsPlaying(false)
    }

    const onError = () => {
        setError('Unable to load video')
        setSource('')
    }

    // of another video plays, stop this one
    useEffect(() => {
        if (isPlaying && DeviceStore.currentMediaFilePlaying && DeviceStore.currentMediaFilePlaying.id !== data.id) {
            setIsPlaying(false)
        }
    }, [isPlaying, DeviceStore.currentMediaFilePlaying?.id, data?.id])

    return (
        <div style={{ height, width, position: 'relative', ...roundedStyle, ...style }}>
            {error ? (
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height }}>
                    <Icon name="frown" style={{ fontSize: '2rem' }} />
                    <em>{error}</em>
                    <small className="text-muted">({data.fileName})</small>
                </div>
            ) : (
                <ReactPlayer
                    ref={player}
                    url={source}
                    controls={controls}
                    playing={isPlaying}
                    muted={muted}
                    playbackRate={playbackRate}
                    light={light}
                    volume={volume}
                    loop={loop}
                    onError={onError}
                    onPlay={onPlayEvent}
                    onPause={onPause}
                    {...(light && { onClickPreview: onPlayEvent })}
                    width="100%"
                    height="100%"
                    config={{
                        file: {
                            attributes: {
                                poster: undefined
                            }
                        }
                    }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}
                />
            )}
        </div>
    )
})

export default SecuredVideo
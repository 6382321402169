import React from 'react'
import { ElementType, forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface CardSubtitleProps extends HTMLAttributes<HTMLHeadingElement> {
    className?: string
    component?: string|ElementType
}

const CardSubtitle = forwardRef<HTMLHeadingElement, CardSubtitleProps>(({
    children,
    component: Component = 'h6',
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('card-subtitle', className)

    return (
        <Component
            ref={ref}
            className={_className}
            {...otherProps}>
            {children}
        </Component>
    )
})

export { CardSubtitle }

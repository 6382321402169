import { PushNotification } from './PushImplementation'
import _ from 'lodash'

export function parsePushNotification(push: any, decode = true): PushNotification | undefined {
    if (!push) return undefined

    const payloadType = _.get(push, 'data.payloadType')

    const payloadString = _.get(push, 'data.payload')

    const notificationString = _.get(push, 'data.notification')

    // TODO: we need ot think about throwing an 'Unhandled Push' error in this case for development.
    if (!payloadString || !payloadType) return undefined

    try {
        const notificationObj = decode ? (notificationString && JSON.parse(notificationString)) : notificationString
        const payloadObj = decode ? JSON.parse(payloadString) : payloadString
        
        return {
            notification: notificationObj,
            data: {
                type: payloadType,
                ...payloadObj
            }
        }
    } catch (_){
        // TODO: we need ot think about throwing this error in development.
        return undefined
    }

}

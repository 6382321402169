
const normalize = (schema: any) => {

    if (!schema) {
        console.error('SCHEMA-PARSER/normalize(): missing schema')
        return {layouts: {full: []}}
    }

    const normalizedViews = {}

    //console.log(schema)

    for (const [layout, fields] of Object.entries(schema.layouts)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        normalizedViews[layout] = fields?.map(({type, ...props}) => {

            // Fields
            if (type?.includes('#')) {
                const key = type.substring(1)

                const normalized = normalize(
                    {
                        layouts: {
                            [layout]: [
                                {
                                    ...props,
                                    ...schema.fields[key]
                                }
                            ]
                        },
                        fields: schema.fields,
                        internalSchemas: schema.internalSchemas
                    }
                )

                type = key

                props = {
                    ...props,
                    ...normalized.layouts[layout][0]
                }
            }

            // Internal Schemas/List/Sub-Form
            if (type?.includes('&')) {
                const key = type.substring(1)

                const normalized = normalize(
                    {
                        layouts: {
                            ...schema.internalSchemas[key].layouts
                        },
                        fields: {
                            ...schema.fields,
                            ...schema.internalSchemas[key].fields
                        },
                        internalSchemas: {
                            ...schema.internalSchemas[key].internalSchemas
                        }
                    }
                )

                type = key

                props = {
                    ...props,
                    internalSchemaId: key,
                    id: (schema.internalSchemas[key]) ? key : props?.id, // props.id handles legacy schema bad data
                    overrideId: props?.id, // backwards compatibility for legacy schema bad data
                    type: 'list',
                    items: normalized.layouts[layout]
                }
            }

            // Row items
            if (type === 'form' || type === 'container' || type === 'row') {
                const normalized = normalize(
                    {
                        layouts: {
                            [layout]: props.items
                        },
                        fields: schema.fields,
                        internalSchemas: schema.internalSchemas
                    }
                )

                props = {
                    ...props,
                    items: normalized.layouts[layout]
                }
            }

            // legacy formatted List/Sub-Form/Internal Schema item
            if (type === 'list' && props?.item) {
                const normalized = normalize(
                    {
                        layouts: {
                            [layout]: [props.item]
                        },
                        fields: schema.fields,
                        internalSchemas: schema.internalSchemas
                    }
                )

                props = {
                    ...props,
                    item: normalized.layouts[layout][0]
                }
            }

            // Polymorphic options
            if (type === 'polymorphic' && props?.options) {
                const normalized = props.options?.map((option) => {
                    const normalized = normalize(
                        {
                            layouts: {
                                [layout]: [option.item]
                            },
                            fields: schema.fields,
                            internalSchemas: schema.internalSchemas
                        }
                    )

                    return {
                        ...option,
                        item: normalized.layouts[layout][0]
                    }
                })

                props = {
                    ...props,
                    options: normalized
                }
            }

            return {
                type: type,
                ...props
            }
        })
    }

    return {
        layouts: normalizedViews
    }
}

export {
    normalize
}

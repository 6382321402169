import React from 'react'
//import { Map } from '@evertel/web/ui'
import Wrapper from './Wrapper'
import { Text, Link } from '@react-pdf/renderer'

const MapReadWidget = ({
    value = {},
    title,
    description
}) => {

    const { address } = value

    return (
        <Wrapper
            value={value}
            title={title}
            description={description}>
            {(!address) ?
                <Text>
                    ---
                </Text>
                :
                <Link
                    src={`https://maps.google.com/?q=${address}`}
                    style={{
                        color: 'blue'
                    }}>
                    {address}
                </Link>
            }

            {/* {(address && geometry) &&
                <View style={{height: 200}}>
                    <Map
                        apiKey={GOOGLE_API_KEY }
                        center={geometry.location}
                        zoom={11}
                    />
                </View>
            } */}
        </Wrapper>
    )
}

MapReadWidget.propTypes = {}

export default MapReadWidget

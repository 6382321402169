import { Component } from 'react'
import { observer } from 'mobx-react'
//import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import classNames from 'classnames'
// evertel
import Colors from '../scss/_colors.module.scss'
import { EmojiStore, DeviceStore } from '../stores'

const EmojiPickerModal = observer(class EmojiPickerModal extends Component {
    static emojiPickerInstance

    static show(config) {
        EmojiPickerModal.emojiPickerInstance.showModal(config)
    }

    static hide() {
        EmojiPickerModal.emojiPickerInstance.hideModal()
    }

    state = {
        isOpen: false
    }

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    showModal = (config = {}) => {
        this.setState({
            isOpen: true,
            post: config.post || {},
            native: config.native || false,
            onSelectEmoji: config.onSelectEmoji,
            enableFrequentEmojiSort: config.enableFrequentEmojiSort || true,
            custom: config.custom || [],
            notifyCancel: config.notifyCancel || null
        })
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    hideModal = () => {

        let notify = this.state.notifyCancel
        this.setState({
            isOpen: false,
            notifyCancel: null
        })

        if (notify) {
            notify()
        }
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        if (this.picker.contains(e.target)) {
            // click inside the picker
            return
        }
        // click outside the picker
        this.hideModal()
    }

    setSkinTone = (skin) => {
        EmojiStore.setSkinTone(skin)
    }

    //--------------------------------------------------------------------------------------------------------------------------
    render() {
        const { post, native, onSelectEmoji, enableFrequentEmojiSort, custom, isOpen } = this.state

        return (
            <div ref={p => this.picker = p} id="emoji-picker" className={classNames({invisible: !isOpen})}>
                <Picker
                    onSelect={(e) => onSelectEmoji(e, post)}
                    theme={DeviceStore.theme}
                    color={Colors.secondary}
                    native={native}
                    enableFrequentEmojiSort={enableFrequentEmojiSort}
                    custom={custom}
                    set="apple"
                    recent={EmojiStore.getFrequentIds(21)}
                    title="Pick your emoji..."
                    emoji="point_up"
                    onSkinChange={this.setSkinTone}
                    defaultSkin={EmojiStore.skinTone}
                />
            </div>
        )
    }
})

export default EmojiPickerModal

import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'


class GlobalSchemaCategoriesController {
    categories = []

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = async () => {
        await this.fetchSchemaCategories()
    }

    fetchSchemaCategories = async () => {
        // fetch schemas
        const categories = await this.api.Routes.DocumentSchemaCategory.get({
            where: {
                departmentId: 0
            },
            order: 'name ASC'
        })

        // update observables
        runInAction(() => {
            this.categories = categories
        })
    }
}

decorate(injectable(), GlobalSchemaCategoriesController)
decorate(inject(Api), GlobalSchemaCategoriesController, 0)

export { GlobalSchemaCategoriesController }

import { observable, action, computed, runInAction, makeAutoObservable } from 'mobx'
import api from '../../api'
import AppStore from '../AppStore'
import DepartmentsAccessStore from '../DepartmentsAccessStore'
import AnalyticsStore from '../AnalyticsStore'
import Media from './Media'
import _ from 'lodash'
import moment from 'moment'
import { container } from '../../di'
import { SessionState } from '@evertel/session' 
import { RoomStore } from '@evertel/stores'

const DEPARTMENT_TYPES_WITH_DOCUMENTS = ['police', 'fire']

export default class Department {
    id = 0
    name = undefined
    shortName = undefined
    isDemo = false
    type = undefined
    isBot = false
    meta = {}
    publicMedia = undefined
    options = []
    error = null
    isBusy = false
    employees = undefined
    subscriptionExpiresDate = undefined
    city = undefined
    state = undefined
    address = undefined
    zipCode = undefined
    location = undefined
    country = undefined
    county = undefined
    federal = undefined
    sheriff = undefined
    phone = undefined
    isTrial = undefined
    passwordTTL = undefined
    enterprise = undefined
    emojis = []
    CRMdata = []
    childDepartments = []
    linkedDepartments = []
    publicImage = undefined

    session = undefined
    roomStore = undefined

    accessCode = undefined

    constructor(data) {
        this.session = container.get(SessionState)
        this.roomStore = container.get(RoomStore)

        makeAutoObservable(this)

        if (!data.id) { throw new Error('id is required') }
        this.id = data.id
        this.updateFromData(data)


    }

    updateFromData(data) {
        // Updates whatever fields are passed in
        const fields = ['name', 'shortName', 'isDemo', 'type', 'isBot', 'meta', 'publicMedia', 'options', 'subscriptionExpiresDate', 'address', 'verifiedMemberCapacity', 'employees',
            'city', 'state', 'county', 'country', 'sheriff', 'phone', 'isTrial', 'trialStartDate', 'passwordTTL', 'emojis', 'zipCode', 'location', 'enterprise', 'publicImage']
        for (const f of fields) {
            if (data[f] !== undefined) {
                switch (f) {
                    case 'publicMedia':
                        this[f] = (data[f]) && new Media(data[f])
                        break
                    default:
                        this[f] = data[f]
                }
            }
        }
    }

    get shortenedName() {
        if (this.shortName) return this.shortName
        return this.name.replace(/\(.*?\)/g, '').split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')
    }

    // this is automatically an action, no annotation necessary
    set shortenedName(value) {
        this.shortName = value
    }

    get status() {
        const today = moment()
        const hasExpired = this.subscriptionExpiresDate ? moment(today).isAfter(this.subscriptionExpiresDate, 'day') : false

        if (!this.isTrial && this.subscriptionExpiresDate && !hasExpired) return 'active'
        if (this.isTrial) return 'in trial'
        if (this.isTrial && hasExpired) return 'trial expired'
        if (!this.isTrial && this.subscriptionExpiresDate && hasExpired) return 'churned'
        return ''
    }

    get usersCount() {
        return this.getUsersCount()
    }

    get roomMessagesCount() {
        return this.fetchRoomMessagesCount()
    }

    get directMessagesCount() {
        return this.fetchDirectMessagesCount()
    }

    get roomsCount() {
        return this.fetchRoomsCount()
    }

    get usesDocuments() {
        return DEPARTMENT_TYPES_WITH_DOCUMENTS.some(d => this.type === d)
    }

    get hasChildDepartments() {
        return !!this.childDepartments?.length
    }

    get hasLinkedDepartments() {
        return !!this.linkedDepartments?.length
    }

    setConnectorName(value) {
        runInAction(() => {
            this.enterprise.connectorName = value
        })
    }

    async update(data) {
        this.error = null
        this.isBusy = true

        try {
            const department = await api.Department.update(this.id, data)

            this.updateFromData(department)
        } catch (e) {
            AppStore.logError({type: 'API put', message: 'Department Model update()', error: e.message || e, departmentId: this.id})

            runInAction(() => {
                this.error = e
            })
        }

        runInAction(() => {
            this.isBusy = false
        })
    }

    async getGeopointFromAddress(address, googleMapsAPIKey) {
        if (!address) return null

        this.error = null
        this.isBusy = true

        try {
            const geopoint = await api.Department.getGeopointFromAddress(address, googleMapsAPIKey)

            this.updateFromData({
                location: geopoint?.results[0]?.geometry?.location
            })
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'Department Model getGeopointFromAddress()',
                error: e.error_message || e,
                departmentId: this.id,
                address: this.address+'+'+this.city+'+'+this.state+'+'+this.zipCode
            })

            runInAction(() => {
                this.error = e
            })
        }

        runInAction(() => {
            this.isBusy = false
        })
    }

    async createRoom(data) {
        this.error = null
        this.isBusy = true

        try {
            const room = await api.Department.addRoom(this.id, data)
            this.roomStore.update(room)

            runInAction(() => {
                this.isBusy = false
            })

            //log to analytics
            AnalyticsStore.logEvent({
                category: 'User',
                action: 'created_room'
            })

            return room
        } catch (e) {
            AppStore.logError({type: 'API post', message: 'Department Model createRoom()', error: e.message || e, departmentId: this.id})

            runInAction(() => {
                this.error = e
                this.isBusy = false
            })
        }
    }

    async searchUsers({ page, pageSize, searchTerm, sortBy, sortOrder, searchLinked = false }) {
        this.error = null
        this.isBusy = false

        if (!page) page = 1
        if (!pageSize) pageSize = 25
        const skip = pageSize * (page - 1)

        const filter = {
            where: {
                searchTerm: searchTerm
            },
            include: [{
                relation: 'departmentsAccess',
                scope: {
                    include: ['position', 'department']
                }
            }],
            limit: pageSize,
            skip: skip,
            order: sortOrder ? (sortBy + ' ' + sortOrder) : undefined
        }

        const endpoint = (searchLinked) ? api.Department.getLinkedDepartmentUsersBySearch : api.Department.getUsersBySearch

        try {
            const users = await endpoint(this.id, filter)

            runInAction(() => {
                this.isBusy = false
            })

            return users

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({
                type: 'API get',
                message: 'Department Model searchUsers()',
                error: e.message || e,
                departmentId: this.id
            })
            return
        }
    }

    async getUsersCount(where, searchLinked) {
        const endpoint = (searchLinked) ? api.Department.getLinkedDepartmentUsersCountBySearch : api.Department.getUsersCountBySearch

        try {
            const count = (await endpoint(this.id, where)).count
            return count
        } catch (e) {
            AppStore.logError({
                type: 'API post',
                message: 'Department Model getUsersCount()',
                error: e.message || e,
                departmentId: this.id
            })
        }
    }

    async fetchRoomMessagesCount(where) {
        try {
            const count = (await api.Department.getReportRoomMessagesCount(this.id, where)).count
            return count
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'Department Model fetchRoomMessagesCount()',
                error: e.message || e,
                departmentId: this.id
            })
        }
    }

    async fetchDirectMessagesCount(where) {
        try {
            const count = (await api.Department.getReportDirectMessagesCount(this.id, where)).count
            return count
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'Department Model fetchDirectMessagesCount()',
                error: e.message || e,
                departmentId: this.id
            })
        }
    }

    async fetchRoomsCount() {
        try {
            const count = (await api.Department.getRooms(this.id, {
                filter: {
                    scope: {
                        fields: ['id']
                    }
                }
            })).length
            return count
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'Department Model fetchRoomsCount()',
                error: e.message || e,
                departmentId: this.id
            })
        }
    }

    async getCapacityCounts(where) {
        this.error = null

        try {
            const capacities = await api.Department.getAvailableCapacities(this.id, where)
            return capacities
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'Department Model getCapacityCounts()',
                error: e.message || e,
                departmentId: this.id
            })

            runInAction(() => {
                this.error = e
            })
        }
    }

    async getHistory({ page, pageSize, searchTerm, sortBy, sortOrder }, filter) {
        this.error = null
        this.isBusy = true
        let historyFilter = filter

        const pageNum = page || 1
        const limit = pageSize || 25


        if (!filter) {
            //create filter for endpoint
            historyFilter = {
                where: {},
                include: ['createdBy'],
                limit: limit,
                skip: limit * (pageNum - 1),
                order: (sortBy || 'id') + ' ' + (sortOrder || 'DESC')
            }
        }
        
        

        if (searchTerm) {
            historyFilter.where = {
                action: { like: `%${searchTerm}%` }
            }
        }

        try {
            const history = await api.Department.getHistory(this.id, historyFilter)

            runInAction(() => {
                this.isBusy = false
            })

            return history
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API get', message: 'Department Model getHistory()', error: e.message || e, departmentId: this.id})
            return
        }
    }

    async getHistoryCount(searchTerm = '') {
        this.error = null

        const where = {
            action: { like: `%${searchTerm}%` }
        }

        try {
            const history = (await api.Department.getHistoryCount(this.id, where)).count


            return history
        } catch (e) {
            runInAction(() => {
                this.error = e
            })

            AppStore.logError({
                type: 'API get',
                message: 'Department Model getHistoryCount()',
                error: e.message || e,
                departmentId: this.id
            })
            return
        }
    }

    async fetchEmojis() {
        try {
            const emojis = await api.Department.getEmojis(this.id)

            // format for emoji-mart
            emojis.forEach(emoji => {
                emoji.id = emoji.colons.replace(/:/g, '') // remove colons
                emoji.image = { uri: emoji.imageUrl }
                emoji.customCategory = 'Evertel'
                emoji.keywords = emoji.shortnames
                emoji.emoticons = []
            })

            runInAction(() => {
                this.emojis = emojis || []
            })
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'Department Model fetchEmojis()',
                error: e.message || e,
                departmentId: this.id
            })
        }
    }

    //======================================
    // CRM DATA
    //======================================

    async fetchCRMdata() {
        try {
            const data = await api.Department.getCRMdata(this.id)

            runInAction(() => {
                this.CRMdata = data
            })
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'Department Model fetchCRMdata()',
                error: e.message || e,
                departmentId: this.id
            })
        }
    }

    async addCRMdata(data) {
        // {
        //     "key": "string",
        //     "valueString": "string",
        //     "valueType": "string",
        //     "valueNumber": 0,
        //     "valueDate": "2020-11-09T23:06:16.431Z",
        //     "userId": 0,
        //     "parentId": 0,
        // }
        try {
            const newData = await api.Department.postCRMdata(this.id, data)

            runInAction(() => {
                this.CRMdata.push(newData)
            })

            return newData
        } catch (e) {
            AppStore.logError({
                type: 'API post',
                message: 'Department Model addCRMdata()',
                error: e.message || e,
                departmentId: this.id,
                data
            })
        }
    }

    async updateCRMdata(crmDataId, data) {
        // {
        //     "id": "number",
        //     "key": "string",
        //     "valueString": "string",
        //     "valueType": "string",
        //     "valueNumber": 0,
        //     "valueDate": "2020-11-09T23:06:16.431Z",
        //     "parentId": 0,
        // }
        try {
            const newData = await api.Department.updateCRMdata(this.id, crmDataId, data)

            const idx = this.CRMdata.findIndex(d => d.id === data.id)

            runInAction(() => {
                this.CRMdata[idx] = newData
            })
        } catch (e) {
            runInAction(() => {
                this.error = e
            })

            AppStore.logError({
                type: 'API put',
                message: 'Department Model updateCRMdata()',
                error: e.message || e,
                departmentId: this.id,
                data
            })
        }
    }

    async deleteCRMdata(CRMdataId) {
        try {
            await api.Department.deleteCRMdata(this.id, CRMdataId)

            runInAction(() => {
                this.CRMdata = this.CRMdata.filter(d => d.id !== CRMdataId)
            })
        } catch (e) {
            AppStore.logError({
                type: 'API delete',
                message: 'Department Model deleteCRMdata()',
                error: e.message || e,
                departmentId: this.id,
                CRMdataId
            })
        }
    }


    //============================================
    // DEPARTMENT LINKS/CHILDREN
    //============================================

    async fetchLinkedDepartments() { // ADMIN ONLY
        try {
            const linked = await api.Routes.DepartmentLinkedThrough.get({
                where: {
                    or: [
                        { childDepartmentId: this.id },
                        { parentDepartmentId: this.id }
                    ]
                },
                include: ['childDepartment', 'parentDepartment']
            })

            console.log('LINKED DEPTS', linked)

            runInAction(() => {
                this.linkedDepartments = linked
            })
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'Department Model fetchLinkedDepartments()',
                error: e.message || e,
                departmentId: this.id
            })
        }
    }

    async linkDepartment(data) { // ADMIN ONLY
        if (!data) return
        // data = {
        //     "meta": {},
        //     "parentDepartmentId": 0,
        //     "childDepartmentId": 0
        // }
        try {
            await api.Routes.DepartmentLinkedThrough.post(data)

            runInAction(() => {
                this.fetchLinkedDepartments() // fetch because we need the includes
            })
        } catch (e) {
            AppStore.logError({
                type: 'API post',
                message: 'Department Model linkDepartment()',
                error: e.message || e,
                departmentId: this.id,
                data
            })
        }
    }

    async unlinkDepartment(linkId) { // ADMIN ONLY
        if (!linkId) return

        try {
            await api.Routes.DepartmentLinkedThrough.delById(linkId)

            runInAction(() => {
                this.linkedDepartments = this.linkedDepartments.filter(l => l.id !== linkId)
            })
        } catch (e) {
            AppStore.logError({
                type: 'API delete',
                message: 'Department Model unlinkDepartment()',
                error: e.message || e,
                departmentId: this.id,
                linkId
            })
        }
    }

    async fetchChildDepartments(filter) {
        try {
            const children = await api.Department.getChildDepartments(this.id, filter)

            runInAction(() => {
                this.childDepartments = children
            })
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'Department Model fetchChildDepartments()',
                error: e.message || e,
                departmentId: this.id,
                filter
            })
        }
    }
}

import { useState, useEffect, useMemo, Suspense } from 'react'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'
// evertel
import { InfoBox, Card, CardHeader, CardBody, Row, Col } from '@evertel/web/ui'
import { useContainer } from '@evertel/di'
import { DI_TYPES, WebAnalyticsStoreInterface, WebCurrentUserStoreInterface, WebAppStoreInterface } from '@evertel/types'
import { Button, Text } from '@evertel/web/ui'
import { AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { EnrollMultiFactorModal } from './EnrollMultiFactorModal'
import { UnenrollMultiFactorModal } from './UnenrollMultiFactorModal'
import { ConfirmEnrollMFA } from './ConfirmEnrollMFA'


const Security = observer(() => {

    const container = useContainer()

    const analyticsStore = useMemo(() => container.get<WebAnalyticsStoreInterface>(DI_TYPES.LegacyAnalyticsStore), [container])
    const currentUserStore = useMemo(() => container.get<WebCurrentUserStoreInterface>(DI_TYPES.LegacyCurrentUserStore), [container])
    const appStore = useMemo(() => container.get<WebAppStoreInterface>(DI_TYPES.LegacyAppStore), [container])
    const authenticationController = useMemo(() => container.get(AuthenticationController), [container])

    const [isLoading, setIsLoading] = useState(false)
    const [showEnrollModal, setShowEnrollModal] = useState(false)
    const [showUnenrollModal, setShowUnenrollModal] = useState(false)
    const [showConfirmEnrollmentModal, setShowConfirmEnrollmentModal] = useState(false)
    const [enrollmentNumber, setEnrollmentNumber] = useState('')

    useEffect(() => {
        autorun((init) => {
            if (!appStore.appLoading) {
                initController()
                
                init.dispose()
            }
        })
        analyticsStore.logPageView('/account/security')
    }, [])

    const initController = async () => {
        setIsLoading(true)

        // fetch authenticators
        await authenticationController.getAuthenticators(currentUserStore.id)

        setIsLoading(false)
    }

    const toggleEnrollModal = () => {
        setShowEnrollModal(!showEnrollModal)
    }

    const toggleUnenrollModal = () => {
        setShowUnenrollModal(!showUnenrollModal)
    }

    const enrollmentInitiated = (phoneNumber: string) => {
        setEnrollmentNumber(phoneNumber)
        setShowEnrollModal(false)
        setShowConfirmEnrollmentModal(true)
    }

    const smsAuthenticator = authenticationController.authenticators?.find(a => a.type === 'sms' && a.confirmed === true)
    const emailAuthenticator = authenticationController.authenticators?.find(a => a.type === 'email' && a.confirmed === true)

    // console.log('AUTHENTICATORS', authenticationController.authenticators)

    return (
        <div className='animated fadeIn m-4'>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <h3>Multi-Factor Authentication (MFA) Settings</h3>
                        </CardHeader>
                        <CardBody className="">
                            <Row className="mb-5">
                                We'll ask for a security code via the methods below if we 
                                notice a login from an unrecognized device or browser.
                            </Row>
                            <Row valign='start' className="flex-wrap">
                                <Col xs={12} lg={6} className="p-4">
                                    <div className="mb-4">
                                        <strong>Text Message (SMS):</strong>
                                        <hr />
                                        <span className="text-muted">
                                            We will send a 6-digit security code to your mobile phone as text (SMS)
                                        </span>
                                    </div>
                                    <div>
                                        {(!isLoading && !authenticationController.hasSMSAuthenticator) &&
                                            <>
                                                <small>
                                                    To recieve security codes via text message, please enroll your mobile number.
                                                </small>
                                                <div className="mt-2">
                                                    <Button
                                                        color='success'
                                                        onClick={toggleEnrollModal}>
                                                        <Text>Enroll Mobile Number</Text>
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        {(!isLoading && authenticationController.hasSMSAuthenticator) &&
                                            <>
                                                <div>
                                                    Number enrolled: <span className="text-success">{smsAuthenticator?.name}</span>
                                                </div>
                                                <div className="mt-4 text-muted">
                                                    <small>
                                                        To change this number you must first
                                                        <span className="text-secondary ml-1 cursor-pointer" onClick={toggleUnenrollModal}>unenroll</span> the current number.
                                                    </small>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </Col>
                                <Col xs={12} lg={6} className="p-4">
                                    <div className="mb-4">
                                        <strong>Email:</strong>
                                        <hr />
                                        <span className="text-muted">
                                            We will send a 6-digit security code to your email address
                                        </span>
                                    </div>
                                    <div>
                                        Email enrolled: <span className="text-success">{emailAuthenticator?.name}</span>

                                        {(!authenticationController.hasSMSAuthenticator) &&
                                            <InfoBox
                                                color="warning"
                                                className="mt-3">
                                                <strong>Please Note:</strong> If your agency has an email firewall (i.e. Mimecast) MFA emails can be 
                                                delayed and can expire before delivery. It is highly recommended in this case, that you enroll in 
                                                Text Message (SMS) authentication.
                                            </InfoBox>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Suspense fallback={'Loading...'}>
                    <EnrollMultiFactorModal
                        isOpen={showEnrollModal && !showConfirmEnrollmentModal}
                        onClose={() => setShowEnrollModal(false)}
                        enrollmentInitiated={enrollmentInitiated}
                    />

                    <UnenrollMultiFactorModal
                        isOpen={showUnenrollModal}
                        onClose={() => setShowUnenrollModal(false)}
                        authenticatorId={(smsAuthenticator) ? smsAuthenticator.id : ''}
                    />

                    <ConfirmEnrollMFA
                        isOpen={showConfirmEnrollmentModal}
                        onClose={() => setShowConfirmEnrollmentModal(false)}
                        phoneNumber={enrollmentNumber}
                    />
                </Suspense>
            </Row>
        </div>
    )
})

export {
    Security
}
import { makeAutoObservable, runInAction } from 'mobx'
import { decorate, inject, injectable } from '@evertel/di'
import { Api } from '@evertel/api'
import { SessionState } from '@evertel/session'
import { APIDataDevice } from '@evertel/types'

export type DrawerDropdowns = 'interagency' | 'agencywide' | 'team' | 'threads'

export type DrawerDropdownsState = {
    interagency: 'open',
    agencywide: 'open',
    team: 'open',
    threads: 'open'
}

const matchAgentItem = (string: string, data: any) => {
    let j = 0,
        regex,
        regexv,
        match,
        matches,
        version

    for (let i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i')
        match = regex.test(string)

        if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
            matches = string.match(regexv)
            version = ''

            if (matches) {
                if (matches[1]) {
                    matches = matches[1]
                }
            }

            if (matches) {
                matches = (matches as string).split(/[._]+/)
                for (j = 0; j < matches.length; j += 1) {
                    if (j === 0) {
                        version += matches[j] + '.'
                    } else {
                        version += matches[j]
                    }
                }
            } else {
                version = '0'
            }
            return {
                name: data[i].name,
                version: parseFloat(version)
            }
        }
    }

    return { name: 'unknown', version: 0 }
}


class DeviceState {
    platform = 'web'
    device: APIDataDevice = {}
    deviceId = 0
    deviceToken = ''
    drawerDropdownsState: DrawerDropdownsState = {
        interagency: 'open',
        agencywide: 'open',
        team: 'open',
        threads: 'open'
    }
    agent = header?.join(' ')
    os = matchAgentItem(this.agent, os)?.name
    browserName = matchAgentItem(this.agent, browser)?.name
    browserVersion = matchAgentItem(this.agent, browser)?.version
    osVersion = matchAgentItem(this.agent, os)?.version
    cookiesEnabled = navigator?.cookieEnabled
    browserLanguage = navigator?.language

    constructor(
        private api: Api,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    init = (platform: 'web' | 'mobile') => {
        this.platform = platform

        if (platform === 'web') {

            const storedDeviceToken = localStorage.getItem('deviceToken')
            if (storedDeviceToken) {
                this.deviceToken = storedDeviceToken
            } else {
                this.setDeviceToken(createDeviceToken())
            }

            // grab needed values from localStorage
            const savedDrawerDropdownsState = localStorage.getItem('drawerDropdownsState')
            const deviceId = localStorage.getItem('deviceId')

            if (savedDrawerDropdownsState) {
                this.drawerDropdownsState = JSON.parse(savedDrawerDropdownsState)
            }
            if (deviceId) {
                this.deviceId = parseInt(deviceId)
            }
        } else {
            // mobile local storage
        }

        this.fetchCurrentDevice()
    }

    fetchCurrentDevice = async () => {
        //this is NEVER triggered again so after a user logs in this won't be called?
        if (!this.deviceId || !this.session.currentUserId) return

        const device = await this.api.Routes.BlueUser.getDevicesById(this.session.currentUserId, this.deviceId)

        runInAction(() => {
            this.device = device
        })
    }

    setCurrentDeviceId = (deviceId: number) => {
        this.deviceId = deviceId

        if (this.platform === 'web') {
            localStorage.setItem('deviceId', this.deviceId?.toString())
        } else {
            // mobile local storage
        }
    }

    setDeviceToken(token: string) {
        this.deviceToken = token

        if (this.platform === 'web') {
            localStorage.setItem('deviceToken', this.deviceToken)
        } else {
            //mobile local storage
        }
    }

    updateDrawerDropdownsState = (dropdown: DrawerDropdowns, isOpen: boolean) => {
        this.drawerDropdownsState = {
            ...this.drawerDropdownsState,
            [dropdown]: (isOpen) ? 'open' : 'closed'
        }

        if (this.platform === 'web') {
            localStorage.setItem('drawerDropdownsState', JSON.stringify(this.drawerDropdownsState)?.toString())
        } else {
            // mobile local storage
        }
    }

    updateDeviceSettings = async (settings: any) => {
        const device = await this.api.Routes.BlueUser.putDevicesSettings(this.session.currentUserId, this.deviceId, settings)

        runInAction(() => {
            this.device = device
        })
    }

    destroy = () => {
        this.setCurrentDeviceId(0)
        // DO NOT CLEAR DEVICE TOKEN ON LOGOUT, IT'S NECESSARY FOR LOGIN!!!
        // this.deviceToken = ''
    }

    getSoundOptions = () => {
        return [
            { label: 'Alien Calling', file: 'alien_calling.mp3', value: 'alien_calling' },
            { label: 'Angry Alarm', file: 'angry_alarm.mp3', value: 'angry_alarm' },
            { label: 'Annoy', file: 'annoy.mp3', value: 'annoy' },
            { label: 'Arrival', file: 'arrival.mp3', value: 'arrival' },
            { label: 'Battle Drums', file: 'battle_drums.mp3', value: 'battle_drums' },
            { label: 'Beepy', file: 'beepy.mp3', value: 'beepy' },
            { label: 'Boing', file: 'boing.mp3', value: 'boing' },
            { label: 'Bonus', file: 'bonus.mp3', value: 'bonus' },
            { label: 'Chime In', file: 'chime_in.mp3', value: 'chime_in' },
            { label: 'Dingy Dong', file: 'dingy_dong.mp3', value: 'dingy_dong' },
            { label: 'Hello', file: 'hello.mp3', value: 'hello' },
            { label: 'I\'m Here', file: 'im_here.mp3', value: 'im_here' },
            { label: 'Knock Knock Bells', file: 'knock_knock_bells.mp3', value: 'knock_knock_bells' },
            { label: 'Plinky', file: 'plinky.mp3', value: 'plinky' },
            { label: 'Ring', file: 'ring.mp3', value: 'ring' },
            { label: 'Running Out', file: 'running_out.mp3', value: 'running_out' },
            { label: 'Siren', file: 'siren.mp3', value: 'siren' },
            { label: 'Success', file: 'success.mp3', value: 'success' },
            { label: 'Wake Up!', file: 'wake_up.mp3', value: 'wake_up' },
            { label: 'Water Alarm', file: 'water_alarm.mp3', value: 'water_alarm' },
            { label: 'Whoa!', file: 'whoa.mp3', value: 'whoa' },
            { label: 'Wow!', file: 'wow.mp3', value: 'wow' },
            { label: 'Zap', file: 'zap.mp3', value: 'zap' },
            { label: 'Zing', file: 'zing.mp3', value: 'zing' }
        ]
    }
}


const os = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
]

const browser = [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
]

const header = [
    navigator.platform,
    navigator.userAgent,
    navigator.appVersion,
    navigator.vendor
]

function createDeviceToken() {
    //gather browser info
    const nAgt = navigator.userAgent
    let browserName = navigator.appName
    let fullVersion = '' + parseFloat(navigator.appVersion)
    let majorVersion = parseInt(navigator.appVersion, 10)
    let nameOffset, verOffset, ix

    //in Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 6)
        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
            fullVersion = nAgt.substring(verOffset + 8)
        }

    //in MSIE, the true version is after "MSIE" in userAgent
    } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
        browserName = 'Microsoft Internet Explorer'
        fullVersion = nAgt.substring(verOffset + 5)

    //in Chrome, the true version is after "Chrome"
    } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
        browserName = 'Chrome'
        fullVersion = nAgt.substring(verOffset + 7)

    //in Safari, the true version is after "Safari" or after "Version"
    } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
        browserName = 'Safari'
        fullVersion = nAgt.substring(verOffset + 7)
        if ((verOffset = nAgt.indexOf('Version')) !== -1) { fullVersion = nAgt.substring(verOffset + 8) }

    //in Firefox, the true version is after "Firefox"
    } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
        browserName = 'Firefox'
        fullVersion = nAgt.substring(verOffset + 8)

    //in most other browsers, "name/version" is at the end of userAgent
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset)
        fullVersion = nAgt.substring(verOffset + 1)
        if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = navigator.appName
        }
    }

    //trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) !== -1) {
        fullVersion = fullVersion.substring(0, ix)
    }
    if ((ix = fullVersion.indexOf(' ')) !== -1) {
        fullVersion = fullVersion.substring(0, ix)
    }

    majorVersion = parseInt('' + fullVersion, 10)
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion)
        majorVersion = parseInt(navigator.appVersion, 10)
    }

    //console.log(fullVersion, majorVersion, browserName)

    const createGUID = function() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    }

    return ( 'Browser/' + browserName + '/' + createGUID())
}

decorate(injectable(), DeviceState)
decorate(inject(Api), DeviceState, 0)
decorate(inject(SessionState), DeviceState, 1)

export { DeviceState }
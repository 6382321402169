import { Agent } from '@evertel/types'

export default class Bot {
    constructor(private getAgent: () => Agent) {}

    postMessage = (body: unknown) => this.getAgent().post(`Bots/messages`, body)
    getMedia = (filter: { where: { id: { inq: any[] } } }) => this.getAgent().get(`Bots/media`, { params: { filter } })
    getMediaCount = (where: any) => this.getAgent().get(`Bots/media/count`, { params: { where } })
    uploadMedia = (meta: unknown) => this.getAgent().post(`Bots/media/upload`, meta)


    // campaigns
    getBotCampaigns = (filter: any) => this.getAgent().get(`BotCampaigns`, { params: { filter } })
    getBotCampaignsCount = (where: any) => this.getAgent().get(`BotCampaigns/count`, { params: { where } })
    createBotCampaign = (data: unknown) => this.getAgent().post(`BotCampaigns`, data)
    getBotCampaign = (id: number, filter: undefined) => this.getAgent().get(`BotCampaigns/${id}`, { params: { filter } })
    updateBotCampaign = (id: any, data: unknown) => this.getAgent().put(`BotCampaigns/${id}`, data)
    getBotCampaignPreview = (id: any, filter: any) => this.getAgent().get(`BotCampaigns/${id}/preview`, { params: { filter } })
    getBotCampaignPreviewUserCount = (id: any, where: any) => this.getAgent().get(`BotCampaigns/${id}/preview/count`, { params: { where } })
    runBotCampaign = (id: any) => this.getAgent().get(`BotCampaigns/${id}/run`)
    getBotCampaignMessages = (id: any, filter: any) => this.getAgent().get(`BotCampaigns/${id}/threadMessages`, { params: { filter } })
    getBotCampaignMessagesCount = (id: any, where: any) => this.getAgent().get(`BotCampaigns/${id}/threadMessages/count`, { params: { where } })
}

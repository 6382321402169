
import { APIDataInvite } from '@evertel/types'
import { makeAutoObservable, runInAction } from 'mobx'
import { debounce, uniqBy } from 'lodash'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'

class UserInvitesSearchController {
    invites: APIDataInvite[] = []
    invitesCount = 0
    userId = 0
    searchTerm = ''
    page = 1
    limit = 20
    isLoading = false
    isInfiniteScroll = true

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init(userId: number, isInfiniteScroll = true) {
        this.userId = userId
        this.invites = []
        this.invitesCount = 0
        this.page = 1
        this.searchTerm = ''
        this.isInfiniteScroll = isInfiniteScroll
    }

    setSearchTerm(value: string) {
        this.searchTerm = value
        this.page = 1
        this.invites = []
        this.invitesCount = 0
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    private debouncedSearchInternal = debounce(async () => {
        await this.search()
        return 
    }, 300)

    debouncedSearch = () => {
        this.isLoading = true
        return this.debouncedSearchInternal()
    }

    search = async () => {
        let count = this.invitesCount
        let results = []

        runInAction(() => {
            this.isLoading = true
        })

        // grab the total count of invites in this dept
        if (!this.invitesCount) {
            count = await this.fetchInvitesCount()
        }

        // stop from fetching if we already got them all
        if (count > this.invites?.length) {
            results = await this.api.Routes.BlueUser.getInvites(this.userId, this.filter)
        }

        runInAction(() => {
            this.invites = (this.isInfiniteScroll) ? uniqBy([...this.invites, ...results], 'id') : results
            this.isLoading = false
        })
    }

    async fetchInvitesCount() {
        const res = await this.api.Routes.BlueUser.getInvitesCount(this.userId, this.filter.where)

        runInAction(() => {
            this.invitesCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    loadNextPage() {
        this.page = this.page + 1
    }

    reset() {
        this.invites = []
        this.invitesCount = 0
        this.page = 1
        this.searchTerm = ''
        this.limit = 20
    }

    get filter() {
        return {
            where: {
                and: [
                    {
                        or: [
                            { to: { like: `%${this.searchTerm}%` } },
                            { status: { like: `%${this.searchTerm}%` } },
                            { type: { like: `%${this.searchTerm}%` } },
                            { content: { like: `%${this.searchTerm}%` } }
                        ]
                    }
                ]
            },
            order: 'createdTime DESC',
            limit: this.limit,
            skip: (this.page - 1) * this.limit
        }
    }
}

decorate(injectable(), UserInvitesSearchController)
decorate(inject(Api), UserInvitesSearchController, 0)

export { UserInvitesSearchController }
import { Component } from 'react'
import { Col, Container, Row } from '@evertel/web/ui'
class Version extends Component {
    render() {
        return (
            <div className="app flex-row align-items-center" style={{height: '100vh', overflow: 'auto'}}>
                <Row align='center' valign='center' className="p-4" style={{ minHeight: '100%', margin: 'auto' }}>
                    <div className="text-center">
                        <h3 className="pt-3">Build info:</h3>
                        <h4 className="text-muted">Version: {process.env.VERSION}</h4>
                        <h4 className="text-muted">Build number: {process.env.BUILD_NUMBER}</h4>
                        <h4 className="text-muted">Commit: {process.env.COMMIT}</h4>
                        <h4 className="text-muted">Branch: {process.env.BRANCH}</h4>
                        <h4 className="text-muted">Build date: {process.env.BUILD_DATE}</h4>
                        <h4 className="text-muted">Env: {process.env.NODE_ENV}</h4>
                    </div>
                </Row>
            </div>
        )
    }
}

export default Version

import React, { forwardRef, HTMLAttributes, ReactNode } from 'react'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLDivElement> {
    alignment?: 'top' | 'center'
    fullscreen?: boolean | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
    scrollable?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
    children: ReactNode
    className?: string
}

const ModalDialog = forwardRef<HTMLDivElement, Props>(({
    alignment,
    fullscreen,
    scrollable,
    size = 'lg',
    children,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames(
        'modal-dialog',
        {
            'modal-dialog-centered': alignment === 'center',
            [typeof fullscreen === 'boolean' ? 'modal-fullscreen' : `modal-fullscreen-${fullscreen}-down`]: fullscreen,
            'modal-dialog-scrollable': scrollable,
            [`modal-${size}`]: size,
        },
        className,
    )

    return (
        <div
            ref={ref}
            className={_className}
            {...otherProps}>
            {children}
        </div>
    )
})

export default ModalDialog
ModalDialog.displayName = 'ModalDialog'

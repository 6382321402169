import React, { useContext, useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { components } from 'react-select'
// evertel
import {
    Button,
    Modal,
    ModalBody,
    Row,
    Col,
    Icon,
    Text,
    ModalHeader,
    ModalFooter,
    PeopleSearchSelect,
    FloatingMenu,
    MenuItem
} from '@evertel/web/ui'
import { getRole } from '@evertel/utils'
import { RoomContext } from '../../RoomContext'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { UserTileRawData } from '@evertel/web/user'
import { useInvite } from '@evertel/web/invites'

interface AddRommMembersModalProps {
    visible: boolean,
    onClose: () => void
}


const AddRoomMembersModal: React.FC<AddRommMembersModalProps> = observer(({
    visible,
    onClose
}) => {

    const { roomController } = useContext(RoomContext)
    const { openInvite } = useInvite()
    const room = roomController.room
    const windowSize = useRef({width: window.innerWidth, height: window.innerHeight})
    const session = useService(SessionState, [])

    useEffect(() => {
        //AnalyticsStore.logModalView('room-invite-guests')
    }, [])

    const onInviteGuests = () => {
        openInvite('guest', [{
            label: room?.name,
            value: room?.id
        }])
    }

    if (!room) return null

    return (
        <Modal 
            visible={visible}
            onClose={onClose}
            style={{minHeight: 'calc(100vh - 4rem)'}}
            size="lg">
            <ModalHeader>
                <Row
                    align="left"
                    className="w-100">
                    <Text tag="h3" heavy>
                        Add People to
                        <Icon
                            name="hashtag"
                            color="muted"
                            className="pl-2 pr-1"
                            size={25}
                        />
                        {room.name}
                    </Text>
                </Row>
            </ModalHeader>
            <ModalBody>           
                <Row>
                    <Col valign="top">
                        {(visible) &&
                            <PeopleSearchSelect
                                searchOnly={true}
                                searchScope="department"
                                whereFilter={{ emailVerified: true }}
                                departmentId={session.selectedDepartmentId}
                                isMulti={false}
                                className="select-inline search no-bg mt-2"
                                menuIsOpen
                                //@ts-expect-error it's a string, expected
                                maxMenuHeight="calc(80vh - 220px)"
                                customComponents={{ Option: User }}
                                autoFocus
                                customProps={{
                                    departmentId: session.selectedDepartmentId,
                                    roomController
                                }} 
                                selected={[]} 
                                onSelect={() => { /* */ }}
                            />
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="bg-light">
                <Row>
                    <Col
                        xs={1}
                        align="right"
                        valign="top"
                        className="p-0">
                        <Icon
                            name="person-walking-dashed-line-arrow-right"
                            color="info"
                            className="pt-1"
                            size={30}
                        />
                    </Col>
                    <Col xs={8}>
                        <Text
                            tag="h4"
                            bold>
                            Need to bring people in from outside your agency?
                        </Text>
                        <Text tag="h5">
                            Invite people from other agencies, outside vendors, federal partners, etc.
                        </Text>
                    </Col>
                    <Col
                        xs={3}
                        align="right"
                        valign="center">
                        <Button
                            color="info"
                            onClick={onInviteGuests}>
                            Invite Guests
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    )
})

const User = observer(({
    data,
    selectProps,
    ...otherProps
}) => {

    const [isLoading, setIsLoading] = useState(false)

    const { departmentId, roomController } = selectProps.customProps
    const usersDepartmentsAccess = data.departmentsAccess?.find(da => da.departmentId === departmentId) || {}
    const isGuest = !!(getRole(usersDepartmentsAccess?.roles) === 'guest')
    // check if they are already a member or manager of the room
    const isMember = roomController?.memberIds?.includes(data.id)
    const isManager = roomController?.managerIds?.includes(data.id)

    const addUser = async (role = 'member', userId: number) => {
        setIsLoading(true)

        if (role === 'manager') {
            await roomController.addManager(userId)
        } else {
            await roomController.addMember(userId)
        }

        setIsLoading(false)
    }

    return (
        // @ts-ignore
        <components.Option {...otherProps}>
            <UserTileRawData
                user={data}
                imageWidth={40}
                className={'py-1'}
                isGuest={isGuest}
                subComponent={
                    <Text
                        size="small"
                        color="muted"> 
                        {usersDepartmentsAccess?.positionString}
                        {(isGuest) && ` @ ${usersDepartmentsAccess?.department?.shortName || usersDepartmentsAccess?.department?.name}`}
                    </Text>
                }
                rightColComponent={
                    <AddButton
                        isMember={isMember}
                        isManager={isManager}
                        isGuest={isGuest}
                        onClick={addUser}
                        userId={data?.id}
                        isLoading={isLoading}
                    />
                }
            />
        </components.Option>
    )
})

const AddButton = observer(({
    userId,
    isManager,
    isMember,
    isGuest,
    onClick,
    isLoading
}) => {
    const getButtonColor = () => {
        if (isManager || isMember) return 'secondary'
        return 'success'
    }

    const getButtonText = () => {
        if (isManager) return 'Manager'
        if (isMember) return 'Member'
        return 'Add'
    }

    return (
        <FloatingMenu
            label="User Role"
            disabled={isLoading}
            className={`btn btn-${getButtonColor()} text-nowrap`}
            renderLabel={() => (
                <>
                    {getButtonText()}
                    {/* <Icon
                        style={{width: 25}}
                        className='d-inline-block ml-2'
                        name={isManager ? 'user-tie' : isMember ? 'user' : 'user-plus'}
                        color="white"
                    /> */}
                    <Icon
                        name="caret-down"
                        type="solid"
                        color="white"
                        className="ml-1"
                    />
                </>
            )}
        >
            <MenuItem
                label={isMember ? 'Already a Member' : isManager ? 'Make a Member' : 'Add as Member'}
                onClick={() => onClick('member', userId)}
                disabled={isMember}
            />
            <MenuItem
                label={isMember ? 'Make a Manager' : isManager ? 'Already a Manager' : 'Add as Manager'}
                onClick={() => onClick('manager', userId)}
                disabled={isManager}
            />
        </FloatingMenu>
    )
})

export { AddRoomMembersModal }

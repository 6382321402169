import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useService } from '@evertel/di'
import { GlobalSchemaCategoriesController } from '@evertel/document'
import { APIDataDocumentSchemaCategory } from '@evertel/types'
import { Category } from './Category'

interface GlobalCategoryPickerProps {
    onClickCategory: (category: APIDataDocumentSchemaCategory) => void,
    selectedCategoryId: number
}

const GlobalCategoryPicker: React.FC<GlobalCategoryPickerProps> = observer(({
    onClickCategory,
    selectedCategoryId
}) => {

    const controller = useService(GlobalSchemaCategoriesController, [])

    useEffect(() => {
        controller.init()
    }, [])

    return (
        <>
            {controller.categories?.map((category: APIDataDocumentSchemaCategory) =>
                <Category
                    category={category}
                    onSelect={onClickCategory}
                    selectedCategoryId={selectedCategoryId}
                />
            )}
        </>
    )
})


export { GlobalCategoryPicker }

import { makeAutoObservable } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { DocumentSchemaStore } from '@evertel/stores'
import { APIDataDocumentSchema } from '@evertel/types'
import { Api } from '@evertel/api'


class CloneSchemaController {

    constructor(
        private documentSchemaStore: DocumentSchemaStore,
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    cloneSchema = async (schemaId: number, departmentId: number, name: string): Promise<APIDataDocumentSchema> => {
        if (!schemaId) return null

        // post clone
        const schema = await this.api.Routes.DocumentSchema.postClone(schemaId, departmentId.toString(), name)

        // save resulting schemas in the documentSchemaStore
        if (schema) this.documentSchemaStore.update(schema)

        return schema
    }
}

decorate(injectable(), CloneSchemaController)
decorate(inject(DocumentSchemaStore), CloneSchemaController, 0)
decorate(inject(Api), CloneSchemaController, 1)

export { CloneSchemaController }


import { observer } from 'mobx-react'
// evertel
import { Icon, useUI, FloatingMenu, MenuItem, MenuHeader } from '@evertel/web/ui'
import { HTMLAttributes } from 'react'
import { useService } from '@evertel/di'
import { CurrentUserController } from '@evertel/blue-user'

type DisplayOptions = 'medium'|'condensed'

type SortOptions = 'newest'|'alpha'|'oldest'|'unread'

interface DrawerNavItemLinkProps extends HTMLAttributes<HTMLDivElement> {
    onClick?: () => void
}


const HeaderButtonSort: React.FC<DrawerNavItemLinkProps> = observer(({
    ...otherProps
}) => {

    const currentUserController = useService(CurrentUserController, [])
    const { addToast } = useUI()

    const userMeta = currentUserController.user?.meta as any

    const currentRoomSort = userMeta?.appearance?.drawerRoomSort || 'unread'
    const currentThreadSort = userMeta?.appearance?.drawerThreadSort || 'unread'
    const currentDisplay = userMeta?.appearance?.drawerDisplay || 'medium'

    const changeRoomSort = async (selected: SortOptions) => {
        if (currentRoomSort !== selected) {
            try {
                await currentUserController.update({ meta: { appearance: { drawerRoomSort: selected }} })

            } catch (error: any) {
                addToast({
                    color: 'danger',
                    message: error.message
                })
            }
        }
    }

    const changeThreadSort = async (selected: SortOptions) => {
        if (currentThreadSort !== selected) {
            try {
                await currentUserController.update({ meta: { appearance: { drawerThreadSort: selected }} })

            } catch (error: any) {
                addToast({
                    color: 'danger',
                    message: error.message
                })
            }
        }
    }

    const changeDisplay = async (selected: DisplayOptions) => {
        if (currentDisplay !== selected) {
            try {
                await currentUserController.update({ meta: { appearance: { drawerDisplay: selected }} })

            } catch (error: any) {
                addToast({
                    color: 'danger',
                    message: error.message
                })
            }
        }
    }

    return (
        <FloatingMenu
            label="Sort"
            renderLabel={() => (
                <Icon
                    name="sort-amount-down"
                    color="muted"
                    size={16}
                />
            )}
        >
            <MenuHeader label="Display:" />
            <MenuItem
                label="Medium"
                icon={{
                    name: currentDisplay === 'medium' ? 'check-square' : 'line-height',
                    color: currentDisplay === 'medium' ? 'success' : undefined,
                    type: currentDisplay === 'medium' ? 'solid' : 'light'
                }}
                onClick={() => changeDisplay('medium')}
            />
            <MenuItem
                label="Condensed"
                icon={{
                    name: currentDisplay === 'condensed' ? 'check-square' : 'arrows-to-line',
                    color: currentDisplay === 'condensed' ? 'success' : undefined,
                    type: currentDisplay === 'condensed' ? 'solid' : 'light'
                }}
                onClick={() => changeDisplay('condensed')}
            />
            
            <MenuHeader label="Sort Rooms By:" />
            <MenuItem
                label="Name (Alphabetical)"
                icon={{
                    name: currentRoomSort === 'alpha' ? 'check-square' : 'arrow-down-a-z',
                    color: currentRoomSort === 'alpha' ? 'success' : undefined,
                    type: currentRoomSort === 'alpha' ? 'solid' : 'light'
                }}
                onClick={() => changeRoomSort('alpha')}
            />
            <MenuItem
                label="Unreads First"
                icon={{
                    name: currentRoomSort === 'unread' ? 'check-square' : 'bell-exclamation',
                    color: currentRoomSort === 'unread' ? 'success' : undefined,
                    type: currentRoomSort === 'unread' ? 'solid' : 'light'
                }}
                onClick={() => changeRoomSort('unread')}
            />
            <MenuItem
                label="Newest First"
                icon={{
                    name: currentRoomSort === 'newest' ? 'check-square' : 'arrow-up-short-wide',
                    color: currentRoomSort === 'newest' ? 'success' : undefined,
                    type: currentRoomSort === 'newest' ? 'solid' : 'light'
                }}
                onClick={() => changeRoomSort('newest')}
            />
            <MenuItem
                label="Oldest First"
                icon={{
                    name: currentRoomSort === 'oldest' ? 'check-square' : 'arrow-down-wide-short',
                    color: currentRoomSort === 'oldest' ? 'success' : undefined,
                    type: currentRoomSort === 'oldest' ? 'solid' : 'light'
                }}
                onClick={() => changeRoomSort('oldest')}
            />
            
            <MenuHeader label="Sort DMs By:" />
            <MenuItem
                label="Name (Alphabetical)"
                icon={{
                    name: currentThreadSort === 'alpha' ? 'check-square' : 'arrow-down-a-z',
                    color: currentThreadSort === 'alpha' ? 'success' : undefined,
                    type: currentThreadSort === 'alpha' ? 'solid' : 'light'
                }}
                onClick={() => changeThreadSort('alpha')}
            />
            <MenuItem
                label="Unreads First"
                icon={{
                    name: currentThreadSort === 'unread' ? 'check-square' : 'bell-exclamation',
                    color: currentThreadSort === 'unread' ? 'success' : undefined,
                    type: currentThreadSort === 'unread' ? 'solid' : 'light'
                }}
                onClick={() => changeThreadSort('unread')}
            />
            <MenuItem
                label="Newest First"
                icon={{
                    name: currentThreadSort === 'newest' ? 'check-square' : 'arrow-up-short-wide',
                    color: currentThreadSort === 'newest' ? 'success' : undefined,
                    type: currentThreadSort === 'newest' ? 'solid' : 'light'
                }}
                onClick={() => changeThreadSort('newest')}
            />
            <MenuItem
                label="Oldest First"
                icon={{
                    name: currentThreadSort === 'oldest' ? 'check-square' : 'arrow-down-wide-short',
                    color: currentThreadSort === 'oldest' ? 'success' : undefined,
                    type: currentThreadSort === 'oldest' ? 'solid' : 'light'
                }}
                onClick={() => changeThreadSort('oldest')}
            />
        </FloatingMenu>
    )
})

export { HeaderButtonSort }

import React, { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface ButtonGroupProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  /** Size the component small or large. */
  size?: 'sm'|'lg'
  /** Create a set of buttons that appear vertically stacked rather than horizontally. Split button dropdowns are not supported here. */
  vertical?: boolean
}

const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(({
    children,
    className,
    size,
    vertical,
    ...otherProps
}, ref) => {

    const _className = classNames(
        {
            [`btn-group-${size}`]: size,
            'btn-group-vertical': vertical,
            'btn-group': !vertical
        },
        className,
    )

    return (
        <div
            ref={ref}
            className={_className}
            {...otherProps}>
            {children}
        </div>
    )
})

export { ButtonGroup }
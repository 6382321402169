export class ApiError extends Error {
    constructor(
        message: string,
        public url: string,
        public status: number,
        public code?: string,
        public override stack?: string
    ) {
        super(message)
    }
}

import React, { forwardRef, HTMLAttributes, ReactNode, ElementType } from 'react'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
    className?: string
}

const ModalContent = forwardRef<HTMLDivElement, Props>(({
    children,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('modal-content', className)

    return (
        <div
            ref={ref}    
            className={_className}
            {...otherProps}>
            {children}
        </div>
    )
})

export default ModalContent
ModalContent.displayName = 'ModalContent'

import React, { forwardRef, HTMLAttributes, useRef, useState } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { useForkedRef } from '@evertel/hooks'

interface CollapseProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  /** Set horizontal collapsing to transition the width instead of height. */
  horizontal?: boolean
  onHide?: () => void
  onShow?: () => void
  visible?: boolean
}

const Collapse = forwardRef<HTMLDivElement, CollapseProps>(({
    children,
    className,
    horizontal,
    onHide,
    onShow,
    visible,
    ...otherProps
}, ref) => {

    const [height, setHeight] = useState<number>()
    const [width, setWidth] = useState<number>()
    const collapseRef = useRef<HTMLDivElement>(null)
    const forkedRef = useForkedRef(ref, collapseRef)

    const getTransitionClass = (state: string) => {
        return state === 'entering'
            ? 'collapsing'
            : state === 'entered'
            ? 'collapse show'
            : state === 'exiting'
            ? 'collapsing'
            : 'collapse'
    }

    const onEntering = () => {
        onShow && onShow()

        if (horizontal) {
            collapseRef.current && setWidth(collapseRef.current.scrollWidth)
            return
        }
        collapseRef.current && setHeight(collapseRef.current.scrollHeight)
    }

    const onEntered = () => {
        if (horizontal) {
            setWidth(0)
            return
        }
        setHeight(0)
    }

    const onExit = () => {
        if (horizontal) {
            collapseRef.current && setWidth(collapseRef.current.scrollWidth)
            return
        }
        collapseRef.current && setHeight(collapseRef.current.scrollHeight)
    }

    const onExiting = () => {
        onHide && onHide()
        if (horizontal) {
            setWidth(0)
            return
        }
        setHeight(0)
    }

    const onExited = () => {
        if (horizontal) {
            setWidth(0)
            return
        }
        setHeight(0)
    }

    const _className = classNames(
        {
            'collapse-horizontal': horizontal
        },
        className
    )

    return (
        <CSSTransition
            in={visible}
            nodeRef={collapseRef}
            onEntering={onEntering}
            onEntered={onEntered}
            onExit={onExit}
            onExiting={onExiting}
            onExited={onExited}
            timeout={350}>
            {(state) => {
                const transitionClass = getTransitionClass(state)
                const currentHeight = height === 0 ? null : { height }
                const currentWidth = width === 0 ? null : { width }
                return (
                    <div
                        ref={forkedRef}
                        className={classNames(_className, transitionClass)}
                        style={{
                            ...currentHeight,
                            ...currentWidth
                        }}
                        {...otherProps}>
                        {children}
                    </div>
                )
            }}
        </CSSTransition>
    )
})

export { Collapse }

import { injectable, decorate, inject } from '@evertel/di'
import { APIDataMedia } from '@evertel/types'
import { Api } from '@evertel/api'
import { MediaVersionResolver } from './MediaVersionResolver'
import { makeAutoObservable } from 'mobx'

class MediaBlobber {
    constructor(
        private api: Api,
        private versionResolver: MediaVersionResolver
    ) {
        makeAutoObservable(this)
    }

    public resolveBlob = async (
        media: APIDataMedia,
        width?: number,
        height?: number
    ): Promise<Blob> => {

        const versions = (media.meta as {versions: Array<{width: number, height: number, id: string}>}).versions

        let url

        if (versions && width && height) {
            const i = this.versionResolver.resolve(
                width,
                height,
                versions
            )
            url = `${media.url as string}?ver=${versions[i].id}`

        } else {
            url = media.url as string
        }

        const remote = await this.api.agent.getAsRaw(url, { baseURL: '', responseType: 'blob' })

        return remote.data
    }
}

decorate(injectable(), MediaBlobber)
decorate(inject(Api), MediaBlobber, 0)
decorate(inject(MediaVersionResolver), MediaBlobber, 1)

export {
    MediaBlobber
}

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { HeaderAlert, Text, useUI } from '@evertel/web/ui'

const NetworkAlert: React.FC = observer(() => {

    const { closeAlertBar } = useUI()

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnlineStatus = () => {
          setIsOnline(navigator.onLine);
        };
    
        window.addEventListener('online', handleOnlineStatus);
        window.addEventListener('offline', handleOnlineStatus);
    
        return () => {
          window.removeEventListener('online', handleOnlineStatus);
          window.removeEventListener('offline', handleOnlineStatus);
        };
    }, []);

    return (
        <HeaderAlert isOpen={!isOnline} color={'red'} onClick={() => closeAlertBar()}>
            <Text>
                No Internet Connection
            </Text>
        </HeaderAlert>
    )
})

export { NetworkAlert }
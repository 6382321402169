import { useState, createContext } from 'react'

export const EvertelContext = createContext()

const ContextProvider = ({children}) => {

    // Confirm dialogue
    const [confirm, setConfirm] = useState({
        title: '',
        message: '',
        isOpen: false,
        proceed: null,
        cancel: null,
        cancelButton: null,
        acceptButton: null,
        headerClassName: null
    })


    return (
        <EvertelContext.Provider value={[confirm, setConfirm]}>
            {children}
        </EvertelContext.Provider>
    )
}

export default ContextProvider
export const EvertelContextConsumer = EvertelContext.Consumer

import { makeAutoObservable, runInAction } from 'mobx'
import { decorate, injectable } from '@evertel/di'
import { isExtensionBanned, getFileExt, fileToUploadableMedia } from '@evertel/utils'
import { UploadableMedia } from '@evertel/media'

class DocumentMediaWriteWidgetController {
    loaded = false
    uploaded: Map<number, number> = new Map()
    uploadQueue: Map<number, UploadableMedia> = new Map()
    deleteQueue: Set<number> = new Set()

    constructor(
    ) {
        makeAutoObservable(this)
    }

    init = (mediaIds: Array<number>) => {
        // @ts-expect-error this is here for bad data that has been seen in old documents.
        if (mediaIds?.uploaded) mediaIds = mediaIds.uploaded

        // TODO: Patch as workaround for corrupt media values
        if (!Array.isArray(mediaIds)) {
            runInAction(() => {
                this.loaded = true
            })

            return
        }

        mediaIds.forEach((id) => {
            runInAction(() => {
                this.uploaded.set(
                    id,
                    id
                )
            })
        })

        runInAction(() => {
            this.loaded = true
        })
    }

    deleteUploaded = (mediaId: number): void => {
        // remove media from uploaded array
        this.uploaded.delete(mediaId)

        // add mediaId to the list of media to delete on save
        this.deleteQueue.add(
            mediaId
        )
    }

    addToUploadQueue = async (files: Array<File>) => {
        const rejectedExtensions = new Set()

        await Promise.all(
            [...files].reverse().map(async (file, index) => {
                //looping through it backwards so items can be deleted from the array without
                //messing up what's being looped through
                const ext = getFileExt(file)
    
                if (isExtensionBanned(ext)) {
                    files.splice(files.length - 1 - index, 1)
                    rejectedExtensions.add(ext)
                } else {
                    const id = (Date.now() + Math.random())
                    const uploadableMedia = await fileToUploadableMedia(file)
    
                    this.uploadQueue.set(id, uploadableMedia)
                }
            })
        )
        if (rejectedExtensions.size) {
            throw new Error(`Unsupported file format found: ${[...rejectedExtensions].join(', ')}`)
        }
    }

    deleteFromUploadQueue = (mediaId: number): void => {
        this.uploadQueue.delete(
            mediaId
        )
    }

    get mediaArrays() {
        return {
            uploaded: [...this.uploaded.values()],
            uploadQueue: [...this.uploadQueue.values()],
            deleteQueue: [...this.deleteQueue.values()]
        }
    }
}

decorate(injectable(), DocumentMediaWriteWidgetController)

export {
    DocumentMediaWriteWidgetController
}

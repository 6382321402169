import { makeAutoObservable, runInAction } from 'mobx'
import { decorate, inject, injectable } from 'inversify'

import { Api } from '@evertel/api'
import { LogoutImplementation } from './LogoutImplementation'
import { SessionState } from '@evertel/session'
import { PushService } from '@evertel/push'
import { DeviceState } from '@evertel/device'
import { StoreManager } from '@evertel/stores'



class LogoutService {
    interceptorInitialized = false

    constructor(
        private api: Api,
        private logoutImplementation: LogoutImplementation,
        private pushService: PushService,
        private sessionState: SessionState,
        private deviceState: DeviceState,
        private storeManager: StoreManager
    ) {
        makeAutoObservable(this)
    }


    onLogoutCallback?: () => void

    init() {
        this.initializeInterceptor()
    }

    initializeInterceptor() {
        if (this.interceptorInitialized) return
    
        this.api.addResponseInterceptor(
            null,
            async (error: any) => {
                if (
                    error.status !== 401
                    || !error.url
                    || error.url.endsWith('/testAccess')
                ) {
                    return Promise.reject(error)
                }

                if (this.sessionState.currentUserId) {
                    try {
                        await this.api.Routes.BlueUser.getTestAccess()
                    } catch (error: any) {
                        if (error.status === 401) {
                            await this.logout()
                        }
                    }
                }
                return Promise.reject(error)
            }
        )

        this.interceptorInitialized = true
    }
    // Set callback to notify when logout is called, in web it triggers a navigation to /login after logout() is called
    setOnLogoutCallback(onLogoutCb: () => void) {
        this.onLogoutCallback = onLogoutCb
    }

    async logout() {
        try {

            //we only care that they're all attempted, it doesn't matter if one fails
            await Promise.allSettled([
                this.pushService.deletePushToken(),
                this.logoutImplementation.logout(),
                this.api.Routes.BlueUser.postLogout()
            ])

            // Clear out auth token
            localStorage.removeItem('authorization')
            this.api.deleteAuthToken()

        } catch (e) {
            if (!this.api.isProduction) console.log('error', e)
        } finally {
            this.sessionState.destroy()
            this.deviceState.destroy()
            this.storeManager.clearAllStores()

            if (this.onLogoutCallback) this.onLogoutCallback()
        }

    }

    async logoutAllDevices() {
        try {
            this.api.Routes.BlueUser.postLogoutOtherDevices(this.sessionState.currentUserId)
        } finally {
            this.logout()
        }
    }

}

decorate(injectable(), LogoutService)
decorate(inject(Api), LogoutService, 0)
decorate(inject(LogoutImplementation), LogoutService, 1)
decorate(inject(PushService), LogoutService, 2)
decorate(inject(SessionState), LogoutService, 3)
decorate(inject(DeviceState), LogoutService, 4)
decorate(inject(StoreManager), LogoutService, 5)

export { LogoutService }
import React, { forwardRef, HTMLAttributes, ReactNode } from 'react'
import classNames from 'classnames'
import { Colors } from '../prop-types'

interface ContentPlaceholderProps extends HTMLAttributes<HTMLDivElement|HTMLSpanElement> {
    width?: number|string
    height?: number|string
    color?: Colors
    shape?: 'rounded'|'react'|'circle'
    className?: string,
    style?: object,
    children?: ReactNode
}


const ContentPlaceholder = forwardRef<HTMLDivElement, ContentPlaceholderProps>(({
    width = '100%',
    height = 13,
    color = 'gray200',
    shape = 'rounded',
    className,
    style = {},
    children,
    ...otherProps
}, ref) => {

    const _className = classNames(
        'content-placeholder',
        'soft-pulse',
        {
            [`bg-${color}`]: color
        },
        shape,
        className
    )

    const borderRadius = (shape === 'circle') ? {borderRadius: '50%'} : {}

    const combinedStyle = {
        width,
        height,
        ...borderRadius,
        ...style // This will merge and overwrite as needed
    }

    return (
        <div
            ref={ref}
            className={_className}
            style={combinedStyle}
            {...otherProps}>
            {children}
        </div>
    )
})

export { ContentPlaceholder }

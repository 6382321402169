import React, { useEffect, useState, Suspense } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'

// evertel
import routes from '../../routes.js'
import { useService } from '@evertel/di'
import { SessionState, IdleTimerService } from '@evertel/session'
import { LogoutService } from '@evertel/logout'
import { Drawer, LeftGutter } from '@evertel/web/drawer'
import { Header } from '@evertel/web/header'
import { Col, useUI } from '@evertel/web/ui'
import { CurrentUserPollService, UnreadCountsState } from '@evertel/blue-user'
import { InviteStore } from '../../stores'

const EvertelLayout = observer(({
    ...props
}) => {

    const { addToast } = useUI()

    const history = useHistory()
    const location = useLocation()
    const sessionState = useService(SessionState, [])
    const logoutService = useService(LogoutService, [])
    const idleTimer = useService(IdleTimerService, [])
    const currentUserPollService = useService(CurrentUserPollService, [])
    const unreadCounts = useService(UnreadCountsState, [])

    useState(() => {
        logoutService.init()
        logoutService.setOnLogoutCallback(() => {
            // Force a full page reload and navigate to the login page
            history.push('/login')
            window.location.reload()
        })

        if (!sessionState.currentUserId) {
            logoutService.logout()
        }
    })

    useEffect(() => {
        if (!sessionState.currentUserId) return

        idleTimer.init()
        idleTimer.setOnIdleCallback(() => logoutService.logout())
        currentUserPollService.startPoll()
        unreadCounts.startPoll()
        currentUserPollService.fetchUserDetails().then(() => {
            InviteStore.consumePendingInvite().finally(() => {
                //the invite could have been consumed during NavigationStore.restoreNavigation
                //in which case the error wouldn't have been displayed
                if (InviteStore.error) {
                    addToast({
                        message: InviteStore.error?.message || InviteStore.error,
                        color: 'danger'
                    })
                    InviteStore.error = null
                }
            })
        })

        //want to make sure the newly loaded page is part of the history
        sessionState.updateNavigationHistory(history.location)
        const unListenToHistoryChange = history.listen((location) => {
            sessionState.updateNavigationHistory(location)
        })

        return function defaultLayoutCleanup() {
            idleTimer.destroyTimers()
            currentUserPollService.stopPoll()
            unreadCounts.stopPoll()
            unListenToHistoryChange()
        }

    }, [sessionState.currentUserId])

    //prevent blank page
    if (location.pathname === '/') {
        history.replace('/landing')
    }

    return (
        <div className="app-wrap">
            <LeftGutter />
            <Drawer {...props} />
            <Col valign="top" className="canvas">
                <Header />
                <div className="page">
                    <Suspense fallback={<div className="m-4">Loading...</div>}>
                        <Switch>
                            {routes?.map((route, idx) => {
                                return route.component ? (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={(props) => (
                                            <route.component {...props} />
                                        )}
                                    />
                                ) : null
                            })}
                        </Switch>
                    </Suspense>
                </div>
            </Col>
            <div id='slide-panel-root'></div>
        </div>
    )
})

export default EvertelLayout
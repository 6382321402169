import React, { useEffect, useState, useCallback } from 'react'
import { Label, Input, Text, Row, Col, Icon, Dropdown, Button } from '@evertel/web/ui'
import { DocumentSchemaSearchSelect } from './DocumentSchemaSearchSelect'
import { DocumentDepartmentPicker } from './DocumentDepartmentSearchSelect'
import { DocumentSearchFilter } from '@evertel/document'
import { SessionState } from '@evertel/session'
import { useService } from '@evertel/di'

interface DocumentSearchFormProps {
    filter: DocumentSearchFilter,
    onSearch: (arg0: DocumentSearchFilter) => void;
}

const DocumentSearchForm: React.FC<DocumentSearchFormProps> = ({
    filter,
    onSearch,
    ...otherProps
}) => {

    const session = useService(SessionState, [])

    const [mode, setMode] = useState((filter?.state === 'archived' || filter?.by || filter?.schemaId) ? 'advanced' : 'basic') // basic|advanced

    const minWidth = 200

    useEffect(() => {
        if (mode === 'basic') {
            // filter
        }
    }, [mode])

    const toggleAdvanced = useCallback(() => {
        if (mode === 'basic') {
            setMode('advanced')
        } else {
            setMode('basic')
            if (filter.state !== 'published' || filter.by || filter.schemaId) {
                onSearch({
                    state: 'published',
                    by: undefined,
                    schemaId: undefined
                })
            }
        }
    }, [filter, mode])

    return (
        <div
            className="py-2 px-3 mb-3 border rounded position-relative"
            {...otherProps}
        >
            {/* {JSON.stringify(filter)} */}
            <Row className="flex-wrap">
                <Col sm="4" className="pr-2 mb-2" style={{ minWidth: minWidth }}>
                    <Label text='Ownership'>
                        <Dropdown
                            value={filter.access}
                            options={[
                                {label: 'Created by me', value: 'owner'}
                            ]}
                            onChange={(selected: any) => {
                                onSearch({access: selected?.value})
                            }}
                            isClearable={true}
                        />
                    </Label>
                </Col>
                <Col sm="4" className="pr-2 mb-2" style={{ minWidth: minWidth, flexBasis: '50%' }}>
                    <Label text='Keyword'>
                        <Input
                            value={filter.text}
                            placeholder="Search..."
                            onChange={(e) => {
                                onSearch({text: e.target.value ? e.target.value : undefined})
                            }}
                        />
                    </Label>
                </Col>

                {(mode === 'advanced') &&
                    <>
                        <Col sm="4" className="pr-2 mb-2" style={{ minWidth: minWidth }}>
                            <Label text='Agency'>
                                <DocumentDepartmentPicker
                                    departmentId={(filter.by?.type==='department') ? filter.by?.id : null}
                                    onSelect={(value) => {
                                        if (value === filter.by?.id) return
                                        onSearch({
                                            by: ((value) ? { type: 'department', id: value } : undefined),
                                            //we also want to reset the schemaId if the agency changes
                                            schemaId: undefined
                                        })
                                    }}
                                    isClearable={true}
                                />
                            </Label>
                        </Col>
                        <Col sm="4" className="pr-2 mb-2" style={{ minWidth: minWidth }}>
                            <Label text='Status'>
                                <Dropdown
                                    value={filter.state}
                                    options={[
                                        {label: 'Published / Active', value: 'published'},
                                        {label: 'Archived / Inactive', value: 'archived'}
                                    ]}
                                    onChange={(selected: any) => {
                                        if (selected?.value === filter.state) return

                                        onSearch({state: selected?.value})
                                    }}
                                    isClearable={false}
                                />
                            </Label>
                        </Col>
                        <Col sm="4" lg="3" className="pr-2 mb-2 flex-grow-0" style={{ minWidth: minWidth }}>
                            <Label text='Type'>
                                <DocumentSchemaSearchSelect
                                    schemaId={filter.schemaId}
                                    by={filter.by??undefined}
                                    // by={{type: 'department', id: (filter.by?.id) ? filter.by.id : session.selectedDepartmentId}}
                                    onSelect={(value) => {
                                        if (value === filter.schemaId) return
                                        onSearch({schemaId: value})
                                    }}
                                    isClearable={true}
                                />
                            </Label>
                        </Col>
                    </>
                }
                <Button 
                    // color="secondary"
                    color='link'
                    size='sm'
                    className="position-absolute top-0 end-0 px-2"
                    // variant="link"
                    onClick={toggleAdvanced}
                >
                    {mode === 'basic' ? 'Advanced search' : <Icon name="xmark" />}
                </Button>
            </Row>
        </div>
    )
}

export {
    DocumentSearchForm
}

export const departmentSizes = [
    {
        label: 'Small (1-500)',
        value: 'small',
        minEmployees: 1,
        maxEmployees: 500
    },
    {
        label: 'Medium (501-1,500)',
        value: 'medium',
        minEmployees: 501,
        maxEmployees: 1500
    },
    {
        label: 'Large (1,501 - 3,000)',
        value: 'large',
        minEmployees: 1501,
        maxEmployees: 3000
    },
    {
        label: 'X-Large (3000+)',
        value: 'x-large',
        minEmployees: 3000,
        maxEmployees: 999999999
    }
]


import { makeAutoObservable } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { BlueUserStore } from '../store'
import { Api } from '@evertel/api'

class UserController {
    userId = 0

    constructor(
        private api: Api,
        private userStore: BlueUserStore
    ) {
        makeAutoObservable(this)
    }

    init(userId: number, filter?: any) {
        if (!userId) return
        
        this.userId = userId
    }

    get id() {
        return this.userId
    }
    
    get user() {
        return this.userStore.findById(this.userId)
    }

    get fullName() {
        if (!this.user) return undefined
        return this.user.firstName + ' ' + this.user.lastName
    }
}

decorate(injectable(), UserController)
decorate(inject(Api), UserController, 0)
decorate(inject(BlueUserStore), UserController, 1)

export { UserController }
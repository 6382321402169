/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo, useState } from 'react'
import { DragDropContext, DragStart, DragUpdate, Droppable } from 'react-beautiful-dnd'
import { observer } from 'mobx-react-lite'
// evertel
import { Row, Col, SlidePanel, Card, CardBody, useUI } from '@evertel/web/ui'
import { Toolbox } from './elements/Toolbox'
import { DnDRow } from './elements/DnDRow'
import { ItemEditForm } from './elements/ItemEditForm'
import { SchemaFieldItemProps } from './data/_schemaItemProps'
import { onDragEndHandler, ResponderProps } from './utils/OnDragEnd'
import { APIDataDocumentSchema } from '@evertel/types'
import { SchemaMediaController, SchemaBuilderController } from '../../controller'


interface SchemaBuilderFullProps {
    schemaId: number
    departmentId: number,
    controller: SchemaBuilderController,
    mediaController: SchemaMediaController,
    schema: APIDataDocumentSchema
}

const SchemaBuilderFull: React.FC<SchemaBuilderFullProps> = observer(({
    controller,
    mediaController,
    schema,
    schemaId
}) => {
    const { addToast, isConfirmed } = useUI()

    const [slidePanelIsVisible, setSlidePanelIsVisible] = useState(false)
    const [selectedField, setSelectedField] = useState<SchemaFieldItemProps|null>(null)
    const [isBoardDropDisabled, setIsBoardDropDisabled] = useState(false)
    const [isBoardCombineEnabled, setIsBoardCombineEnabled] = useState(false)
    //const [isRowDropDisabled, setIsRowDropDisabled] = useState(false)

    const onClickToEditItem = (field: SchemaFieldItemProps) => {
        setSelectedField(field)
        setSlidePanelIsVisible(true)
    }

    const onDelete = async (type: 'field'|'row', rowIndex: number, fieldId?: string) => {
        if (!type || isNaN(rowIndex)) return

        const deleteIt = async () => {
            if (type === 'field') {
                fieldId && controller.deleteField(fieldId, rowIndex)
            } else {
                controller.deleteRow(rowIndex)
            }
        }

        const confirmed = await isConfirmed({
            title: `Delete this ${type}?`,
            message: `Are you sure you want to delete this ${type}?
                ${(type === 'field') ? 'This field will also be deleted from any existing EverDocs.' :
                'Any fields in this row will also be deleted'}`,
            acceptButton: {
                label: 'Delete',
                color: 'danger'
            }
        })
        if (confirmed) {
            deleteIt()
        }
    }

    const onDragStart = (result: DragStart) => {
        const { source, draggableId } = result

        //console.log('DRAG START', result)

        if (draggableId === 'row-toolbox' || draggableId === 'spacer-toolbox') {
            // rows and spacers cannot be combined with other rows
            setIsBoardDropDisabled(false)
            setIsBoardCombineEnabled(false)

        } else if (source?.droppableId === 'toolbox') {
            // dragging element from the toolbox
            // enable board dropping and combining, disable row dropping
            setIsBoardDropDisabled(false)
            setIsBoardCombineEnabled(true)
            //setIsRowDropDisabled(true)

        } else if (source?.droppableId === 'board') {
            // dragging a row from within the board
            // enable board dropping, disabled row dropping and combining
            setIsBoardDropDisabled(false)
            setIsBoardCombineEnabled(false)
            //setIsRowDropDisabled(true)

        } else if (source?.droppableId.includes('row-drop')) {
            // dragging an element from within a row on the board
            // enable combining and board dropping, disable row dropping
            setIsBoardDropDisabled(false)
            setIsBoardCombineEnabled(true)
            //setIsRowDropDisabled(true)
        }
    }

    const onDragEnd = (result: DragUpdate) => {
        //console.log('DRAG END', result)
        
        const { destination, combine } = result

        // dropped outside the board, do nothing
        if (!combine && !destination) return

        // get proper schema
        const activeSchema = (controller.schemaKey === 'root') ? controller.schema : controller.schema.internalSchemas[controller.schemaKey]
        
        // responder returns an array of actions to take on drop
        const responder: ResponderProps[]|undefined = onDragEndHandler(result, activeSchema)
        if (!responder) return

        responder.forEach((response) => {
            if (response.action === 'toast') {
                addToast({...response.params[0]})
            } else {
                controller[response.action](...response.params)
            }
        })
    }

    const onActionCallback = (action: string, props: any) => {
        // this is setup for future expansion to handle callbacks
        // from child components that need to be executed here
        switch (action) {
            case 'open-form':
                setSlidePanelIsVisible(false)
                controller.setSchemaKey(props.sKey)
                break
            default:
                break
        }
    }

    return (
        <>
            {/* @ts-ignore */}
            <DragDropContext
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}>
                <Row>
                    <Col valign="top">
                        <Card style={{minHeight: '75vh'}}>
                            {/* @ts-ignore */}
                            <Droppable
                                droppableId="board"
                                isCombineEnabled={isBoardCombineEnabled}
                                isDropDisabled={isBoardDropDisabled}>
                                {(provided) => (
                                    <CardBody
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        <SchemaRows
                                            schema={schema}
                                            schemaId={schemaId}
                                            controller={controller}
                                            onDelete={onDelete}
                                            onClickToEditItem={onClickToEditItem}
                                            mediaController={mediaController}
                                        />
                                        {provided.placeholder}
                                    </CardBody>
                                )}
                            </Droppable>
                        </Card>
                    </Col>
                    <Col
                        xl={3}
                        lg={4}
                        md={5}
                        valign="top"
                        align="right">
                        <Toolbox
                            controller={controller}
                        />
                    </Col>
                </Row>
            </DragDropContext>

            <SlidePanel
                placement="end"
                backdrop="clear"
                visible={slidePanelIsVisible}
                onHide={() => setSlidePanelIsVisible(false)}
                style={{ width: 450 }}
            >
                <ItemEditForm
                    field={selectedField}
                    onChange={(data) => controller.updateField(data)}
                    onChangeListLayoutField={(data) => controller.updateListLayoutField(data)}
                    mediaController={mediaController}
                    onAction={onActionCallback}
                    onClose={() => setSlidePanelIsVisible(false)}
                />
            </SlidePanel>
        </>
    )
})

interface SchemaRowProps {
    schema: APIDataDocumentSchema
    schemaId: number
    onDelete: (type: 'field'|'row', rowIndex: number, field?: string) => void
    onClickToEditItem: (item: SchemaFieldItemProps) => void
    controller: SchemaBuilderController
    mediaController: SchemaMediaController
}

// this enhances performance greatly. minimizes frame rate drop
const SchemaRows: React.FC<SchemaRowProps> = memo(({
    schema,
    schemaId,
    onDelete,
    onClickToEditItem,
    controller,
    mediaController
    //isRowDropDisabled
}) => {
    return (
        <>
            {schema.layouts?.full?.map((row: SchemaFieldItemProps, idx: number) =>
                <DnDRow
                    key={idx}
                    rowIndex={idx}
                    row={row}
                    schemaId={schemaId}
                    onDelete={(field, rowIndex) => onDelete('field', rowIndex, field)}
                    onDeleteRow={(rowIndex) => onDelete('row', rowIndex)}
                    onEdit={onClickToEditItem}
                    onClickToEditListSchema={controller.setSchemaKey}
                    onItemReorder={controller.reorderFieldInRow}
                    mediaController={mediaController}
                    //isRowDropDisabled={isRowDropDisabled}
                />
            )}
         </>
        )
})

export {
    SchemaBuilderFull
}
import { makeAutoObservable, runInAction } from 'mobx'
// evertel
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { uniqBy } from 'lodash'
import { RoomMessagesStore } from '@evertel/stores'

export type InviteStatus = 'valid'|'consumed'|'deleted'|'expired'

class RoomInvitesController {
    roomId = 0
    invites = []
    invitesLatestFetch = []
    invitesCount = 0
    _filter = {}

    constructor(
        private api: Api,
        private messagesStore: RoomMessagesStore
    ) {
        makeAutoObservable(this)
    }

    init = async (roomId: number, filter?: any) => {
        if (!roomId) console.error('Missing roomId! in RoomInvitesController.init')

        this.roomId = roomId
        
        this.resetSearch()
        this._filter = filter

    }

    setFilter = (filter: any) => {
        this.resetSearch()
        this._filter = filter
    }

    resetSearch(): void {
        this._filter = {}
        this.invites = []
        this.invitesCount = 0
    }

    fetchInvites = async () => {
        if (!this.roomId) return

        this.invitesLatestFetch = []

        const newInvites = await this.api.Routes.Room.getInvites(this.roomId, this.filter)

        runInAction(() => {
            this.invitesLatestFetch = newInvites
            this.invites = uniqBy([...this.invites, ...newInvites], 'id')
            this.invitesCount = this.invites.length // temporary solution until there is a count endpoint
        })
    }

    delete = async (inviteId: number) => {
        const msg = await this.api.Routes.Room.delInvitesById(this.roomId, inviteId)

        runInAction(() => {
            this.invites = this.invites.filter(i => i.id !== inviteId)
        })
    }

    get filter() {
        return {
            where: {}, //this should be overridden by this._filter if it has 'where'
            ...this._filter,
            order: 'createdTime DESC'
        }
    }
}

decorate(injectable(), RoomInvitesController)
decorate(inject(Api), RoomInvitesController, 0)
decorate(inject(RoomMessagesStore), RoomInvitesController, 1)

export { RoomInvitesController }

import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { uniqBy } from 'lodash'

// evertel
import { RoomStore } from '@evertel/stores'
import { SessionState } from '@evertel/session'
import { BlueUserStore } from '@evertel/blue-user'
import { Api } from '@evertel/api'

class RoomJoinRequestsController {
    roomId = 0
    joinRequests = []
    joinRequestsCount = 0
    page = 1
    limit = 100
    _filter = {}

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = async (roomId: number, filter?: any) => {
        if (!roomId) {
            console.error('Missing roomId in RoomJoinRequestController.init')
            return
        }

        this.roomId = roomId
        this.page = 1
        this.joinRequests = []
        this.joinRequestsCount = 0
        this._filter = filter
        
        await this.fetchJoinRequests()
    }

    fetchJoinRequests = async () => {
        let count = this.joinRequestsCount
        let requests = []

        // if we don't have a count yet, grab the total count of requests in this room
        if (!this.joinRequestsCount) {
            count = await this.fetchJoinRequestsCount()
        }

        // stop if we already fetched them all
        if (count <= this.joinRequests?.length) return

        requests = await this.api.Routes.Room.getRoomJoinRequests(this.roomId, this.filter)

        runInAction(() => {
            this.joinRequests = uniqBy([...this.joinRequests, ...requests], 'id')
        })
    }

    fetchJoinRequestsCount = async () => {
        const res = await this.api.Routes.Room.getRoomJoinRequestsCount(this.roomId, this.filter.where)

        runInAction(() => {
            this.joinRequestsCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    fetchNextJoinRequests = () => {
        this.page = this.page + 1
        this.fetchJoinRequests()
    }

    updateRequestsList = (requestId: number, newStatus: string | null) => {
        runInAction(() => {
            if (newStatus === 'blocked') {
                const request = this.joinRequests.find(req => req.id === requestId)
                if (request) {
                    request.status = 'blocked'
                    request.updatedDate = new Date().toISOString().slice(0, 19)
                }
            } else {
                this.joinRequests = this.joinRequests.filter(({id}) => id !== requestId)
                this.joinRequestsCount--
            }
        })
    }

    acceptRequest = async (requestId: number) => {
        if (!requestId) return
        await this.api.Routes.Room.postRoomJoinRequestsAccept(this.roomId, requestId)

        this.updateRequestsList(requestId, 'accepted')
    }

    rejectRequest = async (requestId: number) => {
        if (!requestId) return
        await this.api.Routes.Room.postRoomJoinRequestsReject(this.roomId, requestId)

        this.updateRequestsList(requestId, 'rejected')
    }

    blockRequest = async (requestId: number) => {
        if (!requestId) return
        await this.api.Routes.Room.postRoomJoinRequestsBlock(this.roomId, requestId)

        this.updateRequestsList(requestId, 'blocked')
    }


    get acceptedJoinRequests() {
        return this.joinRequests.filter(j => j.status === 'accepted')
    }
    
    get pendingJoinRequests() {
        return this.joinRequests.filter(j => j.status === 'pending')
    }

    get rejectedJoinRequests() {
        return this.joinRequests.filter(j => j.status === 'rejected')
    }

    get blockedJoinRequests() {
        return this.joinRequests.filter(j => j.status === 'blocked')
    }

    get pendingCount() {
        return this.pendingJoinRequests.length
    }

    get filter() {
        // TODO: do all this include work on the server-side and add to the default endpoint
        return {
            where: {},
            include: [
                {
                    relation: 'requestedBy',
                    scope: {
                        fields: ['id', 'firstName', 'lastName', 'publicImage'],
                        include: [{
                            relation: 'departmentsAccess',
                            scope: {
                                fields: ['id', 'isVerified', 'roles', 'badgeNumber', 'departmentId', 'positionString'],
                                include: [
                                    {
                                        relation: 'department',
                                        scope: {
                                            fields: ['id', 'name', 'city', 'state']
                                        }
                                    }
                                ]
                            }
                        }]
                    }
                }
            ],
            ...this._filter,
            limit: this.limit,
            skip: (this.page - 1) * this.limit
        }
    }
}

decorate(injectable(), RoomJoinRequestsController)
decorate(inject(Api), RoomJoinRequestsController, 0)
decorate(inject(RoomStore), RoomJoinRequestsController, 1)
decorate(inject(SessionState), RoomJoinRequestsController, 2)
decorate(inject(BlueUserStore), RoomJoinRequestsController, 3)

export { RoomJoinRequestsController }

import { observer } from 'mobx-react-lite'
// evertel
import { Links } from '@evertel/constants'
import { Icon, FloatingMenu, MenuItem } from '@evertel/web/ui'

interface HeaderHelpDropdownProps {
    openHelpModal: (action: string) => void
}


const HeaderHelpDropdown: React.FC<HeaderHelpDropdownProps> = observer(({
    openHelpModal
}) => {

    return (
        <FloatingMenu
            label="Help"
            renderLabel={() => (
                <Icon
                    className="d-block"
                    name="question-circle"
                    color="secondary"
                    size={16}
                    style={{width: 20, textAlign: 'center'}}
                />
            )}
        >
            <MenuItem
                label="Help Center"
                icon={{ name: 'life-ring' }}
                href={Links.helpCenter}
            />
            <MenuItem
                label="Training Videos"
                icon={{ name: 'graduation-cap' }}
                href={Links.trainingShorts}
            />
            <MenuItem
                label="Report a Problem"
                icon={{ name: 'bug' }}
                onClick={() => openHelpModal('report-bug')}
            />
            <MenuItem
                label="Make a Suggestion"
                icon={{ name: 'lightbulb' }}
                onClick={() => openHelpModal('make-suggestion')}
            />
            <MenuItem
                label="Ask a Question"
                icon={{ name: 'message-question' }}
                onClick={() => openHelpModal('ask-question')}
            />
        </FloatingMenu>
    )
})

export { HeaderHelpDropdown }

import { useEffect, useRef } from 'react'

/*****************************************************
 This is a useEffect hook but won't run on first load
 *****************************************************/

const useDidMountEffect = (func, deps) => {

    const didMount = useRef(false)

    useEffect(() => {
        if (didMount.current) {
            func()
        } else {
            didMount.current = true
        }
    }, deps)
}

export { useDidMountEffect }
import { computed } from 'mobx'
import { omit } from 'lodash'

//==============================================
// RETURNS A NEGATIVE ID FOR ANY LOCAL ELEMENT
//==============================================
let lastId = -1
export function localId() {
    let id = -Date.now()
    while (id === lastId) id = -Date.now()

    lastId = id
    return id
}

//==============================================
// DEEP COPY
//==============================================
export function deepCopy(objOrArray) {
    return JSON.parse(JSON.stringify(objOrArray))
}

//==============================================
// FORMATS ARRAY OF OBJECTS INTO A USABLE ARRAY
// FOR A 'SELECT' COMPONENT
//==============================================
export function formatForSelect(array: unknown[], labelKey: string, valueKey: string, def = 'Name not provided') {
    const labelFields = labelKey.split('+')

    if (!array) return {}
    if (!Array.isArray(array)) array = [array]

    return computed(() => array.map((item) => {
        let label = (labelFields.length === 1) ? item[labelKey] : item[labelFields[0]] + ' ' + item[labelFields[1]]

        if (!label || label === ' ') {
            label = def
        }

        return ({
            ...omit(item, ['options']),
            label: label,
            value: item[valueKey],
            opts: item.options || []
        })
    })).get()
}

//==============================================
// DETERMINES IF ELEMENT IS POSITIONED OUT OF VIEWPORT
//==============================================
export function isOutOfViewport(elem) {
    // get element's position
    const bounding = elem.getBoundingClientRect()

    // check if it's out of the viewport on each side
    let out = {}
    out.top = bounding.top < 0
    out.left = bounding.left < 0
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth)
    out.any = out.top || out.left || out.bottom || out.right
    out.all = out.top && out.left && out.bottom && out.right

    return out
}

//==============================================
// DETERMINES IF ELEMENT WILL BE POS OUT OF VIEWPORT BASED ON POS PASSED IN
//==============================================
export function willBeOutOfViewport({top, left, right, bottom}) {
    // check if it's out of the viewport on each side
    let out = {}
    out.top = (top < 0) ? top : false
    out.left = (left < 0) ? left : false
    out.bottom = (bottom > (window.innerHeight || document.documentElement.clientHeight)) ? bottom - (window.innerHeight || document.documentElement.clientHeight) : false
    out.right = (right > (window.innerWidth || document.documentElement.clientWidth)) ? right - (window.innerWidth || document.documentElement.clientWidth) : false
    out.any = (out.top || out.left || out.bottom || out.right) ? true : false
    out.all = (out.top && out.left && out.bottom && out.right) ? true : false

    // returns false if not out of bounds, and distance out of bounds if true
    return out
}

//==============================================
// POSITIONS EL RELATIVE TO ANOTHER TRGT ELEMENT
//==============================================
export function positionElementRelativeToTarget(elementSelector, targetSelector, loc = 'top', keepOnScreen = true, pxFromEdge = 10) {
    // grab element and position
    const el = document.querySelector(elementSelector)
    const elPosition = el.getBoundingClientRect()
    // grab target and position
    const target = document.querySelector(targetSelector)
    const targetPosition = target.getBoundingClientRect()

    let left = (loc === 'left') ? targetPosition.left - elPosition.width : targetPosition.left
    let top = (loc === 'top') ? targetPosition.top - elPosition.height : targetPosition.top
    const bottom = top + elPosition.height
    const right = left + elPosition.width

    if (keepOnScreen) {
        const isOut = willBeOutOfViewport({top, left, bottom, right})

        if (isOut.left) left = 0
        if (isOut.top) top = 0
        if (isOut.right) left = left - isOut.right
        if (isOut.bottom) top = top - isOut.bottom
    }

    // adjust position factoring in desired distance to keep el from edge of viewport (pxFromEdge)
    if (top < pxFromEdge) top = pxFromEdge
    if (left + elPosition.width > (window.innerWidth - pxFromEdge)) left = left - pxFromEdge

    el.style.top = top + 'px'
    el.style.left = left + 'px'
}

import { APIDataBlueUser, APIDataThread, APIMetaDataThreadPropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

export interface DisplayThread extends APIDataThread {
    userIds?: number[],
    name: string,
    isBotThread: boolean
}

class ThreadStore extends Store<DisplayThread> {
    constructor() {
        super([...APIMetaDataThreadPropertyNames, 'userIds', 'users', 'name', 'isBotThread'])
    }
}

decorate(injectable(), ThreadStore)

export { ThreadStore }

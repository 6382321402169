import { runInAction, makeAutoObservable } from 'mobx'
import api from '../api'
import DepartmentsAccessStore from './DepartmentsAccessStore'
import DepartmentsStore from './DepartmentsStore'
import AppStore from './AppStore'
import NavigationStore from './NavigationStore'
import { LoadingIndicator, Toaster } from '../components'
import { container } from '../di/container'
import { CurrentUserPollService } from '@evertel/blue-user'
import { SessionState } from '@evertel/session'

class InviteStore {

    isBusy = false
    error: any

    pendingInviteToken = localStorage.getItem('pendingInviteToken') || undefined
    lastInviteData: any
    invitedDepartmentId: any
    invitedRoomId: any
    invitedDepartmentsAccessId: any
    invitedThreadId: any
    lastInviteType = ''

    departmentInvites: any
    departmentInviteLinks: any
    userInvites: any = []
    currentUserPollService: CurrentUserPollService
    sessionState: SessionState

    constructor() {
        makeAutoObservable(this)
        this.currentUserPollService = container.get(CurrentUserPollService)
        this.sessionState = container.get(SessionState)
    }

    get invitedDepartmentsAccess() {
        return DepartmentsAccessStore.departmentsAccess.find(da => da.id === this.invitedDepartmentsAccessId)
    }

    clearLastInvite() {
        this.lastInviteData = false
        this.invitedThreadId = false
        this.invitedDepartmentId = false
        this.invitedRoomId = false
        this.invitedDepartmentsAccessId = false
    }

    setPendingInviteToken(token: any) {
        if (!api.isProduction) console.log('pendingInviteToken set: ', token)
        localStorage.setItem('pendingInviteToken', token)
        this.pendingInviteToken = token
    }

    consumePendingInvite = async (skipNavigation = false) => {
        if (!this.pendingInviteToken) return

        const token = this.pendingInviteToken
        this.pendingInviteToken = ''
        localStorage.removeItem('pendingInviteToken')
        return this.consumeInvite(token, skipNavigation)
    }

    /**
     * Sometimes it won't navigate so callers need to know
     * @returns true if navigation has happened
     */
    consumeInvite = async (token: any, skipNavigation = false): Promise<boolean> => {
        let hasNavigated = false

        this.isBusy = true
        this.error = null
        this.clearLastInvite()

        if (!api.isProduction) console.log('InviteStore consumeInvite: ', token)

        let data: any
        try {
            data = await api.Routes.Invite.postConsumeById(token)
        } catch (e: any) {
            AppStore.logError({
                type: 'API post',
                message: 'InviteStore.consumeInvite()',
                error: e.message || e,
                token
            })

            runInAction(() => {
                this.isBusy = false
                this.error = e
            })
            return
        }

        // store last invite data
        runInAction(() => {
            this.lastInviteData = data
            this.lastInviteType = data.action
        })

        switch (data.action) {
            case 'ROOM_INVITE':
                // example data {
                //     "action": "ROOM_INVITE",
                //     "departmentsAccess": {
                //       "isVerified": true,
                //       "roles": [
                //         "executive"
                //       ],
                //       "isVerifyDisabled": false,
                //       "badgeNumber": "123456789",
                //       "isPrimary": true,
                //       "id": 155,
                //       "departmentId": 9019,
                //       "blueUserId": 159,
                //       "createdDate": "2019-01-22T15:58:05.000Z",
                //       "updatedDate": "2019-12-19T00:09:06.000Z",
                //       "deletedDate": null,
                //       "positionId": 5,
                //       "assignmentId": 34
                //     },
                //     "room": {
                //       "name": "Chris Agency Alerts room",
                //       "description": "",
                //       "isArchived": false,
                //       "memberCapacity": 10,
                //       "managerCapacity": 1,
                //       "bulletinsCapacity": 7,
                //       "options": {
                //         "roomMembersCanMessage": false,
                //         "roomManagersCanMessage": true,
                //         "roomMembersCanLeave": false,
                //         "roomManagersCanLeave": true,
                //         "roomManagersCanRemoveMembers": false,
                //         "roomManagersCanRemoveManagers": true,
                //         "roomManagersCanAddMembers": false,
                //         "roomManagersCanAddManagers": true,
                //         "roomManagersCanInviteMembers": false,
                //         "roomMembersCanInviteMembers": false,
                //         "departmentMembersCanJoinAsMember": false,
                //         "departmentManagersCanJoinAsMember": false,
                //         "departmentMembersCanJoinAsManager": false,
                //         "departmentManagersCanJoinAsManager": false,
                //         "autoAddDepartmentMembers": true,
                //         "departmentMembersCanReadMessages": true,
                //         "departmentManagersCanReadMessages": true
                //       },
                //       "meta": {},
                //       "id": 394,
                //       "departmentId": 9019,
                //       "createdDate": "2019-08-08T23:36:26.000Z",
                //       "updatedDate": "2019-08-08T23:36:26.000Z"
                //     }
                //   }

                // Add invite results to Stores
                // TODO: do we need to add results to store?  Need to access new RoomStore to do so
                DepartmentsAccessStore.updateFromData(data.departmentsAccess)

                // Should get any new departments, access, and rooms, and threads
                await this.currentUserPollService.fetchUserDetails()

                runInAction(() => {
                    this.invitedThreadId = false
                    this.invitedDepartmentId = false
                    this.invitedRoomId = data.room.id
                    this.invitedDepartmentsAccessId = false
                })

                if (!skipNavigation) {

                    // notify user of room join
                    if (data.room?.name) {
                        Toaster.show('success', 'You have now joined ' + data.room.name)
                    }

                    // Navigate to the room
                    LoadingIndicator.hide('app-loading')
                    NavigationStore.navigateToRoom(data.room.id)
                    hasNavigated = true
                }
                break
            case 'USER_INVITE':
                // example data {
                //     "action": "USER_INVITE",
                //     "thread": {
                //       "meta": {},
                //       "id": 1000,
                //       "createdDate": "2018-11-17T01:46:32.000Z",
                //       "updatedDate": "2018-11-17T01:46:32.000Z"
                //     }

                // Get threads
                // TODO: do we need to get threads?

                runInAction(() => {
                    this.invitedThreadId = data.thread.id
                    this.invitedDepartmentId = false
                    this.invitedRoomId = false
                    this.invitedDepartmentsAccessId = false
                })

                if (!skipNavigation) {
                    // Navigate to thread
                    LoadingIndicator.hide('app-loading')
                    NavigationStore.navigateToThread(data.thread.id)
                    hasNavigated = true
                }
                break
            case 'DEPARTMENT_INVITE':
                // example data {
                //     "action": "DEPARTMENT_INVITE",
                //     "departmentsAccess": {
                //       "isVerified": true,
                //       "roles": [
                //         "guest"
                //       ],
                //       "isVerifyDisabled": false,
                //       "badgeNumber": null,
                //       "isPrimary": false,
                //       "id": 1362,
                //       "departmentId": 9019,
                //       "blueUserId": 1294,
                //       "createdDate": "2019-12-19T19:56:09.000Z",
                //       "updatedDate": "2019-12-19T19:56:09.359Z",
                //       "deletedDate": null,
                //       "positionId": null,
                //       "assignmentId": null
                //     }
                //   }

                // Add departmentsAccess to store
                DepartmentsAccessStore.updateFromData(data.departmentsAccess)

                // Should get any new departments, access, and rooms, and threads
                await this.currentUserPollService.fetchUserDetails()

                runInAction(() => {
                    this.invitedThreadId = false
                    this.invitedDepartmentId = data.departmentsAccess.departmentId
                    this.invitedRoomId = false
                    this.invitedDepartmentsAccessId = data.departmentsAccess.id
                })

                if (!skipNavigation) {
                    // Switch to this department
                    if (data.departmentsAccess.departmentId !== AppStore.selectedDepartmentId) {
                        this.sessionState.setSelectedDeparmentId(data.departmentsAccess.departmentId)

                        const department = DepartmentsStore.getDepartmentById(data.departmentsAccess.departmentId)

                        // notify user of dept. switch
                        Toaster.show('success', 'You are now viewing ' + department.name)
                    }
                    // Navigate to static landing screen
                    // TODO: navigate to something else ?

                    LoadingIndicator.hide('app-loading')
                    NavigationStore.navigateToLanding()
                    hasNavigated = true
                }
                break
            default:
                // by default, just update user data and nothing else
                await this.currentUserPollService.fetchUserDetails()
                break
        }

        return hasNavigated

    }

}

export default new InviteStore()

import React from 'react'
import { forwardRef, HTMLAttributes, MouseEventHandler } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
//import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { IconName, IconProp, IconStyle, PullProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
// @ts-ignore
import Colors from '../scss/_colors.module.scss'

// documentation: https://fontawesome.com/docs/web/use-with/react/style
export interface IconProps extends HTMLAttributes<SVGSVGElement|HTMLElement> {
    name: IconName
    color?: string
    size?: SizeProp|number
    type?: IconStyle
    pull?: PullProp
    border?: boolean
    inverse?: boolean
    fixedWidth?: boolean
    className?: string
    style?: any
    onClick?: MouseEventHandler<HTMLElement>|undefined,
    faProps?: FontAwesomeIconProps
}

const Icon = forwardRef<SVGSVGElement, IconProps>(({
    name = 'icons',
    color,
    type = 'light',
    size,
    pull,
    border,
    inverse,
    fixedWidth,
    className,
    style,
    onClick,
    faProps,
    ...otherProps
}, ref) => {

    if (!name || typeof name !== 'string') return null

    const classes = classNames({
        //[`text-${color}`]: color,
        'cursor-pointer': onClick
    }, className)

    const _type = classNames({
        far: type === 'regular',
        fab: type === 'brands',
        fas: type === 'solid',
        fal: type === 'light',
        fad: type === 'duotone'
    })

    let fontSize, iconSize
    if (typeof size === 'number') fontSize = size
    if (typeof size === 'string') iconSize = size


    return (
        <i
            className={classes}
            style={{fontStyle: 'normal', ...style}}
            onClick={(onClick) ? onClick : undefined}
            {...otherProps}>
            <FontAwesomeIcon
                ref={ref}
                icon={[_type, `fa-${name}`]}   
                color={Colors[color]}
                pull={pull}
                border={border}
                inverse={inverse}
                fixedWidth={fixedWidth}
                {...iconSize && {size: iconSize}}
                {...fontSize && {style: {fontSize}}}
                {...faProps}
            />
        </i>
    )
})

export { Icon }

import React from 'react'
import { ElementType, forwardRef, useContext, Ref } from 'react'
import { ToastContext } from './Toast'
import type { ButtonProps } from '../Button'
import { Button } from '../Button'
import { Icon } from '../Icon'

export interface ToastCloseProps extends ButtonProps {
    component?: string|ElementType
}

const ToasterClose = forwardRef<HTMLButtonElement, ToastCloseProps>(({
    children,
    component: Component,
    ...otherProps
}, ref) => {

    const { setVisible } = useContext(ToastContext)

    return (
        (Component) ? (
            <Component
                ref={ref}
                onClick={() => setVisible(false)}
                {...otherProps}
            >
                {children}
            </Component>
        ) : (
            <Button
                ref={ref}
                color="gray500"
                ghost
                aria-label="Close"
                onClick={() => setVisible(false)}
                {...otherProps}
            >
                <Icon
                    name="xmark"
                />
            </Button>
        )
    )
})

export default ToasterClose
ToasterClose.displayName = 'ToasterClose'

import React from 'react'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import classNames from 'classnames'
import { Emoji } from 'emoji-mart'
import { createPortal } from 'react-dom'
// evertel
import { Backdrop } from '@evertel/web/ui'

interface EmojiPickerProps {
    backdrop?: boolean|'static',
    visible: boolean,
    onSelectEmoji: (emoji: Emoji, params: any) => void,
    onClickOutside: () => void,
}

const EmojiPickerModal: React.FC<EmojiPickerProps> = ({
    visible,
    onSelectEmoji,
    onClickOutside,
    backdrop = true,
    ...otherProps
}) => {

    const setSkinTone = (skin: number) => {
       //EmojiStore.setSkinTone(skin)
    }

    return (
        <>
            {createPortal(
            <div
                id="emoji-picker"
                className={classNames({invisible: !visible})}>
                <Picker
                    data={data}
                    onEmojiSelect={onSelectEmoji}
                    onClickOutside={onClickOutside}
                    color="#cc0000"
                    //theme={}
                    {...otherProps}
                />
            </div>
            , document.body)}
            {(typeof window !== 'undefined')
                ? backdrop && createPortal(<Backdrop visible={visible} transparent />, document.body)
                : backdrop && <Backdrop visible={visible} transparent/>}
        </>
    )
}

export { EmojiPickerModal }

import React, { useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { Emoji } from '@evertel/web/emojis'
// evertel
import { APIDataReaction } from '@evertel/types'

interface ReactionProps {
    onSelect?: (reaction: APIDataReaction) => void,
    reaction: APIDataReaction,
    emoji: string,
    isMyReaction?: boolean,
    showClickAnimation?: boolean
}

const Reaction: React.FC<ReactionProps> = observer(({
    reaction = {},
    emoji,
    isMyReaction,
    onSelect,
    showClickAnimation = true
}) => {

    const [bounce, setBounce] = useState(false)

    const onClick = () => {
        if (onSelect) onSelect(reaction)

        setBounce(true)
        
        setTimeout(() => {
            setBounce(false)
        }, 750)
    }

    if (!emoji || !reaction) return null

    return (
        <div className={classNames(
            'reaction',
            {
                mine: isMyReaction,
                bounce: bounce && showClickAnimation
            })}
            onClick={onClick}>
            <Emoji
                size={16}
                id={emoji}
            />
            <div>
                {(!!reaction.count) && reaction.count}
            </div>
        </div>
    )
})

export { Reaction }

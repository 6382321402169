
import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { SessionState } from '@evertel/session'
import { Api } from '@evertel/api'
import { APIDataAny, APIDataUserSettings } from '@evertel/types'
import { UserSettingsStore } from '@evertel/stores'
import { PushService } from '@evertel/push'


class UserSettingsController {
    userId = 0
    hasPushPermission: string | undefined

    constructor(
        private api: Api,
        private session: SessionState,
        private userSettingsStore: UserSettingsStore,
        private pushService: PushService
    ) {
        makeAutoObservable(this)
    }

    init = (userId: number) => {
        if (!userId) return

        this.userId = userId

        // if user's settings are not in the store, fetch and update in the background
        if (!this.settings) {
            this.userSettingsStore.update({
                id: userId
            })
            this.fetchUserSettings()
        }

        this.pushPermission()
    }

    fetchUserSettings = async () => {
        if (!this.userId) return

        try {
            const userSettings = await this.api.Routes.BlueUser.getSettings(this.userId) as APIDataUserSettings

            runInAction(() => {
                this.userSettingsStore.update({ id: this.userId, settings: userSettings })
            })

        } catch (error: any) {
            console.error(error.message)
        }
    }

    updateUserRoomNotificationSettings = async (roomId: number, enabled: boolean) => {
        if (!this.userId || !roomId) return

        const userSettings = await this.api.Routes.BlueUser.putSettings(this.userId, {
            roomNotifications: {
                [roomId]: {
                    roomMessage: {
                        enabled
                    }
                }
            }
        }) as APIDataUserSettings

        runInAction(() => {
            this.userSettingsStore.update({ id: this.userId, settings: userSettings })
        })
    }

    updateUserNotificationSettings = async (settings: object) => {
        const userSettings = await this.api.Routes.BlueUser.putSettings(this.session.currentUserId, settings)

        runInAction(() => {
            this.userSettingsStore.update({ id: this.userId, settings: userSettings })
        })

    }

    async pushPermission() {
        const hasPermission = await this.pushService.getCurrentNotificationPermission()

        runInAction(() => {
            this.hasPushPermission = hasPermission
        })
    }

    get settings() {
        return this.userSettingsStore.findById(this.userId)?.settings
    }

    get roomNotificationSettings() {
        const settings = this.settings as any
        return settings?.roomNotifications || {}
    }

    get disableNotifications() {
        const settings = this.settings as any
        return settings?.disableNotifications
    }


    toggleAllNotifications = async () => {

        await this.updateUserNotificationSettings({
            disableNotifications: !this.disableNotifications
        })
    }

}

decorate(injectable(), UserSettingsController)
decorate(inject(Api), UserSettingsController, 0)
decorate(inject(SessionState), UserSettingsController, 1)
decorate(inject(UserSettingsStore), UserSettingsController, 2)
decorate(inject(PushService), UserSettingsController, 3)

export { UserSettingsController }


import { APIDataDepartment } from '@evertel/types'
import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { debounce, uniqBy } from 'lodash'


class DepartmentsSearchController {
    departments: APIDataDepartment[] = []
    departmentsLatestFetch: APIDataDepartment[] = []
    departmentsCount = 0
    searchTerm = ''
    page = 1
    limit = 50
    infiniteScroll = false
    _filter = {}

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init(infiniteScroll: boolean) {
        this.infiniteScroll = infiniteScroll
        this.resetSearch()
    }

    setSearchTerm(value: string) {
        this.resetSearchMeta()
        this.searchTerm = value
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    setFilter(extraFilter: Record<string, any>) {
        this._filter = extraFilter
    }

    debouncedSearch = async () => {
        return debounce( async () => this.search(), 300)
    }

    search = async () => {
        let count = this.departmentsCount
        let newDepartments = []

        // grab the total count of rooms for this search (once each new search)
        if (!this.departmentsCount) {
            count = await this.fetchDepartmentsCount()
        }

        this.departmentsLatestFetch = []

        // stop if we already fetched them all
        if (this.departments?.length < count) {

            try {
                newDepartments = await this.api.Routes.Department.get(this.filter)
            } catch (e) {
                console.error('ERROR: /libs/shared/department/DepartmentsSearchController search()', e)
                throw e
            }
        }

        runInAction(() => {
            this.departmentsLatestFetch = newDepartments
            this.departments = (this.infiniteScroll) ? uniqBy([...this.departments, ...newDepartments], 'id') : newDepartments
        })

        return this.departmentsLatestFetch

    }

    async fetchDepartmentsCount() {
        let count = 0

        try {

            count = (await this.api.Routes.Department.getCount(this.filter.where)).count

        } catch (e) {
            console.log('ERROR: /libs/shared/department/DepartmentsSearchController fetchDepartmentsCount()', e)
            throw e
        }

        runInAction(() => {
            this.departmentsCount = count ?? 0
        })

        return this.departmentsCount
    }

    loadNextPage(): void {
        this.page = this.page + 1
        this.search()
    }

    resetSearchMeta(): void {
        this.departments = []
        this.departmentsCount = 0
        this.page = 1
    }

    resetSearch(): void {
        this.resetSearchMeta()
        this.searchTerm = ''
    }

    get filter() {
        const or = [
            { city: { like: `%${this.searchTerm}%` } },
            { county: { like: `%${this.searchTerm}%` } },
            { name: { like: `%${this.searchTerm}%` } },
            { type: { like: `%${this.searchTerm}%` } },
            { state: { like: `%${this.searchTerm}%` } },
            { federal: { like: `%${this.searchTerm}%` } }
        ]

        return {
            where: {
                and: [
                    {hidden: false },
                    {or}
                ]
            },
            ...this._filter,
            fields: { options: false }, // exclude 'options' field as it breaks the Select component
            order: 'name ASC',
            skip: (this.page - 1) * this.limit,
            limit: this.limit
        }
    }
}

decorate(injectable(), DepartmentsSearchController)
decorate(inject(Api), DepartmentsSearchController, 0)

export { DepartmentsSearchController }
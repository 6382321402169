import { makeAutoObservable } from 'mobx'
// evertel
import { decorate, injectable } from '@evertel/di'

export interface FieldsObject {
    [i: string]: any;
}

export interface EventArgs {
    /** Typically the object that was interacted with (e.g. 'Video') */
    category: string;
    /** The type of interaction (e.g. 'play') */
    action: string;
    /** Useful for categorizing events (e.g. 'Fall Campaign') */
    label?: string;
    /** A numeric value associated with the event (e.g. 42) */
    value?: number;
    /** Specifies that a hit be considered non-interactive. */
    nonInteraction?: boolean;
    /**
     * This specifies the transport mechanism with which hits will be sent.
     * The options are 'beacon', 'xhr', or 'image'. By default, analytics.js
     * will try to figure out the best method based on the hit size and browser
     * capabilities. If you specify 'beacon' and the user's browser does not support
     * the `navigator.sendBeacon` method, it will fall back to 'image' or 'xhr'
     * depending on hit size.
     */
    transport?: 'beacon' | 'xhr' | 'image';
    /** Custom dimensions */
    dimension1?: string;
    dimension2?: string;
    dimension3?: string;
    dimension4?: string;
    dimension5?: string;
    dimension6?: string;
    dimension7?: string;
    dimension8?: string;
    dimension9?: string;
    dimension10?: string;
    dimension11?: string;
    dimension12?: string;
    dimension13?: string;
    dimension14?: string;
    dimension15?: string;
    dimension16?: string;
    dimension17?: string;
    dimension18?: string;
    dimension19?: string;
    dimension20?: string;
    /** Custom metrics */
    metric1?: number;
    metric2?: number;
    metric3?: number;
    metric4?: number;
    metric5?: number;
    metric6?: number;
    metric7?: number;
    metric8?: number;
    metric9?: number;
    metric10?: number;
    metric11?: number;
    metric12?: number;
    metric13?: number;
    metric14?: number;
    metric15?: number;
    metric16?: number;
    metric17?: number;
    metric18?: number;
    metric19?: number;
    metric20?: number;
}

class Analytics {
    platform = ''
    enabled = false

    constructor() {
        makeAutoObservable(this)
    }

    async init() {
        return undefined
    }

    /**
     * logEvent
     * @param category - Typically the object that was interacted with (e.g. 'Video')
     * @param action  - The type of interaction (e.g. 'play') 
     * @param options.label - Useful for categorizing events (e.g. 'Fall Campaign)
     * @param options.value - A numeric value associated with the event (e.g. 42)
     * @returns 
     */
    async logEvent(category: string, action: string, options?: {
        label?: string,
        value?: number
    }) {
        return undefined
    }

    async logView(screen: string, isModal?: boolean, title?: string) {
        return undefined
    }

    // use to log errors and crashes to GA
    async logException(description: string, fatal = false) {
        return undefined
    }

    async setDimension(dataObject: FieldsObject) {
        return undefined
    }

    async logLogin() {
        return undefined
    }

    async logAppOpen() {
        return undefined
    }

    async logSignUp() {
        return undefined
    }
}

decorate(injectable(), Analytics)

export { Analytics }

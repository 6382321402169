import React from 'react'
import { ElementType, forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

export interface DropdownHeaderProps extends HTMLAttributes<HTMLHeadingElement> {
  className?: string
  component?: string|ElementType
}

const ButtonDropdownHeader = forwardRef<HTMLHeadingElement, DropdownHeaderProps>(({
    children,
    className,
    component: Component = 'h6',
    ...otherProps
}, ref) => {

    const _className = classNames('drop-header', className)

    return (
        <Component
            ref={ref}
            className={_className}
            {...otherProps}>
            {children}
        </Component>
    )
})

export default ButtonDropdownHeader
ButtonDropdownHeader.displayName = 'ButtonDropdownHeader'

import { APIDataDepartment, APIMetaDataDepartmentPropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

class DepartmentStore extends Store<APIDataDepartment> {
    constructor() {
        super(APIMetaDataDepartmentPropertyNames)    
    }
}

decorate(injectable(), DepartmentStore)

export { DepartmentStore }
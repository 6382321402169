import React, { useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
// evertel
import { Button, Row, Col, InfoBox, Input, Icon, Text, useUI } from '@evertel/web/ui'
import { APIDataRoom } from '@evertel/types'
import { JoinRoomController } from '@evertel/room'
import { useService } from '@evertel/di'
import { ConsumeInviteController } from '@evertel/invites'


type Opts = {
    opts: {
        departmentMembersCanJoinAsMember: boolean,
        roomMembersCanLeave: boolean
    }
}
interface JoinRoomActionButtonProps {
    onClose: () => void
}


const JoinRoomByInviteCode: React.FC<JoinRoomActionButtonProps> = observer(({
    onClose
}) => {

    const { addToast } = useUI()
    const consumeInviteContoller = useService(ConsumeInviteController, [])

    const [inviteCode, setInviteCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const onChange = (e) => {
        setInviteCode(e.target.value)
    }

    const submitInviteCode = async () => {
        setIsLoading(true)

        try {
            await consumeInviteContoller.consumeInvite(inviteCode)

            onClose()

        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }

        setIsLoading(false)
    }

    return (
        <InfoBox color="info">
            <Row>
                <Col align="left">
                    <Row
                        align="between"
                        className="w-100">
                        <Text
                            tag="h4"
                            bold>
                            Join a Room by Invite Code
                        </Text>
                        <Button
                            onClick={onClose}
                            color="info">
                            <Icon
                                name="times"
                            />
                        </Button>
                    </Row>
                    <Text tag="h5">
                        If you have been given a room invite code please enter it below to join the room
                    </Text>
                    <Input
                        name="inviteCode"
                        value={inviteCode}
                        onChange={onChange}
                        placeholder="Enter the room invite code..."
                    />
                    <Row
                        align="right"
                        className="w-100">
                        <Button
                            loading={isLoading}
                            onClick={submitInviteCode}
                            color="secondary"
                            disabled={!inviteCode?.length}
                            className="mt-2">
                            Submit & Join
                        </Button>
                    </Row>
                </Col>
            </Row>
        </InfoBox>
    )
})

export { JoinRoomByInviteCode }


/* 
    NOTE: If you do not see an expected function, check the shared utils.

    This utils file is for web-only utils. Anything that is universal
    between web and mobile should be added to the shared utils
*/


//==============================================
// DOWNLOADS A FILE IN A BROWSER
//==============================================
export function downloadFile(url: string, fileName: string, target: '_blank'|'_self'|'_parent'|'_top' = '_blank') {
    // name the file and trigger the download
    console.log('file url ::::::', url)

    const a = document.createElement('a')
    document.body.appendChild(a)
    a.download = fileName
    a.href = url
    a.target = target
    a.click()
}

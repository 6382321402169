/* eslint-disable @typescript-eslint/ban-ts-comment */
import { makeAutoObservable } from 'mobx'
import { getEmojiDataFromNative, init as initEmojiMart } from 'emoji-mart'

// @ts-ignore
import data from '@emoji-mart/data/sets/14/apple.json'
import { decorate, inject, injectable } from 'inversify'
import { DepartmentStore } from '@evertel/department'
import { Api } from '@evertel/api'


class DepartmentEmojisController {
    departmentId = 0

    constructor(
        private departmentStore: DepartmentStore,
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = (departmentId: number) => {
        // initialize emoji-mart data
        initEmojiMart({ data })

        if (!departmentId) {
            console.error('Missing departmentId DepartmentEmojisController.init')
            return
        }

        this.departmentId = departmentId
    }

    getEmojiData = async (text: string) => { // text = native emoji, colon value (custom), or id (frequents)
        let emoji = null
        const isCustom = text.charAt(0) === ':'
        const department = this.departmentStore.findById(this.departmentId)

        if (isCustom) {
            emoji = department?.emojis?.find((e: any) => e.colons === text)
        } else {
            emoji = await getEmojiDataFromNative(text)
        }

        return emoji || text
    }
}

decorate(injectable(), DepartmentEmojisController)
decorate(inject(DepartmentStore), DepartmentEmojisController, 0)
decorate(inject(Api), DepartmentEmojisController, 1)

export { DepartmentEmojisController }

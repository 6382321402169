import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
// evertel
import {
    Button,
    Modal,
    ModalBody,
    Row,
    Col,
    Icon,
    Text,
    ModalHeader,
    RoomSearchSelect,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Nav,
    InfoBox } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { CurrentUserController } from '@evertel/blue-user'
import { JoinRoomController } from '@evertel/room'
import { DepartmentController } from '@evertel/department'
import { JoinRoomByInviteCode } from './elements/JoinRoomByInviteCode'
import { PublicRoomRequestExtraInfoModal } from './PublicRoomRequestExtraInfoModal'
import { useCreateRoom } from './CreateRoomContext'
import { Analytics } from '@evertel/analytics'
import { RoomListResultItem } from './RoomListResultItem'

export type SearchScope = 'public'|'department'

interface JoinRoomModalProps {
    visible: boolean,
    searchScope: SearchScope
    onClose: () => void
}


const JoinRoomModal: React.FC<JoinRoomModalProps> = observer(({
    visible,
    searchScope = 'department',
    onClose
}) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const { openCreateRoom } = useCreateRoom()

    const session = useService(SessionState, [])
    const currentUserController = useService(CurrentUserController, [])
    const joinRoomController = useService(JoinRoomController, [])
    const departmentController = useService(DepartmentController, [])
    const analytics = useService(Analytics, [])

    const [activeTab, setActiveTab] = useState<'department'|'public'>(searchScope)
    const [showJoinByInviteCode, setShowByInviteCode] = useState(false)
    const [showExtraInfoModal, setShowExtraInfoModal] = useState(false)
    const [joinRequestRoomId, setJoinRequestRoomId] = useState(0)

    useEffect(() => {
        if (visible) {
            analytics.logView('join-room-modal', true)
            joinRoomController.init()
        } else {
            //occasionally this is left as true which causes it to open first on reopen and be stuck behind this modal
            setShowExtraInfoModal(false)
        }
    }, [visible])

    useEffect(() => {
        departmentController.init(session.selectedDepartmentId)
    }, [session.selectedDepartmentId])
    
    const onOpenExtraInfoModal = (roomId: number) => {
        setJoinRequestRoomId(roomId)
        setShowExtraInfoModal(true)
    }

    return (
        <Modal 
            visible={visible}
            onClose={onClose}
            style={{minHeight: 'calc(100vh - 4rem)'}}
            size="lg">
            <ModalHeader>
                <Row
                    className="w-100 mr-2"
                    align="between"
                    valign="center">
                    <Text tag="h3" heavy>
                        Join a Room
                    </Text>
                    <Row>
                        <Button
                            color="muted"
                            outline
                            onClick={() => setShowByInviteCode(true)}>
                            Join by Invite Code
                        </Button>
                        {(currentUserController.canManage) &&
                            <Button
                                color="success"
                                outline
                                onClick={() => {
                                    onClose()
                                    openCreateRoom()
                                }}
                                className="ml-2">
                                <Icon
                                    name="plus-circle"
                                    className="mr-2"
                                />
                                Create Room
                            </Button>
                        }
                    </Row>
                </Row>
            </ModalHeader>
            <ModalBody>
                {(showJoinByInviteCode) &&
                    <JoinRoomByInviteCode
                        onClose={() => setShowByInviteCode(false)}
                    />
                }
                <Nav
                    variant="underline"
                    role="tablist">
                    <NavItem>
                        <NavLink
                            active={activeTab === 'department'}
                            onClick={() => setActiveTab('department')}>
                            <Text>
                                {departmentController.shortName || 'Agency'} Rooms
                            </Text>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={activeTab === 'public'}
                            onClick={() => setActiveTab('public')}>
                            <Text>
                                Public Rooms
                            </Text>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent>
                    <TabPane
                        role="tabpanel"
                        visible={activeTab === 'department'}
                    >
                        <Col valign="top" >
                            {(activeTab === 'department') &&
                                <RoomSearchSelect
                                    key={'agency-search'}
                                    searchOnly={true}
                                    searchScope="department"
                                    departmentId={session.selectedDepartmentId}
                                    isMulti={false}
                                    className="select-inline search no-bg mt-2"
                                    menuIsOpen
                                    maxMenuHeight="calc(80vh - 150px)"
                                    customComponents={{ Option: RoomListResultItem }}
                                    autoFocus
                                    customProps={{
                                        joinRoomController,
                                        showDept: true,
                                        onClose,
                                        onOpenExtraInfoModal
                                    }}
                                />
                            }
                        </Col>
                    </TabPane>
                    <TabPane
                        role="tabpanel"
                        visible={activeTab === 'public'}
                    >
                        <Col valign="top" >
                            {(activeTab === 'public') &&
                                <>
                                    <InfoBox color="info" className='mt-2'>
                                        Public rooms are viewable to anyone in Evertel who meets the room's access criteria. If you meet the criteria set by the agency that owns the room you
                                        will be given immediate access. If not, you can request to join and will gain access once approved by that agency.
                                    </InfoBox>
                                    <RoomSearchSelect
                                        key={'global-public-search'}
                                        searchOnly={true}
                                        searchScope="public"
                                        isMulti={false}
                                        className="select-inline mt-2 search no-bg"
                                        menuIsOpen
                                        maxMenuHeight="calc(80vh - 250px)"
                                        customComponents={{ Option: RoomListResultItem }}
                                        autoFocus
                                        customProps={{
                                            joinRoomController,
                                            showDept: true,
                                            onClose,
                                            onOpenExtraInfoModal
                                        }}
                                    />
                                </>
                            }
                        </Col>
                    </TabPane>
                </TabContent>

                <PublicRoomRequestExtraInfoModal
                    visible={showExtraInfoModal}
                    onClose={() => setShowExtraInfoModal(false)}
                    onSave={(body: any) => joinRoomController.requestToJoin(joinRequestRoomId, body)}
                />

            </ModalBody>
        </Modal>
    )
})

export { JoinRoomModal }

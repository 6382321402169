
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Row, Col } from '@evertel/web/ui'
import { DocumentACLPrincipalsController } from '@evertel/document'
import { Name } from './Name'
import { ACLButtonProps, ACLButton } from './ACLButton'

interface ACLProps extends ACLButtonProps {
    departmentId: number,
    currentUserId?: number,
    documentACLPrincipalsController: DocumentACLPrincipalsController
}

export const ACL: React.FC<ACLProps> = observer(({
    acl,
    departmentId,
    documentACLController,
    documentACLPrincipalsController,
    onChangeACL
}) => {

    // handles missing acl but also filters out current department ACL which isnt used yet
    if (!acl?.id || acl?.principalId === departmentId) return null

    const canEditACL = documentACLController.canEditACL(acl.id)

    return (
        <Row className="my-2">
            <Col xs={8}>
                <Name
                    acl={acl}
                    documentACLPrincipalsController={documentACLPrincipalsController}
                />
            </Col>
            <Col
                xs={4}
                align="right">
                <ACLButton
                    acl={acl}
                    documentACLController={documentACLController}
                    onChangeACL={onChangeACL}
                    disabled={!canEditACL}
                />
            </Col>
        </Row>
    )
})
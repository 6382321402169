import { ContainerModule } from '@evertel/di'
import { AuthenticationController } from './controllers/AuthenticationController'
import { EmailVerificationController } from './controllers/EmailVerificationController'
import { RegistrationController } from './controllers/RegistrationController'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(AuthenticationController).to(AuthenticationController).inSingletonScope()
        bind(RegistrationController).to(RegistrationController)
        bind(EmailVerificationController).to(EmailVerificationController)
    })
]

export { DI_MODULES }
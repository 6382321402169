import React from 'react'
import { deal, Delegator } from '@evertel/schema-parser'
import { View } from '@react-pdf/renderer'

interface RowProps {
    value: any,
    items: any,
}

const Row: React.FC<RowProps> = ({
    value: parentValue,
    items,
    ...otherProps
}) => {

    if (!items?.length) return null

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 15
        }}>
            {items.map(({id: itemId, ...itemProps}, i: number) => {
                const itemValue = deal(parentValue, itemId)

                return (
                    <Delegator
                        key={i}
                        id={itemId}
                        value={itemValue}
                        {...itemProps}
                        {...otherProps}
                    />
                )
            })}
        </View>
    )
}

export { Row }
Row.displayName = 'Row (PDF)'

import { useHistory, useParams } from 'react-router'
import { observer } from 'mobx-react'
// evertel
import { InviteStore } from '../../../stores'

const ConsumeInvite = observer(({ match }) => {

    const {token} = useParams()
    const history = useHistory()
    InviteStore.setPendingInviteToken(token)
    history.push('/landing')

    return (
        <>
        </>
    )
})

export default ConsumeInvite
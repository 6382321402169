import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

export interface SlidePanelHeaderProps extends HTMLAttributes<HTMLDivElement> {
    className?: string
}

const SlidePanelHeader = forwardRef<HTMLDivElement, SlidePanelHeaderProps>(({
    children,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('slidepanel-header', className)

    return (
        <div
            ref={ref}
            className={_className}
            {...otherProps}>
            {children}
        </div>
    )
})

export { SlidePanelHeader }
SlidePanelHeader.displayName = 'SlidePanelHeader'

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
// evertel
import { Button, Col, Icon, InfoBox, Row, Spinner, Text, useUI } from '@evertel/web/ui'
import { copyToClipboard } from '@evertel/utils'
import { useService } from '@evertel/di'
import { DepartmentInviteLinkController } from '@evertel/invites'


const DepartmentInviteLink = observer(() => {

    const inviteLinkController = useService(DepartmentInviteLinkController, [])

    const { addToast, isConfirmed } = useUI()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            setIsLoading(true)

            try {
                await inviteLinkController.init()

                if (!inviteLinkController.currentLink) {
                    await inviteLinkController.createDepartmentInviteLink()
                }

            } catch (error: any) {
                addToast({
                    color: 'danger',
                    message: error.message
                })
            }

            setIsLoading(false)
        })()
    }, [addToast, inviteLinkController])

    const revokeLink = async () => {
        const deleteIt = async () => {
            try {
                // delete old link
                await inviteLinkController.deleteDepartmentInviteLink(inviteLinkController.currentLink?.id)

                // then create a new one
                await inviteLinkController.createDepartmentInviteLink()

            } catch (error: any) {
                addToast({
                    color: 'danger',
                    message: error.message
                })
            }
        }
        const confirmed = await isConfirmed({
            title: 'Delete Invite Link?',
            message: 'Are you sure you want to revoke this link? It will no longer work for those that try and use it.',
            acceptButton: {
                label: 'Delete',
                color: 'danger'
            }
        })
        if (confirmed) {
            deleteIt()
        }
    }

    const copyLink = () => {
        copyToClipboard(`${inviteLinkController.currentLink?.url} \n\n Access Code: ${inviteLinkController.currentLink?.token}`)

        addToast({
            color: 'success',
            message: 'Link copied!'
        })
    }

    const inviteLink = inviteLinkController.currentLink

    return (
        <InfoBox color="info">
            <Col>
                <Text>
                        New members can join using the link or access code below. Anyone joining with this link or code will be a member of your agency and will have
                        access to rooms and your full agency directory. Copy and distribute to your team!
                </Text>
                <Row className="mt-4 mb-2 code-block">
                    {(isLoading) && <Spinner width={30} invert />}
                    {(inviteLink && !isLoading) &&
                        <Text>
                            {inviteLink.url}<br/><br/>
                            Access Code: {inviteLink.token}
                        </Text>
                    }
                </Row>
                <Row
                    align="between"
                    valign="center">
                    <Button
                        color="info"
                        className="mr-4"
                        onClick={copyLink}>
                        <Icon
                            name="copy"
                            className="mr-2"
                        />
                        Copy Link
                    </Button>
                    {(inviteLink) &&
                        <Text
                            color="danger"
                            size="smaller">
                            Link expires on: {moment(inviteLink.expiresDate).format('L HH:mm')}
                        </Text>
                    }
                    <Button
                        color="danger"
                        ghost
                        onClick={revokeLink}
                        data-tooltip="When revoked, the current invite link will no longer work and a new link will be created."
                        data-tooltip-pos="top">
                        <Icon
                            name="minus-circle"
                            className="mr-2"
                        />
                        Revoke Link
                    </Button>
                </Row>
            </Col>
        </InfoBox>
    )
})

export { DepartmentInviteLink }

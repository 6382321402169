import React, { useEffect, useCallback, useRef, useState, ReactNode } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { MultiValue, SingleValue, components as reactSelectComponents, Props } from 'react-select'
// evertel
import { formatForSelect, getRole } from '@evertel/utils'
import { UserTileRawData } from '@evertel/web/user'
import { useService } from '@evertel/di'
import { SearchScopeProps, UsersSearchController } from '@evertel/blue-user'
import { APIDataBlueUser } from '@evertel/types'
import { ContentPlaceholder, Text } from '../elements'
import { CustomAsyncPaginate, CustomOptionProps } from './CustomAsyncPaginate'


interface PeopleSearchSelectProps extends Props {
    departmentId: number,
    selected: APIDataBlueUser[],
    placeholder?: string,
    showGuestBadge?: boolean,
    showPosition?: boolean,
    forceShowDepartment?: boolean,
    searchScope: SearchScopeProps,
    whereFilter?: Record<string, any>,
    className?: string,
    onEndReachedThreshold?: number,
    onSelect: (selected: APIDataBlueUser|APIDataBlueUser[]) => void,
    customComponents?: ReactNode,
    customProps?: Record<string, any>
}


const PeopleSearchSelect: React.FC<PeopleSearchSelectProps> = observer(({
    selected = [],
    departmentId,
    showGuestBadge,
    showPosition = true,
    forceShowDepartment,
    placeholder = 'Search by name, email, position, phone, or badge/id...',
    searchScope = 'department',
    whereFilter = {},
    className,
    onSelect,
    customComponents,
    onEndReachedThreshold = 80,
    ...otherProps
}) => {

    const usersSearchController = useService(UsersSearchController, [])

    const [isLoading, setIsLoading] = useState(true)
    const [isSearching, setIsSearching] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        if (!departmentId && searchScope !== 'admin') return

        usersSearchController.init({
            searchScope,
            departmentId,
            filter: {
                include: [{
                    relation: 'departmentsAccess',
                    scope: {
                        include: ['position', 'department']
                    }
                }]
            }
        })

        //needs to render once with loading true to reload async paginate
        setTimeout(() => { setIsLoading(false)}, 1)

    }, [searchScope, departmentId])

    useEffect(() => {
        if (whereFilter) {
            usersSearchController.setWhereFilter(whereFilter)
        }
    }, [whereFilter, usersSearchController])

    const search = useCallback( async (
        searchString: string,
        loadedOptions: APIDataBlueUser[],
        additional: { page: number }
    ) => {

        if (usersSearchController.searchTerm !== searchString) {
            usersSearchController.setSearchTerm(searchString)
        }
        usersSearchController.setPage(additional?.page)

        try {
            setIsSearching(true)
            await usersSearchController.search()
            setIsSearching(false)
        } catch (error) {
            console.error('ERROR: /libs/web/ui/form/PeopleSearchSelect search():', error)
        }

        // grab results and format them for the Select
        const results = formatForSelect(usersSearchController.usersLatestFetch, 'firstName+lastName', 'id')
        const hasMore = (usersSearchController.usersLatestFetch.length >= usersSearchController.limit)

        return {
            options: results || [],
            hasMore,
            additional: {
                page: additional?.page + 1 || 1
            }
        }
    }, [departmentId, searchScope])

    const onSelectOption = (user: MultiValue<APIDataBlueUser>|SingleValue<APIDataBlueUser>) => {
        if (onSelect) {
            usersSearchController.setSearchTerm('')
            onSelect(user as APIDataBlueUser)
        }
    }

    /**
     * start loading more when we're either onEndReachedThreshold (20%) from the bottom or 300px from the bottom, whichever is smaller
     * (if the list has 1000 items, then we don't need to load at 20% from bottom because there's still 200 items to scroll through)
     */
    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number): boolean => {
        if (isSearching) return false
        const maxPixelThreshold = 300
        const percentThreshold = 100 - onEndReachedThreshold

        const pxFromBottom = scrollHeight - clientHeight - scrollTop
        const percentThresholdFromBottomInPx = ((percentThreshold / 100) * (scrollHeight - clientHeight))
    
        // Determine the threshold to use (whichever is smaller)
        const thresholdToUse = Math.min(percentThresholdFromBottomInPx, maxPixelThreshold)

        // console.log('px from bottom / threshold, result', pxFromBottom, thresholdToUse, pxFromBottom <= thresholdToUse)    
        return pxFromBottom <= thresholdToUse
    }

    /**
     * Adds ability to optionally return just the recent results, or combine them.  It combines by default.
     * Leaving here as an example.
     */
    // const reduceOptions = (previousOptions: APIDataBlueUser[], loadedOptions: APIDataBlueUser[], additional: { page: number }) => {
    //     const combinedOptions = uniqBy([...previousOptions, ...loadedOptions], 'id')
    //     return combinedOptions
    // }

    if (isLoading && !otherProps.isDisabled) {
        return (
            <ContentPlaceholder
                className={className}
                style={{
                    height: 38
                }}
            />
        )
    }

    return (
        <CustomAsyncPaginate
            name="users"
            keyName={departmentId}
            loadOptions={search}
            // reduceOptions={reduceOptions}
            debounceTimeout={300}
            additional={{
                page: 1
            }}
            defaultOptions
            closeMenuOnSelect={!otherProps?.isMulti}
            shouldLoadMore={shouldLoadMore}
            value={selected}
            onChange={(customComponents) ? undefined : onSelectOption}
            noOptionsMessage={() => 'No matching users'}
            placeholder={placeholder}
            components={customComponents as any || { Option: SelectUserDisplay }}
            customProps={{
                onSelect: onSelectOption,
                showGuestBadge,
                showPosition,
                forceShowDepartment,
                departmentId,
                searchScope
            }}
            className={className}
            {...otherProps}
        />
    )
})


interface SelectCustomProps {
    onSelect: (user: any) => void
    showGuestBadge: boolean
    departmentId: number
    showPosition: boolean
    forceShowDepartment: boolean
    searchScope: SearchScopeProps
}
const SelectUserDisplay = observer((props: CustomOptionProps<APIDataBlueUser, SelectCustomProps>) => {
    const { data: userData, selectProps, innerRef } = props
    const {
        onSelect, showGuestBadge, departmentId, showPosition, forceShowDepartment, searchScope
    } = selectProps.customProps


    const getUserDepartmentAccess = () => {
        if (!userData.departmentsAccess || userData.departmentsAccess.length === 0) {
            return {}
        }

        if (searchScope === 'department' || searchScope === 'public') {
            return userData.departmentsAccess.find(da => da.departmentId === departmentId) || userData.departmentsAccess[0]
        } else if (searchScope === 'contact' || searchScope === 'admin') {
            const primaryAccess = userData.departmentsAccess.find(da => da.isPrimary === true)
            return primaryAccess || userData.departmentsAccess[0]
        }

        return userData.departmentsAccess[0]
    }

    const usersDepartmentsAccess = getUserDepartmentAccess()

    const isGuest = (showGuestBadge) ? !!(getRole(usersDepartmentsAccess?.roles) === 'guest') : false

    return (
        <reactSelectComponents.Option
            {...props}
        >
            <UserTileRawData
                user={userData}
                imageWidth={40}
                className={classNames('border-bottom py-2', {'cursor-pointer': onSelect})}
                isGuest={isGuest}
                //onClickUser={onSelect}
                subComponent={
                    <Text
                        size="small"
                        color="muted"> 
                        {(showPosition) && usersDepartmentsAccess?.positionString || 'Other'}
                        {((showPosition && isGuest) || forceShowDepartment) && ` @ ${usersDepartmentsAccess?.department?.shortName || usersDepartmentsAccess?.department?.name || 'Unknown'}`}
                    </Text>
                }
            />
        </reactSelectComponents.Option>
    )
})

export { PeopleSearchSelect }

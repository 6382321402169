/* eslint-disable camelcase */
import { makeAutoObservable, runInAction } from 'mobx'
import { decorate, inject, injectable } from 'inversify'
import { Api } from '@evertel/api'

const IS_EMAIL_VERIFIED_POLL_INTERVAL_MS = 3000 // 3 seconds

class EmailVerificationController {
    error = ''
    email = ''
    isVerified = false
    private getIsVerifiedPollInterval = 0

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = (email: string): void => {
        this.isVerified = false
        this.email = email?.toLowerCase()
        
        this.startGetIsVerifiedPoll()
    }

    resendVerificationEmail = async (): Promise<void> => {
        if (!this.email) {
            this.error = 'Please provide an email'
            return
        }
        
        await this.api.Routes.Auth0.postResendVerificationEmail(this.email)
    }

    getIsVerified = async (): Promise<void> => {
        if (!this.email) {
            this.error = 'Please provide an email'
            return
        }

        interface Auth0DataIsVerified {
            email_verified: boolean
        }
        
        // our API is a pass-through to auth0
        const response: Auth0DataIsVerified[] = await this.api.Routes.Auth0.getIsVerified(this.email)

        runInAction(() => {
            this.isVerified = response[0]?.email_verified || false
        })
    }

    startGetIsVerifiedPoll = (): void => {
        // stop poll first in case it was running so we don't create duplicates
        this.stopGetIsVerifiedPoll()

        // create poll
        this.getIsVerifiedPollInterval = setInterval(() => {
            this.getIsVerified().catch(() => {/* no-op */})

        }, IS_EMAIL_VERIFIED_POLL_INTERVAL_MS)
    }

    stopGetIsVerifiedPoll = (): void => {
        if (this.getIsVerifiedPollInterval) clearInterval(this.getIsVerifiedPollInterval)
    }
}

decorate(injectable(), EmailVerificationController)
decorate(inject(Api), EmailVerificationController, 0)

export { EmailVerificationController }

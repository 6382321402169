import React, {
    CSSProperties,
    forwardRef,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import classNames from 'classnames'
import { Transition } from 'react-transition-group'
import { NavContext } from '../sidebar/SidebarNav'

export interface NavGroupProps {
    children?: ReactNode
    className?: string
    compact?: boolean
    toggler?: string | ReactNode
    visible?: boolean
    idx?: string
}
  
const NavGroup = forwardRef<HTMLLIElement, NavGroupProps>(({
    children,
    className,
    compact,
    idx,
    toggler,
    visible,
    ...otherProps
}, ref) => {

    const [height, setHeight] = useState<number | string>()
    const navItemsRef = useRef<HTMLUListElement>(null)

    const { visibleGroup, setVisibleGroup } = useContext(NavContext)

    const [_visible, setVisible] = useState(
        Boolean(
          visible || (idx && visibleGroup && visibleGroup.toString().startsWith(idx.toString())),
        )
    )
  
    useEffect(() => {
        setVisible(Boolean(idx && visibleGroup && visibleGroup.toString().startsWith(idx.toString())))
    }, [visibleGroup])
  
    const handleTogglerOnCLick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setVisibleGroup(
          _visible ? (idx?.toString().includes('.') ? idx.slice(0, idx.lastIndexOf('.')) : '') : idx,
        )
        setVisible(!_visible)
    }
  
    const style: CSSProperties = {
        height: 0
    }
  
    const onEntering = () => {
        navItemsRef.current && setHeight(navItemsRef.current.scrollHeight)
    }
  
    const onEntered = () => {
        setHeight('auto')
    }
  
    const onExit = () => {
        navItemsRef.current && setHeight(navItemsRef.current.scrollHeight)
    }
  
    const onExiting = () => {
        // reflow is necessary to get correct height of the element
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const reflow = navItemsRef.current?.offsetHeight
        setHeight(0)
    }
  
    const onExited = () => {
        setHeight(0)
    }
  
    const transitionStyles = {
        entering: { display: 'block', height: height },
        entered: { display: 'block', height: height },
        exiting: { display: 'block', height: height },
        exited: { height: height },
    }
  
    const _className = classNames('nav-group', { show: _visible }, className)
  
    return (
        <li
            ref={ref}
            className={_className}
            {...otherProps}>
            {toggler && (
                <a
                    className="nav-link nav-group-toggle"
                    onClick={(event) => handleTogglerOnCLick(event)}>
                    {toggler}
                </a>
            )}
            <Transition
                in={_visible}
                nodeRef={navItemsRef}
                onEntering={onEntering}
                onEntered={onEntered}
                onExit={onExit}
                onExiting={onExiting}
                onExited={onExited}
                timeout={300}>
                {(state) => (
                    <ul
                        className={classNames('nav-group-items', {
                            compact: compact,
                        })}
                        style={{ ...style, ...transitionStyles[state] }}
                        ref={navItemsRef}>
                        {React.Children.map(children, (child, index) => {
                            if (React.isValidElement(child)) {
                                return React.cloneElement(child as React.ReactElement<any>, {
                                    key: index,
                                    idx: `${idx}.${index}`
                                })
                            }
                            return true
                        })}
                    </ul>
                )}
            </Transition>
        </li>
    )
})
  

export { NavGroup }
NavGroup.displayName = 'NavGroup'

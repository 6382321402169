import React from 'react'
import { observer } from 'mobx-react-lite'
import { UserTile } from '@evertel/web/user'
import { RoomTile } from '@evertel/web/room'
import { DocumentACLsController, DocumentACLPrincipalsController } from '@evertel/document'
import { APIDataDocumentACL } from '@evertel/types'

export const Name = observer(({
    acl,
    documentACLPrincipalsController
}: { acl: APIDataDocumentACL, documentACLPrincipalsController: DocumentACLPrincipalsController }) => {

    if (!acl || !acl.id || !documentACLPrincipalsController) {
        console.log('ERROR: <DocumentShareModal> <Name/> missing acl prop')
        return null
    }

    if (acl.principalType === DocumentACLsController.principalTypes.Room) {
        // ROOM
        return (
            <RoomTile
                roomId={acl.principalId}
                showMembersCount={true}
            />
        )
    } else if (acl.principalType === DocumentACLsController.principalTypes.BlueUser) {
        // USER
        return (
            <UserTile
                userId={acl.principalId}
                imageWidth={25}
            />
        )
    } else {
        return null
    }
})
import React from 'react'
import { ElementType, forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface CardHeaderProps extends HTMLAttributes<HTMLDivElement> {
    className?: string
    component?: string|ElementType
}

const CardHeader = forwardRef<HTMLDivElement, CardHeaderProps>(({
    children,
    component: Component = 'div',
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('card-header', className)

    return (
        <Component
            ref={ref}
            className={_className}
            {...otherProps}>
            {children}
        </Component>
    )
})

export { CardHeader }

import React, { FC, useContext } from 'react'
import classNames from 'classnames'
import { Reference } from 'react-popper'
// evertel
import { useForkedRef } from '@evertel/hooks'
import { Triggers } from '../../prop-types'
import { ButtonProps } from '../Button'
import { ButtonDropdownContext } from './ButtonDropdown'

export interface DropdownToggleProps extends Omit<ButtonProps, 'type'> {
    /** Enables pseudo element caret on toggler. */
    caret?: boolean
    /** Create a custom toggler which accepts any content. */
    custom?: boolean
    /** Similarly, create split button dropdowns with virtually the same markup as single button dropdowns, but with the addition of `.dropdown-toggle-split` className for proper spacing around the dropdown caret. */
    split?: boolean
    /** Sets which event handlers you’d like provided to your toggle prop. You can specify one trigger or an array of them. 'hover' | 'focus' | 'click' */
    trigger?: Triggers|Triggers[]
    ghost?: boolean
    outline?: boolean
}

const ButtonDropdownToggle: FC<DropdownToggleProps> = ({
    children,
    caret = true,
    custom,
    className,
    split,
    color = 'primary',
    trigger = 'click',
    ghost,
    outline,
    ...otherProps
}) => {

    const { dropdownToggleRef, popper, variant, visible, setVisible } = useContext(ButtonDropdownContext)
    const _className = classNames(
        {
            'drop-toggle': caret,
            'drop-toggle-split': split,
            'nav-link': variant === 'nav-item',
            [`btn-${color}`]: color && !ghost,
            ['btn-ghost-'+color]: ghost,
            ['btn-outline-'+color]: outline
        },
        'btn',
        'cursor-pointer',
        className
    )

    const triggers = {
        ...((trigger === 'click' || trigger.includes('click')) && {
            onClick: (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation()
                setVisible(!visible)
            }
        }),
        ...((trigger === 'focus' || trigger.includes('focus')) && {
            onFocus: () => setVisible(true),
            onBlur: () => setVisible(false)
        }),
        ...((trigger === 'hover' || trigger.includes('hover')) && {
            onMouseEnter: () => setVisible(true),
            onMouseLeave: () => setVisible(false)
        })
    }

    const togglerProps = {
        className: _className,
        'aria-expanded': visible,
        ...(!otherProps.disabled && { ...triggers })
    }

    // we use 'any' because Toggler can be `a` as well as `button`.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Toggler = (ref?: React.Ref<any>) => {
        const forkedRef = useForkedRef(ref, dropdownToggleRef)
        return (custom && React.isValidElement(children)) ? (
            <>
                {React.cloneElement(children as React.ReactElement<any>, {
                    'aria-expanded': visible,
                    ...(!otherProps.disabled && { ...triggers }),
                    ref: forkedRef
                })}
            </>
        ) : variant === 'nav-item' ? (
            <a
                ref={forkedRef}    
                href="#"
                {...togglerProps}>
                {children}
            </a>
        ) : (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            <div
                ref={forkedRef}
                {...togglerProps}
                tabIndex={0}
                {...otherProps}>
                {children}
                {(split) &&
                    <span className="visually-hidden">
                        Toggle Dropdown
                    </span>
                }
            </div>
        )
    }

    return (popper) ? 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <Reference>{({ ref }) => Toggler(ref)}</Reference> : Toggler(dropdownToggleRef)
}

export default ButtonDropdownToggle
ButtonDropdownToggle.displayName = 'ButtonDropdownToggle'

import moment from 'moment'
import { unparse } from 'papaparse'

export const exportCSVFile = (data: any, from: string) => {
    let processedData
    if (from.includes('history')) {
        processedData = flattenflattenHistoryData(data)
    } else {
        processedData = flattenDocumentData(data)
    }
    
    const csv = unparse(processedData)
    downloadCSVFile(csv, from)
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const downloadCSVFile = (csv: Blob | string, from = 'download') => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const blob = new Blob(['\uFEFF'+csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')

    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `${from}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const flattenflattenHistoryData = (data: any) => {
    return data.map((item: any) => ({
        ...item,
        createdBy: `${item.createdBy?.id} ${item.createdBy?.firstName} ${item.createdBy?.lastName} ${item.createdBy?.email}`,
        action: toTitleCase(item.action),
        data: formatData(item.action, item.data),
        createdDate: moment(item.createdDate).format('MM/DD/YYYY HH:mm:ss')
    }))
}

const flattenDocumentData: any = (data: any) => {   
    return data.map((item: any) => ({
        ...mapProperties(item),
        meta: convertObjectsToArrays(item.meta)
    }))
}

const mapProperties = (item: any) => ({
    'Document_Id': item.id,
    'Archived?': item.isArchived,
    'Agency_Id': item.departmentId,
    'Document_Template_Id': item.documentSchemaId,
    'Files': item.media,
    'Created_Date': item.createdDate,
    'Last_Updated_Date': item.updatedDate,
    'Document_Data': convertObjectsToArrays(item.data)
})

export const convertObjectsToArrays: any = (data: any) => {
    if (Array.isArray(data)) {
        return data.map(convertObjectsToArrays)
    } else if (typeof data === 'object' && data !== null) {
        return Object.values(data).map(convertObjectsToArrays)
    } else {
        return data
    }
}

const toTitleCase = (action: string) => {
    const words = action.split('_')
    let result = ''
    for (let i = 0; i < words.length; i++) {
        result += words[i][0].toUpperCase() + words[i].slice(1).toLowerCase() + ' '
    }
    return result.trim()
}

const formatData = (action: string, data: any) => {
    const arrayData = data
    switch (action) {
        case 'member_added':
            return `${arrayData.firstName} ${arrayData.lastName} was added to the agency`

        case 'member_role_added':
            return `${arrayData.firstName} ${arrayData.lastName} was given the ${arrayData.role} role`

        case 'member_role_removed':
            return `${arrayData.firstName} ${arrayData.lastName} role as ${arrayData.role} was removed`

        case 'member_removed':
            return `${arrayData.firstName} ${arrayData.lastName} was removed from the agency`

        case 'member_details_updated':
            return `${arrayData.firstName} ${arrayData.lastName}'s profile was updated`

        case 'member_verified':
            return `${arrayData.firstName} ${arrayData.lastName} was verified ${
                arrayData?.subAction === 'verification_code'
                    ? 'by using the agency access code'
                    : ''
            }`

        case 'member_unverified':
            return `${arrayData.firstName} ${arrayData.lastName} was unverified`

        case 'room_created':
            return `Room ${arrayData.name} was created`

        case 'department_invite_consumed':
            if (!arrayData.invite)
                return 'User accepted invite to the agency using an invite token'
            return `${
                arrayData.invite.to || 'A user'
            } accepted an agency invite using token ${arrayData.invite.token}`

        case 'room_invite_consumed':
            if (!arrayData.invite)
                return 'User accepted invite to the room using an invite token'
            return `${
                arrayData.invite.to || 'A user'
            } accepted a room invite using token ${arrayData.invite.token}`

        case 'department_invite_sent':
            return `Sent an agency invite to: ${arrayData.invites
                .map((i: any) => i.to)
                .toString()}`

        case 'room_invite_sent':
            return `Sent an invite to the room ${
                arrayData.name
            } to: ${arrayData.invites.map((i: any) => i.to).toString()}`

        case 'audit_run':
            return `A message audit was run (id: ${arrayData.auditId})`

        case 'bulletin_added':
            return `Bulletin was shared with room ${
                arrayData.name || arrayData.roomId
            }`

        case 'bulletin_removed':
            return `Bulletin was removed from room ${arrayData.name}`

        case 'subject_added':
            if (!arrayData.subject) return 'A suspect was added'
            return `${
                arrayData.subject.name || 'General Description'
            } was added as a suspect`

        case 'subject_removed':
            if (!arrayData.subject) return 'A suspect was added'
            return `${
                arrayData.instance.name || 'General Description'
            } was removed as a suspect`

        case 'vehicle_added':
            if (!arrayData.vehicle) return 'A vehicle was added'
            return `${arrayData.vehicle.year} ${arrayData.vehicle.make} ${arrayData.vehicle.model} was added as a vehicle`

        case 'vehicle_removed':
            if (!arrayData.vehicle) return 'A vehicle was removed'
            return `${arrayData.vehicle.year} ${arrayData.vehicle.make} ${arrayData.vehicle.model} was removed as a vehicle`

        case 'media_added':
            return 'Media Added'

        case 'media_removed':
            return 'Media Removed'
    }

    return undefined
}
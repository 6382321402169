import React, { HTMLAttributes } from 'react'
import { Icon } from '@evertel/web/ui'
import { lightOrDark } from '@evertel/utils'
import { APIDataDocumentSchema } from '@evertel/types'
import { IconName } from '@fortawesome/fontawesome-svg-core'

interface DocumentIconProps extends HTMLAttributes<HTMLDivElement> {
    schema: APIDataDocumentSchema,
    width?: number,
    height?: number
}

const DocumentIcon: React.FC<DocumentIconProps> = ({
    schema,
    width = 20,
    height = 20,
    ...otherProps
}) => {

    if (!schema) return null

    return (
        <Icon
            name={schema.icon as IconName}
            color={(lightOrDark(schema.color as string) === 'dark') ? 'white' : 'primary'}
            style={{
                background: schema.color,
                padding: '3px',
                borderRadius: 5,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                width,
                height
            }}
            size={height * 0.6}
            {...otherProps}
        />
    )
}

export {
    DocumentIcon
}

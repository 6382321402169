import React, { useEffect } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
// evertel
import { Badge, ContentPlaceholder, Row, Text } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { DepartmentController } from '@evertel/department'
import { APIDataBlueUser, APIDataDepartmentUserThrough, APIDataRoomMessage, APIDataThreadMessage } from '@evertel/types'
import { SessionState } from '@evertel/session'
import { DepartmentsAccessController } from '@evertel/departments-access'
import { getNonGuestPrimaryDepartmentsAccess } from '@evertel/utils'

interface MessageHeaderProps {
    message: APIDataRoomMessage|APIDataThreadMessage,
    user?: APIDataBlueUser,
    userDepartmentsAccess?: APIDataDepartmentUserThrough[],
    isGuest?: boolean,
    isRoom: boolean,
    onClickUser: (userId: number) => void
    roomDepartment?: number
}

const MessageHeader: React.FC<MessageHeaderProps> = observer(({
    message,
    user,
    userDepartmentsAccess,
    isGuest,
    isRoom,
    onClickUser,
    roomDepartment
}) => {

    const departmentController = useService(DepartmentController, [message?.id])
    const session = useService(SessionState, [])
    

    if (!message) return null


    if (!user?.firstName) return (
        <Row
            valign="center"
            className="pt-1">
            <ContentPlaceholder
                width={350}
                height={18}
            />
        </Row>
    )

    return (
        <Row valign="center" className="flex-wrap">
            <div>
                <Text
                    bold
                    onClick={() => onClickUser(user?.id)}
                    className={classNames({'cursor-pointer': onClickUser})}>
                    {(user?.firstName || '') + ' ' + (user?.lastName || '')}
                </Text>
                {(user.isBot) &&
                <Badge
                    color="gray-200"
                    textColor="muted"
                    size={10}
                    className="ml-1 mt-1 text-uppercase">
                    Bot
                </Badge>
                }
            </div>
            <div>
                <Subtext
                    user={user}
                    userDepartmentsAccess={userDepartmentsAccess}
                    isGuest={isGuest}
                    isRoom={isRoom}
                    currentUserId={session.currentUserId}
                    deptController={departmentController}
                    roomDepartment={roomDepartment}
                />
                <Text
                    color="muted"
                    size="smaller"
                    className="pl-1">
                &#183; {moment(message.publishedDate || message.createdDate).format('H:mm')}
                </Text>
            </div>
        </Row>
    )
})


interface SubtextProps {
    user: APIDataBlueUser,
    userDepartmentsAccess: APIDataDepartmentUserThrough[],
    deptController: DepartmentController,
    currentUserId: number,
    isGuest: boolean,
    isRoom: boolean,
    roomDepartment?: number
}

const Subtext: React.FC<SubtextProps> = observer(({
    user,
    userDepartmentsAccess,
    deptController,
    currentUserId,
    isGuest,
    isRoom,
    roomDepartment
}) => {

    const session = useService(SessionState, [])

    const isMe = user.id === currentUserId
    const showDepartment = isGuest || (!isRoom && !isMe)
    
    const selectedDepartment = userDepartmentsAccess?.find(da => da.departmentId === roomDepartment )
    const departmentsAccess = isGuest ? getNonGuestPrimaryDepartmentsAccess(userDepartmentsAccess) : selectedDepartment

    const position = departmentsAccess?.positionString

    useEffect(() => {
        (async () => {
            if (!showDepartment || !user?.id) return

            
            await deptController.init(departmentsAccess?.departmentId)
        })()
    }, [deptController, showDepartment, departmentsAccess?.departmentId, user?.id])

    
    let subText = ''
    
    if (!user?.id) subText = ''
    if (user.isBot) subText = 'Evertel Bot'
    
    // if user is a guest or in a thread show position and full department name
    if (showDepartment && deptController && !user.isBot) {
        subText = `${position} @ ${deptController?.shortName || deptController.department?.name}`
    }
    
    // if user is not a guest and in a room show their position only
    if (isMe || (!isGuest && isRoom)) {
        subText = position || ''
    }

    if ((!subText || !position) && !user.isBot) {
        return (
            <Text
                italic
                color="muted"
                size="smaller"
                className="pl-2">
                No position
            </Text>
        )
    }

    return (
        <Text
            color="muted"
            size="smaller"
            className="pl-2">
            {subText}
        </Text>
    )
})

export { MessageHeader }

import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'


class SchemaMediaUtils {
    constructor(
        private api: Api
    ) {
    }

    processSchemaMedia = async (
        schemaId: number,
        fields: Record<string, any>
    ): Promise<any> => {

        for (const [, value] of Object.entries(fields)) {

            if (value.type === 'checkbox' || value.type === 'checkbox-group') {
                // loop through options and find any images that need uploading
                for (const option of value.options) {
                    if (option.image instanceof File) {
                        const id = await this.uploadMedia(option.image, schemaId)
                        option.image = id
                    }
                }
            }
        }

        return fields
    }

    uploadMedia = async (file: File, schemaId: number) => {
        if (!file) return false
    
        const formData = new FormData()
        formData.append('file', file)
    
        // upload media
        const { id } = await this.api.Routes.DocumentSchema.postMediaUpload(
            schemaId,
            formData
        )
        return id
    }
}

decorate(injectable(), SchemaMediaUtils)
decorate(inject(Api), SchemaMediaUtils, 0)

export { SchemaMediaUtils }

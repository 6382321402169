import { Api as BaseApi } from '@evertel/api'
import App from './old-routes/App'
import AppVersion from './old-routes/AppVersion'
import BlueUser from './old-routes/BlueUser'
import Bot from './old-routes/Bot'
import Department from './old-routes/Department'
import DepartmentCRM from './old-routes/DepartmentCRM'
import UserCRM from './old-routes/UserCRM'


class Api extends BaseApi {
    public App: App
    public AppVersion: AppVersion
    public BlueUser: BlueUser
    public Bot: Bot
    public Department: Department
    public DepartmentCRM: DepartmentCRM
    public UserCRM: UserCRM
    static LOG_NONE = ''
    static LOG_V = 'v'
    static LOG_VV = 'vv'

    public logLevel = ''

    constructor(
        useApi: string,
        withCredentials: boolean,
        platform: string,
        buildNumber: string
    ) {
        super(
            useApi,
            withCredentials,
            platform,
            buildNumber
        )

        this.App = new App(() => {
            return this.agent
        })
        this.AppVersion = new AppVersion(() => {
            return this.agent
        })
        this.BlueUser = new BlueUser(() => {
            return this.agent
        })
        this.Bot = new Bot(() => {
            return this.agent
        })
        this.Department = new Department(() => {
            return this.agent
        })
        this.DepartmentCRM = new DepartmentCRM(() => {
            return this.agent
        })
        this.UserCRM = new UserCRM(() => {
            return this.agent
        })
    }

    defaultUserScope = {
        fields: ['id', 'firstName', 'lastName', 'publicImage', 'publicMedia', 'isBot', 'isAdmin', 'emailVerified'],
        include: [{
            relation: 'departmentsAccess',
            scope: {
                fields: ['id', 'isVerified', 'roles', 'badgeNumber', 'departmentId', 'positionString', 'assignmentString', 'isPrimary'],
                include: [
                    {
                        relation: 'department',
                        scope: {
                            fields: ['id', 'name', 'city', 'state']
                        }
                    }, {
                        relation: 'assignment'
                    }, {
                        relation: 'position'
                    }
                ]
            }
        }]
    }
}

export default Api

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation, useHistory, useParams } from 'react-router-dom'
// evertel
import {
    PageBody,
    Row, Col, Button,
    Icon, Badge, Text, InfoBox, ContentPlaceholder,
    Card, CardBody, CardHeader, CardFooter, useUI
} from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { CategorySchemasController, CloneSchemaController, GlobalSchemaCategoriesController } from '@evertel/document'
import { DocumentIcon } from '../elements/DocumentIcon'
import { GlobalCategoryPicker } from '../schema-categories/GlobalCategoryPicker'
import { SchemaPreviewModal } from './SchemaPreviewModal'
import { APIDataDocumentSchema, APIDataDocumentSchemaCategory } from '@evertel/types'
import { newShade, lightOrDark, truncate } from '@evertel/utils'
import { SessionState } from '@evertel/session'
import { UserController } from '@evertel/blue-user'

const TILEHEIGHT = 250
const TILEWIDTH = 175
const RECOMMENDEDIDS = [1,2,3, 16, 17]

interface SchemaTemplatesProps {
    onNavigationCallback: (path: string) => void
}


const SchemaTemplates: React.FC<SchemaTemplatesProps> = observer(({
    onNavigationCallback
}) => {


    const { toggleLeftNav, addToast } = useUI()
    const location = useLocation()
    const history = useHistory()
    const { target } = useParams()
    const theme = 'light' //useThemeName()

    const [selectedCategory, setSelectedCategory] = useState<APIDataDocumentSchemaCategory>({id: 1, name: 'ICS Forms'})
    const [selectedSchema, setSelectedSchema] = useState<APIDataDocumentSchema>()
    const [showDocumentModal, setShowDocumentModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isCloning, setIsCloning] = useState(false)

    const editGlobalTemplates = location.state?.isAdmin || target === 'global'

    const controller = useService(CategorySchemasController, [])
    const cloneController = useService(CloneSchemaController, [])
    const session = useService(SessionState, [])
    const userController = useService(UserController, [])

    useEffect(() => {
        toggleLeftNav('close')
    }, [])

    useEffect(() => {
        userController.init(session.currentUserId)
    }, [session.currentUserId])

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            await controller.init(selectedCategory.id as number)
            setIsLoading(false)
        })()
    }, [selectedCategory])

    const onSelectCategory = (category: APIDataDocumentSchemaCategory) => {
        setSelectedCategory(category)
    }

    const previewSchema = (schema: APIDataDocumentSchema) => {
        setSelectedSchema(schema)
        setShowDocumentModal(true)
    }

    const onCloneSchema = async (schema: APIDataDocumentSchema) => {
        if (!schema?.id || !session?.selectedDepartmentId) return

        const newName = schema.name + ' (copy)'

        setIsCloning(true)
        try {
            const newSchema = await cloneController.cloneSchema(schema.id, session?.selectedDepartmentId, newName)
            setShowDocumentModal(false)
            setTimeout(() => onNavigationCallback(`/everdocs/schema/edit/${newSchema.id}`), 500)

        } catch (error: any) {
            setShowDocumentModal(false)
            addToast({
                color: 'danger',
                title: 'Clone Unsuccessful',
                message: error.message || error
            })
        }
        setIsCloning(false)
    }

    return (
        <PageBody>
            <Row>
                <Col
                    md={3}
                    valign="top">
                    <Row>
                        {(editGlobalTemplates && userController.user?.isAdmin) ?
                            <Button
                                color="success"
                                onClick={() => history.push({
                                    pathname: '/everdocs/schema/add/global',
                                    state: { isAdmin: true, from: location.pathname }
                                })}>
                                <Icon
                                    name="plus"
                                    color="white"
                                    className="mr-2"
                                />
                                Create Global Template
                            </Button>
                        :
                            <Button
                                color={(theme === 'light') ? 'muted' : 'gray700'}
                                className="mb-3"
                                onClick={() => onNavigationCallback('/everdocs/')}>
                                <Icon
                                    name="chevron-left"
                                    color="white"
                                    className="mr-2"
                                />
                                Back to My EverDocs
                            </Button>
                        }
                    </Row>
                    <Row className="mt-3 mb-3">
                        <Col>
                            <Text tag="h4">
                                EverDoc Templates Catalog
                            </Text>
                        </Col>
                    </Row>
                    <GlobalCategoryPicker
                        onClickCategory={onSelectCategory}
                        selectedCategoryId={selectedCategory.id as number}
                    />
                </Col>
                {/* body ---------------------------------------------------------------------------------------------------------------------- */}
                <Col valign="top">
                    {(editGlobalTemplates && userController.user?.isAdmin) &&
                        <InfoBox color="warning">
                            <Text>You are editing Global Templates!</Text>
                        </InfoBox>
                    }
                    <Card className='big-card'>
                        <CardHeader className="bg-white">
                            <Text
                                tag="h3"
                                className="float-left text-capitalize">
                                {selectedCategory.name}
                            </Text>
                            {(!!controller.schemas?.length) &&
                                <Badge
                                    color="gray"
                                    className="ml-2">
                                    {controller.schemas?.length}
                                </Badge>
                            }
                        </CardHeader>
                        <CardBody style={{minHeight: '50vh'}}>
                            {(selectedCategory.id === 0) ?
                                <RecommendedCategories
                                    selectedCategoryId={selectedCategory.id as number}
                                    onClickCategory={onSelectCategory}
                                />
                            :
                                <Row className="flex-wrap">
                                    {(isLoading) &&
                                        <ContentPlaceholder
                                            className="mx-4"
                                            width={TILEWIDTH}
                                            height={TILEHEIGHT}
                                        />
                                    }
                                    {controller.schemas?.map((schema: APIDataDocumentSchema) => {
                                        const bgColor = newShade(schema.color as string, (lightOrDark(schema.color as string) === 'light') ? 20 : 70)

                                        // only show disabled templates to Admins
                                        if (editGlobalTemplates && !userController.user?.isAdmin && !schema.isEnabled) return null

                                        return (
                                            <Card
                                                onClick={() => previewSchema(schema)}
                                                className="mx-4 mb-4"
                                                style={{
                                                    width: TILEWIDTH,
                                                    height: TILEHEIGHT,
                                                    opacity: (!schema.isEnabled) ? .5 : 1
                                                }}>
                                                <CardHeader
                                                    className="p-2"
                                                    style={{
                                                        backgroundColor: bgColor,
                                                        height: 60
                                                    }}>
                                                    <div>
                                                        <Text
                                                            tag="div"
                                                            color={(lightOrDark(bgColor) === 'light') ? 'primary' : 'white'}
                                                            style={{
                                                                overflow: 'hidden',
                                                                display: '-webkit-box',
                                                                textOverflow: 'ellipsis',
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient: 'vertical'
                                                            }}>
                                                            {schema.name}
                                                        </Text>
                                                    </div>
                                                </CardHeader>
                                                <CardBody className="p-2">
                                                    <Text
                                                        tag="div"
                                                        color="muted"
                                                        size={13}
                                                        style={{
                                                            height: 100,
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                        {truncate(schema.description, 135)}
                                                    </Text>
                                                </CardBody>
                                                <CardFooter>
                                                    <Row>
                                                        <DocumentIcon
                                                            schema={schema}
                                                            className="mr-2"
                                                        />
                                                        {(!schema.isEnabled) &&
                                                            <Text
                                                                color="muted"
                                                                size="smaller"
                                                                italic>
                                                                Disabled
                                                            </Text>
                                                        }
                                                    </Row>
                                                </CardFooter>
                                            </Card>
                                        )
                                    })}
                                </Row>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <SchemaPreviewModal
                visible={showDocumentModal}
                isLoading={isCloning}
                schema={selectedSchema}
                onClose={() => setShowDocumentModal(false)}
                onUse={onCloneSchema}
            />
        </PageBody>
    )
})

const RecommendedCategories = observer(({
    selectedCategoryId,
    onClickCategory
}: {selectedCategoryId: number, onClickCategory: (category: APIDataDocumentSchemaCategory) => void}) => {

    const catsController = useService(GlobalSchemaCategoriesController, [])

    useEffect(() => {
        catsController.init()
    }, [])

    if (selectedCategoryId !== 0) return null

    const recCats = catsController.categories?.filter((c: APIDataDocumentSchemaCategory) => RECOMMENDEDIDS.includes(c.id as number))
    
    return (
        <Col>
            <Row className="flex-wrap">
                {recCats.map((cat: APIDataDocumentSchemaCategory) => {
                    //const bgColor = stringToHslColor(cat.name as string)
                    return (
                        <InfoBox
                            key={cat.id}
                            color="light"
                            onClick={() => onClickCategory(cat)}
                            className="d-flex mr-4 cursor-pointer"
                            style={{
                                width: 200,
                                height: 100,
                                //backgroundColor: bgColor,
                                alignItems: 'end'
                            }}>
                            <Text
                                tag="h3"
                                color="primary">
                                {cat.name}
                            </Text>
                        </InfoBox>
                    )
                })}
            </Row>
        </Col>
    )
})

export {
    SchemaTemplates
}

import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { IconName, IconStyle } from '@fortawesome/fontawesome-svg-core'
import { Icon } from '@evertel/web/ui'

interface EmojiButtonProps {
    id: string,
    icon?: IconName, // name of icon (fontAwesome)
    iconColor?: string,
    iconSize?: number,
    iconType?: IconStyle,
    onClick?: () => void,
    className?: string
}

const EmojiButton: React.FC<EmojiButtonProps> = observer(({
    id,
    icon = 'face-smile-plus',
    iconColor = 'primary',
    iconSize = 16,
    iconType,
    onClick,
    className,
    ...otherProps
}) => {

    return (
        <div
            id={id}
            className={classNames(
                'emoji-button',
                {
                    'cursor-pointer': onClick,
                    className: className
                }
            )}
            onClick={onClick}
            {...otherProps}>
            <Icon
                color={iconColor}
                name={icon}
                size={iconSize}
                type={iconType}
            />
        </div>
    )
})

export { EmojiButton }

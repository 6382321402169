import { forwardRef, ImgHTMLAttributes, MouseEventHandler, useRef, useState } from 'react'
import classNames from 'classnames'
import { ImageResizeMode } from 'react-native'
import brokenImage from './ImageBroken'

// TODO: temporary workaround for missing cookie intermittent img download issue
const BAND_AID_RETRY_ATTEMPTS = 5

export interface ImageProps extends ImgHTMLAttributes<HTMLOrSVGImageElement> {
    url: string,
    fileName?: string,
    align?: 'start' | 'center' | 'end',
    className?: string,
    flexibility?: 'fix-fluid' | 'fluid' | 'fixed',
    rounded?: boolean,
    thumbnail?: boolean,
    onClick?: MouseEventHandler<HTMLImageElement>,
    resizeMode?: ImageResizeMode,
    width?: number | string,
    height?: number | string,
    variant?: 'tile' | 'file-list' | 'file-attachment',
}

const Image = forwardRef<HTMLImageElement, ImageProps>(({
    url,
    fileName,
    align,
    className,
    flexibility = 'fix-fluid',
    rounded,
    thumbnail,
    onClick,
    resizeMode,
    ...otherProps
}, ref) => {

    const errorCount = useRef(0)
    const [imgKey, setImgKey] = useState(0)

    const dimensions = {
        //have to have px for css calc()/max() to work
        width: !otherProps.width ? '400px' : `${Number(otherProps.width)}px`,
        height: !otherProps.height ? '400px' : `${Number(otherProps.height)}px`
    }

    // if (otherProps.width && otherProps.height) {
    //     if (otherProps.width > otherProps.height) {
    //         dimensions.width = null
    //     } else {
    //         dimensions.height = null
    //     }
    // }

    const onClickFiltered = (otherProps.variant === 'tile') ? onClick : undefined

    const _className = classNames({
        [`float-${align}`]: align && (align === 'start' || align === 'end'),
        'd-block mx-auto': align && align === 'center',
        'img-fluid': flexibility === 'fluid',
        'rounded': rounded,
        'img-thumbnail': thumbnail,
        [`object-fit-${resizeMode}`]: resizeMode,
        'cursor-pointer': onClickFiltered
    },
    (otherProps.variant === 'tile') ? className : ''
    )

    const styles = {
        ...(flexibility === 'fix-fluid' && {
            maxWidth: `min(${dimensions.width}, 100%)`,
            maxHeight: `min(${dimensions.height}, calc(100vw - 250px))`
        }),
        ...(flexibility === 'fixed' && {
            width: `${dimensions.width}`,
            height: `${dimensions.height}`
        })
    }

    const onImageError = e => {
        if (errorCount.current < BAND_AID_RETRY_ATTEMPTS) {
            // if we rerender it might cause src={url} to revert, so using useRef

            errorCount.current++

            // TODO: temporary workaround for missing cookie intermittent img download issue
            e.target.src = `${e.target.src}?e=${errorCount.current}`

        } else {
            e.target.src = brokenImage
        }
    }

    return (
        <img
            key={imgKey}
            ref={ref}
            src={url}
            onClick={onClickFiltered}
            onError={onImageError} 
            className={_className}
            alt={fileName}
            style={styles}
            {...otherProps}
            //must not set the width and height defined in otherProps
            width={null}
            height={null}
        />
    )
})

export { Image }

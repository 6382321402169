type Value = Record<string, any>
type Schema = {
    id?: string|number,
    type: string,
    required?: boolean,
    items: Schema[]
}


const validate = (value: Record<string, any>, schema: Schema) => {
    if (schema.type === 'form' || schema.type === 'container' || schema.type === 'row') {
        return validateForm(value, schema.items)

    } else if (schema.type === 'list') {
        return validateList(value, schema)
        
    } else if (schema.type === 'media') {
        return null

    } else if (typeof(schema.id) !== 'undefined') {
        return validateField(value, schema)
    }

    return null
}

const validateField = (value: Value, schema: Schema) => {
    if (!schema.required || value) {
        return null
    }

    return 'This field is required'
}

const validateForm = (value: Record<string, any>, schema: Schema[]) => {
    if (!value || !schema) return null
    let errors = {}

    schema.forEach((schema: Schema) => {
        const item = schema.id
            ? {
                [schema.id]: validate(value[schema.id], schema)
            }
            : validate(value, schema)

        errors = {
            ...errors,
            ...item
        }
    })

    return errors
}

const validateList = (value: Record<string, any>, schema: Schema) => {
    let errors: any[] = []

    const newSchema = JSON.parse(JSON.stringify(schema))

    if (newSchema.item && newSchema.item.type !== 'form' && newSchema.item.type !== 'row') {
        newSchema.item = {
            type: 'row',
            items: [newSchema.item]
        }
    }

    if (!value) {
        return errors
    }

    if (Array.isArray(value)) {
        value.forEach((v: any) => {
            errors = errors.concat({
                _id: v._id,
                ...validate(
                    v,
                    newSchema.item || newSchema
                )
            })
        })
    }

    return errors
}

const hasErrors = (errors: Record<string, any>) => {
    if (!errors) {
        return false
    }

    if (typeof errors === 'string') {
        return true
    }

    for (const [, value] of Object.entries(errors)) {
        const result = hasErrors(value)

        if (result) {
            return true
        }
    }

    return false
}

const countErrors = (errors: Record<string, any>) => {
    if (!errors) {
        return 0
    }

    if (typeof errors === 'string') {
        return 1
    }

    let count = 0

    for (const [, value] of Object.entries(errors)) {
        const result = hasErrors(value)

        if (result) {
            count++
        }
    }

    return count
}

export {
    validate,
    hasErrors,
    countErrors
}

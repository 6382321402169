import React, { useState, useRef } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { Text, Row, Collapse, Map } from '@evertel/web/ui'
import Wrapper from './Wrapper'
import { MapValue } from '@evertel/types'
import { observer } from 'mobx-react-lite'

// Evertel api
const GOOGLE_API_KEY = 'AIzaSyChZxzna4W6pmBoe3PFibh9saY_DOU1T3Q'

export interface MapWriteWidgetProps {
    id: number|string,
    value: MapValue,
    onChange?: (id: string|number, value: MapValue) => void,
    title: string,
    required?: boolean,
    multiline?: boolean,
    errors?: string[],
    scope?: string
}

const MapWriteWidget: React.FC<MapWriteWidgetProps> = observer(({
    id,
    value = {
        address: '',
        geometry: {
            location: {
                lat: 37.0902,
                lng: -95.7129
            }
        }
    },
    onChange,
    title,
    required,
    scope,
    errors,
    ...otherProps
}) => {

    const inputRef = useRef<HTMLInputElement>(null)
    const [isMapLoaded, setIsMapLoaded] = useState(false)

    // handle any old data that was just a string
    if (typeof value === 'string') {
        value = {
            address: value,
            geometry: {
                location: {
                    lat: 37.0902,
                    lng: -95.7129
                }
            }
        }
    }

    return (
        <Wrapper
            title={title}
            required={required}
            errors={errors}
            {...otherProps}>
            <Row className='position-relative'>
                { isMapLoaded && 
                <Autocomplete
                    ref={inputRef}
                    onPlaceSelected={(place) => {
                        onChange && onChange(id, {
                            address: place?.formatted_address,
                            geometry: {
                                location: {
                                    lat: place?.geometry?.location?.lat(),
                                    lng: place?.geometry?.location?.lng()
                                }
                            }
                        })
                    }}
                    defaultValue={value?.address}
                    options={{
                        componentRestrictions: { country: 'us' },
                        types: [ 'geocode', 'establishment'],
                        fields: [ 'formatted_address', 'geometry', 'name' ]
                    }}
                    className="form-control"
                    style={{
                        position: 'absolute',
                        zIndex: 1,
                        marginTop: 10,
                        marginLeft: 10,
                        width: '97%'
                    }}
                />
                }
            </Row>
            <Row style={{height: (scope === 'schemaBuilder') ? '20vh' : '40vh'}}>
                <Map
                    apiKey={GOOGLE_API_KEY}
                    center={value?.geometry?.location}
                    zoom={14}
                    onChange={(value, { results }: { results: Record<string, any>}) => {
                        onChange && onChange(id, {
                            address: results[0].formatted_address,
                            geometry: {
                                location: value
                            }
                        })
                        if (inputRef.current && scope !== 'schemaBuilder') inputRef.current.value = results[0].formatted_address
                    }}
                    onLoaded={() => setIsMapLoaded(true)}
                />
            </Row>
        </Wrapper>
    )
})

// ----------------------------------------------------------------------------------------

export interface MapReadWidgetProps {
    value?: MapValue,
    title?: string,
    empty?: string,
    contentType?: string,
    truncate?: boolean
}

const MapReadWidget: React.FC<MapReadWidgetProps> = ({
    value = {
        address: '',
        geometry: {
            location: {
                lat: 37.0902,
                lng: -95.7129
            }
        }
    },
    title
}) => {
    
    const [showMap, setShowMap] = useState(false)

    if (typeof value === 'string') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        {/* @ts-ignore */}
        value = {
            address: value
        }
    }

    const { address, geometry, displayName } = value

    return (
        <Wrapper
            value={value}
            title={title}>
            <Text
                color={((address ?? displayName) && geometry) ? 'secondary' : 'primary'}
                {...((address ?? displayName) && geometry) && {onClick: () => setShowMap(!showMap)}}>
                {address || displayName || '---'}
            </Text>
            {((address || displayName) && geometry) &&
                <Collapse visible={showMap}>
                    <Row style={{height: '40vh'}}>
                        <Map
                            apiKey={GOOGLE_API_KEY }
                            center={geometry.location}
                            zoom={11}
                        />
                    </Row>
                </Collapse>
            }
            
        </Wrapper>
    )
}

export {
    MapWriteWidget,
    MapReadWidget
}

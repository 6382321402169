import React, { memo } from 'react'
import { Icon, Text, Col } from '@evertel/web/ui'

interface SpacerWidgetProps {
    border?: boolean
    height?: number|string
    scope?: string
}

const SpacerWidget: React.FC<SpacerWidgetProps> = ({
    border,
    height = 30,
    scope
}) => {

    if (typeof height === 'string') {
        height = (isNaN(parseInt(height, 10))) ? 30 : parseInt(height, 10)
    }
    
    return (
        <Col
            align="center"
            valign="center"
            className="spacer"
            // we have a minHeight on rows in the schemaBuilder so if height is less it looks funky
            height={(scope === 'schemaBuilder' && height < 85) ? 85 : height}> 
            {(border) && <hr/>}
            {(scope === 'schemaBuilder' && !border) &&
                <div>
                    <Icon
                        name="arrows-up-down"
                        color="muted"
                        className="mr-2"
                    />
                    <Text color="muted">
                        Spacer ({height})
                    </Text>
                </div>
            }
        </Col>
    )
}

export {
    SpacerWidget
}


/** Generated code, do not modify !!!*/

/** Alert */
export interface APIDataAlert {
    id?: number
    title?: string
    message?: string
    image?: string
    objectId?: number
    type?: string
    data?: APIDataAny
    /** From BlueUser hasMany Alert as alerts */
    blueUserId?: number
    createdTime?: Date
}

/** Alert Metadata */
export const APIMetaDataAlertPropertyNames = ['id', 'title', 'message', 'image', 'objectId', 'type', 'data', 'blueUserId', 'createdTime']
export const APIMetaDataAlertRelationNames = []

export const APIMetaDataAlertProperties = {
    id: {
        type: 'number'
    },
    title: {
        type: 'string',
        required: 'true'
    },
    message: {
        type: 'string',
        required: 'true'
    },
    image: {
        type: 'string',
        required: 'false'
    },
    objectId: {
        type: 'number',
        required: 'true'
    },
    type: {
        type: 'string',
        required: 'true',
        enum: 'connection.new,connection.enabled,keyExchange'
    },
    data: {
        type: 'APIDataAny'
    },
    blueUserId: {
        type: 'number',
        relationName: 'alerts'
    },
    createdTime: {
        type: 'Date'
    }
}

export const APIMetaDataAlertRelations = {
}

/** AnonymousUserImage */
export interface APIDataAnonymousUserImage {
    id?: number
    x1?: string
    x2?: string
    x3?: string
}

/** AnonymousUserImage Metadata */
export const APIMetaDataAnonymousUserImagePropertyNames = ['id', 'x1', 'x2', 'x3']
export const APIMetaDataAnonymousUserImageRelationNames = []

export const APIMetaDataAnonymousUserImageProperties = {
    id: {
        type: 'number'
    },
    x1: {
        type: 'string',
        required: 'true'
    },
    x2: {
        type: 'string',
        required: 'true'
    },
    x3: {
        type: 'string',
        required: 'true'
    }
}

export const APIMetaDataAnonymousUserImageRelations = {
}

/** AppVersion */
export interface APIDataAppVersion {
    id?: number
}

/** AppVersion Metadata */
export const APIMetaDataAppVersionPropertyNames = ['id']
export const APIMetaDataAppVersionRelationNames = []

export const APIMetaDataAppVersionProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataAppVersionRelations = {
}

/** App */
export interface APIDataApp {
    id?: number
}

/** App Metadata */
export const APIMetaDataAppPropertyNames = ['id']
export const APIMetaDataAppRelationNames = []

export const APIMetaDataAppProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataAppRelations = {
}

/** Audit */
export interface APIDataAudit {
    id?: number
    description?: string
    options?: APIDataAny
    status?: string
    version?: number
    fileUrl?: string
    fileName?: string
    fileKey?: string
    contentLength?: number
    mimetype?: string
    timeZone?: string
    meta?: APIDataAny
    type?: string
    /** From Department hasMany Audit as audits */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** From relation belongsTo BlueUser as createdBy */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    createdBy?: APIDataBlueUser
    createdTime?: Date
}

/** Audit Metadata */
export const APIMetaDataAuditPropertyNames = ['id', 'description', 'options', 'status', 'version', 'fileUrl', 'fileName', 'fileKey', 'contentLength', 'mimetype', 'timeZone', 'meta', 'type', 'departmentId', 'blueUserId', 'createdTime']
export const APIMetaDataAuditRelationNames = ['department', 'createdBy']

export const APIMetaDataAuditProperties = {
    id: {
        type: 'number'
    },
    description: {
        type: 'string'
    },
    options: {
        type: 'APIDataAny',
        default: '{}'
    },
    status: {
        type: 'string',
        required: 'true',
        enum: 'processing,complete,archived,failed'
    },
    version: {
        type: 'number',
        default: '1'
    },
    fileUrl: {
        type: 'string'
    },
    fileName: {
        type: 'string'
    },
    fileKey: {
        type: 'string'
    },
    contentLength: {
        type: 'number'
    },
    mimetype: {
        type: 'string'
    },
    timeZone: {
        type: 'string'
    },
    meta: {
        type: 'APIDataAny',
        default: '{}'
    },
    type: {
        type: 'string',
        default: 'random',
        enum: 'random,scheduled,private'
    },
    departmentId: {
        type: 'number',
        relationName: 'audits'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    blueUserId: {
        type: 'number',
        relationName: 'createdBy'
    },
    createdBy: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdTime: {
        type: 'Date'
    }
}

export const APIMetaDataAuditRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    },
    createdBy: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** Auth0 */
export interface APIDataAuth0 {
    id?: number
}

/** Auth0 Metadata */
export const APIMetaDataAuth0PropertyNames = ['id']
export const APIMetaDataAuth0RelationNames = []

export const APIMetaDataAuth0Properties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataAuth0Relations = {
}

/** BanIp */
export interface APIDataBanIp {
    id?: number
}

/** BanIp Metadata */
export const APIMetaDataBanIpPropertyNames = ['id']
export const APIMetaDataBanIpRelationNames = []

export const APIMetaDataBanIpProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataBanIpRelations = {
}

/** BlueUser */
export interface APIDataBlueUser {
    id?: number
    isEnabled?: boolean
    passwordCreatedDate?: Date
    departmentRequest?: string
    stripeCustomerId?: string
    isAdmin?: boolean
    firstName?: string
    lastName?: string
    publicImage?: string
    phoneNumber?: string
    meta?: APIDataAny
    isBot?: boolean
    isSearchable?: boolean
    auth0?: APIDataAny
    emailVerified?: boolean
    email?: string
    created?: Date
    /** Relation hasMany DepartmentUserThrough */
    departmentsAccess?: APIDataDepartmentUserThrough[]
    /** Relation hasMany Room */
    managedRooms?: APIDataRoom[]
    /** Relation hasMany Media */
    identifyingDocuments?: APIDataMedia[]
    /** From relation belongsTo Media as publicMedia */
    publicMediaId?: number
    /** Relation belongsTo Media */
    publicMedia?: APIDataMedia
    /** Relation hasMany MediaMultiPartUpload */
    mediaMultiPartUploads?: APIDataMediaMultiPartUpload[]
    /** Relation hasMany RoomJoinRequest */
    roomJoinRequests?: APIDataRoomJoinRequest[]
    signupDate?: Date
    lastUpdated?: Date
}

/** BlueUser Metadata */
export const APIMetaDataBlueUserPropertyNames = ['id', 'isEnabled', 'passwordCreatedDate', 'departmentRequest', 'stripeCustomerId', 'isAdmin', 'firstName', 'lastName', 'publicImage', 'phoneNumber', 'meta', 'isBot', 'isSearchable', 'auth0', 'emailVerified', 'email', 'created', 'publicMediaId', 'signupDate', 'lastUpdated']
export const APIMetaDataBlueUserRelationNames = ['departmentsAccess', 'managedRooms', 'identifyingDocuments', 'publicMedia', 'mediaMultiPartUploads', 'roomJoinRequests']

export const APIMetaDataBlueUserProperties = {
    id: {
        type: 'number'
    },
    isEnabled: {
        type: 'boolean',
        default: 'true'
    },
    passwordCreatedDate: {
        type: 'Date'
    },
    departmentRequest: {
        type: 'string'
    },
    stripeCustomerId: {
        type: 'string'
    },
    isAdmin: {
        type: 'boolean',
        default: 'false'
    },
    firstName: {
        type: 'string',
        default: ''
    },
    lastName: {
        type: 'string',
        default: ''
    },
    publicImage: {
        type: 'string'
    },
    phoneNumber: {
        type: 'string'
    },
    meta: {
        type: 'APIDataAny',
        default: '{}'
    },
    isBot: {
        type: 'boolean',
        default: 'false'
    },
    isSearchable: {
        type: 'boolean',
        default: 'true'
    },
    auth0: {
        type: 'APIDataAny',
        default: '{}'
    },
    emailVerified: {
        type: 'boolean',
        default: 'false'
    },
    email: {
        type: 'string',
        required: 'true'
    },
    created: {
        type: 'Date'
    },
    departmentsAccess: {
        type: 'DepartmentUserThrough[]',
        isRelationshipProperty: 'true'
    },
    managedRooms: {
        type: 'Room[]',
        isRelationshipProperty: 'true'
    },
    identifyingDocuments: {
        type: 'Media[]',
        isRelationshipProperty: 'true'
    },
    publicMediaId: {
        type: 'number',
        relationName: 'publicMedia'
    },
    publicMedia: {
        type: 'Media',
        isRelationshipProperty: 'true'
    },
    mediaMultiPartUploads: {
        type: 'MediaMultiPartUpload[]',
        isRelationshipProperty: 'true'
    },
    roomJoinRequests: {
        type: 'RoomJoinRequest[]',
        isRelationshipProperty: 'true'
    },
    signupDate: {
        type: 'Date'
    },
    lastUpdated: {
        type: 'Date'
    }
}

export const APIMetaDataBlueUserRelations = {
    settings: {
        type: 'hasOne',
        model: 'UserSettings',
        foreignKey: ''
    },
    departmentsAccess: {
        type: 'hasMany',
        model: 'DepartmentUserThrough'
    },
    alerts: {
        type: 'hasMany',
        model: 'Alert',
        foreignKey: '',
        options: '[object Object]'
    },
    managedRooms: {
        type: 'hasMany',
        model: 'Room',
        foreignKey: 'blueUserId',
        through: 'RoomManagerThrough'
    },
    reads: {
        type: 'hasMany',
        model: 'Read',
        foreignKey: 'blueUserId',
        options: '[object Object]'
    },
    reactions: {
        type: 'hasMany',
        model: 'Reaction',
        foreignKey: 'blueUserId',
        through: 'ReactionUserThrough',
        options: '[object Object]'
    },
    roomMessages: {
        type: 'hasMany',
        model: 'RoomMessage',
        foreignKey: 'ownerId',
        options: '[object Object]'
    },
    threadMessages: {
        type: 'hasMany',
        model: 'ThreadMessage',
        foreignKey: 'ownerId',
        options: '[object Object]'
    },
    threads: {
        type: 'hasMany',
        model: 'Thread',
        foreignKey: 'blueUserId',
        through: 'ThreadUserThrough',
        options: '[object Object]'
    },
    accessTokens: {
        type: 'hasMany',
        model: 'ExtendedAccessToken',
        foreignKey: 'userId',
        options: '[object Object]'
    },
    devices: {
        type: 'hasMany',
        model: 'Device',
        foreignKey: 'blueUserId',
        options: '[object Object]'
    },
    identifyingDocuments: {
        type: 'hasMany',
        model: 'Media',
        through: 'UserIdMediaThrough'
    },
    eventLogs: {
        type: 'hasMany',
        model: 'EventLog',
        foreignKey: 'blueUserId',
        options: '[object Object]'
    },
    invitesSent: {
        type: 'hasMany',
        model: 'Invite',
        foreignKey: 'fromId',
        options: '[object Object]'
    },
    invitesReceived: {
        type: 'hasMany',
        model: 'Invite',
        foreignKey: 'toUserId',
        options: '[object Object]'
    },
    previousPasswords: {
        type: 'hasMany',
        model: 'UserPreviousPassword',
        foreignKey: 'blueUserId',
        options: '[object Object]'
    },
    consumedInvites: {
        type: 'hasMany',
        model: 'Invite',
        foreignKey: 'blueUserId',
        through: 'InviteConsumerThrough',
        description: 'Invites you have consumed',
        options: '[object Object]'
    },
    resetPasswordToken: {
        type: 'hasMany',
        model: 'ResetPasswordToken',
        foreignKey: 'blueUserId',
        options: '[object Object]'
    },
    enrollMfaToken: {
        type: 'hasMany',
        model: 'EnrollMfaToken',
        foreignKey: 'blueUserId',
        options: '[object Object]'
    },
    CRMData: {
        type: 'hasMany',
        model: 'UserCRM',
        options: '[object Object]'
    },
    media: {
        type: 'hasMany',
        model: 'Media',
        foreignKey: 'ownerId',
        options: '[object Object]'
    },
    publicMedia: {
        type: 'belongsTo',
        model: 'Media',
        foreignKey: 'publicMediaId'
    },
    mediaMultiPartUploads: {
        type: 'hasMany',
        model: 'MediaMultiPartUpload',
        foreignKey: 'ownerId'
    },
    roomJoinRequests: {
        type: 'hasMany',
        model: 'RoomJoinRequest',
        foreignKey: 'requestedById'
    }
}

/** BotCampaignMessageThrough */
export interface APIDataBotCampaignMessageThrough {
    id?: number
    /** From relation belongsTo ThreadMessage as message */
    threadMessageId?: number
    /** Relation belongsTo ThreadMessage */
    message?: APIDataThreadMessage
    /** From BotCampaign hasMany ThreadMessage as threadMessages */
    botCampaignId?: number
    /** Relation belongsTo BotCampaign */
    campaign?: APIDataBotCampaign
}

/** BotCampaignMessageThrough Metadata */
export const APIMetaDataBotCampaignMessageThroughPropertyNames = ['id', 'threadMessageId', 'botCampaignId']
export const APIMetaDataBotCampaignMessageThroughRelationNames = ['message', 'campaign']

export const APIMetaDataBotCampaignMessageThroughProperties = {
    id: {
        type: 'number'
    },
    threadMessageId: {
        type: 'number',
        relationName: 'message'
    },
    message: {
        type: 'ThreadMessage',
        isRelationshipProperty: 'true'
    },
    botCampaignId: {
        type: 'number',
        relationName: 'threadMessages'
    },
    campaign: {
        type: 'BotCampaign',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataBotCampaignMessageThroughRelations = {
    message: {
        type: 'belongsTo',
        model: 'ThreadMessage',
        foreignKey: 'threadMessageId'
    },
    campaign: {
        type: 'belongsTo',
        model: 'BotCampaign',
        foreignKey: 'botCampaignId'
    }
}

/** BotCampaign */
export interface APIDataBotCampaign {
    id?: number
    title?: string
    description?: string
    state?: string
    scheduledDate?: Date
    data?: APIDataAny
    targetedUsersCount?: number
    createdDate?: Date
    updatedDate?: Date
}

/** BotCampaign Metadata */
export const APIMetaDataBotCampaignPropertyNames = ['id', 'title', 'description', 'state', 'scheduledDate', 'data', 'targetedUsersCount', 'createdDate', 'updatedDate']
export const APIMetaDataBotCampaignRelationNames = []

export const APIMetaDataBotCampaignProperties = {
    id: {
        type: 'number'
    },
    title: {
        type: 'string'
    },
    description: {
        type: 'string'
    },
    state: {
        type: 'string',
        default: 'draft'
    },
    scheduledDate: {
        type: 'Date'
    },
    data: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    targetedUsersCount: {
        type: 'number',
        default: '0'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    }
}

export const APIMetaDataBotCampaignRelations = {
    threadMessages: {
        type: 'hasMany',
        model: 'ThreadMessage',
        through: 'BotCampaignMessageThrough',
        options: '[object Object]'
    }
}

/** Bot */
export interface APIDataBot {
    id?: number
}

/** Bot Metadata */
export const APIMetaDataBotPropertyNames = ['id']
export const APIMetaDataBotRelationNames = []

export const APIMetaDataBotProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataBotRelations = {
}

/** BulletinMediaThrough */
export interface APIDataBulletinMediaThrough {
    id?: number
    /** From Bulletin hasMany Media as media */
    bulletinId?: number
    /** Relation belongsTo Bulletin */
    bulletin?: APIDataBulletin
    /** From relation belongsTo Media as media */
    mediaId?: number
    /** Relation belongsTo Media */
    media?: APIDataMedia
}

/** BulletinMediaThrough Metadata */
export const APIMetaDataBulletinMediaThroughPropertyNames = ['id', 'bulletinId', 'mediaId']
export const APIMetaDataBulletinMediaThroughRelationNames = ['bulletin', 'media']

export const APIMetaDataBulletinMediaThroughProperties = {
    id: {
        type: 'number'
    },
    bulletinId: {
        type: 'number',
        relationName: 'media'
    },
    bulletin: {
        type: 'Bulletin',
        isRelationshipProperty: 'true'
    },
    mediaId: {
        type: 'number',
        relationName: 'media'
    },
    media: {
        type: 'Media',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataBulletinMediaThroughRelations = {
    bulletin: {
        type: 'belongsTo',
        model: 'Bulletin',
        foreignKey: 'bulletinId'
    },
    media: {
        type: 'belongsTo',
        model: 'Media',
        foreignKey: 'mediaId'
    }
}

/** BulletinSubject */
export interface APIDataBulletinSubject {
    id?: number
    name?: string
    phone?: string
    socialSecurity?: string
    birthDate?: Date
    carriesWeapons?: boolean
    lastLocation?: string
    violencePotential?: string
    description?: string
    associates?: string
    causeToArrest?: boolean
    chargesForBooking?: string
    isVisible?: boolean
    /** From Bulletin hasMany BulletinSubject as subjects */
    bulletinId?: number
    /** Relation belongsTo Bulletin */
    bulletin?: APIDataBulletin
}

/** BulletinSubject Metadata */
export const APIMetaDataBulletinSubjectPropertyNames = ['id', 'name', 'phone', 'socialSecurity', 'birthDate', 'carriesWeapons', 'lastLocation', 'violencePotential', 'description', 'associates', 'causeToArrest', 'chargesForBooking', 'isVisible', 'bulletinId']
export const APIMetaDataBulletinSubjectRelationNames = ['bulletin']

export const APIMetaDataBulletinSubjectProperties = {
    id: {
        type: 'number'
    },
    name: {
        type: 'string',
        default: ''
    },
    phone: {
        type: 'string',
        default: ''
    },
    socialSecurity: {
        type: 'string'
    },
    birthDate: {
        type: 'Date'
    },
    carriesWeapons: {
        type: 'boolean',
        default: 'false'
    },
    lastLocation: {
        type: 'string',
        default: ''
    },
    violencePotential: {
        type: 'string',
        default: ''
    },
    description: {
        type: 'string',
        default: ''
    },
    associates: {
        type: 'string',
        default: ''
    },
    causeToArrest: {
        type: 'boolean',
        default: 'false'
    },
    chargesForBooking: {
        type: 'string',
        default: ''
    },
    isVisible: {
        type: 'boolean',
        default: 'true'
    },
    bulletinId: {
        type: 'number',
        relationName: 'subjects'
    },
    bulletin: {
        type: 'Bulletin',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataBulletinSubjectRelations = {
    bulletin: {
        type: 'belongsTo',
        model: 'Bulletin',
        foreignKey: 'bulletinId'
    }
}

/** BulletinVehicle */
export interface APIDataBulletinVehicle {
    id?: number
    make?: string
    model?: string
    year?: string
    description?: string
    registrationState?: string
    plate?: string
    isVisible?: boolean
    /** From Bulletin hasMany BulletinVehicle as vehicles */
    bulletinId?: number
    /** Relation belongsTo Bulletin */
    bulletin?: APIDataBulletin
}

/** BulletinVehicle Metadata */
export const APIMetaDataBulletinVehiclePropertyNames = ['id', 'make', 'model', 'year', 'description', 'registrationState', 'plate', 'isVisible', 'bulletinId']
export const APIMetaDataBulletinVehicleRelationNames = ['bulletin']

export const APIMetaDataBulletinVehicleProperties = {
    id: {
        type: 'number'
    },
    make: {
        type: 'string',
        default: ''
    },
    model: {
        type: 'string',
        default: ''
    },
    year: {
        type: 'string',
        default: ''
    },
    description: {
        type: 'string',
        default: ''
    },
    registrationState: {
        type: 'string',
        default: 'Not Available'
    },
    plate: {
        type: 'string',
        default: ''
    },
    isVisible: {
        type: 'boolean',
        default: 'true'
    },
    bulletinId: {
        type: 'number',
        relationName: 'vehicles'
    },
    bulletin: {
        type: 'Bulletin',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataBulletinVehicleRelations = {
    bulletin: {
        type: 'belongsTo',
        model: 'Bulletin',
        foreignKey: 'bulletinId'
    }
}

/** Bulletin */
export interface APIDataBulletin {
    id?: number
    title?: string
    status?: string
    summary?: string
    reportNumber?: string
    crimeType?: string
    otherInfo?: string
    isUrgent?: boolean
    isDraft?: boolean
    isVisible?: boolean
    isArchived?: boolean
    /** From relation belongsTo BlueUser as createdBy */
    blueUserId?: number
    /** From relation belongsTo BlueUser as caseAgent */
    caseAgentId?: number
    /** From Department hasMany Bulletin as bulletins */
    departmentId?: number
    originalPostId?: number
    bulletinType?: string
    /** Relation hasMany BulletinSubject */
    subjects?: APIDataBulletinSubject[]
    /** Relation hasMany BulletinVehicle */
    vehicles?: APIDataBulletinVehicle[]
    /** Relation hasMany Media */
    media?: APIDataMedia[]
    /** Relation hasMany Room */
    rooms?: APIDataRoom[]
    /** Relation belongsTo BlueUser */
    caseAgent?: APIDataBlueUser
    /** Relation belongsTo BlueUser */
    createdBy?: APIDataBlueUser
    reads?: APIDataRead[]
    createdDate?: Date
    updatedDate?: Date
}

/** Bulletin Metadata */
export const APIMetaDataBulletinPropertyNames = ['id', 'title', 'status', 'summary', 'reportNumber', 'crimeType', 'otherInfo', 'isUrgent', 'isDraft', 'isVisible', 'isArchived', 'blueUserId', 'caseAgentId', 'departmentId', 'originalPostId', 'bulletinType', 'reads', 'createdDate', 'updatedDate']
export const APIMetaDataBulletinRelationNames = ['subjects', 'vehicles', 'media', 'rooms', 'caseAgent', 'createdBy']

export const APIMetaDataBulletinProperties = {
    id: {
        type: 'number'
    },
    title: {
        type: 'string',
        required: 'true'
    },
    status: {
        type: 'string',
        required: 'true',
        enum: 'Open,Located,In Custody,Cancelled'
    },
    summary: {
        type: 'string',
        required: 'true'
    },
    reportNumber: {
        type: 'string',
        default: ''
    },
    crimeType: {
        type: 'string',
        default: ''
    },
    otherInfo: {
        type: 'string',
        default: ''
    },
    isUrgent: {
        type: 'boolean',
        default: 'false'
    },
    isDraft: {
        type: 'boolean',
        default: 'true',
        description: 'True is not distributed. Can not go from false to true'
    },
    isVisible: {
        type: 'boolean',
        default: 'true',
        description: 'True is seen by officers.'
    },
    isArchived: {
        type: 'boolean',
        default: 'false',
        description: 'True is stored for future reference.'
    },
    blueUserId: {
        type: 'number',
        relationName: 'createdBy'
    },
    caseAgentId: {
        type: 'number',
        relationName: 'caseAgent'
    },
    departmentId: {
        type: 'number',
        relationName: 'bulletins'
    },
    originalPostId: {
        type: 'number',
        default: '0'
    },
    bulletinType: {
        type: 'string',
        default: ''
    },
    subjects: {
        type: 'BulletinSubject[]',
        isRelationshipProperty: 'true'
    },
    vehicles: {
        type: 'BulletinVehicle[]',
        isRelationshipProperty: 'true'
    },
    media: {
        type: 'Media[]',
        isRelationshipProperty: 'true'
    },
    rooms: {
        type: 'Room[]',
        isRelationshipProperty: 'true'
    },
    caseAgent: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdBy: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    reads: {
        type: 'Read[]'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    }
}

export const APIMetaDataBulletinRelations = {
    subjects: {
        type: 'hasMany',
        model: 'BulletinSubject',
        foreignKey: 'bulletinId'
    },
    vehicles: {
        type: 'hasMany',
        model: 'BulletinVehicle',
        foreignKey: 'bulletinId'
    },
    media: {
        type: 'hasMany',
        model: 'Media',
        foreignKey: 'bulletinId',
        through: 'BulletinMediaThrough'
    },
    rooms: {
        type: 'hasMany',
        model: 'Room',
        through: 'RoomBulletinThrough'
    },
    caseAgent: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'caseAgentId'
    },
    createdBy: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    },
    reads: {
        type: 'hasMany',
        model: 'Read',
        polymorphic: 'readable'
    }
}

/** Debug */
export interface APIDataDebug {
    id?: number
}

/** Debug Metadata */
export const APIMetaDataDebugPropertyNames = ['id']
export const APIMetaDataDebugRelationNames = []

export const APIMetaDataDebugProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataDebugRelations = {
}

/** DepartmentAssignment */
export interface APIDataDepartmentAssignment {
    id?: number
    name?: string
    departmentType?: string
    /** From Department hasMany DepartmentAssignment as assignments */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
}

/** DepartmentAssignment Metadata */
export const APIMetaDataDepartmentAssignmentPropertyNames = ['id', 'name', 'departmentType', 'departmentId']
export const APIMetaDataDepartmentAssignmentRelationNames = ['department']

export const APIMetaDataDepartmentAssignmentProperties = {
    id: {
        type: 'number'
    },
    name: {
        type: 'string',
        default: ''
    },
    departmentType: {
        type: 'string'
    },
    departmentId: {
        type: 'number',
        relationName: 'assignments'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataDepartmentAssignmentRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    }
}

/** DepartmentCRM */
export interface APIDataDepartmentCRM {
    id?: number
    key?: string
    valueString?: string
    valueType?: string
    valueNumber?: number
    valueDate?: Date
    /** From Department hasMany DepartmentCRM as CRMData */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** From DepartmentCRM hasMany DepartmentCRM as children */
    parentId?: number
    /** Relation belongsTo DepartmentCRM */
    parent?: APIDataDepartmentCRM
    /** Relation hasMany DepartmentCRM */
    children?: APIDataDepartmentCRM[]
    deletedDate?: Date
}

/** DepartmentCRM Metadata */
export const APIMetaDataDepartmentCRMPropertyNames = ['id', 'key', 'valueString', 'valueType', 'valueNumber', 'valueDate', 'departmentId', 'parentId', 'deletedDate']
export const APIMetaDataDepartmentCRMRelationNames = ['department', 'parent', 'children']

export const APIMetaDataDepartmentCRMProperties = {
    id: {
        type: 'number'
    },
    key: {
        type: 'string'
    },
    valueString: {
        type: 'string'
    },
    valueType: {
        type: 'string',
        default: 'string'
    },
    valueNumber: {
        type: 'number'
    },
    valueDate: {
        type: 'Date'
    },
    departmentId: {
        type: 'number',
        relationName: 'CRMData'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    parentId: {
        type: 'number',
        relationName: 'children'
    },
    parent: {
        type: 'DepartmentCRM',
        isRelationshipProperty: 'true'
    },
    children: {
        type: 'DepartmentCRM[]',
        isRelationshipProperty: 'true'
    },
    deletedDate: {
        type: 'Date'
    }
}

export const APIMetaDataDepartmentCRMRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    },
    parent: {
        type: 'belongsTo',
        model: 'DepartmentCRM',
        foreignKey: 'parentId'
    },
    children: {
        type: 'hasMany',
        model: 'DepartmentCRM',
        foreignKey: 'parentId'
    }
}

/** DepartmentEmailDomainThrough */
export interface APIDataDepartmentEmailDomainThrough {
    id?: number
    /** From VerifiedEmailDomain hasMany Department as departments */
    domain?: string
    /** Relation belongsTo VerifiedEmailDomain */
    verifiedEmailDomain?: APIDataVerifiedEmailDomain
    /** From Department hasMany VerifiedEmailDomain as domains */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
}

/** DepartmentEmailDomainThrough Metadata */
export const APIMetaDataDepartmentEmailDomainThroughPropertyNames = ['id', 'domain', 'departmentId']
export const APIMetaDataDepartmentEmailDomainThroughRelationNames = ['verifiedEmailDomain', 'department']

export const APIMetaDataDepartmentEmailDomainThroughProperties = {
    id: {
        type: 'number'
    },
    domain: {
        type: 'string',
        relationName: 'departments'
    },
    verifiedEmailDomain: {
        type: 'VerifiedEmailDomain',
        isRelationshipProperty: 'true'
    },
    departmentId: {
        type: 'number',
        relationName: 'domains'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataDepartmentEmailDomainThroughRelations = {
    verifiedEmailDomain: {
        type: 'belongsTo',
        model: 'VerifiedEmailDomain',
        foreignKey: 'domain'
    },
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    }
}

/** DepartmentEnterpriseThrough */
export interface APIDataDepartmentEnterpriseThrough {
    id?: number
    connectorName?: string
    meta?: APIDataAny
    /** From relation belongsTo Department as department */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    createdDate?: Date
    updatedDate?: Date
    deletedDate?: Date
}

/** DepartmentEnterpriseThrough Metadata */
export const APIMetaDataDepartmentEnterpriseThroughPropertyNames = ['id', 'connectorName', 'meta', 'departmentId', 'createdDate', 'updatedDate', 'deletedDate']
export const APIMetaDataDepartmentEnterpriseThroughRelationNames = ['department']

export const APIMetaDataDepartmentEnterpriseThroughProperties = {
    id: {
        type: 'number'
    },
    connectorName: {
        type: 'string'
    },
    meta: {
        type: 'APIDataAny',
        default: '{}'
    },
    departmentId: {
        type: 'number',
        relationName: 'department'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    },
    deletedDate: {
        type: 'Date'
    }
}

export const APIMetaDataDepartmentEnterpriseThroughRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    }
}

/** DepartmentLinkedThrough */
export interface APIDataDepartmentLinkedThrough {
    id?: number
    meta?: APIDataAny
    /** From Department hasMany DepartmentLinkedThrough as childDepartmentsTrough */
    parentDepartmentId?: number
    /** Relation belongsTo Department */
    parentDepartment?: APIDataDepartment
    /** From Department hasMany DepartmentLinkedThrough as parentDepartmentsThrough */
    childDepartmentId?: number
    /** Relation belongsTo Department */
    childDepartment?: APIDataDepartment
}

/** DepartmentLinkedThrough Metadata */
export const APIMetaDataDepartmentLinkedThroughPropertyNames = ['id', 'meta', 'parentDepartmentId', 'childDepartmentId']
export const APIMetaDataDepartmentLinkedThroughRelationNames = ['parentDepartment', 'childDepartment']

export const APIMetaDataDepartmentLinkedThroughProperties = {
    id: {
        type: 'number'
    },
    meta: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    parentDepartmentId: {
        type: 'number',
        relationName: 'childDepartmentsTrough'
    },
    parentDepartment: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    childDepartmentId: {
        type: 'number',
        relationName: 'parentDepartmentsThrough'
    },
    childDepartment: {
        type: 'Department',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataDepartmentLinkedThroughRelations = {
    parentDepartment: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'parentDepartmentId'
    },
    childDepartment: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'childDepartmentId'
    }
}

/** DepartmentPosition */
export interface APIDataDepartmentPosition {
    id?: number
    name?: string
    level?: number
    departmentType?: string
    /** From Department hasMany DepartmentPosition as positions */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
}

/** DepartmentPosition Metadata */
export const APIMetaDataDepartmentPositionPropertyNames = ['id', 'name', 'level', 'departmentType', 'departmentId']
export const APIMetaDataDepartmentPositionRelationNames = ['department']

export const APIMetaDataDepartmentPositionProperties = {
    id: {
        type: 'number'
    },
    name: {
        type: 'string',
        default: ''
    },
    level: {
        type: 'number',
        default: '0'
    },
    departmentType: {
        type: 'string'
    },
    departmentId: {
        type: 'number',
        relationName: 'positions'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataDepartmentPositionRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    }
}

/** DepartmentUserThrough */
export interface APIDataDepartmentUserThrough {
    id?: number
    isVerified?: boolean
    roles?: string[]
    isVerifyDisabled?: boolean
    badgeNumber?: string
    isPrimary?: boolean
    assignmentString?: string
    positionString?: string
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** From Department hasMany DepartmentUserThrough as usersAccess */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    /** From relation belongsTo DepartmentAssignment as assignment */
    departmentAssignmentId?: number
    /** Relation belongsTo DepartmentAssignment */
    assignment?: APIDataDepartmentAssignment
    /** From relation belongsTo DepartmentPosition as position */
    departmentPositionId?: number
    /** Relation belongsTo DepartmentPosition */
    position?: APIDataDepartmentPosition
    createdDate?: Date
    updatedDate?: Date
    deletedDate?: Date
}

/** DepartmentUserThrough Metadata */
export const APIMetaDataDepartmentUserThroughPropertyNames = ['id', 'isVerified', 'roles', 'isVerifyDisabled', 'badgeNumber', 'isPrimary', 'assignmentString', 'positionString', 'blueUserId', 'departmentId', 'departmentAssignmentId', 'departmentPositionId', 'createdDate', 'updatedDate', 'deletedDate']
export const APIMetaDataDepartmentUserThroughRelationNames = ['department', 'user', 'assignment', 'position']

export const APIMetaDataDepartmentUserThroughProperties = {
    id: {
        type: 'number'
    },
    isVerified: {
        type: 'boolean',
        default: 'false'
    },
    roles: {
        type: 'string[]',
        default: ''
    },
    isVerifyDisabled: {
        type: 'boolean',
        default: 'false',
        description: 'Forbidden from verifying'
    },
    badgeNumber: {
        type: 'string'
    },
    isPrimary: {
        type: 'boolean',
        default: 'false'
    },
    assignmentString: {
        type: 'string'
    },
    positionString: {
        type: 'string'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    departmentId: {
        type: 'number',
        relationName: 'usersAccess'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    departmentAssignmentId: {
        type: 'number',
        relationName: 'assignment'
    },
    assignment: {
        type: 'DepartmentAssignment',
        isRelationshipProperty: 'true'
    },
    departmentPositionId: {
        type: 'number',
        relationName: 'position'
    },
    position: {
        type: 'DepartmentPosition',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    },
    deletedDate: {
        type: 'Date'
    }
}

export const APIMetaDataDepartmentUserThroughRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    },
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    },
    assignment: {
        type: 'belongsTo',
        model: 'DepartmentAssignment'
    },
    position: {
        type: 'belongsTo',
        model: 'DepartmentPosition'
    }
}

/** Department */
export interface APIDataDepartment {
    id?: number
    name?: string
    shortName?: string
    city?: string
    county?: string
    address?: string
    zipCode?: string
    state?: string
    country?: string
    verificationCode?: string
    hidden?: boolean
    type?: string
    verifiedMemberCapacity?: number
    executiveMemberCapacity?: number
    employees?: number
    subscriptionExpiresDate?: Date
    options?: APIDataAny
    meta?: APIDataAny
    isDemo?: boolean
    isTrial?: boolean
    trialStartDate?: Date
    passwordTTL?: number
    location?: APIDataGeoPoint
    publicImage?: string
    /** Relation hasMany DepartmentAssignment */
    assignments?: APIDataDepartmentAssignment[]
    /** Relation hasMany DepartmentPosition */
    positions?: APIDataDepartmentPosition[]
    /** From relation belongsTo Media as publicMedia */
    publicMediaId?: number
    /** Relation belongsTo Media */
    publicMedia?: APIDataMedia
    /** Relation hasMany VerifiedEmailDomain */
    domains?: APIDataVerifiedEmailDomain[]
}

/** Department Metadata */
export const APIMetaDataDepartmentPropertyNames = ['id', 'name', 'shortName', 'city', 'county', 'address', 'zipCode', 'state', 'country', 'verificationCode', 'hidden', 'type', 'verifiedMemberCapacity', 'executiveMemberCapacity', 'employees', 'subscriptionExpiresDate', 'options', 'meta', 'isDemo', 'isTrial', 'trialStartDate', 'passwordTTL', 'location', 'publicImage', 'publicMediaId']
export const APIMetaDataDepartmentRelationNames = ['assignments', 'positions', 'publicMedia', 'domains']

export const APIMetaDataDepartmentProperties = {
    id: {
        type: 'number'
    },
    name: {
        type: 'string',
        required: 'true',
        default: 'N/A'
    },
    shortName: {
        type: 'string',
        default: ''
    },
    city: {
        type: 'string',
        default: 'N/A'
    },
    county: {
        type: 'string',
        default: 'N/A'
    },
    address: {
        type: 'string',
        default: 'N/A'
    },
    zipCode: {
        type: 'string',
        default: 'N/A'
    },
    state: {
        type: 'string',
        default: 'N/A'
    },
    country: {
        type: 'string',
        default: 'USA'
    },
    verificationCode: {
        type: 'string',
        default: ''
    },
    hidden: {
        type: 'boolean',
        default: 'false'
    },
    type: {
        type: 'string',
        required: 'true',
        default: 'police'
    },
    verifiedMemberCapacity: {
        type: 'number',
        default: '0'
    },
    executiveMemberCapacity: {
        type: 'number',
        default: '0'
    },
    employees: {
        type: 'number',
        default: '0'
    },
    subscriptionExpiresDate: {
        type: 'Date'
    },
    options: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    meta: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    isDemo: {
        type: 'boolean',
        default: 'false'
    },
    isTrial: {
        type: 'boolean',
        default: 'false'
    },
    trialStartDate: {
        type: 'Date'
    },
    passwordTTL: {
        type: 'number',
        default: '7776000',
        description: 'in seconds'
    },
    location: {
        type: 'GeoPoint'
    },
    publicImage: {
        type: 'string'
    },
    assignments: {
        type: 'DepartmentAssignment[]',
        isRelationshipProperty: 'true'
    },
    positions: {
        type: 'DepartmentPosition[]',
        isRelationshipProperty: 'true'
    },
    publicMediaId: {
        type: 'number',
        relationName: 'publicMedia'
    },
    publicMedia: {
        type: 'Media',
        isRelationshipProperty: 'true'
    },
    domains: {
        type: 'VerifiedEmailDomain[]',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataDepartmentRelations = {
    users: {
        type: 'hasMany',
        model: 'BlueUser',
        foreignKey: 'departmentId',
        through: 'DepartmentUserThrough',
        options: '[object Object]'
    },
    usersAccess: {
        type: 'hasMany',
        foreignKey: 'departmentId',
        model: 'DepartmentUserThrough',
        options: '[object Object]'
    },
    media: {
        type: 'hasMany',
        model: 'Media',
        options: '[object Object]'
    },
    invites: {
        type: 'hasMany',
        model: 'Invite',
        options: '[object Object]'
    },
    rooms: {
        type: 'hasMany',
        model: 'Room',
        options: '[object Object]'
    },
    bulletins: {
        type: 'hasMany',
        model: 'Bulletin',
        options: '[object Object]'
    },
    audits: {
        type: 'hasMany',
        model: 'Audit',
        options: '[object Object]'
    },
    assignments: {
        type: 'hasMany',
        model: 'DepartmentAssignment'
    },
    positions: {
        type: 'hasMany',
        model: 'DepartmentPosition'
    },
    publicMedia: {
        type: 'belongsTo',
        foreignKey: 'publicMediaId',
        model: 'Media'
    },
    threadMessages: {
        type: 'hasMany',
        model: 'ThreadMessage',
        through: 'ThreadMessageDepartmentThrough',
        options: '[object Object]'
    },
    CRMData: {
        type: 'hasMany',
        model: 'DepartmentCRM',
        options: '[object Object]'
    },
    emojis: {
        type: 'hasMany',
        model: 'Emoji',
        options: '[object Object]'
    },
    parentDepartments: {
        type: 'hasMany',
        model: 'Department',
        foreignKey: 'childDepartmentId',
        keyThrough: 'parentDepartmentId',
        through: 'DepartmentLinkedThrough',
        options: '[object Object]'
    },
    childDepartments: {
        type: 'hasMany',
        model: 'Department',
        foreignKey: 'parentDepartmentId',
        keyThrough: 'childDepartmentId',
        through: 'DepartmentLinkedThrough',
        options: '[object Object]'
    },
    parentDepartmentsThrough: {
        type: 'hasMany',
        model: 'DepartmentLinkedThrough',
        foreignKey: 'childDepartmentId',
        options: '[object Object]'
    },
    childDepartmentsTrough: {
        type: 'hasMany',
        model: 'DepartmentLinkedThrough',
        foreignKey: 'parentDepartmentId',
        options: '[object Object]'
    },
    domains: {
        type: 'hasMany',
        model: 'VerifiedEmailDomain',
        through: 'DepartmentEmailDomainThrough',
        foreignKey: 'departmentId'
    },
    documentSchemas: {
        type: 'hasMany',
        model: 'DocumentSchema',
        foreignKey: 'departmentId',
        options: '[object Object]'
    },
    documents: {
        type: 'hasMany',
        model: 'Document',
        foreignKey: 'departmentId',
        options: '[object Object]'
    },
    documentSchemaCategories: {
        type: 'hasMany',
        model: 'DocumentSchemaCategory',
        foreignKey: 'departmentId',
        options: '[object Object]'
    },
    enterprise: {
        type: 'hasOne',
        model: 'DepartmentEnterpriseThrough',
        foreignKey: 'departmentId'
    }
}

/** Device */
export interface APIDataDevice {
    id?: number
    token?: string
    type?: string
    buildNumber?: number
    pushToken?: string
    settings?: APIDataAny
    info?: string
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    /** Relation hasMany ExtendedAccessToken */
    accessTokens?: APIDataExtendedAccessToken[]
}

/** Device Metadata */
export const APIMetaDataDevicePropertyNames = ['id', 'token', 'type', 'buildNumber', 'pushToken', 'settings', 'info', 'blueUserId']
export const APIMetaDataDeviceRelationNames = ['user', 'accessTokens']

export const APIMetaDataDeviceProperties = {
    id: {
        type: 'number'
    },
    token: {
        type: 'string'
    },
    type: {
        type: 'string',
        enum: 'apple,android,ios-rn,android-rn'
    },
    buildNumber: {
        type: 'number'
    },
    pushToken: {
        type: 'string'
    },
    settings: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    info: {
        type: 'string',
        description: 'For debug info'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    accessTokens: {
        type: 'ExtendedAccessToken[]',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataDeviceRelations = {
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    },
    accessTokens: {
        type: 'hasMany',
        model: 'ExtendedAccessToken',
        foreignKey: 'deviceId'
    }
}

/** DocumentACLUserThrough */
export interface APIDataDocumentACLUserThrough {
    id?: number
    accessType: string
    /** From Document hasMany DocumentACLUserThrough as ACLUsers */
    documentId?: number
    /** Relation belongsTo Document */
    document?: APIDataDocument
    /** From relation belongsTo DocumentACL as documentACL */
    documentACLId?: number
    /** Relation belongsTo DocumentACL */
    documentACL?: APIDataDocumentACL
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
}

/** DocumentACLUserThrough Metadata */
export const APIMetaDataDocumentACLUserThroughPropertyNames = ['id', 'accessType', 'documentId', 'documentACLId', 'blueUserId']
export const APIMetaDataDocumentACLUserThroughRelationNames = ['document', 'documentACL', 'user']

export const APIMetaDataDocumentACLUserThroughProperties = {
    id: {
        type: 'number'
    },
    accessType: {
        0: 's',
        1: 't',
        2: 'r',
        3: 'i',
        4: 'n',
        5: 'g'
    },
    documentId: {
        type: 'number',
        relationName: 'ACLUsers'
    },
    document: {
        type: 'Document',
        isRelationshipProperty: 'true'
    },
    documentACLId: {
        type: 'number',
        relationName: 'documentACL'
    },
    documentACL: {
        type: 'DocumentACL',
        isRelationshipProperty: 'true'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataDocumentACLUserThroughRelations = {
    document: {
        type: 'belongsTo',
        model: 'Document',
        foreignKey: 'documentId'
    },
    documentACL: {
        type: 'belongsTo',
        model: 'DocumentACL',
        foreignKey: 'documentACLId'
    },
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** DocumentACL */
export interface APIDataDocumentACL {
    id?: number
    accessType: string
    principalType: string
    principalId: number
    /** From relation belongsTo BlueUser as createdBy */
    createdById?: number
    /** Relation belongsTo BlueUser */
    createdBy?: APIDataBlueUser
    /** From Document hasMany DocumentACL as ACL */
    documentId?: number
    /** Relation belongsTo Document */
    document?: APIDataDocument
    undefined?: string
    principal?: APIDataAny
    createdDate?: Date
    deletedDate?: Date
}

/** DocumentACL Metadata */
export const APIMetaDataDocumentACLPropertyNames = ['id', 'accessType', 'principalType', 'principalId', 'createdById', 'documentId', 'undefined', 'principal', 'createdDate', 'deletedDate']
export const APIMetaDataDocumentACLRelationNames = ['createdBy', 'document']

export const APIMetaDataDocumentACLProperties = {
    id: {
        type: 'number'
    },
    accessType: {
        0: 's',
        1: 't',
        2: 'r',
        3: 'i',
        4: 'n',
        5: 'g'
    },
    principalType: {
        0: 's',
        1: 't',
        2: 'r',
        3: 'i',
        4: 'n',
        5: 'g'
    },
    principalId: {
        0: 'n',
        1: 'u',
        2: 'm',
        3: 'b',
        4: 'e',
        5: 'r'
    },
    createdById: {
        type: 'number',
        relationName: 'createdBy'
    },
    createdBy: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    documentId: {
        type: 'number',
        relationName: 'ACL'
    },
    document: {
        type: 'Document',
        isRelationshipProperty: 'true'
    },
    undefined: {
        type: 'string'
    },
    principal: {
        type: 'APIDataAny'
    },
    createdDate: {
        type: 'Date'
    },
    deletedDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentACLRelations = {
    createdBy: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'createdById'
    },
    document: {
        type: 'belongsTo',
        model: 'Document',
        foreignKey: 'documentId'
    },
    principal: {
        type: 'belongsTo',
        polymorphic: 'true',
        polymorphicTypes: 'BlueUser,Room,Department,Thread'
    },
    messagesThrough: {
        type: 'hasMany',
        model: 'DocumentMessageThrough',
        foreignKey: 'documentACLId',
        options: '[object Object]'
    }
}

/** DocumentMediaThrough */
export interface APIDataDocumentMediaThrough {
    id?: number
    /** From Document hasMany Media as media */
    documentId?: number
    /** Relation belongsTo Document */
    document?: APIDataDocument
    /** From relation belongsTo Media as media */
    mediaId?: number
    /** Relation belongsTo Media */
    media?: APIDataMedia
    createdDate?: Date
    deletedDate?: Date
}

/** DocumentMediaThrough Metadata */
export const APIMetaDataDocumentMediaThroughPropertyNames = ['id', 'documentId', 'mediaId', 'createdDate', 'deletedDate']
export const APIMetaDataDocumentMediaThroughRelationNames = ['document', 'media']

export const APIMetaDataDocumentMediaThroughProperties = {
    id: {
        type: 'number'
    },
    documentId: {
        type: 'number',
        relationName: 'media'
    },
    document: {
        type: 'Document',
        isRelationshipProperty: 'true'
    },
    mediaId: {
        type: 'number',
        relationName: 'media'
    },
    media: {
        type: 'Media',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    deletedDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentMediaThroughRelations = {
    document: {
        type: 'belongsTo',
        model: 'Document',
        foreignKey: 'documentId'
    },
    media: {
        type: 'belongsTo',
        model: 'Media',
        foreignKey: 'mediaId'
    }
}

/** DocumentMessageThrough */
export interface APIDataDocumentMessageThrough {
    id?: number
    /** From relation belongsTo DocumentACL as documentACL */
    documentACLId?: number
    /** From Document hasMany DocumentMessageThrough as messagesThrough */
    documentId?: number
    /** Relation belongsTo Document */
    document?: APIDataDocument
    /** Relation belongsTo DocumentACL */
    documentACL?: APIDataDocumentACL
    /** From relation belongsTo ThreadMessage as threadMessage */
    threadMessageId?: number
    /** Relation belongsTo ThreadMessage */
    threadMessage?: APIDataThreadMessage
    /** From relation belongsTo RoomMessage as roomMessage */
    roomMessageId?: number
    /** Relation belongsTo RoomMessage */
    roomMessage?: APIDataRoomMessage
    createdDate?: Date
    deletedDate?: Date
}

/** DocumentMessageThrough Metadata */
export const APIMetaDataDocumentMessageThroughPropertyNames = ['id', 'documentACLId', 'documentId', 'threadMessageId', 'roomMessageId', 'createdDate', 'deletedDate']
export const APIMetaDataDocumentMessageThroughRelationNames = ['document', 'documentACL', 'threadMessage', 'roomMessage']

export const APIMetaDataDocumentMessageThroughProperties = {
    id: {
        type: 'number'
    },
    documentACLId: {
        type: 'number',
        relationName: 'documentACL'
    },
    documentId: {
        type: 'number',
        relationName: 'messagesThrough'
    },
    document: {
        type: 'Document',
        isRelationshipProperty: 'true'
    },
    documentACL: {
        type: 'DocumentACL',
        isRelationshipProperty: 'true'
    },
    threadMessageId: {
        type: 'number',
        relationName: 'threadMessage'
    },
    threadMessage: {
        type: 'ThreadMessage',
        isRelationshipProperty: 'true'
    },
    roomMessageId: {
        type: 'number',
        relationName: 'roomMessage'
    },
    roomMessage: {
        type: 'RoomMessage',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    deletedDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentMessageThroughRelations = {
    document: {
        type: 'belongsTo',
        model: 'Document',
        foreignKey: 'documentId'
    },
    documentACL: {
        type: 'belongsTo',
        model: 'DocumentACL',
        foreignKey: 'documentACLId'
    },
    threadMessage: {
        type: 'belongsTo',
        model: 'ThreadMessage',
        foreignKey: 'threadMessageId'
    },
    roomMessage: {
        type: 'belongsTo',
        model: 'RoomMessage',
        foreignKey: 'roomMessageId'
    }
}

/** DocumentRevision */
export interface APIDataDocumentRevision {
    id?: number
    data?: APIDataObject
    /** From Document hasMany DocumentRevision as revisions */
    documentId?: number
    /** Relation belongsTo Document */
    document?: APIDataDocument
    /** From relation belongsTo DocumentSchema as documentSchema */
    documentSchemaId?: number
    /** Relation belongsTo DocumentSchema */
    documentSchema?: APIDataDocumentSchema
    /** From relation belongsTo DocumentSchemaRevision as documentSchemaRevision */
    documentSchemaRevisionId?: number
    /** Relation belongsTo DocumentSchemaRevision */
    documentSchemaRevision?: APIDataDocumentSchemaRevision
    /** From relation belongsTo BlueUser as createdBy */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    createdBy?: APIDataBlueUser
    createdDate?: Date
}

/** DocumentRevision Metadata */
export const APIMetaDataDocumentRevisionPropertyNames = ['id', 'data', 'documentId', 'documentSchemaId', 'documentSchemaRevisionId', 'blueUserId', 'createdDate']
export const APIMetaDataDocumentRevisionRelationNames = ['document', 'documentSchema', 'documentSchemaRevision', 'createdBy']

export const APIMetaDataDocumentRevisionProperties = {
    id: {
        type: 'number'
    },
    data: {
        type: 'APIDataObject',
        default: '{}'
    },
    documentId: {
        type: 'number',
        relationName: 'revisions'
    },
    document: {
        type: 'Document',
        isRelationshipProperty: 'true'
    },
    documentSchemaId: {
        type: 'number',
        relationName: 'documentSchema'
    },
    documentSchema: {
        type: 'DocumentSchema',
        isRelationshipProperty: 'true'
    },
    documentSchemaRevisionId: {
        type: 'number',
        relationName: 'documentSchemaRevision'
    },
    documentSchemaRevision: {
        type: 'DocumentSchemaRevision',
        isRelationshipProperty: 'true'
    },
    blueUserId: {
        type: 'number',
        relationName: 'createdBy'
    },
    createdBy: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentRevisionRelations = {
    document: {
        type: 'belongsTo',
        model: 'Document',
        foreignKey: 'documentId'
    },
    documentSchema: {
        type: 'belongsTo',
        model: 'DocumentSchema',
        foreignKey: 'documentSchemaId'
    },
    documentSchemaRevision: {
        type: 'belongsTo',
        model: 'DocumentSchemaRevision',
        foreignKey: 'documentSchemaRevisionId'
    },
    createdBy: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** DocumentSchemaCategoryThrough */
export interface APIDataDocumentSchemaCategoryThrough {
    id?: number
    /** From DocumentSchema hasMany DocumentSchemaCategory as categories */
    documentSchemaId?: number
    /** Relation belongsTo DocumentSchema */
    documentSchema?: APIDataDocumentSchema
    /** From DocumentSchemaCategory hasMany DocumentSchema as documentSchemas */
    categoryId?: number
    /** Relation belongsTo DocumentSchemaCategory */
    documentSchemaCategory?: APIDataDocumentSchemaCategory
    createdDate?: Date
}

/** DocumentSchemaCategoryThrough Metadata */
export const APIMetaDataDocumentSchemaCategoryThroughPropertyNames = ['id', 'documentSchemaId', 'categoryId', 'createdDate']
export const APIMetaDataDocumentSchemaCategoryThroughRelationNames = ['documentSchema', 'documentSchemaCategory']

export const APIMetaDataDocumentSchemaCategoryThroughProperties = {
    id: {
        type: 'number'
    },
    documentSchemaId: {
        type: 'number',
        relationName: 'categories'
    },
    documentSchema: {
        type: 'DocumentSchema',
        isRelationshipProperty: 'true'
    },
    categoryId: {
        type: 'number',
        relationName: 'documentSchemas'
    },
    documentSchemaCategory: {
        type: 'DocumentSchemaCategory',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentSchemaCategoryThroughRelations = {
    documentSchema: {
        type: 'belongsTo',
        model: 'DocumentSchema',
        foreignKey: 'documentSchemaId'
    },
    documentSchemaCategory: {
        type: 'belongsTo',
        model: 'DocumentSchemaCategory',
        foreignKey: 'categoryId'
    }
}

/** DocumentSchemaCategory */
export interface APIDataDocumentSchemaCategory {
    id?: number
    name?: string
    /** From relation belongsTo Department as department */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** Relation hasMany DocumentSchema */
    documentSchemas?: APIDataDocumentSchema[]
    createdDate?: Date
}

/** DocumentSchemaCategory Metadata */
export const APIMetaDataDocumentSchemaCategoryPropertyNames = ['id', 'name', 'departmentId', 'createdDate']
export const APIMetaDataDocumentSchemaCategoryRelationNames = ['department', 'documentSchemas']

export const APIMetaDataDocumentSchemaCategoryProperties = {
    id: {
        type: 'number'
    },
    name: {
        type: 'string',
        required: 'true'
    },
    departmentId: {
        type: 'number',
        relationName: 'department'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    documentSchemas: {
        type: 'DocumentSchema[]',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentSchemaCategoryRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    },
    documentSchemas: {
        type: 'hasMany',
        model: 'DocumentSchema',
        foreignKey: 'categoryId',
        through: 'DocumentSchemaCategoryThrough'
    }
}

/** DocumentSchemaMediaThrough */
export interface APIDataDocumentSchemaMediaThrough {
    id?: number
    /** From DocumentSchema hasMany Media as media */
    documentSchemaId?: number
    /** Relation belongsTo DocumentSchema */
    documentSchema?: APIDataDocumentSchema
    /** From relation belongsTo Media as media */
    mediaId?: number
    /** Relation belongsTo Media */
    media?: APIDataMedia
    createdDate?: Date
    deletedDate?: Date
}

/** DocumentSchemaMediaThrough Metadata */
export const APIMetaDataDocumentSchemaMediaThroughPropertyNames = ['id', 'documentSchemaId', 'mediaId', 'createdDate', 'deletedDate']
export const APIMetaDataDocumentSchemaMediaThroughRelationNames = ['documentSchema', 'media']

export const APIMetaDataDocumentSchemaMediaThroughProperties = {
    id: {
        type: 'number'
    },
    documentSchemaId: {
        type: 'number',
        relationName: 'media'
    },
    documentSchema: {
        type: 'DocumentSchema',
        isRelationshipProperty: 'true'
    },
    mediaId: {
        type: 'number',
        relationName: 'media'
    },
    media: {
        type: 'Media',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    deletedDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentSchemaMediaThroughRelations = {
    documentSchema: {
        type: 'belongsTo',
        model: 'DocumentSchema',
        foreignKey: 'documentSchemaId'
    },
    media: {
        type: 'belongsTo',
        model: 'Media',
        foreignKey: 'mediaId'
    }
}

/** DocumentSchemaRevision */
export interface APIDataDocumentSchemaRevision {
    id?: number
    schema?: APIDataAny
    /** From DocumentSchema hasMany DocumentSchemaRevision as revisions */
    documentSchemaId?: number
    /** Relation belongsTo DocumentSchema */
    documentSchema?: APIDataDocumentSchema
    /** From relation belongsTo BlueUser as createdBy */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    createdBy?: APIDataBlueUser
    createdDate?: Date
}

/** DocumentSchemaRevision Metadata */
export const APIMetaDataDocumentSchemaRevisionPropertyNames = ['id', 'schema', 'documentSchemaId', 'blueUserId', 'createdDate']
export const APIMetaDataDocumentSchemaRevisionRelationNames = ['documentSchema', 'createdBy']

export const APIMetaDataDocumentSchemaRevisionProperties = {
    id: {
        type: 'number'
    },
    schema: {
        type: 'APIDataAny',
        default: '[object Object]',
        required: 'true'
    },
    documentSchemaId: {
        type: 'number',
        relationName: 'revisions'
    },
    documentSchema: {
        type: 'DocumentSchema',
        isRelationshipProperty: 'true'
    },
    blueUserId: {
        type: 'number',
        relationName: 'createdBy'
    },
    createdBy: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentSchemaRevisionRelations = {
    documentSchema: {
        type: 'belongsTo',
        model: 'DocumentSchema',
        foreignKey: 'documentSchemaId'
    },
    documents: {
        type: 'hasMany',
        model: 'Document',
        foreignKey: 'documentSchemaRevisionId',
        options: '[object Object]'
    },
    createdBy: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** DocumentSchema */
export interface APIDataDocumentSchema {
    id?: number
    name?: string
    description?: string
    isEnabled?: boolean
    schema?: APIDataAny
    meta?: APIDataAny
    icon?: string
    color?: string
    /** From relation belongsTo Department as department */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** Relation hasMany DocumentSchemaRevision */
    revisions?: APIDataDocumentSchemaRevision[]
    /** Relation hasMany Media */
    media?: APIDataMedia[]
    /** Relation hasMany DocumentSchemaCategory */
    categories?: APIDataDocumentSchemaCategory[]
    createdDate?: Date
    updatedDate?: Date
}

/** DocumentSchema Metadata */
export const APIMetaDataDocumentSchemaPropertyNames = ['id', 'name', 'description', 'isEnabled', 'schema', 'meta', 'icon', 'color', 'departmentId', 'createdDate', 'updatedDate']
export const APIMetaDataDocumentSchemaRelationNames = ['department', 'revisions', 'media', 'categories']

export const APIMetaDataDocumentSchemaProperties = {
    id: {
        type: 'number'
    },
    name: {
        type: 'string',
        required: 'true'
    },
    description: {
        type: 'string',
        default: ''
    },
    isEnabled: {
        type: 'boolean',
        default: 'false'
    },
    schema: {
        type: 'APIDataAny',
        default: '[object Object]',
        required: 'true'
    },
    meta: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    icon: {
        type: 'string',
        default: 'siren-on'
    },
    color: {
        type: 'string',
        default: '#529CFF'
    },
    departmentId: {
        type: 'number',
        relationName: 'department'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    revisions: {
        type: 'DocumentSchemaRevision[]',
        isRelationshipProperty: 'true'
    },
    media: {
        type: 'Media[]',
        isRelationshipProperty: 'true'
    },
    categories: {
        type: 'DocumentSchemaCategory[]',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentSchemaRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    },
    documents: {
        type: 'hasMany',
        model: 'Document',
        foreignKey: 'documentSchemaId',
        options: '[object Object]'
    },
    revisions: {
        type: 'hasMany',
        model: 'DocumentSchemaRevision',
        foreignKey: 'documentSchemaId'
    },
    media: {
        type: 'hasMany',
        model: 'Media',
        foreignKey: 'documentSchemaId',
        through: 'DocumentSchemaMediaThrough'
    },
    categories: {
        type: 'hasMany',
        model: 'DocumentSchemaCategory',
        foreignKey: 'documentSchemaId',
        through: 'DocumentSchemaCategoryThrough'
    }
}

/** DocumentSearchData */
export interface APIDataDocumentSearchData {
    id?: number
    key?: string
    valueType?: string
    valueString?: string
    valueNumber?: number
    valueDate?: Date
    /** From Document hasMany DocumentSearchData as documentSearchData */
    documentId?: number
    /** Relation belongsTo Document */
    document?: APIDataDocument
    createdDate?: Date
}

/** DocumentSearchData Metadata */
export const APIMetaDataDocumentSearchDataPropertyNames = ['id', 'key', 'valueType', 'valueString', 'valueNumber', 'valueDate', 'documentId', 'createdDate']
export const APIMetaDataDocumentSearchDataRelationNames = ['document']

export const APIMetaDataDocumentSearchDataProperties = {
    id: {
        type: 'number'
    },
    key: {
        type: 'string'
    },
    valueType: {
        type: 'string'
    },
    valueString: {
        type: 'string'
    },
    valueNumber: {
        type: 'number'
    },
    valueDate: {
        type: 'Date'
    },
    documentId: {
        type: 'number',
        relationName: 'documentSearchData'
    },
    document: {
        type: 'Document',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentSearchDataRelations = {
    document: {
        type: 'belongsTo',
        model: 'Document',
        foreignKey: 'documentId'
    }
}

/** Document */
export interface APIDataDocument {
    id?: number
    name?: string
    isArchived?: boolean
    data?: APIDataObject
    meta?: APIDataObject
    /** From relation belongsTo Department as department */
    departmentId?: number
    /** From relation belongsTo DocumentSchemaRevision as documentSchemaRevision */
    documentSchemaRevisionId?: number
    /** From relation belongsTo DocumentSchema as documentSchema */
    documentSchemaId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** Relation belongsTo DocumentSchema */
    documentSchema?: APIDataDocumentSchema
    /** Relation belongsTo DocumentSchemaRevision */
    documentSchemaRevision?: APIDataDocumentSchemaRevision
    /** Relation hasMany DocumentRevision */
    revisions?: APIDataDocumentRevision[]
    /** Relation hasMany DocumentACL */
    ACL?: APIDataDocumentACL[]
    /** Relation hasMany DocumentACLUserThrough */
    ACLUsers?: APIDataDocumentACLUserThrough[]
    /** Relation hasMany Media */
    media?: APIDataMedia[]
    createdDate?: Date
    updatedDate?: Date
}

/** Document Metadata */
export const APIMetaDataDocumentPropertyNames = ['id', 'name', 'isArchived', 'data', 'meta', 'departmentId', 'documentSchemaRevisionId', 'documentSchemaId', 'createdDate', 'updatedDate']
export const APIMetaDataDocumentRelationNames = ['department', 'documentSchema', 'documentSchemaRevision', 'revisions', 'ACL', 'ACLUsers', 'media']

export const APIMetaDataDocumentProperties = {
    id: {
        type: 'number'
    },
    name: {
        type: 'string',
        default: ''
    },
    isArchived: {
        type: 'boolean',
        default: 'false'
    },
    data: {
        type: 'APIDataObject',
        default: '{}'
    },
    meta: {
        type: 'APIDataObject',
        default: '{}'
    },
    departmentId: {
        type: 'number',
        relationName: 'department'
    },
    documentSchemaRevisionId: {
        type: 'number',
        relationName: 'documentSchemaRevision'
    },
    documentSchemaId: {
        type: 'number',
        relationName: 'documentSchema'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    documentSchema: {
        type: 'DocumentSchema',
        isRelationshipProperty: 'true'
    },
    documentSchemaRevision: {
        type: 'DocumentSchemaRevision',
        isRelationshipProperty: 'true'
    },
    revisions: {
        type: 'DocumentRevision[]',
        isRelationshipProperty: 'true'
    },
    ACL: {
        type: 'DocumentACL[]',
        isRelationshipProperty: 'true'
    },
    ACLUsers: {
        type: 'DocumentACLUserThrough[]',
        isRelationshipProperty: 'true'
    },
    media: {
        type: 'Media[]',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    },
    documentSchema: {
        type: 'belongsTo',
        model: 'DocumentSchema',
        foreignKey: 'documentSchemaId'
    },
    documentSchemaRevision: {
        type: 'belongsTo',
        model: 'DocumentSchemaRevision',
        foreignKey: 'documentSchemaRevisionId'
    },
    revisions: {
        type: 'hasMany',
        model: 'DocumentRevision',
        foreignKey: 'documentId'
    },
    documentSearchData: {
        type: 'hasMany',
        model: 'DocumentSearchData',
        foreignKey: 'documentId',
        options: '[object Object]'
    },
    ACL: {
        type: 'hasMany',
        model: 'DocumentACL',
        foreignKey: 'documentId'
    },
    ACLUsers: {
        type: 'hasMany',
        model: 'DocumentACLUserThrough',
        foreignKey: 'documentId'
    },
    media: {
        type: 'hasMany',
        model: 'Media',
        foreignKey: 'documentId',
        through: 'DocumentMediaThrough'
    },
    messagesThrough: {
        type: 'hasMany',
        model: 'DocumentMessageThrough',
        foreignKey: 'documentId',
        options: '[object Object]'
    }
}

/** DynamicLink */
export interface APIDataDynamicLink {
    id?: number
}

/** DynamicLink Metadata */
export const APIMetaDataDynamicLinkPropertyNames = ['id']
export const APIMetaDataDynamicLinkRelationNames = []

export const APIMetaDataDynamicLinkProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataDynamicLinkRelations = {
}

/** Emoji */
export interface APIDataEmoji {
    id?: number
    name?: string
    short_names?: string[]
    colons?: string
    imageUrl?: string
    native?: string
    /** From relation belongsTo Department as department */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** From relation belongsTo BlueUser as createdBy */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    createdBy?: APIDataBlueUser
    createdDate?: Date
}

/** Emoji Metadata */
export const APIMetaDataEmojiPropertyNames = ['id', 'name', 'short_names', 'colons', 'imageUrl', 'native', 'departmentId', 'blueUserId', 'createdDate']
export const APIMetaDataEmojiRelationNames = ['department', 'createdBy']

export const APIMetaDataEmojiProperties = {
    id: {
        type: 'number'
    },
    name: {
        type: 'string'
    },
    short_names: {
        type: 'string[]'
    },
    colons: {
        type: 'string'
    },
    imageUrl: {
        type: 'string'
    },
    native: {
        type: 'string'
    },
    departmentId: {
        type: 'number',
        relationName: 'department'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    blueUserId: {
        type: 'number',
        relationName: 'createdBy'
    },
    createdBy: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataEmojiRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    },
    createdBy: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** EnrollMfaToken */
export interface APIDataEnrollMfaToken {
    id?: string
    ttl?: number
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    createdDate?: Date
}

/** EnrollMfaToken Metadata */
export const APIMetaDataEnrollMfaTokenPropertyNames = ['id', 'ttl', 'blueUserId', 'createdDate']
export const APIMetaDataEnrollMfaTokenRelationNames = ['user']

export const APIMetaDataEnrollMfaTokenProperties = {
    id: {
        type: 'string',
        id: 'true'
    },
    ttl: {
        type: 'number',
        ttl: 'true',
        default: '1209600',
        description: 'time to live in seconds (2 weeks by default)'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataEnrollMfaTokenRelations = {
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** EventLog */
export interface APIDataEventLog {
    id?: number
    type?: string
    subType?: string
    message?: string
    data?: APIDataAny
    ip?: string
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** From relation belongsTo Department as department */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    /** From relation belongsTo ExtendedAccessToken as accessToken */
    accessTokenId?: string
    /** Relation belongsTo ExtendedAccessToken */
    accessToken?: APIDataExtendedAccessToken
    createdDate?: Date
}

/** EventLog Metadata */
export const APIMetaDataEventLogPropertyNames = ['id', 'type', 'subType', 'message', 'data', 'ip', 'blueUserId', 'departmentId', 'accessTokenId', 'createdDate']
export const APIMetaDataEventLogRelationNames = ['department', 'user', 'accessToken']

export const APIMetaDataEventLogProperties = {
    id: {
        type: 'number'
    },
    type: {
        type: 'string',
        default: 'NONE'
    },
    subType: {
        type: 'string',
        default: 'NONE'
    },
    message: {
        type: 'string',
        default: ''
    },
    data: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    ip: {
        type: 'string',
        default: ''
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    departmentId: {
        type: 'number',
        relationName: 'department'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    accessTokenId: {
        type: 'string',
        relationName: 'accessToken'
    },
    accessToken: {
        type: 'ExtendedAccessToken',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataEventLogRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    },
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    },
    accessToken: {
        type: 'belongsTo',
        model: 'ExtendedAccessToken',
        foreignKey: 'accessTokenId'
    }
}

/** ExtendedAccessToken */
export interface APIDataExtendedAccessToken {
    id?: string
    /** From relation belongsTo BlueUser as user */
    userId?: number
    /** From relation belongsTo Device as device */
    deviceId?: number
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    /** Relation belongsTo Device */
    device?: APIDataDevice
}

/** ExtendedAccessToken Metadata */
export const APIMetaDataExtendedAccessTokenPropertyNames = ['id', 'userId', 'deviceId']
export const APIMetaDataExtendedAccessTokenRelationNames = ['user', 'device']

export const APIMetaDataExtendedAccessTokenProperties = {
    id: {
        type: 'string'
    },
    userId: {
        type: 'number',
        relationName: 'user'
    },
    deviceId: {
        type: 'number',
        relationName: 'device'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    device: {
        type: 'Device',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataExtendedAccessTokenRelations = {
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'userId'
    },
    device: {
        type: 'belongsTo',
        model: 'Device',
        foreignKey: 'deviceId'
    }
}

/** Google */
export interface APIDataGoogle {
    id?: number
}

/** Google Metadata */
export const APIMetaDataGooglePropertyNames = ['id']
export const APIMetaDataGoogleRelationNames = []

export const APIMetaDataGoogleProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataGoogleRelations = {
}

/** Hide */
export interface APIDataHide {
    id?: number
    hideableId?: number
    hideableType?: string
    hideable?: APIDataAny
    /** From relation belongsTo BlueUser as hideFrom */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    hideFrom?: APIDataBlueUser
    createdTime?: Date
}

/** Hide Metadata */
export const APIMetaDataHidePropertyNames = ['id', 'hideableId', 'hideableType', 'hideable', 'blueUserId', 'createdTime']
export const APIMetaDataHideRelationNames = ['hideFrom']

export const APIMetaDataHideProperties = {
    id: {
        type: 'number'
    },
    hideableId: {
        type: 'number'
    },
    hideableType: {
        type: 'string'
    },
    hideable: {
        type: 'APIDataAny'
    },
    blueUserId: {
        type: 'number',
        relationName: 'hideFrom'
    },
    hideFrom: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdTime: {
        type: 'Date'
    }
}

export const APIMetaDataHideRelations = {
    hideable: {
        type: 'belongsTo',
        polymorphic: '[object Object]'
    },
    hideFrom: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: ''
    }
}

/** InviteConsumerThrough */
export interface APIDataInviteConsumerThrough {
    id?: number
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** From Invite hasMany InviteConsumerThrough as consumptions */
    inviteId?: number
    /** Relation belongsTo Invite */
    invite?: APIDataInvite
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    createdDate?: Date
}

/** InviteConsumerThrough Metadata */
export const APIMetaDataInviteConsumerThroughPropertyNames = ['id', 'blueUserId', 'inviteId', 'createdDate']
export const APIMetaDataInviteConsumerThroughRelationNames = ['invite', 'user']

export const APIMetaDataInviteConsumerThroughProperties = {
    id: {
        type: 'number'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    inviteId: {
        type: 'number',
        relationName: 'consumptions'
    },
    invite: {
        type: 'Invite',
        isRelationshipProperty: 'true'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataInviteConsumerThroughRelations = {
    invite: {
        type: 'belongsTo',
        model: 'Invite',
        foreignKey: 'inviteId'
    },
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** Invite */
export interface APIDataInvite {
    id?: number
    token?: string
    content?: APIDataAny
    action?: APIDataAny
    expiresDate?: Date
    to?: string
    status?: string
    sendResult?: APIDataAny
    type?: string
    isPersistent?: boolean
    url?: string
    setConsumerRole?: string
    verifyConsumer?: boolean
    /** From relation belongsTo BlueUser as from */
    fromId?: number
    /** From relation belongsTo BlueUser as toUser */
    toUserId?: number
    /** From relation belongsTo Department as department */
    departmentId?: number
    /** Relation belongsTo BlueUser */
    from?: APIDataBlueUser
    /** Relation belongsTo BlueUser */
    toUser?: APIDataBlueUser
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** From Room hasMany Invite as invites */
    roomId?: number
    /** Relation belongsTo Room */
    room?: APIDataRoom
    /** Relation hasMany BlueUser */
    consumers?: APIDataBlueUser[]
    /** Relation hasMany InviteConsumerThrough */
    consumptions?: APIDataInviteConsumerThrough[]
    createdTime?: Date
}

/** Invite Metadata */
export const APIMetaDataInvitePropertyNames = ['id', 'token', 'content', 'action', 'expiresDate', 'to', 'status', 'sendResult', 'type', 'isPersistent', 'url', 'setConsumerRole', 'verifyConsumer', 'fromId', 'toUserId', 'departmentId', 'roomId', 'createdTime']
export const APIMetaDataInviteRelationNames = ['from', 'toUser', 'department', 'room', 'consumers', 'consumptions']

export const APIMetaDataInviteProperties = {
    id: {
        type: 'number'
    },
    token: {
        type: 'string'
    },
    content: {
        type: 'APIDataAny'
    },
    action: {
        type: 'APIDataAny'
    },
    expiresDate: {
        type: 'Date'
    },
    to: {
        type: 'string'
    },
    status: {
        type: 'string',
        default: 'valid',
        enum: 'valid,consumed,expired,deleted,failed'
    },
    sendResult: {
        type: 'APIDataAny'
    },
    type: {
        type: 'string'
    },
    isPersistent: {
        type: 'boolean',
        default: 'false'
    },
    url: {
        type: 'string'
    },
    setConsumerRole: {
        type: 'string'
    },
    verifyConsumer: {
        type: 'boolean',
        default: 'false'
    },
    fromId: {
        type: 'number',
        relationName: 'from'
    },
    toUserId: {
        type: 'number',
        relationName: 'toUser'
    },
    departmentId: {
        type: 'number',
        relationName: 'department'
    },
    from: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    toUser: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    roomId: {
        type: 'number',
        relationName: 'invites'
    },
    room: {
        type: 'Room',
        isRelationshipProperty: 'true'
    },
    consumers: {
        type: 'BlueUser[]',
        isRelationshipProperty: 'true'
    },
    consumptions: {
        type: 'InviteConsumerThrough[]',
        isRelationshipProperty: 'true'
    },
    createdTime: {
        type: 'Date'
    }
}

export const APIMetaDataInviteRelations = {
    from: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'fromId'
    },
    toUser: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'toUserId'
    },
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: ''
    },
    room: {
        type: 'belongsTo',
        model: 'Room',
        foreignKey: ''
    },
    consumers: {
        type: 'hasMany',
        model: 'BlueUser',
        foreignKey: 'inviteId',
        through: 'InviteConsumerThrough',
        description: 'Users that have consumed this invite.'
    },
    consumptions: {
        type: 'hasMany',
        model: 'InviteConsumerThrough',
        foreignKey: 'inviteId',
        description: 'Users that have consumed this invite.'
    }
}

/** LinkPreview */
export interface APIDataLinkPreview {
    id?: number
    link?: string
    preview?: APIDataAny
    createdDate?: Date
}

/** LinkPreview Metadata */
export const APIMetaDataLinkPreviewPropertyNames = ['id', 'link', 'preview', 'createdDate']
export const APIMetaDataLinkPreviewRelationNames = []

export const APIMetaDataLinkPreviewProperties = {
    id: {
        type: 'number'
    },
    link: {
        type: 'string'
    },
    preview: {
        type: 'APIDataAny'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataLinkPreviewRelations = {
}

/** Mailchimp */
export interface APIDataMailchimp {
    id?: number
}

/** Mailchimp Metadata */
export const APIMetaDataMailchimpPropertyNames = ['id']
export const APIMetaDataMailchimpRelationNames = []

export const APIMetaDataMailchimpProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataMailchimpRelations = {
}

/** MediaMultiPartUploadPart */
export interface APIDataMediaMultiPartUploadPart {
    id?: number
    partNumber?: number
    ETag?: string
    /** From MediaMultiPartUpload hasMany MediaMultiPartUploadPart as parts */
    mediaMultiPartUploadId?: number
    /** Relation belongsTo MediaMultiPartUpload */
    mediaMultiPartUpload?: APIDataMediaMultiPartUpload
}

/** MediaMultiPartUploadPart Metadata */
export const APIMetaDataMediaMultiPartUploadPartPropertyNames = ['id', 'partNumber', 'ETag', 'mediaMultiPartUploadId']
export const APIMetaDataMediaMultiPartUploadPartRelationNames = ['mediaMultiPartUpload']

export const APIMetaDataMediaMultiPartUploadPartProperties = {
    id: {
        type: 'number'
    },
    partNumber: {
        type: 'number'
    },
    ETag: {
        type: 'string'
    },
    mediaMultiPartUploadId: {
        type: 'number',
        relationName: 'parts'
    },
    mediaMultiPartUpload: {
        type: 'MediaMultiPartUpload',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataMediaMultiPartUploadPartRelations = {
    mediaMultiPartUpload: {
        type: 'belongsTo',
        model: 'MediaMultiPartUpload'
    }
}

/** MediaMultiPartUpload */
export interface APIDataMediaMultiPartUpload {
    id?: number
    uploadId?: string
    mimetype?: string
    fileName?: string
    fileKey?: string
    privateBucket?: boolean
    postProcessing?: APIDataAny
    /** From relation belongsTo BlueUser as owner */
    ownerId?: number
    /** Relation belongsTo BlueUser */
    owner?: APIDataBlueUser
    /** Relation hasMany MediaMultiPartUploadPart */
    parts?: APIDataMediaMultiPartUploadPart[]
    /** From relation belongsTo Device as device */
    deviceId?: number
    /** Relation belongsTo Device */
    device?: APIDataDevice
    createdDate?: Date
}

/** MediaMultiPartUpload Metadata */
export const APIMetaDataMediaMultiPartUploadPropertyNames = ['id', 'uploadId', 'mimetype', 'fileName', 'fileKey', 'privateBucket', 'postProcessing', 'ownerId', 'deviceId', 'createdDate']
export const APIMetaDataMediaMultiPartUploadRelationNames = ['owner', 'parts', 'device']

export const APIMetaDataMediaMultiPartUploadProperties = {
    id: {
        type: 'number'
    },
    uploadId: {
        type: 'string'
    },
    mimetype: {
        type: 'string'
    },
    fileName: {
        type: 'string'
    },
    fileKey: {
        type: 'string'
    },
    privateBucket: {
        type: 'boolean'
    },
    postProcessing: {
        type: 'APIDataAny',
        default: '{}'
    },
    ownerId: {
        type: 'number',
        relationName: 'owner'
    },
    owner: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    parts: {
        type: 'MediaMultiPartUploadPart[]',
        isRelationshipProperty: 'true'
    },
    deviceId: {
        type: 'number',
        relationName: 'device'
    },
    device: {
        type: 'Device',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataMediaMultiPartUploadRelations = {
    owner: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'ownerId'
    },
    parts: {
        type: 'hasMany',
        model: 'MediaMultiPartUploadPart',
        foreignKey: 'mediaMultiPartUploadId'
    },
    device: {
        type: 'belongsTo',
        model: 'Device',
        foreignKey: 'deviceId'
    }
}

/** Media */
export interface APIDataMedia {
    id?: number
    mimetype?: string
    url?: string
    requiresAuth?: boolean
    description?: string
    previewUrl?: string
    fileName?: string
    fileKey?: string
    previewKey?: string
    contentLength?: number
    meta?: APIDataAny
    processing?: string
    /** From relation belongsTo BlueUser as owner */
    ownerId?: number
    /** From relation belongsTo Department as department */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** From Thread hasMany Media as media */
    threadId?: number
    /** Relation belongsTo Thread */
    thread?: APIDataThread
    /** From Room hasMany Media as media */
    roomId?: number
    /** Relation belongsTo Room */
    room?: APIDataRoom
    /** From relation belongsTo Bulletin as bulletin */
    bulletinId?: number
    /** Relation belongsTo Bulletin */
    bulletin?: APIDataBulletin
    /** Relation belongsTo BlueUser */
    owner?: APIDataBlueUser
    updatedDate?: Date
}

/** Media Metadata */
export const APIMetaDataMediaPropertyNames = ['id', 'mimetype', 'url', 'requiresAuth', 'description', 'previewUrl', 'fileName', 'fileKey', 'previewKey', 'contentLength', 'meta', 'processing', 'ownerId', 'departmentId', 'threadId', 'roomId', 'bulletinId', 'updatedDate']
export const APIMetaDataMediaRelationNames = ['department', 'thread', 'room', 'bulletin', 'owner']

export const APIMetaDataMediaProperties = {
    id: {
        type: 'number'
    },
    mimetype: {
        type: 'string'
    },
    url: {
        type: 'string'
    },
    requiresAuth: {
        type: 'boolean'
    },
    description: {
        type: 'string'
    },
    previewUrl: {
        type: 'string'
    },
    fileName: {
        type: 'string'
    },
    fileKey: {
        type: 'string'
    },
    previewKey: {
        type: 'string'
    },
    contentLength: {
        type: 'number'
    },
    meta: {
        type: 'APIDataAny',
        default: '{}'
    },
    processing: {
        type: 'string'
    },
    ownerId: {
        type: 'number',
        relationName: 'owner'
    },
    departmentId: {
        type: 'number',
        relationName: 'department'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    threadId: {
        type: 'number',
        relationName: 'media'
    },
    thread: {
        type: 'Thread',
        isRelationshipProperty: 'true'
    },
    roomId: {
        type: 'number',
        relationName: 'media'
    },
    room: {
        type: 'Room',
        isRelationshipProperty: 'true'
    },
    bulletinId: {
        type: 'number',
        relationName: 'bulletin'
    },
    bulletin: {
        type: 'Bulletin',
        isRelationshipProperty: 'true'
    },
    owner: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    updatedDate: {
        type: 'Date'
    }
}

export const APIMetaDataMediaRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department'
    },
    thread: {
        type: 'belongsTo',
        model: 'Thread'
    },
    room: {
        type: 'belongsTo',
        model: 'Room'
    },
    bulletin: {
        type: 'belongsTo',
        model: 'Bulletin'
    },
    owner: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'ownerId'
    }
}

/** MySQLProcessListMonitoring */
export interface APIDataMySQLProcessListMonitoring {
    id?: number
    dateTime?: Date
    user?: string
    host?: string
    command?: string
    duration?: number
    state?: string
    info?: string
}

/** MySQLProcessListMonitoring Metadata */
export const APIMetaDataMySQLProcessListMonitoringPropertyNames = ['id', 'dateTime', 'user', 'host', 'command', 'duration', 'state', 'info']
export const APIMetaDataMySQLProcessListMonitoringRelationNames = []

export const APIMetaDataMySQLProcessListMonitoringProperties = {
    id: {
        type: 'number'
    },
    dateTime: {
        type: 'Date'
    },
    user: {
        type: 'string'
    },
    host: {
        type: 'string'
    },
    command: {
        type: 'string'
    },
    duration: {
        type: 'number'
    },
    state: {
        type: 'string'
    },
    info: {
        type: 'string'
    }
}

export const APIMetaDataMySQLProcessListMonitoringRelations = {
}

/** Push */
export interface APIDataPush {
    id?: number
}

/** Push Metadata */
export const APIMetaDataPushPropertyNames = ['id']
export const APIMetaDataPushRelationNames = []

export const APIMetaDataPushProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataPushRelations = {
}

/** ReactionUserThrough */
export interface APIDataReactionUserThrough {
    id?: number
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** From Reaction hasMany ReactionUserThrough as reactedByThrough */
    reactionId?: number
    /** Relation belongsTo Reaction */
    reaction?: APIDataReaction
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    reactedToId?: number
    reactedToType?: string
    reactedTo?: APIDataAny
    createdDate?: Date
}

/** ReactionUserThrough Metadata */
export const APIMetaDataReactionUserThroughPropertyNames = ['id', 'blueUserId', 'reactionId', 'reactedToId', 'reactedToType', 'reactedTo', 'createdDate']
export const APIMetaDataReactionUserThroughRelationNames = ['reaction', 'user']

export const APIMetaDataReactionUserThroughProperties = {
    id: {
        type: 'number'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    reactionId: {
        type: 'number',
        relationName: 'reactedByThrough'
    },
    reaction: {
        type: 'Reaction',
        isRelationshipProperty: 'true'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    reactedToId: {
        type: 'number'
    },
    reactedToType: {
        type: 'string'
    },
    reactedTo: {
        type: 'APIDataAny'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataReactionUserThroughRelations = {
    reaction: {
        type: 'belongsTo',
        model: 'Reaction',
        foreignKey: 'reactionId'
    },
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    },
    reactedTo: {
        type: 'belongsTo',
        polymorphic: '[object Object]'
    }
}

/** Reaction */
export interface APIDataReaction {
    id?: number
    text?: string
    count?: number
    reactedToId?: number
    reactedToType?: string
    reactedTo?: APIDataAny
    /** Relation hasMany BlueUser */
    reactedBy?: APIDataBlueUser[]
    /** Relation hasMany ReactionUserThrough */
    reactedByThrough?: APIDataReactionUserThrough[]
}

/** Reaction Metadata */
export const APIMetaDataReactionPropertyNames = ['id', 'text', 'count', 'reactedToId', 'reactedToType', 'reactedTo']
export const APIMetaDataReactionRelationNames = ['reactedBy', 'reactedByThrough']

export const APIMetaDataReactionProperties = {
    id: {
        type: 'number'
    },
    text: {
        type: 'string'
    },
    count: {
        type: 'number'
    },
    reactedToId: {
        type: 'number'
    },
    reactedToType: {
        type: 'string'
    },
    reactedTo: {
        type: 'APIDataAny'
    },
    reactedBy: {
        type: 'BlueUser[]',
        isRelationshipProperty: 'true'
    },
    reactedByThrough: {
        type: 'ReactionUserThrough[]',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataReactionRelations = {
    reactedTo: {
        type: 'belongsTo',
        polymorphic: '[object Object]'
    },
    reactedBy: {
        type: 'hasMany',
        model: 'BlueUser',
        foreignKey: 'reactionId',
        through: 'ReactionUserThrough'
    },
    reactedByThrough: {
        type: 'hasMany',
        model: 'ReactionUserThrough',
        foreignKey: 'reactionId'
    }
}

/** ReadArchive */
export interface APIDataReadArchive {
    id?: number
    readableId?: number
    readableType?: string
    readable?: APIDataAny
    /** From relation belongsTo BlueUser as readBy */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    readBy?: APIDataBlueUser
    createdDate?: Date
}

/** ReadArchive Metadata */
export const APIMetaDataReadArchivePropertyNames = ['id', 'readableId', 'readableType', 'readable', 'blueUserId', 'createdDate']
export const APIMetaDataReadArchiveRelationNames = ['readBy']

export const APIMetaDataReadArchiveProperties = {
    id: {
        type: 'number'
    },
    readableId: {
        type: 'number'
    },
    readableType: {
        type: 'string'
    },
    readable: {
        type: 'APIDataAny'
    },
    blueUserId: {
        type: 'number',
        relationName: 'readBy'
    },
    readBy: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataReadArchiveRelations = {
    readable: {
        type: 'belongsTo',
        polymorphic: '[object Object]'
    },
    readBy: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** Read */
export interface APIDataRead {
    id?: number
    /** From relation belongsTo BlueUser as readBy */
    blueUserId?: number
    readableId?: number
    readableType?: string
    readable?: APIDataAny
    /** Relation belongsTo BlueUser */
    readBy?: APIDataBlueUser
    createdDate?: Date
}

/** Read Metadata */
export const APIMetaDataReadPropertyNames = ['id', 'blueUserId', 'readableId', 'readableType', 'readable', 'createdDate']
export const APIMetaDataReadRelationNames = ['readBy']

export const APIMetaDataReadProperties = {
    id: {
        type: 'number'
    },
    blueUserId: {
        type: 'number',
        relationName: 'readBy'
    },
    readableId: {
        type: 'number'
    },
    readableType: {
        type: 'string'
    },
    readable: {
        type: 'APIDataAny'
    },
    readBy: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataReadRelations = {
    readable: {
        type: 'belongsTo',
        polymorphic: '[object Object]'
    },
    readBy: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** Report */
export interface APIDataReport {
    id?: number
    description?: string
    objectId?: number
    objectType?: string
    object?: APIDataAny
    /** From relation belongsTo BlueUser as reportedBy */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    reportedBy?: APIDataBlueUser
    createdDate?: Date
}

/** Report Metadata */
export const APIMetaDataReportPropertyNames = ['id', 'description', 'objectId', 'objectType', 'object', 'blueUserId', 'createdDate']
export const APIMetaDataReportRelationNames = ['reportedBy']

export const APIMetaDataReportProperties = {
    id: {
        type: 'number'
    },
    description: {
        type: 'string',
        required: 'true'
    },
    objectId: {
        type: 'number'
    },
    objectType: {
        type: 'string'
    },
    object: {
        type: 'APIDataAny'
    },
    blueUserId: {
        type: 'number',
        relationName: 'reportedBy'
    },
    reportedBy: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataReportRelations = {
    object: {
        type: 'belongsTo',
        polymorphic: '[object Object]'
    },
    reportedBy: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** Reports */
export interface APIDataReports {
    id?: number
}

/** Reports Metadata */
export const APIMetaDataReportsPropertyNames = ['id']
export const APIMetaDataReportsRelationNames = []

export const APIMetaDataReportsProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataReportsRelations = {
}

/** ResetPasswordToken */
export interface APIDataResetPasswordToken {
    id?: string
    ttl?: number
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    createdDate?: Date
}

/** ResetPasswordToken Metadata */
export const APIMetaDataResetPasswordTokenPropertyNames = ['id', 'ttl', 'blueUserId', 'createdDate']
export const APIMetaDataResetPasswordTokenRelationNames = ['user']

export const APIMetaDataResetPasswordTokenProperties = {
    id: {
        type: 'string',
        id: 'true'
    },
    ttl: {
        type: 'number',
        ttl: 'true',
        default: '1209600',
        description: 'time to live in seconds (2 weeks by default)'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataResetPasswordTokenRelations = {
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** RoomBulletinThrough */
export interface APIDataRoomBulletinThrough {
    id?: number
    /** From relation belongsTo Bulletin as bulletin */
    bulletinId?: number
    /** From Room hasMany Bulletin as bulletins */
    roomId?: number
    /** Relation belongsTo Room */
    room?: APIDataRoom
    /** Relation belongsTo Bulletin */
    bulletin?: APIDataBulletin
}

/** RoomBulletinThrough Metadata */
export const APIMetaDataRoomBulletinThroughPropertyNames = ['id', 'bulletinId', 'roomId']
export const APIMetaDataRoomBulletinThroughRelationNames = ['room', 'bulletin']

export const APIMetaDataRoomBulletinThroughProperties = {
    id: {
        type: 'number'
    },
    bulletinId: {
        type: 'number',
        relationName: 'bulletin'
    },
    roomId: {
        type: 'number',
        relationName: 'bulletins'
    },
    room: {
        type: 'Room',
        isRelationshipProperty: 'true'
    },
    bulletin: {
        type: 'Bulletin',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataRoomBulletinThroughRelations = {
    room: {
        type: 'belongsTo',
        model: 'Room',
        foreignKey: 'roomId'
    },
    bulletin: {
        type: 'belongsTo',
        model: 'Bulletin',
        foreignKey: 'bulletinId'
    }
}

/** RoomJoinRequestRule */
export interface APIDataRoomJoinRequestRule {
    id?: number
    permission?: string
    type?: string
    value?: APIDataAny
    /** From Room hasMany RoomJoinRequestRule as roomJoinRequestRules */
    roomId?: number
    /** Relation belongsTo Room */
    room?: APIDataRoom
    deletedDate?: Date
}

/** RoomJoinRequestRule Metadata */
export const APIMetaDataRoomJoinRequestRulePropertyNames = ['id', 'permission', 'type', 'value', 'roomId', 'deletedDate']
export const APIMetaDataRoomJoinRequestRuleRelationNames = ['room']

export const APIMetaDataRoomJoinRequestRuleProperties = {
    id: {
        type: 'number'
    },
    permission: {
        type: 'string',
        enum: 'accept,deny',
        default: 'accept'
    },
    type: {
        type: 'string',
        enum: 'departmentDistanceFromRoom,departmentDistanceFromPoint,departmentState,emailDomain,departmentId'
    },
    value: {
        type: 'APIDataAny',
        default: ''
    },
    roomId: {
        type: 'number',
        relationName: 'roomJoinRequestRules'
    },
    room: {
        type: 'Room',
        isRelationshipProperty: 'true'
    },
    deletedDate: {
        type: 'Date'
    }
}

export const APIMetaDataRoomJoinRequestRuleRelations = {
    room: {
        type: 'belongsTo',
        model: 'Room',
        foreignKey: 'roomId'
    }
}

/** RoomJoinRequest */
export interface APIDataRoomJoinRequest {
    id?: number
    status?: string
    meta?: APIDataAny
    /** From relation belongsTo BlueUser as requestedBy */
    requestedById?: number
    /** From Room hasMany RoomJoinRequest as roomJoinRequests */
    roomId?: number
    /** Relation belongsTo Room */
    room?: APIDataRoom
    /** Relation belongsTo BlueUser */
    requestedBy?: APIDataBlueUser
    createdDate?: Date
    updatedDate?: Date
}

/** RoomJoinRequest Metadata */
export const APIMetaDataRoomJoinRequestPropertyNames = ['id', 'status', 'meta', 'requestedById', 'roomId', 'createdDate', 'updatedDate']
export const APIMetaDataRoomJoinRequestRelationNames = ['room', 'requestedBy']

export const APIMetaDataRoomJoinRequestProperties = {
    id: {
        type: 'number'
    },
    status: {
        type: 'string',
        enum: 'pending,accepted,rejected,blocked',
        default: 'pending'
    },
    meta: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    requestedById: {
        type: 'number',
        relationName: 'requestedBy'
    },
    roomId: {
        type: 'number',
        relationName: 'roomJoinRequests'
    },
    room: {
        type: 'Room',
        isRelationshipProperty: 'true'
    },
    requestedBy: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    }
}

export const APIMetaDataRoomJoinRequestRelations = {
    room: {
        type: 'belongsTo',
        model: 'Room',
        foreignKey: 'roomId'
    },
    requestedBy: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'requestedById'
    }
}

/** RoomManagerThrough */
export interface APIDataRoomManagerThrough {
    id?: number
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** From Room hasMany BlueUser as managers */
    roomId?: number
    /** Relation belongsTo Room */
    room?: APIDataRoom
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
}

/** RoomManagerThrough Metadata */
export const APIMetaDataRoomManagerThroughPropertyNames = ['id', 'blueUserId', 'roomId']
export const APIMetaDataRoomManagerThroughRelationNames = ['room', 'user']

export const APIMetaDataRoomManagerThroughProperties = {
    id: {
        type: 'number'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    roomId: {
        type: 'number',
        relationName: 'managers'
    },
    room: {
        type: 'Room',
        isRelationshipProperty: 'true'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataRoomManagerThroughRelations = {
    room: {
        type: 'belongsTo',
        model: 'Room'
    },
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** RoomMessageForwarded */
export interface APIDataRoomMessageForwarded {
    id?: number
    /** From relation belongsTo BlueUser as originalMessageOwner */
    originalOwnerId?: number
    /** Relation belongsTo BlueUser */
    originalMessageOwner?: APIDataBlueUser
    /** From relation belongsTo RoomMessage as originalMessage */
    originalMessageId?: number
    /** Relation belongsTo RoomMessage */
    originalMessage?: APIDataRoomMessage
    /** From relation belongsTo BlueUser as forwardedByUser */
    forwardedByUserId?: number
    /** Relation belongsTo BlueUser */
    forwardedByUser?: APIDataBlueUser
    /** From relation belongsTo Room as forwardedToRoom */
    forwardedToRoomId?: number
    /** Relation belongsTo Room */
    forwardedToRoom?: APIDataRoom
    /** From relation belongsTo RoomMessage as forwardedMessage */
    forwardedMessageId?: number
    /** Relation belongsTo RoomMessage */
    forwardedMessage?: APIDataRoomMessage
    createdDate?: Date
}

/** RoomMessageForwarded Metadata */
export const APIMetaDataRoomMessageForwardedPropertyNames = ['id', 'originalOwnerId', 'originalMessageId', 'forwardedByUserId', 'forwardedToRoomId', 'forwardedMessageId', 'createdDate']
export const APIMetaDataRoomMessageForwardedRelationNames = ['originalMessageOwner', 'originalMessage', 'forwardedByUser', 'forwardedToRoom', 'forwardedMessage']

export const APIMetaDataRoomMessageForwardedProperties = {
    id: {
        type: 'number'
    },
    originalOwnerId: {
        type: 'number',
        relationName: 'originalMessageOwner'
    },
    originalMessageOwner: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    originalMessageId: {
        type: 'number',
        relationName: 'originalMessage'
    },
    originalMessage: {
        type: 'RoomMessage',
        isRelationshipProperty: 'true'
    },
    forwardedByUserId: {
        type: 'number',
        relationName: 'forwardedByUser'
    },
    forwardedByUser: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    forwardedToRoomId: {
        type: 'number',
        relationName: 'forwardedToRoom'
    },
    forwardedToRoom: {
        type: 'Room',
        isRelationshipProperty: 'true'
    },
    forwardedMessageId: {
        type: 'number',
        relationName: 'forwardedMessage'
    },
    forwardedMessage: {
        type: 'RoomMessage',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataRoomMessageForwardedRelations = {
    originalMessageOwner: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'originalOwnerId'
    },
    originalMessage: {
        type: 'belongsTo',
        model: 'RoomMessage',
        foreignKey: 'originalMessageId'
    },
    forwardedByUser: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'forwardedByUserId'
    },
    forwardedToRoom: {
        type: 'belongsTo',
        model: 'Room',
        foreignKey: 'forwardedToRoomId'
    },
    forwardedMessage: {
        type: 'belongsTo',
        model: 'RoomMessage',
        foreignKey: 'forwardedMessageId'
    }
}

/** RoomMessageMediaThrough */
export interface APIDataRoomMessageMediaThrough {
    id?: number
    /** From RoomMessage hasMany Media as media */
    roomMessageId?: number
    /** Relation belongsTo RoomMessage */
    message?: APIDataRoomMessage
    /** From relation belongsTo Media as media */
    mediaId?: number
    /** Relation belongsTo Media */
    media?: APIDataMedia
}

/** RoomMessageMediaThrough Metadata */
export const APIMetaDataRoomMessageMediaThroughPropertyNames = ['id', 'roomMessageId', 'mediaId']
export const APIMetaDataRoomMessageMediaThroughRelationNames = ['message', 'media']

export const APIMetaDataRoomMessageMediaThroughProperties = {
    id: {
        type: 'number'
    },
    roomMessageId: {
        type: 'number',
        relationName: 'media'
    },
    message: {
        type: 'RoomMessage',
        isRelationshipProperty: 'true'
    },
    mediaId: {
        type: 'number',
        relationName: 'media'
    },
    media: {
        type: 'Media',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataRoomMessageMediaThroughRelations = {
    message: {
        type: 'belongsTo',
        model: 'RoomMessage',
        foreignKey: 'roomMessageId'
    },
    media: {
        type: 'belongsTo',
        model: 'Media',
        foreignKey: 'mediaId'
    }
}

/** RoomMessage */
export interface APIDataRoomMessage {
    id?: number
    text?: string
    isUrgent?: boolean
    type?: string
    isRetracted?: boolean
    meta?: APIDataAny
    /** From relation belongsTo BlueUser as owner */
    ownerId?: number
    /** Relation belongsTo BlueUser */
    owner?: APIDataBlueUser
    /** From Room hasMany RoomMessage as messages */
    roomId?: number
    /** Relation belongsTo Room */
    room?: APIDataRoom
    reports?: APIDataReport[]
    /** Relation hasMany Media */
    media?: APIDataMedia[]
    reads?: APIDataRead[]
    reactions?: APIDataReaction[]
    reactedByThrough?: APIDataReactionUserThrough[]
    /** From RoomMessage hasMany RoomMessage as replies */
    repliesToId?: number
    /** Relation hasMany RoomMessage */
    replies?: APIDataRoomMessage[]
    createdDate?: Date
    updatedDate?: Date
    publishedDate?: Date
}

/** RoomMessage Metadata */
export const APIMetaDataRoomMessagePropertyNames = ['id', 'text', 'isUrgent', 'type', 'isRetracted', 'meta', 'ownerId', 'roomId', 'reports', 'reads', 'reactions', 'reactedByThrough', 'repliesToId', 'createdDate', 'updatedDate', 'publishedDate']
export const APIMetaDataRoomMessageRelationNames = ['owner', 'room', 'media', 'replies']

export const APIMetaDataRoomMessageProperties = {
    id: {
        type: 'number'
    },
    text: {
        type: 'string'
    },
    isUrgent: {
        type: 'boolean',
        default: 'false'
    },
    type: {
        type: 'string',
        default: 'user',
        enum: 'user,system,event,user_audio'
    },
    isRetracted: {
        type: 'boolean',
        default: 'false'
    },
    meta: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    ownerId: {
        type: 'number',
        relationName: 'owner'
    },
    owner: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    roomId: {
        type: 'number',
        relationName: 'messages'
    },
    room: {
        type: 'Room',
        isRelationshipProperty: 'true'
    },
    reports: {
        type: 'Report[]'
    },
    media: {
        type: 'Media[]',
        isRelationshipProperty: 'true'
    },
    reads: {
        type: 'Read[]'
    },
    reactions: {
        type: 'Reaction[]'
    },
    reactedByThrough: {
        type: 'ReactionUserThrough[]'
    },
    repliesToId: {
        type: 'number',
        relationName: 'replies'
    },
    replies: {
        type: 'RoomMessage[]',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    },
    publishedDate: {
        type: 'Date'
    }
}

export const APIMetaDataRoomMessageRelations = {
    owner: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'ownerId'
    },
    room: {
        type: 'belongsTo',
        model: 'Room'
    },
    reports: {
        type: 'hasMany',
        model: 'Report',
        as: 'reports',
        polymorphic: 'object'
    },
    media: {
        type: 'hasMany',
        model: 'Media',
        foreignKey: 'roomMessageId',
        through: 'RoomMessageMediaThrough'
    },
    reads: {
        type: 'hasMany',
        model: 'Read',
        polymorphic: 'readable'
    },
    reactions: {
        type: 'hasMany',
        model: 'Reaction',
        polymorphic: 'reactedTo'
    },
    reactedByThrough: {
        type: 'hasMany',
        model: 'ReactionUserThrough',
        polymorphic: 'reactedTo'
    },
    replies: {
        type: 'hasMany',
        model: 'RoomMessage',
        foreignKey: 'repliesToId'
    }
}

/** Room */
export interface APIDataRoom {
    id?: number
    name?: string
    description?: string
    isArchived?: boolean
    options?: APIDataAny
    meta?: APIDataAny
    isSearchable?: boolean
    location?: APIDataGeoPoint
    /** From relation belongsTo Department as department */
    departmentId?: number
    /** Relation belongsTo Department */
    department?: APIDataDepartment
    /** Relation hasMany BlueUser */
    members?: APIDataBlueUser[]
    /** Relation hasMany BlueUser */
    managers?: APIDataBlueUser[]
    /** Relation hasMany Bulletin */
    bulletins?: APIDataBulletin[]
    createdDate?: Date
    updatedDate?: Date
}

/** Room Metadata */
export const APIMetaDataRoomPropertyNames = ['id', 'name', 'description', 'isArchived', 'options', 'meta', 'isSearchable', 'location', 'departmentId', 'createdDate', 'updatedDate']
export const APIMetaDataRoomRelationNames = ['department', 'members', 'managers', 'bulletins']

export const APIMetaDataRoomProperties = {
    id: {
        type: 'number'
    },
    name: {
        type: 'string',
        default: ''
    },
    description: {
        type: 'string',
        default: ''
    },
    isArchived: {
        type: 'boolean',
        default: 'false'
    },
    options: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    meta: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    isSearchable: {
        type: 'boolean',
        default: 'false'
    },
    location: {
        type: 'GeoPoint'
    },
    departmentId: {
        type: 'number',
        relationName: 'department'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    },
    members: {
        type: 'BlueUser[]',
        isRelationshipProperty: 'true'
    },
    managers: {
        type: 'BlueUser[]',
        isRelationshipProperty: 'true'
    },
    bulletins: {
        type: 'Bulletin[]',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    }
}

export const APIMetaDataRoomRelations = {
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: ''
    },
    members: {
        type: 'hasMany',
        model: 'BlueUser',
        foreignKey: '',
        through: 'UserRoomThrough'
    },
    managers: {
        type: 'hasMany',
        model: 'BlueUser',
        through: 'RoomManagerThrough'
    },
    bulletins: {
        type: 'hasMany',
        model: 'Bulletin',
        through: 'RoomBulletinThrough'
    },
    messages: {
        type: 'hasMany',
        model: 'RoomMessage',
        options: '[object Object]'
    },
    invites: {
        type: 'hasMany',
        model: 'Invite',
        options: '[object Object]'
    },
    roomJoinRequests: {
        type: 'hasMany',
        model: 'RoomJoinRequest',
        foreignKey: 'roomId',
        options: '[object Object]'
    },
    roomJoinRequestRules: {
        type: 'hasMany',
        model: 'RoomJoinRequestRule',
        foreignKey: 'roomId',
        options: '[object Object]'
    },
    media: {
        type: 'hasMany',
        model: 'Media',
        options: '[object Object]'
    }
}

/** Slack */
export interface APIDataSlack {
    id?: number
}

/** Slack Metadata */
export const APIMetaDataSlackPropertyNames = ['id']
export const APIMetaDataSlackRelationNames = []

export const APIMetaDataSlackProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataSlackRelations = {
}

/** ThreadMessageDepartmentThrough */
export interface APIDataThreadMessageDepartmentThrough {
    id?: number
    wasVerified?: boolean
    /** From relation belongsTo Department as department */
    departmentId?: number
    /** From ThreadMessage hasMany Department as department */
    threadMessageId?: number
    /** Relation belongsTo ThreadMessage */
    message?: APIDataThreadMessage
    /** Relation belongsTo Department */
    department?: APIDataDepartment
}

/** ThreadMessageDepartmentThrough Metadata */
export const APIMetaDataThreadMessageDepartmentThroughPropertyNames = ['id', 'wasVerified', 'departmentId', 'threadMessageId']
export const APIMetaDataThreadMessageDepartmentThroughRelationNames = ['message', 'department']

export const APIMetaDataThreadMessageDepartmentThroughProperties = {
    id: {
        type: 'number'
    },
    wasVerified: {
        type: 'boolean',
        default: 'true'
    },
    departmentId: {
        type: 'number',
        relationName: 'department'
    },
    threadMessageId: {
        type: 'number',
        relationName: 'department'
    },
    message: {
        type: 'ThreadMessage',
        isRelationshipProperty: 'true'
    },
    department: {
        type: 'Department',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataThreadMessageDepartmentThroughRelations = {
    message: {
        type: 'belongsTo',
        model: 'ThreadMessage',
        foreignKey: 'threadMessageId'
    },
    department: {
        type: 'belongsTo',
        model: 'Department',
        foreignKey: 'departmentId'
    }
}

/** ThreadMessageMediaThrough */
export interface APIDataThreadMessageMediaThrough {
    id?: number
    /** From ThreadMessage hasMany Media as media */
    threadMessageId?: number
    /** Relation belongsTo ThreadMessage */
    message?: APIDataThreadMessage
    /** From relation belongsTo Media as media */
    mediaId?: number
    /** Relation belongsTo Media */
    media?: APIDataMedia
}

/** ThreadMessageMediaThrough Metadata */
export const APIMetaDataThreadMessageMediaThroughPropertyNames = ['id', 'threadMessageId', 'mediaId']
export const APIMetaDataThreadMessageMediaThroughRelationNames = ['message', 'media']

export const APIMetaDataThreadMessageMediaThroughProperties = {
    id: {
        type: 'number'
    },
    threadMessageId: {
        type: 'number',
        relationName: 'media'
    },
    message: {
        type: 'ThreadMessage',
        isRelationshipProperty: 'true'
    },
    mediaId: {
        type: 'number',
        relationName: 'media'
    },
    media: {
        type: 'Media',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataThreadMessageMediaThroughRelations = {
    message: {
        type: 'belongsTo',
        model: 'ThreadMessage',
        foreignKey: 'threadMessageId'
    },
    media: {
        type: 'belongsTo',
        model: 'Media',
        foreignKey: 'mediaId'
    }
}

/** ThreadMessage */
export interface APIDataThreadMessage {
    id?: number
    text?: string
    isUrgent?: boolean
    type?: string
    isRetracted?: boolean
    meta?: APIDataAny
    /** From relation belongsTo BlueUser as owner */
    ownerId?: number
    /** Relation belongsTo BlueUser */
    owner?: APIDataBlueUser
    /** From Thread hasMany ThreadMessage as messages */
    threadId?: number
    /** Relation belongsTo Thread */
    thread?: APIDataThread
    reports?: APIDataReport[]
    /** Relation hasMany Media */
    media?: APIDataMedia[]
    reads?: APIDataRead[]
    reactions?: APIDataReaction[]
    reactedByThrough?: APIDataReactionUserThrough[]
    /** Relation hasMany Department */
    department?: APIDataDepartment[]
    createdDate?: Date
    updatedDate?: Date
    publishedDate?: Date
}

/** ThreadMessage Metadata */
export const APIMetaDataThreadMessagePropertyNames = ['id', 'text', 'isUrgent', 'type', 'isRetracted', 'meta', 'ownerId', 'threadId', 'reports', 'reads', 'reactions', 'reactedByThrough', 'createdDate', 'updatedDate', 'publishedDate']
export const APIMetaDataThreadMessageRelationNames = ['owner', 'thread', 'media', 'department']

export const APIMetaDataThreadMessageProperties = {
    id: {
        type: 'number'
    },
    text: {
        type: 'string'
    },
    isUrgent: {
        type: 'boolean',
        default: 'false'
    },
    type: {
        type: 'string',
        default: 'user',
        enum: 'user,system,event,user_audio'
    },
    isRetracted: {
        type: 'boolean',
        default: 'false'
    },
    meta: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    ownerId: {
        type: 'number',
        relationName: 'owner'
    },
    owner: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    threadId: {
        type: 'number',
        relationName: 'messages'
    },
    thread: {
        type: 'Thread',
        isRelationshipProperty: 'true'
    },
    reports: {
        type: 'Report[]'
    },
    media: {
        type: 'Media[]',
        isRelationshipProperty: 'true'
    },
    reads: {
        type: 'Read[]'
    },
    reactions: {
        type: 'Reaction[]'
    },
    reactedByThrough: {
        type: 'ReactionUserThrough[]'
    },
    department: {
        type: 'Department[]',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    },
    publishedDate: {
        type: 'Date'
    }
}

export const APIMetaDataThreadMessageRelations = {
    owner: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'ownerId'
    },
    thread: {
        type: 'belongsTo',
        model: 'Thread'
    },
    reports: {
        type: 'hasMany',
        model: 'Report',
        as: 'reports',
        polymorphic: 'object'
    },
    media: {
        type: 'hasMany',
        model: 'Media',
        through: 'ThreadMessageMediaThrough'
    },
    reads: {
        type: 'hasMany',
        model: 'Read',
        polymorphic: 'readable'
    },
    reactions: {
        type: 'hasMany',
        model: 'Reaction',
        polymorphic: 'reactedTo'
    },
    reactedByThrough: {
        type: 'hasMany',
        model: 'ReactionUserThrough',
        polymorphic: 'reactedTo'
    },
    department: {
        type: 'hasMany',
        model: 'Department',
        through: 'ThreadMessageDepartmentThrough'
    }
}

/** ThreadUserThrough */
export interface APIDataThreadUserThrough {
    id?: number
    isEnabled?: boolean
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** From Thread hasMany BlueUser as users */
    threadId?: number
    /** Relation belongsTo Thread */
    thread?: APIDataThread
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    createdDate?: Date
    updatedDate?: Date
}

/** ThreadUserThrough Metadata */
export const APIMetaDataThreadUserThroughPropertyNames = ['id', 'isEnabled', 'blueUserId', 'threadId', 'createdDate', 'updatedDate']
export const APIMetaDataThreadUserThroughRelationNames = ['thread', 'user']

export const APIMetaDataThreadUserThroughProperties = {
    id: {
        type: 'number'
    },
    isEnabled: {
        type: 'boolean',
        default: 'false'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    threadId: {
        type: 'number',
        relationName: 'users'
    },
    thread: {
        type: 'Thread',
        isRelationshipProperty: 'true'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    }
}

export const APIMetaDataThreadUserThroughRelations = {
    thread: {
        type: 'belongsTo',
        model: 'Thread',
        foreignKey: 'threadId'
    },
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** Thread */
export interface APIDataThread {
    id?: number
    usersKey?: string
    meta?: APIDataAny
    /** Relation hasMany ThreadMessage */
    messages?: APIDataThreadMessage[]
    /** Relation hasMany BlueUser */
    users?: APIDataBlueUser[]
    createdDate?: Date
    updatedDate?: Date
}

/** Thread Metadata */
export const APIMetaDataThreadPropertyNames = ['id', 'usersKey', 'meta', 'createdDate', 'updatedDate']
export const APIMetaDataThreadRelationNames = ['messages', 'users']

export const APIMetaDataThreadProperties = {
    id: {
        type: 'number'
    },
    usersKey: {
        type: 'string'
    },
    meta: {
        type: 'APIDataAny',
        default: '[object Object]'
    },
    messages: {
        type: 'ThreadMessage[]',
        isRelationshipProperty: 'true'
    },
    users: {
        type: 'BlueUser[]',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    },
    updatedDate: {
        type: 'Date'
    }
}

export const APIMetaDataThreadRelations = {
    messages: {
        type: 'hasMany',
        model: 'ThreadMessage'
    },
    users: {
        type: 'hasMany',
        model: 'BlueUser',
        through: 'ThreadUserThrough'
    },
    media: {
        type: 'hasMany',
        model: 'Media',
        options: '[object Object]'
    }
}

/** UserCRM */
export interface APIDataUserCRM {
    id?: number
    key?: string
    valueString?: string
    valueType?: string
    valueNumber?: number
    valueDate?: Date
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    /** From UserCRM hasMany UserCRM as children */
    parentId?: number
    /** Relation belongsTo UserCRM */
    parent?: APIDataUserCRM
    /** Relation hasMany UserCRM */
    children?: APIDataUserCRM[]
    deletedDate?: Date
}

/** UserCRM Metadata */
export const APIMetaDataUserCRMPropertyNames = ['id', 'key', 'valueString', 'valueType', 'valueNumber', 'valueDate', 'blueUserId', 'parentId', 'deletedDate']
export const APIMetaDataUserCRMRelationNames = ['user', 'parent', 'children']

export const APIMetaDataUserCRMProperties = {
    id: {
        type: 'number'
    },
    key: {
        type: 'string'
    },
    valueString: {
        type: 'string'
    },
    valueType: {
        type: 'string',
        default: 'string'
    },
    valueNumber: {
        type: 'number'
    },
    valueDate: {
        type: 'Date'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    parentId: {
        type: 'number',
        relationName: 'children'
    },
    parent: {
        type: 'UserCRM',
        isRelationshipProperty: 'true'
    },
    children: {
        type: 'UserCRM[]',
        isRelationshipProperty: 'true'
    },
    deletedDate: {
        type: 'Date'
    }
}

export const APIMetaDataUserCRMRelations = {
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    },
    parent: {
        type: 'belongsTo',
        model: 'UserCRM',
        foreignKey: 'parentId'
    },
    children: {
        type: 'hasMany',
        model: 'UserCRM',
        foreignKey: 'parentId'
    }
}

/** UserIdMediaThrough */
export interface APIDataUserIdMediaThrough {
    id?: number
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    /** From relation belongsTo Media as media */
    mediaId?: number
    /** Relation belongsTo Media */
    media?: APIDataMedia
}

/** UserIdMediaThrough Metadata */
export const APIMetaDataUserIdMediaThroughPropertyNames = ['id', 'blueUserId', 'mediaId']
export const APIMetaDataUserIdMediaThroughRelationNames = ['user', 'media']

export const APIMetaDataUserIdMediaThroughProperties = {
    id: {
        type: 'number'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    mediaId: {
        type: 'number',
        relationName: 'media'
    },
    media: {
        type: 'Media',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataUserIdMediaThroughRelations = {
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    },
    media: {
        type: 'belongsTo',
        model: 'Media',
        foreignKey: 'mediaId'
    }
}

/** UserPreviousPassword */
export interface APIDataUserPreviousPassword {
    id?: number
    password?: string
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
    createdDate?: Date
}

/** UserPreviousPassword Metadata */
export const APIMetaDataUserPreviousPasswordPropertyNames = ['id', 'password', 'blueUserId', 'createdDate']
export const APIMetaDataUserPreviousPasswordRelationNames = ['user']

export const APIMetaDataUserPreviousPasswordProperties = {
    id: {
        type: 'number'
    },
    password: {
        type: 'string'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataUserPreviousPasswordRelations = {
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** UserProfile */
export interface APIDataUserProfile {
    id?: number
    firstName?: string
    lastName?: string
    publicImage?: string
    anonymousImage?: string
    birthDate?: Date
    phoneNumber?: string
    badgeNumber?: string
    serviceStartedDate?: Date
    position?: string
}

/** UserProfile Metadata */
export const APIMetaDataUserProfilePropertyNames = ['id', 'firstName', 'lastName', 'publicImage', 'anonymousImage', 'birthDate', 'phoneNumber', 'badgeNumber', 'serviceStartedDate', 'position']
export const APIMetaDataUserProfileRelationNames = []

export const APIMetaDataUserProfileProperties = {
    id: {
        type: 'number'
    },
    firstName: {
        type: 'string',
        default: ''
    },
    lastName: {
        type: 'string',
        default: ''
    },
    publicImage: {
        type: 'string'
    },
    anonymousImage: {
        type: 'string'
    },
    birthDate: {
        type: 'Date'
    },
    phoneNumber: {
        type: 'string'
    },
    badgeNumber: {
        type: 'string'
    },
    serviceStartedDate: {
        type: 'Date'
    },
    position: {
        type: 'string'
    }
}

export const APIMetaDataUserProfileRelations = {
}

/** UserRoomThrough */
export interface APIDataUserRoomThrough {
    id?: number
    /** From relation belongsTo Room as room */
    roomId?: number
    /** Relation belongsTo Room */
    room?: APIDataRoom
    /** From relation belongsTo BlueUser as user */
    blueUserId?: number
    /** Relation belongsTo BlueUser */
    user?: APIDataBlueUser
}

/** UserRoomThrough Metadata */
export const APIMetaDataUserRoomThroughPropertyNames = ['id', 'roomId', 'blueUserId']
export const APIMetaDataUserRoomThroughRelationNames = ['room', 'user']

export const APIMetaDataUserRoomThroughProperties = {
    id: {
        type: 'number'
    },
    roomId: {
        type: 'number',
        relationName: 'room'
    },
    room: {
        type: 'Room',
        isRelationshipProperty: 'true'
    },
    blueUserId: {
        type: 'number',
        relationName: 'user'
    },
    user: {
        type: 'BlueUser',
        isRelationshipProperty: 'true'
    }
}

export const APIMetaDataUserRoomThroughRelations = {
    room: {
        type: 'belongsTo',
        model: 'Room',
        foreignKey: 'roomId'
    },
    user: {
        type: 'belongsTo',
        model: 'BlueUser',
        foreignKey: 'blueUserId'
    }
}

/** UserSettings */
export interface APIDataUserSettings {
    id?: number
    settings?: APIDataAny
}

/** UserSettings Metadata */
export const APIMetaDataUserSettingsPropertyNames = ['id', 'settings']
export const APIMetaDataUserSettingsRelationNames = []

export const APIMetaDataUserSettingsProperties = {
    id: {
        type: 'number'
    },
    settings: {
        type: 'APIDataAny',
        default: '[object Object]'
    }
}

export const APIMetaDataUserSettingsRelations = {
}

/** VerifiedEmailDomain */
export interface APIDataVerifiedEmailDomain {
    id?: string
    meta?: APIDataAny
    /** Relation hasMany Department */
    departments?: APIDataDepartment[]
    createdDate?: Date
}

/** VerifiedEmailDomain Metadata */
export const APIMetaDataVerifiedEmailDomainPropertyNames = ['id', 'meta', 'createdDate']
export const APIMetaDataVerifiedEmailDomainRelationNames = ['departments']

export const APIMetaDataVerifiedEmailDomainProperties = {
    id: {
        type: 'string',
        id: 'true',
        required: 'true',
        index: '[object Object]'
    },
    meta: {
        type: 'APIDataAny',
        default: '{}'
    },
    departments: {
        type: 'Department[]',
        isRelationshipProperty: 'true'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataVerifiedEmailDomainRelations = {
    departments: {
        type: 'hasMany',
        model: 'Department',
        through: 'DepartmentEmailDomainThrough',
        foreignKey: 'domain'
    }
}

/** WebContent */
export interface APIDataWebContent {
    id?: number
    content?: string
    label?: string
}

/** WebContent Metadata */
export const APIMetaDataWebContentPropertyNames = ['id', 'content', 'label']
export const APIMetaDataWebContentRelationNames = []

export const APIMetaDataWebContentProperties = {
    id: {
        type: 'number'
    },
    content: {
        type: 'string',
        required: 'true',
        datatype: 'longtext'
    },
    label: {
        type: 'string',
        required: 'true'
    }
}

export const APIMetaDataWebContentRelations = {
}

/** WorkQueue */
export interface APIDataWorkQueue {
    id?: number
}

/** WorkQueue Metadata */
export const APIMetaDataWorkQueuePropertyNames = ['id']
export const APIMetaDataWorkQueueRelationNames = []

export const APIMetaDataWorkQueueProperties = {
    id: {
        type: 'number'
    }
}

export const APIMetaDataWorkQueueRelations = {
}

/** GeoPoint */
export interface APIDataGeoPoint {
    lat?: number
    lng?: number
}

/** GeoPoint Metadata */
export const APIMetaDataGeoPointPropertyNames = ['id', 'lat', 'lng']
export const APIMetaDataGeoPointRelationNames = []

export const APIMetaDataGeoPointProperties = {
    id: {
    },
    lat: {
        type: 'number'
    },
    lng: {
        type: 'number'
    }
}

export const APIMetaDataGeoPointRelations = {
}

/** Count */
export interface APIDataCount {
    count?: number
}

/** Count Metadata */
export const APIMetaDataCountPropertyNames = ['id', 'count']
export const APIMetaDataCountRelationNames = []

export const APIMetaDataCountProperties = {
    id: {
    },
    count: {
        type: 'number'
    }
}

export const APIMetaDataCountRelations = {
}

/** ErrorResponse */
export interface APIDataErrorResponse {
    error: {
        name: string
        status: number
        message: string
        code: string
        stack: string
    }

}

/** ErrorResponse Metadata */
export const APIMetaDataErrorResponsePropertyNames = ['id', 'error']
export const APIMetaDataErrorResponseRelationNames = []

export const APIMetaDataErrorResponseProperties = {
    id: {
    },
    error: {
        name: 'string',
        status: 'number',
        message: 'string',
        code: 'string',
        stack: 'string'
    }
}

export const APIMetaDataErrorResponseRelations = {
}

/** LoginRequest */
export interface APIDataLoginRequest {
    email: string
    password: string
    deviceToken: string
}

/** LoginRequest Metadata */
export const APIMetaDataLoginRequestPropertyNames = ['id', 'email', 'password', 'deviceToken']
export const APIMetaDataLoginRequestRelationNames = []

export const APIMetaDataLoginRequestProperties = {
    id: {
    },
    email: {
        0: 's',
        1: 't',
        2: 'r',
        3: 'i',
        4: 'n',
        5: 'g'
    },
    password: {
        0: 's',
        1: 't',
        2: 'r',
        3: 'i',
        4: 'n',
        5: 'g'
    },
    deviceToken: {
        0: 's',
        1: 't',
        2: 'r',
        3: 'i',
        4: 'n',
        5: 'g'
    }
}

export const APIMetaDataLoginRequestRelations = {
}

/** BlueUserHistory */
export interface APIDataBlueUserHistory {
    action?: string
    data?: APIDataObject
    createdDate?: Date
}

/** BlueUserHistory Metadata */
export const APIMetaDataBlueUserHistoryPropertyNames = ['action', 'data', 'createdDate']
export const APIMetaDataBlueUserHistoryRelationNames = []

export const APIMetaDataBlueUserHistoryProperties = {
    action: {
        type: 'string'
    },
    data: {
        type: 'APIDataObject'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataBlueUserHistoryRelations = {
}

/** BotCampaignHistory */
export interface APIDataBotCampaignHistory {
    action?: string
    data?: APIDataObject
    createdDate?: Date
}

/** BotCampaignHistory Metadata */
export const APIMetaDataBotCampaignHistoryPropertyNames = ['action', 'data', 'createdDate']
export const APIMetaDataBotCampaignHistoryRelationNames = []

export const APIMetaDataBotCampaignHistoryProperties = {
    action: {
        type: 'string'
    },
    data: {
        type: 'APIDataObject'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataBotCampaignHistoryRelations = {
}

/** BulletinHistory */
export interface APIDataBulletinHistory {
    action?: string
    data?: APIDataObject
    createdDate?: Date
}

/** BulletinHistory Metadata */
export const APIMetaDataBulletinHistoryPropertyNames = ['action', 'data', 'createdDate']
export const APIMetaDataBulletinHistoryRelationNames = []

export const APIMetaDataBulletinHistoryProperties = {
    action: {
        type: 'string'
    },
    data: {
        type: 'APIDataObject'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataBulletinHistoryRelations = {
}

/** DepartmentCRMHistory */
export interface APIDataDepartmentCRMHistory {
    action?: string
    data?: APIDataObject
    createdDate?: Date
}

/** DepartmentCRMHistory Metadata */
export const APIMetaDataDepartmentCRMHistoryPropertyNames = ['action', 'data', 'createdDate']
export const APIMetaDataDepartmentCRMHistoryRelationNames = []

export const APIMetaDataDepartmentCRMHistoryProperties = {
    action: {
        type: 'string'
    },
    data: {
        type: 'APIDataObject'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataDepartmentCRMHistoryRelations = {
}

/** DepartmentHistory */
export interface APIDataDepartmentHistory {
    action?: string
    data?: APIDataObject
    createdDate?: Date
}

/** DepartmentHistory Metadata */
export const APIMetaDataDepartmentHistoryPropertyNames = ['action', 'data', 'createdDate']
export const APIMetaDataDepartmentHistoryRelationNames = []

export const APIMetaDataDepartmentHistoryProperties = {
    action: {
        type: 'string'
    },
    data: {
        type: 'APIDataObject'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataDepartmentHistoryRelations = {
}

/** DocumentSchemaHistory */
export interface APIDataDocumentSchemaHistory {
    action?: string
    data?: APIDataObject
    createdDate?: Date
}

/** DocumentSchemaHistory Metadata */
export const APIMetaDataDocumentSchemaHistoryPropertyNames = ['action', 'data', 'createdDate']
export const APIMetaDataDocumentSchemaHistoryRelationNames = []

export const APIMetaDataDocumentSchemaHistoryProperties = {
    action: {
        type: 'string'
    },
    data: {
        type: 'APIDataObject'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentSchemaHistoryRelations = {
}

/** DocumentHistory */
export interface APIDataDocumentHistory {
    action?: string
    data?: APIDataObject
    createdDate?: Date
}

/** DocumentHistory Metadata */
export const APIMetaDataDocumentHistoryPropertyNames = ['action', 'data', 'createdDate']
export const APIMetaDataDocumentHistoryRelationNames = []

export const APIMetaDataDocumentHistoryProperties = {
    action: {
        type: 'string'
    },
    data: {
        type: 'APIDataObject'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataDocumentHistoryRelations = {
}

/** RoomHistory */
export interface APIDataRoomHistory {
    action?: string
    data?: APIDataObject
    createdDate?: Date
}

/** RoomHistory Metadata */
export const APIMetaDataRoomHistoryPropertyNames = ['action', 'data', 'createdDate']
export const APIMetaDataRoomHistoryRelationNames = []

export const APIMetaDataRoomHistoryProperties = {
    action: {
        type: 'string'
    },
    data: {
        type: 'APIDataObject'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataRoomHistoryRelations = {
}

/** UserCRMHistory */
export interface APIDataUserCRMHistory {
    action?: string
    data?: APIDataObject
    createdDate?: Date
}

/** UserCRMHistory Metadata */
export const APIMetaDataUserCRMHistoryPropertyNames = ['action', 'data', 'createdDate']
export const APIMetaDataUserCRMHistoryRelationNames = []

export const APIMetaDataUserCRMHistoryProperties = {
    action: {
        type: 'string'
    },
    data: {
        type: 'APIDataObject'
    },
    createdDate: {
        type: 'Date'
    }
}

export const APIMetaDataUserCRMHistoryRelations = {
}

export type APIDataAny = any
export type APIDataObject = {[x:string]: any}

export type AgentConfig = { params?: unknown, headers?: unknown, data?: unknown, onUploadProgress?: (event:any) => void }

export interface Agent {
    post: <ResponseType>(url: string, data?: unknown, config?: AgentConfig) => Promise<ResponseType>
    get: <ResponseType>(url: string, config?: AgentConfig) => Promise<ResponseType>
    put: <ResponseType>(url: string, data?: unknown, config?: AgentConfig) => Promise<ResponseType>
    delete: <ResponseType>(url: string, config?: AgentConfig) => Promise<ResponseType>
    del: <ResponseType>(url: string, config?: AgentConfig) => Promise<ResponseType>
}


import { runInAction, makeAutoObservable } from 'mobx'
import api from '../api'
import AppStore from './AppStore'
import AnalyticsStore from './AnalyticsStore'
import UsersStore from './UsersStore'
import DepartmentsStore from './DepartmentsStore'
import Media from './models/Media'
import { container } from '../di'
import { SessionState } from '@evertel/session'
import { AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { CurrentUserPollService } from '@evertel/blue-user'

const USER_DETAILS_POLL_INTERVAL_MS = 30000 // 21 seconds slow poll

// Keeps track of the current user's profile data
class CurrentUserStore {
    isBusy = false
    error = null
    authenticationController: AuthenticationController
    sessionState: SessionState
    currentUserPollService: CurrentUserPollService
    currentEmail = ''

    constructor() {
        this.authenticationController = container.get(AuthenticationController)
        this.sessionState = container.get(SessionState)
        this.currentUserPollService = container.get(CurrentUserPollService)

        makeAutoObservable(this)
    }

    async init() {
        return true
    }

    get user() {
        return UsersStore.currentUser
    }

    get id() {
        return AppStore.userId
    }

    get publicImage() {
        return UsersStore.currentUser.publicImage
    }

    get firstName() {
        return UsersStore.currentUser.firstName
    }

    get lastName() {
        return UsersStore.currentUser.lastName
    }

    // firstName + lastName
    get fullName() {
        return UsersStore.currentUser.fullName
    }

    // First letter of first name, last name
    get fullNameShort() {
        return UsersStore.currentUser.fullNameShort
    }

    get isSearchable() {
        return UsersStore.currentUser.isSearchable
    }

    get phoneNumber() {
        return UsersStore.currentUser.phoneNumber
    }

    get isAdmin() {
        return UsersStore.currentUser.isAdmin
    }

    get isEnabled() {
        return UsersStore.currentUser.isEnabled
    }

    get meta() {
        return UsersStore.currentUser.meta
    }

    get auth0() {
        return UsersStore.currentUser.auth0
    }

    get lastUpdated() {
        return UsersStore.currentUser.lastUpdated
    }

    get departments() {
        return DepartmentsStore.departments.filter(d => this.departmentsAccess.find((d2: any) => d.id === d2.departmentId))
    }

    get guestDepartments() {
        return DepartmentsStore.departments.filter(d => this.departmentsAccess.find((d2: any) => d.id === d2.departmentId && d2.role === 'guest'))
    }

    get departmentsNotGuest() {
        return DepartmentsStore.departments.filter(d => this.departmentsAccess.find((d2: any) => d.id === d2.departmentId && d2.role !== 'guest'))
    }

    get selectedDepartment() {
        return DepartmentsStore.departments.find(d => d.id === AppStore.selectedDepartmentId) || undefined
    }

    get departmentsAccess() {
        return UsersStore.currentUser.departmentsAccess || []
    }

    get departmentsAccessNotGuest() {
        return UsersStore.currentUser.departmentsAccess?.filter((da: any) => da.role !== 'guest')
    }

    // Returns this users department access that matches the department I have selected
    get selectedDepartmentsAccess() {
        if (!UsersStore.currentUser) return false

        return UsersStore.currentUser.departmentsAccess.find((da: any) => da.departmentId === AppStore.selectedDepartmentId) || false
    }

    get guestDepartmentsAccess() {
        return UsersStore.currentUser.guestDepartmentsAccess
    }

    get selectedDepartmentRole() {
        if (!this.selectedDepartmentsAccess) return false

        return this.selectedDepartmentsAccess.role
    }

    // Returns this users primary department, this user is not You
    get primaryDepartmentsAccess() {
        return UsersStore.currentUser.primaryDepartmentsAccess || false
    }

    get isInSelectedDepartment() {
        return UsersStore.currentUser.isInSelectedDepartment || false
    }

    get email() {
        return UsersStore.currentUser.email
    }

    get emailVerified() {
        return UsersStore.currentUser.emailVerified
    }

    get passwordTTL() {
        return UsersStore.currentUser.passwordTTL
    }

    get minimumDepartmentPasswordTTL() {
        // returns shortest password TTL setting from all of user's depts in days
        return UsersStore.currentUser.minimumDepartmentPasswordTTL
    }

    // are they a manager or an executive?
    get canManage() {
        if (!this.selectedDepartmentsAccess) return false

        return this.selectedDepartmentsAccess.role === 'executive' || this.selectedDepartmentsAccess.role === 'management'
    }

    get drawerRoomSort() {
        return UsersStore.currentUser.drawerRoomSort
    }

    get drawerThreadSort() {
        return UsersStore.currentUser.drawerThreadSort
    }

    get drawerDisplay() {
        return UsersStore.currentUser.drawerDisplay
    }

    get hasExecutiveRole() {
        if (!this.selectedDepartmentsAccess) return false

        return this.selectedDepartmentsAccess.role === 'executive'
    }

    get enterprise() {
        return UsersStore.currentUser.auth0 && UsersStore.currentUser.auth0.enterprise
    }

    async update(data: any) {
        if (!data || !UsersStore.currentUser) return

        this.isBusy = true
        this.error = null

        try {
            const userData = await api.BlueUser.update(AppStore.userId, data)

            UsersStore.currentUser.updateFromData(userData)

            console.log('CURRENT USER UPDATED: ', userData)

            //log to analytics
            AnalyticsStore.logEvent({
                category: 'User',
                action: 'edit_profile',
                label: undefined,
                value: undefined,
                nonInteraction: undefined,
                transport: undefined
            })
        } catch (e: any) {
            AppStore.logError({ type: 'API put', message: 'CurrentUserStore.update()', error: e.message || e })

            runInAction(() => {
                this.error = e
            })

            return // don't do anything else
        } finally {
            this.isBusy = false
        }
    }

    async uploadProfilePic(media: any) {
        this.isBusy = true
        this.error = null

        if (!UsersStore.currentUser) return

        const profilePic = await Media.fromLocalMedia(media)

        if (!profilePic) throw new Error('Could not determine media content.')

        try {
            // Upload media
            await profilePic.upload(`${api.baseURL}BlueUsers/${AppStore.userId}/profileImage`)

            if (profilePic.error) throw profilePic.error

            UsersStore.currentUser.updateFromData({
                publicImage: profilePic.url,
                publicMedia: profilePic
            })

            runInAction(() => {
                this.isBusy = false
            })

            //log to analytics
            AnalyticsStore.logEvent({
                category: 'User',
                action: 'edit_profile_image',
                label: undefined,
                value: undefined,
                nonInteraction: undefined,
                transport: undefined
            })
        } catch (e: any) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({ type: 'API post', message: 'CurrentUserStore.ulpoadProfilePic()', error: e.message || e })
            return
        }
    }

    async changePassword(body: any) {
        this.isBusy = true
        this.error = null

        try {
            await this.authenticationController.changePassword(AppStore.userId, body)

            runInAction(() => {
                this.isBusy = false
            })
        } catch (e: any) {
            AppStore.logError({ type: 'API put', message: 'CurrentUserStore.changePassword()', error: e.message || e })

            runInAction(() => {
                this.isBusy = false
                this.error = e
            })
        }
    }

    async leaveDepartment(deptId: any) {
        this.isBusy = true
        this.error = null

        if (!deptId) throw new Error('Department ID required!')

        try {
            const result: any = await api.Department.leaveDepartment(deptId, AppStore.userId)
            // Updates departments access and selected departmentId
            // TODO: maybe directly update DepartmentAccesStore.departmentsAccess array and remove this entry
            if (result.count) await this.currentUserPollService.fetchUserDetails()

            // log to analytics
            AnalyticsStore.logEvent({
                category: 'User',
                action: 'left_department',
                label: undefined,
                value: undefined,
                nonInteraction: undefined,
                transport: undefined
            })
        } catch (e: any) {
            AppStore.logError({ type: 'API put', message: 'CurrentUserStore.leaveDepartment()', error: e.message || e })

            runInAction(() => {
                this.error = e
            })
        }

        runInAction(() => {
            this.isBusy = false
        })
    }

    promptForProfilePic() {
        const profilePopupCallback = function () {
            // NavigationStore.navigate('EditProfile')
            // Popup.hide()
        }

        // give the UI a second to breathe before popping up
        setTimeout(() => {
            // Popup.show({
            //     title: 'Put a face to your name!',
            //     textBody: 'Upload a photo of yourself so your colleagues know its you!',
            //     buttonText: 'Upload photo',
            //     buttonColor: 'secondary',
            //     headerImage: require('../assets/images/UserPhotoReminder/UserPhotoReminder.png'),
            //     callback: profilePopupCallback,
            //     cancelText: 'Not Now'
            // })
        }, 1000)
    }
}

export default new CurrentUserStore() 

import React  from 'react'
import TextReadWidget from './TextWidget'

const DropdownReadWidget = ({
    value,
    options,
    ...otherProps
}) => {
    
    const option = options && options.find((option) => option.value === value)

    value = typeof value !== 'undefined' && option?.label

    return (
        <TextReadWidget
            value={value}
            {...otherProps}
        />
    )
}

export default DropdownReadWidget

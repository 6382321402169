import React, { useEffect, useState } from 'react'
import { AnalyticsStore, AppStore, LoginStore, NavigationStore } from '../../../stores'
import { observer } from 'mobx-react'
import { Toaster, Icon } from '../../../components'
import { Button, Card, CardBody, Col, Row, Input, InfoBox, CardFooter } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { Link } from 'react-router-dom'


const ConfirmAuth0TFA = observer(() => {

    const authenticationController = useService(AuthenticationController, [])

    const [state, setStateRaw] = useState({
        confirmIsLoading: false,
        resendIsLoading: false,
        code: '',
        error: null,
        showMoreWays: false,
        overrideAuthEmailIsLoading: false,
        overrideAuthPhoneIsLoading: false,
        activeAuthenticator: 'email'
    })

    const setState = (newState) => {
        setStateRaw(prevState => ({
            ...prevState,
            ...(typeof newState === 'function' ? newState(prevState) : newState)
        }))
    }

    useState(() => {
        AnalyticsStore.logPageView('/two-factor')
    })

    useEffect(() => {
        if (!AppStore.appLoading) {
            if (!LoginStore.email) {
                Toaster.show('error', 'Missing email address.')
                NavigationStore.navigate('/login')
                return
            } else {
                (async () => {
                    await authenticationController.getAuthenticatorsByEmail(LoginStore.email)
                    setState({
                        activeAuthenticator: authenticationController.hasSMSAuthenticator ? 'sms' : 'email'
                    })
                }
                )()
            }
        }
    }, [AppStore.appLoading, LoginStore.email])

    const onChangeCode = (e) => {
        setState({ code: e.target.value })
    }

    const confirmTFA = async (e) => {
        e.preventDefault()

        if (!state.code) {
            setState({ error: 'Please enter your security code' })
            return
        }

        setState({ confirmIsLoading: true })

        await LoginStore.getTFAToken(state.code)

        setState({
            confirmIsLoading: false,
            error: LoginStore.error || null
        })
    }

    const resend2fa = async (useEmail = true, usePhone = false) => {
        if (!LoginStore.mfaToken) {
            Toaster.show('error', 'Missing MFA Token, please login again.')
            setState({ error: 'Resend failed [Missing MFA Token]' })
            NavigationStore.navigate('/login')
            return
        }

        setState({
            resendIsLoading: true,
            activeAuthenticator: usePhone ? 'sms' : 'email',
            overrideAuthEmailIsLoading: useEmail,
            overrideAuthPhoneIsLoading: usePhone
        })

        await LoginStore.challengeUser(LoginStore.mfaToken, {
            email: LoginStore.email,
            useEmail,
            usePhone
        })

        setState({
            resendIsLoading: false,
            overrideAuthEmailIsLoading: false,
            overrideAuthPhoneIsLoading: false,
            error: LoginStore.error?.message || null
        })

        if (!LoginStore.error) {
            Toaster.show('success', 'A new security code has been sent!')
        }
    }

    const toggleMoreWays = () => {
        setState(prevState => ({ showMoreWays: !prevState.showMoreWays }))
    }

    const disableResendButtons = state.confirmIsLoading || state.overrideAuthEmailIsLoading || state.overrideAuthPhoneIsLoading
    const email = !authenticationController.isLoading ? authenticationController.authenticators?.find(a => a.type === 'email')?.name : null
    const phoneNumber = !authenticationController.isLoading ? authenticationController.authenticators?.find(a => a.type === 'sms')?.name || null : null

    return (
        <div className="app fadeIn animated registration" style={{ height: '100vh', overflow: 'auto' }}>
            <Row align='center' valign='center' className="p-4" style={{ minHeight: '100%' }}>
                <Card className="" style={{ maxWidth: 700 }}>
                    <CardBody className="p-4">
                        {/* HEADER */}
                        <Row className="mb-3">
                            <Col align="center text-center">
                                <h1>Two-Step Verification</h1>
                                <h3>
                                    It looks like you haven't logged in on this device or browser before.
                                    For your protection, we've sent you a security code to verify it's you.
                                </h3>
                            </Col>
                        </Row>
                        {(!!state.error) &&
                            <InfoBox color="danger">
                                {state.error}
                            </InfoBox>
                        }
                        <div className="text-center text-success">
                            <Icon
                                name={(state.activeAuthenticator === 'email') ? 'envelope' : 'mobile-android-alt'}
                                className="mr-2"
                            />
                            {(state.activeAuthenticator === 'email') ? email : phoneNumber}
                        </div>
                        <form onSubmit={confirmTFA}>
                            <div className="mt-2">
                                <Input
                                    type="text"
                                    value={state.code}
                                    onChange={(e) => onChangeCode(e)}
                                    placeholder="******"
                                    className="text-center"
                                    maxLength={6}
                                    style={{
                                        letterSpacing: 10,
                                        fontSize: 'x-large',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </div>
                            <Row>
                                <Col align="center">
                                    <Button
                                        color="success"
                                        loading={state.confirmIsLoading}
                                        disabled={state.confirmIsLoading}
                                        type="submit"
                                        size="l"
                                        className="mt-4"
                                        style={{ width: '50%' }}>
                                        <strong>Verify</strong>
                                    </Button>
                                    <Button
                                        color="link"
                                        spinnerColor='#808080'
                                        loading={state.resendIsLoading}
                                        disabled={disableResendButtons}
                                        type="button"
                                        className="mt-3"
                                        onClick={() => resend2fa(state.activeAuthenticator === 'email', state.activeAuthenticator === 'sms')}>
                                        Resend security code
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                        {(!!phoneNumber) &&
                            <>
                                <Row className="mt-3">
                                    <Col align="center">
                                        <Button
                                            color="link"
                                            size="sm"
                                            className="mt-4"
                                            onClick={toggleMoreWays}>
                                            More ways to verify
                                        </Button>
                                    </Col>
                                </Row>
                                {(state.showMoreWays) &&
                                    <>
                                        <Row>
                                            <Col align="center">
                                                <Button
                                                    color="link"
                                                    spinnerColor='#808080'
                                                    loading={state.overrideAuthEmailIsLoading}
                                                    disabled={disableResendButtons}
                                                    type="button"
                                                    size="sm"
                                                    onClick={() => resend2fa(true, false)}>
                                                    <Icon
                                                        name="envelope"
                                                        className="mr-2"
                                                    />
                                                    Email code to: <strong>{email}</strong>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col align="center">
                                                <Button
                                                    color="link"
                                                    spinnerColor='#808080'
                                                    loading={state.overrideAuthPhoneIsLoading}
                                                    disabled={disableResendButtons}
                                                    type="button"
                                                    size="sm"
                                                    onClick={() => resend2fa(false, true)}>
                                                    <Icon
                                                        name="mobile-android-alt"
                                                        className="mr-2"
                                                    />
                                                    Text code to: <strong>{phoneNumber}</strong>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </>
                        }
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col align="center">
                                <div><small>Have an issue? We can help</small></div>
                                <a href="mailto:support@getevertel.com" className="d-block btn btn-link">
                                    <small>support@getevertel.com</small>
                                </a>
                                <Link
                                    className="mt-4"
                                    to="/login"
                                >
                                    <small>Logout</small>
                                </Link>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Row>
        </div>
    )
})

export default ConfirmAuth0TFA
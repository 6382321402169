import { ContainerModule } from '@evertel/di'

import {
    DocumentDepartmentPickerController,
    DocumentMediaController,
    SchemaFieldMediaController,
    SchemaMediaController,
    DocumentSchemaPickerController,
    DocumentMediaReadWidgetController,
    DocumentMediaWriteWidgetController,
    SchemaBuilderController,
    SchemaCategoriesController
} from './controller'

import {
    SchemaCategoryUtils,
    SchemaMediaUtils
} from './utils'

const DI_WEB_DOCUMENT_MODULE = new ContainerModule((bind) => {
    // Controller
    bind(DocumentDepartmentPickerController).toSelf()
    bind(DocumentMediaController).toSelf()
    bind(DocumentSchemaPickerController).toSelf()
    bind(DocumentMediaReadWidgetController).toSelf()
    bind(DocumentMediaWriteWidgetController).toSelf()
    bind(SchemaMediaController).toSelf()
    bind(SchemaFieldMediaController).toSelf()
    bind(SchemaCategoryUtils).toSelf()
    bind(SchemaMediaUtils).toSelf()
    bind(SchemaBuilderController).toSelf()
    bind(SchemaCategoriesController).toSelf()
})

export {
    DI_WEB_DOCUMENT_MODULE
}

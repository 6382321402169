import { observable, runInAction, action, makeObservable } from 'mobx'
import api from '../api'
import _ from 'lodash'
import { DepartmentsStore, AppStore } from '.'

// Keeps track of our login state and progress
class DepartmentsSearchStore {
    resultsIds = []
    results = []
    isBusy = false
    error = null

    constructor() {
        makeObservable(this, {
            resultsIds: observable,
            results: observable,
            isBusy: observable,
            error: observable,
            clearSearch: action,
            addDepartmentToStore: action('Add department to DepartmentsStore')
        })

        
    }

    // Search contacts api then updates this.resultsIds observable, debounced
    search = _.debounce(async (searchString) => {
        runInAction(() => {
            this.isBusy = true
            this.error = null
        })
        let departments = []
        try {
            departments = await api.Department.getDepartmentsBySearch({
                where: {
                    name: { like: `%${searchString}%`}
                },
                order: 'name'
            })

            runInAction(() => {
                this.isBusy = false
                this.results = departments
                this.resultsIds = departments.map(d => d.id)
            })
        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({type: 'API get', message: 'DepartmentsSearchStore.search()', error: e.message || e})
        }
    }, 50, {leading: true})

    async clearSearch() {
        this.resultsIds.clear()
        this.results.clear()
        this.isBusy = false
        this.error = null
        this.search.cancel()
    }

    addDepartmentToStore = (department) => {
        DepartmentsStore.updateFromData(department)
    }
}


// Creates a single instance of this store
export default new DepartmentsSearchStore()


import { ContainerModule } from '@evertel/di'
import { ChildDepartmentStore } from './ChildDepartmentStore'
import { DocumentSchemaStore } from './DocumentSchemaStore'
import { RoomStore } from './RoomStore'
import { RoomMessagesStore, roomMessagesStoreInstance } from './RoomMessagesStore'
import { ThreadMessagesStore } from './ThreadMessagesStore'
import { ThreadStore } from './ThreadStore'
import { UserSettingsStore } from './UserSettingsStore'
import { StoreManager } from './StoreManager'
// diContainer.bind(Api).toConstantValue(api)

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(DocumentSchemaStore).toSelf().inSingletonScope()
        bind(RoomStore).toSelf().inSingletonScope()
        bind(RoomMessagesStore).toConstantValue(roomMessagesStoreInstance)
        bind(ThreadMessagesStore).toSelf().inSingletonScope()
        bind(ThreadStore).toSelf().inSingletonScope()
        bind(UserSettingsStore).toSelf().inSingletonScope()
        bind(ChildDepartmentStore).toSelf().inSingletonScope()
        bind(StoreManager).toSelf().inSingletonScope()
    })
]

export { DI_MODULES }

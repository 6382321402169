import React from 'react'
import { observer } from 'mobx-react'
// evertel
import { Button, Col, Icon, Row, Text, ToggleSwitch } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { useUI } from '@evertel/web/ui'
import { CurrentUserController } from '@evertel/blue-user'
//import PublicRoomSettingsModal from './PublicRoomSettingsModal'


/* *****************************************************
    NOTE: this component is used in multiple locations.
    Make sure any edits are tested in all uses
***************************************************** */


interface RoomSettingsFormProps {
    roomId?: number,
    options: Record<string, any>,
    isSearchable: boolean,
    isArchived?: boolean,
    hideOptions?: string[],
    onChange: (option: string, value: boolean) => void,
    onOpenICModal?: () => void,
    className?: string
}

const RoomSettingsForm: React.FC<RoomSettingsFormProps> = observer(({
    roomId,
    options,
    isSearchable,
    isArchived,
    hideOptions,
    onChange,
    onOpenICModal,
    ...otherProps
}) => {

    const currentUserController = useService(CurrentUserController, [])
    const role = currentUserController.selectedDepartmentRole

    if (role === 'employee'){
        return null
    }

    const { isConfirmed } = useUI()

    const isExecutive = role === 'executive'

    const confirmAgencyWide = async (checked) => {
        if (checked) {
            // if turning on 'agency wide', warn them...
            const confirmed = await isConfirmed({
                title: 'Please Note!',
                message: 'Turning on this setting will add ALL verified members in your entire agency to this room. Are you sure you want to do this?'
            })

            if (confirmed) {
                onChange('autoAddDepartmentMembers', true)
            }
        } else {
            onChange('autoAddDepartmentMembers', false)
        }
    }

    const onBeforeArchive = async (checked: boolean) => {
        if (checked) {
            // if turning on 'archive', warn them...
            const confirmed = await isConfirmed({
                title: 'Are you sure you want to archive this room?',
                message: 'Archived rooms are no longer accessible by members. It can be unarchived by room managers or executives at any time.'
            })

            if (confirmed) {
                onChange('isArchived', checked)
            }
        } else {
            onChange('isArchived', checked)
        }
    }

    if (!options) return null

    return (
        <Col {...otherProps}>
            {/* PRIVATE */}
            {!hideOptions?.includes('isPrivate') &&
                <Row className="mt-2 pb-2 border-bottom border-light">
                    <Col
                        xs={1}
                        align="center"
                        valign="top"
                        className="p-0">
                        <Icon
                            name="lock"
                            color="muted"
                            className="mr-2"
                        />
                    </Col>
                    <Col
                        xs={9}
                        className="pl-0">
                        <Text bold>
                            Make Private:
                            <Icon
                                name="question-circle"
                                color="info"
                                className="ml-1"
                                data-tooltip="Only invited users can access this room."
                                data-tooltip-pos="top"
                            />
                        </Text>
                    </Col>
                    <Col align="right">
                        <ToggleSwitch
                            name="departmentMembersCanJoinAsMember"
                            id={`departmentMembersCanJoinAsMember${roomId ?? ''}`}
                            onChange={(checked) => onChange('departmentMembersCanJoinAsMember', !checked)}
                            checked={!options.departmentMembersCanJoinAsMember}
                            disabled={isSearchable || options.autoAddDepartmentMembers || isArchived}
                            data-tooltip={(isSearchable) ? 'Incident Command rooms cannot be marked private' : (options.autoAddDepartmentMembers) ? 'Agency-Wide rooms cannot be marked private' : null}
                            data-tooltip-pos="left"
                        />
                    </Col>
                </Row>
            }
            {/* READ ONLY */}
            {!hideOptions?.includes('roomMembersCanMessage') &&
                <Row className="mt-2 pb-2 border-bottom border-light">
                    <Col
                        xs={1}
                        align="center"
                        valign="top"
                        className="p-0">
                        <Icon
                            name="eye"
                            color="muted"
                            className="mr-2"
                        />
                    </Col>
                    <Col
                        xs={9}
                        className="pl-0">
                        <Text bold>
                            Make Read Only:
                            <Icon
                                name="question-circle"
                                color="info"
                                className="ml-1"
                                data-tooltip="Members can read but not post messages (only room managers can post)."
                                data-tooltip-pos="top"
                            />
                        </Text>
                        {/* <Text
                            color="muted"
                            size={(small) ? 'smaller' : 'small'}>
                            Members can read but not post messages (only managers can post).
                        </Text> */}
                    </Col>
                    <Col align="right">
                        <ToggleSwitch
                            name="roomMembersCanMessage"
                            id={`roomMembersCanMessage${roomId ?? ''}`}
                            onChange={(checked) => onChange('roomMembersCanMessage', !checked)}
                            checked={!options.roomMembersCanMessage}
                            disabled={isArchived}
                        />
                    </Col>
                </Row>
            }
            {/* AGENCY WIDE */}
            {(!hideOptions?.includes('autoAddDepartmentMembers')) &&
                <Row className="mt-2 pb-2 border-bottom border-light">
                    <Col
                        xs={1}
                        align="center"
                        valign="top"
                        className="p-0">
                        <Icon
                            name="users"
                            color="muted"
                            className="mr-2"
                        />
                    </Col>
                    <Col
                        xs={9}
                        className="pl-0">
                        <Text bold>
                            Make Agency-Wide (Broadcast):
                            <Icon
                                name="question-circle"
                                color="info"
                                className="ml-1"
                                data-tooltip="Automatically add ALL users (excluding guests) in the entire agency to this room."
                                data-tooltip-pos="top-left"
                            />
                        </Text>
                        {/* <Text
                            color="muted"
                            size={(small) ? 'smaller' : 'small'}>
                            Automatically add ALL users (excluding guests) in the entire agency to this room.
                        </Text> */}
                    </Col>
                    <Col align="right">
                        <ToggleSwitch
                            name="autoAddDepartmentMembers"
                            id={`autoAddDepartmentMembers${roomId ?? ''}`}
                            onChange={(checked) => confirmAgencyWide(checked)}
                            checked={options.autoAddDepartmentMembers}
                            disabled={!options?.departmentMembersCanJoinAsMember || isSearchable || !isExecutive || isArchived}
                            data-tooltip={(!isExecutive) ? 'Only executives can edit this setting' :
                                (isSearchable) ? 'Incident Command rooms cannot also be Agency-Wide rooms' :
                                    (!options?.departmentMembersCanJoinAsMember) ? 'Private rooms cannot also be Agency-Wide rooms' : null}
                            data-tooltip-pos="left"
                        />
                    </Col>
                </Row>
            }
            {/* OPEN/IC ROOMS */}
            {!hideOptions?.includes('isSearchable') &&
                <Row className="mt-2 pb-2 border-bottom border-light">
                    <Col
                        xs={1}
                        align="center"
                        valign="top"
                        className="p-0">
                        <Icon
                            name="bolt"
                            color="muted"
                            className="mr-2"
                        />
                    </Col>
                    <Col
                        xs={9}
                        className="pl-0">
                        <Text bold>
                            Make Incident Command:
                            <Icon
                                name="question-circle"
                                color="info"
                                className="ml-1"
                                data-tooltip="IC rooms are discoverable by people in other agencies within the region you set. Great for true interoperability during crisis events."
                                data-tooltip-pos="top"
                            />
                        </Text>
                        {/* <Text
                            color="muted"
                            size={(small) ? 'smaller' : 'small'}>
                            IC rooms are discoverable by people in other agencies within the region you set. Great for true interoperability during crisis events.
                        </Text> */}
                        {(isSearchable && isExecutive && !hideOptions?.includes('accessCriteria') && !isArchived) &&
                            <Row>
                                <Button
                                    className="my-1"
                                    color="info"
                                    size="sm"
                                    onClick={onOpenICModal}>
                                    Set access criteria
                                </Button>
                            </Row>
                        }
                    </Col>
                    <Col align="right">
                        <ToggleSwitch
                            name="isSearchable"
                            id={`isSearchable${roomId ?? ''}`}
                            onChange={(checked) => onChange('isSearchable', checked)}
                            checked={isSearchable}
                            disabled={!options?.departmentMembersCanJoinAsMember || options.autoAddDepartmentMembers || !isExecutive || isArchived}
                            data-tooltip={(!isExecutive) ? 'Only executives can edit this setting' :
                                (options.autoAddDepartmentMembers) ? 'Agency-Wide rooms cannot also be Incident Command rooms' :
                                    (!options?.departmentMembersCanJoinAsMember) ? 'Private rooms cannot also be Incident Command rooms' : null}
                            data-tooltip-pos="left"
                        />
                    </Col>
                </Row>
            }
            {/* SCREENSHOT DETECTION */}
            {!hideOptions?.includes('detectScreenshots') &&
                <Row className="mt-2 pb-2 border-bottom border-light">
                    <Col
                        xs={1}
                        align="center"
                        valign="top"
                        className="p-0">
                        <Icon
                            name="camera-slash"
                            color="muted"
                            className="mr-2"
                        />
                    </Col>
                    <Col
                        xs={9}
                        className="pl-0">
                        <Text bold>
                            Detect/Disable Screenshots on Mobile:
                            <Icon
                                name="question-circle"
                                color="info"
                                className="ml-1"
                                data-tooltip="When enabled, screenshots will be prevented (Android) or a notice sent to the room when taken (iOS)"
                                data-tooltip-pos="top-left"
                            />
                        </Text>
                        {/* <Text
                            color="muted"
                            size={(small) ? 'smaller' : 'small'}>
                            When enabled, screenshots will be prevented (Android) or a notice sent to the room when taken (iOS)
                        </Text> */}
                    </Col>
                    <Col align="right">
                        <ToggleSwitch
                            name="detectScreenshots"
                            id={`detectScreenshots${roomId ?? ''}`}
                            onChange={(checked) => onChange('detectScreenshots', checked)}
                            checked={(options.detectScreenshots === undefined) ? true : options.detectScreenshots}
                            disabled={isArchived}
                        />
                    </Col>
                </Row>
            }
            {/* ARCHIVE */}
            {!hideOptions?.includes('isArchived') &&
                <Row className="mt-2 pb-2 border-bottom border-light">
                    <Col
                        xs={1}
                        align="center"
                        valign="top"
                        className="p-0">
                        <Icon
                            name="archive"
                            color="danger"
                            className="mr-2"
                        />
                    </Col>
                    <Col
                        xs={9}
                        className="pl-0">
                        <Text
                            color="danger"
                            bold>
                            Archive for Everyone:
                            <Icon
                                name="question-circle"
                                color="info"
                                className="ml-1"
                                data-tooltip="If you no longer need this room to be active, archive it. It can be unarchived later."
                                data-tooltip-pos="top"
                            />
                        </Text>
                        {/* <Text
                            color="muted"
                            size={(small) ? 'smaller' : 'small'}>
                            If you no longer need this room to be active, archive it. It can be unarchived later.
                        </Text> */}
                    </Col>
                    <Col align="right">
                        <ToggleSwitch
                            name="isArchived"
                            id="isArchived"
                            onChange={(checked) => onBeforeArchive(checked)}
                            checked={isArchived}
                        />
                    </Col>
                </Row>
            }
        </Col>
    )
})

export { RoomSettingsForm }

import { ContainerModule } from '@evertel/di'
import { ImageController, FileController, VideoController, AudioController, MediaController } from './elements/media/controller'
import { ToastService } from './elements'

const DI_WEB_UI_MODULE = new ContainerModule((bind) => {
    bind(MediaController).toSelf()
    bind(ImageController).toSelf()
    bind(FileController).toSelf()
    bind(VideoController).toSelf()
    bind(AudioController).toSelf()
    bind(ToastService).toSelf().inSingletonScope()
})

export { DI_WEB_UI_MODULE }

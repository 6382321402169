import { observable, action, runInAction, computed, makeObservable, makeAutoObservable } from 'mobx'
import _ from 'lodash'
import api from '../../api'
import AppStore from '../AppStore'
import { Media } from '../models'
import { container } from '../../di'
import { SessionState } from '@evertel/session' 

// Keeps track of our login state and progress
class BotMessagesStoreAdmin {
    isBusy = false
    error = null
    departments = []
    media = []
    mediaCount = 0
    botCampaigns = []
    botCampaignsCount = 0
    campaignUsers = []

    constructor() {
        this.session = container.get(SessionState)

        makeAutoObservable(this)

    }

    updateFromData(data) {
        if (!data) { throw new Error('data can not be undefined') }

        // if data is an array then use it, otherwise its an object, put it in an array, the array objects should be unique by id
        const dataCampaigns = _.uniqBy(data.length !== undefined ? data : [data], 'id')

        for (const dc of dataCampaigns) {
            const existingIdx = this.botCampaigns.findIndex(c => c.id === dc.id)

            if (existingIdx === -1) {
                this.botCampaigns.push(dc)
            } else {
                this.botCampaigns[existingIdx] = dc
            }
        }
    }

    async getCampaignById(id) {
        if (!id) return

        const campaignId = +id

        const campaign = this.botCampaigns.find(c => c.id === campaignId)

        // if we have a matching campaign, return it, otherwise, fetch it
        if (campaign) {
            return campaign
        } else {
            try {
                const fetchedCampaign = await api.Bot.getBotCampaign(campaignId)

                runInAction(() => {
                    this.updateFromData(fetchedCampaign)
                })

                return this.botCampaigns.find(c => c.id === campaignId)
            } catch (e) {
                runInAction(() => {
                    this.error = e
                })

                AppStore.logError({type: 'API get', message: 'BotMessagesStoreAdmin.getCampaignById()', error: e.message || e, id})
            }
        }
    }

    async sendBotMessage(body) {
        this.error = null
        this.isBusy = true

        try {
            const response = await api.Bot.postMessage(body)

            runInAction(() => {
                this.isBusy = false
            })

            return response

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({
                type: 'API get',
                message: 'BotMessagesStoreAdmin.sendBotMessage()',
                error: e.message || e
            })
        }
    }

    async getBotMediaByIds(ids) { // ids should be an array
        if (!ids || !ids.length) return

        // if not an array make it one
        if (!Array.isArray(ids)) ids = [ids]

        const localMedia = []
        const missingMediaIds = []

        // loop through ids and decipher which media we already have locally
        for (const id of ids) {
            const localFound = this.media.find(m => m.id === id)
            if (localFound) {
                localMedia.push(localFound)
            } else {
                missingMediaIds.push(id)
            }
        }

        // if we don't have any missing media, return local media now
        if (!missingMediaIds.length) return localMedia.map(r => new Media(r))

        // if we have missing media, fetch it
        try {
            const remoteMedia = await api.Bot.getMedia({
                where: { id: { inq: missingMediaIds}}
            })

            return [...localMedia, ...remoteMedia].map(r => new Media(r))
        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'BotMessageStoreAdmin.getBotMediaById()',
                error: e.message || e, ids
            })
        }
    }

    get botMedia() {
        if (!this.media.length) return []
        return this.media
    }

    searchMedia = _.debounce(async (filter) => {
        runInAction(() => {
            this.isBusy = true
            this.error = null
        })
        let media = []

        try {
            // Get media via search
            media = await api.Routes.Bot.getMedia(filter)

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({
                type: 'API get',
                message: 'BotMessageStoreAdmin.searchMedia()',
                error: e.message || e,
                filter
            })
        }

        // save results
        runInAction(() => {
            this.isBusy = false
            this.media = media
        })
    }, 250)

    async fetchMediaCount(where) {
        let mediaCount = 0

        try {
            mediaCount = (await api.Routes.Bot.getMediaCount(where)).count

            runInAction(() => {
                this.mediaCount = mediaCount
            })

        } catch (e) {
            AppStore.logError({type: 'API get', message: 'BotMessageStoreAdmin.getMediaCount()', error: e.message || e, where})
        }
    }

    async clearSearchMedia() {
        this.media.clear()
        this.isBusy = false
        this.error = null
        this.searchMedia.cancel()
    }

    getBotCampaigns = _.debounce(async (filter) => {
        this.error = null
        this.isBusy = true

        try {
            const campaigns = await api.Bot.getBotCampaigns(filter)

            runInAction(() => {
                this.isBusy = false
                this.botCampaigns = campaigns
            })

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({type: 'API get', message: 'BotMessagesStoreAdmin.getBotCampaigns()', error: e.message || e})
        }
    }, 50, {leading: true})

    async getBotCampaignsCount(where) {
        this.error = null
        this.isBusy = true

        try {
            const count = (await api.Bot.getBotCampaignsCount(where)).count

            runInAction(() => {
                this.isBusy = false
                this.botCampaignsCount = count
            })

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({type: 'API get', message: 'BotMessagesStoreAdmin.getBotCampaignsCount()', error: e.message || e})
        }
    }

    async createCampaign(config) {
        this.error = null
        this.isBusy = true

        // add where filters
        const data = this.filterTypeToWhereFilter(config.data)
        config.data = data

        //console.log('config', JSON.stringify(config, null, 2))

        try {
            const campaign = await api.Bot.createBotCampaign(config)

            runInAction(() => {
                this.isBusy = false
                this.updateFromData(campaign)
            })

            return campaign

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({type: 'API post', message: 'BotMessagesStoreAdmin.createCampaign()', error: e.message || e, config})
        }
    }

    async updateCampaign(id, config) {
        if (!id) {
            this.error = 'Missing campaign ID'
            return
        }

        this.error = null
        this.isBusy = true

        // add where filters
        const data = this.filterTypeToWhereFilter(config.data)
        config.data = data

        try {
            const campaign = await api.Bot.updateBotCampaign(id, config)

            runInAction(() => {
                this.isBusy = false
                this.updateFromData(campaign)
            })

            return campaign

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({type: 'API put', message: 'BotMessagesStoreAdmin.updateCampaign()', error: e.message || e, id, config})
        }
    }

    async fetchCampaignUsersPreview(id, filter) {
        if (!id) {
            this.error = 'Missing campaign ID'
            return
        }

        this.error = null
        this.isBusy = true

        try {
            const users = await api.Bot.getBotCampaignPreview(id, filter)

            runInAction(() => {
                this.isBusy = false
                this.campaignUsers = [...this.campaignUsers, ...users]
            })

        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({type: 'API get', message: 'BotMessagesStoreAdmin.getCampaignUsersPreview()', error: e.message || e, id, filter})
        }
    }

    async fetchCampaignUsersPreviewCount(id, where = {}) {
        if (!id) {
            this.error = 'Missing campaign ID'
            return
        }

        try {
            const count = (await api.Bot.getBotCampaignPreviewUserCount(id, where)).count

            return count

        } catch (e) {
            AppStore.logError({type: 'API get', message: 'BotMessagesStoreAdmin.fetchCampaignUsersPreviewCount()', error: e.message || e, id, where})
        }
    }

    clearCampaignUsers() {
        this.campaignUsers = []
    }

    async runCampaign(id) {
        if (!id) {
            this.error = 'Missing campaign ID'
            return
        }

        try {
            await api.Bot.runBotCampaign(id)

        } catch (e) {
            AppStore.logError({type: 'API get', message: 'BotMessagesStoreAdmin.runCampaign()', error: e.message || e, id})
        }
    }

    // HELPER: builds where filter for campaigns
    filterTypeToWhereFilter = (data) => {
        //console.log(data.filters)
        const userCRMWhere = { and: [] }
        const departmentCRMWhere = { and: [] }
        const departmentWhere = { and: [] }
        const departmentUserThroughWhere = { and: [] }
        const blueUserWhere = { and: [] }
        // Has any filterType for DepartmentCRM
        const hasDepartmentCRMFilter = data.filters.some(f => f.filterType === 'departmentCRM')
        // Has any filterType for UserCRM
        const hasUserCRMFilter = data.filters.some(f => f.filterType === 'userCRM')
        // Has any filterType for DepartmentCRM or Department
        const hasDepartmentFilter = hasDepartmentCRMFilter || data.filters.some(f => ['agencyType', 'states', 'country', 'agencySize', 'agencies'].includes(f.filterType))
        // Has any filterType for Department or DepartmentUserThrough
        const hasDepartmentUserThroughFilter = hasDepartmentFilter || data.filters.some(f => ['roles'].includes(f.filterType))

        // build department crm 'where' clause, departmentCRMWhere ---------------------------------------------------
        const departmentCRMTypeFilters = data.filters.find(f => f.filterType === 'departmentCRM')
        if (departmentCRMTypeFilters) {
            const crmTypeFilters = departmentCRMTypeFilters.reduce((acc, val) => acc.concat(val), [])
            for (const value of crmTypeFilters) {
                const op = value.operator || 'eq'
                departmentCRMWhere.and.push({
                    key: value.key,
                    valueNumber: value.valueNumber && { [op]: value.valueNumber },
                    valueString: value.valueString && { [op]: value.valueString },
                    valueDate: value.valueDate && { [op]: value.valueDate }
                })
            }
        }

        // build user crm 'where' clause, userCRMWhere --------------------------------------------------------------
        const userCRMTypeFilters = data.filters.find(f => f.filterType === 'userCRM')
        if (userCRMTypeFilters) {
            for (const value of userCRMTypeFilters.value.flat()) {
                const op = value.operator || 'eq'
                userCRMWhere.and.push({
                    key: value.key,
                    valueNumber: value.valueNumber && { [op]: value.valueNumber },
                    valueString: value.valueString && { [op]: value.valueString },
                    valueDate: value.valueDate && { [op]: value.valueDate }
                })
            }
        }
        // build department 'where' clause, departmentWhere ---------------------------------------------------------
        if (hasDepartmentCRMFilter) {
            departmentWhere.and.push({
                id: {
                    inq: '#DepartmentCRM_departmentIds'
                }
            })
        }

        // agency types
        const agencyTypeFilter = data.filters.find(f => f.filterType === 'agencyType')
        if (agencyTypeFilter) {
            const types = agencyTypeFilter.value.flat()
            const typeOperator = agencyTypeFilter.operator || 'inq'
            departmentWhere.and.push({
                type: {
                    [typeOperator]: types.map(v => v.value)
                }
            })
        }

        // agency states
        const agencyStateFilter = data.filters.find(f => f.filterType === 'states')
        if (agencyStateFilter) {
            const states = agencyStateFilter.value.flat()
            const stateOperator = agencyStateFilter.operator || 'inq'
            departmentWhere.and.push({
                state: {
                    [stateOperator]: states.map(v => v.value)
                }
            })
        }

        // agency country
        const agencyCountryFilter = data.filters.find(f => f.filterType === 'country')
        if (agencyCountryFilter) {
            const countries = agencyCountryFilter.value.flat()
            const countryOperator = agencyCountryFilter.operator || 'inq'
            departmentWhere.and.push({
                country: {
                    [countryOperator]: countries.map(v => v.value)
                }
            })
        }

        // agency size
        const agencySizeFilter = data.filters.find(f => f.filterType === 'agencySize')
        if (agencySizeFilter) {
            const sizes = agencySizeFilter.value.flat()
            const or = []
            for (const size of sizes) {
                or.push(
                    {
                        and: [
                            { employees: { gt: size.minEmployees } },
                            { employees: { lt: size.maxEmployees } }
                        ]
                    }
                )
            }
            departmentWhere.and.push({ or })
        }

        // only return departmentIds with at least one user
        departmentWhere.and.push({
            verifiedMemberCapacity: { gt: 0 }
        })

        // always filter out isDemo
        departmentWhere.and.push({
            isDemo: false
        })

        // build depaDepartmentUserThrough 'where' clause, departmentUserThroughWhere ---------------------
        const agenciesFilter = data.filters.find(f => f.filterType === 'agencies')
        if (agenciesFilter) {
            const op = agenciesFilter.operator || 'inq'
            departmentWhere.and.push({
                id: { [op]: agenciesFilter.value.map(a => a.id) }
            })
        }

        // roles
        const rolesFilter = data.filters.find(f => f.filterType === 'roles')
        if (rolesFilter) {
            const roles = rolesFilter.value.flat().map(r => r.value)
            const rolesOp = (rolesFilter.operator === 'nin') ? 'nlike' : 'like'
            departmentUserThroughWhere.and.push({
                or: roles.map(r => ({ roles: { [rolesOp]: `%${r}%` } }))
            })
        }

        // always filter out unverified !?
        departmentUserThroughWhere.and.push({
            isVerified: true
        })

        if (hasDepartmentFilter) {
            departmentUserThroughWhere.and.push({
                departmentId: { inq: '#Department_ids' }
            })
        }

        // build BlueUser 'where' clause, blueUserWhere ---------------------------------------------------
        if (hasDepartmentUserThroughFilter) {
            blueUserWhere.and.push({
                id: {
                    inq: '#DepartmentUserThrough_blueUserIds'
                }
            })
        }

        // add UserCRM results
        if (hasUserCRMFilter) {
            blueUserWhere.and.push({
                id: {
                    inq: '#UserCRM_blueUserIds'
                }
            })
        }

        // included/exclude people
        const peopleFilter = data.filters.find(f => f.filterType === 'people')
        if (peopleFilter) {
            const userIds = peopleFilter.value.flat().map(u => u.value)
            const peopleOperator = peopleFilter.operator || 'inq'
            blueUserWhere.and.push({
                id: {
                    [peopleOperator]: userIds
                }
            })
        }

        const result = {
            ...data,
            userCRMWhere: hasUserCRMFilter && userCRMWhere,
            departmentCRMWhere: hasDepartmentCRMFilter && departmentCRMWhere,
            departmentWhere: hasDepartmentFilter && departmentWhere,
            departmentUserThroughWhere: hasDepartmentUserThroughFilter && departmentUserThroughWhere,
            blueUserWhere
        }

        return result
    }
}

export default new BotMessagesStoreAdmin()

import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataMedia } from '@evertel/types'
import { getFileExt, getMediaType } from '@evertel/utils'
import { APIDataMediaToNormalizedMedia, NormalizedMedia } from '@evertel/media'

class DocumentMediaController {
    media: NormalizedMedia
    width? = 150
    height? = 100

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = async (documentId: number, mediaId: number, width?: number, height?: number) => {
        try {
            const media = await this.api.Routes.Document.getMediaById(documentId, mediaId)
            const normalizedMedia = APIDataMediaToNormalizedMedia(media)

            runInAction(() => {
                this.media = normalizedMedia
                this.width = width
                this.height = height
            })
        } catch (error) {
            console.error('DocumentMediaController:', error.message)
        }

    }

    get url() {
        return this.media?.url
    }

    get fileName() {
        return this.media?.fileName
    }

    get type() {
        return getMediaType(this.media)
    }

    get fileExtension() {
        return getFileExt(this.media)
    }
}

decorate(injectable(), DocumentMediaController)
decorate(inject(Api), DocumentMediaController, 0)

export {
    DocumentMediaController
}

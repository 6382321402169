/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { HTMLAttributes, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Col, Icon, InfoBox, Row, useUI } from '@evertel/web/ui'
import { InviteByEmailForm } from './InviteByEmailForm'
import { useService } from '@evertel/di'
import { DepartmentInviteLink } from './DepartmentInviteLink'
import { SendInvitesBody, SendInvitesController } from '@evertel/invites'

interface DepartmentInviteFormProps extends HTMLAttributes<HTMLDivElement|HTMLSpanElement> {
    departmentId: number,
    onClose: () => void,
    currentUserId?: number,
    inviteBody?: SendInvitesBody,
    invitedRooms?: {label: string, value: number}[],
}

const DepartmentInviteForm: React.FC<DepartmentInviteFormProps> = observer(({
    departmentId,
    onClose
}) => {

    const sendInvitesController = useService(SendInvitesController, [])

    const { addToast } = useUI()

    const [isLoading, setIsLoading] = useState(false)
    const [inviteBody, setInviteBody] = useState<SendInvitesBody>()
    const [error, setError] = useState<string>()
    const [openInviteLink, setOpenInviteLink] = useState(false)

    const send = async () => {
        if (!inviteBody?.to?.length || !inviteBody?.roles?.length) {
            setError('Please make sure you have at least one invite to send and have set their role')
            return
        }

        setIsLoading(true)

        try {
            // @ts-ignore
            await sendInvitesController.sendDepartmentInvite(departmentId, inviteBody as SendInvitesBody)

            addToast({
                color: 'success',
                message: 'Your invites have been sent!'
            })

            onClose()

        } catch (error: any) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }

        setIsLoading(false)
    }

    return (
        <Col>
            <InfoBox color="info">
                Agency members can access rooms, EverDocs, and your full agency directory. This is best for agency employees.
            </InfoBox>
            {(error) &&
                <InfoBox
                    color="danger"
                    className="fadeIn">
                    {error}
                </InfoBox>
            }
            <InviteByEmailForm
                departmentId={departmentId}
                className="mt-4"
                inviteType="department"
                showRole
                onChange={setInviteBody}
            />
            <Row className="mt-4">
                <Button
                    color="link"
                    onClick={() => setOpenInviteLink(!openInviteLink)}>
                    <Icon
                        name="link"
                        className="mr-2"
                    />
                    {(openInviteLink) ? 'close' : 'Use agency invite link'}
                </Button>
            </Row>
            {(openInviteLink) &&
                <Row className="mt-2">
                    <DepartmentInviteLink/>
                </Row>
            }
            <Row
                align="center"
                className="mt-4">
                <Button
                    color="success"
                    loading={isLoading}
                    onClick={send}>
                    Send Invites
                </Button>
            </Row>
        </Col>
    )
})

export { DepartmentInviteForm }

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { Virtuoso } from 'react-virtuoso'
// evertel
import { Spinner, InfoBox, Row, Modal, ModalBody, ModalHeader, useUI, Text } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { MessageReadsController } from '@evertel/message'
import { UserTileRawData } from '@evertel/web/user'
interface ReadByModalProps {
    visible: boolean,
    messageId?: number,
    modelType: 'room'|'thread',
    onClose?: () => void,
    onCloseDone?: () => void
}


const ReadByModal: React.FC<ReadByModalProps> = observer(({
    visible,
    messageId,
    modelType,
    onClose,
    onCloseDone
}) => {

    const messageReadsController = useService(MessageReadsController, [])
    const { addToast } = useUI()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // only initialize if model is visible
        if (visible) {
            setIsLoading(true)

            try {
                messageReadsController.init(messageId, modelType)

            } catch (error) {
                console.error(error.message)

                addToast({
                    color: 'danger',
                    message: error.message
                })
            }

            setIsLoading(false)
        }

    }, [visible, messageId, messageReadsController, modelType, addToast])

    const onEndReached = () => {
        if (visible) {
            messageReadsController.fetchNextPage()
        }
    }

    const readsCount = messageReadsController.readsCount
    const title = `${readsCount} ${(readsCount > 1) ? 'people have' : 'person has'} read this message...`

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCloseDone={onCloseDone}>
            <ModalHeader
                title={(isLoading) ? 'Loading reads...' : title}
                closeButton
            />
            <ModalBody style={{
                height: (readsCount > 10) ? '50vh' : readsCount * 65,
                minHeight: 130
            }}>
                <Virtuoso
                    endReached={onEndReached}
                    data={messageReadsController.reads}
                    totalCount={readsCount}
                    overscan={300}
                    itemContent={User}
                    components={{
                        Footer: () => {
                            return (
                                <Footer
                                    showSpinner={isLoading}
                                />
                            )
                        },
                        EmptyPlaceholder
                    }}
                />
            </ModalBody>
        </Modal>
    )
})

const User = (
    index,
    data
) => {

    if (!data) return null

    const read = data
    const user = read?.readBy

    if (!user) {
        return (
            <Row className="border-bottom border-light pb-2 mb-2">
                <Text
                    color="muted"
                    italic>
                    Could not load user
                </Text>
            </Row>
        )
    }

    return (
        <UserTileRawData
            user={user}
            className="border-bottom border-light pb-2 mb-2"
            rightColComponent={
                <Text
                    color="muted"
                    size="smaller"
                    className='date-cell'
                >
                    Read:<br/>
                    {moment(read.createdDate).calendar(null, {
                        sameDay: '[Today at] H:mm',
                        lastDay: '[Yesterday at] H:mm',
                        sameElse: 'MM/DD/YYYY @ H:mm'
                    })}
                </Text>
            }
        />
    )
}

const Footer = ({ showSpinner }) => {
    if (!showSpinner) return null
    return (
        <i className="text-muted">
            <Spinner /> Loading...
        </i>
    )
}

const EmptyPlaceholder = () => {
    return (
        <InfoBox color="info">
            No one has read to this message yet
        </InfoBox>
    )
}

export { ReadByModal }

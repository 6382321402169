import { runInAction, makeAutoObservable, autorun } from 'mobx'
// evertel
import api from '../api'
import AnalyticsStore from './AnalyticsStore'
import CurrentUserStore from './CurrentUserStore'
import NavigationStore from './NavigationStore'
import { SessionState } from '@evertel/session'

import { LoadingIndicator } from '../components'
import { setNavigationStore } from '../di/diInterop'
import { container } from '../di'

import * as Electron from '../utilities/electronInterface'
import { LogoutService } from '@evertel/logout'

//what and why is this?
setNavigationStore(NavigationStore)

class AppStore {
    hasValidAuthToken = false
    openCount = 0 // used to track number of opens. Used to prompt user every so-many opens

    appLoading = true
    showAlertBar = [] // array of header alert bars to show --> 'push-permission-unset' | 'push-permission-denied' | 'offline' | 'demo-agency' | 'version-update' | 'session-timeout'


    constructor() {
        makeAutoObservable(this)
        this.sessionState = container.get(SessionState)
        this.logoutService = container.get(LogoutService)
    }

    get userId() {
        return this.sessionState.currentUserId
    }
    get selectedDepartmentId() {
        return this.sessionState.selectedDepartmentId
    }

    async init() {
        // show loading overlay
        LoadingIndicator.show('app-loading', 'full-overlay')

        // initialize stores that don't require authentication
        //TODO: eliminate this entirely
        AnalyticsStore.init()

        try {
            //Validate AuthToken
            await this.testAuthToken()
            if (!this.hasValidAuthToken) {
                if (!api.isProduction) console.log('AppStore init invalid auth token')
                await this.logout()
            }

        } catch (e) {
            this.logError({ message: 'App init Error', error: e.message, stack: e.stack })
            await this.logout()
        }

        runInAction(() => {
            this.appLoading = false
        })

        // hide loading overlay (give extra time for UI to update)
        setTimeout(() => {
            LoadingIndicator.hide('app-loading', 'full-overlay')
        }, 300)
    }

    async testAuthToken() {
        if (!this.userId) return false
        
        try {
            // test this token
            await api.Routes.BlueUser.getTestAccess()
            runInAction(() => {
                this.hasValidAuthToken = true
            })
            return true
        } catch (e) {
            // if server says it's invalid then we don't have auth
            // we only know the cookie has cleared if the server sends a 401
            if (e.status === 401) {
                runInAction(() => {
                    this.hasValidAuthToken = false
                })
                if (!api.isProduction) console.warn('testAuthToken SERVER failed 401')
                return false
            }

            if (!api.isProduction) console.log('testAuthToken PASS Unknown status')

            runInAction(() => {
                this.hasValidAuthToken = true
            })
            return true
        }
    }

    async logout() {
        runInAction(() => {
            if (!api.isProduction) console.log('logout() clear important values')
            this.hasValidAuthToken = false
        })
        this.logoutService.logout()
        Electron.loggedOut()
    }

    async getDevelopmentInfo() {
        return JSON.stringify({
            CurrentUser: {
                fullName: CurrentUserStore.fullName,
                id: CurrentUserStore.id
            }
        }, null, 2)
    }

    async logError(errorObj) {
        if (!errorObj) return
        if (errorObj.error === 'Authorization Required') return
        if (errorObj.error === 'Network Error') return

        // only console log on DEV
        if (!api.isProduction) console.log('*****ERROR*****', errorObj)

        // don't log to API if on localhost
        if (window.location.hostname === 'localhost') return

        const userInfo = await this.getDevelopmentInfo()
        const error = { errorObj, userInfo }
        api.Routes.App.postLogError(JSON.stringify(error))
    }
}

export default new AppStore()

import React, { forwardRef } from 'react'
import { Icon } from './Icon'


export type InfoButtonProps = {
    color?: string,
    text: string
}


const InfoButton = forwardRef<SVGSVGElement, InfoButtonProps>(({
    color = 'info',
    text,
    ...otherProps
}, ref) => {

    return (
        <span {...otherProps}>
            <Icon
                ref={ref}
                name="circle-info"
                type="solid"
                color={color}
            />
        </span>
    )
})

export { InfoButton }

import React, { forwardRef, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { testImage, stringToHslColor, gradientBackground } from '@evertel/utils'
import { useService } from '@evertel/di'
import { DepartmentController } from '@evertel/department'

interface DepartmentAvatarProps {
    departmentId: number,
    className?: string,
    width?: number,
    height?: number,
    style?: React.CSSProperties,
    onClick?: (departmentId: number) => void
}

const DepartmentAvatar = observer(forwardRef<HTMLImageElement, DepartmentAvatarProps>(({
    departmentId,
    width = 40,
    height = 40,
    onClick,
    className,
    ...otherProps
}, ref) => {

    const departmentController = useService(DepartmentController, [departmentId])
    
    const [isImageValid, setIsImageValid] = useState(true)

    useEffect(() => {
        departmentController.init(departmentId)
    }, [departmentId])

    useEffect(() => {
        // make sure image exists on server, if not, we render initials block

        if (departmentController.department?.publicImage) {
            testImage(departmentController.department?.publicImage).then(result => {
                if (result === 'error') {
                    setIsImageValid(false)
                } else {
                    setIsImageValid(true)
                }
            }).catch(error => {
                setIsImageValid(false)
            })
        }
    }, [departmentController.department?.publicImage])

    const _classNames = classNames(
        'profile-pic text-uppercase',
        {
            'cursor-pointer': onClick,
            'no-avatar': !(departmentController.department?.publicImage && isImageValid)
        },
        className
    )

    if (departmentController.department?.publicImage && isImageValid) {
        // valid image
        return (
            <img
                ref={ref}
                src={departmentController.department?.publicImage}
                className={_classNames}
                title={departmentController.department?.name}
                alt={departmentController.department?.name}
                width={width}
                height={height}
                {...otherProps}
                {...onClick && { onClick: () => onClick(departmentId) }}
            />
        )
    } else {
        // invalid image
        const { style, ...rest } = otherProps
        const shortName = departmentController.department?.shortName || getAbbreviatedName(departmentController.department.name)

        const divider = Math.max(shortName?.length - 3, 3)

        const fontSize = Math.max(10, Math.floor(width / divider))

        return (
            <div
                ref={ref}
                className={_classNames}
                title={departmentController.department?.name}
                style={{
                    ...style,
                    background: gradientBackground(departmentController.department?.name),
                    // backgroundColor: stringToHslColor(departmentController.department?.name),
                    width,
                    height,
                    fontSize
                }}
                {...rest}
                {...onClick && { onClick: () => onClick(departmentId) }}
            >
                {shortName}
            </div>
        )
    }
}))

function getAbbreviatedName(name) {
    if (!name) return ''
    const nameWithoutParenthesis = name.split('(')[0].trim()
    return nameWithoutParenthesis
        .split(' ') // Split the name into words
        .map(word => word[0]) // Take the first letter of each word
        .join('') // Join the letters to form the abbreviation
        .toUpperCase() // Optionally convert to uppercase
}

export { DepartmentAvatar }

export const roles = [
    {
        label: 'Executive',
        value: 'executive'
    },
    {
        label: 'Management',
        value: 'management'
    },
    {
        label: 'Employee',
        value: 'employee'
    },
    {
        label: 'Guest',
        value: 'guest'
    }
]

import React, { lazy } from 'react'
import { EvertelLayout } from './containers'
import { Security } from '@evertel/web/security'
import { DepartmentUsersList, ChildDepartments } from '@evertel/web/department'

// Lazy load components
const Room = lazy(() => import('./views/Rooms/Room'))
const Thread = lazy(() => import('./views/Threads/Thread'))
const DocumentSearchPage = lazy(() => import('./views/Documents/SearchPage'))
const EverDocCreatePage = lazy(() => import('./views/Documents/CreatePage'))
const EverDocEditPage = lazy(() => import('./views/Documents/EditPage'))
const EverDocSchemaBuilderPage = lazy(() => import('./views/Documents/SchemaBuilderPage'))
const EverDocSchemaCategoriesPage = lazy(() => import('./views/Documents/ManageSchemaCategoriesPage'))
const EverDocTemplatesPage = lazy(() => import('./views/Documents/SchemaTemplatesPage'))

// ACCOUNT SETTINGS
const Profile = lazy(() => import('./views/Account/Profile/Profile'))
const Preferences = lazy(() => import('./views/Account/Profile/Preferences'))
const Notifications = lazy(() => import('./views/Account/Profile/Notifications'))
const MyDepartments = lazy(() => import('./views/Account/Profile/MyDepartments'))

// MANAGEMENT
const User = lazy(() => import('./views/Users/User'))
const Rooms = lazy(() => import('./views/Rooms/ManageRooms'))
const Audits = lazy(() => import('./views/Audits/Audits'))
const MessageAudit = lazy(() => import('./views/Audits/AuditForm'))
const DepartmentHistory = lazy(() => import('./views/History/DepartmentHistory'))
const Reports = lazy(() => import('./views/Reports/Reports'))
const DepartmentSettings = lazy(() => import('./views/Account/Department/Settings'))

// ADMIN (EVERTEL)
const Dashboard = lazy(() => import('./views/Admin/Dashboard/Dashboard'))
const Departments = lazy(() => import('./views/Admin/Departments/Departments'))
const Department = lazy(() => import('./views/Admin/Departments/Department'))
const BotMessageForm = lazy(() => import('./views/Admin/Messaging/BotMessageForm'))
const BotCampaignForm = lazy(() => import('./views/Admin/Messaging/BotCampaignForm'))
const BotMessages = lazy(() => import('./views/Admin/Messaging/Campaigns'))
const UsersAdmin = lazy(() => import('./views/Admin/Users/Users'))
const UserAdmin = lazy(() => import('./views/Admin/Users/User'))
const VerifiedDomains = lazy(() => import('./views/Admin/VerfiedDomains/VerifiedDomains'))
const LandingPage = lazy(() => import('./views/Landing/Landing'))

const routes = [
    { path: '/', exact: true, name: 'Home', component: EvertelLayout },

    { path: '/landing', exact: true, name: 'Landing', component: LandingPage },

    { path: '/room/:id', exact: true, name: 'Room', component: Room },
    { path: '/thread/:id', exact: true, name: 'Message Thread', component: Thread },

    { path: '/everdocs', exact: true, name: 'EverDoc Search', component: DocumentSearchPage },
    { path: '/everdocs/add/:id', exact: true, name: 'Create an EverDoc', component: EverDocCreatePage },
    { path: '/everdocs/schema/add/:target?', exact: true, name: 'Create an EverDoc Type', component: EverDocSchemaBuilderPage },
    { path: '/everdocs/schema/edit/:id/:target?', exact: true, name: 'Edit an EverDoc Type', component: EverDocSchemaBuilderPage },
    { path: '/everdocs/schema/categories', exact: true, name: 'Manage EverDoc Template Categories', component: EverDocSchemaCategoriesPage },
    { path: '/everdocs/schema/templates/:target?', exact: true, name: 'Browse EverDoc Templates', component: EverDocTemplatesPage },
    { path: '/everdocs/:id', exact: true, name: 'Edit EverDoc', component: EverDocEditPage },

    { path: '/account/profile/:id?', exact: true, name: 'My Profile', component: Profile },
    { path: '/account/preferences', exact: true, name: 'Preferences', component: Preferences },
    { path: '/account/security', exact: true, name: 'Security', component: Security },
    { path: '/account/notifications', exact: true, name: 'Notifications', component: Notifications },
    { path: '/account/departments/:id', exact: true, name: 'My Agencies', component: MyDepartments },

    { path: '/manage/users', exact: true, name: 'Users', component: DepartmentUsersList },
    { path: ['/manage/agency/:departmentId/user/:userId', '/manage/user/:userId'], exact: true, name: 'User Details', component: User },
    { path: '/manage/rooms', exact: true, name: 'Manage Rooms', component: Rooms },
    { path: '/manage/audits', exact: true, name: 'Audits', component: Audits },
    { path: '/manage/audits/messages', exact: true, name: 'Message Audits', component: MessageAudit },
    { path: '/manage/history', exact: true, name: 'Agency History', component: DepartmentHistory },
    { path: '/manage/reports/:id', exact: true, name: 'Agency Reports', component: Reports },
    { path: '/manage/agency/settings/:id', exact: true, name: 'Agency Settings', component: DepartmentSettings },
    { path: '/manage/linked/', exact: true, name: 'Linked Agencies', component: ChildDepartments },

    { path: '/admin/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/admin/agencies/', exact: true, name: 'Agencies', component: Departments },
    { path: '/admin/agency/:id', exact: true, name: 'Agency Detail', component: Department },
    { path: '/admin/users/', exact: true, name: 'Users', component: UsersAdmin },
    { path: '/admin/user/:id', exact: true, name: 'User Details', component: UserAdmin },
    { path: '/admin/messaging', exact: true, name: 'Bot Campaigns', component: BotMessages },
    { path: '/admin/messaging/messageForm/:id?', exact: true, name: 'Send Bot Messages', component: BotMessageForm },
    { path: '/admin/messaging/campaignForm/:id?', exact: true, name: 'Send Bot Campaign', component: BotCampaignForm },
    { path: '/admin/verified-domains', exact: true, name: 'Verfied Domains', component: VerifiedDomains }
]

export default routes
import React, { forwardRef, HTMLAttributes, ReactElement, useEffect, useState, useCallback } from 'react'
import classNames from 'classnames'
import { Text, Col, Row, Badge, Icon } from '@evertel/web/ui'
import { ProfilePic } from './ProfilePic'
import { APIDataBlueUser } from '@evertel/types'

// ********************************************************************
// NOTE: this component should only be used where you are NOT expecting
// the user to be stored in the usersStore or come through MobX,
// like in user searches.
// For user's expected to be in the UsersStore use the UserTile component
// ********************************************************************

interface UserTileProps extends HTMLAttributes<HTMLDivElement> {
    user: APIDataBlueUser,
    imageWidth?: number,
    isGuest?: boolean,
    isManager?: boolean,
    subComponent?: ReactElement,
    rightColComponent?: ReactElement,
    className?: string
}

const UserTileRawData = forwardRef<HTMLDivElement, UserTileProps>(({
    user = {},
    imageWidth = 30,
    isGuest,
    isManager,
    subComponent,
    rightColComponent,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames(
        'user-tile',
        'p-0',
        className
    )

    const displayName = useCallback(() => {
        if (!user?.id) return "Unknown"
        if (user.firstName || user.lastName) {
            return user.firstName + " " + user.lastName
        } else {
            return user.email.split('@')[0]
        }
    }, [user])


    return (
        <Row
            ref={ref}
            className={_className}
            {...otherProps}>
            <Col
                className="pr-3 flex-grow-0">
                <ProfilePic
                    userId={user.id}
                    imageUrl={user?.publicImage}
                    width={imageWidth}
                    height={imageWidth}
                    firstName={user?.firstName || '?'}
                    lastName={user?.lastName || '?'}
                />
            </Col>
            <Col valign='start' style={{minWidth:0}}>
                <Text
                    bold
                    className="text-truncate">
                    {displayName()}
                    {(isManager) &&
                        <Badge
                            color="secondary"
                            shape="rounded-pill"
                            className="ml-1 py-0">
                            Manager
                        </Badge>
                    }
                    {(isGuest) &&
                        <Badge
                            color="warning"
                            shape="rounded-pill"
                            className="ml-1 py-0">
                            Guest
                        </Badge>
                    }
                </Text>
                {(subComponent) && React.cloneElement( subComponent )}
            </Col>
            {(rightColComponent) &&
                <Col
                    align="right"
                    className="p-0">
                    {React.cloneElement( rightColComponent )}
                </Col>
            }
        </Row>
    )
})

export { UserTileRawData }

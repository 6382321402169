import { FC } from 'react'
import classNames from 'classnames'
//evertel
import {Icon, Button } from '../'
import { downloadFile } from '@evertel/web/utils'

interface DownloadButtonProps {
    fileUrl: string,
    fileName: string,
    allowDownload: boolean,
}

export const DownloadButton: FC<DownloadButtonProps> = ({
    fileUrl,
    fileName,
    allowDownload,
    ...otherProps
}) => {

    if (!allowDownload) return null

    return (
        <Button
            onClick={() => downloadFile(fileUrl, fileName)}
            size="sm"
            {...otherProps}
            className="download-btn"
            color="ghost-muted"
        >
            <Icon
                name="cloud-arrow-down"
            />
        </Button>
    )
}
// pluralizes a string
export function pluralize(count, noun, suffix = 's') {
    return (count !== 1) ? noun + suffix : noun
}

// copy's string to clipbloard
export function copyToClipboard(str) {
    if (!navigator.clipboard) {
        copyToClipboardFallback(str)
        return
    }
    navigator.clipboard.writeText(str).then(() => {
        // do nothing
    }, (err) => {
        console.error('Could not copy text: ', err)
    })
}

// fallback if clipboard not supported in browser
export function copyToClipboardFallback(str) {
    // fallback for browser compatibility
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()

    try {
        document.execCommand('copy')
    } catch (err) {
        console.error('Could not copy text: ', err)
    }

    document.body.removeChild(el)
}

// truncae string and add ellipsis
export function truncate(str, n){
    return typeof str === 'string' && (str.length > n) ? str.substr(0, n-1) + '...' : str
}
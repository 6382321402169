import { makeAutoObservable, runInAction } from 'mobx'
import { decorate, inject, injectable } from 'inversify'
import { SessionState } from '@evertel/session'
import { Api } from '@evertel/api'
import { botMessageActionsNavigationOptions } from '@evertel/constants'
import { DisplayThread, RoomStore, ThreadMessagesStore, ThreadStore } from '@evertel/stores'
import { BlueUserStore } from '@evertel/blue-user'


class MessageActionController {
    isBusy = false
    error = ''

    constructor(
        private session: SessionState,
        private api: Api,
        private threadMessagesStore: ThreadMessagesStore,
        private threadStore: ThreadStore,
        private userStore: BlueUserStore,
        private roomStore: RoomStore
    ) {
        makeAutoObservable(this)
    }

    getActionLocation = (cta) => {
        if (!cta || !cta.params) return null
        const actionLocation = botMessageActionsNavigationOptions.find(ba => ba.value === cta.params.screen)
        if (!actionLocation.location) return false

        switch (cta.params.screen) {
            case 'profile':
            case 'myAgencies':
            case 'editEmail':
            case 'settings':
            case 'resetPassword':
                return { location: actionLocation.location + this.session.currentUserId, params: actionLocation.params }
            default:
                return { location: actionLocation.location, params: actionLocation.params }
        }
    }

    async threadMessageAction(message, actionEvent) {
        if (!message || !actionEvent) return

        this.isBusy = false
        this.error = ''
        
        try {
            const result = await this.api.Routes.ThreadMessage.postAction(message.id, {
                action: actionEvent,
                value: undefined
            })
            
            runInAction(() => {
                if (result) {
                    this.threadMessagesStore.update((result as any).threadMessage)
                }
                this.isBusy = false
            })
        } catch (error) {
            runInAction(() => {
                this.error = error.message
                this.isBusy = false
            })
        }
    }

    async onClickAction(cta, message, history) {
        this.isBusy = true
        
        switch (cta.action) {
            case 'url': {
                window.location.href = cta.params?.url
                break
            }
            case 'navigate': {
                const option = this.getActionLocation(cta)
                if (cta.value === 'thread' && cta.params?.threadId) {
                    const thread = (await this.api.Routes.Thread.getById(cta.params?.threadId, {
                        include: {
                            relation: 'users',
                            scope: {
                                fields: ['id', 'firstName', 'lastName', 'publicImage', 'publicMedia', 'isBot', 'isAdmin']
                            }
                        }
                    })) as DisplayThread
            
                    runInAction(() => {
                        thread.userIds = thread.users?.map((u: any) => u.id)
                        this.threadStore.update(thread)
                        this.userStore.update(thread.users)
                    })

                    history.push('/thread/'+cta.params.threadId)
                } else if (cta.value === 'room' && cta.params?.roomId) {
                    const room = await this.api.Routes.Room.getById(cta.params?.roomId)

                    runInAction(() => {
                        this.roomStore.update(room)
                    })

                    history.push('/room/'+cta.params.roomId)
                } else if (option) {
                    history.push(option?.location)
                }
                break
            }
            case 'threadMessageAction':
                if (!message) return
                // API call to threadMessages/id/action0
                await this.threadMessageAction(message, cta.params?.action)
                break
            default:
                break
        }
        this.isBusy = false
    }

    get actionError(): string {
        return this.error
    }
}

decorate(injectable(), MessageActionController)
decorate(inject(SessionState), MessageActionController, 0)
decorate(inject(Api), MessageActionController, 1)
decorate(inject(ThreadMessagesStore), MessageActionController, 2)
decorate(inject(ThreadStore), MessageActionController, 3)
decorate(inject(BlueUserStore), MessageActionController, 4)
decorate(inject(RoomStore), MessageActionController, 5)

export { MessageActionController }

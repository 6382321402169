import React, { createContext, forwardRef, HTMLAttributes, useState, useRef, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Media } from '../media'
import { Row, Col } from '../../layout'
import { PhotoGalleryActionBar } from './PhotoGalleryActionBar'
import { downloadFile } from '@evertel/web/utils'
import { createPortal } from 'react-dom'
import { NormalizedMedia } from '@evertel/media'

interface PhotoGalleryProps extends HTMLAttributes<HTMLDivElement> {
    visible?: boolean
}

interface ContextProps extends PhotoGalleryProps {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    setMedia: (media: NormalizedMedia[], selectedMedia?: number) => void
    media?: NormalizedMedia[]
}

export const PhotoGalleryContext = createContext({} as ContextProps)

const PhotoGallery = observer(forwardRef<HTMLDivElement, PhotoGalleryProps>(({
    children
}, ref) => {

    const lightboxRef = useRef<HTMLDivElement>(null)

    const mediaRef = useRef(null)
    const galleryIndexRef = useRef(null)

    const [visible, setVisible] = useState(false)
    const [media, _setMedia] = useState<NormalizedMedia[]>([])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scale, setScale] = useState(1)

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    //handles mouse zoom scroll keyboard zooming and close
    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            event.preventDefault()
            if (event.deltaY < 0) {
                zoomIn()
            } else {
                zoomOut()
            }
        }

        const currentLightboxRef = lightboxRef.current
        if (visible && currentLightboxRef) {
            currentLightboxRef.focus() // Focus when visible, important for keyboard navigation
            currentLightboxRef.addEventListener('wheel', handleWheel)
        }

        return () => {
            if (currentLightboxRef) {
                currentLightboxRef.removeEventListener('wheel', handleWheel)
            }
        }
    }, [visible])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case '+':
            case '=':
                zoomIn()
                break
            case '-':
            case '_':
                zoomOut()
                break
            case 'ArrowLeft':
                switchSelected('prev')
                break
            case 'ArrowRight':
                switchSelected('next')
                break
            case 'Escape':
                event.stopPropagation()
                setVisible(false)
                break
        }
    }

    //adjusts css transform:scale for zoom changes
    useEffect(() => {
        if (mediaRef.current) {
            if (mediaRef.current.getInternalPlayer) {
                mediaRef.current.getInternalPlayer().style.transform = `scale(${scale})`
            } else {
                mediaRef.current.style.transform = `scale(${scale})`
            }
        }
    }, [scale])

    //resets zoom on open/close
    useEffect(() => {
        reset()
    }, [visible])

    // dynamically recalculate size on window size change
    // disabled now since css %'s seem to be working ok enough
    // useEffect(() => {
    //     const handleResize = () => {
    //         setWindowSize({
    //             width: window.innerWidth,
    //             height: window.innerHeight
    //         })
    //     }
    
    //     window.addEventListener('resize', handleResize)
    
    //     return () => {
    //         window.removeEventListener('resize', handleResize)
    //     }
    // }, [])

    // const calculateItemDimension = useCallback(() => {
    //     const { width, height } = windowSize
    //     const itemWidth = width * 0.7
    //     const itemHeight = height * 0.8
    //     return { width: itemWidth, height: itemHeight }
    // }, [windowSize])

    /**
     * This will only display images and video if those are in the list of provided media.
     * @param media 
     * @param mediaId 
     */
    const setMedia = (media: NormalizedMedia[], mediaId?: number) => {
        const ivMedia = media.filter(
            (m) => m.contentType === 'image' || m.contentType === 'video'
        )
        const usefulMedia = (ivMedia.length) ? ivMedia : media

        _setMedia(usefulMedia)

        const newIndex = mediaId ? usefulMedia.findIndex((m) => m.id === mediaId) : 0
        setSelectedIndex(newIndex !== -1 ? newIndex : 0)
    }

    const contextValues = {
        media,
        setMedia,
        visible,
        setVisible
    }

    const zoomIn = useCallback(() => {
        setScale(prevScale => {
            let delta = 0
            if (prevScale < 2.6) {
                delta = 0.2
            }
            return prevScale + delta
        })
    }, []) 

    const zoomOut = useCallback(() => {
        setScale(prevScale => {
            let delta = 0
            if (prevScale > 0.4) {
                delta = -0.2
            }
            return prevScale + delta
        })
    }, [])
      
    const reset = () => {
        setScale(1)
    }

    const download = () => {
        const file = media[selectedIndex]

        if (file.url?.includes('blob') || file.contentType !== 'image') {
            downloadFile(file.url, file.fileName)
        } else {
            // we want to be downloading the original full sized image when someone needs to download
            downloadFile(`${file.url}?ver=original`, file.fileName)
        }
    }

    const switchSelected = (direction: 'next' | 'prev' | 'specific', mediaId?: number) => {
        if (media.length <= 1) return //no switching if there's only 1 item

        const currentIndex = selectedIndex
      
        let newIndex
        if (direction === 'next') {
            newIndex = (currentIndex + 1) % media.length
        } else if (direction === 'prev') {
            newIndex = (currentIndex - 1 + media.length) % media.length
        } else if (direction === 'specific' && mediaId !== undefined) {
            newIndex = media.findIndex((m) => m.id === mediaId)
        }
      
        if (newIndex !== undefined) {
            setSelectedIndex(newIndex)
            const buttons = galleryIndexRef.current.querySelectorAll('button')
            buttons[newIndex].focus()
        }
        reset()
    }

    const selected = media[selectedIndex]

    const gallery = () => {
        if (visible) {
            return (
                <div
                    ref={lightboxRef}
                    className="lightbox-wrapper"
                    onKeyDown={handleKeyDown}
                    tabIndex={-1}
                >
                    <div
                        ref={ref}
                        className={classNames(
                            'lightbox-backdrop px-5 py-3',
                            'fadeIn'
                        )}
                        id="lightboxBackdrop"
                        style={{ animationDuration: '0.3s' }}
                    >
                        <Col className="lightbox-body flex-grow-1" valign="top">
                            <PhotoGalleryActionBar
                                media={selected as NormalizedMedia}
                                onClose={() => setVisible(false)}
                                onZoomIn={zoomIn}
                                onZoomOut={zoomOut}
                                onReset={reset}
                                onDownload={download}
                            />
                            <Col
                                valign={selected?.contentType === 'video' ? 'top' : 'center'}
                                align="center"
                                className="flex-grow-1 h-100">
                                <Row
                                    align="center"
                                    valign="center"
                                    className="lightbox-selected w-100"
                                    style={{ 
                                        height: selected?.contentType === 'video' ? 'calc(100% - 115px)' : '100%'
                                    }}
                                >
                                    {(selected) &&
                                        <Media
                                            // this key is essential or it won't change when selected is changed
                                            key={selected.id}
                                            ref={mediaRef}
                                            media={selected as NormalizedMedia}
                                            width={window.innerWidth * 0.7}
                                            height={window.innerHeight * 0.8}
                                            {...(selected.contentType === 'video' && { maxHeight: '100%' })}
                                            imageProps={{ resizeMode: 'contain' }}
                                            className={`bg-transparent ${selected.contentType === 'video' ? 'h-100' : ''}`}
                                            style={{textAlign: 'center'}}
                                        />
                                    }
                                </Row>
                                {(media?.length > 1) &&
                                    <Row
                                        align="center"
                                        className="lightbox-thumbs flex-wrap"
                                        ref={galleryIndexRef}
                                    >
                                        {media.map((m, index) =>
                                            <div
                                                data-tooltip={m?.fileName}
                                                data-tooltip-pos="top"
                                            >
                                                <button onClick={() => switchSelected('specific', m.id as number)}>
                                                    <Media
                                                        key={m.id}
                                                        media={m}
                                                        width={100}
                                                        height={75}
                                                        rounded={true}
                                                        imageProps={{ resizeMode: 'cover', flexibility: 'fixed' }}
                                                        videoProps={{showThumbnail: true}}
                                                        className={`p-0 ${index === selectedIndex ? 'selected' : ''}`}
                                                    />
                                                </button>
                                            </div>
                                        )}
                                    </Row>
                                }
                            </Col>
                        </Col>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <PhotoGalleryContext.Provider value={contextValues}>
            {createPortal(gallery(), document.body)}
            {children}
        </PhotoGalleryContext.Provider>
    )
}))

export { PhotoGallery }

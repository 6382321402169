/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { DragDropContext, Droppable, Draggable, DragUpdate } from 'react-beautiful-dnd'
import { Button, Icon, Row, Col, Input, Image } from '@evertel/web/ui'
import { reorder } from '@evertel/utils'
import { SchemaMediaController } from '../../../controller'

export type Option = { label: string, value: string, image?: number|File }

interface OptionsProps {
    onChange?: (options: Option[]) => void
    options?: Option[]
    isCheckbox?: boolean
    mediaController: SchemaMediaController
}

const OptionsBuilder: React.FC<OptionsProps> = observer(({
    onChange,
    options,
    isCheckbox,
    mediaController,
    ...otherProps
}) => {

    const [_options, setOptions] = useState<Option[]>([{ label: '', value: '' }])

    useEffect(() => {
        setOptions(options || [{ label: '', value: '' }])
    }, [options])

    const _onChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        const optionsCopy = [..._options]

        optionsCopy[index] = {
            ...optionsCopy[index],
            [e.target.name]: e.target.value
        }
        setOptions(optionsCopy)
        if (onChange) onChange(optionsCopy)
    }

    const _onBlur = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        // auto-set value if not set already on blur
        const optionsCopy = [..._options]

        // if not the label field or the value field is already set, do nothing
        if (e.target.name !== 'label' || optionsCopy[index].value?.length) return

        const optionValue = e.target.value?.replace(/\s+/g, '-').toLowerCase()

        optionsCopy[index] = {
            ...optionsCopy[index],
            value: optionValue
        }
        
        setOptions(optionsCopy)
        if (onChange) onChange(optionsCopy)
    }

    const onDragEnd = (result: DragUpdate) => {
        // dropped outside the list
        if (!result.destination) return

        const items = reorder(
            options as Option[],
            result.source.index,
            result.destination.index
        ) as Option[]

        setOptions(items)
        if (onChange) onChange(items)
    }

    const onAddOptionImage = (index: number, media: File[]) => {
        const image = (Array.isArray(media)) ? media[0] : media
        const { file } = mediaController.addToUploadQueue(image)
        const optionsCopy = [..._options]

        optionsCopy[index] = {
            ...optionsCopy[index],
            image: file
        }
        setOptions(optionsCopy)
        if (onChange) onChange(optionsCopy)
    }

    const addOption = () => {
        const optionsCopy = [..._options]
        optionsCopy.push({ label: '', value: '' })
        setOptions(optionsCopy)
    }

    const deleteOption = (index: number) => {
        const optionsCopy = [..._options]
        optionsCopy.splice(index, 1)
        setOptions(optionsCopy)
        if (onChange) onChange(optionsCopy)
    }

    return (
        // @ts-ignore
        <DragDropContext onDragEnd={onDragEnd}>
            {/* @ts-ignore */}
            <Droppable
                droppableId="options-builder"
                type="options">
                {(provided) => (
                    <Col
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        {...otherProps}>

                        {_options?.map((option, idx) =>
                            <>
                            {/* @ts-ignore */}
                                <Draggable
                                    key={idx}
                                    draggableId={`option-${idx}`}
                                    index={idx}>
                                        {(provided) => (
                                            <Row
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                valign='center'
                                                className="mb-2">

                                                {/* DRAG HANDLE */}
                                                <div {...provided.dragHandleProps}>
                                                    <Icon
                                                        name="grip-dots-vertical"
                                                        color="muted"
                                                        className="mr-2"
                                                    />
                                                </div>
                                                {(isCheckbox) &&
                                                    <Button
                                                        color="gray300"
                                                        ghost
                                                        className="p-1 mr-2"
                                                        upload={true}
                                                        onUpload={(file) => onAddOptionImage(idx, file)}
                                                        fileTypes="image/*">
                                                        {(option.image) ?
                                                            // option.image is an ID 
                                                            <Image
                                                                src={(option.image instanceof File) ?
                                                                    mediaController.getLocalFileURL(option.image as File) as string :
                                                                    mediaController.mediaArrays?.uploaded?.find(m => m.id === option.image)?.url as string
                                                                }
                                                                resizeMode="contain"
                                                                width={30}
                                                                height={30}
                                                                url=''
                                                            />
                                                            :
                                                            <Icon
                                                                name="image"
                                                                color="muted"
                                                            />
                                                        }
                                                    </Button>
                                                }

                                                <Input
                                                    name="label"
                                                    value={option.label}
                                                    placeholder="Label..."
                                                    className="mr-2"
                                                    onChange={(event) => _onChange(idx, event)}
                                                    onBlur={(event) => _onBlur(idx, event)}
                                                    {...otherProps}
                                                />
                                                <Input
                                                    name="value"
                                                    value={option.value}
                                                    placeholder={(isCheckbox) ? 'Value when checked...' : 'Value...'}
                                                    className={(_options?.length === 1) ? 'mr-4' : ''}
                                                    onChange={(event) => _onChange(idx, event)}
                                                    {...otherProps}
                                                />
                                                {(_options?.length > 1) &&
                                                    <Icon
                                                        name="circle-xmark"
                                                        type="solid"
                                                        color="gray300"
                                                        className="ml-2"
                                                        onClick={() => deleteOption(idx)}
                                                    />
                                                }
                                            </Row>
                                        )}
                                </Draggable>
                            </>                            
                        )}
                        <div>
                            <Button
                                color="link"
                                size='sm'
                                onClick={addOption}
                                className="mr-4 float-right">
                                Add option
                            </Button>
                        </div>
                    </Col>
                )}
            </Droppable>
        </DragDropContext>
    )
})

export default OptionsBuilder
OptionsBuilder.displayName = 'OptionsBuilder'

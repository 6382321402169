import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface CardBodyProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * A string of all className you want applied to the base component.
     */
    className?: string
}

const CardBody = forwardRef<HTMLDivElement, CardBodyProps>(({
    children,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('card-body', className)

    return (
        <div
            ref={ref}
            className={_className}
            {...otherProps}>
            {children}
        </div>
    )
})

export { CardBody }
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Text } from '@evertel/web/ui'
import { APIDataRoomMessage } from '@evertel/types'
import { useService } from '@evertel/di'
import { UserController } from '@evertel/blue-user'
import { UserTile } from '@evertel/web/user'

interface ForwardMessagePreviewProps {
    forwardMessage: APIDataRoomMessage
}

const ForwardMessagePreview: React.FC<ForwardMessagePreviewProps> = observer(({
    forwardMessage
}) => {

    const ownerId = forwardMessage?.ownerId || 1
    const userController = useService(UserController, [ownerId])

    useEffect(() => {
        userController.init(ownerId)
    }, [userController, ownerId])

    return <Col>
        <Text children={'Message:'} tag={'h4'} bold={true} />
        <div className="p-2 post-form reply bg-light rounded">
            <Row style={{ paddingBottom: 2 }}>
                <UserTile imageWidth={40} userId={ownerId} />
            </Row>
            <Row className='mb-3'>
                <Text className='ml-5' children={forwardMessage?.text} />
            </Row>
            {forwardMessage?.media?.length > 0 &&
                <Row className='ml-5'>
                    <Text color="muted" italic>{`${forwardMessage?.media?.length} media attached`}</Text>
                </Row>
            }
        </div>
    </Col>
})

export {
    ForwardMessagePreview
}
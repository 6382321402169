import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface ToastBodyProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

const ToasterBody = forwardRef<HTMLDivElement, ToastBodyProps>(({
    children,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('toast-body', className)

    return (
        <div
            ref={ref}
            className={_className}
            {...otherProps}
        >
            {children}
        </div>
    )
})

export default ToasterBody
ToasterBody.displayName = 'ToasterBody'

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@evertel/web/ui'
import { observer } from 'mobx-react'
//file uploader
import { FilePond, registerPlugin } from 'react-filepond'
import FilepondPluginImagePreview from 'filepond-plugin-image-preview'
import FilepondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilepondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilepondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
// evertel
import { API_ROOT } from '../apiConfig'
import { ProfilePic } from '@evertel/web/user'
import { DepartmentAvatar } from '@evertel/web/department'
import { AnalyticsStore, UsersStore, AppStore, DepartmentsStore } from '../stores'
import { Icon, Toaster } from '.'

const propTypes = {
    id: PropTypes.number.isRequired,
    type: PropTypes.string, // department || user
    onImageUpdated: PropTypes.func, // callback after image has been deleted or changed
    initialPic: PropTypes.string,
    onUploadStart: PropTypes.func,
    onUploadEnd: PropTypes.func
}

const defaultProps = {
    type: 'user'
}

// fileuploader plugins
registerPlugin(
    FilepondPluginImageExifOrientation,
    FilepondPluginImagePreview,
    FilepondPluginFileValidateType,
    FilepondPluginImageResize,
    FilePondPluginImageCrop,
    FilePondPluginImageTransform,
    FilePondPluginFileEncode,
    FilePondPluginFilePoster
)


const ProfilePicUploader = observer(({
    id,
    type,
    inImageUpdated,
    initialPic,
    onUploadStart,
    onUploadEnd
}) => {

    const user = UsersStore.getUserById(id)
    const [profilePic, setProfilePic] = useState(initialPic)

    useEffect(()=>{
        setProfilePic(initialPic)
    }, [initialPic])

    const deleteUserPic = async () => {
        await user.deleteProfilePic()

        const error = user.error
        if (error) {
            Toaster.show('error', error.message || error)
            return
        }

        setProfilePic(null)

        // once the file is deleted, refetch the user/dept data
        // to refresh store
        if (type === 'user') {
            UsersStore.fetchUser(id)
        } else {
            DepartmentsStore.fetchDepartmentById(id)
        }

        AnalyticsStore.logEvent({
            category: 'User',
            action: 'edited_profile_image'
        })
    }

    const deleteDeptPic = () => {
        setProfilePic(null)
    }

    const onAddFileStart = () => {
        if (onUploadStart) onUploadStart()
    }

    const onProcessedFile = async (error, file) => {
        if (error) return
        if (onUploadEnd) onUploadEnd()

        // once the file is uploaded, refetch the user/dept data
        // to refresh store
        if (type === 'user') {
            UsersStore.fetchUser(id)
        } else {
            DepartmentsStore.fetchDepartmentById(id)
        }
    }

    const onError = (e, f) => {
        AppStore.logError({
            type: 'Filepond upload',
            message: 'Profile pic uploader',
            error: e.message || e,
            userId: id
        })
    }


    if (profilePic) {
        return (
            <div className="mb-3 position-relative">
                {type === 'department' &&
                    <DepartmentAvatar
                        departmentId={id}
                        width={150}
                        height={150}
                    />
                }
                {type === 'user' &&
                    <ProfilePic
                        userId={id}
                        imageUrl={profilePic}
                        width={150}
                        height={150}
                    />
                }                
                <Button
                    color="danger"
                    size="sm"
                    className="position-absolute top-0 start-0"
                    onClick={(type === 'user') ? deleteUserPic : deleteDeptPic}>
                    <Icon
                        name="trash"
                        className="text-white"
                    />
                </Button>
            </div>
        )
    } else {
        return (
            <FilePond
                className="profile-pic-uploader"
                labelIdle="Drag & drop your photo or <span class='filepond--label-action'>Browse</span>"
                acceptedFileTypes={['image/*']}
                imagePreviewHeight={150}
                imageResizeTargetWidth="500"
                imageResizeTargetHeight="500"
                imageCropAspectRatio="1:1"
                stylePanelLayout="compact"
                //styleLoadIndicatorPosition="center bottom"
                //styleButtonRemoveItemPosition="center bottom"
                allowMultiple={false}
                allowFilePoster={true}
                onerror={onError}
                onprocessfile={onProcessedFile}
                onaddfilestart={onAddFileStart}
                credits={false}
                server={{
                    url: API_ROOT,
                    process: {
                        url: (type === 'department') ? `/Departments/${id}/publicMedia/upload` : `/BlueUsers/${id}/publicMedia/upload`,
                        method: 'POST',
                        withCredentials: true,
                        headers: {
                            'X-App-Build': `Web/${process.env.BUILD_NUMBER}`,
                            //TODO: store authorization in Service somewhere
                            Authorization: localStorage.getItem('authorization')
                        }
                    }
                }}
            />
        )
    }
})

ProfilePicUploader.propTypes = propTypes
ProfilePicUploader.defaultProps = defaultProps
export default ProfilePicUploader

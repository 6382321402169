import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'
import ToasterClose from './ToasterClose'

export interface ToastHeaderProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
    className?: string
    closeButton?: boolean
}

const ToasterHeader = forwardRef<HTMLDivElement, ToastHeaderProps>(({
    children,
    className,
    closeButton,
    ...otherProps
}, ref) => {

    const _className = classNames('toast-header', className)

    return (
        <div
            ref={ref}
            className={_className}
            {...otherProps}
        >
            {children}
        </div>
    )
})

export default ToasterHeader
ToasterHeader.displayName = 'ToasterHeader'

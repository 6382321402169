import { useCallback } from 'react'
import { Emoji } from '@evertel/web/emojis'

type OptionProps = {
    option: string,
    messageId?: number,
    params?: any
}

type Emoji = {
    id: string,
    usedCount: number,
    lastUsed: Date
}

interface EmojiListProps {
    emojis: Emoji[],
    messageId: number,
    skinTone:  1 | 2 | 3 | 4 | 5 | 6,
    onClickOption: (option: OptionProps) => void,
}

export const EmojiList: React.FC<EmojiListProps> = ({emojis, onClickOption, messageId, skinTone}) => {
    const onClickFrequentReactions = useCallback((event, id) => {
        event.stopPropagation()
        onClickOption({
            option: 'reaction',
            messageId,
            params: { native: id }
        })
    }, [messageId, onClickOption])
    
    if (emojis?.length <= 0) {
        return null
    }

    return (
        <div className="frequent-reactions">
            {emojis.slice().sort((a, b) => b.usedCount - a.usedCount).map((e, idx) =>
                <div
                    key={idx}
                    className="emoji"
                    onClick={(event) => onClickFrequentReactions(event, e.id)}
                >
                    <Emoji
                        id={e.id}
                        skin={skinTone}
                        size={16}
                    />
                </div>
            )}
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { Virtuoso } from 'react-virtuoso'
// evertel
import { Text, Modal, ModalBody, ModalHeader, InfoBox, Spinner } from '@evertel/web/ui'
import { UserTile } from '@evertel/web/user'
import { useService } from '@evertel/di'
import { MessageReactedByController } from '@evertel/message'
import { APIDataReactionUserThrough } from '@evertel/types'
import { Emoji } from '@evertel/web/emojis'

interface ReactedByModalProps {
    messageId: number,
    modelType: 'room'|'thread'
    visible: boolean,
    onClose: () => void,
    onCloseDone: () => void
}


const ReactedByModal: React.FC<ReactedByModalProps> = observer(({
    messageId,
    modelType,
    visible,
    onClose,
    onCloseDone
}) => {

    const reactedByController = useService(MessageReactedByController, [messageId])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            if (visible) {
                setIsLoading(true)

                await reactedByController.init(messageId, modelType)

                setIsLoading(false)
            }
        })()
    }, [messageId, visible, reactedByController, modelType])

    const onEndReached = () => {
        if (visible) {
            reactedByController.fetchNextPage()
        }
    }

    const title = (reactedByController.reactedByCount === 1) ? reactedByController.reactedByCount + ' reaction' : reactedByController.reactedByCount + ' reactions'

    return (
        <Modal
            visible={visible}
            alignment="center"
            onClose={onClose}
            onCloseDone={onCloseDone}>
            <ModalHeader
                title={(isLoading) ? 'Loading reactions...' : title}
                closeButton
            />
            <ModalBody style={{
                height: (reactedByController.reactedByCount > 10) ? '50vh' : reactedByController.reactedByCount * 65,
                minHeight: 100
            }}>
                <Virtuoso
                    endReached={onEndReached}
                    data={reactedByController.reactedBy}
                    totalCount={reactedByController.reactedByCount}
                    overscan={300}
                    style={{ height: '100%', overflowX: 'hidden' }}
                    itemContent={(index, reaction) => {
                        return (
                            <User
                                data={reaction}
                                index={index}
                            />
                        )
                    }}
                    components={{
                        Footer: () => {
                            return (
                                <Footer
                                    showSpinner={isLoading}
                                />
                            )
                        },
                        EmptyPlaceholder: () => {
                            return (
                                <EmptyPlaceholder
                                    isLoading={isLoading}
                                />
                            )
                        }
                    }}
                />
            </ModalBody>
        </Modal>
    )
})


interface UserProps {
    index: number,
    data: APIDataReactionUserThrough
}

const User: React.FC<UserProps> = ({
    index,
    data
}) => {

    if (!data?.reaction) return null

    return (
        <UserTile
            userId={data.blueUserId}
            className="border-bottom border-light pb-2 mb-2"
            rightComponent={
                <>
                    <Emoji
                        id={data.reaction.text}
                        size={25}
                    />
                    <Text
                        color="muted"
                        size="smaller"
                        className="ml-2">
                        {moment(data.createdDate).format('L HH:mm:ss')}
                    </Text>
                </>
            }
        />
    )
}

const Footer = ({ showSpinner }) => {
    if (!showSpinner) return null

    return (
        <i className="text-muted">
            <Spinner /> Loading...
        </i>
    )
}

const EmptyPlaceholder = ({ isLoading }) => {
    if (isLoading) return null

    return (
        <InfoBox color="info">
            No one has reacted to this message yet
        </InfoBox>
    )
}

export { ReactedByModal }

import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { MultiValue, Props, SingleValue } from 'react-select'
// evertel
import { formatForSelect } from '@evertel/utils'
import { APIDataDepartment } from '@evertel/types'
import { useService } from '@evertel/di'
import { ContentPlaceholder } from '../elements'
import { DepartmentsSearchController } from '@evertel/department'
import { CustomAsyncPaginate } from './CustomAsyncPaginate'


interface DepartmentSearchSelectProps extends Props {
    selected: Record<string, any>[],
    placeholder?: string,
    whereFilter?: Record<string, any>,
    className?: string,
    onEndReachedThreshold?: number,
    onSelect: (department: APIDataDepartment) => void,
    customComponents?: ReactNode,
    customProps?: Record<string, any>
}


const DepartmentSearchSelect: React.FC<DepartmentSearchSelectProps> = observer(({
    selected = [],
    placeholder = 'Search agencies...',
    className,
    onSelect,
    customComponents,
    onEndReachedThreshold = 80,
    ...otherProps
}) => {

    const departmentsSearchController = useService(DepartmentsSearchController, [])

    const [isLoading, setIsLoading] = useState(true)
    const [isSearching, setIsSearching] = useState(false)

    useEffect(() => {
        //this could be in useState(()=>{}) but for the sake of matching the other SearchSelects it's like so

        setIsLoading(true)
        departmentsSearchController.init(false)
        //needs to render once with loading true to reload async paginate
        setTimeout(() => { setIsLoading(false)}, 1)

    }, [])

    const search = useCallback( async (
        searchString: string,
        loadedOptions: APIDataDepartment[],
        additional: { page: number }
    ) => {

        if (departmentsSearchController.searchTerm !== searchString) {
            departmentsSearchController.setSearchTerm(searchString)
        }
        departmentsSearchController.setPage(additional?.page)

        try {
            setIsSearching(true)
            await departmentsSearchController.search()
            setIsSearching(false)
            
        } catch (error) {
            console.log('ERROR: /libs/web/ui/form/DepartmentSearchSelect search():', error)
        }

        // grab results and format them for the Select
        const results = formatForSelect(departmentsSearchController.departmentsLatestFetch, 'name', 'id')
        const hasMore = (departmentsSearchController.departmentsLatestFetch.length >= departmentsSearchController.limit)

        return {
            options: results || [],
            hasMore,
            additional: {
                page: additional?.page + 1 || 1
            }
        }
    }, [])

    const onSelectOption = (dept: MultiValue<APIDataDepartment>|SingleValue<APIDataDepartment>) => {
        if (onSelect) {
            departmentsSearchController.setSearchTerm('')
            onSelect(dept as APIDataDepartment)
        }    
    }

    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number): boolean => {
        if (isSearching) return false
        const maxPixelThreshold = 300
        const percentThreshold = 100 - onEndReachedThreshold

        const pxFromBottom = scrollHeight - clientHeight - scrollTop
        const percentThresholdFromBottomInPx = ((percentThreshold / 100) * (scrollHeight - clientHeight))
    
        // Determine the threshold to use (whichever is smaller)
        const thresholdToUse = Math.min(percentThresholdFromBottomInPx, maxPixelThreshold)

        // console.log('px from bottom / threshold, result', pxFromBottom, thresholdToUse, pxFromBottom <= thresholdToUse)    
        return pxFromBottom <= thresholdToUse
    }

    if (isLoading) {
        return (
            <ContentPlaceholder
                className={className}
                style={{
                    height: 38
                }}
            />
        )
    }

    return (
        <CustomAsyncPaginate
            name="departments"
            loadOptions={search}
            debounceTimeout={300}
            additional={{
                page: 1
            }}
            defaultOptions
            value={selected}
            onChange={(customComponents) ? undefined : onSelectOption}
            noOptionsMessage={() => 'No matching agencies'}
            placeholder={placeholder}
            shouldLoadMore={shouldLoadMore}
            menuPlacement='auto'
            {...(customComponents) && {components: customComponents}}
            className={className}
            {...otherProps}
        />
    )
})

export { DepartmentSearchSelect }

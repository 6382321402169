import Wrapper from './Wrapper'
import { Text, Link } from '@react-pdf/renderer'

const TextReadWidget = ({
    value = '',
    title,
    description,
    contentType = 'text'
}) => {

    //console.log(title, value, contentType)

    return (
        <Wrapper
            title={title}
            description={description}>
            {(contentType !== 'url' && contentType !== 'email') ?
                <Text>
                    {value || '---'}
                </Text>
                :
                <Link
                    src={(contentType === 'email') ? `mailto:${value}` : value}
                    style={{
                        color: 'blue'
                    }}>
                    {value || '---'}
                </Link>
            }
        </Wrapper>
    )
}

export default TextReadWidget
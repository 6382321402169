
import React from 'react'
import { FloatingMenu, MenuItem, Icon } from '@evertel/web/ui'
import { APIDataDocumentSchema } from '@evertel/types'

interface OptionsMenuProps {
    schema: APIDataDocumentSchema
    onCreate?: (schemaId: number) => void
    onEdit: (schemaId: number) => void
    onClone?: (schema: APIDataDocumentSchema) => void
    canManage: boolean
}

export const OptionsMenu: React.FC<OptionsMenuProps> = ({
    schema,
    onCreate,
    onEdit,
    onClone,
    canManage
}) => {
    const handleCreate = (e) => {
        e.stopPropagation()
        if (onCreate){
            onCreate(schema.id as number)
        }
    }
    const handleEdit = (e) => {
        e.stopPropagation()
        onEdit(schema.id as number)
    }
    const handleClone = (e) => {
        e.stopPropagation()
        if (onClone){
            onClone(schema)
        }
    }
    return (
        <FloatingMenu
            label="Document Actions"
            renderLabel={() => (
                <Icon
                    name="ellipsis-v"
                    color="gray600"
                />
            )}
            className="document-options-menu py-0 px-2"
        >
            {onCreate && (
                <MenuItem
                    label="Create Document"
                    onClick={handleCreate}
                />
            )}
            {canManage && (
                <MenuItem
                    label="Edit Template"
                    onClick={handleEdit}
                />
            )}
            {canManage && onClone && (
                <MenuItem
                    label="Clone Template"
                    onClick={handleClone}
                />
            )}
        </FloatingMenu>
    )
}
import React, { useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Row, Card, CardBody, Icon, Text, Col, Media, PhotoGalleryContext } from '@evertel/web/ui'
import Wrapper from './Wrapper'
import { useService } from '@evertel/di'
import { useService as useGlobalService } from '@evertel/service' 
import { DocumentMediaReadWidgetController } from '../../controller'
import { getFileTypeFromMimetype } from '@evertel/utils'

export interface MediaReadWidgetProps {
    id: number|string,
    value: number[],
    title: string
}

const MediaReadWidget: React.FC<MediaReadWidgetProps> = observer(({
    value,
    title,
    ...otherProps
}) => {

    const photoGallery = useContext(PhotoGalleryContext)
    const mediaReadWidgetController = useService(DocumentMediaReadWidgetController, [])
    const meta = useGlobalService('meta')

    useEffect(() => {
        (async () => {
            if (!meta?.document) {
                console.error('ERROR: Missing meta in MediaReadWidget. You forgot to add the meta ServiceProvider.')
                return
            }

            await mediaReadWidgetController.init(
                meta.document,
                value
            )
        })()
    }, [])

    const onClickMedia = () => {
        photoGallery.setMedia(mediaReadWidgetController.visualMedia)
        photoGallery.setVisible(true)
    }

    //console.log('MEDIA', title, value)

    return (
        <Wrapper
            title={title}
            {...otherProps}>
            <Card>
                <CardBody>
                    <Row style={{
                        flexWrap: 'wrap'
                    }}>
                        {mediaReadWidgetController.media?.map((media) => {
                            const type = getFileTypeFromMimetype(media.mimeType as string)
                            return (
                                <Media
                                    key={media.id}
                                    media={media}
                                    width={250}
                                    height={150}
                                    {...(type === 'image' || type === 'video') && {onClick: onClickMedia}}
                                    className="mr-3 my-2"
                                />
                            )
                        })}
                        {(!mediaReadWidgetController.media?.length) &&
                            <Col align="center">
                                <Icon
                                    name="image-slash"
                                    color="muted"
                                />
                                <Text
                                    color="muted"
                                    italic>
                                    No {title}
                                </Text>
                            </Col>
                        }
                    </Row>
                </CardBody>
            </Card>
        </Wrapper>
    )
})

export {
    MediaReadWidget
}

import { decorate, inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import { APIDataRoom } from '@evertel/types'
import { Api } from '@evertel/api'
import { RoomStore } from '@evertel/stores'
import { SessionState } from '@evertel/session'

type CreateRoomConfig = {
    isSearchable: boolean,
    name: string,
    description: string,
    options: {
        autoAddDepartmentMembers: boolean,
        departmentMembersCanJoinAsMember: boolean,
        roomMembersCanMessage: boolean,
        detectScreenshots: boolean,
        roomMembersCanLeave: boolean
    }
}

class CreateRoomController {

    constructor(
        private api: Api,
        private roomStore: RoomStore,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    async createRoom(config: CreateRoomConfig): Promise<APIDataRoom> {
        const room = await this.api.Routes.Department.postRoom(this.session.selectedDepartmentId, config)

        runInAction(() => {
            this.roomStore.update(room)
        })

        return room
    }
}

decorate(injectable(), CreateRoomController)
decorate(inject(Api), CreateRoomController, 0)
decorate(inject(RoomStore), CreateRoomController, 1)
decorate(inject(SessionState), CreateRoomController, 2)

export { CreateRoomController }
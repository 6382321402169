import { Component } from 'react'
import { InfoBox } from '@evertel/web/ui'
import { AppStore, AnalyticsStore } from '../stores'


//===================================================================================
class ErrorBoundary extends Component {
//===================================================================================

    state = { error: null, errorInfo: null }

    componentDidCatch = async (error, errorInfo) => {
        // catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

        const errorObj = {type: 'FATAL', URL: window.location.href, error: error.toString(), errorInfo}

        // log to API
        AppStore.logError(errorObj)

        // log to GA
        AnalyticsStore.logException(error.toString(), true)
    }

    //--------------------------------------------------------------------------------
    render() {
    //--------------------------------------------------------------------------------
        if (this.state.errorInfo) {
            return (
                <div className="p-5 app fadeIn animated registration" style={{height: '100vh'}}>
                    <InfoBox color="warning">
                        {/* <img src={'assets/img/doughnut-guy.png'} className="pb-3" alt="Evertel Doughnut Guy" /> */}
                        <h1>Hmm. Something went wrong.</h1>
                        <h5>Our engineers have been automatically notified. Try going back or refreshing your page. If you continue to experience this issue, please email us at <a href="mailto:connectsupport@genasys.com">connectSupport@genasys.com</a> or try our <a href="https://getevertel.com/help-center/" target="_blank" rel="noreferrer">Help Center</a>.
                        </h5>
                        <details className="pt-4" style={{ whiteSpace: 'pre-wrap' }}>
                            <div>
                                {this.state.error && this.state.error.toString()}
                                <br />
                                {this.state.errorInfo.componentStack}
                            </div>
                        </details>
                    </InfoBox>
                </div>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary

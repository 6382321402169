
import { APIDataMedia } from '@evertel/types'
import { makeAutoObservable } from 'mobx'
import { injectable, decorate } from 'inversify'
import { formatBytes, getFileAttributes, getFileExt, getMediaType } from '@evertel/utils'

class FileController {
    file: APIDataMedia = {}

    constructor(
    ) {
        makeAutoObservable(this)
    }

    init(file: APIDataMedia) {
        // expects a full media object already fetched elsewhere
        this.file = file
    }

    get isLocal() {
        return this.file instanceof File
    }

    get url() {
        if (this.isLocal) {
            // if a local file, return blob
            return URL.createObjectURL(this.file as File)
        }

        return this.file?.url
    }

    get fileName() {
        return (this.isLocal) ? (this.file as File)?.name : this.file?.fileName
    }

    get type() {
        return getMediaType(this.file)
    }

    get fileExtension() {
        return getFileExt(this.file)
    }

    get humanizedFileSize() {
        return formatBytes((this.isLocal) ? (this.file as File)?.size : this.file?.contentLength, 1)
    }

    get fileTypeAttributes() {
        return getFileAttributes(this.fileName)
    }
}


decorate(injectable(), FileController)

export {
    FileController
}

import { observable, runInAction, action, computed, makeObservable } from 'mobx'
import api from '../api'
import AppStore from './AppStore'
//import UsersStore from './UsersStore'
import _ from 'lodash'

// Keeps track of our login state and progress
class ContactsSearchStore {
    contacts = []
    count = 0
    //@observable resultIds = []
    isBusy = false
    error = null

    constructor() {
        makeObservable(this, {
            contacts: observable,
            count: observable,
            isBusy: observable,
            error: observable,
            results: computed,
            clearSearch: action
        })

        
    }

    get results() {
        if (!this.contacts.length) return []
        //return UsersStore.users.filter(u => this.resultsIds.includes(u.id))
        return this.contacts
    }

    // Search contacts api then updates this.resultsIds observable, debounced
    search = _.debounce(async (searchString = '', page = 1) => {

        let filterNonPrintableCharacters
        if (searchString.length !== 0) filterNonPrintableCharacters = /^([a-z]|[0-9]|\s)*$/gi.test(searchString)
        if (searchString.length !== 0 && !filterNonPrintableCharacters) { return }

        runInAction(() => {
            this.isBusy = true
            this.error = null
        })
        let contacts = []
        try {
            // Get users via search contacts
            contacts = await api.BlueUser.searchContacts(AppStore.userId, {
                where: {
                    searchTerm: searchString
                },
                ...api.defaultUserScope,
                order: 'firstName',
                page: page,
                skip: (page - 1) * 100,
                limit: 100
            })

            // get total count of matching users (used for paging)
            await this.fetchSearchCount(searchString)

            // Save Users to local store models from data
            //UsersStore.updateFromData(contacts)
        } catch (e) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })

            AppStore.logError({type: 'API get', message: 'ContactsSearchStore.search()', error: e.message || e})
        }

        // save results
        runInAction(() => {
            this.isBusy = false
            this.contacts = contacts
            //this.resultsIds = contacts.map(u => u.id)
        })
    }, 50, {leading: true})

    async fetchSearchCount(searchString) {
        try {
            const count = (await api.BlueUser.searchContactsCount(AppStore.userId, { searchTerm: searchString })).count
            runInAction(() => {
                this.count = count
            })

        } catch (e) {
            AppStore.logError({
                type: 'API get',
                message: 'ContactsSearchStore.fetchSearchCount()',
                error: e.message || e
            })

            runInAction(() => {
                this.isBusy = false
                this.error = e
            })
        }
    }

    async clearSearch() {
        //this.resultsIds.clear()
        this.contacts.clear()
        this.isBusy = false
        this.error = null
        this.search.cancel()
    }
}


// Creates a single instance of this store
export default new ContactsSearchStore()


import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { InfoButton } from '../elements/InfoButton'

interface LabelProps {
    text?: string,
    /** ID of the form element this is used for */
    htmlFor?: string,
    required?: boolean,
    optional?: boolean,
    hint?: string,
    hintPosition?: 'left'|'right'|'top'|'bottom'
    className?: string
    bold?: boolean
    children?: React.ReactNode
}

const Label = forwardRef<HTMLLabelElement, LabelProps>(({
    text,
    htmlFor,
    required,
    optional,
    hint,
    hintPosition = 'right',
    bold,
    className,
    children,
    ...otherProps
}, ref) => {

    return (
        <label
            ref={ref}
            htmlFor={htmlFor}
            className={classNames({
                    'font-weight-bold': bold,
                    'cursor-pointer': htmlFor
                },
                className
            )}
            {...otherProps}>
            {text}
            {(required) && <span className="text-danger">*</span>}
            {(optional) && <small className="text-muted"> (optional)</small>}
            {(hint) &&
                <InfoButton
                    text={hint}
                    color="muted"
                    // @ts-ignore
                    className="ml-1"
                    data-tooltip={hint}
                    data-tooltip-pos={hintPosition}
                />
            }
            {children}
        </label>
    )
})

export { Label }

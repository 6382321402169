import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useService } from '@evertel/di'
import { ContentPlaceholder, Col, DeleteButtonOverlay } from '@evertel/web/ui'
import { DocumentMediaController } from '../../controller'
import { Media } from '@evertel/web/ui'
import { UploadableMedia } from '@evertel/media'

interface DocumentMediaProps {
    documentId: number,
    mediaId: number,
    width: number,
    height: number,
    className?: string,
    localFile?: UploadableMedia,
    onDelete?: (mediaId: number) => void
}

const DocumentMedia: React.FC<DocumentMediaProps> = observer(({
    documentId,
    mediaId,
    width,
    height,
    className,
    localFile,
    onDelete,
    ...otherProps
}) => {
    
    const documentMediaController = useService(DocumentMediaController, [mediaId])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            if (!localFile) {
                setIsLoading(true)

                await documentMediaController.init(
                    documentId,
                    mediaId,
                    width,
                    height
                )

                setIsLoading(false)
            }
        })()
    }, [mediaId])

    if (isLoading) {
        return (
            <ContentPlaceholder
                width={width}
                height={height}
                className={className}
            />
        )
    }

    return (
        <Media
            media={(localFile) ? localFile : documentMediaController.media}
            variant="file-attachment"
            width={width}
            height={height}
            fileProps={{width: 200}}
            videoProps={{ showThumbnail: true }}
            imageProps={{ flexibility: 'fixed' }}
            resizeMode="cover"
            rounded
            onDelete={() => onDelete && onDelete(mediaId)}
            className=""
            {...otherProps}
        />
    )
})

export {
    DocumentMedia
}

import React from "react";
import { AppLoaderProps } from "../UIContext";

const AppLoader: React.FC<AppLoaderProps> = ({
    name = 'page-loading',
    type = 'default',
    isOpen = false,
    children
}) => {

    return (
        <div className={isOpen ? 'fade-in' : 'fade-out'}>
            <div className="spinner">
                <div className={type === 'full-overlay' ? 'full-overlay ' : ''}>
                    {type === 'full-overlay' &&
                        <div className="pb-2">
                            <img src={'assets/img/evertel-shield-110x110.png'} height="138" className="pb-3" alt="Evertel" />
                            <h1>Loading...</h1>
                            <h3>Just one moment, please</h3>
                        </div>
                    }
                    <div className="sk-three-bounce">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                    {children}
                </div>
            </div>
            <div className={type === 'full-overlay' ? 'modal-backdrop opaque show' : 'modal-backdrop show'}></div>
        </div>
    )
}

export { AppLoader }
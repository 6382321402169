import React, { useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react'
import { Button, Card, CardBody, CardHeader, ContentPlaceholder, Icon, InfoBox, Row, TanTable, TanTableColumnDef } from '@evertel/web/ui'
import { formatNumber } from '@evertel/utils'
import { SessionState } from '@evertel/session'
import { useService } from '@evertel/di'
import { Link, useHistory } from 'react-router-dom'
import { ChildDepartmentController, DepartmentController } from '@evertel/department'
import { SelectedDepartmentAccessController } from '@evertel/departments-access'
import { ColumnDef } from '@tanstack/react-table'
import { APIDataDepartment } from '@evertel/types'

const ChildDepartments: React.FC = observer(() => {
    const session = useService(SessionState, [])
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)

    const sessionDepartmentId = session.selectedDepartmentId
    const sessionUserId = session.currentUserId

    const departmentController = useService(DepartmentController, [sessionDepartmentId])
    const selectedDepartmentAccessController = useService(SelectedDepartmentAccessController, [sessionDepartmentId])
    const childDepartmentController = useService(ChildDepartmentController, [sessionDepartmentId])

    useEffect(() => {
        const initData = async () => {
            setIsLoading(true)
            await departmentController.init(sessionDepartmentId)
            await childDepartmentController.init(sessionDepartmentId)
            await selectedDepartmentAccessController.init(sessionUserId)
            setIsLoading(false)
        }
        initData()
    }, [sessionDepartmentId, sessionUserId, departmentController, childDepartmentController, selectedDepartmentAccessController])

    const onSelectDepartment = (department: APIDataDepartment) => {
        history.push('/account/department/settings/' + department.id)
    }

    const columns = useMemo<TanTableColumnDef<APIDataDepartment>[]>(() => [
        {
            accessorKey: 'id',
            header: 'Id',
            size: '10%'
        },
        {
            accessorKey: 'name',
            header: 'Name',
            size: '15%',
            cell: ({ row }) => (
                <Link className="btn btn-link px-3 py-1" to={`/manage/agency/settings/${row.original.id}`}>
                    {row.original.name || 'Missing Name'}
                </Link>
            )
        },
        {
            accessorKey: 'type',
            header: 'Type',
            size: '10%',
            cell: ({ getValue }) => <span style={{textTransform: 'capitalize'}}>{getValue() as string}</span>
        },
        {
            accessorKey: 'city',
            header: 'City',
            size: '10%'
        },
        {
            accessorKey: 'state',
            header: 'State',
            size: '10%'
        },
        {
            accessorKey: 'employees',
            header: 'Employees',
            size: '10%',
            cell: ({ getValue }) => <div style={{textAlign: 'right'}}>{getValue() as number}</div>
        },
        {
            id: 'actions',
            size: 200,
            meta: {align: 'right'},
            cell: ({ row }) => (
                <Row align="end">
                    <Link className="btn btn-purple px-3 py-1" to={`/manage/reports/${row.original.id}`}>
                        <Icon name="chart-pie-alt" color="white" />
                    </Link>
                    <Link className="btn btn-info px-3 py-1 mx-2" to={`/manage/users/`}>
                        <Icon name="users" color="white" />
                    </Link>
                    <Link className="btn btn-secondary px-3 py-1" to={`/manage/agency/settings/${row.original.id}`}>
                        <Icon name="edit" color="white" />
                    </Link>
                </Row>
            )
        }
    ], [history])

    if (isLoading) {
        return <ContentPlaceholder />
    }

    const isExecutive = selectedDepartmentAccessController.role === 'executive'

    if (!isExecutive) {
        return (
            <InfoBox color='danger' className='m-4'>
                Sorry, you do not have proper credentials to access this page.
            </InfoBox>
        )
    }

    return (
        <div className="animated fadeIn m-4">
            <Card>
                <CardHeader>
                    <span className="page-title">
                        <Icon name="building" className="mr-2" />
                        Manage Child Agencies ({formatNumber(childDepartmentController.childDepartments.length) || 0})
                    </span>
                </CardHeader>
                <CardBody>
                    <TanTable
                        data={childDepartmentController.childDepartments}
                        columns={columns}
                        options={{
                            responsive: true,
                            pageSize: 25,
                            pageSizeList: [],
                            showPagination: true
                        }}
                    />
                </CardBody>
            </Card>
        </div>
    )
})

export { ChildDepartments }
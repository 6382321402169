import { APIDataDocumentACL, APIDataRoom } from '@evertel/types'
import { injectable, inject, decorate } from '@evertel/di'
import { makeAutoObservable, runInAction } from 'mobx'
import { Api } from '@evertel/api'

// ACL -----------------------------------------------
// accessType: "OWNER"
// createdById: 159
// createdDate: "2022-06-08T00:11:16.000Z"
// deletedDate: null
// documentId: 1398
// id: 2144
// principal: {firstName: 'Michael', lastName: 'Smith', id: 159}
// principalId: 159
// principalType: "BlueUser"

class DocumentACLsController {
    documentId = 0
    userId = 0
    ACLs: APIDataDocumentACL[] = []
    roomsFound: (APIDataRoom & {selected?: boolean})[] = []
    canShare = false
    canEdit = false
    myAccessType?: string = undefined
    hasPreviousShares?: boolean = true

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    static accessTypes = {
        OWNER: 'OWNER',
        EDIT_AND_SHARE: 'EDIT_AND_SHARE',
        EDIT: 'EDIT',
        READ_AND_SHARE: 'READ_AND_SHARE',
        READ: 'READ'
    }

    static principalTypes = {
        BlueUser: 'BlueUser',
        Room: 'Room',
        Department: 'Department',
        Thread: 'Thread'
    }

    async init(documentId: number, userId: number) {
        this.documentId = documentId
        this.userId = userId

        const aclData = await this.fetchACLForDocument(documentId)

        this.fetchMyACL(documentId)
        this.fetchPreviousSharesCount()
        
        runInAction(() => {
            this.ACLs = [...aclData]
        })
    }

    async fetchACLForDocument(documentId: number) {
        return await this.api.Routes.Document.getAcl(documentId, {
            include: {
                relation: 'principal',
                scope: {
                    fields: ['id', 'name', 'firstName', 'lastName']
                }
            }
        })
    }

    addACL = async (
        principalId: number,
        principalType: string,
        accessType: string,
        notify?: {urgent: boolean}
    ) => {

        // POST one ACL to document
        try {
            const newAcl = await this.api.Routes.Document.postAcl(this.documentId, {
                principalId,
                principalType,
                accessType
            } as APIDataDocumentACL)

            if (notify) {
                await this.api.Routes.Document.postAclNotify(this.documentId, newAcl.id, notify.urgent)
            }
    
            // Update our mobx list, to include principal
            const updatedAclData = await this.fetchACLForDocument(this.documentId)
    
            runInAction(() => {
                this.ACLs = updatedAclData
            })
    
            // return the mobx model added
            return this.ACLs.find(acl => acl.id === newAcl.id)

        } catch (error) {
            if (error.code === 'ACL_ALREADY_EXISTS') {
                // ignore it
                return false
            }
            throw error
        }
    }

    removeACL = async (aclOrId: number | APIDataDocumentACL) => {
        const aclId = (aclOrId as APIDataDocumentACL).id || aclOrId as number

        await this.api.Routes.Document.delAclById(this.documentId, aclId)

        runInAction(async () => {
            this.ACLs = this.ACLs.filter(acl => acl.id !== aclId)
        })
    }

    updateACL = async (aclId: number, accessType: string) => {
        try {
            const resp = await this.api.Routes.Document.putAclAccessType(this.documentId, aclId, accessType)

            runInAction(() => {
                const index = this.ACLs.findIndex(acl => acl.id === resp.id)
    
                if (index !== -1) {
                    this.ACLs[index] = resp
                }
            })
        } catch (error) {
            console.log('ERROR: libs/shared/DocumentACLsController -> updateACL()', error)
        }
    }

    toggleAccessTypeReadEdit = (aclId: number, newAccess: string) => {
        if (!aclId || isNaN(aclId)) {
            console.log('ERROR: libs/shared/DocumentACLsController ->  toggleAccessTypeReadEdit(aclId, newAccess) is missing aclID or is not a number')
            return
        }
        if (!['read', 'edit'].includes(newAccess)) {
            console.log(`ERROR: libs/shared/DocumentACLsController ->  toggleAccessTypeReadEdit(acl, newAccess) second argument must be "read" or "edit". "${newAccess}" was passed in`)
            return
        }

        const acl = this.ACLs.find(a => a.id === aclId)

        if (!acl) {
            console.log('ERROR: libs/shared/DocumentACLsController ->  toggleAccessTypeReadEdit(aclId, newAccess) could not find a matching ACL')
            return
        }

        const currentAccessType = acl.accessType
        const canShare = this.canShareDocument(currentAccessType)
        let newAccessType

        switch (newAccess) {
            case 'read':
                // if the new access requested is the same as the current, do nothing
                if (currentAccessType === DocumentACLsController.accessTypes.READ_AND_SHARE || currentAccessType === DocumentACLsController.accessTypes.READ) return
                
                if (canShare) {
                    newAccessType = DocumentACLsController.accessTypes.READ_AND_SHARE
                } else {
                    newAccessType = DocumentACLsController.accessTypes.READ
                }
                break
            case 'edit':
                // if the new access requested is the same as the current, do nothing
                if (currentAccessType === DocumentACLsController.accessTypes.EDIT_AND_SHARE || currentAccessType === DocumentACLsController.accessTypes.EDIT) return

                if (canShare) {
                    newAccessType = DocumentACLsController.accessTypes.EDIT_AND_SHARE
                } else {
                    newAccessType = DocumentACLsController.accessTypes.EDIT
                }
                break
            default:
                newAccessType = currentAccessType
                return
        }

        this.updateACL(aclId, newAccessType)
    }

    toggleAccessTypeShare = (aclId: number) => {
        if (!aclId || isNaN(aclId)) {
            console.log('ERROR: libs/shared/DocumentACLsController ->  toggleAccessTypeShare(aclId) is missing aclID or is not a number')
            return
        }

        const acl = this.ACLs.find(a => a.id === aclId)

        if (!acl) {
            console.log('ERROR: libs/shared/DocumentACLsController ->  toggleAccessTypeShare(aclId) could not find a matching ACL')
            return
        }

        const currentAccessType = acl.accessType
        let newAccessType

        switch (currentAccessType) {
            case DocumentACLsController.accessTypes.READ:
                newAccessType = DocumentACLsController.accessTypes.READ_AND_SHARE
                break
            case DocumentACLsController.accessTypes.READ_AND_SHARE:
                newAccessType = DocumentACLsController.accessTypes.READ
                break
            case DocumentACLsController.accessTypes.EDIT:
                newAccessType = DocumentACLsController.accessTypes.EDIT_AND_SHARE
                break
            case DocumentACLsController.accessTypes.EDIT_AND_SHARE:
                newAccessType = DocumentACLsController.accessTypes.EDIT
                break
            default:
                newAccessType = currentAccessType
        }

        this.updateACL(aclId, newAccessType)
    }

    getDepartmentACLbyDepartmentId = (departmentId: number) => {
        return this.ACLs.find(a => a.principalId === departmentId)
    }

    isRoomInACL = (room: {id?: number}) => {
        return !!this.ACLs.find((acl) => acl.principalId === room.id)
    }

    addRoomsToAcl = async (
        roomsToAdd: Array<any>,
        accessType = DocumentACLsController.accessTypes.READ,
        notify?: {urgent: boolean}
    ) => {
        await roomsToAdd.map(async (r) => await this.addACL(r.id, DocumentACLsController.principalTypes.Room, accessType, notify))

        // refetch ACLs from server
        this.fetchACLForDocument(this.documentId)
    }

    addDepartmentToAcl = async (departmentId: number) => {
        const resp = await this.addACL(departmentId, DocumentACLsController.principalTypes.Department, DocumentACLsController.accessTypes.READ)
        this.fetchACLForDocument(this.documentId)
        return resp
    }

    doesAclMatch = (type: string, value: Record<string, unknown>) => {
        return this.ACLs?.find((r) => r.principalType === type && r.principalId === value?.id)
    }

    matchAcl = (value: Record<string, unknown>) => {
        if (value.membersDirectory) {
            return this.doesAclMatch('Room', value)
        } else if (value.verifiedMemberCapacity) {
            // selected = !!(value?.find((r) => r.principalType === 'Department' && r.principalId === item?.id))
            return this.doesAclMatch('Department', value)
        } else {
            return false
        }
    }

    fetchMyACL = async (documentId: number) => {
        const resp = await this.api.Routes.Document.getMyAccess(documentId)

        if (resp) {
            runInAction(() => {
                this.canEdit = this.canEditDocument(resp.accessType)
                this.canShare = this.canShareDocument(resp.accessType)
                this.myAccessType = resp.accessType
            })
            return this.canShare
        }
        return false
    }

    fetchPreviousSharesCount = async () => {
        const count = await this.api.Routes.Document.getAcl(this.documentId, { deleted: true, accessType: { neq: 'OWNER' } })

        runInAction(() => {
            this.hasPreviousShares = (count) ? true : false
        })
    }

    canShareDocument = (accessType: string) => {
        return (accessType === DocumentACLsController.accessTypes.OWNER || accessType === DocumentACLsController.accessTypes.READ_AND_SHARE || accessType === DocumentACLsController.accessTypes.EDIT_AND_SHARE)
    }
    
    canEditDocument = (accessType: string) => {
        return (accessType === DocumentACLsController.accessTypes.OWNER || accessType === DocumentACLsController.accessTypes.EDIT || accessType === DocumentACLsController.accessTypes.EDIT_AND_SHARE)
    }

    canEditACL = (aclId: number) => {
        const acl = this.ACLs?.find(acl => acl.id === aclId)
        return (this.myAccessType === 'OWNER' || this.myAccessType === 'EDIT_AND_SHARE' || acl.createdById === this.userId)
    }

    isEditor = (accessType: string) => {
        return (accessType === DocumentACLsController.accessTypes.EDIT || accessType === DocumentACLsController.accessTypes.EDIT_AND_SHARE)
    }

    isViewer = (accessType: string) => {
        return (accessType === DocumentACLsController.accessTypes.READ || accessType === DocumentACLsController.accessTypes.READ_AND_SHARE)
    }

    get aclOwner() {
        return this.ACLs?.filter((acl) => acl.accessType === 'Owner')
    }

    get aclUsers() {
        return this.ACLs?.filter((acl) => acl.principalType === 'BlueUser')
    }

    get aclRooms() {
        return this.ACLs?.filter((acl) => acl.principalType === 'Room')
    }

    get aclThreads() {
        return this.ACLs?.filter((acl) => acl.principalType === 'Thread')
    }

    get aclDepartments() {
        return this.ACLs?.filter((acl) => acl.principalType === 'Department')
    }

    get currentUserACL() {
        return this.ACLs?.find((acl) => acl.principalId === this.userId)
    }

    get aclsNotOwner() {
        // return any ACLs where whre accessType is not 'owner'
        return this.ACLs?.filter(acl => acl.accessType !== DocumentACLsController.accessTypes.OWNER)
    }
}

decorate(injectable(), DocumentACLsController)
decorate(inject(Api), DocumentACLsController, 0)

export { DocumentACLsController }

import React, { useEffect } from 'react'
import TextReadWidget from './TextWidget'
import { useService } from '@evertel/di'
import { UserController } from '@evertel/blue-user'
import { observer } from 'mobx-react-lite'

const UserReadWidget = observer(({
    value,
    ...otherProps
}) => {

    const controller = useService(UserController, [])

    useEffect(() => {
        controller.init(
            value,
            {
                fields: ['id', 'firstName', 'lastName']
            }
        )        
    }, [value])

    if (!value) return null

    return (
        <TextReadWidget
            value={controller.fullName}
            {...otherProps}
        />
    )
})

export default UserReadWidget

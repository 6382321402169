import { createContext, useState, useContext } from 'react'
import { CreateThreadModal } from './CreateThreadModal'

export type SearchScope = 'contacts'|'public'

export interface CreateThreadContextProps {
    openCreateThread: (searchScope?: SearchScope) => void,
    closeCreateThread: () => void
}

export const CreateThreadContext = createContext<CreateThreadContextProps>({
    openCreateThread: (searchScope?: SearchScope) => {
        //
    },
    closeCreateThread: () => {
        //
    }
})

export const CreateThreadContextProvider: React.FC<{children?: JSX.Element}> = ({
    children
}) => {

    const [visible, setVisible] = useState(false)
    const [searchScope, setSearchScope] = useState<SearchScope>('contacts')

    const openCreateThread = (searchScope: SearchScope = 'contacts') => {
        if (searchScope === 'contacts' || searchScope === 'public') {
            setSearchScope(searchScope)
        }
        setVisible(true)
    }

    const closeCreateThread = () => {
        setVisible(false)
    }

    return (
        <CreateThreadContext.Provider value={{ openCreateThread, closeCreateThread }}>
            {children}
            <CreateThreadModal
                visible={visible}
                searchScope={searchScope}
                onClose={() => setVisible(false)}
            />
        </CreateThreadContext.Provider>
    )
}

export const CreateThreadConsumer = CreateThreadContext.Consumer

export const useCreateThread= () => {
    return useContext(CreateThreadContext)
}

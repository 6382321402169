import { observable, action, toJS, makeObservable } from 'mobx'
import { getEmojiDataFromNative, init } from 'emoji-mart'
import data from '@emoji-mart/data'
import CurrentUserStore from './CurrentUserStore'
import UsersStore from './UsersStore'
import AppStore from './AppStore'

init({ data})

const version = 1

class EmojiStore {
    skinTone = 1
    frequent = [
        {id: '+1', usedCount: 1, lastUsed: new Date()},
        {id: '-1', usedCount: 1, lastUsed: new Date()},
        {id: 'white_check_mark', usedCount: 1, lastUsed: new Date()},
        {id: 'grinning', usedCount: 1, lastUsed: new Date()},
        {id: 'facepunch', usedCount: 1, lastUsed: new Date()},
        {id: 'pray', usedCount: 1, lastUsed: new Date()},
        {id: 'joy', usedCount: 1, lastUsed: new Date()}
    ]

    constructor() {
        makeObservable(this, {
            skinTone: observable,
            frequent: observable,
            init: action,
            updateFromData: action,
            getFrequent: action,
            getFrequentIds: action,
            incrementFrequent: action,
            decrementFrequent: action,
            setSkinTone: action,
            getEmojiData: action
        })

        
    }

    init() {
        console.log('AppStore.userId', AppStore.userId)
        console.log('UsersStore.currentUser', UsersStore.currentUser)
        console.log('CurrentUserStore', CurrentUserStore)
        const frequentData = CurrentUserStore?.meta?.frequentEmojis
        if (frequentData && frequentData.version === version) {
            this.frequent = frequentData.emojis
            this.skinTone = frequentData.skinTone
        }
    }

    updateFromData(emojiData) {
        if (!emojiData) return

        // Updates whatever fields are passed in individually
        const fields = ['emojis', 'skinTone']
        for (const f of fields) {
            if (emojiData[f] !== undefined) {
                switch (f) {
                    case 'emojis':
                        this.frequent = emojiData[f]
                        break
                    case 'skinTone':
                        this.skinTone = emojiData[f]
                        break
                    default:
                        break
                }
            }
        }
    }

    getFrequent(top = 5, includeCustom = true) {
        // return the top X most used emojis on this device
        const sortedFrequents = this.frequent.slice().sort((a, b) => b.usedCount - a.usedCount)
        if (includeCustom) {
            return sortedFrequents.slice(0, top)
        } else {
            // if includeCustom is false, filter out custom emojis before returning
            return sortedFrequents.filter(f => !f.id.includes(':')).slice(0, top)
        }
    }

    getFrequentIds = (top = 5) => {
        const tops = this.getFrequent(top)
        return tops.map(fc => fc.id.replace(/:/g, ''))
    }

    async incrementFrequent(text) {
        let emoji = null
        let emojiIdx = null
        const isCustom = text.charAt(0) === ':'

        if (isCustom) {
            emoji = text
            emojiIdx = this.frequent.findIndex(e => e.id === text)
        } else {
            emoji = getEmojiDataFromNative(text, 'apple', data)
            emojiIdx = this.frequent.findIndex(e => e.id === emoji.id)
        }

        if (emojiIdx !== -1) {
            this.frequent[emojiIdx].usedCount++
            this.frequent[emojiIdx].lastUsed = new Date()
        } else {
            this.frequent.push({
                id: emoji.id || emoji,
                usedCount: 1,
                lastUsed: new Date()
            })
        }

        // update server
        await CurrentUserStore.update({
            meta: {
                frequentEmojis: {
                    emojis: toJS(this.frequent),
                    skinTone: this.skinTone,
                    version: version
                }
            }
        })
    }

    async decrementFrequent(text) {
        let emoji = null
        let emojiIdx = null
        const isCustom = text.charAt(0) === ':'

        if (isCustom) {
            emoji = text
            emojiIdx = this.frequent.findIndex(e => e.id === text)
        } else {
            emoji = getEmojiDataFromNative(text, 'apple', data)
            emojiIdx = this.frequent.findIndex(e => e.id === emoji.id)
        }

        if (emojiIdx !== -1) {
            this.frequent[emojiIdx].usedCount--
        }

        // update server
        await CurrentUserStore.update({
            meta: {
                frequentEmojis: {
                    emojis: toJS(this.frequent),
                    skinTone: this.skinTone,
                    version: version
                }
            }
        })
    }

    async setSkinTone(skin) { // 1, 2, 3, 4, 5, 6
        if (!skin) {
            console.log('setSkinTone is missing the skin param (1-6)')
            return
        }
        this.skinTone = skin

        // update server
        await CurrentUserStore.update({
            meta: {
                frequentEmojis: {
                    emojis: toJS(this.frequent),
                    skinTone: skin,
                    version: version
                }
            }
        })
    }

    getEmojiData = (text) => { // text = native emoji, colon value (custom), or id (frequents)
        let emoji = null
        const isCustom = text.charAt(0) === ':'

        if (isCustom) {
            emoji = CurrentUserStore.selectedDepartment?.emojis.find(e => e.colons === text)
        } else {
            emoji = getEmojiDataFromNative(text, 'apple', data) || text
        }
        return emoji
    }
}

export default new EmojiStore()

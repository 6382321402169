import { TextWriteWidget } from './TextWidget'
import { SpacerWidget } from './SpacerWidget'
import { DateWriteWidget } from './DateWidget'
import { DropdownWriteWidget } from './DropdownWidget'
import { MediaWriteWidget } from './MediaWriteWidget'
import { UserWriteWidget } from './UserWidget'
import { CheckboxWriteWidget } from './CheckboxWidget'
import { ListWriteWidget } from './ListWidget'
import { MapWriteWidget } from './MapWidget'
import { Row } from './Row'
import { Form } from '@evertel/schema-parser'
import { UnknownWidget } from './UnknownWidget'
import { BulletinTypeWriteWidget } from './BulletinTypeWidget'
import { BulletinStatusWriteWidget } from './BulletinStatusWidget'
import { BulletinUrgentWriteWidget } from './BulletinUrgentWidget'

const WriteWidgets = {
    'bulletin_status': BulletinStatusWriteWidget,
    'bulletin_type': BulletinTypeWriteWidget,
    'bulletin_urgent': BulletinUrgentWriteWidget,
    'checkbox-group': CheckboxWriteWidget,
    'dropdown_yesno': DropdownWriteWidget,
    text: TextWriteWidget,
    spacer: SpacerWidget,
    date: DateWriteWidget,
    map: MapWriteWidget,
    checkbox: CheckboxWriteWidget,
    title: TextWriteWidget,
    media: MediaWriteWidget,
    dropdown: DropdownWriteWidget,
    list: ListWriteWidget,
    user: UserWriteWidget,
    row: Row,
    form: Form,
    unknown: UnknownWidget
}

export {
    WriteWidgets
}
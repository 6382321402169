import { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'

// evertel
import { Icon, Col, Badge, Row } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { useHistory, useLocation } from 'react-router-dom'
import { DepartmentAvatar } from '@evertel/web/department'
import { CurrentUserDepartmentsController, UnreadCountsState } from '@evertel/blue-user'
import { LeftGutterAppButton } from './elements'
import { DrawerController } from '@evertel/drawer'
import { FloatingMenu, MenuItem } from '@evertel/web/ui'

const LeftGutter = observer(() => {

    const session = useService(SessionState, [])
    const drawerController = useService(DrawerController, [])
    const currentUserDepartmentsController = useService(CurrentUserDepartmentsController, [])
    const unreadCounts = useService(UnreadCountsState, [])

    const { roomUnreadCountsByDept, roomUnreadUrgentsByDept, threadsMessageUnreadCount } = unreadCounts

    const totalUnreads = Object.values(roomUnreadCountsByDept).reduce((acc, val) => acc + val, 0) + threadsMessageUnreadCount

    useEffect(() => {
        document.title = `Evertel ${totalUnreads ? `(${totalUnreads})` : ''}`
    }, [totalUnreads])
    
    const history = useHistory()
    const location = useLocation()

    const onSwitchDepartment = (deptId: number) => {
        // set the new department as selected
        session.setSelectedDeparmentId(deptId)

        // get last path in that dept.
        const lastPath = session.previousPath

        if (['/admin', '/manage'].some(path => location.pathname.includes(path))) {
            return
        }

        if (lastPath) {
            // go to last path
            history.push(lastPath)
        } else {
            // if not last path, take them to default
            history.push('/landing')
        }
    }

    const getMessagePath = useCallback(() => {
        const pathname = history.location.pathname
    
        //if the user is already in the message section, then this should take them to landing
        const onRoomOrThread = pathname.includes('/room/') || pathname.includes('/thread/')
        if (onRoomOrThread) {
            return '/landing'
        }

        //if not it should go to the previous place they were in message section
        return session.previousRoomOrThread?.path || '/landing'

    }, [history.location, session.previousRoomOrThread])

    const maxDisplayOtherDepts = 3

    const recentDepartmentIds = session.recentDepartmentIds

    //the extra map is to get rid of errors about id maybe being undefined
    const otherDepts = (currentUserDepartmentsController.departmentsNotGuest?.filter(d => d.id !== session.selectedDepartmentId) || []) .map(d => ({ ...d, id: d.id as number, name: d.name as string }))

    // Custom sorting function
    const sortedDepts = otherDepts.sort((a, b) => {
        const aInRecent = recentDepartmentIds.indexOf(a.id)
        const bInRecent = recentDepartmentIds.indexOf(b.id)
  
        // 1. Sort by presence in recentDepartmentIds
        if (aInRecent !== -1 && bInRecent !== -1) {
            return aInRecent - bInRecent
        }
        if (aInRecent !== -1) return -1
        if (bInRecent !== -1) return 1
  
        // 2. Sort by urgent messages
        const aUrgent = roomUnreadUrgentsByDept[a.id] || false
        const bUrgent = roomUnreadUrgentsByDept[b.id] || false
        if (aUrgent && bUrgent) {
            return a.name.localeCompare(b.name)
        }
        if (aUrgent) return -1
        if (bUrgent) return 1
  
        // 3. Sort by non-urgent unread messages
        const aUnread = roomUnreadCountsByDept[a.id] || 0
        const bUnread = roomUnreadCountsByDept[b.id] || 0
        if (aUnread > 0 && bUnread > 0) {
            return a.name.localeCompare(b.name)
        }
        if (aUnread > 0) return -1
        if (bUnread > 0) return 1
  
        // 4. Sort remaining in alphabetical order
        return a.name.localeCompare(b.name)
    })

    const currentDeptCount =
        roomUnreadCountsByDept &&
        roomUnreadCountsByDept[session?.selectedDepartmentId] ?
            roomUnreadCountsByDept[session?.selectedDepartmentId] + threadsMessageUnreadCount
            :
            threadsMessageUnreadCount

    const hasUrgentMessages = roomUnreadUrgentsByDept[session?.selectedDepartmentId] || false

    return (
        <Col className="left-gutter">
            <PerfectScrollbar options={{ suppressScrollX: true }} style={{textAlign: 'center'}}>

                <FloatingMenu
                    label="Agencies"
                    renderLabel={() => (
                        <>
                            <Col
                                align="center"
                                className="dept-icons-wrapper">
                                <DepartmentAvatar
                                    departmentId={session.selectedDepartmentId as number}
                                    width={35}
                                    height={35}
                                    className="mb-4 active"
                                    //data-tooltip={dept.name}
                                    data-tooltip-pos="right"
                                />
                                {/* grab a max of 3 other depts */}
                                {sortedDepts?.slice(0, maxDisplayOtherDepts)?.map((dept) => 
                                    <Row key={dept.id} className="position-relative">
                                        <DepartmentAvatar
                                            key={dept.id}
                                            departmentId={dept.id as number}
                                            width={25}
                                            height={25}
                                            className="other-depts"
                                        />
                                        {(roomUnreadCountsByDept && roomUnreadCountsByDept[dept.id] > 0) &&
                                            <Badge
                                                color={roomUnreadUrgentsByDept[dept.id] ? 'danger' : 'warning'}
                                                size={10}
                                                position="top-end"
                                                shape="rounded-pill"
                                                className="position-absolute translate-middle"
                                            >
                                                {(roomUnreadCountsByDept[dept.id] > 200) ? '200+' : roomUnreadCountsByDept[dept.id]}
                                            </Badge>
                                        }
                                    </Row>
                                )}
                            </Col>
                            { sortedDepts?.length > maxDisplayOtherDepts &&
                                <Icon
                                    name="caret-down"
                                    color="white"
                                    type="solid"
                                    size={16}
                                    style={{ width: 25 }}
                                />                            
                            }
                        </>
                    )}
                >
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        {sortedDepts?.map((dept) => (
                            <MenuItem
                                key={dept.id}
                                label={dept.name||''}
                                onClick={() => onSwitchDepartment(dept.id as number)}>
                                <DepartmentAvatar
                                    departmentId={dept.id as number}
                                    width={25}
                                    height={25}
                                    className="mr-2"
                                    data-tooltip={dept.name}
                                    data-tooltip-pos="right"
                                />
                                {dept.name}
                                {(roomUnreadCountsByDept && roomUnreadCountsByDept[dept.id] > 0) &&
                                <Badge
                                    color={unreadCounts.getDeptRoomUnreads(dept.id).some((room) => room.urgent > 0) ? 'danger' : 'warning'}
                                    className="ml-2"
                                >
                                    {roomUnreadCountsByDept[dept.id]}
                                </Badge>
                                }
                            </MenuItem>
                        ))}
                        <MenuItem
                            icon={{name: 'plus-circle', size: 18, className: 'mr-2', style: {width: 25, textAlign: 'center'}}}
                            label='Manage My Agencies'
                            to={`/account/departments/${session.currentUserId}`}
                        />
                    </PerfectScrollbar>
                </FloatingMenu>

                <LeftGutterAppButton
                    to={getMessagePath()}
                    icon="envelope"
                    active={drawerController.activeSection === 'messages'}
                    label="Messages"
                    badgeCount={currentDeptCount}
                    hasUrgents={hasUrgentMessages}
                />

                <LeftGutterAppButton
                    to={`/everdocs`}
                    icon="file-alt"
                    label="EverDocs"
                    active={drawerController.activeSection === 'everdocs'}
                />
            </PerfectScrollbar>
        </Col>
    )
})

export { LeftGutter }

import React from 'react'
import { AllHTMLAttributes, ElementType, forwardRef, MouseEvent } from 'react'
import classNames from 'classnames'

export interface LinkProps extends AllHTMLAttributes<HTMLButtonElement|HTMLAnchorElement> {
    active?: boolean
    className?: string
    component?: string|ElementType
    disabled?: boolean
    href?: string
}

const Link = forwardRef<HTMLButtonElement|HTMLAnchorElement, LinkProps>(({
    children,
    active,
    className,
    component: Component = 'a',
    disabled,
    ...otherProps
}, ref) => {

    // TODO: remove duplicated classes ex. `active active` in `<ListGroupItem>`
    const _className = classNames(
        className,
        {
            active,
            disabled,
            'link': otherProps.onClick && !disabled,
            'cursor-disabled': disabled
        }
    )

    return (
        <Component
            ref={ref}
            className={_className}
            {...(active && { 'aria-current': 'page' })}
            {...(Component === 'a' && disabled && { 'aria-disabled': true, tabIndex: -1 })}
            {...(((Component === 'a' || Component === 'button') && !disabled) && {
                onClick: (event: MouseEvent<HTMLButtonElement|HTMLAnchorElement>) => {
                    event.preventDefault
                    otherProps.onClick && otherProps.onClick(event)
                }
            })}
            disabled={disabled}
            {...otherProps}>
            {children}
        </Component>
    )
})

export { Link }

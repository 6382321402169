import { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { IconName } from '@fortawesome/fontawesome-svg-core'
// evertel
import { Icon, Text, Badge, Col } from '@evertel/web/ui'
import { ThreadContext } from '../../Thread'

interface RightGutterProps {
    toggleAside: (option: string, intent: string) => void
    activeTab?: string
    roomNotificationsEnabled?: boolean
}


const RightGutter: React.FC<RightGutterProps> = observer(({
    toggleAside,
    activeTab,
    roomNotificationsEnabled,
    ...otherProps
}) => {

    const { threadController, setIsMessageSearchModalOpen } = useContext(ThreadContext)

    const showOption = (option: string) => {
        toggleAside(option, 'open')
    }

    return (

        <Col
            valign='top'
            className="right-gutter"
            {...otherProps}>
            <GutterItem
                id="search"
                onClick={() => setIsMessageSearchModalOpen(true)}
                icon="search"
                title="Search"
                activeTab={activeTab}
            />
            <GutterItem
                id="directory"
                onClick={() => showOption('directory')}
                icon="users"
                title="People"
                activeTab={activeTab}
            />
            <GutterItem
                id="media-search"
                onClick={() => showOption('media-search')}
                icon="file-alt"
                title="Files"
                activeTab={activeTab}
            />
        </Col>
    )
})


interface ItemProps {
    id: string
    onClick: () => void
    icon: IconName
    title: string
    badgeCount?: number
    badgeColor?: string
    activeTab?: string
    color?: string
}

const GutterItem: React.FC<ItemProps> = observer(({
    id,
    onClick,
    icon,
    title,
    badgeCount = 0,
    badgeColor = 'secondary',
    activeTab,
    color = ''
}) => {
    const _classNames = classNames(
        'right-gutter-items',
        { 'active': activeTab === id }
    )
    return (
        <button
            className={_classNames}
            onClick={onClick}
        >
            <div className="position-relative">
                <Icon
                    name={icon}
                    size={20}
                    color={color}
                />
                { (badgeCount > 0) &&
                    <Badge color={badgeColor} size={10} position="top-end" shape="rounded-pill" className="position-absolute translate-middle" >
                        {(badgeCount > 200) ? '200+' : badgeCount}
                    </Badge>
                }
            </div>
            <Text
                size={12}
                color={color}>
                {title}
            </Text>
        </button>
    )
})

export { RightGutter }
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { uniq } from 'lodash'
import { Col, Row } from '../layout'
import { Icon, Text } from '../elements'
import { IconName } from '@fortawesome/fontawesome-svg-core'

interface IconPickerProps {
    value?: IconName|string
    instructions?: string
    scrollHeight?: number
    iconSize?: number
    onChange: (hex: string) => void
}

const IconPicker: React.FC<IconPickerProps> = ({
    value = 'file',
    instructions = 'Select an icon...',
    scrollHeight = 300,
    iconSize = 20,
    onChange,
    ...otherProps
}) => {

    const [icon, setIcon] = useState<IconName|string>(value)

    useEffect(() => {
        onChange && onChange(icon)
    }, [icon])

    return (
            <Col {...otherProps}>
                <Row className="mb-2">
                    <Text>
                        {instructions}
                    </Text>
                </Row>
                {/* @ts-ignore */}
                <PerfectScrollbar style={{height: scrollHeight}}>
                    <Row className="flex-wrap">
                        {uniq(iconNames).sort().map((name, i) =>
                            <div
                                key={i}
                                className={classNames(
                                    'icon-swatch bg-light m-1 p-1',
                                    {
                                        'selected': value === name
                                    }
                                )}>
                                <Icon
                                    name={name as IconName}
                                    size={iconSize}
                                    fixedWidth
                                    className="p-1"
                                    onClick={() => setIcon(name as IconName)}
                                />
                            </div>
                        )}
                    </Row>
                </PerfectScrollbar>
            </Col>
        
    )
}

export {
    IconPicker
}

const iconNames = [
    'pen-to-square',
    'money-check-pen',
    'money-check-dollar-pen',
    'pen-nib',
    'pen',
    'pencil',
    'marker',
    'highlighter',
    'chart-simple',
    'signature',
    'scissors',
  'fill-drip', 
  'arrows-to-circle',  
  'wagon-covered', 
  'line-height', 
  'bagel', 
  'at', 
  'phone-arrow-up-right', 
  'trash-can', 
  'circle-l', 
  'head-vr', 
  'user-xmark', 
  'face-hand-yawn', 
  'gauge-simple-min', 
  'stethoscope', 
  'coffin', 
  'message', 
  'bowl-salad', 
  'info', 
  'robot-astromech', 
  'ring-diamond', 
  'fondue-pot', 
  'theta', 
  'face-hand-peeking', 
  'square-user', 
  'down-left-and-up-right-to-center', 
  'file-lines', 
  'wave-square', 
  'ring', 
  'building-un', 
  'dice-three', 
  'tire-pressure-warning', 
  'calendar-days', 
  'mp3-player', 
  'anchor-circle-check', 
  'tally-4', 
  'rectangle-history', 
  'building-circle-arrow-right', 
  'volleyball-ball', 
  'sun-haze', 
  'ufo', 
  'utensil-fork', 
  'arrows-up-to-line', 
  'mobile-signal', 
  'barcode-scan', 
  'folder-arrow-down', 
  'circle-minus', 
  'face-icicles', 
  'shovel', 
  'door-open', 
  'films', 
  'right-from-bracket', 
  'face-glasses', 
  'nfc', 
  'atom', 
  'soap', 
  'heart-music-camera-bolt', 
  'microphone-lines-slash', 
  'closed-captioning-slash', 
  'calculator-simple', 
  'bridge-circle-check', 
  'sliders-up', 
  'map-marker-minus', 
  'pump-medical', 
  'fingerprint', 
  'ski-boot', 
  'standard-definition',
  'hand-point-right', 
  'magnifying-glass-location', 
  'message-bot', 
  'step-forward', 
  'face-smile-beam',  
  'light-ceiling', 
  'message-exclamation', 
  'bowl-shaved-ice', 
  'square-x', 
  'utility-pole-double', 
  'flag-checkered', 
  'chevron-double-up', 
  'user-vneck', 
  'school-circle-exclamation', 
  'crop', 
  'angle-double-down', 
  'users-rectangle', 
  'people-roof', 
  'square-arrow-right', 
  'map-marker-plus', 
  'lightbulb-exclamation-on', 
  'people-line', 
  'beer-mug-empty', 
  'crate-empty', 
  'diagram-predecessor', 
  'transporter', 
  'calendar-circle-user', 
  'arrow-up-long', 
  'person-carry-box', 
  'fire-flame-simple', 
  'male', 
  'laptop', 
  'file-csv', 
  'menorah', 
  'union', 
  'chevron-double-left', 
  'circle-heart', 
  'truck-plane', 
  'record-vinyl', 
  'bring-forward', 
  'square-p', 
  'face-grin-stars', 
  'sigma', 
  'camera-movie', 
  'bong', 
  'clarinet', 
  'truck-flatbed', 
  'spaghetti-monster-flying', 
  'arrow-down-up-across-line', 
  'leaf-heart', 
  'house-building', 
  'cheese-swiss', 
  'utensil-spoon', 
  'jar-wheat', 
  'envelopes-bulk', 
  'file-circle-exclamation', 
  'bow-arrow', 
  'cart-xmark', 
  'xmark-hexagon', 
  'circle-h', 
  'merge', 
  'pager', 
  'cart-minus', 
  'address-book', 
  'pan-frying', 
  'grid', 
  'football-helmet', 
  'hand-love', 
  'trees', 
  'strikethrough', 
  'page', 
  'k', 
  'diagram-previous',
'compass',
'folder',
'book',
'briefcase',
'badge-check',
'business-time',
'phone-volume',
'tags',
'address-card',
'calculator',
'bullseye',
'glasses',
'vault',
'user-tie-hair-long',
'user-tie-hair',
'stapler',
'square-poll-vertical',
'square-poll-horizontal',
'square-phone',
'presentation-screen',
'scale-balanced',
'paperclip-vertical',
'person-chalkboard',
'note-sticky',
'notebook',
'magnifying-glass-chart',
'list-radio',
'folders',
'floppy-disk',
'chart-user',
'cake-candles',
'box-archive',
'file-user',
'file-lines',
'file-chart-pie',
'file-chart-column',
'envelope-open',
'coffee-pot',
'cloud-word',
'clipboard-question',
'chart-user',
'chart-pie',
'chart-line-up',
'chart-line-down',
'chart-line',
'cabinet-filing',
'bullhorn',
'briefcase-blank',
'badge-dollar',
'arrows-to-eye',
'arrows-to-dot',
'arrows-spin',
'bell',
'people-robbery',
'handcuffs',
'bell-school',
'bell-on',
'bell-exclamation',
'police-box',
'user-police',
'user-police-tie',
'siren',
'siren-on',
'building-shield',
'gun',
'gun-slash',
'scanner-keyboard',
'person-rifle',
'jet-fighter-up',
'jet-fighter',
'raygun',
'gavel',
'file-contract',
'file-excel',
'file-export',
'file-word',
'file-import',
'file-check',
'file-pdf',
'file-exclamation',
'file-zipper',
'file-xmark',
'file-video',
'file-shield',
'file-powerpoint',
'file-plus',
'file-pen',
'file-music',
'file-minus',
'file-magnifying-glass',
'file-lock',
'file-image',
'file-heart',
'file-csv',
'file-arrow-up',
'file-arrow-down',
'file-spreadsheet',
'file-waveform',
'file-user',
'file-signature',
'file-prescription',
'file-medical',
'file-invoice-dollar',
'page',
'photo-film',
'film',
'fire',
'fireplace',
'fire-smoke',
'fire-hydrant',
'fire-flame-simple',
'fire-flame-curved',
'fire-extinguisher',
'dumpster-fire',
'truck-field-un',
'truck-field',
'photo-film-music',
'house-fire',
'clock-five',
'video',
'palette',
'campfire',
'circle',
'sort',
'dragon',
'tower-observation',
'square-quarters',
'smoke',
'pie',
'person-running',
'mask-face',
'image-polaroid',
'hose-reel',
'hose',
'head-side-mask',
'clapperboard',
'candle-holder',
'headphones',
'ticket',
'speaker',
'waveform',
'tv-retro',
'star-sharp',
'drone',
'camera-cctv',
'poo',
'person-military-rifle',
'mosquito',
'toilet-paper-under',
'mobile-screen',
'face-smile',
'face-smile-wink',
'face-smile-relaxed',
'face-smile-horns',
'laptop-mobile',
'message-smile',
'comment-smile',
'location-smile',
'map-pin',
'map',
'leaf-maple',
'location-dot',
'location-check',
'globe',
'layer-group',
'street-view',
'road',
'location-crosshairs',
'location-arrow',
'book-atlas',
'earth-americas',
'compass-drafting',
'car-bus',
'house',
'magnifying-glass',
'image',
'music',
'heart',
'bomb',
'circle-info',
'car',
'umbrella',
'gift',
'bookmark',
'droplet',
'plus',
'eye',
'magnet',
'money-bill',
'lemon',
'key',
'tree',
'wifi',
'bicycle',
'flask',
'person',
'binoculars',
'mug-saucer',
'landmark',
'anchor',
'newspaper',
'flag',
'flag-checkered',
'rocket',
'restroom',
'ship',
'train',
'wrench',
'trophy',
'hospital',
'route',
'plug',
'recycle',
'dollar-sign',
'wheelchair',
'vest-patches',
'utensils',
'truck-medical',
'train-track',
'traffic-light-stop',
'taxi',
'square-parking',
'skull-cow',
'shoe-prints',
'person-walking-with-cane',
'paw',
'life-ring',
'graduation-cap',
'bridge-suspension',
'bed',
'sitemap',
'calendar-days',
'alarm-clock',
'calendar',
'clock',
'calendar-xmark',
'calendar-range',
'calendar-plus',
'calendar-clock',
'calendar-check',
'database',
'user-secret',
'sensor-triangle-exclamation',
'face-flushed',
'face-dizzy',
'bandage',
'user-graduate',
'face-head-bandage',
'water',
'satellite',
'glass-water',
'chart-waterfall',
'wave-pulse',
'percent',
'truck-droplet',
'tornado',
'sheet-plastic',
'hand-middle-finger',
'dungeon',
'tree-decorated',
'face-nauseated',
'arrows-rotate',
'route-interstate',
'certificate',
'masks-theater',
'suitcase-medical',
'note-medical',
'star-of-life',
'user-nurse',
'user-doctor',
'syringe',
'prescription-bottle',
'file-prescription',
'biohazard',
'stethoscope',
'virus',
'joint',
'receipt',
'tooth',
'staff-snake',
'smoking',
'skull',
'nose',
'id-card-clip',
'lips',
'lungs',
'heart-pulse',
'book-user',
'cannabis',
'bone-break',
'bone',
'bacteria',
'wind',
'volcano',
'hurricane',
'house-crack',
'burst',
'cloud-showers-heavy',
'bolt',
'cloud-bolt',
'gauge',
'car-on',
'car-burst',
'gas-pump',
'brake-warning',
'car-bolt',
'dog-leashed',
'moon',
'user-alien',
'star-shooting',
'eclipse',
'comet',
'alien',
'pig',
'spider-black-widow',
'monkey',
'dog',
'bee',
'duck',
'crow',
'worm',
'snake',
'turtle',
'mountains',
'axe',
'acorn',
'boot',
'flashlight',
'handshake','ribbon',
'dove',
'book-heart',
'chart-simple',
'balloons',
'robot',
'school',
'basketball-hoop',
'child',
'baby',
'puzzle-piece',
'snowman',
'teddy-bear',
'pinata',
'ice-cream',
'children',
'lollipop',
'family',
'candy',
'balloon',
'apple-whole',
'shirt',
'user-tie',
'socks',
'mustache',
'hat-santa',
'hat-cowboy',
'clothes-hanger',
'inbox',
'comment',
'walkie-talkie',
'hundred-points',
'face-frown',
'crystal-ball',
'circle-nodes',
'traffic-cone',
'hammer',
'ruler',
'user-helmet-safety',
'toolbox',
'screwdriver-wrench',
'person-digging',
'helmet-safety',
'frame',
'angle-90',
'cube',
'scribble',
'circle-dashed',
'cubes',
'broom',
'face-sad-cry',
'face-laugh-squint',
'face-scream',
'face-pouting',
'face-meh',
'face-eyes-xmarks',
'face-astonished',
'face-angry',
'sliders',
'check',
'bars',
'xmark',
'link',
'scissors',
'link-simple',
'command',
'check-double',
'atom-simple',
'utility-pole',
'outlet',
'fan',
'explosion',
'gas-pump',
'memo-pad',
'waffle',
'taco',
'donut',
'candy-cane',
'bacon',
'burger-lettuce',
'ghost',
'bat',
'mask',
'turkey',
'church',
'user-injured',
'person-falling',
'route-interstate',
'bullseye',
'user-crown',
'arrows-to-eye',
'tally',
'tilde',
'omega',
'pi',
'infinity',
'sigma',
'function',
'face-vomit',
'skull-crossbones',
'face-thermometer',
'hand-fist',
'ballot-check',
'award',
'republican',
'democrat',
'flag-usa',
'landmark-dome',
'ballot',
'question',
'asterisk',
'circle-exclamation',
'cross',
'yin-yang',
'star-of-david',
'jedi',
'hands-praying',
'coffin-cross',
'book-bible',
'user-bounty-hunter',
'user-astronaut',
'starship',
'hand-spock',
'flux-capacitor',
'lock',
'bug',
'whistle',
'shield-dog',
'road-spikes',
'hands-bound',
'diamond-exclamation',
'ban',
'badge-sheriff',
'hashtag',
'square-poll-horizontal',
'signal-stream',
'thumbs-up',
'thumbs-down',
'dumbbell',
'sportsball',
'person-running',
'hockey-mask',
'football',
'flag-pennant',
'baseball-bat-ball',
'list',
'rectangle-list',
'list-check',
'watch',
'snooze',
'drone-front',
'plane-up',
'robot-astromech',
'passport',
'heat',
'cactus',
'user',
'person',
'snowflake',
'sun',
'stars',
'rainbow',
'cloud-showers-heavy',
'clouds',
'notes',
'note',
'memo',
'square-quote'
]

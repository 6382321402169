import { injectable, decorate } from '@evertel/di'

class MediaVersionResolver {
    /**
     * Resolves smaller version suitable to size (width * height)
     *
     * @param width
     * @param height
     * @param versions Versions are ordered from smallest to largest, ending with the original
     */
    
    public resolve(
        width: number,
        height: number,
        versions: Array<{width: number, height: number}>
    ): number {
        const area = width * height

        // Select last version by default (original)
        let selected = versions.length - 1

        // Remove last one
        versions = versions.slice(0, -1)

        let i = versions.length - 1

        while (i >= 0) {
            const version = versions[i]

            // Smaller size version found?
            if (version.width * version.height < area) {
                break
            }

            // Select smaller but fit size version
            selected = i

            i--
        }

        return selected
    }
}

decorate(injectable(), MediaVersionResolver)

export {
    MediaVersionResolver
}

import React from 'react'
import { Dropdown, Text, Badge } from '@evertel/web/ui'
import Wrapper from './Wrapper'
import { SingleValue } from 'react-select'

const colors = {
    open: {
        text: '#ffffff',
        background: '#4DBD74'
    },
    'in-custody': {
        text: '#21262a',
        background: '#63C2DE'
    },
    located: {
        text: '#21262a',
        background: '#63C2DE'
    },
    cancelled: {
        text: '#21262a',
        background: '#F86C6B'
    }
}

const options = [
    {
        label: '',
        value: ''
    },
    {
        label: 'Open',
        value: 'open'
    },
    {
        label: 'Located',
        value: 'located'
    },
    {
        label: 'In Custody',
        value: 'in-custody'
    },
    {
        label: 'Cancelled',
        value: 'cancelled'
    }
]

type Value = SingleValue<{ label: string; value: string; }>

interface BulletinStatusProps {
    id: number,
    value: any,
    onChange: (id:number, value: Value) => void,
    title: string,
    description: string,
    required: boolean,
    errors: string[]
}

const BulletinStatusWriteWidget: React.FC<BulletinStatusProps> = ({
    id,
    value,
    onChange,
    title,
    description,
    required,
    errors
}) => {

    return (
        <Wrapper
            title={title}
            description={description}
            required={required}
            errors={errors}>
            <Dropdown
                value={value}
                onChange={(value) => {
                    onChange(id, value as Value)
                }}
                options={options}
            />
        </Wrapper>
    )
}


const BulletinStatusReadWidget = ({
    value
}: { value: 'open'|'in-custody'|'located'|'cancelled' }) => {

    if (!value) {
        return null
    }

    const option = options.find((option) => option.value === value)

    if (!option) {
        return null
    }

    return (
        <Badge
            color={colors[value]?.background}>
            <Text color={colors[value]?.text}>
                {option.label}
            </Text>
        </Badge>
    )
}

export {
    BulletinStatusWriteWidget,
    BulletinStatusReadWidget
}

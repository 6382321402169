
import { makeAutoObservable } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { BlueUserStore } from '../store'
import { FetchUserService } from '../services'
import { Api } from '@evertel/api'

class UserWidgetController {
    userId = 0

    constructor(
        private api: Api,
        private userStore: BlueUserStore,
        private fetchUserService: FetchUserService
    ) {
        makeAutoObservable(this)
    }

    async init(userId: number, documentId: number, filter?: any) {
        if (!userId) return
        
        this.userId = userId
        await this.fetchUserService.fetchSpecificUserFromDocument(documentId, userId)
    }

    get id() {
        return this.userId
    }
    
    get user() {
        const res = this.userStore.findById(this.userId)
        return res
        
    }

    get fullName() {
        if (!this.user) return undefined
        return this.user.firstName + ' ' + this.user.lastName
    }
}

decorate(injectable(), UserWidgetController)
decorate(inject(Api), UserWidgetController, 0)
decorate(inject(BlueUserStore), UserWidgetController, 1)
decorate(inject(FetchUserService), UserWidgetController, 2)

export { UserWidgetController }
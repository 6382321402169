import React from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { deal, Delegator } from '@evertel/schema-parser'

interface RowProps {
    id?: string,
    value: any,
    errors: any,
    onChange: () => void,
    items: any,
    className?: string,
    documentId?: number,
    schemaId?: number
}

const Row: React.FC<RowProps> = observer(({
    id,
    value: parentValue,
    errors: parentErrors,
    onChange,
    items,
    className,
    ...otherProps
}) => {

    //console.log('ROW', {parentValue}, {items}, otherProps)

    if (!items?.length) return null

    return (
        <div className={classNames('evertel-row', className)}>
            {items.map(({id: itemId, overrideId, ...itemProps}, i: number) => {
                //console.log('ROW ITEM', {itemId}, {parentValue}, {overrideId}, parentValue[overrideId], itemProps)

                // workaround for bad data in leagacy schema data structure
                if (parentValue && overrideId && parentValue[overrideId]) itemId = overrideId

                const itemValue = deal(parentValue, itemId)
                const itemErrors = deal(parentErrors, itemId)

                return (
                    <Delegator
                        key={i}
                        {...itemProps}
                        {...otherProps}
                        id={itemId}
                        value={itemValue}
                        errors={itemErrors}
                        onChange={onChange}
                        className="p-2"
                    />
                )
            })}
        </div>
    )
})

export { Row }
Row.displayName = 'Row (Schema)'

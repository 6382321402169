import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
// evertel
import { Col, Modal, ModalBody, Row, Text, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane, Icon } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { InviteTabs, OpenInviteProps } from './InviteContext'
import { ContactInviteForm, DepartmentInviteForm, RoomInviteForm } from './elements'
import { DepartmentController } from '@evertel/department'
import { formatNumber } from '@evertel/utils'
import { SendInvitesBody } from '@evertel/invites'
import { SelectedDepartmentAccessController } from '@evertel/departments-access'

interface InviteModalProps extends OpenInviteProps {
    visible: boolean,
    inviteBody?: SendInvitesBody,
    inviteType?: InviteTabs,
    invitedRooms?: { label: string, value: number }[],
    inviteDepartmentId?: number | undefined,
    onClose: () => void,
    onCloseDone: () => void
}

const InviteModal: React.FC<InviteModalProps> = observer(({
    visible,
    onClose,
    onCloseDone,
    inviteBody,
    inviteType,
    invitedRooms,
    inviteDepartmentId
}) => {

    const session = useService(SessionState, [])
    const departmentController = useService(DepartmentController, [])
    const departmentId = inviteDepartmentId || session.selectedDepartmentId
    const selectedDepartmentAccessController = useService(SelectedDepartmentAccessController, [departmentId])


    const [activeTab, setActiveTab] = useState<InviteTabs>(inviteType || 'member')

    useEffect(() => {
        if (!session.selectedDepartmentId) return

        selectedDepartmentAccessController.init(session.currentUserId)
        departmentController.init(departmentId)

        departmentController.fetchAvailableCapacities()

    }, [departmentController, selectedDepartmentAccessController, session.currentUserId, departmentId])

    useEffect(() => {
        setActiveTab(inviteType as InviteTabs)
    }, [inviteType])

    const tabs = [
        {
            id: 'member',
            name: 'Agency Member',
            description: 'Invite agency personnel',
            allowedCount: formatNumber(departmentController.capacities?.verifiedMemberCapacity),
            usedCount: formatNumber(departmentController.capacities?.verifiedMembersCount),
            allowedRoles: ['executive', 'management'],
            component: DepartmentInviteForm
        },
        {
            id: 'guest',
            name: 'Room Guest',
            description: 'Invite outside people',
            allowedCount: '',
            usedCount: '',
            allowedRoles: ['executive', 'management'],
            component: RoomInviteForm
        },
        {
            id: 'contact',
            name: 'Direct Contact',
            description: 'Invite to DM',
            allowedCount: '',
            usedCount: '',
            allowedRoles: ['executive', 'management', 'employee'],
            component: ContactInviteForm
        }
    ]

    const filteredTabs = tabs.filter(tab => tab.allowedRoles.includes(selectedDepartmentAccessController.role as string))

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCloseDone={onCloseDone}
            size="lg">
            <ModalHeader
                title={`Invite People to ${departmentController.department.name}`}
            />
            <ModalBody className="">
                <Nav
                    variant="box"
                    role="tablist">
                    {filteredTabs.map(tab =>
                        <NavItem
                            key={tab.id}
                            className={(activeTab !== tab.id) ? 'bg-light' : ''}>
                            <NavLink
                                active={activeTab === tab.id}
                                className="p-4"
                                onClick={() => setActiveTab(tab.id as InviteTabs)}>
                                <Col>
                                    <Row>
                                        <Icon
                                            name="check-circle"
                                            type={(activeTab === tab.id) ? 'solid' : 'light'}
                                            color={(activeTab === tab.id) ? 'success' : 'muted'}
                                            size={18}
                                            className="mr-2"
                                        />
                                        <Text>
                                            {tab.name}
                                        </Text>
                                    </Row>
                                    <Text
                                        color="muted"
                                        className="mt-3"
                                        style={{
                                            fontWeight: 'normal'
                                        }}>
                                        {tab.description}
                                    </Text>
                                    <Text
                                        color="muted"
                                        className="mt-3"
                                        style={{
                                            fontWeight: 'normal'
                                        }}>
                                        {(tab.usedCount && tab.allowedCount) && `${tab.usedCount} / ${tab.allowedCount}`}
                                    </Text>
                                </Col>
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
                {/* content -------------------------------------------------------------- */}
                <TabContent>
                    {tabs.map(tab =>
                        <TabPane
                            key={tab.id}
                            role="tabpanel"
                            visible={activeTab === tab.id}
                            className="mt-4">
                            <tab.component
                                currentUserId={session.currentUserId}
                                departmentId={departmentId}
                                inviteBody={inviteBody}
                                invitedRooms={invitedRooms}
                                onClose={onClose}
                                role={selectedDepartmentAccessController.role as string}
                            />
                        </TabPane>
                    )}
                </TabContent>
            </ModalBody>
        </Modal>
    )
})

export { InviteModal }

import React, { ChangeEvent, useContext, useState } from 'react'
import { Col, Row, Button, Input, Icon } from '@evertel/web/ui'
import { observer } from 'mobx-react'
import { positionElementRelativeToTarget } from '@evertel/utils'
import { MessageWallContext } from '../../../MessageWallContext'

interface ForwardMessageInputProps {
    message: string,
    isUrgent: boolean,
    toggleUrgent: () => void,
    onChangeMessage: (message: string) => void
}

const ForwardMessageInput: React.FC<ForwardMessageInputProps> = observer(({
    message,
    isUrgent,
    toggleUrgent,
    onChangeMessage
}) => {

    const { setEmojiPickerState, emojiPickerState } = useContext(MessageWallContext)

    const [caretOffset, setCaretOffset] = useState(1)

    const onSelectEmoji = (emoji: any) => {
        setEmojiPickerState({
            visible: false,
            params: {}
        })

        const messageChange = message + emoji.native

        onChangeMessage(messageChange)
        setCaretOffset(caretOffset + emoji.native.length)
    }

    const showEmojiModal = () => {
        positionElementRelativeToTarget('#emoji-picker', '#forwardMessageInput', 'bottom')
        setEmojiPickerState({
            visible: true,
            params: {
                onSelectEmoji: onSelectEmoji
            }
        })
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChangeMessage(e.target.value)
    }

    return <div 
        className="p-2 post-form reply bg-light"
        >
        <Row>
            <Input
                placeholder="Add a custom message (optional)"
                value={message}
                onChange={onChange}
            />
        </Row>
        <Row>
            <Col className="post-tools text-left pr-4 py-1">
                <span data-tut="tour-posttools">
                    <Button color="link" onClick={toggleUrgent} size="lg" className="py-0 px-1 mr-1">
                        <Icon name="exclamation-triangle" color={isUrgent ? "danger" : "link"} />
                    </Button>
                    <Button color="link" onClick={showEmojiModal} size="lg" className="p-0">
                        <Icon name="smile" color={emojiPickerState?.visible ? "warning" : "link"}  />
                    </Button>
                </span>
            </Col>
        </Row>
    </div>
})

export {
    ForwardMessageInput
}
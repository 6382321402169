//==============================================
// PLURALIZES ANY STRING IF NEEDED
//==============================================
export function pluralize(count: number, noun: string, suffix = 's'): string {
    return (count !== 1) ? noun + suffix : noun
}

//==============================================
// WEB ONLY! COPY TO CLIPBOARD
//==============================================
export function copyToClipboard(str: string): void {
    if (!navigator.clipboard) {
        copyToClipboardFallback(str)
        return
    }
    navigator.clipboard.writeText(str).then(() => {
        // do nothing
    }, (err) => {
        console.error('Could not copy text: ', err)
    })
}

//==============================================
// WEB ONLY. COPY TO CLIPBOARD FALLBACK
//==============================================
export function copyToClipboardFallback(str: string) {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()

    try {
        document.execCommand('copy')
    } catch (err) {
        console.error('Could not copy text: ', err)
    }

    document.body.removeChild(el)
}

//==============================================
// TRUNCATE STRING. ADD ELLIPSIS
//==============================================
export function truncate(str: string, numberOfLetters: number): string {
    if (!str) return ''
    if (!numberOfLetters) return str
    return (str.length > numberOfLetters) ? str.substring(0, numberOfLetters-1) + '...' : str
}

//==============================================
// CONVERT STRING TO HSL COLOR
//==============================================
export function stringToHslColor(str: string) {
    if (!str) return 'hsl(0, 0%, 83%)' // light gray (default)

    let hash = 0

    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    const h = hash % 360

    return 'hsl(' + h + ', 70%, 40%)' // hue, saturation, lightness
}

export function gradientBackground(str: string) {
    const hslColor = stringToHslColor(str)
    return `linear-gradient(125deg, rgba(255,255,255,0.03) 0%, rgba(0,0,0,0.03) 67%), ${hslColor}`
}

import { observer } from 'mobx-react'
import { Icon, Text, Col, Row, Badge } from '@evertel/web/ui'
import { IconName } from '@fortawesome/fontawesome-common-types'
import { HTMLAttributes } from 'react'
import { useService } from '@evertel/di'
import { CurrentUserController } from '@evertel/blue-user'
import classNames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'


interface LeftGutterAppButtonProps extends LinkProps {
    label: string,
    icon: IconName,
    active?: boolean,
    badgeCount?: number,
    hasUrgents?: boolean
}


const LeftGutterAppButton: React.FC<LeftGutterAppButtonProps> = observer(({
    label,
    icon,
    active,
    to,
    badgeCount = 0,
    hasUrgents = false,
    ...otherProps
}) => {
    const currentUserController = useService(CurrentUserController, [])
    const userMeta = currentUserController.user?.meta as any
    
    return (
        <Link
            to={to}
            // align="center"
            className={classNames('app-button flex-col align-items-center', { 'active': active })}
            {...otherProps}>
            <Row key={'messages'} className="position-relative">
                <Icon
                    name={icon}
                    size={22}
                    color={(active) ? 'secondary' : 'muted'}
                    type={(active) ? 'solid' : 'light'}
                    fixedWidth
                />
                { (badgeCount > 0) &&
                    <Badge
                        color={hasUrgents? 'danger' : 'warning'}
                        textColor='black'
                        size={10}
                        position="top-end"
                        shape="rounded-pill"
                        className="position-absolute translate-middle"
                    >
                        {(badgeCount > 200) ? '200+' : badgeCount}
                    </Badge>
                }
            </Row>
            <Text
                size="smaller"
                color={(active) ? 'secondary' : 'muted'} >
                {label}
            </Text>
        </Link>
    )
})

export { LeftGutterAppButton }

import { decorate, injectable, inject } from '@evertel/di'
import { RoomMessagesStore } from './RoomMessagesStore'
import { ThreadMessagesStore } from './ThreadMessagesStore'
import { RoomStore } from './RoomStore'
import { ThreadStore } from './ThreadStore'
import { UserSettingsStore } from './UserSettingsStore'
import { ChildDepartmentStore } from './ChildDepartmentStore'
import { DocumentSchemaStore } from './DocumentSchemaStore'
import { action, makeObservable, runInAction } from 'mobx'

class StoreManager {
    constructor(
        private roomMessagesStore: RoomMessagesStore,
        private threadMessagesStore: ThreadMessagesStore,
        private roomStore: RoomStore,
        private threadStore: ThreadStore,
        private userSettingsStore: UserSettingsStore,
        private childDepartmentStore: ChildDepartmentStore,
        private documentSchemaStore: DocumentSchemaStore
    ) {

        makeObservable(this, {
            clearAllStores: action
        })

    }

    clearAllStores() {
        this.roomMessagesStore.clear()
        this.threadMessagesStore.clear()
        this.roomStore.clear()
        this.threadStore.clear()
        this.userSettingsStore.clear()
        this.childDepartmentStore.clear()
        this.documentSchemaStore.clear()
    }
}

decorate(injectable(), StoreManager)
decorate(inject(RoomMessagesStore), StoreManager, 0)
decorate(inject(ThreadMessagesStore), StoreManager, 1)
decorate(inject(RoomStore), StoreManager, 2)
decorate(inject(ThreadStore), StoreManager, 3)
decorate(inject(UserSettingsStore), StoreManager, 4)
decorate(inject(ChildDepartmentStore), StoreManager, 5)
decorate(inject(DocumentSchemaStore), StoreManager, 6)

export { StoreManager }
import { ContainerModule } from '@evertel/di'
import { DeviceState } from './DeviceState'

const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(DeviceState).toSelf().inSingletonScope()
    })
]

export {
    DI_MODULES
}
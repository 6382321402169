import React from 'react'
import {ElementType, DOMAttributes, ReactNode, forwardRef} from 'react'
import classNames from 'classnames'
import { CSSProperties } from 'styled-components'


export type TextProps = {
    tag?: ElementType,
    bold?: boolean,
    heavy?: boolean,
    italic?: boolean,
    color?: string,
    onClick?: DOMAttributes<ElementType>['onClick'],
    children: ReactNode,
    className?: string,
    size?: 'small'|'smaller'|'large'|'larger'|'largest'|number,
    style?: CSSProperties
}

const fsMap: string[] = ['largest', 'larger', 'large', 'normal', 'small', 'smaller']

const Text = forwardRef<HTMLParagraphElement, TextProps>(({
    onClick,
    tag,
    bold,
    heavy,
    italic,
    size,
    color,
    className,
    children,
    style,
    ...otherProps
}, ref) => {

    const Tag: ElementType = tag || 'span'
    let fontSize: number|null = null

    if (size && typeof size === 'number') fontSize = size

    const _className = classNames(
        {
            'cursor-pointer': onClick,
            [`text-${color}`]: color,
            [`fs-${fsMap.findIndex(s => s === size)+1}`]: size && typeof size === 'string',
            'font-italic': italic,
            'font-weight-bold': bold,
            'weight-900': heavy
        },
        className)

    return (
        // @ts-ignore
        <Tag
            ref={ref}
            onClick={onClick}
            className={_className}
            style={{ ...style, fontSize }}
            {...otherProps}>
            {children}
        </Tag>
    )
})

export { Text }

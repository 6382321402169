import React, { useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@evertel/web/ui'
// evertel
import { useConfirm } from '@evertel/web/hooks'


const Confirm = () => {
    const {
        title = '',
        message = '',
        isOpen = false,
        proceed,
        cancel,
        cancelButton,
        acceptButton,
        headerClassName
    } = useConfirm()

    useEffect(() => {
        // allow user to accept with Enter key...
        // or cancel with Escape key
        const handleKeydown = (e) => {
            if (proceed && isOpen && e.key === 'Enter') {
                proceed()
            }
            if (cancel && isOpen && e.key === 'Escape') {
                cancel()
            }
        }

        window.addEventListener('keydown', handleKeydown)

        return () => window.removeEventListener('keydown', handleKeydown)

    }, [proceed, cancel, isOpen])

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader
                title={title || 'Please confirm...'}
                className={(headerClassName)}
            />
            <ModalBody>
                <div dangerouslySetInnerHTML={{__html: message}} />
            </ModalBody>
            <ModalFooter align="center">
                <Button
                    color={cancelButton?.color || 'muted'}
                    size="lg"
                    className="mr-3"
                    onClick={cancelButton?.onClick || cancel}
                    {...cancelButton}>
                    {cancelButton?.label || 'Cancel'}
                </Button>
                <Button
                    color={acceptButton?.color || 'success'}
                    size="lg"
                    onClick={acceptButton?.onClick || proceed}
                    {...acceptButton}>
                    {acceptButton?.label || 'Ok'}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default Confirm
Confirm.displayName = 'Confirm Modal'

import React from 'react'
import { forwardRef } from 'react'
import classNames from 'classnames'
import { NavLink, NavLinkProps } from './NavLink'

const NavItem = forwardRef<HTMLLIElement, NavLinkProps>(({
    children,
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('nav-item', className)

    if (otherProps.href || otherProps.to) {
        children = (
            <NavLink
                className={className}
                {...otherProps}>
                {children}
            </NavLink>
        )
    }
    return (
        <li
            ref={ref}
            className={_className}>
            {children}
        </li>
    )
})

export { NavItem }
NavItem.displayName = 'NavItem'

import React, { HTMLAttributes, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
    Col,
    Row,
    InfoBox,
    RoomSearchSelect,
    Label,
    Button,
    useUI
} from '@evertel/web/ui'
import { InviteByEmailForm } from './InviteByEmailForm'
import { SendInvitesBody, SendInvitesController } from '@evertel/invites'
import { useService } from '@evertel/di'
import { APIDataRoom } from '@evertel/types'

interface RoomInviteFormProps extends HTMLAttributes<HTMLDivElement|HTMLSpanElement> {
    currentUserId: number,
    departmentId: number,
    inviteBody?: SendInvitesBody,
    invitedRooms?: {label: string, value: number}[],
    onClose: () => void,
    role?: string
}

const RoomInviteForm: React.FC<RoomInviteFormProps> = observer(({
    currentUserId,
    departmentId,
    inviteBody: initialBody,
    invitedRooms: initialRooms,
    onClose,
    role,
    ...otherProps
}) => {

    const sendInvitesController = useService(SendInvitesController, [])

    const { addToast } = useUI()

    const [isLoading, setIsLoading] = useState(false)
    const [inviteBody, setInviteBody] = useState<SendInvitesBody>(initialBody as SendInvitesBody)
    const [invitedRooms, setInvitedRooms] = useState(initialRooms || [])
    const [error, setError] = useState<string>()

    const send = async () => {
        const invitedRoomIds = invitedRooms?.map((r: any) => r.value)
        console.log(invitedRoomIds)

        if (!inviteBody?.to?.length || !inviteBody?.roles?.length || !invitedRoomIds?.length) {
            setError('Please make sure you have at least one invite and room selected')
            return
        }

        setIsLoading(true)

        try {
            await sendInvitesController.sendRoomInvite(invitedRoomIds as number[], inviteBody as SendInvitesBody)

            addToast({
                color: 'success',
                message: 'Your invites have been sent!'
            })

            onClose()

        } catch (error: any) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }

        setIsLoading(false)
    }

    const searchScope = useMemo(()=> role === 'executive' ?'department':'managed', [role])

    return (
        <Col {...otherProps}>
            <InfoBox color="warning">
                Guests are non-employees that you want to collaborate with (e.g. people from other agencies).
                They can only access the messages, files and members of the rooms invited to and nothing else.
            </InfoBox>
            {(error) &&
                <InfoBox
                    color="danger"
                    className="fadeIn">
                    {error}
                </InfoBox>
            }
            <Label
                text="Rooms to invite to"
                className="mt-3"
            />
            <RoomSearchSelect
                searchScope={searchScope}
                departmentId={departmentId}
                isMulti
                placeholder="Search and select from the rooms you manage..."
                onSelect={setInvitedRooms as (room: APIDataRoom[]) => void}
                selected={invitedRooms as APIDataRoom[]}
            />
            <InviteByEmailForm
                departmentId={departmentId}
                className="mt-4"
                inviteType="room"
                showPublicSearch
                onChange={setInviteBody}
            />
            <Row
                align="center"
                className="mt-4"
            >
                <Button
                    color="success"
                    loading={isLoading}
                    onClick={send}>
                    Send Invites
                </Button>
            </Row>
        </Col>
    )
})

export { RoomInviteForm }

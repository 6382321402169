import { DragUpdate } from 'react-beautiful-dnd'
import { SchemaProps } from '@evertel/types'

//***************************
const devMode = false
//***************************

export interface ResponderProps {
    action: string,
    params: any[]
}

export const onDragEndHandler = (
    result: DragUpdate,
    schema: SchemaProps
): ResponderProps[]|undefined => {

    const { destination, source, draggableId, combine } = result

    const actions = []
    const droppedItem = draggableId
    const droppedType = getItemType(result, schema)
    const draggedFrom = source.droppableId
    const droppedOn = destination?.droppableId
    const isDroppingItemOnBoard = droppedOn === 'board'
    const isAddingNewFieldToRow = combine?.draggableId?.includes('row-')
    const isMovingFieldToRow = destination?.droppableId.includes('row-drop-') || combine?.droppableId.includes('row-drop-')

    if (devMode) {
        console.log(
            {droppedItem},
            {droppedType},
            {draggedFrom},
            {droppedOn},
            {isDroppingItemOnBoard},
            {isAddingNewFieldToRow},
            {isMovingFieldToRow}
        )
    }

    // =======================================
    // dropping a new item on a row (combining)
    // =======================================
    if (isAddingNewFieldToRow) {
        const targetRowNum = combine?.draggableId.split('-').pop()
        const targetRowIndex = (targetRowNum || targetRowNum === '0') && parseInt(targetRowNum, 10)
        const isTargetRowEmpty = (schema.layouts.full[targetRowIndex]?.items?.length) ? false : true

        if (devMode) {
            console.log({targetRowNum}, {targetRowIndex}, {isTargetRowEmpty})
        }

        if (droppedType === 'row') {
            // we cannot combine rows together, reject this
            actions.push({
                action: 'toast',
                params: [{
                    color: 'warning',
                    title: 'Oops!',
                    message: 'You cannot add a row to another row'
                }]
            })
        } else if (droppedType === 'list' || droppedType === 'spacer') {
            if (!isTargetRowEmpty) {
                // we cannot combine a list (sub-forms) with other items in a row, reject this
                actions.push({
                    action: 'toast',
                    params: [{
                        color: 'warning',
                        title: 'Oops!',
                        message: 'You can only add "Sub Forms" and "Spacers" between rows or to empty rows'
                    }]
                })
            } else {
                // add new list (sub-form) to (empty) row
                actions.push({
                    action: 'addListToRow',
                    params: [
                        targetRowIndex,
                        droppedType
                    ]
                })
            }
        } else {
            // all other item types
            const targetRowHasList = !!schema.layouts.full[targetRowIndex].items?.find((i: any) => i.type === 'list' || i.type?.charAt(0) === '&')
            const targetRowHasSpacer = !!schema.layouts.full[targetRowIndex].items?.find((i: any) => i.type?.includes('spacer'))

            if (devMode) {
                console.log({targetRowHasList}, {targetRowHasSpacer})
            }

            if (targetRowHasList || targetRowHasSpacer) {
                // we cannot combine items with a list (sub-form) or a spacer in a row, reject this
                actions.push({
                    action: 'toast',
                    params: [{
                        color: 'warning',
                        title: 'Oops!',
                        message: 'You cannot combine fields and a "Sub Form" or "Spacer" in the same row. To add fields to the "form", click on the "form"'
                    }]
                })
            } else {
                // add new field to row
                actions.push({
                    action: 'addFieldToRow',
                    params: [
                        targetRowIndex,
                        droppedType
                    ]
                })
            }
        }
    }

    // =======================================
    // moving a field from one row to another
    // =======================================
    if (isMovingFieldToRow) {
        const targetRowNum = destination?.droppableId.split('-').pop()
        const targetRowIndex = (targetRowNum || targetRowNum === '0') && parseInt(targetRowNum, 10)
        const oldRowNum = source?.droppableId?.split('-').pop()
        const oldRowIndex = (oldRowNum || oldRowNum === '0') && parseInt(oldRowNum, 10)
        const isTargetRowEmpty = (schema.layouts.full[targetRowIndex]?.items?.length) ? false : true
        const targetRowHasList = (schema.layouts.full[targetRowIndex].items?.find((i: any) => i.type === 'list' || i.type?.charAt(0) === '&')) ? true : false
        const targetRowHasSpacer = (schema.layouts.full[targetRowIndex].items?.find((i: any) => i.type?.includes('spacer'))) ? true : false

        if (devMode) {
            console.log(
                {targetRowNum},
                {targetRowIndex},
                {isTargetRowEmpty},
                {targetRowHasList},
                {targetRowHasSpacer},
                {oldRowNum},
                {oldRowIndex}
            )
        }

        if (!schema.layouts.full[targetRowIndex].items) {
            // defense for outdated v1 structure
            actions.push({
                action: 'toast',
                params: [{
                    color: 'warning',
                    title: 'Oops!',
                    message: 'You cannot combine other items with this row'
                }]
            })
        } else if ((droppedType === 'list' && !isTargetRowEmpty) || targetRowHasList || targetRowHasSpacer) {
            // we cannot combine a list (subforms) with other items in a row, reject this
            actions.push({
                action: 'toast',
                params: [{
                    color: 'warning',
                    title: 'Oops!',
                    message: 'You cannot combine "sub forms" or "spacers" with other elements in a row'
                }]
            })
        } else {
            // move field (or list/subform to empty row)
            actions.push({
                action: 'moveFieldToRow',
                params: [
                    oldRowIndex,
                    targetRowIndex,
                    (droppedType === 'list') ? `&${droppedItem}` : `#${droppedItem}`
                ]
            })
        }
    }

    // ==============================================================
    // dropping item directly on the board (includes reordering rows)
    // ==============================================================
    if (isDroppingItemOnBoard) {
        const newRowIndex = destination?.index

        if (devMode) {
            console.log({newRowIndex})
        }
        
        if (droppedType === 'row') {
            if (draggedFrom === 'board') {
                // reordering rows
                const oldRowIndex = source.index

                if (devMode) {
                    console.log({oldRowIndex})
                }

                // if dropping a row FROM the board, we are reordering rows
                actions.push({
                    action: 'updateRow',
                    params: [
                        oldRowIndex,
                        newRowIndex
                    ]
                })
            }
            if (draggedFrom === 'toolbox') {
                // if dropping a row from the toolbox, we are adding an empty row
                actions.push({
                    action: 'addRow',
                    params: [
                        newRowIndex
                    ]
                })
            }
        } else {
            // if dropping any non-row item on the board

            // (1) add a new row
            actions.push({
                action: 'addRow',
                params: [
                    newRowIndex
                ]
            })

            // (2) add the new item to the new row
            if (droppedType === 'list') {
                // add list to the row we just created
                actions.push({
                    action: 'addListToRow',
                    params: [
                        newRowIndex,
                        droppedType
                    ]
                })
            } else {
                // add item to the row we just created
                actions.push({
                    action: 'addFieldToRow',
                    params: [
                        newRowIndex,
                        droppedType
                    ]
                })
            }
        }
    }

    return actions
}

const getItemType = (result: DragUpdate, schema: SchemaProps): string|undefined => {
    const { draggableId, source, combine } = result

    if (draggableId.includes('list')) {
        return 'list'
    }

    if (schema.layouts.full.find((r: any) => r.items && (r.items[0]?.type === 'list' || r.items[0]?.type?.charAt(0) === '&') && r.items[0]?.id === draggableId)) {
        return 'list'
    }

    if (source.droppableId === 'toolbox' || !combine) {
        return draggableId.split('-')[0] 
    }
    return undefined
}
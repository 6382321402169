import { makeAutoObservable, runInAction } from 'mobx'
import { decorate, inject, injectable } from 'inversify'
import { Api } from '@evertel/api'
import { APIDataVerifiedEmailDomain } from '@evertel/types'



class VerifiedEmailDomainController {
    
    verifiedDomains: APIDataVerifiedEmailDomain[] = []
    isLoading = false
    searchTerm = ''
    page = 1   
    verifiedDomainsCount = 0 
    limit = 100


    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init = () => {
        this.verifiedDomains = []
        this.verifiedDomainsCount = 0
        this.page = 1
        this.searchTerm = ''
    }

    setSearchTerm = (value: string) => {
        this.searchTerm = value
        this.page = 1
        this.verifiedDomains = []
        this.verifiedDomainsCount = 0
        this.getAllVerifiedEmailDomains()
    }

    setPage = (pageNumber: number) => {
        this.page = pageNumber
        this.getAllVerifiedEmailDomains()
    }

    getCount = async () => {
        const response = await this.api.Routes.VerifiedEmailDomain.getCount(this.filter.where)

        runInAction(() => {
            this.verifiedDomainsCount = response?.count ?? 0
        })

        return response?.count ?? 0
    }

    getAllVerifiedEmailDomains = async () => {
        let count = this.verifiedDomainsCount
        let results: APIDataVerifiedEmailDomain[] = []

        runInAction(() => {
            this.isLoading = true
        })

        if (!this.verifiedDomainsCount) {
            count = await this.getCount()
        }

        if (count > this.verifiedDomains?.length) {
            results = await this.api.Routes.VerifiedEmailDomain.get(this.filter)
        }



        runInAction(() => {
            this.verifiedDomains = results     
            this.isLoading = false
        })

    }

    loadNextPage = () => {
        this.page = this.page + 1
        this.getAllVerifiedEmailDomains()
    }

    getVerifiedEmailDomain = async (domain: string, filter?: any) => {
        return await this.api.Routes.VerifiedEmailDomain.getById(domain, filter)
    }

    fetchDepartmentsByDomain = async (domain: string, filter: any) => {
        return await this.api.Routes.VerifiedEmailDomain.getDepartments(domain, {
            ...filter,
            include: 'publicMedia'
        })
    }

    fetchDepartmentsByDomainCount = async (domain: string, where: any) => {
        await this.api.Routes.VerifiedEmailDomain.getDepartmentsCount(domain, where)
    }

    createVerifiedEmailDomain = async (data: any) => {
        runInAction(() => {
            this.isLoading = true
        })

        const response = await this.api.Routes.VerifiedEmailDomain.post(data)

        runInAction(() => {
            if (response) {
                //must create new because push doesn't trigger updates
                this.verifiedDomains = [response, ...this.verifiedDomains]
                this.verifiedDomainsCount++
            }
            this.isLoading = false                
        })

        return response 
    }

    deleteVerifiedEmailDomain = async (domain: string) => {
        runInAction(() => {
            this.isLoading = true
        })
        const response = await this.api.Routes.VerifiedEmailDomain.delById(domain)

        runInAction(() => {
            if (response.count) {
                this.verifiedDomains = this.verifiedDomains.filter(d => d.id !== domain)
                this.verifiedDomainsCount--
            }
            this.isLoading = false
        })

        return response
    }

    get filter() {
        return {
            where: {
                and: [
                    {
                        or: [
                            { id: { like: `%${this.searchTerm}%` }}
                        ]
                    }
                ]
            },
            order: 'id ASC',
            limit: this.limit,
            skip: (this.page - 1) * this.limit
        }
    }

}

decorate(injectable(), VerifiedEmailDomainController)
decorate(inject(Api), VerifiedEmailDomainController, 0)

export { VerifiedEmailDomainController }
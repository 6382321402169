/* eslint-disable @typescript-eslint/ban-ts-comment */
import { makeAutoObservable, runInAction } from 'mobx'
// evertel
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataInvite } from '@evertel/types'
import { SessionState } from '@evertel/session'
import moment from 'moment'

class DepartmentInviteLinkController {
    departmentId: number | undefined
    departmentInviteLinks: APIDataInvite[] = []
    _filter = undefined

    constructor(
        private api: Api,
        private session: SessionState
    ) {
        makeAutoObservable(this)
    }

    init = async (departmentId?: number, filter?: any) => {

        this.departmentId = departmentId ?? this.session.selectedDepartmentId

        this._filter = filter

        await this.fetchDepartmentInviteLinks()
    }

    setFilter = (filter: any) => {
        this._filter = filter
    }

    fetchDepartmentInviteLinks = async () => {
        // @ts-ignore
        const departmentInviteLinks = await this.api.Routes.Department.getInvites(this.departmentId, this.filter)
        
        runInAction(() => {
            this.departmentInviteLinks = departmentInviteLinks
        })
    }

    createDepartmentInviteLink = async () => {
        if (!this.departmentId) return

        const link = await this.api.Routes.Department.postInvites(this.departmentId,
            30, // expiresInDays
            'employee' // setConsumerRole,
        )

        runInAction(() => {
            this.departmentInviteLinks = [...this.departmentInviteLinks, link]
        })

        return link
    }

    deleteDepartmentInviteLink = async (linkId: any) => {
        if (!linkId || !this.departmentId) return

        await this.api.Routes.Department.delInvitesById(this.departmentId, linkId)

        runInAction(() => {
            this.departmentInviteLinks = this.departmentInviteLinks.filter((l: any) => l.id !== linkId)
        })
    }

    get currentLink() {
        // grab the link with the greatest expiration date        
        const dates = this.departmentInviteLinks?.map(l => moment(l.expiresDate)) || []
        return this.departmentInviteLinks?.find(link => moment(link.expiresDate).valueOf() === moment.max(dates).valueOf())
    }

    get filter() {
        return {
            where: {
                type: 'DEPARTMENT_INVITE',
                setConsumerRole: 'employee',
                status: 'valid',
                isPersistent: true,
                expiresDate: { gt: new Date() }
            },
            order: 'id DESC',
            limit: 100
        }
    }
}

decorate(injectable(), DepartmentInviteLinkController)
decorate(inject(Api), DepartmentInviteLinkController, 0)
decorate(inject(SessionState), DepartmentInviteLinkController, 1)

export { DepartmentInviteLinkController }

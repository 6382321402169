export const schemaItemTypes = [
    {
        name: 'Row',
        icon: 'table-rows',
        id: 'row'
    },
    {
        name: 'Spacer',
        icon: 'arrows-up-down',
        id: 'spacer'
    },
    // {
    //     name: 'Image',
    //     icon: 'photo-film',
    //     id: 'image'
    // },
    {
        name: 'Text Input',
        icon: 'input-text',
        id: 'text'
    },
    {
        name: 'Dropdown',
        icon: 'list-dropdown',
        id: 'dropdown'
    },
    {
        name: 'Checkbox',
        icon: 'square-check',
        id: 'checkbox'
    },
    {
        name: 'Date',
        icon: 'calendar-days',
        id: 'date'
    },
    {
        name: 'Location/Address/Map',
        icon: 'location-dot',
        id: 'map'
    },
    {
        name: 'User Select',
        icon: 'user',
        id: 'user'
    },
    {
        name: 'File Upload',
        icon: 'file-arrow-up',
        id: 'media'
    },
    // {
    //     name: 'Signature',
    //     icon: 'signature',
    //     id: 'signature'
    // },
    {
        name: 'Sub Form',
        icon: 'table-list',
        id: 'list'
    }
]

export const schemaItemTypesLayout = {
    layout: [
        'row',
        'spacer'
    ],
    form: [
        'text',
        'dropdown',
        'checkbox',
        'date',
        'map',
        'user',
        'media',
        'list'
    ]
}
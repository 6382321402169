import { Option } from '../elements/OptionsBuilder'

// this file controls what props are set/editable for each schema item type (nothing to do with TS).
// itemProps is looped over in the item edit panel in the schema builder.

const common = {
    title: {
        type: 'string',
        label: 'Label',
        default: 'Text Label',
        maxLength: 200
    },
    description: {
        type: 'string',
        label: 'Description (optional)',
        hint: 'Text displayed in this field when empty',
        default: '',
        maxLength: 500
    },
    hint: {
        type: 'string',
        label: 'Hint (optional)',
        hint: 'Short instructions or description',
        default: '',
        maxLength: 200
    },
    placeholder: {
        type: 'string',
        label: 'Placeholder (optional)',
        hint: 'Text displayed in this field when empty',
        default: '',
        maxLength: 200
    },
    required: {
        type: 'boolean',
        label: 'Make this field required',
        default: false
    }
}

export const itemProps = {
    text: {
        ...common,
        multiline: {
            type: 'boolean',
            label: 'Make long text/paragraph',
            hint: 'Useful for fields with larger amounts of content like Notes or Descriptions',
            default: false
        },
        contentType: {
            type: 'contentType',
            label: 'Special Content Type'
        }
    },
    date: {
        ...common,
        title: {
            ...common.title,
            default: 'Date'
        }
        // showTime: {
        //     type: 'boolean',
        //     label: 'Include Time',
        //     hint: 'Add an area to enter both date and time',
        //     default: false
        // },
        // setRange: {
        //     type: 'boolean',
        //     label: 'Select Date Range',
        //     hint: 'Require date range (start date - end date)',
        //     default: false
        // }
    },
    dropdown: {
        ...common,
        title: {
            ...common.title,
            default: 'Dropdown'
        },
        options: {
            type: 'options',
            label: 'Options',
            hint: 'Add each option\'s label and value.',
            default: [{label: '', value: ''}]
        }
    },
    checkbox: {
        title: {
            ...common.title,
            default: 'Checkbox'
        },
        description: {
            ...common.description
        },
        hint: {
            ...common.hint
        },
        options: {
            type: 'options',
            label: 'Checkbox Options',
            hint: 'Add each option\'s label and value',
            default: [{label: 'Checkbox Option', value: 'checkbox-option'}]
        },
        allowMultiple: {
            type: 'boolean',
            label: 'Allow more than one selection',
            default: true
        }
    },
    'checkbox-group': {
        title: {
            ...common.title,
            default: 'Checkbox'
        },
        description: {
            ...common.description
        },
        options: {
            type: 'options',
            label: 'Checkbox Options',
            hint: 'Add each option\'s label and value',
            default: [{label: 'Checkbox Option', value: 'checkbox-option'}]
        },
        allowMultiple: {
            type: 'boolean',
            label: 'Allow more than one selection',
            default: true
        }
    },
    media: {
        ...common,
        title: {
            ...common.title,
            default: 'File Upload'
        },
        allowMultiple: {
            type: 'boolean',
            label: 'Allow more than one file',
            default: true
        },
        typesAllowed: {
            type: 'fileTypes',
            label: 'Only allow specific file types',
            default: ''
        }
    },
    spacer: {
        height: {
            type: 'number',
            label: 'Height',
            default: 30
        },
        border: {
            type: 'boolean',
            label: 'Add a divider',
            default: false
        }
    },
    map: {
        ...common,
        title: {
            ...common.title,
            default: 'Location/Address'
        }
    },
    user: {
        ...common,
        title: {
            ...common.title,
            default: 'User'
        }
    },
    list: {
        title: {
            ...common.title,
            default: 'Sub Form'
        },
        description: {
            type: 'string',
            label: 'Description (optional)',
            hint: 'Text displayed in this field when empty',
            default: ''
        },
        hint: {
            type: 'string',
            label: 'Hint (optional)',
            hint: 'Short instructions or description',
            default: ''
        }
    }
}

export type SchemaFieldItemProps = {
    id: string
    type: string
    label: string
    hint: string
    default: string|boolean
    options: Option[]
    typesAllowed: string[]
    height: number
    border: boolean
    allowMultiple: boolean
    showTime: boolean
    setRange: boolean
    multiline: boolean
    internalSchemaId?: string
    hidden?: boolean
    maxLength?: number
}

export interface SchemaFieldProps {
    title: SchemaFieldItemProps
    description: SchemaFieldItemProps
    hint: SchemaFieldItemProps
    placeholder: SchemaFieldItemProps
    required: SchemaFieldItemProps
}
import React from 'react'
import { Text, View } from '@react-pdf/renderer'

const Wrapper = ({
    value,
    title,
    description,
    empty,
    children
}) => {

    title = (value !== '' || empty !== '') ? title : undefined

    return (
        <View
            //wrap={false}
            style={{
                width: '100%',
                justifyContent: 'center'
            }}>
            {(title) &&
                <View style={{
                    marginBottom: (description) ? 3 : 8
                }}>
                    <Text style={{
                        color: '#666666',
                        fontSize: 10
                    }}>
                        {title}:
                    </Text>
                </View>
            }

            {(description) &&
                <Text style={{
                    color: '#999999',
                    fontSize: 9,
                    fontStyle: 'italic',
                    marginBottom: 8
                }}>
                    {description}
                </Text>
            }

            {children}
        </View>
    )
}

export default Wrapper

import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'

export type MapCoords = {
    lat: number
    lng: number
}

export type MapProps = {
    apiKey: string,
    zoom: number,
    center: MapCoords,
    onChange?: (value: MapCoords, geo: { results: object[] }) => void
    onLoaded?: () => void
}

interface InternalMap {
    instance: () => void,
    api: {
        Marker: {
            new ({map, position, draggable}: {
                map: () => void,
                position: MapCoords,
                draggable: boolean
            }): Drawing
        },
        Geocoder: {
            new (): {
                geocode: (options: object) => Promise<{results: object[]}>
            }
        }
    }
}

type Drawing = {
    addListener: (
        name: string,
        func: (e: {latLng: {lat: () => number, lng: () => number}}) => void
    ) => void,
    setMap: (v?: unknown) => void
}


const Map: React.FC<MapProps> = ({
    apiKey,
    zoom,
    center: initialCenter,
    onChange,
    onLoaded,
    ...otherProps
}) => {

    const [map, setMap] = useState<InternalMap>()
    const [center, setCenter] = useState(initialCenter)
    const [, setDrawings] = useState<Drawing[]>([])

    useEffect(() => {
        if (typeof map === 'undefined' || typeof map.instance === 'undefined' || typeof map.api === 'undefined') {
            return
        }

        const marker = new map.api.Marker({
            map: map.instance,
            position: {
                lat: center.lat,
                lng: center.lng
            },
            draggable: true
        })

        marker.addListener('dragend', async (e) => {
            const geo = new map.api.Geocoder()

            const result = await geo.geocode({
                'latLng': e.latLng
            })

            onChange?.(
                {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng()
                },
                result
            )
        })

        setDrawings((prev) => {
            if (prev.length > 0) {
                // erase current drawings from map
                prev.map(drawing => drawing.setMap(null))
            }

            return [marker]
        })
    }, [map, center, onChange])

    useEffect(() => {
        if (JSON.stringify(initialCenter) === JSON.stringify(center)) {
            return
        }

        setCenter(initialCenter)
    }, [initialCenter])

    return (
        <GoogleMapReact
            bootstrapURLKeys={{
                key: apiKey,
                libraries: ['places']
            }}
            zoom={zoom}
            center={{
                lat: center.lat,
                lng: center.lng
            }}
            options={{
                disableDefaultUI: true
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({map, maps}) => {
                setMap((prev) => {
                    return {
                        ...prev,
                        instance: map,
                        api: maps
                    }
                })
                if (onLoaded) onLoaded()
            }}
            {...otherProps}
        />
    )
}

export { Map }

import React, { useState } from 'react'
import classNames from 'classnames'
import { useDidUpdate } from '@mantine/hooks'
import { Icon, Text, Row, Button, Card, CardBody, CardFooter, Badge, useUI } from '@evertel/web/ui'
import { Delegator } from '@evertel/schema-parser'
import Wrapper from './Wrapper'
import { SchemaFieldItemProps } from '../schema-builder/data/_schemaItemProps'

export interface ListWriteWidgetProps {
    id: number|string,
    value: Record<string, any>[],
    onChange: (id: string|number, value: Record<string, any>[]) => void,
    title: string,
    description?: string,
    hint?: string,
    required?: boolean,
    item?: any,
    items?: any[],
    scope?: string,
    internalSchemaId?: string,
    schemaId?: number,
    documentId?: number,
    onClickToEditListSchema: (internalSchemaId: string) => void
}

const ListWriteWidget: React.FC<ListWriteWidgetProps> = ({
    id,
    title,
    description,
    hint,
    item,
    items,
    onChange,
    value: initialValue = [],
    scope,
    internalSchemaId,
    onClickToEditListSchema,
    ...otherProps
}) => {

    //console.log(id, title, initialValue, items)

    const { isConfirmed } = useUI()

    const [value, setValue] = useState<Record<string, any>[]>(initialValue) // handles bad data from EverDocs v1
    const listItems = (item) ? item.items : items

    useDidUpdate(() => {
        onChange && onChange(id, value)
    }, [value])

    const onAdd = () => {
        setValue((prev) => {
            return prev.concat({
                _id: Date.now()
            })
        })
    }

    const onDelete = async (index: number) => {
        const deleteIt = async () => {
            setValue((prev) => {
                return prev.filter((_, idx) => idx !== index)
            })
        }

        const confirmed = await isConfirmed({
            title: `Delete this ${title} item?`,
            message: `Are you sure you want to delete this ${title} item from this document?`,
            acceptButton: {
                label: 'Delete',
                color: 'danger'
            }
        })
        if (confirmed) {
            deleteIt()
        }
    }

    return (
        <Wrapper
            title={title}
            description={description}
            hint={hint}
            className="p-2 ListWriteWidget">
            <Card className={classNames({'list': scope === 'schemaBuilder'})}>
                <CardBody>
                    {/* in the schemaBuilder we want to show the form even though there's no data (value) */}
                    {(scope === 'schemaBuilder') ?
                        <>
                            {listItems?.map(({id, type, ...itemProps}: {id: string, type: string}, idx: number) =>
                                <div key={idx}>
                                    <Delegator
                                        value={undefined}
                                        id={id}
                                        type={type}
                                        index={idx}
                                        scope={scope}
                                        {...otherProps}
                                        {...itemProps}                                    />
                                </div>
                            )}
                            <div
                                className="list-click-instructions"
                                onClick={() => onClickToEditListSchema(internalSchemaId || item.internalSchemaId)}>
                                <Badge
                                    color="warning"
                                    size={16}>
                                    Click to manage this form
                                </Badge>
                            </div>
                        </>
                        :
                        <>
                            {value?.map(({ _id, ...itemValue }, index) =>
                                <Card key={`v-${index}`}>
                                    <CardBody>
                                        {listItems?.map((listItem: SchemaFieldItemProps, idx: number) =>
                                            <div key={`i-${idx}`}>
                                                <Delegator
                                                    value={itemValue}
                                                    index={idx}
                                                    onChange={(itemId: string, itemValue: string) => {
                                                        setValue((prev) => {
                                                            return prev.map((v) => {
                                                                if (v._id !== _id) {
                                                                    return v
                                                                }
                                                                return {
                                                                    _id: _id,
                                                                    ...v,
                                                                    [itemId]: itemValue
                                                                }
                                                            })
                                                        })
                                                    }}
                                                    {...otherProps}
                                                    {...listItem}
                                                />
                                            </div>
                                        )}
                                        <Row align="right">
                                            <Button
                                                color="danger"
                                                ghost
                                                onClick={() => onDelete(index)}>
                                                <Text
                                                    size="small">
                                                    Delete
                                                </Text>
                                            </Button>
                                        </Row>
                                    </CardBody>
                                </Card>
                            )}
                        </>
                    }
                </CardBody>
                <CardFooter>
                    <Button
                        color="success"
                        onClick={onAdd}
                        size="sm">
                        <Icon
                            name="plus"
                            color="white"
                            className="mr-2"
                        />
                        <Text
                            color="white"
                            size="small">
                            Add {title}
                        </Text>
                    </Button>
                </CardFooter>
            </Card>
        </Wrapper>
    )
}

// ----------------------------------------------------------------------------------------

export interface ListReadWidgetProps {
    value?: string[],
    title?: string,
    empty?: string,
    schemaId?: number,
    documentId?: number,
    item?: Record<string, any>,
    items?: SchemaFieldItemProps[]
}

const ListReadWidget: React.FC<ListReadWidgetProps> = ({
    value = [],
    title,
    item,
    items,
    ...otherProps
}) => {

    //console.log('LIST', title, {item}, {items}, {value}, {...otherProps})

    const listItems = (item) ? item.items : items // backwards campatibility

    // @ts-expect-error: no-unused-vars
    const { internalSchemaId, ...remainingProps } = otherProps

    return (
        <Wrapper
            title={title}
            {...remainingProps}>
            {value?.map((val, index) =>
                <Card key={`v-${index}`}>
                    <CardBody>
                        {listItems?.map((listItem: SchemaFieldItemProps, idx: number) =>
                            <div key={`i-${idx}`}>
                                <Delegator
                                    value={val}
                                    index={idx}
                                    {...otherProps}
                                    {...listItem}
                                />
                            </div>
                        )}
                    </CardBody>
                </Card>
            )}
            {(!value?.length) &&
                <Text color="muted">
                    No {title}
                </Text>
            }
        </Wrapper>
    )
}

export {
    ListWriteWidget,
    ListReadWidget
}

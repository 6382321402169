import React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

export interface DropdownDividerProps extends HTMLAttributes<HTMLHRElement> {
  className?: string
}

const ButtonDropdownDivider = forwardRef<HTMLHRElement, DropdownDividerProps>(({
    className,
    ...otherProps
}, ref) => {

    const _className = classNames('drop-divider', className)

    return (
        <hr
            ref={ref}
            className={_className}
            {...otherProps}
        />
    )
})

export default ButtonDropdownDivider
ButtonDropdownDivider.displayName = 'ButtonDropdownDivider'

//=================================================
// EXTRACT AUDIO PEAKS FOR USE IN AUDIO WAVEFORMS
//=================================================

//{TypedArray} array - Subarray of audio to calculate peaks from.
function findMinMax(array) {
    let min = Infinity
    let max = -Infinity
    let i = 0
    let len = array.length
    let curr

    for (; i < len; i++) {
        curr = array[i]
        if (min > curr) {
            min = curr
        }
        if (max < curr) {
            max = curr
        }
    }

    return {
        min: min,
        max: max
    }
}

// {TypedArray} channel - Audio track frames to calculate peaks from.
// {Number} samplesPerPixel - Audio frames per peak
function extractPeaks(channel, samplesPerPixel) {
    let i
    let chanLength = channel.length
    let numPeaks = Math.ceil(chanLength / samplesPerPixel)
    let start
    let end
    let segment
    let extreme

    // create interleaved array of min,max
    let peaks = new Float32Array(numPeaks * 2)

    for (i = 0; i < numPeaks; i++) {
        start = i * samplesPerPixel
        end = (i + 1) * samplesPerPixel > chanLength ? chanLength : (i + 1) * samplesPerPixel

        segment = channel.subarray(start, end)
        extreme = findMinMax(segment)

        peaks[i * 2] = extreme.min
        peaks[i * 2 + 1] = extreme.max
    }
    return peaks
}

//merge channels to make mono
function makeMono(channelPeaks) {
    let numChan = channelPeaks.length
    let weight = 1 / numChan
    let numPeaks = channelPeaks[0].length / 2
    let c = 0
    let i = 0
    let min
    let max
    let peaks = new Float32Array(numPeaks * 2)

    for (i = 0; i < numPeaks; i++) {
        min = 0
        max = 0

        for (c = 0; c < numChan; c++) {
            min += weight * channelPeaks[c][i * 2]
            max += weight * channelPeaks[c][i * 2 + 1]
        }

        peaks[i * 2] = min
        peaks[i * 2 + 1] = max
    }
    // return in array so channel number counts still work
    return [peaks]
}

// {AudioBuffer,TypedArray} source - Source of audio samples for peak calculations.
// {Number} samplesPerPixel - Number of audio samples per peak.
// {Number} cueIn - index in channel to start peak calculations from.
// {Number} cueOut - index in channel to end peak calculations from (non-inclusive).
module.exports = function (source, samplesPerPixel, isMono, cueIn, cueOut) {
    samplesPerPixel = samplesPerPixel || 10000

    if (isMono === null || isMono === undefined) {
        isMono = true
    }

    let numChan = source.numberOfChannels
    let peaks = []
    let c
    let numPeaks
    let channel
    let slice

    if (typeof source.subarray === 'undefined') {
        for (c = 0; c < numChan; c++) {
            channel = source.getChannelData(c)
            cueIn = cueIn || 0
            cueOut = cueOut || channel.length
            slice = channel.subarray(cueIn, cueOut)
            peaks.push(extractPeaks(slice, samplesPerPixel))
        }
    } else {
        cueIn = cueIn || 0
        cueOut = cueOut || source.length
        peaks.push(extractPeaks(source.subarray(cueIn, cueOut), samplesPerPixel))
    }

    if (isMono && peaks.length > 1) {
        peaks = makeMono(peaks)
    }

    numPeaks = peaks[0].length / 2

    return {
        length: numPeaks,
        data: peaks
    }
}


import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { BlueUserStore } from '../store'
import { SessionState } from '@evertel/session'
import { Api } from '@evertel/api'
import { DepartmentsAccessStore } from '@evertel/departments-access'
import { getRole } from '@evertel/utils'
import { DepartmentStore } from '@evertel/department'

class CurrentUserController {

    constructor(
        private api: Api,
        private userStore: BlueUserStore,
        private session: SessionState,
        private departmentsAccessStore: DepartmentsAccessStore,
        private departmentStore: DepartmentStore
    ) {
        makeAutoObservable(this)
    }

    update = async (data: any) => {
        if (!data) return

        try {
            const userData = await this.api.Routes.BlueUser.putById(this.session.currentUserId, data)

            runInAction(() => {
                this.userStore.update(userData)
            })
        } catch (e) {
            console.error({
                type: 'API put',
                message: 'CurrentUserController.update()',
                error: e.message,
                userId: this.session.currentUserId
            })
            return
        }
    }

    get user() {
        return this.userStore.findById(this.session.currentUserId)
    }

    get fullName() {
        return this.user?.firstName + ' ' + this.user?.lastName
    }

    get departmentsAccess() {
        return this.departmentsAccessStore.find(da => da.blueUserId === this.session.currentUserId)
    }

    get departmentsAccessNotGuest() {
        return this.departmentsAccess?.filter((da: any) => getRole(da.roles) !== 'guest')
    }

    get guestDepartmentsAccess() {
        return this.departmentsAccess?.filter((da: any) => getRole(da.roles) === 'guest')
    }

    get selectedDepartmentsAccess() {
        return this.departmentsAccess?.find((da: any) => da.departmentId === this.session.selectedDepartmentId) || {}
    }

    get primaryDepartmentsAccess() {
        return this.departmentsAccess?.find((da: any) => da.isPrimary === true) || {}
    }

    get canManage() {
        if (!this.selectedDepartmentsAccess) return false

        return this.selectedDepartmentsAccess.roles?.includes('executive') || this.selectedDepartmentsAccess.roles?.includes('management')
    }

    get canExecutive() {
        if (!this.selectedDepartmentsAccess) return false

        return this.selectedDepartmentsAccess.roles?.includes('executive')
    }

    get hasGuestRoleInSelectedDepartment() {
        return getRole(this.selectedDepartmentsAccess.roles) === 'guest'
    }

    get selectedDepartmentRole() {
        if (!this.selectedDepartmentsAccess?.roles) return undefined

        return getRole(this.selectedDepartmentsAccess.roles)
    }

    get primaryDepartmentRole() {
        if (!this.primaryDepartmentsAccess?.roles) return undefined

        return getRole(this.primaryDepartmentsAccess.roles)
    }

    get primaryDepartment() {
        const primaryDeptId = this.primaryDepartmentsAccess?.departmentId
        return this.departmentStore.findById(primaryDeptId)
    }

    get selectedDepartment() {
        const selectedDeptId = this.selectedDepartmentsAccess?.departmentId
        return this.departmentStore.findById(selectedDeptId)
    }

    get isAdmin() {
        return this.user?.isAdmin || false
    }

    getRoleInDepartment(departmentId: number) {
        const da = this.departmentsAccess?.find((da: any) => da.departmentId === departmentId) || {}
        
        // You might not be a member at all in this department
        if (!da) return false 

        // You are not e member if you are not verified
        if (!da.isVerified) return false 

        return getRole(da.roles)
    }
}

decorate(injectable(), CurrentUserController)
decorate(inject(Api), CurrentUserController, 0)
decorate(inject(BlueUserStore), CurrentUserController, 1)
decorate(inject(SessionState), CurrentUserController, 2)
decorate(inject(DepartmentsAccessStore), CurrentUserController, 3)
decorate(inject(DepartmentStore), CurrentUserController, 4)

export { CurrentUserController }

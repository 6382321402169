import React, { ElementType, forwardRef, HTMLAttributes, MouseEventHandler } from 'react'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLDivElement> {
    fadeIn: boolean
    tag: string|ElementType,
    children?: any
    className?: string
}

const Container = forwardRef<HTMLDivElement, Props>(({
    fadeIn = false,
    tag: Tag = 'div',
    className,
    children,
    ...otherProps
}, ref) => {

    const classes = classNames({
        'fadeIn animated': fadeIn,
        className
    })

    return (
        <Tag
            ref={ref}    
            className={classes}
            {...otherProps}>
            {children}
        </Tag>
    )
})

export { Container }

import React, { useEffect } from 'react'
import Wrapper from './Wrapper'
import { Text, Image, View } from '@react-pdf/renderer'
import { SchemaFieldMediaController } from '../../controller'
import { useService } from '@evertel/di'


const CheckboxReadWidget = ({
    value = [],
    title,
    label,
    options,
    empty,
    schemaId
}) => {

    const values = Array.isArray(value) ? value : [value]

    const fieldMediaController = useService(SchemaFieldMediaController, [])

    useEffect(() => {
        const mediaIds = []
        options?.map(o => {
            if (o.image) {
                mediaIds.push(o.image)
            }
        })

        fieldMediaController.init(
            schemaId,
            mediaIds
        )
    }, [schemaId])

    if (!options?.length && value) {
        return (
            <Text>
                {label}
            </Text>
        )
    }

    return (
        <Wrapper
            value={value}
            title={title}
            empty={empty}>
            {values?.map((value, idx) => {
                const found = options?.find(option => option.value === value)

                if (!found && !value) return null

                return (
                    <View
                        key={idx}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            width: '100%',
                            justifyContent: 'center'
                        }}>
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            
                            <Text style={{
                                paddingRight: 20
                            }}>
                                {found.label || value}
                            </Text>
                        </View>
                        {(found.image) &&
                            <View style={{
                                marginBottom: 8
                            }}>
                                <Image
                                    src={(found.image instanceof File) ?
                                        fieldMediaController?.getLocalFileURL(found.image) :
                                        fieldMediaController?.mediaArrays?.uploaded?.find(m => m.id === found.image)?.url
                                    }
                                    width={100}
                                    height={66}
                                />
                            </View>
                        }
                    </View>
                )
            })}
        </Wrapper>
    )
}

CheckboxReadWidget.propTypes = {}

export default CheckboxReadWidget
CheckboxReadWidget.displayName = 'CheckboxReadWidget (PDF)'

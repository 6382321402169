import { UploadableMedia } from '@evertel/media'
import { APIDataMedia } from '@evertel/types'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { localId } from './general'

//==============================================
// GETS IMAGE DIMENSIONS <Promise>
//==============================================
export async function getImageDimensions(url: string): Promise<{ width: number, height: number }> {
    const img = new Image()
  
    const loadImage = (): Promise<{ width: number, height: number }> => {
        return new Promise((resolve, reject) => {
            const onLoad = () => {
                resolve({ width: img.naturalWidth, height: img.naturalHeight })
            }
  
            const onError = (err: Event) => {
                reject(err)
            }
  
            img.addEventListener('load', onLoad, { once: true })
            img.addEventListener('error', onError, { once: true })
  
            img.src = url
        })
    }
    return await loadImage()
}

//==============================================
// RETURNS MIMETYPE FROM MEDIA OBJECT
//==============================================
export function getMimetype(media: APIDataMedia): string | undefined {
    if (!media) return undefined
    const slashIdx = media.mimetype?.indexOf('/')
    const mimetype = media.mimetype?.slice(0, slashIdx)
    return mimetype
}

//==============================================
// RETURNS FILE EXTENSION FROM URL
//==============================================
export function getFileExtensionFromUrl(url: string): string | undefined {
    // i.e. https://example.com/fold.er/fil.e.jpg?param.eter#hash=12.345 returns jpg
    return url.split(/[#?]/)[0].split('.').pop().trim()
}

//==============================================
// RETURNS TYPE OF MEDIA FROM MEDIA OBJECT
//==============================================
export function getMediaType(media: APIDataMedia | string): string {
    let mimeType = ''
    if (typeof media === 'string') {
        mimeType = media
    } else if (media && media.mimetype) {
        mimeType = media?.mimetype
    }

    if (!mimeType) {
        return 'application'
    }

    const slashIdx = mimeType.indexOf('/')
    const mediaType = slashIdx !== -1 ? mimeType.slice(0, slashIdx) : 'application'
    return mediaType
}
//==============================================
// RETURNS TYPE OF MEDIA FROM MIMETYPE
//==============================================
export function getFileTypeFromMimetype(mimetype: string): string | undefined {
    // if text/plain passed in will return 'text'
    if (!mimetype) return undefined
    return mimetype.split('/').shift()
}

//==============================================
// RETURNS SCALE FLOAT BASED ON ASPECT RATIO
//==============================================
export function parseAspectRatio(aspectRatio: string): number {
    // returns aspect ratio multiplier from string (ie 4:3 will return 1.333333)
    let [p1, p2]: string[] | number[] = aspectRatio.split(':')
    p1 = parseInt(p1, 10)
    p2 = parseInt(p2, 10)
    return p1 / p2
}

//==============================================
// SCALES MEDIA BASED ON MAX DIMENSIONS AND
// NATURAL ASPECT RATIO
//==============================================
interface scaleMediaProps {
    maxWidth: number
    maxHeight: number
    naturalWidth: number
    naturalHeight: number
}
export function scaleMedia({ maxWidth, maxHeight, naturalWidth, naturalHeight }: scaleMediaProps) {
    let width = naturalWidth
    let height = naturalHeight

    // check if the current width is larger than the max
    if (naturalWidth > maxWidth) {
        const ratio = maxWidth / naturalWidth   // get aspect ratio multiplier
        height = naturalHeight * ratio   // scale height
        width = naturalWidth * ratio    // scale width
    }

    // if the scaled height is still larger than max scale some more
    if (height > maxHeight) {
        const ratio = maxHeight / height   // get aspect ratio multiplier
        width = width * ratio   // scale width
        height = height * ratio   // scale height
    }

    return { width: Math.round(width), height: Math.round(height) }
}

//==============================================
// RETURNS MIMETYPE FROM FILE EXTENSION
//==============================================
export function mimeTypeFromExtension(extension: string): string {
    if (!extension) return 'application/octet-stream'

    switch (extension.toLowerCase()) {
        case 'jpeg':
        case 'jpg':
        case 'jpe':
            return 'image/jpeg'
        case 'mp4':
        case 'mp4v':
        case 'mpg4':
            return 'video/mp4'
        case 'mpeg':
        case 'mpg':
        case 'mpe':
        case 'm1v':
        case 'm2v':
            return 'video/mpeg'
        case 'm4a':
            return 'audio/x-m4a'
        case 'aac':
            return 'audio/aac'
        case 'png':
            return 'image/png'
        case 'wav':
            return 'audio/wav'
        case 'docx':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        case 'pdf':
            return 'application/pdf'
        case 'mov':
            return 'video/quicktime'
        case 'gif':
            return 'image/gif'
        case 'heic':
            return 'image/heic'
        default:
            return 'application/octet-stream'
    }
}

//==============================================
// RETURNS FILENAME FROM FULL FILE PATH
//==============================================
export function filenameFromPath(filePath: string): string | undefined {
    // returns file name from passed in file path string
    if (filePath && typeof filePath === 'string') {
        return filePath.split('\\').pop().split('/').pop()
    }
    return undefined
}

//==============================================
// RETURNS FILE EXTENSION FROM FILENAME OR
// MEDIA OBJECT
//==============================================
export function getFileExt(file: string | APIDataMedia | File): string | undefined {
    if (!file) return undefined

    // returns file extention from file name string or full media object
    let el = null
    if (typeof file === 'string') {
        el = file
    } else if ('name' in file) {
        el = file.name
    } else if ('fileName' in file) {
        el = file.fileName
    }

    if (!el) return undefined

    return el.split('.').pop()
}

//==============================================
// RETURNS FILE ICON NAME, COLOR, TYPENAME
// FOR THE FILE PASSED IN
//==============================================
interface Attributes {
    iconName: IconName,
    iconColor: string,
    typeName: string
}
export function getFileAttributes(fileName: string): Attributes {
    const fileType = getFileExt(fileName)
    const attributes: Attributes = { iconName: 'file', iconColor: 'secondary', typeName: `${fileType} File` }

    switch (fileType) {
        case 'mp3':
        case 'wav':
        case 'aac':
            attributes.iconName = 'file-audio'
            attributes.iconColor = '#4CAF50'
            attributes.typeName = 'Audio'
            break
        case 'mp4':
        case 'avi':
        case 'mpg':
        case 'mov':
        case 'webm':
        case '3gp':
        case 'wmv':
        case 'm4v':
            attributes.iconName = 'file-video'
            attributes.iconColor = '#4CAF50'
            attributes.typeName = 'Video'
            break
        case 'xlsx':
        case 'xls':
        case 'xlsm':
        case 'ods':
            attributes.iconName = 'file-excel'
            attributes.iconColor = 'seagreen'
            attributes.typeName = 'Excel Spreadsheet'
            break
        case 'pdf':
            attributes.iconName = 'file-pdf'
            attributes.iconColor = 'crimson'
            attributes.typeName = 'PDF Document'
            break
        case 'doc':
        case 'docx':
            attributes.iconName = 'file-word'
            attributes.iconColor = 'royalblue'
            attributes.typeName = 'Word Document'
            break
        case 'zip':
        case 'rar':
        case 'tgz':
        case 'tar.gz':
        case 'z':
        case 'rpm':
        case '7z':
            attributes.iconName = 'file-archive'
            attributes.iconColor = 'secondary'
            attributes.typeName = 'File Archive'
            break
        case 'ppt':
        case 'pptx':
        case 'pptsx':
        case 'pps':
        case 'key':
        case 'odp':
            attributes.iconName = 'file-powerpoint'
            attributes.iconColor = 'brown'
            attributes.typeName = 'PowerPoint Presentation'
            break
        case 'csv':
            attributes.iconName = 'file-csv'
            attributes.iconColor = '#359AA2'
            attributes.typeName = 'CSV File'
            break
        case 'aif':
        case 'cda':
        case 'mid':
        case 'midi':
        case 'mp3':
        case 'mpa':
        case 'wav':
        case 'ogg':
        case 'wma':
        case 'wpl':
            attributes.iconName = 'file-audio'
            attributes.iconColor = 'pink'
            attributes.typeName = 'Audio File'
            break
        case 'db':
        case 'dbf':
        case 'sql':
        case 'xml':
            attributes.iconName = 'database'
            attributes.iconColor = 'purple'
            attributes.typeName = 'Data File'
            break
        case 'txt':
        case 'wpd':
        case 'rtf':
            attributes.iconName = 'file-alt'
            attributes.iconColor = 'grey'
            attributes.typeName = 'Text File'
            break
        default:
            attributes.iconColor = '#359AA2'
            break
    }
    return attributes
}

//==============================================
// RETURNS A VIDEO'S META DATA <Promise>
//==============================================
export async function getVideoMetadata(url: string): Promise<{ height: number, width: number, duration: number }> {
    const video = document.createElement('video')
  
    const loadVideoMetadata = (): Promise<{ height: number, width: number, duration: number }> => {
        return new Promise((resolve, reject) => {
            const onLoadedMetadata = () => {
                resolve({
                    height: video.videoHeight,
                    width: video.videoWidth,
                    duration: video.duration * 1000
                })
            }
  
            const onError = (err: Event) => {
                console.log('ERROR GETTING VIDEO METADATA', err)
                reject(err)
            }
  
            video.addEventListener('loadedmetadata', onLoadedMetadata, { once: true })
            video.addEventListener('error', onError, { once: true })
  
            video.src = url
        })
    }  

    const metadata = await loadVideoMetadata()
    return metadata

}

//==============================================
// TESTS IF AN IMAGE URL IS VALID <Promise>
//==============================================
export function testImage(url: string, timeoutT?: number) {
    return new Promise(function (resolve, reject) {
        const timeout = timeoutT || 3000
        const img = new Image()
        let timer = undefined

        img.onerror = img.onabort = function () {
            clearTimeout(timer)
            reject('error')
        }
        img.onload = function () {
            clearTimeout(timer)
            resolve('success')
        }
        timer = setTimeout(function () {
            // reset .src to invalid URL so it stops previous
            // loading, but doens't trigger new load
            img.src = '//!!!!/noexist.jpg'
            reject('timeout')
        }, timeout)

        img.src = url
    })
}

//==============================================
// RETURNS FILEPATH NAME FROM FULL FILEPATH
//==============================================
export function getFilePathName(filePath: string, seperator = '/'): string {
    return filePath.substring(0, filePath.lastIndexOf(seperator))
}

//==============================================
// RETURNS FILEPATH BASE FROM FULL FILEPATH
//==============================================
export function getFileBaseName(str: string, seperator = '/'): string {
    return str.substring(str.lastIndexOf(seperator) + 1)
}

//==============================================
// MOBILE ONLY! HELPER TO
// GETDISTANCEBETWEENTOUCHES()
//==============================================
export function pow2abs(a: number, b: number): number {
    // used as helper to get the distance between two touches on mobile
    return Math.pow(Math.abs(a - b), 2)
}

export const fileToUploadableMedia = async (file: File):Promise<UploadableMedia> => {
    const url = URL.createObjectURL(file)

    const mimeType = file.type || 'application/unknown'
    const contentType = getMediaType(mimeType)

    let width: number, height: number, duration: number

    if (contentType === 'image') { 
        const imageDimensions = await getImageDimensions(url) 
        width = imageDimensions.width
        height = imageDimensions.height
    }

    if (contentType === 'video') { 
        const videoDimensions = await getVideoMetadata(url)
        width = videoDimensions.width
        height = videoDimensions.height
        duration = videoDimensions.duration
    }

    const orientation = (width > height) ? 'landscape' : 'portrait'

    return {
        id: localId(),
        url,
        fileName: file.name,
        contentType,
        mimeType,
        contentLength: file.size,
        orientation,
        width,
        height,
        duration,
        isLocalMedia: true,
        state: 'readyToUpload',
        uploadProgress: 0,
        onGetPartFormData: async (partNumber, start, end) => {
            const fileData = file.slice(start, end)
            const formData = new FormData()
            formData.append('part', fileData)
            return formData 
        },
        updatedDate: new Date()
    }
}

//==============================================
// MOBILE ONLY! RETURNS THE DISTANCE BETWEEN
// TWO TOUCHPOINTS
//==============================================
export function getDistanceBetweenTouches(touches: Array<Touch>) {
    // used for pinch & zoom
    const [a, b] = touches

    if (a == null || b == null) {
        return 0
    }
    return Math.sqrt(
        pow2abs(a.pageX, b.pageX) + pow2abs(a.pageY, b.pageY)
    )
}

//==============================================
// RETURNS THE SCALE OF A ZOOMED IMAGE
//==============================================
export function getScale(currentDistance: number, initialDistance: number): number {
    // get the scale of a zoomed image
    return currentDistance / initialDistance * 1.2
}

//==============================================
// RETURNS THE CLOSEST SIZE VERSION OF AN IMAGE
// BASED ON THE NEEDED HEIGHT
//==============================================
export function getClosestVersionByHeight(height: number, media: APIDataMedia) {
    if (!height || !media || !media.meta) return undefined
    const meta = media.meta as {
        versions?: any[],
        width?: number,
        height?: number
    }

    if (!meta.versions) {
        // if no versions, just return the original
        return ({
            width: meta.width,
            height: meta.height,
            id: 'original',
            url: `${media.url}?ver=original&u=${media.id}`
        })
    }

    // outputs an object with the closest image version size and the full url based on the height passed in
    const versionsPlusOriginal = [...meta.versions, {
        width: meta?.width || 400,
        height: meta.height || 300,
        id: 'original'
    }]

    // compare each height and hold on to the closest match
    let closestHeightVersion = versionsPlusOriginal[0]
    for (const version of versionsPlusOriginal) {
        const a = closestHeightVersion
        const b = version
        closestHeightVersion = (Math.abs(b.height - height) < Math.abs(a.height - height)) ? b : a
    }

    // return the version, add url like getVersion does
    return ({
        ...closestHeightVersion,
        url: `${media.url}?ver=${closestHeightVersion.id}&u=${media.id}`
    })
}

const bannedExtensions = ['exe', 'bat', 'msi', 'com', 'vbs', 'js', 'ps1', ' scr', 'cmd', 'dll', 'jar', 'heic', 'avif']

export const isExtensionBanned = (ext: string) => { return bannedExtensions.includes(ext) }
import React from 'react'
import { Text } from '@react-pdf/renderer'

const Title = ({
    text
}) => {

    return (
        <Text
            style={{
                fontWeight: 'bold'
            }}>
            {text}
        </Text>
    ) 
}

export default Title

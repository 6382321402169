
import { APIDataBlueUser } from '@evertel/types'
import { makeAutoObservable, runInAction } from 'mobx'
import { debounce, uniqBy } from 'lodash'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { BlueUserStore } from '@evertel/blue-user'
import { PushService, PushNotification } from '@evertel/push'

export type SearchScopes = 'all'|'members'|'managers'
export type SearchRoles = 'guest'|'executive'|'management'|'employee'|'all'

class RoomDirectorySearchController {
    users: APIDataBlueUser[] = []
    usersLatestFetch: APIDataBlueUser[] = []
    usersCount = 0
    roomId = 0
    searchTerm = ''
    page = 1
    limit = 100
    isInfiniteScroll = true
    searchScope: SearchScopes = 'all'
    searchRole: SearchRoles = 'all'
    notificationUpdate = 0

    constructor(
        private api: Api,
        private userStore: BlueUserStore,
        private pushService: PushService
    ) {
        makeAutoObservable(this)
    }

    init(roomId: number, isInfiniteScroll?: boolean) {
        this.roomId = roomId
        this.searchScope = 'all'
        this.searchRole = 'all'
        this.isInfiniteScroll = isInfiniteScroll
        this.pushService.addPushNotificationListener(this.onPushNotification)

        this.resetState()
    }

    destroy = () => {
        this.pushService.removePushNotificationListener(this.onPushNotification)
    }

    onPushNotification = (notification: PushNotification) => {
        if (notification.data.type === 'Room_updated' && notification.data.roomId === this.roomId){
            this.incrementNotification()
        }
    }

    setSearchTerm(value: string) {
        this.resetSearchMeta()
        this.searchTerm = value
    }

    incrementNotification(){
        this.notificationUpdate = this.notificationUpdate + 1
    }

    setSearchScope(value: SearchScopes) {
        this.resetSearchMeta()
        this.searchScope = value
    }

    setSearchRole(value: SearchRoles) {
        this.resetSearchMeta()
        this.searchRole = value
    }

    setPage(pageNumber: number) {
        this.page = pageNumber
    }

    debouncedSearch = async () => {
        return debounce( async () => this.search(), 300)
    }

    search = async () => {
        if (!this.roomId) {
            console.error('Missing roomId on RoomDirectorySearchController.search()')
            return []
        }

        let count = this.usersCount
        let results = []

        // grab the total count of docs in this room
        count = await this.fetchUsersCount()
        
        this.usersLatestFetch = []

        results = await this.api.Routes.Room.getSearchUsers(this.roomId, this.filter)

        this.userStore.update(results)

        runInAction(() => {
            this.usersLatestFetch = results
            this.users = (this.isInfiniteScroll) ? uniqBy([...this.users, ...results], 'id') : results
            this.usersCount = count
        })

        return this.usersLatestFetch
    }

    async fetchUsersCount() {
        const res = await this.api.Routes.Room.getSearchUsersCount(this.roomId, this.filter.where)

        runInAction(() => {
            this.usersCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    fetchNextDocuments() {
        this.page = this.page + 1
        this.search()
    }

    resetSearchMeta() {
        this.page = 1
        this.users = []
        this.usersCount = 0
    }

    resetState() {
        this.resetSearchMeta()
        this.searchTerm = ''
        this.limit = 100
    }

    get filter() {
        return {
            include: [
                {
                    relation: 'departmentsAccess',
                    scope: {
                        include: {
                            relation: 'department',
                            scope: {
                                fields: ['id', 'name', 'shortName']
                            }
                        }
                    }
                }
            ],
            where: {
                searchTerm: this.searchTerm,
                scope: this.searchScope || 'all',
                role: this.searchRole || 'all'
            },
            order: 'firstName ASC',
            limit: this.limit,
            skip: (this.page - 1) * this.limit
        }
    }
}

decorate(injectable(), RoomDirectorySearchController)
decorate(inject(Api), RoomDirectorySearchController, 0)
decorate(inject(BlueUserStore), RoomDirectorySearchController, 1)
decorate(inject(PushService), RoomDirectorySearchController, 2)

export { RoomDirectorySearchController }

import React, { useCallback } from 'react'
import { normalize } from './utils/Normalize'
import Form from './Form'
import { ValueProvider } from './ValueContext'
import { observer } from 'mobx-react-lite'

interface DocumentProps {
    layout: 'full' | 'pdf',
    schema: { layouts: Record<string, unknown>, id?: number },
    documentId?: number,
    schemaId?: number,
    value: unknown,
    onChange?: (value: Record<string, unknown>) => void,
    errors?: Record<string, unknown>
}

const Document: React.FC<DocumentProps> = observer(({
    layout = 'full',
    schema,
    value = {},
    onChange,
    errors,
    ...otherProps
}) => {

    schema = normalize(schema)

    //console.log('NORMALIZED SCHEMA', {schema}, {value})

    const handleChange = useCallback((_, value) => {
        //console.log('handleChange', value)
        onChange && onChange(value)
    }, [])

    return (
        <ValueProvider value={value as any}>
            <Form
                id="_root"
                items={schema.layouts[layout] as any}
                value={value}
                errors={errors}
                onChange={handleChange}
                {...otherProps}
            />
        </ValueProvider>
    )
})

export default Document

import { Col, Row, ContentPlaceholder} from '../../src'

const MultilinePlaceholder = ({sections = 1, className = ''}) => {
    return (
        <>
            {Array.from({ length: sections }).map((_, index) => (
                <Row 
                    key={index}
                    className={className}
                >
                    <Col className="p-3" style={{ opacity: 0.2 }}>
                        <Row>
                            <ContentPlaceholder width={18} height={18} />
                            <ContentPlaceholder height={18} className="ml-2" />
                        </Row>
                        <Row className="mt-3">
                            <ContentPlaceholder width={18} height={18} />
                            <ContentPlaceholder height={18} className="ml-2" />
                        </Row>
                        <Row className="mt-3">
                            <ContentPlaceholder width={18} height={18} />
                            <ContentPlaceholder height={18} className="ml-2" />
                        </Row>
                    </Col>
                </Row>
            ))}
        </>
    )
}

export {
    MultilinePlaceholder
}

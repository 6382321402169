import React from 'react'
import { Icon } from './Icon'

interface RetryButtonProps {
    postId: number,
    onClick: () => void
}

const RetryButton: React.FC<RetryButtonProps> = ({
    postId,
    onClick
}) => {
    const id = 'retry-' + postId

    return (
        <div className="cursor-pointer d-flex flex-column align-items-center mt-5" onClick={onClick} id={id}>
            <div className="bg-danger d-flex justify-content-center align-items-center" style={{ borderRadius: 50, height: 25, width: 25 }}>
                <Icon color="white" name="redo-alt" />
            </div>
            <small className="text-danger">Failed</small>
        </div>
    )
}

export { RetryButton }